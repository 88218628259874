import Constants from '../constants/action'

const initialState = {
  name: '',
  route: {
    addresses: [
      {
        id: null,
        address: '',
        detail_address: '',
        address_type: 'receiver',
        contact_name: '',
        contact_phone_number: '',
        email_address: '',
        company_name: '',
        remark: '',
        lat: null,
        branch_id: '',
      },
    ],
  },
  loading: false,
  activeWaypointID: null,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case Constants.CURRENT_ROUTE_MODIFIED: {
      const newAddresses = [...action.route.addresses]
      if (action.route.addresses.length === 0) {
        newAddresses.push({ ...initialState.route.addresses[0] })
      }
      return {
        ...state,
        route: {
          ...action.route,
          addresses: newAddresses,
        },
      }
    }

    case Constants.CURRENT_ROUTE_CREATED:
      return initialState

    case Constants.CURRENT_ROUTE_UPDATED:
      return initialState

    case Constants.SET_ACTIVE_WAYPOINT:
      return { ...state, activeWaypointID: action.activeWaypointID }

    default:
      return state
  }
}
