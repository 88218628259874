import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { CalendarWithEditableInput } from 'ggx-componentlibrary/components/calendar/with-editable-input/calendar.component'
import { HeadingSmall } from 'ggx-componentlibrary/design/typography/typography.styles'

import { getLocale } from '../../legacy/utils/locale'
import { CalendarContainer } from './filter-date.component.styles'

const FilterDate = ({ onChange, filterDate }) => {
  const [t] = useTranslation()
  const currentLocale = getLocale()
  return (
    <CalendarContainer>
      <HeadingSmall>
        {t('order_management__filter__delivery_date')}
      </HeadingSmall>
      <CalendarWithEditableInput
        onSubmit={onChange}
        selectedDates={filterDate}
        locale={currentLocale}
        inputText={t('calendar__to')}
        footerApplyButtonText={t('common__apply')}
        footerCancelButtonText={t('common__cancel')}
      />
    </CalendarContainer>
  )
}

FilterDate.propTypes = {
  onChange: PropTypes.func,
  filterDate: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }),
}

export { FilterDate }
