import { Api } from '../../legacy/utils/api'

const _onboardingApi = new Api(Api.BASE_URL.B2B)

const apiService = {
  setViewedServiceIntroduction: async () => {
    try {
      const method = 'put'
      const endpoint = '/users/'

      const data = {
        meta: {
          b2b: {
            service_introduction: false,
          },
        },
      }

      return await _onboardingApi.request(method, endpoint, null, data)
    } catch (error) {
      throw error
    }
  },
}

export { apiService, _onboardingApi }
