import React from 'react'

import { Body } from 'ggx-componentlibrary/design/typography/typography.styles'

import {
  AddressContainer,
  AddressInformation,
  AddressBody,
  AddressCompound,
  AddressContactBody,
  SaveAddressBody,
  AddressName,
  GenericOptionContainer,
  GenericContent,
  GenericLabel,
  GenericInfo,
  OrderHistoryIcon,
  SavedAddressIcon,
  LocationSuggestionIcon,
  BinIcon,
  PlusIcon,
  StyledLocationTypeImage,
  StyledLocationTypeTextSection,
  ContactIcon,
  AddressBookIcon,
  Divider,
  AddContactIcon,
} from './options.component.styles'

export const OPTION_TYPE = {
  LOCATION: 'location',
  LOCATION_WITHOUT_ICON: 'location-without-icon',
  NDD_LOCATION: 'ndd-location',
  NO_LOCATION: 'no-location',
  SAVE_ADDRESS: 'save-address',
  SAVED_ADDRESS: 'saved-address',
  SAVE_ADDRESS_BOOK: 'save-address-book',
  PICK_FROM_ADDRESS_BOOK: 'pick-from-address-book',
  SAVED_ADDRESS_BOOK: 'saved-address-book',
  RECENT_ADDRESS: 'recent-address',
  PACKAGE_DETAILS: 'package-details',
  NAME: 'name',
  ORGANIZATION: 'organization',
}

export const OPTION_COMPONENT = {
  [OPTION_TYPE.LOCATION]: props => <LocationSuggestionOption {...props} />,
  [OPTION_TYPE.NDD_LOCATION]: props => <NDDLocationOption {...props} />,
  [OPTION_TYPE.SAVE_ADDRESS]: props => <SaveAddressOption {...props} />,
  [OPTION_TYPE.SAVED_ADDRESS]: props => <SavedAddressOption {...props} />,
  [OPTION_TYPE.SAVE_ADDRESS_BOOK]: props => (
    <SaveAddressBookOption {...props} />
  ),
  [OPTION_TYPE.PICK_FROM_ADDRESS_BOOK]: props => (
    <PickFromAddressBookOption {...props} />
  ),
  [OPTION_TYPE.SAVED_ADDRESS_BOOK]: props => (
    <SavedAddressBookOption {...props} />
  ),
  [OPTION_TYPE.RECENT_ADDRESS]: props => <RecentAddressOption {...props} />,
  [OPTION_TYPE.GENERIC]: props => <GenericOption {...props} />,
}

const AddressInformationSection = ({
  name,
  contact_name,
  contact_phone_number,
  address,
  detail_address,
  label,
}) => {
  const contactName = contact_name || ''
  const contactPhoneNumber = contact_phone_number || ''
  const dot = contact_name && contact_phone_number ? ' · ' : ''
  const contactInformation = `${contactName}${dot}${contactPhoneNumber}`

  return (
    <AddressInformation>
      {name && <AddressName>{name}</AddressName>}
      {label && <AddressBody>{label}</AddressBody>}
      <AddressCompound>
        {address && <AddressBody>{address}</AddressBody>}
        {detail_address && (
          <AddressBody>&nbsp;&nbsp;·&nbsp;&nbsp;{detail_address}</AddressBody>
        )}
      </AddressCompound>
      {contactInformation && (
        <AddressContactBody>{contactInformation}</AddressContactBody>
      )}
    </AddressInformation>
  )
}

const RecentAddressOption = ({
  name,
  contact_name,
  contact_phone_number,
  address,
  detail_address,
  recent_address_id,
  muiProps,
}) => {
  return (
    <AddressContainer {...muiProps} data-testid="autocomplete-option">
      <OrderHistoryIcon data-testid="order-history-icon" />
      <AddressInformationSection
        name={name}
        contact_name={contact_name}
        contact_phone_number={contact_phone_number}
        address={address}
        detail_address={detail_address}
      />
    </AddressContainer>
  )
}

const SaveAddressOption = ({
  showDivider,
  showSaveAddressModal,
  label,
  muiProps,
}) => {
  const overrideMuiProps = {
    ...muiProps,
    onMouseDown: e => {
      showSaveAddressModal(OPTION_TYPE.SAVE_ADDRESS)
    }, // Override the onClick func from MUI props.
    showDivider: showDivider,
  }

  return (
    <AddressContainer {...overrideMuiProps} data-testid="autocomplete-option">
      <PlusIcon data-testid="plus-icon" />
      <SaveAddressBody>{label}</SaveAddressBody>
    </AddressContainer>
  )
}

const SavedAddressOption = ({
  name,
  contact_name,
  contact_phone_number,
  address,
  detail_address,
  saved_address_id,
  handleDeleteSavedAddress,
  muiProps,
}) => {
  return (
    <AddressContainer {...muiProps} data-testid="autocomplete-option">
      <SavedAddressIcon data-testid="saved-address-icon" />
      <AddressInformationSection
        name={name}
        contact_name={contact_name}
        contact_phone_number={contact_phone_number}
        address={address}
        detail_address={detail_address}
      />
      <BinIcon
        onClick={event => {
          event.stopPropagation()
          handleDeleteSavedAddress(saved_address_id)
        }}
        data-testid="bin-icon"
      />
    </AddressContainer>
  )
}

const SaveAddressBookOption = ({
  showDivider,
  showSaveAddressModal,
  label,
  muiProps,
}) => {
  const overrideMuiProps = {
    ...muiProps,
    onMouseDown: e => {
      showSaveAddressModal(OPTION_TYPE.SAVE_ADDRESS_BOOK)
    }, // Override the onClick func from MUI props.
  }

  return (
    <>
      <AddressContainer {...overrideMuiProps} data-testid="autocomplete-option">
        <AddContactIcon data-testid="plus-icon" />
        <SaveAddressBody>{label}</SaveAddressBody>
      </AddressContainer>
      {showDivider && <Divider />}
    </>
  )
}

const PickFromAddressBookOption = ({
  showDivider,
  showSaveAddressModal,
  label,
  muiProps,
}) => {
  const overrideMuiProps = {
    ...muiProps,
    onMouseDown: e => {
      showSaveAddressModal(OPTION_TYPE.PICK_FROM_ADDRESS_BOOK)
    }, // Override the onClick func from MUI props.
  }

  return (
    <>
      <AddressContainer {...overrideMuiProps} data-testid="autocomplete-option">
        <AddressBookIcon />
        <SaveAddressBody>{label}</SaveAddressBody>
      </AddressContainer>
      {showDivider && <Divider />}
    </>
  )
}

const SavedAddressBookOption = ({
  name,
  contact_name,
  contact_phone_number,
  address,
  detail_address,
  muiProps,
  showDivider,
}) => {
  return (
    <>
      <AddressContainer {...muiProps} data-testid="autocomplete-option">
        <ContactIcon data-testid="contact-icon" />
        <AddressInformationSection
          name={name}
          contact_name={contact_name}
          contact_phone_number={contact_phone_number}
          address={address}
          detail_address={detail_address}
        />
      </AddressContainer>
      {showDivider && <Divider />}
    </>
  )
}

const LocationSuggestionOption = ({
  label,
  place_id,
  withoutIcon,
  muiProps,
}) => {
  return (
    <AddressContainer {...muiProps} data-testid="autocomplete-option">
      {!withoutIcon && (
        <LocationSuggestionIcon data-testid="location-suggestion-icon" />
      )}
      <AddressInformationSection label={label} />
    </AddressContainer>
  )
}

const PackageDetailsSection = ({ label, value }) => {
  const { length, width, height, weight, remark } = value
  const sizeOutput = `${length} x ${width} x ${height} cm`
  const weightOutput = remark ? `, ${weight} kg, ${remark}` : `, ${weight} kg `

  return (
    <>
      <GenericLabel>{label}</GenericLabel>
      <GenericInfo>
        {sizeOutput}
        {weightOutput}
      </GenericInfo>
    </>
  )
}

const NameSection = ({ label, value }) => {
  const { name, phone_number: phoneNumber, address, detail } = value
  const phoneOutput = phoneNumber ? ` (${phoneNumber})` : ''
  const detailOutput = detail ? `${detail}, ` : ''

  return (
    <>
      <GenericLabel>
        {name}
        {phoneOutput}
      </GenericLabel>
      <GenericInfo>
        {detailOutput}
        {address}
      </GenericInfo>
    </>
  )
}

const GenericOption = ({ label, value, muiProps, groupType }) => {
  const showDivider =
    groupType === OPTION_TYPE.PACKAGE_DETAILS || groupType === OPTION_TYPE.NAME
  return (
    <GenericOptionContainer
      {...muiProps}
      data-testid="autocomplete-option"
      showDivider={showDivider}
    >
      <GenericContent>
        {groupType === OPTION_TYPE.PACKAGE_DETAILS && (
          <PackageDetailsSection label={label} value={value} />
        )}
        {groupType === OPTION_TYPE.NAME && (
          <NameSection label={label} value={value} />
        )}
        {groupType === OPTION_TYPE.ORGANIZATION && <Body>{label}</Body>}
      </GenericContent>
    </GenericOptionContainer>
  )
}

const NDDLocationOption = ({ value, muiProps }) => {
  return (
    <AddressContainer {...muiProps} data-testid="autocomplete-option">
      <StyledLocationTypeImage locationType={value.type} />
      <StyledLocationTypeTextSection location={value} />
    </AddressContainer>
  )
}

const AddressOption = ({
  muiProps,
  option,
  handleDeleteSavedAddress,
  showSaveAddressModal,
}) => {
  const {
    name,
    contact_name,
    contact_phone_number,
    address,
    detail_address,
    saved_address_id,
    recent_address_id,
  } = option?.data?.value || option?.value || {}
  const { label, value } = option

  switch (option.groupType) {
    case OPTION_TYPE.RECENT_ADDRESS:
      return OPTION_COMPONENT[option.groupType]({
        name,
        contact_name,
        contact_phone_number,
        address,
        detail_address,
        recent_address_id,
        muiProps,
      })
    case OPTION_TYPE.SAVE_ADDRESS:
      return OPTION_COMPONENT[option.groupType]({
        showDivider: true,
        showSaveAddressModal,
        label: option.label,
        muiProps,
      })
    case OPTION_TYPE.SAVED_ADDRESS:
      return OPTION_COMPONENT[option.groupType]({
        name,
        contact_name,
        contact_phone_number,
        address,
        detail_address,
        saved_address_id,
        muiProps,
        handleDeleteSavedAddress,
      })
    case OPTION_TYPE.SAVE_ADDRESS_BOOK:
      return OPTION_COMPONENT[option.groupType]({
        showDivider: true,
        showSaveAddressModal,
        label: option.label,
        muiProps,
      })
    case OPTION_TYPE.PICK_FROM_ADDRESS_BOOK:
      return OPTION_COMPONENT[option.groupType]({
        showDivider: true,
        showSaveAddressModal,
        label: option.label,
        muiProps,
      })
    case OPTION_TYPE.SAVED_ADDRESS_BOOK:
      return OPTION_COMPONENT[option.groupType]({
        name,
        contact_name,
        contact_phone_number,
        address,
        detail_address,
        saved_address_id,
        muiProps,
        handleDeleteSavedAddress,
        showDivider: option.showDivider,
      })
    case OPTION_TYPE.NDD_LOCATION:
      return OPTION_COMPONENT[option.groupType]({
        label,
        value,
        muiProps,
      })
    case OPTION_TYPE.PACKAGE_DETAILS:
    case OPTION_TYPE.NAME:
    case OPTION_TYPE.ORGANIZATION:
      return OPTION_COMPONENT[OPTION_TYPE.GENERIC]({
        label,
        value,
        muiProps,
        groupType: option.groupType,
      })
    case OPTION_TYPE.LOCATION_WITHOUT_ICON:
    case OPTION_TYPE.LOCATION:
    case OPTION_TYPE.NO_LOCATION:
    default:
      const withoutIcon =
        option.groupType === OPTION_TYPE.LOCATION_WITHOUT_ICON ||
        option.groupType === OPTION_TYPE.NO_LOCATION

      return OPTION_COMPONENT[OPTION_TYPE.LOCATION]({
        label,
        place_id: value?.place_id || option.label,
        withoutIcon,
        muiProps,
      })
  }
}

export default AddressOption
