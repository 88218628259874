import styled from "styled-components"

import { Tab } from "../../component-library/components/tab/tab.component"
import { SPACING } from "../../component-library/design/spacing/spacing.constants"
import { COLOR } from "../../component-library/design/color/color.constants"
import { SubscriptionCallout } from "../../paid-subscription-plan/subscription-callout/subscription-callout.component"
import { BodyBold } from "ggx-componentlibrary/design/typography/typography.styles"

const MODAL_HEIGHT = "640px"
const TAB_ITEM_WIDTH = "140px"

const StyledTab = styled(Tab)`
  margin-bottom: ${SPACING.M};
`

const StyledSubscriptionCallout = styled(SubscriptionCallout)`
  margin-bottom: -${SPACING.XS};
`

const StyledConfirmPopup = styled.div`
  position: absolute;
  border-radius: 4px;
  padding: ${SPACING.S};
  background: white;
  width: 250px;
  bottom: calc(100% + ${SPACING.XS});
  right: 0;
  border: 1px solid #0000001A;
  box-shadow: 0px 2px 6px 0px #0000001A;
`

const StyledButton = styled(BodyBold)`
 cursor: pointer;
 color: ${COLOR.BLUE_MEDIUM};
 margin-top: ${SPACING.XS};
 margin-bottom: 0;
 margin-left: auto;
 &:hover { text-decoration: underline; }
`

const Mask = styled.div`
  position: absolute;
  top:0;
  bottom:0;
  width: calc(100% - 48px);
  height: calc(100% - 48px);
  background-color: white;
  opacity: 0.7;
`

export { StyledTab, MODAL_HEIGHT, TAB_ITEM_WIDTH, StyledSubscriptionCallout, StyledConfirmPopup, StyledButton, Mask }
