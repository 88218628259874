import styled from 'styled-components'
import Button from '@mui/material/Button';

import { SPACING } from "ggx-componentlibrary/design/spacing/spacing.constants"
import { COLOR } from 'ggx-componentlibrary/design/color/color.constants'
import { Body, TextLink, TextLinkStyles } from "ggx-componentlibrary/design/typography/typography.styles"

const TraceAndTrackWapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const OpenAnTraceLink = styled(TextLink)`
  display: flex;
  align-items: center;
  gap: ${SPACING.XS};
  font-size: 14px;
`

const OpenAnTraceLinkText = styled.span`
  max-width: 131px;
`

const CopyAnTraceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${SPACING.XS};
  cursor: pointer;
`

const OpenShareMenuText = styled.p`
  ${TextLinkStyles}
  display: flex;
  align-items: center;
  gap: ${SPACING.XS};
  font-size: 14px;
`

const ShareMenuItem = styled(Button)`
  height: ${SPACING.XL2};
  padding: ${SPACING.S};
  text-transform: none;
  border-radius: 0;
  justify-content: left;

  &:hover {
    background-color: ${COLOR.GREY_LIGHTER};
  }

  a {
    text-decoration: none;
  }
`

const StyledBody = styled(Body)`
  height: ${SPACING.XL2};
  padding: ${SPACING.S};
  cursor: default;
  opacity: ${({disabled}) => disabled ? '0.38' : '1' }; 
`

export {
  TraceAndTrackWapper,
  OpenAnTraceLink,
  OpenAnTraceLinkText,
  CopyAnTraceContainer,
  OpenShareMenuText,
  ShareMenuItem,
  StyledBody,
}