import { isCurrentCountryLocale } from './locale'
import { languageCodeToLocale } from '../locales/conversions'

const storeNewLanguageAndReload = newLanguage => {
  const previousLocale = localStorage.getItem('locale')
  const newLocale = languageCodeToLocale(newLanguage)

  if (previousLocale !== newLocale && isCurrentCountryLocale(newLocale)) {
    localStorage.setItem('locale', newLocale)
    window.location.reload()
  }
}

export { storeNewLanguageAndReload }
