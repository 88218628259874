import styled from "styled-components"
import { COLOR } from "../../../component-library/design/color/color.constants"
import { FONT_SIZE } from "../../../component-library/design/typography/typography.constants"

const MessageWrapper = styled.li`
  padding: 1.357rem 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const Message = styled.div`
  flex: 0 0 100%;
`

const ActionTextButton = styled.button`
  padding: 0.5rem 0 0;
  outline: none;
  border: 0;
  background: transparent;
  font-size: ${FONT_SIZE.SMALL};
  font-weight: bold;
  color: ${COLOR.BLUE_MEDIUM};
  margin-bottom: 0;

  &:hover {
    color: ${COLOR.BLUE_DARK};
  }
`

const CloseIcon = styled.span`
  padding-left: 0.286em;
  color: ${COLOR.RED_MEDIUM};
`

export { MessageWrapper, Message, ActionTextButton, CloseIcon }
