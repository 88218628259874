const parseGetOrdersResponse = response => {
  const additionalOrderInformation = (response.included || []).reduce(
    (orderInformation, nextItem) => {
      const { id, attributes } = nextItem
      orderInformation[id] = { ...attributes }
      return orderInformation
    },
    {}
  )

  const orders = response.data.map(order => {
    const { id, attributes } = order
    const copy = { id, ...attributes }
    const linkedOrderKey = order?.relationships?.order?.data?.id || undefined
    const linkedOrder = additionalOrderInformation[linkedOrderKey]
    const waypoints = order.relationships.waypoints.data.map(
      ({ id }) => additionalOrderInformation[id]
    )

    copy.linkedOrder = linkedOrder
    copy.waypoints = waypoints
    return copy
  })

  return orders
}

const getDateWithOffset = (days = 0) =>
  new Date(new Date().setDate(new Date().getDate() + days))

export { parseGetOrdersResponse, getDateWithOffset }
