import { getCountry } from '../../../service/locale/country.service'

const getMapCenter = () => {
  const MapCenters = {
    HK: { lat: 22.278731, lng: 114.176109 },
    SG: { lat: 1.352083, lng: 103.819836 },
    TW: { lat: 23.511835, lng: 120.999817 },
    VN: { lat: 16.004639, lng: 106.715991 },
  }
  const country = getCountry()

  return MapCenters[country]
}

const getDefaultZoom = () => {
  const DefaultZoom = {
    HK: 11,
    SG: 11,
    TW: 8,
    VN: 6,
  }
  const country = getCountry()

  return DefaultZoom[country]
}

export { getMapCenter, getDefaultZoom }
