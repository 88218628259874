import * as types from './types'

function setFilterStatus(value) {
  return {
    type: types.SET_FILTER_STATUS,
    value,
  }
}

function setFilterDate(value) {
  return {
    type: types.SET_FILTER_DATE,
    value,
  }
}

function setFilterBranch(value) {
  return {
    type: types.SET_FILTER_BRANCH,
    value,
  }
}

function setOrderSearchValue(value) {
  return {
    type: types.SET_ORDER_SEARCH_VALUE,
    value,
  }
}

function clearFilters() {
  return {
    type: types.CLEAR_FILTERS,
  }
}

export {
  setFilterStatus,
  setFilterDate,
  setFilterBranch,
  setOrderSearchValue,
  clearFilters,
}
