import styled from "styled-components"

import { COLOR } from "../../../component-library/design/color/color.constants"
import { SPACING } from "../../../component-library/design/spacing/spacing.constants"
import {
  Heading,
  Body,
} from "../../../component-library/design/typography/typography.styles"

import StarSelected from "../assets/star-selected.svg"
import StarUnselected from "../assets/star-unselected.svg"

const Wrapper = styled.div`
  padding-bottom: ${(props) =>
    props.isRated ? `${SPACING.XS}` : `${SPACING.M}`};
`

const StyledHeading = styled(Heading)`
  padding-right: 26px;
  margin-bottom: 0px;
`

const RatingWrapper = styled.div`
  display: flex;
`

const StyledBody = styled(Body)`
  color: ${COLOR.GREY_DARK};
  margin-right: ${SPACING.XS};
`

const starStyles = `
  width: 16px;
  height: 16px;
`

const StyledStarSelected = styled(StarSelected)`
  ${starStyles}
`

const StyledStarUnselected = styled(StarUnselected)`
  ${starStyles}
`

export {
  Wrapper,
  StyledHeading,
  RatingWrapper,
  StyledBody,
  StyledStarSelected,
  StyledStarUnselected,
}
