import styled from "styled-components"

import { SPACING } from "ggx-componentlibrary/design/spacing/spacing.constants"

const DriverProfilePicture = styled.img`
  border-radius: 50%;
  width: 32px;
  height: 32px;
  margin-right: ${SPACING.S};
`

const DriverProfile = styled.div`
  display: flex;
  align-items: flex-start;
`

export { DriverProfilePicture, DriverProfile }
