import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Payment } from 'ggx-global/payment/payment.component'
import {
  StyledAddCardButton,
  PaymentWrapper,
  StyledHint,
} from './credit-card-group.component.styles'
import { getSavedCard } from '../../payment/duck/selectors'

const CreditCardGroup = ({ formWidth, hintText, className, metaError }) => {
  const [t] = useTranslation()
  const paymentWrapperRef = useRef(null)
  const savedCard = useSelector(state => getSavedCard(state))
  const [addCardInProgress, setAddCardInProgress] = useState(false)

  const submitCard = () => {
    setAddCardInProgress(true)
    paymentWrapperRef.current
      .querySelector('.credit-card-hidden-button')
      .click()
  }

  const enableButton = () => {
    setAddCardInProgress(false)
  }

  return (
    <div className={className}>
      <PaymentWrapper
        ref={paymentWrapperRef}
        formWidth={formWidth}
        savedCard={savedCard}
      >
        <Payment metaError={metaError} onCardSaved={enableButton} />
        {!savedCard && (
          <StyledAddCardButton
            type="button"
            isLoading={addCardInProgress}
            disabled={addCardInProgress}
            onClick={submitCard}
            data-cy="add-card-btn"
          >
            {t('creditCardGroup.button.addCard')}
          </StyledAddCardButton>
        )}
      </PaymentWrapper>
      {hintText && !savedCard && <StyledHint>{hintText}</StyledHint>}
    </div>
  )
}

CreditCardGroup.propTypes = {
  formWidth: PropTypes.string,
  hintText: PropTypes.string,
  className: PropTypes.string,
  metaError: PropTypes.shape({
    code: PropTypes.string,
  }),
}

export { CreditCardGroup }
