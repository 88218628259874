import React, { useEffect, useState, useMemo, memo } from 'react'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import pDebounce from 'p-debounce'
import Popper from '@mui/material/Popper'

import MuiAutoComplete, {
  CATEGORY,
} from 'ggx-componentlibrary/components/autocomplete/mui-autocomplete/mui-autocomplete.component'
import { OPTION_TYPE } from 'ggx-componentlibrary/components/autocomplete/mui-autocomplete/Options/options.component'
import { Dropdown } from 'ggx-componentlibrary/components/dropdown/dropdown.component'
import { adminService } from 'ggx-global/api/api.service'

import translate from '../../legacy/locales/translate'
import {
  Container,
  Label,
  FieldLabel,
  ApplyButton,
} from './organization-switch.component.styles'

const formatOrganizationOptions = (organizations = []) => {
  return organizations.map(organization => {
    return {
      groupType: OPTION_TYPE.ORGANIZATION,
      label: organization.name,
      value: organization,
    }
  })
}

const formatBranchOptions = (branches = []) => {
  return branches.map(branch => ({
    label: branch.name,
    value: branch.id,
  }))
}

const OrganizationSwitch = memo(() => {
  const currentUser = useSelector(state => state.session.currentUser)
  const branches = useSelector(state => state.organization.branches)
  const [organizations, setOrganizations] = useState([])
  const [selectedOrganiztion, setSelectedOrganiztion] = useState(
    currentUser.organization
  )
  const [
    branchesOfSelectedOrganiztion,
    setBranchesOfSelectedOrganiztion,
  ] = useState([])
  const [selectedBranchId, setSelectedBranchId] = useState(
    String(currentUser.branch_id)
  )
  const debouncedGetOrganizations = useMemo(
    () => pDebounce(adminService.getOrganizations, 300),
    []
  )
  /**
   * We need this customPopperComponent due to default Popper component couldn't be rendered in the right position.
   * Possible reason for this situation is that we use position: fixed for positioning this component, we might need to refactor it later.
   * https://github.com/gogovan/b2b-website/blob/f56a64c00971ddbbbe9e63f034e23e366003616e/client/src/global/navigation/footer-menu/footer-menu.component.styles.js#L143-L156
   */
  const customPopperComponent = useMemo(() => {
    return ({ style, ...props }) => {
      return (
        <Popper
          {...props}
          modifiers={[
            {
              name: 'computeStyles',
              options: {
                roundOffsets: ({ x, y }) => {
                  return {
                    x: 16, // padding-left of Container
                    y: y,
                  }
                },
              },
            },
          ]}
        />
      )
    }
  }, [])

  const handleQuery = searchText => {
    return debouncedGetOrganizations(searchText)
      .then(organizations => {
        return formatOrganizationOptions(organizations)
      })
      .catch(err => {
        console.warn(
          'OrganizationSwitch getOrganizations failed => searchText: ',
          searchText
        )
        console.warn('OrganizationSwitch getOrganizations failed => err: ', err)
      })
  }

  const handleChange = ({ value }) => {
    setSelectedOrganiztion(value)
    setBranchesOfSelectedOrganiztion(value.branches || [])
  }

  const handleBranchChange = branch => {
    setSelectedBranchId(branch.value)
  }

  const handleSubmit = async e => {
    e.preventDefault()

    if (selectedOrganiztion.id && selectedBranchId) {
      try {
        await adminService.switchOrganizationAndBranch({
          orgId: selectedOrganiztion.id,
          branchId: selectedBranchId,
        })
        window.location.reload()
      } catch {
        console.log('failed to update organization')
      }
    }
  }

  useEffect(() => {
    debouncedGetOrganizations(currentUser.organization.name)
      .then(data => {
        setOrganizations(formatOrganizationOptions(data))
      })
      .catch(() => null)
  }, [])

  const selectedOption = {
    value: selectedOrganiztion.name,
    label: selectedOrganiztion.name,
  }

  const branchesOptions = formatBranchOptions(
    isEmpty(branchesOfSelectedOrganiztion)
      ? branches
      : branchesOfSelectedOrganiztion
  )

  return (
    <Container>
      <Label>{translate('organization.switch.label')}</Label>
      <FieldLabel>
        {translate('organization.switch.organizationLabel')}
      </FieldLabel>
      <MuiAutoComplete
        category={CATEGORY.GENERIC}
        onChange={handleChange}
        onQuery={handleQuery}
        selectedOption={selectedOption}
        defaultOptions={organizations}
        customPopperComponent={customPopperComponent}
        menuWidth={252}
        supportEditSelectedValue
      />
      <FieldLabel>{translate('organization.switch.branchLabel')}</FieldLabel>
      <Dropdown
        dataTestid="organiztion-branch-dropdown"
        defaultOptions={branchesOptions}
        onChange={handleBranchChange}
        value={selectedBranchId}
      />
      <ApplyButton
        buttonType="primary"
        onClick={handleSubmit}
        disabled={
          currentUser.organization.id === selectedOrganiztion.id &&
          currentUser.branch_id === selectedBranchId
        }
        data-cy="change_branch_btn"
      >
        {translate('organization.switch.apply')}
      </ApplyButton>
    </Container>
  )
})

export { OrganizationSwitch }
