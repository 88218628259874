import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation, Trans } from 'react-i18next'
import { useSelector } from 'react-redux'
import { displayAlert } from 'ggx-componentlibrary/components/alerts/alerts.component'

import {
  StyledModal,
  Title,
  BillingDescription,
  PaymentMethodTitle,
  StyledCreditCardGroup,
  StartSubscriptionButton,
  TermsAndCondition,
  SuccessText,
  StyledLink,
  ModalHeader,
  StyledTextLink,
  MaybeLaterButton,
} from './subscription-modal.component.styles'
import {
  Body,
  TextLink,
  Heading,
} from '../../component-library/design/typography/typography.styles'
import { Button } from '../../component-library/components/button/button.component'
import { getSavedCard } from '../../payment/duck/selectors'
import {
  getIsFreeTrial,
  getEndDate,
  getStartDate,
  getState,
} from '../duck/selectors'
import { subscriptionService } from '../api/api.service'
import { formatDateTime } from '../../service/date-time/format-date-time'
import BusinessProLogoUrl from './assets/gogobusinesspro-logo.file.svg'
import { SUBSCRIPTION_STATE } from '../paid-subscription-plan.constants'

import UpgradeIcon from './assets/upgrade-icon.svg'

const SubscriptionModal = ({ onRequestClose }) => {
  const [t] = useTranslation()
  const savedCard = useSelector(state => getSavedCard(state))
  const isFreeTrial = useSelector(state => getIsFreeTrial(state))
  const subscriptionEndDate = useSelector(state => getEndDate(state))
  const subscriptionStartDate = useSelector(state => getStartDate(state))
  const [subscriptionInProgress, setSubscriptionInProgress] = useState(false)
  const [maybeLaterInProgress, setMaybeLaterInProgress] = useState(false)
  const subscriptionPeriod =
    new Date(subscriptionEndDate).getMonth() -
    new Date(subscriptionStartDate).getMonth()
  const subscriptionState = useSelector(state => getState(state))

  const VIEW_FORM = 'view_form'
  const VIEW_SUCCESS = 'view_success'
  const [view, setView] = useState(VIEW_FORM)
  const [wasFreeTrial, setWasFreeTrial] = useState(false)
  const isFreeTrialExpired =
    subscriptionState === SUBSCRIPTION_STATE.FREE_TRIAL_EXPIRED

  React.useEffect(() => {
    if (
      subscriptionState === SUBSCRIPTION_STATE.ACTIVE &&
      subscriptionInProgress
    ) {
      setView(VIEW_SUCCESS)
      setSubscriptionInProgress(false)
    }
  }, [subscriptionInProgress, subscriptionState])

  React.useEffect(() => {
    if (isFreeTrial && !wasFreeTrial) {
      setWasFreeTrial(true)
    }
  }, [isFreeTrial, wasFreeTrial])

  const handleSubscriptionButton = async () => {
    setSubscriptionInProgress(true)
    try {
      await subscriptionService.register({ tier: 'pro' })
    } catch {
      setSubscriptionInProgress(false)
      displayAlert({ message: t('errors.generic'), type: 'error' })
    }
  }

  const handleMaybeLaterButton = async () => {
    setMaybeLaterInProgress(true)
    try {
      await subscriptionService.confirmFreeTrialExpired()
      onRequestClose()
    } catch {
      setMaybeLaterInProgress(false)
      displayAlert({ message: t('errors.generic'), type: 'error' })
    }
  }

  const getModalProps = currentView => {
    const modalProps = {
      onRequestClose,
      header: false,
      height: '680px',
      showCloseButton: !isFreeTrialExpired,
    }

    if (currentView === VIEW_SUCCESS) {
      return {
        ...modalProps,
        showCloseButton: false,
        height: undefined,
      }
    }

    return modalProps
  }

  const { protocol, hostname, port } = window.location
  const protocolHostname = `${protocol}//${hostname}${port ? `:${port}` : ''}`
  const svgUrl = protocolHostname + BusinessProLogoUrl

  return (
    <StyledModal {...getModalProps(view)}>
      <ModalHeader>
        <img src={svgUrl} alt="Logo" data-testid="business-pro-logo" />
      </ModalHeader>
      {view === VIEW_FORM && !isFreeTrialExpired && (
        <>
          <Title as="p">{t('text__heading__value_proposition')}</Title>
          {isFreeTrial && (
            <>
              <Body typographyStyles="mb-none">
                {t('text__enjoying_months_free_trial', {
                  count: subscriptionPeriod,
                })}
              </Body>
              <Body typographyStyles="c-grey_dark">
                {t('text__subscribe_now_start_paying_after', {
                  date: formatDateTime(subscriptionEndDate, 'D MMM, YYYY'),
                })}
              </Body>
            </>
          )}
          <Body noMargin>{t('text__subscription_fee_frequency')}</Body>
          <BillingDescription>
            {t('text__recurring_billing_cancel_anytime')}
          </BillingDescription>
          <PaymentMethodTitle>{t('common__payment_method')}</PaymentMethodTitle>
          <Body>{t('common__credit_card')}</Body>
          <StyledCreditCardGroup />
          <StartSubscriptionButton
            buttonType="warning"
            onClick={handleSubscriptionButton}
            isLoading={subscriptionInProgress}
            size="large"
            disabled={!savedCard}
          >
            {t('button__start_subscription')}
          </StartSubscriptionButton>
          <TermsAndCondition>
            <Trans
              i18nKey="text__subscription_modal__terms_and_conditions"
              components={[
                <TextLink target="_blank" href={t('b2clink.legal')} />,
                <TextLink target="_blank" href={t('b2clink.privacy')} />,
              ]}
            />
          </TermsAndCondition>
        </>
      )}
      {view === VIEW_FORM && isFreeTrialExpired && (
        <>
          <Title as="p" typographyStyles="mb-xs">
            {t('text__free_trial_expired_ready_to_subscribe')}
          </Title>

          <Heading
            as={StyledTextLink}
            typographyStyles="mb-none"
            target="_blank"
            href="/pro"
          >
            {t('text_link__pro_subscription_benefits')}
            <UpgradeIcon />
          </Heading>

          <Body noMargin typographyStyles="mt-s">
            {t('text__subscription_fee_frequency')}
          </Body>
          <BillingDescription>
            {t('text__recurring_billing_cancel_anytime')}
          </BillingDescription>
          <PaymentMethodTitle>{t('common__payment_method')}</PaymentMethodTitle>
          <Body>{t('common__credit_card')}</Body>
          <StyledCreditCardGroup />
          <StartSubscriptionButton
            isFreeTrialExpired={isFreeTrialExpired}
            buttonType="warning"
            onClick={handleSubscriptionButton}
            isLoading={subscriptionInProgress}
            size="large"
            disabled={!savedCard || maybeLaterInProgress}
          >
            {t('button__start_subscription')}
          </StartSubscriptionButton>
          <MaybeLaterButton
            buttonType="secondary"
            onClick={handleMaybeLaterButton}
            isLoading={maybeLaterInProgress}
            size="large"
            disabled={subscriptionInProgress || maybeLaterInProgress}
          >
            {t('btn__maybe_later')}
          </MaybeLaterButton>
          <TermsAndCondition>
            <Trans
              i18nKey="text__subscription_modal__terms_and_conditions"
              components={[
                <TextLink target="_blank" href={t('b2clink.legal')} />,
                <TextLink target="_blank" href={t('b2clink.privacy')} />,
              ]}
            />
          </TermsAndCondition>
        </>
      )}
      {view === VIEW_SUCCESS && wasFreeTrial && (
        <>
          <Title as="p">
            {t('header__extended_subscription_during_free-trial')}
          </Title>
          <SuccessText>
            {t('body__extended_subscription_during_free-trial', {
              date: formatDateTime(subscriptionEndDate, 'D MMM, YYYY'),
            })}
          </SuccessText>
          <Button
            buttonType="warning"
            size="large"
            width="100%"
            as={StyledLink}
            to="/home"
          >
            {t('button_done')}
          </Button>
        </>
      )}
      {view === VIEW_SUCCESS && !wasFreeTrial && (
        <>
          <Title as="p">{t('text__welcome_membership')}</Title>
          <SuccessText>
            {t('text__description__welcome_membership')}
          </SuccessText>
          <Button
            buttonType="warning"
            size="large"
            width="100%"
            as={StyledLink}
            to="/home"
          >
            {t('button__get_started')}
          </Button>
        </>
      )}
    </StyledModal>
  )
}

SubscriptionModal.propTypes = {
  onRequestClose: PropTypes.func,
}

export { SubscriptionModal }
