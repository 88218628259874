// TODO: actually I don't know why this is called organizations.js as there's
// always only one organization.
import _memoize from 'lodash/memoize'
import Constants from '../constants/action'
import NoticeActions from './notice'
import { api } from '../utils/api'
import { setTempProfilePhoto } from '../views/helpers'

function _setOrganizationError({ errorMessage, data, status, statusText }) {
  return {
    type: Constants.SET_ERROR_MESSAGE,
    data,
    errorMessage,
    status,
    statusText,
  }
}

const _getOrganizationPromise = _memoize(
  function(user, dispatch) {
    return api
      .request('get', `b2b/organizations/${user.organization.id}`, dispatch)
      .then(response => {
        dispatch({
          type: Constants.SET_ORGANIZATION,
          organization: response.data,
        })
      })
      .catch(err => {
        dispatch(
          _setOrganizationError({ ...err.response, errorMessage: err.message })
        )
      })
  },
  () => Math.floor(Date.now() / 1000)
)

function _getRegionsPromise(dispatch) {
  return api.get('b2b/regions', dispatch).then(response => {
    dispatch({
      type: Constants.SET_REGIONS,
      regions: response.data.map(r =>
        Object.assign(r, { description: r.name, value: r.id })
      ),
    })
  })
}

const Actions = {
  getAllOrganizationInfo: user => dispatch => {
    dispatch(NoticeActions.showLoading())
    Promise.all([
      _getOrganizationPromise(user, dispatch),
      _getRegionsPromise(dispatch),
    ]).then(
      () => {
        dispatch(NoticeActions.hideLoading())
      },
      () => {
        dispatch(NoticeActions.hideLoading())
      }
    )
  },

  getOrganization: user => dispatch => {
    _getOrganizationPromise(user, dispatch)
  },

  // TODO: similar to user's uploadProfilePhoto
  uploadProfilePhoto: (organizationId, file, src) => dispatch => {
    const formData = new FormData()
    formData.append('id', organizationId)
    formData.append('file', file)
    formData.append('type', 0)

    api
      .uploadFile(
        'post',
        `b2b/organizations/${organizationId}/upload_image`,
        formData,
        file.type,
        dispatch
      )
      .then(response => {
        const data = {
          organization: { profile_photo_image_id: response.data.id },
        }
        api.request(
          'put',
          `b2b/organizations/${organizationId}`,
          dispatch,
          data
        )
      })
      .then(() => {
        setTempProfilePhoto('organization', src)
      })
      .catch(err => {
        dispatch(
          _setOrganizationError({ ...err.response, errorMessage: err.message })
        )
      })
  },

  getRegions: () => dispatch => {
    _getRegionsPromise(dispatch)
  },

  resetOrganization: () => dispatch => {
    dispatch({ type: Constants.RESET_ORGANIZATION })
  },
}

export default Actions
