import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  VehiclePictureInDetail,
  ListContainer,
  DetailHR,
  getVoucherPicture,
} from './voucher.component.styles'
import {
  Body,
  BodyBold,
} from 'ggx-componentlibrary/design/typography/typography.styles'

import { formatDateTime } from 'ggx-service/date-time/format-date-time'
import { Modal } from 'ggx-componentlibrary/components/modal/modal.component'
import { Button } from 'ggx-componentlibrary/components/button/button.component'
import { isHongKong } from 'ggx-src/legacy/utils/locale'

const SERVICE_TYPES = {
  TRANSPORT: 'transport',
  DELIVERY: 'delivery',
}

const VoucherCardModal = ({
  title,
  serviceTypes,
  vehicleTypesRule,
  minOrderSpent,
  expiredAt,
  remaining,
  total,
  code: couponcode,
  remark,
  onModalClose,
}) => {
  const [t] = useTranslation()
  return (
    <Modal
      header={title}
      height="516px"
      footer={
        <Button buttonType="primary" size="small" onClick={onModalClose}>
          {t('button__back')}
        </Button>
      }
      onRequestClose={onModalClose}
    >
      <div>
        <VehiclePictureInDetail
          src={getVoucherPicture(serviceTypes, vehicleTypesRule)}
        />
        <BodyBold typographyStyles="mb-S">{t('text__details')}</BodyBold>
        <ListContainer>
          {isHongKong() && (
            <Body as="li" noMargin>
              {serviceTypes.length === 2 &&
                t('text__coupon_detail_both_eligible')}
              {serviceTypes.length === 1 &&
                serviceTypes[0] === SERVICE_TYPES.TRANSPORT &&
                t('text__coupon_transport_only')}
              {serviceTypes.length === 1 &&
                serviceTypes[0] === SERVICE_TYPES.DELIVERY &&
                t('text__coupon_delivery_only')}
            </Body>
          )}
          <Body as="li" noMargin>
            {minOrderSpent === 0
              ? t('data__any_order_amount')
              : t('data__min_currency_number_spend', { amount: minOrderSpent })}
          </Body>
          <Body as="li" noMargin>
            {t('data__valid_until_date', {
              date: formatDateTime(
                expiredAt,
                t('common__timestamp__dd_mmm_yyyy'),
                false
              ),
            })}
          </Body>
          <Body as="li" noMargin>
            {t('data__n_out_of_n_used', { used: total - remaining, total })}
          </Body>
          <Body as="li" noMargin>
            {t('data__code_coupon', { couponcode })}
          </Body>
        </ListContainer>
      </div>
      <DetailHR />
      <Body noMargin typographyStyles="c-grey_darker">
        {remark}
      </Body>
    </Modal>
  )
}

VoucherCardModal.propTypes = {
  title: PropTypes.string,
  serviceTypes: PropTypes.arrayOf(PropTypes.string),
  vehicleTypesRule: PropTypes.arrayOf(PropTypes.string),
  minOrderSpent: PropTypes.number,
  expiredAt: PropTypes.number,
  remaining: PropTypes.number,
  total: PropTypes.number,
  code: PropTypes.string,
  remark: PropTypes.string,
  onModalClose: PropTypes.func,
}

export { VoucherCardModal }
