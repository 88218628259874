const snakeCaseToCamelCase = featureflag =>
  featureflag
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join('')

const getReduxFeatureFlagName = featureflag =>
  `featureFlag${snakeCaseToCamelCase(featureflag)}`

export { getReduxFeatureFlagName }
