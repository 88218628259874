import styled, { keyframes } from "styled-components"

import { SPACING } from "ggx-componentlibrary/design/spacing/spacing.constants"
import { COLOR } from "ggx-componentlibrary/design/color/color.constants"
import { Modal } from "ggx-componentlibrary/components/modal/modal.component"
import {
  Content,
  Footer,
} from "ggx-componentlibrary/components/modal/modal.component.styles"

const fadeIn = keyframes`
  0% { 
    opacity: 0; 
  }
  100% { 
    opacity: 1; 
  }
`

const PositionedWrapper = styled.div`
  position: relative;
`

const StyledModal = styled(Modal)`
  animation: ${fadeIn} 0.5s;
  box-shadow: 0px 4px 10px rgba(0, 18, 26, 0.09),
    0px 8px 24px rgba(0, 18, 26, 0.06), 0px 3px 30px rgba(0, 18, 26, 0.05);
  border-radius: 8px;

  ${Content} {
    margin-top: 0;
    flex: auto;
  }
  ${Footer} {
    padding: 0 ${SPACING.M} ${SPACING.M};
    border-top: none;
  }
  min-height: 0;
`

const Chevron = styled.div`
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-right: 24px solid ${COLOR.WHITE};
  position: absolute;
  top: ${(props) =>
    props.$chevronTopOffset ? props.$chevronTopOffset : "16px"};
  left: -18px;
`

export { StyledModal, Chevron, PositionedWrapper }
