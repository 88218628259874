import styled from "styled-components"
import ReactModal from "react-modal"

import { HeadingXL } from "../../design/typography/typography.styles"
import { SPACING } from "../../design/spacing/spacing.constants"
import { FONT_SIZE } from "../../design/typography/typography.constants"
import { COLOR } from "../../design/color/color.constants"
import CrossIcon from "./assets/modal-cross.svg"
import { StyledButton } from "../button/button.component.styles"

const MODAL_WIDTH = "620px"
const MODAL_BREAKPOINT = MODAL_WIDTH
const MODAL_MAX_HEIGHT = "610px"
const MODAL_MIN_HEIGHT = "215px"

const Header = styled.div`
  position: relative;
  padding: ${SPACING.S} ${SPACING.XL} ${SPACING.S} ${SPACING.M};
  border-bottom: 1px solid ${COLOR.GREY_LIGHT};
`

const StyledTitle = styled(HeadingXL)`
  font-size: ${FONT_SIZE.XLARGE};
  margin: 0;
`

const StyledCrossIcon = styled(CrossIcon)`
  position: absolute;
  top: 18px;
  right: ${SPACING.S};
  cursor: pointer;
  :focus {
    border: 1px solid ${COLOR.BLUE_LIGHT};
  }
`

const Content = styled.div`
  overflow-y: auto;
  padding: ${SPACING.M};
  flex: 1;
  margin-top: ${(props) => (props.showHeader ? "0px" : "52px")};
  position: relative;
`

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: ${SPACING.S} ${SPACING.M} ${SPACING.S} 0;
  border-top: 1px solid ${COLOR.GREY_LIGHT};

  ${StyledButton} {
    margin-right: ${SPACING.S};
    :last-of-type {
      margin-right: 0;
    }
  }
`

const ReactModalAdapter = ({ className, ...props }) => {
  const contentClassName = `${className}__content`
  const overlayClassName = `${className}__overlay`

  return (
    <ReactModal
      portalClassName={className}
      className={contentClassName}
      overlayClassName={overlayClassName}
      {...props}
    />
  )
}

const Wrapper = styled.div`
  position: absolute;
  width: 100vw;
  height: 100%;
  background: ${COLOR.WHITE};
  border: 1px solid ${COLOR.GREY_LIGHT};
  -webkit-overflow-scrolling: touch;
  border-radius: 4px;
  outline: none;
  padding: 0;

  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  @media (min-width: ${MODAL_BREAKPOINT}) {
    height: ${(props) => props?.$height || "fit-content"};
    width: ${(props) => props?.$width || MODAL_WIDTH};
    top: ${(props) => props?.$modalPosition?.top || "50%"};
    left: ${(props) => props?.$modalPosition?.left || "50%"};
    right: initial;
    bottom: initial;
    transform: translate(-50%, -50%);
    min-height: ${(props) => props?.$minHeight || MODAL_MIN_HEIGHT};
    max-height: ${(props) => props?.$maxHeight || props?.$height || MODAL_MAX_HEIGHT};
  }
`

const getHighlightedAreas = (highlights) => {
  let highlightedAreaPath = ""

  for (let i = 0; i < highlights.length; i += 1) {
    highlightedAreaPath += `
      ${highlights[i].x} ${highlights[i].y},
      calc(${highlights[i].x} + ${highlights[i].width}) ${highlights[i].y},
      calc(${highlights[i].x} + ${highlights[i].width}) calc(${highlights[i].y} + ${highlights[i].height}),
      ${highlights[i].x} calc(${highlights[i].y} + ${highlights[i].height}),
      ${highlights[i].x} ${highlights[i].y}
    `

    if (i !== highlights.length - 1) {
      highlightedAreaPath += `,
        0 0,
      `
    }
  }

  return highlightedAreaPath
}

const StyledReactModal = styled(ReactModalAdapter)`
  &__content {
    /* Leaving here in case its useful
       but we prefer to style the exported Content / Footer / Header components
    */
  }

  ${(props) =>
    props.$highlights &&
    `
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      background-color: transparent;
      z-index: 998;
    }
  `}

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 18, 26, 0.38);
    z-index: 999;

    ${(props) =>
      props.$highlights &&
      `
      clip-path: polygon(evenodd,
        0 0,
        100% 0,
        100% 100%,
        0% 100%,
        0 0,
        ${getHighlightedAreas(props.$highlights)}
      );
    `}
    ${props => props.overlayClass}
  }
`

export {
  Header,
  StyledTitle,
  Footer,
  Content,
  StyledCrossIcon,
  MODAL_BREAKPOINT,
  StyledReactModal,
  Wrapper,
}
