import React from 'react'
import PropTypes from 'prop-types'

import { StyledSelectionCellCheckbox } from './table.component.styles'

const SelectionCell = ({ onSelect, selected }) => (
  <React.Fragment>
    {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
    <span onClick={event => event.stopPropagation()}>
      <StyledSelectionCellCheckbox onChange={onSelect} value={selected} />
    </span>
  </React.Fragment>
)

SelectionCell.propTypes = {
  onSelect: PropTypes.func,
  selected: PropTypes.bool,
}

export { SelectionCell }
