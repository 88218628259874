import * as types from './types'

const initialState = false

function selfRegistrationReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.FEATUREFLAG_SELF_REGISTRATION:
      return action.value || initialState

    default:
      return state
  }
}

const selfRegistrationReducers = {
  featureFlagSelfRegistration: selfRegistrationReducer,
}

export { selfRegistrationReducers }
