import Select from 'react-select'
import PropTypes from 'prop-types'
import {
  StyledMenu,
  StyledOption,
  StyledControl,
  StyledValueContainer,
  StyledSingleValue,
  StyledPlaceholder,
  StyledDropdownIndicator,
  NoOptionsMessage,
  ClearIndicator,
  IndicatorSeparator,
  StyledError,
} from './dropdown.component.styles'

const components = {
  Menu: StyledMenu,
  Option: StyledOption,
  Control: StyledControl,
  ValueContainer: StyledValueContainer,
  SingleValue: StyledSingleValue,
  Placeholder: StyledPlaceholder,
  DropdownIndicator: StyledDropdownIndicator,
  NoOptionsMessage,
  ClearIndicator,
  IndicatorSeparator,
}

const Dropdown = ({
  id,
  name,
  className,
  placeholder,
  defaultOptions,
  value,
  onChange,
  onBlur,
  disabled,
  meta = {},
  dataTestid,
  maxHeight,
  isButtonDropdown,
}) => {
  const wrappedOnChange =
    typeof onChange === 'function'
      ? selected => onChange(selected || null)
      : null

  /**
   * Redux-Form expects the value to be passed-through when onBlur is called
   * @see https://github.com/erikras/redux-form/issues/2768
   */
  const wrappedOnBlur =
    typeof onBlur === 'function' ? () => onBlur(value) : null

  const error = meta.touched && meta.error

  let persistedOption
  if (typeof value === 'string' || typeof value === 'number') {
    persistedOption = defaultOptions.filter(option => option.value === value)[0]
  }

  return (
    <div data-testid={dataTestid} className={className}>
      <Select
        id={id}
        name={name}
        options={defaultOptions}
        value={persistedOption || value}
        placeholder={placeholder}
        onChange={wrappedOnChange}
        onBlur={wrappedOnBlur}
        components={components}
        isSearchable={false}
        isDisabled={disabled}
        error={error}
        maxMenuHeight={maxHeight}
        blurInputOnSelect={false}
        controlShouldRenderValue={!isButtonDropdown}
      />
      {error && <StyledError>{meta.error}</StyledError>}
    </div>
  )
}

Dropdown.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  maxHeight: PropTypes.number,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
  ]),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  defaultOptions: PropTypes.array,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
  dataTestid: PropTypes.string.isRequired,
  isButtonDropdown: PropTypes.bool,
}

Dropdown.defaultProps = {
  disabled: false,
  meta: {},
}

Dropdown.displayName = 'Dropdown'

export { Dropdown }
