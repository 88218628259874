import React from 'react'
import Button from '@mui/material/Button'

import getCurrency from '../../../locales/get_currency'
import translate from '../../../locales/translate'

import {
  BalanceContainer,
  DepositSection,
  DownloadIcon,
  DownloadReportContainer,
} from './balance.component.styles'

const translateWithPrefix = (key, options) =>
  translate(`wallet.view.${key}`, options)

const Balance = ({ organization, handleDownloadReport }) => {
  if (!organization) return null
  const { name, deposit_balance, credit_balance } = organization

  return (
    <BalanceContainer>
      <div>
        <p>{translateWithPrefix('companyName')}</p>
        <p>{name}</p>

        <DepositSection
          heading={translateWithPrefix('deposit')}
          content={translateWithPrefix('depositInfo')}
          contentWidth="250px"
        />
        <p>{getCurrency({ amount: deposit_balance })}</p>
        <p>{translateWithPrefix('balance')}</p>
        <p>{getCurrency({ amount: credit_balance })}</p>
      </div>
      <DownloadReportContainer>
        <Button variant="outlined" onMouseDown={handleDownloadReport}>
          <DownloadIcon imgPath="general/icon_export" />
          {translateWithPrefix('downloadReport')}
        </Button>
      </DownloadReportContainer>
    </BalanceContainer>
  )
}

export default Balance
