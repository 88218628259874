import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Rating } from './rating/rating.component'
import {
  Wrapper,
  StyledTextArea,
  ButtonsWrapper,
  StyledButton,
} from './driver-rating.component.styles'
import { Heading } from './../../../component-library/design/typography/typography.styles'
import { orderService } from '../../../van-booking/api/order-api.service'
import { displayAlert } from '../../../component-library/components/alerts/alerts.component'
import DriverRatingOptions from './options/options.component'

const CLIENT_FEEDBACK_TYPE = {
  driving: 1,
  navigation: 2,
  politeness: 3,
  punctuality: 4,
  vehicle_quality: 5,
  charges: 6,
  incorrect_driver_info: 7,
  helpfulness: 8,
  cool_music: 9,
  other: 10,
}

const CLIENT_BAD_FEEDBACK_OPTIONS = {
  [CLIENT_FEEDBACK_TYPE.driving]: 'text__driver_feedback__driving',
  [CLIENT_FEEDBACK_TYPE.navigation]: 'text__driver_feedback__navigation',
  [CLIENT_FEEDBACK_TYPE.politeness]:
    'chip__driver_feedback__politeness__non_5_star',
  [CLIENT_FEEDBACK_TYPE.punctuality]:
    'chip__driver_feedback__punctuality__non_5_star',
  [CLIENT_FEEDBACK_TYPE.vehicle_quality]:
    'text__driver_feedback__vehicle_quality',
  [CLIENT_FEEDBACK_TYPE.charges]: 'text__driver_feedback__charges',
  [CLIENT_FEEDBACK_TYPE.incorrect_driver_info]:
    'text__driver_feedback__incorrect_license_plate',
  [CLIENT_FEEDBACK_TYPE.other]: 'common__other',
}

const CLIENT_GOOD_FEEDBACK_OPTIONS = {
  [CLIENT_FEEDBACK_TYPE.driving]: 'text__driver_feedback__driving',
  [CLIENT_FEEDBACK_TYPE.navigation]: 'text__driver_feedback__navigation',
  [CLIENT_FEEDBACK_TYPE.politeness]: 'text__driver_feedback__politeness',
  [CLIENT_FEEDBACK_TYPE.punctuality]: 'text__driver_feedback__punctuality',
  [CLIENT_FEEDBACK_TYPE.vehicle_quality]:
    'text__driver_feedback__vehicle_quality',
  [CLIENT_FEEDBACK_TYPE.helpfulness]: 'text__driver_feedback__helpfulness',
  [CLIENT_FEEDBACK_TYPE.cool_music]: 'text__driver_feedback__cool_music',
  [CLIENT_FEEDBACK_TYPE.other]: 'common__other',
}

const DriverRating = ({ orderId, name, onRatingSuccess }) => {
  const [t] = useTranslation()
  const [ratingSelected, setRatingSelected] = useState(null)
  const [feedback, setFeedback] = useState(null)
  const [selectedOptions, setSelectedOptions] = React.useState([])

  const buttonSendOnClickHandler = async () => {
    try {
      await orderService.rateDriver({
        idOrderRequest: orderId,
        feedbackTypeCds: selectedOptions,
        driverRating: ratingSelected,
        clientFeedback: feedback,
      })

      displayAlert({ message: t('notification__order_rated'), autoClose: true })
      onRatingSuccess()
    } catch (error) {
      displayAlert({
        message: t('errors.generic'),
        autoClose: true,
        type: 'error',
      })
    }
  }

  const handleChange = event => {
    const nameToNum = Number(event.target.name)

    if (event.target.checked) {
      if (selectedOptions.includes(nameToNum)) return

      setSelectedOptions([...selectedOptions, nameToNum])
    } else {
      setSelectedOptions(
        selectedOptions.filter(feedback => feedback !== nameToNum)
      )
    }
  }

  const cancelButtonOnClickHandler = () => {
    setRatingSelected(null)
    setSelectedOptions([])
  }

  const handleRatingSelected = rating => {
    if (rating === 5) {
      setSelectedOptions(
        selectedOptions.filter(
          feedback =>
            ![
              CLIENT_FEEDBACK_TYPE.charges,
              CLIENT_FEEDBACK_TYPE.incorrect_driver_info,
            ].includes(feedback)
        )
      )
    } else {
      setSelectedOptions(
        selectedOptions.filter(
          feedback =>
            ![
              CLIENT_FEEDBACK_TYPE.helpfulness,
              CLIENT_FEEDBACK_TYPE.cool_music,
            ].includes(feedback)
        )
      )
    }

    setRatingSelected(rating)
  }

  return (
    <Wrapper>
      <Heading noMargin>
        {t('text__please_rate_driver_service', { name })}
      </Heading>
      <Rating
        initialRating={ratingSelected}
        onRatingClick={handleRatingSelected}
      />
      {ratingSelected && (
        <>
          <DriverRatingOptions
            ratingSelected={ratingSelected}
            selectedOptions={selectedOptions}
            handleChange={handleChange}
          />
          <StyledTextArea
            rows="2"
            placeholder={
              ratingSelected === 5
                ? t('input__share_more_compliments_with_your_driver')
                : t('text_field__tell_us_more_of_what_happened')
            }
            onChange={value => setFeedback(value)}
          />
          <ButtonsWrapper>
            <StyledButton
              onClick={cancelButtonOnClickHandler}
              buttonType="secondary"
            >
              {t('common__cancel')}
            </StyledButton>
            <StyledButton onClick={buttonSendOnClickHandler}>
              {t('btn__send')}
            </StyledButton>
          </ButtonsWrapper>
        </>
      )}
    </Wrapper>
  )
}

DriverRating.propTypes = {
  orderId: PropTypes.string,
  name: PropTypes.string,
  onRatingSuccess: PropTypes.func,
}

export {
  DriverRating,
  CLIENT_FEEDBACK_TYPE,
  CLIENT_BAD_FEEDBACK_OPTIONS,
  CLIENT_GOOD_FEEDBACK_OPTIONS,
}
