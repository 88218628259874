import PropTypes from 'prop-types'
import React from 'react'

import {
  RadioButton,
  RadioButtonWrapper,
  StyledSmallText,
  StyledHeading,
} from './radio-button.component.styles'

import { StyledError } from '../text-field/text-field.component.styles'

RadioButton.propTypes = {
  dataTestid: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
}

RadioButton.displayName = 'RadioButton'

class RadioButtonGroup extends React.Component {
  static propTypes = {
    dataTestid: PropTypes.string,
    className: PropTypes.string,
    value: PropTypes.string,
    onSelectionChange: PropTypes.func,
    titleId: PropTypes.string,
    titleText: PropTypes.string,
    children: PropTypes.node.isRequired,
    meta: PropTypes.shape({
      error: PropTypes.string,
      touched: PropTypes.bool,
    }),
  }

  static defaultProps = {
    meta: { error: '', touched: false },
  }

  constructor(props) {
    super(props)
    this.state = {
      selectedValue: this.props.value,
    }
  }

  componentDidMount() {
    if (!this.props.titleId && !this.props.titleText) {
      throw new Error('Missing title prop: titleId or titleText needed')
    } else if (this.props.titleId && this.props.titleText) {
      throw new Error('Radio button group should only have one title prop')
    }
  }

  static getDerivedStateFromProps(props) {
    return { selectedValue: props.value }
  }

  handleSelection = value => {
    this.setState({
      selectedValue: value,
    })
    this.props.onSelectionChange(value)
  }

  handleSpaceKeyPress = (event, value) => {
    if (event.key === ' ') {
      event.preventDefault()
      this.handleSelection(value)
    }
  }

  _wrapRadioComponent = radio => {
    const { value, helperText, children, disabled } = radio.props
    const onClickWrapper = !disabled ? () => this.handleSelection(value) : null
    const textContent = Array.isArray(children)
      ? children.filter(el => typeof el === 'string')
      : children

    return (
      <RadioButtonWrapper
        key={value}
        onClick={onClickWrapper}
        onKeyPress={event => this.handleSpaceKeyPress(event, value)}
        role="radio"
        aria-checked={this.state.selectedValue === value}
        aria-label={helperText ? `${textContent} ${helperText}` : textContent}
        tabIndex={disabled ? '-1' : '0'}
        disabled={disabled}
        aria-disabled={disabled}
        data-testid={`radio_button_${value}`}
      >
        {React.cloneElement(radio, {
          'data-error-field': !!this.props.meta.error,
        })}
        {helperText && (
          <StyledSmallText aria-hidden>{helperText}</StyledSmallText>
        )}
      </RadioButtonWrapper>
    )
  }

  /**
   * Traverses through an array or tree of Elements and wraps any instances of RadioButton
   * via RadioButtonGroup.prototype._wrapRadioComponent
   */
  _replaceRadioComponentInTree = (componentTree, index) => {
    if (!componentTree) {
      return null
    }

    const { props } = componentTree
    const appendKey = typeof index === 'number' ? { key: index } : {}

    /**
     * The current Element is our RadioButton. We will now call _wrapRadioComponent to render the
     * required radio button behaviour. There is no need to process its children any further.
     */
    if (componentTree.type === RadioButton) {
      return this._wrapRadioComponent(componentTree)
    }

    /**
     * The componentTree is an array instead of a tree.
     * This usually happens when called from the render method by passing all of
     * the RadioButtonGroup's children into this method.
     */
    if (Array.isArray(componentTree)) {
      return componentTree.map(this._replaceRadioComponentInTree)
    }

    /**
     * The current Element contains multiple children.
     */
    if (Array.isArray(props && props.children)) {
      return React.cloneElement(
        componentTree,
        appendKey,
        props.children.map(this._replaceRadioComponentInTree)
      )
    }

    /**
     * The current Element contains only a single child.
     */
    if (props && props.children) {
      return React.cloneElement(
        componentTree,
        appendKey,
        this._replaceRadioComponentInTree(props.children)
      )
    }

    /**
     * The current object is likely a non-Element.
     */
    return componentTree
  }

  render() {
    const { titleId, titleText, className, dataTestid, meta } = this.props
    const error = meta.touched && meta.error

    return (
      <div data-testid={dataTestid} className={className}>
        {titleText && <StyledHeading>{titleText}</StyledHeading>}
        <div role="radiogroup" aria-label={titleText} aria-labelledby={titleId}>
          {this._replaceRadioComponentInTree(this.props.children)}
        </div>
        {error && <StyledError>{error}</StyledError>}
      </div>
    )
  }
}

export { RadioButton, RadioButtonGroup }
