import { Api } from '../../legacy/utils/api'

const deliveryApi = new Api(Api.BASE_URL.DP_C3)

const apiService = {
  verifyAddress: async ({ latitude, longitude }) => {
    const method = 'get'
    const endpoint = '/addresses/verify'
    const params = { latitude, longitude }

    try {
      const result = await deliveryApi.request(
        method,
        endpoint,
        null,
        null,
        null,
        null,
        params
      )
      return result.data
    } catch (error) {
      throw error
    }
  },

  placeOrder: async (order = {}) => {
    const method = 'post'
    const endpoint = '/orders'
    const body = { order }

    try {
      const result = await deliveryApi.request(method, endpoint, null, body)
      return result.data.data
    } catch (error) {
      throw error
    }
  },

  getOrders: async ({
    status,
    page,
    limit = 10,
    dateRange,
    from,
    to,
    q,
    info,
  } = {}) => {
    const method = 'get'
    const endpoint = '/orders'
    const params = {
      status,
      page,
      limit,
      date_range: dateRange,
      from,
      to,
      q,
      info,
    }

    try {
      const result = await deliveryApi.request(
        method,
        endpoint,
        null,
        null,
        null,
        null,
        params
      )

      return result.data
    } catch (error) {
      throw error
    }
  },

  getOrder: async uuid => {
    const method = 'get'
    const endpoint = `/orders/${uuid}`

    try {
      const result = await deliveryApi.request(method, endpoint)
      const { data: { data } = {} } = result

      if (!data) {
        throw new Error(
          'apiService.getOrder: data object not found in response'
        )
      }

      return { order: data }
    } catch (error) {
      throw error
    }
  },

  getWaybills: async ({ id }) => {
    const method = 'get'
    const endpoint = '/orders/waybills'
    const params = { id }

    try {
      const result = await deliveryApi.request(
        method,
        endpoint,
        null,
        null,
        null,
        null,
        params
      )
      return result.data.data.waybills
    } catch (error) {
      throw error
    }
  },

  getOrderQuote: async ({
    shipper,
    recipient,
    delivery_quotation_type: deliveryQuotationType,
    pickupTime,
    weight,
    length,
    height,
    width,
    user_voucher_ids,
  }) => {
    const method = 'post'
    const endpoint = '/orders/quote'
    const body = {
      order: {
        shipper,
        recipient,
        delivery_quotation_type: deliveryQuotationType,
        pickup_time: pickupTime,
        weight,
        length,
        height,
        width,
        user_voucher_ids,
      },
    }
    try {
      const result = await deliveryApi.request(method, endpoint, null, body)
      return result.data.data
    } catch (error) {
      throw error
    }
  },

  getPricingOptions: async ({ shipper, recipient, pickup_time }) => {
    const method = 'post'
    const endpoint = '/orders/quotes'
    const body = { order: { shipper, recipient, pickup_time } }

    try {
      const result = await deliveryApi.request(method, endpoint, null, body)
      return result.data.data
    } catch (error) {
      throw error
    }
  },

  cancelOrder: async ({ uuid }) => {
    const method = 'patch'
    const endpoint = `/orders/${uuid}`
    const data = {
      status: 'cancelled_by_shipper',
    }

    try {
      const result = await deliveryApi.request(method, endpoint, null, data)
      return result
    } catch (error) {
      throw error
    }
  },

  uploadImage: async ({ file }) => {
    const method = 'post'
    const endpoint = '/images'
    const data = new FormData()
    data.append('file', file, file.name)
    const headers = {
      'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
    }

    try {
      const result = await deliveryApi.request(
        method,
        endpoint,
        null,
        data,
        headers
      )
      return result.data.data
    } catch (error) {
      throw error
    }
  },

  getContacts: async ({ name } = {}) => {
    const method = 'get'
    const endpoint = '/contacts'
    const params = { name }

    try {
      const result = await deliveryApi.request(
        method,
        endpoint,
        null,
        null,
        null,
        null,
        params
      )
      const { data } = result
      return data && data.data
    } catch (error) {
      throw error
    }
  },
  addTip: async ({ tip, uuid }) => {
    try {
      const method = 'patch'
      const endpoint = `/orders/${uuid}`
      const data = { tip }

      const result = await deliveryApi.request(method, endpoint, null, data)
      return result
    } catch (error) {
      throw error
    }
  },
  downloadReport: async (params = {}) => {
    const method = 'get'
    const endpoint = '/orders/xlsx'
    const config = { responseType: 'blob' }

    try {
      const result = await deliveryApi.request(
        method,
        endpoint,
        null,
        null,
        null,
        config,
        params
      )

      return {
        data: result.data,
        headers: result.headers,
      }
    } catch (error) {
      throw error
    }
  },

  reportCourier: async ({
    orderId,
    reportFeedbackTypes,
    reportFeedbackText,
  }) => {
    const method = 'post'
    const endpoint = `orders/${orderId}/report_courier`

    const body = {
      report_feedback_types: reportFeedbackTypes,
      report_feedback_text: reportFeedbackText,
    }

    try {
      const result = await deliveryApi.request(method, endpoint, null, body)
      return result.data.data
    } catch (error) {
      throw error
    }
  },

  sendOrderReceipt: async (orderId, email) => {
    const method = 'post'
    const endpoint = `/orders/${orderId}/receipt`

    try {
      const data = { receipt_email: email }
      const result = await deliveryApi.request(method, endpoint, null, data)
      return result
    } catch (error) {
      throw error
    }
  },
  downloadOrderPDF: async (id, lang = 'en') => {
    const method = 'post'
    const endpoint = `/orders/${id}/print`
    const config = { responseType: 'blob' }

    try {
      const result = await deliveryApi.request(
        method,
        endpoint,
        null,
        { lang },
        null,
        config
      )

      return {
        data: result.data,
      }
    } catch (error) {
      throw error
    }
  },
}

export { deliveryApi, apiService }
