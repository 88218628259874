import {
  EMAIL_FORMAT,
  HK_PHONE_FORMAT,
  HK_RESERVED_PHONE,
  SG_PHONE_FORMAT,
  TW_PHONE_FORMAT,
  VN_PHONE_FORMAT,
} from './validation.constants'

const HKTelephoneField = (errorMessage, optional = false) => (value = '') => {
  if (optional && (value === '' || value === null)) return undefined

  const valueWithoutSpace = value && value.replace(/\s/g, '')
  return HK_PHONE_FORMAT.test(valueWithoutSpace) &&
    !HK_RESERVED_PHONE.test(valueWithoutSpace)
    ? undefined
    : errorMessage
}

const SGTelephoneField = (errorMessage, optional = false) => (value = '') => {
  if (optional && (value === '' || value === null)) return undefined

  const valueWithoutSpace = value.replace(/\s/g, '')
  return SG_PHONE_FORMAT.test(valueWithoutSpace) &&
    !valueWithoutSpace.startsWith('999') &&
    !valueWithoutSpace.startsWith('00')
    ? undefined
    : errorMessage
}

const TWTelephoneField = (errorMessage, optional = false) => (value = '') => {
  if (optional && (value === '' || value === null)) return undefined

  const valueWithoutSpace = value.replace(/\s/g, '')
  return TW_PHONE_FORMAT.test(valueWithoutSpace) ? undefined : errorMessage
}

const VNTelephoneField = (errorMessage, optional = false) => (value = '') => {
  if (optional && (value === '' || value === null)) return undefined

  const valueWithoutSpace = value.replace(/\s/g, '')
  return VN_PHONE_FORMAT.test(valueWithoutSpace) ? undefined : errorMessage
}

const requiredField = errorMessage => value =>
  typeof value === 'string' && value.length ? undefined : errorMessage

const requiredEmailFormat = errorMessage => value =>
  value && EMAIL_FORMAT.test(value) ? undefined : errorMessage

const requiredDropdown = errorMessage => option =>
  option && option.value !== undefined ? undefined : errorMessage

const passwordField = errorMessage => value =>
  value && /^[A-Za-z0-9\s$&+,:;=?@#|'<>.^*()%!-]{0,7}$/.test(value)
    ? errorMessage
    : undefined

const passwordFieldNoSpaces = errorMessage => value =>
  value && /\s+|^[A-Za-z0-9$&+,:;=?@#|'<>.^*()%!-]{0,7}$/.test(value)
    ? errorMessage
    : undefined

const passwordFieldMatch = errorMessage => (value, allValues) =>
  value === allValues.password ? undefined : errorMessage

const validUrl = urlString => {
  let url

  try {
    url = new URL(urlString)
  } catch (_) {
    return false
  }

  return url.protocol === 'http:' || url.protocol === 'https:'
}

const urlFieldValidator = errorMessage => urlString => {
  if (urlString === undefined || urlString === '' || validUrl(urlString)) {
    return undefined
  }

  return `${urlString} ${errorMessage}`
}

const characterLimitValidator = (errorMessage, limit) => (value = '') => {
  if (value === null) return undefined

  return value.length <= limit ? undefined : errorMessage
}

const addressLatLonValidator = errorMessage => (
  value,
  _dummy0,
  _dummy1,
  name
) => {
  if (name === 'address') {
    return typeof value === 'string' && value.length ? undefined : errorMessage
  } else if (name === 'lat' || name === 'lon') {
    return typeof value === 'number' ? undefined : errorMessage
  }

  return undefined
}

const oldAndNewPasswordMustBeDifferent = errorMessage => (
  oldPassword,
  newPassword
) => {
  return newPassword?.length >= 8 &&
    oldPassword?.length >= 8 &&
    oldPassword === newPassword
    ? errorMessage
    : undefined
}

export {
  HKTelephoneField,
  SGTelephoneField,
  TWTelephoneField,
  VNTelephoneField,
  requiredField,
  requiredEmailFormat,
  requiredDropdown,
  passwordFieldMatch,
  passwordField,
  urlFieldValidator,
  validUrl,
  characterLimitValidator,
  addressLatLonValidator,
  passwordFieldNoSpaces,
  oldAndNewPasswordMustBeDifferent,
}
