import styled from "styled-components"

import { COLOR } from "ggx-componentlibrary/design/color/color.constants"
import {
  FONT_SIZE,
  FONT_REGULAR,
} from "ggx-componentlibrary/design/typography/typography.constants"
import { SPACING } from "ggx-componentlibrary/design/spacing/spacing.constants"
import { TextLink } from "ggx-componentlibrary/design/typography/typography.styles"

const StripeElementStyles = `
  box-sizing: border-box;
  height: 40px;
  padding: 12px 16px;
  border: 1px solid ${COLOR.GREY_LIGHT};
  border-radius: 4px;
`

const StyledPaymentWrapper = styled.div`
  width: 100%;

  .StripeElement {
    ${StripeElementStyles}
    &--focus {
      border-color: ${COLOR.BLUE_MEDIUM};
    }
    &--invalid {
      border-color: ${COLOR.RED_MEDIUM};
    }
  }
`

const CardElementStyles = {
  base: {
    color: COLOR.GREY_DARK,
    fontSize: FONT_SIZE.SMALL,
    fontFamily:
      "Wix Madefor Text, Lucida Grande, Helvetica Neue, Helvetica, Arial, Verdana, sans-serif",
    "::placeholder": {
      color: COLOR.GREY_DARK,
    },
  },
  invalid: {
    color: COLOR.RED_MEDIUM,
  },
}

const CardDisplayWrapper = styled.div`
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  ${StripeElementStyles}
  ${FONT_REGULAR}
  font-size: ${FONT_SIZE.SMALL};
  color: ${COLOR.GREY_DARK};
  letter-spacing: 1px;
  cursor: default;
  ${(props) =>
    props.noBorderDisplayMode
      ? "height: auto; padding: 0; border: none;"
      : null};
`

const CardIcon = styled.div`
  width: 20px;
  height: 14px;
  margin-right: 6px;

  img {
    display: block;
    position: relative;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
  }
`

const CardNumberWrapper = styled.div`
  flex: 1;
  text-align: left;
`

const CardExpiryDateWrapper = styled.div`
  text-align: right;
`

const StyledTextLink = styled(TextLink)`
  display: inline-block;
  font-size: ${FONT_SIZE.SMALL};
  margin-top: ${SPACING.XS};
`

const HiddenButton = styled.button`
  display: none;
`

export {
  StyledPaymentWrapper,
  CardElementStyles,
  CardDisplayWrapper,
  CardIcon,
  CardNumberWrapper,
  CardExpiryDateWrapper,
  StyledTextLink,
  HiddenButton,
}
