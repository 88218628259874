import { Component } from 'react'
import PropTypes from 'prop-types'

import translate from '../../../locales/translate'
import {
  FilterContainer,
  Filter,
  FilterLabel,
  StyledDropdown,
} from './filter.component.styles'

const translateWithPrefix = key => translate(`wallet.filter.${key}`)

export const showAll = translateWithPrefix('showAll')

// NOTE: show previous 5 years and, on or after 2016
const currentYear = new Date().getFullYear()
const yearOptions = [
  currentYear,
  currentYear - 1,
  currentYear - 2,
  currentYear - 3,
  currentYear - 4,
]
  .filter(year => year >= 2016)
  .map(year => year.toString())
yearOptions.unshift(showAll)

const yearDropdownOptions = yearOptions.map(option => ({
  label: option,
  value: option,
}))

const monthOptions = [showAll]
for (let i = 1; i <= 12; i += 1) {
  monthOptions.push(`${i}`)
}
const monthDropdownOptions = monthOptions.map(option => ({
  label: option,
  value: option,
}))

class WalletFilterView extends Component {
  filterTransactions = (field, option) => {
    const { onChanged, filterParams } = this.props
    const clonedParams = { ...filterParams }

    clonedParams[field] = option.value

    if (field === 'year') {
      clonedParams.month = ''
    }

    const ret = Object.assign(clonedParams, { page: 1 }) || {}

    if (onChanged) onChanged(ret)
    return ret
  }

  render() {
    return (
      <FilterContainer>
        <Filter>
          <FilterLabel>{translateWithPrefix('year')}</FilterLabel>
          <StyledDropdown
            dataTestid="wallet-year-dropdown"
            defaultOptions={yearDropdownOptions}
            onChange={option => this.filterTransactions('year', option)}
            value={this.props.filterParams.year || showAll}
          />
        </Filter>
        <Filter>
          <FilterLabel>{translateWithPrefix('month')}</FilterLabel>
          <StyledDropdown
            dataTestid="wallet-month-dropdown"
            defaultOptions={monthDropdownOptions}
            onChange={option => this.filterTransactions('month', option)}
            value={this.props.filterParams.month || showAll}
          />
        </Filter>
      </FilterContainer>
    )
  }
}

WalletFilterView.propTypes = {
  onChanged: PropTypes.func,
  filterParams: PropTypes.object,
}

export default WalletFilterView
