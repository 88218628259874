import styled from "styled-components"
import Link from "react-router/lib/Link"

import { COLOR } from "ggx-componentlibrary/design/color/color.constants"
import { SPACING } from "ggx-componentlibrary/design/spacing/spacing.constants"
import {
  FONT_REGULAR,
  FONT_SIZE,
  LINE_HEIGHT,
} from "ggx-componentlibrary/design/typography/typography.constants"
import {
  BodyBold,
  SmallText,
  SmallTextBold,
} from "ggx-componentlibrary/design/typography/typography.styles"
import { ContentBox } from "ggx-componentlibrary/components/tooltip/tooltip.component.styles"
import Logo from "./assets/gogobusiness-logo.svg"
import CollapseSVG from "./assets/collapse_icon.svg"
import ExpandSVG from "./assets/expand_icon.svg"
import TopupSVG from "./assets/top_up.svg"
import PlaceOrderSVG from "./assets/place-order.svg"
import OrderManagementSVG from "./assets/order-management.svg"
import BulkOrderSVG from "./assets/bulk-order.svg"
import TransactionHistorySVG from "./assets/transaction_history.svg"
import ChevronSVG from "./assets/chevron-right.svg"

import {
  COLLAPSED_WIDTH,
  EXPANDED_WIDTH,
  COLLAPSED_TRANSATION_DURATION,
  EXPANDED_TRANSATION_DURATION,
} from "./navigation.constants"

const STYLES_FOR_TOGGLE_OPACITY = (props) => {
  if (props.$isNavigationHovered) return 'opacity: 1'
  return props.$isNavigationCollapsed ? 'opacity: 0' : 'opacity: 1'
}

const CollapseIcon = styled(CollapseSVG)`
  path {
    fill: ${COLOR.GREY_DARK}
  }
`
const ExpandIcon = styled(ExpandSVG)`
  path {
    fill: ${COLOR.GREY_DARK}
  }
`
const TopupIcon = styled(TopupSVG)`
  flex-shrink: 0;
  ${(props) => {
    if (props.$isNavigationHovered) return 'margin: auto'
    return props.$isNavigationCollapsed ? 'margin: 0 6px' : 'margin: auto'
  }}
`
const PlaceOrderIcon = styled(PlaceOrderSVG)``
const OrderManagementIcon = styled(OrderManagementSVG)``
const BulkOrderIcon = styled(BulkOrderSVG)``
const TransactionHistoryIcon = styled(TransactionHistorySVG)``

const NavigationContainer = styled.nav`
  position: absolute;
  z-index: 5;
  width: ${(props) => {
    if (props.$isNavigationHovered) return EXPANDED_WIDTH
    return props.$isNavigationCollapsed ? COLLAPSED_WIDTH : EXPANDED_WIDTH
  }};
  height: 100vh;
  padding-bottom: ${SPACING.XS};

  display: flex;
  flex-direction: column;

  will-change: width;
  transition-property: width;
  transition-duration: ${(props) => {
    if (props.$isNavigationHovered) return EXPANDED_TRANSATION_DURATION
    return props.$isNavigationCollapsed
      ? COLLAPSED_TRANSATION_DURATION
      : EXPANDED_TRANSATION_DURATION
  }};
  transition-timing-function: ${(props) => {
    if (props.$isNavigationHovered) return "ease-out"
    return props.$isNavigationCollapsed ? "ease-in" : "ease-out"
  }};

  background: ${COLOR.GREY_LIGHTEST};
  border-right: 1px solid ${COLOR.GREY_LIGHT};
`

const NavigationInnerContainer = styled.div`
  position: relative;
  width: 100%;
  flex-grow: 1;
  padding-bottom: 130px;

  overflow-x: hidden;
  overflow-y: auto;
`

const TopBar = styled.div`
  display: flex;
  padding: 19px 20px 17px 8px;
  justify-content: space-between;
  align-items: center;
`

const CollapseButton = styled.div`
  padding: 6px 12px;
  border-radius: 6px;

  position: relative;
  cursor: pointer;

  :hover {
    background: rgba(0, 0, 0, 0.04);

    ${CollapseIcon}, ${ExpandIcon} {
      path {
        fill: ${COLOR.BLUE_MEDIUM};
      }
    }
  }
`

const LogoContainer = styled(Link)`
  display: block;
  height: 30px;

  pointer-events: ${(props) => {
    if (props.$isNavigationHovered) return 'auto'
    return props.$isNavigationCollapsed ? 'none' : 'auto'
  }};
`

const GogobusinessLogo = styled(Logo)`
  opacity: ${(props) => {
    if (props.$isNavigationHovered) return 1
    return props.$isNavigationCollapsed ? 0 : 1
  }};

  transition: opacity
    ${(props) => {
      if (props.$isNavigationHovered) return EXPANDED_TRANSATION_DURATION
      return props.$isNavigationCollapsed
        ? COLLAPSED_TRANSATION_DURATION
        : EXPANDED_TRANSATION_DURATION
    }};
`

const Label = styled.span`
  display: inline-block;
  ${FONT_REGULAR}
  font-size: ${FONT_SIZE.MEDIUM};
  line-height: ${LINE_HEIGHT.SMALL};
  color: ${COLOR.GREY_DARKER};
  margin-left: 12px;
  text-decoration: none;
`

const Row = styled(Link)`
  position: relative;
  display: flex;
  justify-content: start;
  align-items: center;
  min-height: 40px;
  padding: 0 ${SPACING.S};
  width: ${EXPANDED_WIDTH};
  text-decoration: none;
  word-wrap: break-word;
  margin-top: 2px;

  ${Label} {
    color: ${(props) => props.$isActive ? COLOR.BLACK : COLOR.GREY_DARKER};
    ${STYLES_FOR_TOGGLE_OPACITY}
  }

  svg {
    margin-left: 4px;
    flex-shrink: 0;
  }

  &:before {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    margin-left: -${SPACING.XS};
    width: ${(props) => {
      if (props.$isNavigationHovered) return `calc(${EXPANDED_WIDTH} - ${SPACING.S})`
      return props.$isNavigationCollapsed ? `calc(${COLLAPSED_WIDTH} - ${SPACING.S})` : `calc(${EXPANDED_WIDTH} - ${SPACING.S})`;
    }};
    height: 100%;
    border-radius: 6px;
    ${(props) => props.$isActive && `background-color: #E4E5E5;`}
    transition: width ${(props) => {
      if (props.$isNavigationHovered) return EXPANDED_TRANSATION_DURATION
      return props.$isNavigationCollapsed
        ? COLLAPSED_TRANSATION_DURATION
        : EXPANDED_TRANSATION_DURATION
    }};
  }

  :hover {
    &:before {
      ${(props) => !props.$isActive && `background-color: rgba(0, 0, 0, 0.04);`}
    }

    ${(props) => !props.$isActive && `
      ${Label} {
        color: ${COLOR.BLUE_MEDIUM};
      }
    `}
  }
`

const PlaceOrderRow = styled(Row)`
  ${PlaceOrderIcon} {
    path {
      fill: ${COLOR.GREY_DARK};
    }
  }

  ${(props) => !props.$isActive && `
    :hover {
      ${PlaceOrderIcon} {
        path {
          fill: ${COLOR.BLUE_MEDIUM};
        }
      }
    }
  `}
`

const BulkOrderRow = styled(Row)`
  ${Label} {
    margin-left: 9px;
  }

  ${BulkOrderIcon} {
    margin-left: -9px;
    rect {
      fill-opacity: 1;
      fill: ${COLOR.GREY_DARK};
    }
  }

  ${(props) => !props.$isActive && `
    :hover {
      ${BulkOrderIcon} {
        rect {
          fill: ${COLOR.BLUE_MEDIUM};
        }
      }
    }
  `}
`

const OrderManagementRow = styled(Row)`
  ${OrderManagementIcon} {
    path {
      fill: ${COLOR.GREY_DARK};
    }
  }

  ${(props) => !props.$isActive && `
    :hover {
      ${OrderManagementIcon} {
        path {
          fill: ${COLOR.BLUE_MEDIUM};
        }
      }
    }
  `}

`

const TransactionHistoryRow = styled(Row)`
  ${TransactionHistoryIcon} {
    path {
      stroke: ${COLOR.GREY_DARK};
    }
  }

  ${(props) => !props.$isActive && `
    :hover {
      ${TransactionHistoryIcon} {
        path {
          stroke: ${COLOR.BLUE_MEDIUM};
        }
      }
    }
  `}
`

const SectionTitle = styled.div`
    position: relative;
    width: ${EXPANDED_WIDTH};
    padding: 18px 12px 8px 20px;
    margin-top: 12px;

    & + ${Row} {
      margin-top: 0;
    }

    &:before {
      position: absolute;
      content: '.';
      margin-left: 8px;
      line-height: 10px;
      top: 50%;
      transform: translateY(-50%);
      display: ${(props) => props.$isNavigationCollapsed && !props.$isNavigationHovered ? 'inline-block' : 'none'}
    }

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      display: inline-block;
      line-height: 10px;
      width: ${(props) => props.$isNavigationCollapsed && !props.$isNavigationHovered ? `calc(${COLLAPSED_WIDTH} - 24px)` : `calc(${EXPANDED_WIDTH} - 24px)`};
      height: 1px;
      background: ${COLOR.GREY_LIGHT};
      margin: 0 12px;
    }

    ${BodyBold} {
      display:inline-block;
      ${STYLES_FOR_TOGGLE_OPACITY};
      transition: opacity ${(props) => {
        if (!props.$isNavigationHovered) return EXPANDED_TRANSATION_DURATION
        return !props.$isNavigationCollapsed
          ? COLLAPSED_TRANSATION_DURATION
          : EXPANDED_TRANSATION_DURATION
      }};
    }
`

const TopupButtonAmount = styled(BodyBold)`
  color: ${COLOR.GREY_DARKER};
  margin-bottom: 0;
  transition: color 0.1s;
`

const AdaptiveMenuBlock = styled.div`
  position: relative;
  & > *:first-child {
    opacity: ${(props) => {
      if (props.$isNavigationHovered) return 1
      return props.$isNavigationCollapsed ? 0 : 1
    }};
  
    transition: opacity
      ${(props) => {
        if (props.$isNavigationHovered) return EXPANDED_TRANSATION_DURATION
        return props.$isNavigationCollapsed
          ? COLLAPSED_TRANSATION_DURATION
          : EXPANDED_TRANSATION_DURATION
      }};
  }
  & > *:nth-child(2) {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
    opacity: ${(props) => {
      if (props.$isNavigationHovered) return 0
      return !props.$isNavigationCollapsed ? 0 : 1
    }};
    transition: opacity
      ${(props) => {
        if (!props.$isNavigationHovered) return EXPANDED_TRANSATION_DURATION
        return !props.$isNavigationCollapsed
          ? COLLAPSED_TRANSATION_DURATION
          : EXPANDED_TRANSATION_DURATION
      }};
  }
`

const UserInfoContainer = styled.div`
  white-space: nowrap;
  margin: ${SPACING.XS};
  background: ${COLOR.WHITE};
  border-radius: 6px;
  &:hover {
    color: ${COLOR.BLUE_MEDIUM}
  }

  & ${ContentBox} {
    right: -12px;
    bottom: 100%;
  }
  & > *:nth-child(2) {
    border-top: 1px solid ${COLOR.GREY_LIGHT};
  }
`
const UserInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${SPACING.XS} 12px ${SPACING.XS} 9px;
  border-radius: 6px;
  cursor: pointer;
  gap: 10px;
  &:hover {
    ${SmallText}, ${BodyBold} {
      color: ${COLOR.BLUE_MEDIUM};
    }
    path {
      stroke: ${COLOR.BLUE_MEDIUM};
    }
  }
  
  div > ${BodyBold}, ${SmallText} {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

const UserInfoContent = styled.div`
  flex: 1 0 auto;
  max-width: calc(100% - 58px)
`

const UserImage = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
`

const BalanceInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${SPACING.XS} 12px;
  cursor: pointer;
  width: 100%;
  & path:nth-child(odd) {
    fill: ${COLOR.BLUE_MEDIUM};
    fill-opacity: 1;
  }
  & path:nth-child(even) {
    stroke: ${COLOR.BLUE_MEDIUM};
    stroke-opacity: 1;
  }
  &:hover > *:first-child {
    color: ${COLOR.BLUE_MEDIUM};
  }
`
const CollapseUserInfoContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  padding: ${SPACING.XS} ${SPACING.S};

  ${TopupIcon} {
    margin-left: 5px;
  }
`

const ProfilePhoto = styled.div`
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  background-image: ${(props) => `url(${props.$profilePhotoSrc})`};
  background-size: cover;
  background-position: center center;
  margin: 13px 0 24px 1px;
  flex-shrink: 0;
`

const ChevronIconNoMargin = styled(ChevronSVG)`
  flex-shrink: 0;
`

export {
  STYLES_FOR_TOGGLE_OPACITY,
  NavigationContainer,
  NavigationInnerContainer,
  CollapseButton,
  LogoContainer,
  GogobusinessLogo,
  Row,
  PlaceOrderRow,
  BulkOrderRow,
  OrderManagementRow,
  TransactionHistoryRow,
  SectionTitle,
  Label,
  TopupButtonAmount,
  CollapseIcon,
  ExpandIcon,
  TopupIcon,
  PlaceOrderIcon,
  OrderManagementIcon,
  BulkOrderIcon,
  TransactionHistoryIcon,
  AdaptiveMenuBlock,
  UserInfoContainer,
  UserInfo,
  UserInfoContent,
  UserImage,
  BalanceInfo,
  CollapseUserInfoContainer,
  ProfilePhoto,
  TopBar,
  ChevronIconNoMargin,
}
