import styled from "styled-components"

import { COLOR } from "ggx-componentlibrary/design/color/color.constants"
import {
  TextLink,
  Body,
} from "../../component-library/design/typography/typography.styles"

const StyledBody = styled(Body)`
  margin-bottom: 0;
  display: "inline";
`

const StyledTextLink = styled(TextLink)`
  cursor: pointer;
`
const StyledTextLinkInAlert = styled(TextLink)`
  color: ${COLOR.BLUE_LIGHT};
  cursor: pointer;
`

export { StyledBody, StyledTextLink, StyledTextLinkInAlert }
