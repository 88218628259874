import { Api } from '../../legacy/utils/api'

const _api = new Api(Api.BASE_URL.B2B)

const paymentService = {
  getCard: async () => {
    const method = 'get'
    const endpoint = '/credit_cards/default'

    try {
      const result = await _api.request(method, endpoint)
      return result.data
    } catch (error) {
      throw error
    }
  },
  removeCard: async card_id => {
    const method = 'delete'
    const endpoint = '/credit_cards'

    try {
      const result = await _api.request(
        method,
        endpoint,
        null,
        null,
        null,
        null,
        {
          card_id,
        }
      )
      return result.data
    } catch (error) {
      throw error
    }
  },
  getCardSetupIntent: async () => {
    const method = 'post'
    const endpoint = '/payment/card_intent'

    try {
      const result = await _api.request(method, endpoint)
      return result.data
    } catch (error) {
      throw error
    }
  },
  updateCardSetupIntent: async (intent_id, metadata) => {
    const method = 'put'
    const endpoint = `/payment/card_intent/${intent_id}`

    try {
      const result = await _api.request(method, endpoint, null, metadata)
      return result.data
    } catch (error) {
      throw error
    }
  },
  getPaymentInfo: async () => {
    const method = 'get'
    const endpoint = '/payment/info'

    try {
      const result = await _api.request(method, endpoint)

      return result.data
    } catch (error) {
      throw error
    }
  },
  updateCard: async ({ nickname, stripe_card_id, is_primary }) => {
    const method = 'put'
    const endpoint = '/credit_cards'

    try {
      const result = await _api.request(
        method,
        endpoint,
        null,
        { nickname, stripe_card_id, is_primary },
        null,
        null,
        null
      )
      return result.data
    } catch (error) {
      throw error
    }
  },
}

export { _api, paymentService }
