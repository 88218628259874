import styled from "styled-components"

import { SPACING } from "ggx-componentlibrary/design/spacing/spacing.constants"
import { getTypographyStyling } from "ggx-componentlibrary/design/typography/service"

const PageContainer = styled.section`
  padding: ${SPACING.M} ${SPACING.L}
    ${(props) => (props.isHeading ? "0px" : SPACING.XL5)};
  margin-bottom: ${(props) => (props.isHeading ? "12px" : "0px")};
  margin-left: 0px;
  margin-right: 0px;
`

const HeadingSection = styled.div`
  padding-bottom: ${(props) => (props.isHeading ? "0px" : SPACING.M)};

  display: flex;
  justify-content: space-between;
  ${(props) => getTypographyStyling(props.typographyStyles)}
`

const LeftColumn = styled.div``

const RightColumn = styled.div`
  padding-top: 18px;
`

export { PageContainer, HeadingSection, LeftColumn, RightColumn }
