import * as types from './types'

const initialState = false

function homepageAccessReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.FEATUREFLAG_HOMEPAGE_ACCESS:
      return typeof action.value === 'boolean' ? action.value : initialState

    default:
      return state
  }
}

export { homepageAccessReducer }
