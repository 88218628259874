import { Api } from '../../legacy/utils/api'

const _walletApi = new Api(Api.BASE_URL.B2B)

const walletApiService = {
  getCard: async () => {
    const method = 'get'
    const endpoint = '/credit_cards/default'
    const params = null

    try {
      const result = await _walletApi.request(
        method,
        endpoint,
        null,
        null,
        null,
        null,
        params
      )
      return result.data
    } catch (error) {
      throw error
    }
  },

  saveCard: async ({ id }) => {
    const method = 'post'
    const endpoint = '/credit_cards'
    const data = { token: id }

    try {
      const result = await _walletApi.request(
        method,
        endpoint,
        null,
        data,
        null,
        null,
        null
      )
      return result.data
    } catch (error) {
      throw error
    }
  },

  removeCard: async () => {
    const method = 'delete'
    const endpoint = '/credit_cards/default'
    const params = null

    try {
      const result = await _walletApi.request(
        method,
        endpoint,
        null,
        null,
        null,
        null,
        params
      )
      return result
    } catch (error) {
      throw error
    }
  },

  getTransactions: async ({ type, year, month, page = 1, perPage = 15 }) => {
    const method = 'get'
    const endpoint = '/payment_transactions'
    const params = {
      type,
      year,
      month,
      page,
      per_page: perPage,
    }

    try {
      const result = await _walletApi.request(
        method,
        endpoint,
        null,
        null,
        null,
        null,
        params
      )
      return result.data
    } catch (error) {
      throw error
    }
  },
}

export { _walletApi, walletApiService }
