import React, { useState, useEffect, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import { Button } from 'ggx-componentlibrary/components/button/button.component'

import { CardsTable } from './cards-table.component'
import { VoucherCard } from './voucher-card.component'
import { RedemptionForm } from './redemption-form.component'
import { VoucherCardModal } from './voucher-card-modal.component'

import {
  ActiveEmptyDescription,
  StyledBodyBold,
  StyledBody,
  StyledModal,
} from './voucher.component.styles'

import { getActiveCoupons } from 'ggx-global/duck/selectors'
import { fetchCoupon } from 'ggx-global/duck/actions'
import { displayAlert } from 'ggx-componentlibrary/components/alerts/alerts.component'

const PRE_PAGE_NUM = 5

const SERVICE_TYPES = {
  TRANSPORT: 'transport',
  DELIVERY: 'delivery',
}

const VoucherModal = ({
  orderPrice,
  serviceType,
  vehicleType,
  regionIds,
  usedVoucherIds,
  onSelectCoupon,
  onModalClose,
}) => {
  const [t] = useTranslation()
  const [selectedVoucherCode, setSelectedVoucherCode] = useState()

  const dispatch = useDispatch()
  const coupons = useSelector(state => getActiveCoupons(state))
  const [isFirstLoading, setIsFirstLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  const reloadCoupons = useCallback(async () => {
    setIsLoading(true)
    await dispatch(
      fetchCoupon({
        orderPrice,
        serviceType,
        vehicleType,
        regionIds,
      })
    )
    setIsLoading(false)
  }, [])

  useEffect(() => {
    ;(async () => {
      await reloadCoupons()
      setIsFirstLoading(false)
    })()
  }, [])

  const selectNewCouponAfterRedeeming = useCallback(async (code, data) => {
    await reloadCoupons()
    const detail = Object.values(data.vouchers)[0]
    if (detail.can_apply_to_order) {
      setSelectedVoucherCode(code.toLocaleLowerCase())
    }
  }, [])

  const [currentPageNumber, setCurrentPageNumber] = useState(1)
  const [showedCouponDetail, setShowedCouponDetail] = useState()
  const usedVoucherIdSet = new Set(usedVoucherIds ?? [])
  const filteredCoupons = useMemo(() => {
    return coupons
      .map(item => ({
        ...item,
        available: item?.available.filter(id => !usedVoucherIdSet.has(id)),
      }))
      .filter(item => item?.available?.length)
      .reduce(
        (groups, item) => {
          groups[item.can_apply_to_order ? 0 : 1].push(item)
          return groups
        },
        [[], []]
      )
      .flat()
  }, [coupons, usedVoucherIds])
  const currentPageItems = useMemo(() => {
    return filteredCoupons
      .slice(
        (currentPageNumber - 1) * PRE_PAGE_NUM,
        (currentPageNumber - 1) * PRE_PAGE_NUM + PRE_PAGE_NUM
      )
      .map(item => ({
        id: item.id,
        code: item.code,
        title: item.title,
        serviceTypes: item.service_types_rule,
        vehicleTypesRule: item.vehicle_types_rule,
        minOrderSpent: Number(item.min_order_spent_rule),
        expiredAt: new Date(item.expired_at).getTime(),
        remark: item.remark,
        remaining: item.available.length,
        total: item.total,
        disabled: !item.can_apply_to_order,
      }))
  }, [currentPageNumber, filteredCoupons])

  return (
    <StyledModal
      height="80%"
      width="800px"
      header={t('common__coupons')}
      footer={
        <>
          <Button buttonType="secondary" size="small" onClick={onModalClose}>
            {t('common__cancel')}
          </Button>
          <Button
            buttonType="primary"
            size="small"
            onClick={() => {
              onSelectCoupon &&
                onSelectCoupon(
                  filteredCoupons.find(
                    item => item.code === selectedVoucherCode
                  )
                )
              onModalClose()
            }}
            disabled={!selectedVoucherCode}
          >
            {t('common__apply')}
          </Button>
        </>
      }
      onRequestClose={onModalClose}
    >
      <CardsTable
        column={2}
        loading={isLoading}
        onLoadPage={setCurrentPageNumber}
        currentPageNumber={currentPageNumber}
        totalPageNumber={Math.ceil(coupons.length / PRE_PAGE_NUM)}
      >
        <RedemptionForm
          onSuccess={selectNewCouponAfterRedeeming}
          orderPrice={orderPrice}
          serviceType={serviceType}
          vehicleType={vehicleType}
        />
        {currentPageItems.map(item => {
          return (
            <VoucherCard
              {...item}
              key={item.id}
              {...(selectedVoucherCode === item.code
                ? { type: 'selected' }
                : item.disabled
                ? { type: 'disabled' }
                : {})}
              onClick={() =>
                setSelectedVoucherCode(pre =>
                  pre === item.code ? undefined : item.code
                )
              }
              onClickDetail={() => setShowedCouponDetail(item)}
            />
          )
        })}
        {!isFirstLoading && currentPageItems.length === 0 && (
          <ActiveEmptyDescription>
            <StyledBodyBold>{t('coupon_mgmt_empty__title')}</StyledBodyBold>
            <StyledBody>{t('coupon_mgmt_empty__description')}</StyledBody>
          </ActiveEmptyDescription>
        )}
      </CardsTable>
      {showedCouponDetail && (
        <VoucherCardModal
          {...showedCouponDetail}
          onModalClose={() => setShowedCouponDetail(undefined)}
        ></VoucherCardModal>
      )}
    </StyledModal>
  )
}

VoucherModal.propTypes = {
  orderPrice: PropTypes.number,
  serviceType: PropTypes.oneOf([
    SERVICE_TYPES.TRANSPORT,
    SERVICE_TYPES.DELIVERY,
  ]),
  vehicleType: PropTypes.string,
  regionIds: PropTypes.arrayOf(PropTypes.string),
  usedVoucherIds: PropTypes.arrayOf(PropTypes.number),
  onSelectCoupon: PropTypes.func,
  onModalClose: PropTypes.func,
}

export { VoucherModal }
