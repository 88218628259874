import styled from "styled-components"

import { COLOR } from "ggx-componentlibrary/design/color/color.constants"

import { Row } from '../navigation.component.styles'

const HomeRow = styled(Row)`
  svg {
    path {
      stroke: ${COLOR.GREY_DARK};
    }
  }

  ${(props) => !props.$isActive && `
    &:hover {
      svg {
        path {
          stroke: ${COLOR.BLUE_MEDIUM};
        }
      }
    }
  `}
`

export { HomeRow }