import Constants from '../../../constants/auth'

const isValidUser = currentUser =>
  currentUser ? typeof currentUser.id === 'number' : false

const isB2BUser = currentUser => {
  const b2bUserTypes = [
    Constants.B2B_ADMIN_USER,
    Constants.B2B_MASTER_USER,
    Constants.B2B_BRANCH_USER,
    Constants.B2B_SUB_USER,
  ]
  return isValidUser(currentUser) && b2bUserTypes.includes(currentUser.level)
}

const isAdminUser = currentUser =>
  isValidUser(currentUser) && currentUser.level === Constants.B2B_ADMIN_USER

const isMasterUser = currentUser =>
  isValidUser(currentUser) && currentUser.level === Constants.B2B_MASTER_USER

const isBranchUser = currentUser =>
  isValidUser(currentUser) && currentUser.level === Constants.B2B_BRANCH_USER

const isSubUser = currentUser =>
  isValidUser(currentUser) && currentUser.level === Constants.B2B_SUB_USER

const routeWithAuthorize = route => 'authorize' in route

const isWalletUser = currentUser =>
  isMasterUser(currentUser) || isBranchUser(currentUser)

const checkUserPermission = ({
  routeProps,
  currentUserLevel,
  successCb,
  failedCb
}) => {
  if (routeWithAuthorize(routeProps)) {
    const authorize = routeProps.authorize
    
    if (authorize.includes(currentUserLevel)) {
      successCb && successCb()
    } else {
      failedCb && failedCb()
    }
  }
}

export {
  isValidUser,
  isAdminUser,
  isMasterUser,
  isBranchUser,
  isSubUser,
  isWalletUser,
  isB2BUser,
  routeWithAuthorize,
  checkUserPermission,
}
