import * as types from './types'
import { apiService } from 'ggx-src/billing/api/api.service'

function setCreditBalance({
  credit,
  freeCredit,
  freeCreditExpiryDate,
  unsettledBalance,
}) {
  return {
    type: types.SET_CREDIT_BALANCE,
    credit,
    freeCredit,
    freeCreditExpiryDate,
    unsettledBalance,
  }
}

function updateCreditBalance() {
  return async (dispatch, getState) => {
    const {
      credits_balance: credit,
      free_credits_balance: freeCredit,
    } = await apiService.getPrepaidBalance()
    dispatch(setCreditBalance({ credit, freeCredit }))
  }
}

function subtractCreditBalance(amount) {
  return async (dispatch, getState) => {
    const { creditBalance } = getState()
    dispatch(
      setCreditBalance({
        ...creditBalance,
        credit: creditBalance.credit - amount,
      })
    )
  }
}

export { setCreditBalance, updateCreditBalance, subtractCreditBalance }
