import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'

import {
  SmallText,
  BodyBold,
} from 'ggx-componentlibrary/design/typography/typography.styles'
import { formatCurrency } from 'ggx-service/i18n/format-currency.service'

import { ConnectedTopupModal } from 'ggx-src/billing/topup-modal/topup-modal.component'
import { payByCreditCard } from 'ggx-src/van-booking/duck/selectors'
import { getTotalCredit } from 'ggx-src/billing/duck/selectors'
import { getFeatureFlagGogodelivery } from 'ggx-src/delivery/duck/selectors'
import {
  isWalletUser,
  isAdminUser,
} from 'ggx-src/legacy/views/helpers/auth/auth'
import {
  getOrganizationName,
  getCurrentUserProfilePhotoList,
  getProfilePhotoDataUrl,
} from 'ggx-src/profile/duck/selectors'

import { getFeatureFlag } from 'ggx-service/feature-flag/selectors'

import {
  getAccountName,
  getCurrentUserLevel,
} from 'ggx-src/users-and-branches/duck/selectors'

import {
  NavigationContainer,
  NavigationInnerContainer,
  CollapseButton,
  LogoContainer,
  GogobusinessLogo,
  TopupButtonAmount,
  CollapseIcon,
  ExpandIcon,
  TopupIcon,
  AdaptiveMenuBlock,
  UserInfoContainer,
  UserInfo,
  BalanceInfo,
  CollapseUserInfoContainer,
  ProfilePhoto,
  UserImage,
  UserInfoContent,
  TopBar,
  ChevronIconNoMargin,
} from './navigation.component.styles'
import defaultProfilePhotoSrc from './assets/default_profile_photo.file.svg'
import HomeSection from './home/home.component'
import AdminSection from './admin/admin.component'
import VanBookingSection from './van-booking/van-booking.component'
import DeliverySection from './delivery/delivery.component'
import NDDSection from './next-day-delivery/next-day-delivery.component'
import BillingSection from './billing/billing.component'
import Notification from './notification/notification.component'
import Settings from './settings/settings.component'

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    [`&.${tooltipClasses.tooltip}.${tooltipClasses.tooltipPlacementBottom}`]: {
      marginTop: '2px',
    },
  },
})

const Navigation = ({
  currentUser,
  isNavigationCollapsed,
  isNavigationHovered,
  setIsNavigationCollapsed,
  setIsNavigationHovered,
  showHomepage,
  showVanBookingMenu,
  showTransportBulkImport,
  showFavoriteDrivers,
  showBillingNavigation,
  showDeliveryBulkImport,
  activePath,
}) => {
  const [t] = useTranslation()

  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false)
  const [isOrganizationSwitchOpen, setIsOrganizationSwitchOpen] = useState(
    false
  )
  const [showTopupModal, setShowTopupModal] = useState(false)

  const isCreditCardUser = useSelector(state => payByCreditCard(state))
  const totalCredit = useSelector(state => getTotalCredit(state))
  const featureFlagGogodelivery = useSelector(state =>
    getFeatureFlagGogodelivery(state)
  )
  const featureFlagNextDayDelivery = useSelector(state =>
    getFeatureFlag({ state, featureFlag: 'next_day_delivery' })
  )

  const creditCardUserWithPrepaid = isCreditCardUser || featureFlagGogodelivery
  const creditCardUserWithNDD = isCreditCardUser && featureFlagNextDayDelivery

  const collapseButtonClickHandler = () => {
    setIsNavigationCollapsed()
    setIsNavigationHovered(false)
  }

  const handleMouseEnterOrOver = () => {
    if (isSubMenuOpen) return
    setIsNavigationHovered(true)
  }

  const handleMouseLeave = () => {
    if (isSubMenuOpen) return
    if (isOrganizationSwitchOpen) return

    setIsNavigationHovered(false)
  }

  const stopPropagateMouseOverToNavgationContainer = event => {
    // This event listener is used to avoid expanding navigation when hovering logo area
    event.stopPropagation()
  }
  const navigationInnerContainerRef = useRef()
  const [userMenuOffset, setUserMenuOffset] = useState()
  const handleToggleSubMenuClick = () => {
    setIsSubMenuOpen(prev => !prev)
    setUserMenuOffset(navigationInnerContainerRef.current?.scrollTop ?? 0)
  }
  const profilePhotoDataUrl = useSelector(state =>
    getProfilePhotoDataUrl(state)
  )
  const photosObject = useSelector(state =>
    getCurrentUserProfilePhotoList(state)
  )
  const profilePhotoSrc =
    profilePhotoDataUrl || photosObject?.small || defaultProfilePhotoSrc

  const userName = useSelector(state => getAccountName(state))
  const userLevel = useSelector(state => getCurrentUserLevel(state))
  const organizationName = useSelector(state => getOrganizationName(state))

  return (
    <NavigationContainer
      $isNavigationCollapsed={isNavigationCollapsed}
      $isNavigationHovered={isNavigationHovered}
      onMouseLeave={handleMouseLeave}
      data-testid="navigation-menu"
    >
      <NavigationInnerContainer
        ref={navigationInnerContainerRef}
        onMouseOver={handleMouseEnterOrOver}
        onScroll={() => setIsSubMenuOpen(false)}
      >
        <TopBar onMouseOver={stopPropagateMouseOverToNavgationContainer}>
          <CustomWidthTooltip
            title={
              isNavigationCollapsed
                ? t('text__expand_sidebar')
                : t('text__collapse_sidebar')
            }
            placement="bottom-start"
          >
            <div>
              <CollapseButton onClick={() => collapseButtonClickHandler()}>
                {isNavigationCollapsed ? <ExpandIcon /> : <CollapseIcon />}
              </CollapseButton>
            </div>
          </CustomWidthTooltip>
          <LogoContainer
            to="/home"
            $isNavigationCollapsed={isNavigationCollapsed}
            $isNavigationHovered={isNavigationHovered}
          >
            <GogobusinessLogo
              $isNavigationCollapsed={isNavigationCollapsed}
              $isNavigationHovered={isNavigationHovered}
            />
          </LogoContainer>
        </TopBar>

        <AdaptiveMenuBlock
          $isNavigationCollapsed={isNavigationCollapsed}
          $isNavigationHovered={isNavigationHovered}
        >
          <UserInfoContainer data-testid="navigation-bar-footer-menu-user">
            <UserInfo onClick={handleToggleSubMenuClick}>
              <UserImage src={profilePhotoSrc} />
              <UserInfoContent>
                <SmallText noMargin>{organizationName}</SmallText>
                <BodyBold noMargin>{userName}</BodyBold>
                <SmallText noMargin>
                  {userLevel === 'b2b_admin_user' &&
                    t('usersAndBranches.usersTable.accessLevel.admin')}
                  {userLevel === 'b2b_master_user' && t('common__master_user')}
                  {userLevel === 'b2b_branch_user' && t('common__branch')}
                  {userLevel === 'b2b_sub_user' && t('common__user')}
                </SmallText>
              </UserInfoContent>
              <ChevronIconNoMargin />
            </UserInfo>
            {creditCardUserWithPrepaid && (
              <BalanceInfo onClick={() => setShowTopupModal(true)}>
                <TopupButtonAmount data-cy="topup-amount">
                  {formatCurrency({ amount: totalCredit })}
                </TopupButtonAmount>
                <Tooltip title={t('common__top-up')} placement="top">
                  <div>
                    <TopupIcon />
                  </div>
                </Tooltip>
              </BalanceInfo>
            )}
          </UserInfoContainer>
          <CollapseUserInfoContainer>
            <ProfilePhoto $profilePhotoSrc={profilePhotoSrc} />
            {creditCardUserWithPrepaid && <TopupIcon />}
          </CollapseUserInfoContainer>
        </AdaptiveMenuBlock>
        {showHomepage && (
          <HomeSection
            activePath={activePath}
            isNavigationCollapsed={isNavigationCollapsed}
            isNavigationHovered={isNavigationHovered}
          />
        )}
        {isAdminUser(currentUser) && (
          <AdminSection
            organizationName={organizationName}
            isOrganizationSwitchOpen={isOrganizationSwitchOpen}
            setIsOrganizationSwitchOpen={setIsOrganizationSwitchOpen}
            isNavigationCollapsed={isNavigationCollapsed}
            isNavigationHovered={isNavigationHovered}
          />
        )}
        <VanBookingSection
          activePath={activePath}
          isNavigationCollapsed={isNavigationCollapsed}
          isNavigationHovered={isNavigationHovered}
          showVanBookingMenu={showVanBookingMenu}
          showTransportBulkImport={showTransportBulkImport}
          showFavoriteDrivers={showFavoriteDrivers}
          showBillingNavigation={showBillingNavigation}
          isWalletUser={isWalletUser(currentUser)}
        />
        {featureFlagGogodelivery && (
          <DeliverySection
            activePath={activePath}
            isNavigationCollapsed={isNavigationCollapsed}
            isNavigationHovered={isNavigationHovered}
            hasNDDService={creditCardUserWithNDD}
            showDeliveryBulkImport={showDeliveryBulkImport}
          />
        )}
        {creditCardUserWithNDD && (
          <NDDSection
            activePath={activePath}
            isNavigationCollapsed={isNavigationCollapsed}
            isNavigationHovered={isNavigationHovered}
          />
        )}
        <BillingSection
          activePath={activePath}
          isNavigationCollapsed={isNavigationCollapsed}
          isNavigationHovered={isNavigationHovered}
          showBillingNavigation={showBillingNavigation}
        />
      </NavigationInnerContainer>
      <Notification
        isNavigationCollapsed={isNavigationCollapsed}
        isNavigationHovered={isNavigationHovered}
        onMouseEnter={handleMouseEnterOrOver}
      />
      <Settings
        onMouseEnter={handleMouseEnterOrOver}
        onOutsideClick={() => setIsNavigationHovered(false)}
        isSubMenuOpen={isSubMenuOpen}
        subMenuOffset={userMenuOffset}
        setIsSubMenuOpen={setIsSubMenuOpen}
      />
      {showTopupModal && (
        <ConnectedTopupModal
          handleModalClose={() => setShowTopupModal(false)}
        />
      )}
    </NavigationContainer>
  )
}

Navigation.propTypes = {
  currentUser: PropTypes.object.isRequired,
  isNavigationCollapsed: PropTypes.bool,
  isNavigationHovered: PropTypes.bool,
  setIsNavigationCollapsed: PropTypes.func,
  setIsNavigationHovered: PropTypes.func,
  showHomepage: PropTypes.bool,
  showVanBookingMenu: PropTypes.bool,
  showTransportBulkImport: PropTypes.bool,
  showFavoriteDrivers: PropTypes.bool,
  showBillingNavigation: PropTypes.bool,
  showDeliveryBulkImport: PropTypes.bool,
  activePath: PropTypes.string,
}

export { Navigation }
