import Constants from '../constants/action'

const initialState = {
  depositTransactions: [],
  filterParams: {},
  currentPage: 1,
  prevPage: null,
  nextPage: null,
  totalPages: 0,
  totalCount: 0,
  loading: false,
  allIds: [],
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case Constants.SET_DEPOSIT_TRANSACTIONS: {
      return {
        ...state,
        depositTransactions: action.depositTransactions.data,
        prevPage: action.depositTransactions.meta.prev_page,
        nextPage: action.depositTransactions.meta.next_page,
        currentPage: action.depositTransactions.meta.current_page,
        totalPages: action.depositTransactions.meta.total_pages,
        totalCount: action.depositTransactions.meta.total_count,
        allIds: action.depositTransactions.meta.all_ids,
        loading: false,
      }
    }

    case Constants.SET_DEPOSIT_TRANSACTIONS_FETCHING: {
      return {
        ...state,
        loading: true,
        currentPage: state.currentPage,
        totalPages: state.totalPages,
        totalCount: state.totalCount,
      }
    }

    case Constants.SET_DEPOSIT_TRANSACTIONS_ERROR:
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}
