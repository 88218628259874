import enHK from './en_hk'
import enSG from './en_sg'
import zhHK from './zh_hk'
import zhTW from './zh_tw'
import viVN from './vi_vn'

export default {
  'en-hk': enHK,
  'en-sg': enSG,
  'zh-hk': zhHK,
  'zh-tw': zhTW,
  'vi-vn': viVN,
}
