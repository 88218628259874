import PropTypes from 'prop-types'
import Color from './color'

function TextArea({ width, height = '100px', style, ...props }) {
  const _style = {
    width,
    height,
    border: `solid 1px ${Color.ALTO_GREY}`,
    ...style,
  }

  return <textarea type="text" style={_style} {...props} />
}

TextArea.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.object,
}

export default TextArea
