import * as types from './types'

const initialState = false

function collapseSideBarReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.COLLAPSE_SIDEBAR:
      return !state

    default:
      return state
  }
}

function hideToastContainerReducer(state = false, action = {}) {
  switch (action.type) {
    case types.HIDE_TOAST_CONTAINER:
      return typeof action.value === 'boolean' ? action.value : false

    default:
      return state
  }
}

function bannerReducer(state = {}, action = {}) {
  switch (action.type) {
    case types.SHOW_BANNER:
      return {
        key: action.key,
        parameter: action.parameter,
      }

    case types.HIDE_BANNER:
      return {}

    default:
      return state
  }
}

function couponReducer(
  state = {
    active_voucher_ids: [],
    history_voucher_ids: [],
    vouchers: {},
  },
  action = {}
) {
  switch (action.type) {
    case types.SET_COUPON:
      return action.payload

    default:
      return state
  }
}

function modalReducer(
  state = {
    creditCard: {
      visible: false,
      cardItent: {},
    },
  },
  action = {}
) {
  switch (action.type) {
    case types.SHOW_ADD_CREDIT_CARD_MODAL:
      return {
        ...state,
        creditCard: action.payload,
      }
    case types.HIDE_ADD_CREDIT_CARD_MODAL:
      return {
        ...state,
        creditCard: {
          visible: false,
          cardItent: {},
        },
      }
    default:
      return state
  }
}

const featureFlagGlobalReducers = {
  collapseSidebar: collapseSideBarReducer,
  hideToastContainer: hideToastContainerReducer,
  banner: bannerReducer,
  coupons: couponReducer,
  modal: modalReducer,
}

export { featureFlagGlobalReducers }
