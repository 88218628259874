import styled from "styled-components"

import { Color } from "../helpers"

const WalletTabbedContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const TabSection = styled.div`
  background-color: ${Color.PORCELAIN_GREY};
`

const TabContent = styled.div`
  background-color: ${Color.WHITE};
`

const ActionBarContainer = styled.div`
  padding-left: 20px;
  padding-bottom: 20px;
`

export { WalletTabbedContainer, TabSection, TabContent, ActionBarContainer }
