import styled from "styled-components"

import { SPACING } from "ggx-componentlibrary/design/spacing/spacing.constants"
import { Body, BodyBold } from "ggx-componentlibrary/design/typography/typography.styles"
import { FONT_SIZE,  } from "ggx-componentlibrary/design/typography/typography.constants"
import { StyledButton } from "ggx-componentlibrary/components/button/button.component.styles"
import PayNowLogoSVG from "../assets/paynow.svg"
import IconExternalLink from "ggx-src/assets/external_link.svg"

const DROPDOWN_MAXHEIGHT = 245
const DROPDOWN_WIDTH = "273px"
const CREDIT_CARD_GROUP_WIDTH = "397px"

const DropdownContainer = styled.div`
  width: ${DROPDOWN_WIDTH};
`

const StyledBody = styled(BodyBold)`
  margin: 0 0 ${SPACING.S};
`

const PaymentOptionHeading = styled(Body)`
  margin-bottom: ${SPACING.S};
`

const Footer = styled.div`
  margin-top: ${SPACING.XL};
  display: flex;
  justify-content: flex-end;
  
  ${StyledButton} {
    margin-right: ${SPACING.S};
    :last-of-type {
      margin-right: 0;
    }
  }
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
`
const QRCodeContainer = styled.div`
  flex: auto 0 0;
  margin-right: 24px;
`
const QRCode = styled.div`
  margin-bottom: ${SPACING.XS};
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: 170px;
  height: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  img {
    width: 100%;
    height: 100%;
  }
`

const PayNowLogo = styled(PayNowLogoSVG)`
  display: block;
  margin: auto;
`
const StepList = styled.div`
  flex: auto;
`
const Step = styled(Body)`
  margin-bottom: ${SPACING.S};
`
const StyledExternalLinkIcon = styled(IconExternalLink)`
  margin-left: ${SPACING.XXS};
  width: ${FONT_SIZE.LARGE};
  height: ${FONT_SIZE.LARGE};
  vertical-align: bottom;
`

export {
  DropdownContainer,
  StyledBody,
  PaymentOptionHeading,
  DROPDOWN_MAXHEIGHT,
  CREDIT_CARD_GROUP_WIDTH,
  Footer,
  Row,
  QRCodeContainer,
  QRCode,
  PayNowLogo,
  StepList,
  Step,
  StyledExternalLinkIcon,
}
