/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */

const VEHICLE_TRANSLATIONS = {
  van: 'common__van',
  premiumVan: 'premium_van',
  van500: 'common__vehicle__van_500kg',
  van1000: 'common__vehicle__van_1000kg',
  mudou: 'common__truck_55ton',
  mudou9: 'common__truck_9ton',
  motorcycle: 'newOrder.tab.orderInfo.selection.vehicleType.motorcycle',
  sedan: 'newOrder.tab.orderInfo.selection.vehicleType.sedan',
  lorry10: 'newOrder.tab.orderInfo.selection.vehicleType.lorry10',
  lorry14: 'newOrder.tab.orderInfo.selection.vehicleType.lorry14',
  lorry24: 'newOrder.tab.orderInfo.selection.vehicleType.lorry24',
  struck: 'newOrder.tab.orderInfo.selection.vehicleType.struck',
  mtruck: 'newOrder.tab.orderInfo.selection.vehicleType.mtruck',
  tricycle: 'newOrder.tab.orderInfo.selection.vehicleType.tricycle',
  truck500: 'newOrder.tab.orderInfo.selection.vehicleType.truck500',
  truck1000: 'newOrder.tab.orderInfo.selection.vehicleType.truck1000',
  truck1500: 'newOrder.tab.orderInfo.selection.vehicleType.truck1500',
  truck2000: 'newOrder.tab.orderInfo.selection.vehicleType.truck2000',
}

const getVehicleIcon = (country, vehicleType) => {
  try {
    return require(`./assets/${country}/${vehicleType}.svg`)
  } catch (error) {
    return require('./assets/HK/van.svg')
  }
}

export { VEHICLE_TRANSLATIONS, getVehicleIcon }
