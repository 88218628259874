import * as types from './types'

const initialState = false

function featureFlagDeveloperReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.FEATUREFLAG_DEVELOPER:
      return typeof action.value === 'boolean' ? action.value : initialState

    default:
      return state
  }
}

const developerReducers = {
  featureFlagDeveloper: featureFlagDeveloperReducer,
}

export { developerReducers }
