import styled from "styled-components"

import { Color } from "../../helpers"

const OrderRequestIdButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${Color.LOCHMARA_BLUE};
`

export { OrderRequestIdButton }
