import styled from "styled-components"

import { getTypographyStyling } from "./service"
import {
  FONT_SIZE,
  LINE_HEIGHT,
  FONT_BOLD,
  FONT_REGULAR,
} from "./typography.constants"
import { COLOR } from "../color/color.constants"
import { SPACING } from "../spacing/spacing.constants"

/**
 * @typedef typography_constants
 * @example
 *
 * Margin Constants
 * XXS: '4px'  XS:   '8px' S:   '16px' M:   '24px' L:   '32px'
 * XL: '40px'  XL2: '48px' XL3: '56px' XL4: '64px' XL5: '128px'
 *
 * Font size Constants
 * SMALL:   '12px'  MEDIUM:   '14px'  LARGE: '16px' XLARGE: '20px'
 * XXLARGE: '24px'  XXXLARGE: '32px'
 *
 * Color constants
 * GREY_LIGHTEST GREY_LIGHTER  GREY_LIGHT  GREY_MEDIUM GREY_DARK GREY_DARKER
 * BLUE_LIGHTER  BLUE_LIGHT    BLUE_MEDIUM BLUE_DARK
 * TEAL_LIGHT    TEAL_MEDIUM
 * PURPLE        PURPLE_DARK
 * RED_LIGHT     RED_MEDIUM
 * ORANGE_MEDIUM ORANGE_DARK
 * YELLOW_LIGHT  YELLOW_MEDIUM
 * BLACK         WHITE
 */
const HeadingJumbo = styled.h1`
  margin: 0 0 ${SPACING.XS} 0;
  ${FONT_BOLD}
  font-size: ${FONT_SIZE.XXXLARGE};
  line-height: ${LINE_HEIGHT.XXXLARGE};
  color: ${COLOR.BLACK};
  ${(props) => getTypographyStyling(props.typographyStyles)}
`
HeadingJumbo.displayName = "HeadingJumbo"

/**
 * @typedef typography_constants
 * @example
 *
 * Margin Constants
 * XXS: '4px'  XS:   '8px' S:   '16px' M:   '24px' L:   '32px'
 * XL: '40px'  XL2: '48px' XL3: '56px' XL4: '64px' XL5: '128px'
 *
 * Font size Constants
 * SMALL:   '12px'  MEDIUM:   '14px'  LARGE: '16px' XLARGE: '20px'
 * XXLARGE: '24px'  XXXLARGE: '32px'
 *
 * Color constants
 * GREY_LIGHTEST GREY_LIGHTER  GREY_LIGHT  GREY_MEDIUM GREY_DARK GREY_DARKER
 * BLUE_LIGHTER  BLUE_LIGHT    BLUE_MEDIUM BLUE_DARK
 * TEAL_LIGHT    TEAL_MEDIUM
 * PURPLE        PURPLE_DARK
 * RED_LIGHT     RED_MEDIUM
 * ORANGE_MEDIUM ORANGE_DARK
 * YELLOW_LIGHT  YELLOW_MEDIUM
 * BLACK         WHITE
 */
const HeadingXL = styled.h1`
  margin: 0 0 ${SPACING.XS} 0;
  ${FONT_BOLD}
  font-size: ${FONT_SIZE.XXLARGE};
  line-height: ${LINE_HEIGHT.XXLARGE};
  color: ${COLOR.BLACK};
  ${(props) => getTypographyStyling(props.typographyStyles)}
`
HeadingXL.displayName = "HeadingXL"

/**
 * @typedef typography_constants
 * @example
 *
 * Margin Constants
 * XXS: '4px'  XS:   '8px' S:   '16px' M:   '24px' L:   '32px'
 * XL: '40px'  XL2: '48px' XL3: '56px' XL4: '64px' XL5: '128px'
 *
 * Font size Constants
 * SMALL:   '12px'  MEDIUM:   '14px'  LARGE: '16px' XLARGE: '20px'
 * XXLARGE: '24px'  XXXLARGE: '32px'
 *
 * Color constants
 * GREY_LIGHTEST GREY_LIGHTER  GREY_LIGHT  GREY_MEDIUM GREY_DARK GREY_DARKER
 * BLUE_LIGHTER  BLUE_LIGHT    BLUE_MEDIUM BLUE_DARK
 * TEAL_LIGHT    TEAL_MEDIUM
 * PURPLE        PURPLE_DARK
 * RED_LIGHT     RED_MEDIUM
 * ORANGE_MEDIUM ORANGE_DARK
 * YELLOW_LIGHT  YELLOW_MEDIUM
 * BLACK         WHITE
 */
const HeadingL = styled.h2`
  margin: 0 0 ${SPACING.XS} 0;
  ${FONT_BOLD}
  font-size: ${FONT_SIZE.XLARGE};
  line-height: ${LINE_HEIGHT.XLARGE};
  color: ${COLOR.BLACK};
  ${(props) => getTypographyStyling(props.typographyStyles)}
`
HeadingL.displayName = "HeadingL"

/**
 * @typedef typography_constants
 * @example
 *
 * Margin Constants
 * XXS: '4px'  XS:   '8px' S:   '16px' M:   '24px' L:   '32px'
 * XL: '40px'  XL2: '48px' XL3: '56px' XL4: '64px' XL5: '128px'
 *
 * Font size Constants
 * SMALL:   '12px'  MEDIUM:   '14px'  LARGE: '16px' XLARGE: '20px'
 * XXLARGE: '24px'  XXXLARGE: '32px'
 *
 * Color constants
 * GREY_LIGHTEST GREY_LIGHTER  GREY_LIGHT  GREY_MEDIUM GREY_DARK GREY_DARKER
 * BLUE_LIGHTER  BLUE_LIGHT    BLUE_MEDIUM BLUE_DARK
 * TEAL_LIGHT    TEAL_MEDIUM
 * PURPLE        PURPLE_DARK
 * RED_LIGHT     RED_MEDIUM
 * ORANGE_MEDIUM ORANGE_DARK
 * YELLOW_LIGHT  YELLOW_MEDIUM
 * BLACK         WHITE
 */
const Heading = styled.h3`
  margin: ${(props) => (props.noMargin ? "0" : `0 0 ${SPACING.XS} 0`)};
  ${FONT_BOLD}
  font-size: ${FONT_SIZE.LARGE};
  line-height: ${LINE_HEIGHT.LARGE};
  color: ${COLOR.BLACK};
  ${(props) => getTypographyStyling(props.typographyStyles)}
`
Heading.displayName = "Heading"

/**
 * @typedef typography_constants
 * @example
 *
 * Margin Constants
 * XXS: '4px'  XS:   '8px' S:   '16px' M:   '24px' L:   '32px'
 * XL: '40px'  XL2: '48px' XL3: '56px' XL4: '64px' XL5: '128px'
 *
 * Font size Constants
 * SMALL:   '12px'  MEDIUM:   '14px'  LARGE: '16px' XLARGE: '20px'
 * XXLARGE: '24px'  XXXLARGE: '32px'
 *
 * Color constants
 * GREY_LIGHTEST GREY_LIGHTER  GREY_LIGHT  GREY_MEDIUM GREY_DARK GREY_DARKER
 * BLUE_LIGHTER  BLUE_LIGHT    BLUE_MEDIUM BLUE_DARK
 * TEAL_LIGHT    TEAL_MEDIUM
 * PURPLE        PURPLE_DARK
 * RED_LIGHT     RED_MEDIUM
 * ORANGE_MEDIUM ORANGE_DARK
 * YELLOW_LIGHT  YELLOW_MEDIUM
 * BLACK         WHITE
 */
const HeadingSmall = styled.h4`
  margin: 0 0 ${SPACING.XS} 0;
  ${FONT_BOLD}
  font-size: ${FONT_SIZE.MEDIUM};
  line-height: 20px;
  color: ${COLOR.BLACK};
  ${(props) => getTypographyStyling(props.typographyStyles)}
`

HeadingSmall.displayName = "HeadingSmall"

/**
 * @typedef typography_constants
 * @example
 *
 * Margin Constants
 * XXS: '4px'  XS:   '8px' S:   '16px' M:   '24px' L:   '32px'
 * XL: '40px'  XL2: '48px' XL3: '56px' XL4: '64px' XL5: '128px'
 *
 * Font size Constants
 * SMALL:   '12px'  MEDIUM:   '14px'  LARGE: '16px' XLARGE: '20px'
 * XXLARGE: '24px'  XXXLARGE: '32px'
 *
 * Color constants
 * GREY_LIGHTEST GREY_LIGHTER  GREY_LIGHT  GREY_MEDIUM GREY_DARK GREY_DARKER
 * BLUE_LIGHTER  BLUE_LIGHT    BLUE_MEDIUM BLUE_DARK
 * TEAL_LIGHT    TEAL_MEDIUM
 * PURPLE        PURPLE_DARK
 * RED_LIGHT     RED_MEDIUM
 * ORANGE_MEDIUM ORANGE_DARK
 * YELLOW_LIGHT  YELLOW_MEDIUM
 * BLACK         WHITE
 */
const BodyBold = styled.p`
  margin: ${(props) => (props.noMargin ? "0" : `0 0 ${SPACING.XS} 0`)};
  ${FONT_BOLD}
  font-size: ${FONT_SIZE.MEDIUM};
  line-height: ${LINE_HEIGHT.MEDIUM};
  color: ${COLOR.BLACK};
  ${(props) => getTypographyStyling(props.typographyStyles)}
`
BodyBold.displayName = "BodyBold"

/**
 * @typedef typography_constants
 * @example
 *
 * Margin Constants
 * XXS: '4px'  XS:   '8px' S:   '16px' M:   '24px' L:   '32px'
 * XL: '40px'  XL2: '48px' XL3: '56px' XL4: '64px' XL5: '128px'
 *
 * Font size Constants
 * SMALL:   '12px'  MEDIUM:   '14px'  LARGE: '16px' XLARGE: '20px'
 * XXLARGE: '24px'  XXXLARGE: '32px'
 *
 * Color constants
 * GREY_LIGHTEST GREY_LIGHTER  GREY_LIGHT  GREY_MEDIUM GREY_DARK GREY_DARKER
 * BLUE_LIGHTER  BLUE_LIGHT    BLUE_MEDIUM BLUE_DARK
 * TEAL_LIGHT    TEAL_MEDIUM
 * PURPLE        PURPLE_DARK
 * RED_LIGHT     RED_MEDIUM
 * ORANGE_MEDIUM ORANGE_DARK
 * YELLOW_LIGHT  YELLOW_MEDIUM
 * BLACK         WHITE
 */
const Body = styled.p`
  margin: ${(props) => (props.noMargin ? "0" : `0 0 ${SPACING.XS} 0`)};
  ${FONT_REGULAR}
  font-size: ${FONT_SIZE.MEDIUM};
  line-height: ${LINE_HEIGHT.MEDIUM};
  color: ${COLOR.BLACK};
  ${(props) => getTypographyStyling(props.typographyStyles)}
  white-space: pre-line;
`
Body.displayName = "Body"

const TextLinkStyles = `
  color: ${COLOR.BLUE_MEDIUM};
  text-decoration: none;
  cursor: pointer;
  ${FONT_REGULAR}

  &:hover, &:focus {
    text-decoration: underline;
  }
`

/**
 * @typedef typography_constants
 * @example
 *
 * Margin Constants
 * XXS: '4px'  XS:   '8px' S:   '16px' M:   '24px' L:   '32px'
 * XL: '40px'  XL2: '48px' XL3: '56px' XL4: '64px' XL5: '128px'
 *
 * Font size Constants
 * SMALL:   '12px'  MEDIUM:   '14px'  LARGE: '16px' XLARGE: '20px'
 * XXLARGE: '24px'  XXXLARGE: '32px'
 *
 * Color constants
 * GREY_LIGHTEST GREY_LIGHTER  GREY_LIGHT  GREY_MEDIUM GREY_DARK GREY_DARKER
 * BLUE_LIGHTER  BLUE_LIGHT    BLUE_MEDIUM BLUE_DARK
 * TEAL_LIGHT    TEAL_MEDIUM
 * PURPLE        PURPLE_DARK
 * RED_LIGHT     RED_MEDIUM
 * ORANGE_MEDIUM ORANGE_DARK
 * YELLOW_LIGHT  YELLOW_MEDIUM
 * BLACK         WHITE
 */
const TextLink = styled.a`
  ${TextLinkStyles}
  ${(props) => getTypographyStyling(props.typographyStyles)}
`
TextLink.displayName = "Link"

/**
 * @typedef typography_constants
 * @example
 *
 * Margin Constants
 * XXS: '4px'  XS:   '8px' S:   '16px' M:   '24px' L:   '32px'
 * XL: '40px'  XL2: '48px' XL3: '56px' XL4: '64px' XL5: '128px'
 *
 * Font size Constants
 * SMALL:   '12px'  MEDIUM:   '14px'  LARGE: '16px' XLARGE: '20px'
 * XXLARGE: '24px'  XXXLARGE: '32px'
 *
 * Color constants
 * GREY_LIGHTEST GREY_LIGHTER  GREY_LIGHT  GREY_MEDIUM GREY_DARK GREY_DARKER
 * BLUE_LIGHTER  BLUE_LIGHT    BLUE_MEDIUM BLUE_DARK
 * TEAL_LIGHT    TEAL_MEDIUM
 * PURPLE        PURPLE_DARK
 * RED_LIGHT     RED_MEDIUM
 * ORANGE_MEDIUM ORANGE_DARK
 * YELLOW_LIGHT  YELLOW_MEDIUM
 * BLACK         WHITE
 */
const SmallTextBold = styled.p`
  margin: ${(props) => (props.noMargin ? "0" : `0 0 ${SPACING.XS} 0`)};
  ${FONT_BOLD}
  font-size: ${FONT_SIZE.SMALL};
  line-height: ${LINE_HEIGHT.SMALL};
  color: ${COLOR.GREY_DARK};
  ${(props) => getTypographyStyling(props.typographyStyles)}
`
SmallTextBold.displayName = "SmallTextBold"

/**
 * @typedef typography_constants
 * @example
 *
 * Margin Constants
 * XXS: '4px'  XS:   '8px' S:   '16px' M:   '24px' L:   '32px'
 * XL: '40px'  XL2: '48px' XL3: '56px' XL4: '64px' XL5: '128px'
 *
 * Font size Constants
 * SMALL:   '12px'  MEDIUM:   '14px'  LARGE: '16px' XLARGE: '20px'
 * XXLARGE: '24px'  XXXLARGE: '32px'
 *
 * Color constants
 * GREY_LIGHTEST GREY_LIGHTER  GREY_LIGHT  GREY_MEDIUM GREY_DARK GREY_DARKER
 * BLUE_LIGHTER  BLUE_LIGHT    BLUE_MEDIUM BLUE_DARK
 * TEAL_LIGHT    TEAL_MEDIUM
 * PURPLE        PURPLE_DARK
 * RED_LIGHT     RED_MEDIUM
 * ORANGE_MEDIUM ORANGE_DARK
 * YELLOW_LIGHT  YELLOW_MEDIUM
 * BLACK         WHITE
 */
const SmallText = styled.p`
  margin: ${(props) => (props.noMargin ? "0" : `0 0 ${SPACING.XS} 0`)};
  ${FONT_REGULAR}
  font-size: ${FONT_SIZE.SMALL};
  line-height: ${LINE_HEIGHT.SMALL};
  color: ${COLOR.GREY_DARK};
  ${(props) => getTypographyStyling(props.typographyStyles)}
`
SmallText.displayName = "SmallText"

export {
  HeadingJumbo,
  HeadingXL,
  HeadingL,
  Heading,
  HeadingSmall,
  BodyBold,
  Body,
  TextLinkStyles,
  TextLink,
  SmallTextBold,
  SmallText,
}
