import * as types from './types'

const initialPaymentState = {
  savedCardInfo: {
    brand: null,
    last4: null,
    hasBeenRetrieved: false,
  },
  availablePaymentMethods: [],
  stripeCards: [],
}

function paymentReducers(state = initialPaymentState, action = {}) {
  switch (action.type) {
    case types.SET_SAVED_CARD_INFO: {
      return {
        ...state,
        savedCardInfo: {
          ...state.savedCardInfo,
          ...action.value,
        },
      }
    }

    case types.SET_PAYMENT_INFO: {
      return {
        ...state,
        ...action.value,
      }
    }

    case types.SET_CARD_INFO: {
      const updatedStripeCards = state.stripeCards.map(card => {
        if (card.id !== action.value.id) {
          if (action.value.is_default) {
            // Only allow one card as default card.
            card.is_default = false
          }

          return card
        }

        return action.value
      })

      return {
        ...state,
        stripeCards: updatedStripeCards,
      }
    }

    case types.SET_CARDS_INFO: {
      return {
        ...state,
        stripeCards: action.value,
      }
    }

    case types.REMOVE_CARD: {
      return {
        ...state,
        stripeCards: state.stripeCards.filter(card => card.id !== action.value),
      }
    }

    default:
      return state
  }
}

export { paymentReducers }
