import GoogleMap from './gmap'

const MapUtils = GoogleMap

export async function placeDetails(geocoder, { lat, lng }) {
  const place = {
    lat,
    lng,
  }
  try {
    const places = await MapUtils.reverseGeocode(geocoder, { lat, lng })
    place.address = places[0].formatted_address
  } catch (err) {
    return err
  }
  return place
}

export default MapUtils
