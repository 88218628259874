import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { sessionSwitch } from '../actions/session'
import { featureFlagService } from '../../service/feature-flag/feature-flag'
import { getFeatureFlag } from 'ggx-service/feature-flag/selectors'
import { getAppVersion, setAppVersion } from '../../app-version-check/service'
import { getHideToastContainer } from '../../global/duck/selectors'
import { apiService } from '../../app-version-check/api/api.service'
import { displayAlert } from '../../component-library/components/alerts/alerts.component'
import { StyledToastContainer } from '../../component-library/components/alerts/alerts.component.styles'
import { StyledBody, StyledTextLink } from './main.styles'
import { FayeSubscription } from 'ggx-global/faye/faye-subscription.component'
import { DebugMenu } from 'ggx-global/debug-menu/debug-menu.component'
import { decodedToken } from './helpers/auth/tokens'

export class MainLayout extends Component {
  componentDidMount() {
    featureFlagService.triggerFeatureFlag('reload_page_message')
    window.addEventListener('visibilitychange', this.handleVisibilitychange);
  }

  componentDidUpdate(prevProps) {
    const { featureFlagReloadPage } = this.props
    const { featureFlagReloadPage: previousFeatureFlagReloadPage } = prevProps

    if (previousFeatureFlagReloadPage === featureFlagReloadPage) {
      return
    }

    if (featureFlagReloadPage) {
      const { t } = this.props

      const handleAppVersionCheck = async () => {
        try {
          const {
            app_version: appVersionOnMount,
          } = await apiService.requestAppVersion()
          setAppVersion(appVersionOnMount)

          this.intervalId = setInterval(async () => {
            const {
              app_version: appVersion,
            } = await apiService.requestAppVersion()
            const appVersionInStorage = getAppVersion()
            if (appVersion !== appVersionInStorage) {
              const message = () => (
                <div>
                  <StyledBody style={{ color: 'white' }}>
                    {t('systemMessage.deployment.message')}
                  </StyledBody>
                  <StyledTextLink
                    typographyStyles="c-blue_light"
                    onClick={() => {
                      window.location.reload()
                    }}
                  >
                    {t('systemMessage.deployment.reload')}
                  </StyledTextLink>
                </div>
              )

              const autoClose = false

              displayAlert({ message, autoClose })
              clearInterval(this.intervalId)
            }
          }, 60000)
        } catch (error) {
          console.log(error)
        }
      }

      handleAppVersionCheck()
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalId)
    window.removeEventListener('visibilitychange', this.handleVisibilitychange)
  }

  handleVisibilitychange = () => {
    if (
      document.visibilityState === 'visible' &&
      window.location.pathname !== '/login' &&
      window.location.pathname !== '/recovery' &&
      window.location.pathname !== '/customer-service' &&
      window.location.pathname !== '/signup' &&
      window.location.pathname !== '/activate-organisation-account' &&
      window.location.pathname !== '/set-password'
    ) {
      const { session, sessionSwitch } = this.props
      const { userId, sessionToken } = decodedToken()

      if (
        Number(session?.currentUser?.id) === Number(userId) &&
        session.currentUser.session_token === sessionToken
      )
        return

      sessionSwitch()
    }
  }

  render() {
    return (
      <div>
        <StyledToastContainer
          hideToastContainer={this.props.hideToastContainer}
        />
        <FayeSubscription />
        {ENV.ENABLE_DEBUG && <DebugMenu />}
        {this.props.children}
      </div>
    )
  }
}

MainLayout.propTypes = {
  children: PropTypes.any,
  hideToastContainer: PropTypes.bool,
  t: PropTypes.func,
}

const mapStateToProps = state => ({
  featureFlagReloadPage: getFeatureFlag({
    state,
    featureFlag: 'reload_page_message',
  }),
  hideToastContainer: getHideToastContainer(state),
  session: state.session,
})

const mapDispatchToProps = dispatch => {
  return {
    sessionSwitch: () => dispatch(sessionSwitch()),
  }
}

const ConnectedMainLayout = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainLayout)
const ConnectedMainLayoutWithtranslation = withTranslation()(
  ConnectedMainLayout
)

export default props => <ConnectedMainLayoutWithtranslation {...props} />
