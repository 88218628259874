import PropTypes from 'prop-types'

const LinkItem = ({ link, title }) => (
  <li>
    <a href={link} target="_blank" rel="noopener noreferrer">
      {title}
    </a>
  </li>
)

LinkItem.propTypes = {
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default LinkItem
