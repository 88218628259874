import styled from "styled-components"
import PropTypes from "prop-types"

import {
  FONT_REGULAR,
  FONT_SIZE,
} from "../../design/typography/typography.constants"
import { Body } from "../../design/typography/typography.styles"
import { SPACING } from "../../design/spacing/spacing.constants"
import { COLOR } from "../../design/color/color.constants"
import { Checkbox } from "../checkbox/checkbox.component"
import DeleteRowIcon from "./assets/remove.svg"

const StyledTableWrapper = styled.div`
  position: relative;

  ${({ multiRowHeaderGridLines = [] }) =>
    multiRowHeaderGridLines.length > 0
      ? ` & tr:first-of-type th {
            border-right: 1px solid ${COLOR.GREY_LIGHT}
          }

          & tr:first-of-type th:last-of-type {
            border-right: none;
          }
      `
      : ""}}

  ${({ supportRowDeletion }) =>
    supportRowDeletion
      ? ` & tr:first-of-type th:nth-last-child(2) {
              border-right: none;
            }
          `
      : ""}}

  & tr {
    ${({ multiRowHeaderGridLines = [] }) => {
      let templateLiteral = ``

      multiRowHeaderGridLines.forEach((index) => {
        templateLiteral += `
          & td:nth-of-type(${index}) {
            border-right: 1px solid ${COLOR.GREY_LIGHT}
          }

          & th:nth-of-type(${index}) {
            border-right: 1px solid ${COLOR.GREY_LIGHT}
          }
         `
      })
      return templateLiteral
    }}
  }
`

const StyledTable = styled.table`
  width: 100%;
  height: 100%;

  table-layout: ${(props) =>
    props.supportEdit || props.autoLayout ? "auto" : "fixed"};
  border-collapse: separate;
  ${FONT_REGULAR};
`

const StyledTableHeaderCell = styled.th`
  padding: ${SPACING.XS};
  border-bottom: 1px solid ${COLOR.GREY_LIGHT};
  color: ${COLOR.GREY_DARK};
  font-size: ${FONT_SIZE.SMALL};
  font-weight: 400;
  text-align: left;
  width: ${(props) => props.columnWidth};
  line-height: 16px;

  ${({ isSelectionColumn }) =>
    isSelectionColumn
      ? `
        width: 35px;
        padding-left: ${SPACING.S};
        padding-right: 0;
      `
      : ""}

  ${({ isDeleteRowColumn }) =>
    isDeleteRowColumn
      ? `
        width: 50px;
      `
      : ""}
`

/**
 * The Row component needs to always re-render in order to evaluate the new `isActivated`
 * prop in Reactabular's onRow function.
 */
const AlwaysRerenderedRow = ({
  supportActivation,
  isActivated,
  isSelected,
  ...props
}) => <tr {...props} />
AlwaysRerenderedRow.shouldComponentUpdate = true
AlwaysRerenderedRow.propTypes = {
  supportActivation: PropTypes.bool,
  isActivated: PropTypes.bool,
  isSelected: PropTypes.bool,
}

const StyledTableAlwaysRerenderedRow = styled(AlwaysRerenderedRow)`
  cursor: ${({ supportActivation }) => supportActivation && "pointer"};
  background: ${({ isActivated, isSelected, isDisabled}) => {
    if (isActivated === true && isSelected === true) {
      return COLOR.BLUE_LIGHT
    } else if (isSelected === true) {
      return COLOR.BLUE_LIGHTER
    } else if (isActivated === true) {
      return COLOR.GREY_LIGHTEST
    } else if (isDisabled === true) {
      return COLOR.GREY_MEDIUM
    }

    return "transparent"
  }};

  :hover {
    background-color: ${COLOR.GREY_LIGHTEST};
  }
`

const StyledTableBodyCell = styled.td`
  padding: ${SPACING.S} ${SPACING.XS};
  position: relative;
  box-shadow: inset 0 -1px 0 0 ${COLOR.GREY_LIGHT};
  color: ${COLOR.BLACK};
  font-size: ${FONT_SIZE.MEDIUM};
  line-height: 20px;
  vertical-align: top;
  text-align: left;
  overflow: ${({ overflow }) => overflow ?? "hidden" };

  ${({ isSelectionColumn }) =>
    isSelectionColumn
      ? `
        padding-left: ${SPACING.S};
        overflow: visible;
      `
      : ""}

  ${({ invalidRow }) =>
    invalidRow ? `background-color: ${COLOR.RED_LIGHT};` : ""}
`

const StyledLoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${(props) =>
    props.showOverlay ? "rgba(255, 255, 255, 0.8)" : "transparent"};

  > * {
    position: absolute;
    top: 230px;
    left: 50%;
    transform: translateX(-50%);
  }
`

const StyledEmptyDataMessage = styled(Body)`
  color: ${COLOR.GREY_DARK};
`

const StyledSelectionHeaderCheckbox = styled(Checkbox)`
  height: 16px;
  width: 16px;
`

const StyledSelectionCellCheckbox = styled(Checkbox)`
  display: flex;
  height: 24px;
  align-items: center;
`

const StyledDeleteRowIcon = styled(DeleteRowIcon)`
  cursor: pointer;
`

export {
  StyledTableWrapper,
  StyledTable,
  StyledTableAlwaysRerenderedRow,
  StyledTableHeaderCell,
  StyledTableBodyCell,
  StyledEmptyDataMessage,
  StyledLoadingWrapper,
  StyledSelectionHeaderCheckbox,
  StyledSelectionCellCheckbox,
  StyledDeleteRowIcon,
  DeleteRowIcon,
}
