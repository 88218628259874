import styled from "styled-components"
import { SPACING } from "../../design/spacing/spacing.constants"
import { FONT_SIZE } from "../../design/typography/typography.constants"
import { InputStyles } from "./../text-field/text-field.component.styles"

const StyledTextArea = styled(({ error, ...args }) => <textarea {...args} />)(
  (props) => {
    const { error } = props
    const inputStyles = InputStyles({
      error,
      ...props,
    })

    return `
    ${inputStyles}
    height: auto;
    padding: ${SPACING.XS} ${SPACING.S};
    resize: none;
    line-height: ${FONT_SIZE.XXLARGE};
    width: 100%;
  `
  }
)

export { StyledTextArea }
