import styled from 'styled-components'
import Link from "react-router/lib/Link"

import { HeadingXL, Body, TextLinkStyles } from "ggx-componentlibrary/design/typography/typography.styles"
import { FONT_SIZE, LINE_HEIGHT } from "ggx-componentlibrary/design/typography/typography.constants"
import { SPACING } from "ggx-componentlibrary/design/spacing/spacing.constants"

const AuthErrorContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const StyledHeadingXL = styled(HeadingXL)`
  margin: ${SPACING.M}
`

const StyledBody = styled(Body)`
  font-size: ${FONT_SIZE.LARGE};
  line-height: ${LINE_HEIGHT.LARGE};
  margin-bottom: ${SPACING.M}
`

const StyledTextLink = styled(Link)`
  ${TextLinkStyles}
  font-size: ${FONT_SIZE.LARGE};
`

export {
  AuthErrorContainer,
  StyledHeadingXL,
  StyledBody,
  StyledTextLink
}

