import styled from "styled-components"

import { FONT_SIZE } from "ggx-componentlibrary/design/typography/typography.constants"
import { SPACING } from "ggx-componentlibrary/design/spacing/spacing.constants"
import { COLOR } from "ggx-componentlibrary/design/color/color.constants"
import CloseSVG from "../../paid-subscription-plan/assets/close.svg"
import PhoneSVG from "../../paid-subscription-plan/assets/phone.svg"
import IconExternalLink from "../../assets/external_link.svg"

const fabIconLargeDuration = "0.1s"
const CardContainerDuration = "0.3s"

const CardContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: ${COLOR.WHITE};
  display: flex;
  flex-direction: column;
  padding: ${SPACING.S};
  width: 360px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: ${SPACING.S};
  opacity: ${(props) => (props.$isOpen ? 1 : 0)};
  transform: translateY(${(props) => (props.$isOpen ? 0 : "100%")});
  transition: transform ${CardContainerDuration},
    opacity ${CardContainerDuration};
  transition-delay: ${(props) => (props.$isOpen ? fabIconLargeDuration : 0)};
  pointer-events: ${(props) => (props.$isOpen ? "auto" : "none")};
`

const CardRow = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: ${SPACING.M};
  min-height: 40px;
`

const FabIconSmall = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${COLOR.WHITE};
  background-color: ${COLOR.BLUE_MEDIUM};
  font-size: ${FONT_SIZE.XXLARGE};
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: ${SPACING.S};
  flex-shrink: 0;
`

const FabIconLarge = styled.a`
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${COLOR.WHITE};
  font-size: ${FONT_SIZE.XXXLARGE};
  width: ${SPACING.XL4};
  height: ${SPACING.XL4};
  border: 8px solid white;
  border-radius: 50%;
  cursor: pointer;
  background-color: ${COLOR.BLUE_MEDIUM};
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);

  opacity: ${(props) => (props.$isOpen ? 1 : 0)};
  transform: translateY(${(props) => (props.$isOpen ? 0 : "100%")});

  transition-delay: ${(props) => (props.$isOpen ? CardContainerDuration : 0)};
  transition: background-color 0s,
    transform ${fabIconLargeDuration}, opacity ${fabIconLargeDuration};
  :hover {
    background-color: ${COLOR.BLUE_DARK};
  }
`

const ContactButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: ${COLOR.GREY_LIGHTER};
  padding: ${SPACING.XS} 0;
`

const CloseIcon = styled(CloseSVG)`
  position: relative;
  top: -3px;
  margin-left: auto;
  cursor: pointer;
  flex-shrink: 0;
`

const PhoneIcon = styled(PhoneSVG)`
  margin-right: ${SPACING.XS};
`

const Schedule = styled.div`
  position: relative;
  top: -3px;
`

const StyledExternalLink = styled(IconExternalLink)`
  position: relative;
  top: 2px;
  height: 16px;
  width: 16px;
`

export {
  CardContainer,
  FabIconLarge,
  FabIconSmall,
  CardRow,
  ContactButton,
  CloseIcon,
  PhoneIcon,
  Schedule,
  StyledExternalLink,
}
