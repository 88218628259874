import { useEffect, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { socket } from 'ggx-global/faye/faye.setup'

import {
  handleAddCreditCardSuccess,
  getSavedCard,
} from 'ggx-src/payment/duck/actions'

import { setSubscriptionInfo } from '../duck/actions'

const useSubscriptionNotification = () => {
  const subscribedToChannelRef = useRef(false)
  const dispatch = useDispatch()
  const organisationId = useSelector(
    state => state?.organization?.organization?.id
  )
  const [t] = useTranslation()

  const onMessageReceived = useCallback(
    message => {
      const { event_type, action, status } = message

      if (
        event_type === 'payment' &&
        action === 'add_credit_card' &&
        status === 200
      ) {
        dispatch(handleAddCreditCardSuccess())
        dispatch(getSavedCard())
      } else {
        dispatch(setSubscriptionInfo())
      }
    },
    [dispatch, t]
  )

  useEffect(() => {
    if (organisationId && !subscribedToChannelRef.current) {
      const subscription = socket.subscribe(
        `/notification/subscription/${organisationId}`,
        onMessageReceived
      )

      subscription.callback(() => {
        subscribedToChannelRef.current = true
      })
      return () => subscription.unsubscribe()
    }
  }, [dispatch, organisationId, onMessageReceived])
}

export { useSubscriptionNotification }
