import styled from "styled-components"

import { SPACING } from "../../design/spacing/spacing.constants"
import { Body } from "../../design/typography/typography.styles"

const HiddenCheckbox = styled.input.attrs(() => ({ type: "checkbox" }))`
  display: none;
`

const DisplayedCheckbox = styled.div`
  height: 16px;
  margin-right: ${SPACING.S};
`

const CheckboxContainer = styled.div`
  display: inline-flex;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
  user-select: none;
`

const StyledBody = styled(Body)`
  margin: 0;
`

const SubContainer = styled.div`
  margin: ${SPACING.XS} 0 0 32px;
`

const StyledLabel = styled.label`
  display: flex;
`

export {
  HiddenCheckbox,
  DisplayedCheckbox,
  CheckboxContainer,
  StyledBody,
  SubContainer,
  StyledLabel,
}
