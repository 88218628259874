import React from 'react'
import PropTypes from 'prop-types'

import { StyledSelectionHeaderCheckbox } from './table.component.styles'

const SelectionHeader = ({ onSelect, indeterminate, allSelected }) => (
  <StyledSelectionHeaderCheckbox
    onChange={onSelect}
    indeterminate={indeterminate}
    value={allSelected}
  />
)

SelectionHeader.propTypes = {
  onSelect: PropTypes.func,
  indeterminate: PropTypes.bool,
  allSelected: PropTypes.bool,
}

export { SelectionHeader }
