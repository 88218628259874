import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { getActiveCouponNum } from 'ggx-global/duck/selectors'

import { Label } from '../../navigation.component.styles'
import CouponSVG from '../../assets/coupon.svg'

import {
  StyledCouponRow,
  CouponBadge,
  CouponIconWrapper,
} from './coupon-row.component.styles'

const CouponRow = ({
  activePath,
  isNavigationCollapsed,
  isNavigationHovered,
}) => {
  const [t] = useTranslation()
  const couponNum = useSelector(state => getActiveCouponNum(state))

  return (
    <StyledCouponRow
      to="/voucher"
      $isActive={activePath === '/voucher'}
      $isNavigationCollapsed={isNavigationCollapsed}
      $isNavigationHovered={isNavigationHovered}
    >
      <CouponIconWrapper
        $isNavigationCollapsed={isNavigationCollapsed}
        $isNavigationHovered={isNavigationHovered}
      >
        <CouponBadge
          $isNavigationCollapsed={isNavigationCollapsed}
          $isNavigationHovered={isNavigationHovered}
        >
          <span>{couponNum}</span>
        </CouponBadge>
        <CouponSVG $isActive={activePath === '/voucher'} />
      </CouponIconWrapper>
      <Label>{t('common__coupons')}</Label>
      <CouponBadge
        $isNavigationCollapsed={isNavigationCollapsed}
        $isNavigationHovered={isNavigationHovered}
      >
        <span>{couponNum}</span>
      </CouponBadge>
    </StyledCouponRow>
  )
}

CouponRow.propTypes = {
  activePath: PropTypes.string,
  isNavigationCollapsed: PropTypes.bool,
  isNavigationHovered: PropTypes.bool,
}

export default CouponRow
