const FEATUREFLAG_ORDER_DETAIL_REVAMP = 'FEATUREFLAG_ORDER_DETAIL_REVAMP'
const SET_FILTER_STATUS = 'SET_FILTER_STATUS'
const SET_FILTER_DATE = 'SET_FILTER_DATE'
const SET_FILTER_BRANCH = 'SET_FILTER_BRANCH'
const SET_ORDER_SEARCH_VALUE = 'SET_ORDER_SEARCH_VALUE'
const CLEAR_FILTERS = 'CLEAR_FILTERS'

export {
  FEATUREFLAG_ORDER_DETAIL_REVAMP,
  SET_FILTER_STATUS,
  SET_FILTER_DATE,
  SET_FILTER_BRANCH,
  SET_ORDER_SEARCH_VALUE,
  CLEAR_FILTERS,
}
