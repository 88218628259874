import getCountry from '../../views/helpers/get_country'

const defaultFeatureConfig = {
  WALLET: false,
  ORDER_DISPUTE: false,
  ORDER_TUNNEL: false,
  ORDER_HOURLY: false,
  ORDER_PASSENGER: false,
  SIDEBAR_ORDER_LAYOUT: false,
}

const countrySpecificConfig = {
  HK: {
    ...defaultFeatureConfig,
    WALLET: true,
    ORDER_DISPUTE: true,
    ORDER_TUNNEL: true,
    ORDER_HOURLY: true,
    ORDER_PASSENGER: true,
  },
  SG: {
    ...defaultFeatureConfig,
    WALLET: true,
    ORDER_DISPUTE: true,
  },
  TW: {
    ...defaultFeatureConfig,
    WALLET: true,
    ORDER_DISPUTE: true,
  },
  VN: {
    ...defaultFeatureConfig,
    WALLET: true,
    ORDER_DISPUTE: true,
  },
}

const featureConfig = countrySpecificConfig[getCountry()]
export { featureConfig }
