/* eslint-disable camelcase */
const getCurrentUserProfilePhotoList = state =>
  state?.session?.currentUser?.profile_photo?.urls

const getCurrentUserCompanyPhotoList = state =>
  state?.session?.currentUser?.organization?.profile_photo?.urls

const getCurrentUserPhoneNumber = state =>
  state?.session?.currentUser?.meta?.phone_number

const getOrganizationId = state => state?.session?.currentUser?.organization?.id

const getOrganizationName = state =>
  state?.session?.currentUser?.organization?.name

const getOrganizationContactName = state =>
  state?.session?.currentUser?.organization?.contact_person

const getOrganizationContactEmail = state =>
  state?.session?.currentUser?.organization?.email_address

const getOrganizationContactPhone = state =>
  state?.session?.currentUser?.organization?.phone_number

const getProfilePhotoDataUrl = state => state?.profilePhotoDataUrl

const getCompanyProfilePhotoDataUrl = state => state?.companyProfilePhotoDataUrl

const getCurrentUserMeta = state => state?.session?.currentUser?.meta

const getCurrentUserId = state => state?.session?.currentUser?.id

export {
  getCurrentUserProfilePhotoList,
  getCurrentUserCompanyPhotoList,
  getCurrentUserPhoneNumber,
  getOrganizationId,
  getOrganizationName,
  getOrganizationContactName,
  getOrganizationContactEmail,
  getOrganizationContactPhone,
  getProfilePhotoDataUrl,
  getCompanyProfilePhotoDataUrl,
  getCurrentUserMeta,
  getCurrentUserId,
}
