import React, { useState } from 'react'
import PropTypes from 'prop-types'

import {
  StyledContentWrapper,
  StyledWrapper,
} from './accordion.component.styles'
import { Heading } from 'ggx-componentlibrary/design/typography/typography.styles'

import UpArrow from './assets/arrow-up.svg'
import DownArrow from './assets/arrow-down.svg'

const Accordion = ({ header, children, className, defaultIsOpen = false }) => {
  const [showContent, setShowContent] = useState(defaultIsOpen)

  const onClickHandler = e => {
    e.preventDefault()
    setShowContent(!showContent)
  }

  return (
    <div className={className}>
      <StyledWrapper href="" onClick={onClickHandler} showContent={showContent}>
        <Heading typographyStyles="mb-none mr-s">{header}</Heading>
        {showContent ? <UpArrow /> : <DownArrow />}
      </StyledWrapper>
      {showContent && <StyledContentWrapper>{children}</StyledContentWrapper>}
    </div>
  )
}

Accordion.propTypes = {
  header: PropTypes.string,
  defaultIsOpen: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
}

export { Accordion }
