import styled from "styled-components"
import Link from "react-router/lib/Link"

import { SmallText } from "ggx-componentlibrary/design/typography/typography.styles"
import { COLOR } from "ggx-componentlibrary/design/color/color.constants"
import { SPACING } from "ggx-componentlibrary/design/spacing/spacing.constants"
import { EXPANDED_WIDTH } from "../navigation.constants"

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
`

Menu.Row = styled.div`
  width: ${EXPANDED_WIDTH};
  padding: ${SPACING.S} 0;
  display: flex;
  align-items: center;
  cursor: pointer;

  :hover {
    background-color: ${COLOR.GREY_LIGHTER};
  }

  ${(props) => {
    if (props.$isActive) {
      return `
        background-color: ${COLOR.GREY_LIGHTER};
      `
    }
  }}
`

const SubMenu = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  left: 169px;
  width: 176px;
  top: calc(80px - ${props => Math.min(props.$subMenuOffset ?? 0, 80)}px);
  background-color: ${COLOR.WHITE};
  border-radius: ${SPACING.XXS};

  opacity: ${(props) => (props.$showSubMenu ? 1 : 0)};
  pointer-events: ${(props) => (props.$showSubMenu ? "auto" : "none")};
  transition: opacity 0.2s ease-in-out;
  padding: ${SPACING.XS} 0;
  z-index: 5;
`

SubMenu.Row = styled(Link)`
  text-decoration: none;
  padding: 12px ${SPACING.M};

  :hover {
    background-color: ${COLOR.GREY_LIGHTER};

    ${SmallText} {
      color: ${COLOR.BLUE_MEDIUM} !important;
    }
  }
`

const LanguageSwitch = styled(SmallText)`
  margin-bottom: 0;
  padding: 12px ${SPACING.M};
  cursor: pointer;
  :hover {
    background-color: ${COLOR.GREY_LIGHTER};
    color: ${COLOR.BLUE_MEDIUM} !important;
  }
`

export {
  Menu,
  SubMenu,
  LanguageSwitch,
}
