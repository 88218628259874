import styles from '../styles'
import getCurrency from '../../../locales/get_currency'
import { OrderRequestIdButton } from './row_column.component.styles'

export const orderRequestIdColumn = (
  orderRequestId,
  openOrderRequestDialog,
  included
) => {
  if (!orderRequestId) return <span>-</span>
  return (
    <OrderRequestIdButton
      onClick={() => {
        openOrderRequestDialog(orderRequestId.toString(), included)
      }}
    >
      #{orderRequestId}
    </OrderRequestIdButton>
  )
}

export const dateColumn = transactionTime => {
  const timestamp = moment(transactionTime)
  return <div>{timestamp.format('L')}</div>
}

export const timeColumn = transactionTime => {
  const timestamp = moment(transactionTime)
  return <div>{timestamp.format('HH:mm')}</div>
}

const transactionTypeText = type => {
  if (type === 'order_completion') {
    return translateWithPrefix('transactionTypes.orderCompletion')
  } else if (type === 'settlement') {
    return translateWithPrefix('transactionTypes.settlement')
  } else if (type === 'order_dispute') {
    return translateWithPrefix('transactionTypes.orderDispute')
  } else if (type === 'deposit') {
    return translateWithPrefix('transactionTypes.deposit')
  }
  return type
}

export const descriptionColumn = (description, type) => {
  if (description && type) {
    return (
      <span>
        {transactionTypeText(type)} - {description}
      </span>
    )
  } else if (type) {
    return <span>{transactionTypeText(type)}</span>
  } else if (description) {
    return <span>{description}</span>
  }
  return <span>-</span>
}

export const amountColumn = amount => {
  const style = amount > 0 ? {} : styles.negativeAmount
  return <div style={style}>{getCurrency({ amount })}</div>
}
