import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Modal } from 'ggx-componentlibrary/components/modal/modal.component'
import { Body } from 'ggx-componentlibrary/design/typography/typography.styles'
import { Button } from 'ggx-componentlibrary/components/button/button.component'

const ChangedPasswordModal = ({ onModalClose }) => {
  const [t] = useTranslation()

  const footerButton = (
    <Button onClick={onModalClose}>{t('button__got_it')}</Button>
  )

  return (
    <Modal
      header={t('dialog__title__force_log_out')}
      onRequestClose={onModalClose}
      footer={footerButton}
    >
      <Body typographyStyles="mt-XXS mb-XXS">
        {t('dialog__description__force_log_out')}
      </Body>
    </Modal>
  )
}

ChangedPasswordModal.propTypes = {
  onModalClose: PropTypes.func.isRequired,
}

export default ChangedPasswordModal
