import * as types from './types'

function orderManagementFilterReducer(state = {}, action = {}) {
  switch (action.type) {
    case types.SET_FILTER_STATUS:
      return {
        ...state,
        filterStatus: action.value,
      }
    case types.SET_FILTER_DATE:
      return {
        ...state,
        filterDate: {
          startDate: action.value.startDate,
          endDate: action.value.endDate,
        },
      }
    case types.SET_FILTER_BRANCH:
      return {
        ...state,
        filterBranch: action.value,
      }
    case types.SET_ORDER_SEARCH_VALUE:
      return {
        ...state,
        orderSearchValue: action.value,
      }
    case types.CLEAR_FILTERS:
      return {}
    default:
      return state
  }
}

const orderManagementReducers = {
  orderManagementFilters: orderManagementFilterReducer,
}

export { orderManagementReducers }
