import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Button } from 'ggx-componentlibrary/components/button/button.component'
import {
  HeadingJumbo,
  Heading,
  HeadingL,
  BodyBold,
} from 'ggx-componentlibrary/design/typography/typography.styles'
import { getCountry } from 'ggx-service/locale/country.service'

import {
  Container,
  GreetingCard,
  ServicesCard,
  DeliveryIcon,
  TransportIcon,
  Service,
  StyledModal,
  TourGreetingCard,
  TourImageCard,
  StyledImg,
} from './welcome-modal.component.styles'
import { getCurrentUserName } from '../duck/selectors'
import { apiService } from '../api/api.service'
import TourImagePath from '../assets/onboarding_gogoman.png'

const WelcomeModal = ({
  handleModalClose,
  handleTutorialStart,
  handleFeatureTourStart,
}) => {
  const [t] = useTranslation()
  const userName = useSelector(state => getCurrentUserName(state))
  const country = getCountry()

  const handleOnboardingSkip = () => {
    handleModalClose()
    apiService.setViewedServiceIntroduction()
  }

  const footer = (
    <>
      <Button buttonType="secondary" onClick={handleOnboardingSkip}>
        {t('button__onboarding__skip')}
      </Button>
      <Button onClick={handleTutorialStart}>
        {t('button__onboarding__start')}
      </Button>
    </>
  )

  const HKWelcomeModal = (
    <StyledModal
      width="1080px"
      height="480px"
      footer={footer}
      showCloseButton={false}
      onRequestClose={() => {}}
    >
      <Container>
        <GreetingCard>
          <HeadingJumbo>
            {t('common__hello_name', { name: userName })}
          </HeadingJumbo>
          <Heading>{t('text__onboarding_intro')}</Heading>
        </GreetingCard>
        <ServicesCard>
          <Service>
            <TransportIcon />
            <BodyBold typographyStyles="mt-s">
              {t('common__van_truck_service')}
            </BodyBold>
          </Service>
          <Service>
            <DeliveryIcon />
            <BodyBold typographyStyles="mt-s">
              {t('home.service.delivery.title')}
            </BodyBold>
          </Service>
        </ServicesCard>
      </Container>
    </StyledModal>
  )

  const handleTourStart = () => {
    handleFeatureTourStart()
    apiService.setViewedServiceIntroduction()
  }

  const tourFooter = (
    <>
      <Button buttonType="secondary" onClick={handleOnboardingSkip}>
        {t('button__skip')}
      </Button>
      <Button onClick={handleTourStart}>{t('button__show_me_around')}</Button>
    </>
  )

  const NonHKWelcomeModal = (
    <StyledModal
      width="743px"
      height="480px"
      footer={tourFooter}
      showCloseButton={false}
      onRequestClose={() => {}}
    >
      <Container>
        <TourGreetingCard>
          <HeadingJumbo>
            {t('common__hello_name', { name: userName })}
          </HeadingJumbo>
          <HeadingL>
            {t(
              'tour__lets_show_you_all_you_need_to_know_about_gogobusiness_with_a_very_quick_tour'
            )}
          </HeadingL>
        </TourGreetingCard>
        <TourImageCard>
          <StyledImg src={TourImagePath} alt="" />
        </TourImageCard>
      </Container>
    </StyledModal>
  )

  return country === 'HK' ? HKWelcomeModal : NonHKWelcomeModal
}

WelcomeModal.propTypes = {
  handleModalClose: PropTypes.func,
  handleTutorialStart: PropTypes.func,
  handleFeatureTourStart: PropTypes.func,
}

export { WelcomeModal }
