// @flow
import PropTypes from 'prop-types'

const extraRequirementsByCountryVehicle = (props: {
  extraRequirements: Object,
  vehicle: string,
}) => {
  const { extraRequirements, vehicle } = props
  return extraRequirements.filter(
    extraRequirement =>
      (!extraRequirement.vehicle && !extraRequirement.vehicles) ||
      (extraRequirement.vehicle && extraRequirement.vehicle === vehicle) ||
      (extraRequirement.vehicles &&
        extraRequirement.vehicles.indexOf(vehicle) !== -1)
  )
}

extraRequirementsByCountryVehicle.propTypes = {
  extraRequirements: PropTypes.object.isRequired,
  vehicle: PropTypes.string.isRequired,
}

export { extraRequirementsByCountryVehicle }
