import styled from "styled-components"

import { TextLink } from "ggx-componentlibrary/design/typography/typography.styles"
import { FONT_SIZE } from "ggx-componentlibrary/design/typography/typography.constants"
import { SPACING } from "ggx-componentlibrary/design/spacing/spacing.constants"
import IconExternalLink from "../../../../assets/external_link.svg"

const LearnMoreLink = styled(TextLink)`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  font-size: ${FONT_SIZE.MEDIUM};
`

const IconExternalLinkIcon = styled(IconExternalLink)`
  margin-left: ${SPACING.XS};
`

export { LearnMoreLink, IconExternalLinkIcon }
