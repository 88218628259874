import _ from 'lodash'

export const findIncluded = (included, dataType) => {
  if (!included) return []
  const isDataType = include => include.type === dataType
  return included.filter(isDataType)
}

export const findIncludedAttributes = (id, included) => {
  const matchIncludes = included.filter(req => req.id === id)
  const firstMatch = matchIncludes.length > 0 ? matchIncludes[0].attributes : {}
  return firstMatch
}

export const createWaypoint = (w = {}) => {
  if (!w.uniqueId) {
    w.uniqueId = _.uniqueId('waypoint')
  }
  return w
}
