import { Api } from '../../legacy/utils/api'

const _api = new Api(Api.BASE_URL.B2B)

const apiService = {
  addBonus: async (orderId, value) => {
    const method = 'post'
    const endpoint = `/order_requests/${orderId}/add_tips`

    try {
      const data = { tips: value }
      const result = await _api.request(method, endpoint, null, data)
      return result
    } catch (error) {
      throw error
    }
  },
  sendOrderReceipt: async (orderId, email) => {
    const method = 'post'
    const endpoint = `/order_requests/${orderId}/receipt`

    try {
      const data = { receipt_email: email }
      const result = await _api.request(method, endpoint, null, data)
      return result
    } catch (error) {
      throw error
    }
  },
}

export { _api, apiService }
