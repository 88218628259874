import PropTypes from 'prop-types'
import { camelCase } from 'lodash'
import moment from 'moment'

import translate from '../../../legacy/locales/translate'
import { eventsWithoutAvatar } from '../../../legacy/constants/notification'

import {
  StatusWrapper,
  StatusIndicator,
  MessageContentWrapper,
  MessageWrapper,
  MessageContentAbove,
  MessageContentBelow,
  MessageContentBelowLeft,
  CancelBtnContainer,
  CancelOrderBtn,
  StyledDate,
  StyledRequestId,
  DismissBtn,
} from './message.component.styles'
import MessageText from './message_text'
import MessageAvatar from './message_avatar'

const camelEventType = eventType => camelCase(eventType) || ''
const eventDate = unixTimestamp =>
  moment(parseInt(unixTimestamp * 1000, 10)).format('YYYY-MM-DD HH:mm')
const translateWithPrefix = key => translate(`notification.actionButton.${key}`)

const Message = ({
  listKey,
  message,
  orderRequestStatus,
  onOrderIdClick,
  onDismissClick,
  onCancelOrderClick,
  isLoadingAction,
  featureFlagFavoriteDrivers,
}) => {
  const isCancelled = orderRequestStatus === 'cancelled'
  const label = isCancelled
    ? translateWithPrefix('cancelled') || 'CANCELLED'
    : translateWithPrefix('cancel') || 'CANCEL'
  const cancelOrderButton =
    message.eventType === 'driver_pool_fallback' ? (
      <CancelOrderBtn
        disabled={isCancelled}
        onClick={evt => {
          evt.preventDefault()
          evt.stopPropagation()
          onCancelOrderClick && onCancelOrderClick(evt)
        }}
      >
        {label}
      </CancelOrderBtn>
    ) : (
      <div />
    )

  return (
    <MessageWrapper key={listKey} onClick={onOrderIdClick}>
      <StatusWrapper>
        <StatusIndicator status={camelEventType(message.eventType)} />
      </StatusWrapper>

      <MessageContentWrapper>
        <MessageContentAbove>
          <MessageText
            featureFlagFavoriteDrivers={featureFlagFavoriteDrivers}
            orderStatus={message.eventType}
            orderRequestId={message.orderRequestId}
            body={message.body}
          />
          {!eventsWithoutAvatar.includes(message.eventType) && (
            <MessageAvatar url={message.driverProfilePhoto} />
          )}
          <CancelBtnContainer>{cancelOrderButton}</CancelBtnContainer>
        </MessageContentAbove>

        <MessageContentBelow>
          <MessageContentBelowLeft>
            <StyledDate>{eventDate(message.date)}</StyledDate>

            {message.orderRequestId ? (
              <StyledRequestId>#{message.orderRequestId}</StyledRequestId>
            ) : null}
          </MessageContentBelowLeft>

          {onDismissClick && (
            <DismissBtn
              onClick={evt => {
                evt.preventDefault()
                evt.stopPropagation()
                onDismissClick && onDismissClick(evt)
              }}
            >
              {translate('popup.message.dismiss')}
              <span>X</span>
            </DismissBtn>
          )}
        </MessageContentBelow>
      </MessageContentWrapper>
    </MessageWrapper>
  )
}

Message.propTypes = {
  listKey: PropTypes.number.isRequired,
  message: PropTypes.object.isRequired,
  orderRequestStatus: PropTypes.string,
  onOrderIdClick: PropTypes.func.isRequired,
  onDismissClick: PropTypes.func.isRequired,
  onCancelOrderClick: PropTypes.func.isRequired,
  isLoadingAction: PropTypes.bool.isRequired,
  featureFlagFavoriteDrivers: PropTypes.bool.isRequired,
}

export { Message }
