const COLLAPSE_SIDEBAR = 'COLLAPSE_SIDEBAR'
const HIDE_TOAST_CONTAINER = 'HIDE_TOAST_CONTAINER'
const SHOW_BANNER = 'SHOW_BANNER'
const HIDE_BANNER = 'HIDE_BANNER'
const SET_COUPON = 'SET_COUPON'
const SHOW_ADD_CREDIT_CARD_MODAL ='SHOW_ADD_CREDIT_CARD_MODAL'
const HIDE_ADD_CREDIT_CARD_MODAL = 'HIDE_ADD_CREDIT_CARD_MODAL'

export {
  COLLAPSE_SIDEBAR,
  HIDE_TOAST_CONTAINER,
  SHOW_BANNER,
  HIDE_BANNER,
  SET_COUPON,
  SHOW_ADD_CREDIT_CARD_MODAL,
  HIDE_ADD_CREDIT_CARD_MODAL,
}
