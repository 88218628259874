import { Component } from 'react'
import PropTypes from 'prop-types'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHeader from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import {
  dateColumn,
  timeColumn,
  orderRequestIdColumn,
  descriptionColumn,
  amountColumn,
} from '../row-column/row_column.component'

import { LoadingSpinner } from '../../helpers'
import styles from '../styles'
import translate from '../../../locales/translate'

const translateWithPrefix = (key, options) =>
  translate(`wallet.view.${key}`, options)

const headerColumns = [
  { titleKey: 'date' },
  { titleKey: 'time' },
  { titleKey: 'orderRequestId' },
  { titleKey: 'description', wide: true },
  { titleKey: 'amount' },
]

const buildTransactionColumns = ({ row, openOrderRequestDialog, included }) => {
  const { attributes } = row
  const {
    amount,
    created_at: createdAt,
    order_request_id: orderRequestId,
    remark,
    transaction_type: transactionType,
  } = attributes

  return [
    { column: dateColumn(createdAt) },
    { column: timeColumn(createdAt) },
    {
      column: orderRequestIdColumn(
        orderRequestId,
        () => openOrderRequestDialog(orderRequestId),
        included
      ),
    },
    { column: descriptionColumn(remark, transactionType), wide: true },
    { column: amountColumn(parseInt(amount, 10)) },
  ]
}

class WalletTransactionTab extends Component {
  bodyRows() {
    const { transactions, openOrderRequestDialog, included } = this.props
    const buildColumns = transactions.map((row, i) => {
      const transactionColumns = buildTransactionColumns({
        row,
        openOrderRequestDialog,
        included,
      })
      const rowColumns = transactionColumns.map((transaction, p) => (
        <TableCell key={p} style={styles.rowCell}>
          {transaction.column}
        </TableCell>
      ))
      return <TableRow key={i}>{rowColumns}</TableRow>
    })
    return buildColumns
  }

  headerRow() {
    const columns = headerColumns.map((header, i) => (
      <TableCell key={i} style={styles.headerCell}>
        {translateWithPrefix(header.titleKey).toUpperCase()}
      </TableCell>
    ))
    return columns
  }

  render() {
    const { loading } = this.props

    return (
      <>
        <Table>
          <TableHeader>
            <TableRow>{this.headerRow()}</TableRow>
          </TableHeader>
          {!loading && <TableBody>{this.bodyRows()}</TableBody>}
        </Table>
        {loading && <LoadingSpinner style={styles.loadingSpinner} />}
      </>
    )
  }
}

WalletTransactionTab.propTypes = {
  transactions: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  openOrderRequestDialog: PropTypes.func,
  included: PropTypes.arrayOf(PropTypes.object),
}

export default WalletTransactionTab
