import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { StyledDropdown } from './filter-status.component.styles'
import { HeadingSmall } from '../../component-library/design/typography/typography.styles'

const FilterStatus = ({ onChange, filterStatus }) => {
  const [t] = useTranslation()
  const statusFilterOptions = useMemo(
    () => [
      { label: t('common__show_all'), value: undefined },
      { label: t('common__pending'), value: 'pending' },
      { label: t('common__active'), value: 'active' },
      { label: t('common__completed'), value: 'completed' },
      { label: t('common__cancelled'), value: 'cancelled' },
    ],
    [t]
  )

  const filterStatusOption = statusFilterOptions.find(
    ({ value }) => value === filterStatus
  )

  return (
    <div>
      <HeadingSmall>{t('common__order_status')}</HeadingSmall>
      <StyledDropdown
        defaultOptions={statusFilterOptions}
        onChange={onChange}
        value={filterStatusOption || statusFilterOptions[0]}
        dataTestid="van-order-status-filter-dropdown"
      />
    </div>
  )
}

FilterStatus.propTypes = {
  onChange: PropTypes.func,
  filterStatus: PropTypes.string,
}

export { FilterStatus }
