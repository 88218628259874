import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'

import { Button } from '../../../../component-library/components/button/button.component'
import { SPACING } from '../../../../component-library/design/spacing/spacing.constants'

import translate from '../../../locales/translate'

const translateWithPrefix = key => translate(`user_suspended.dialog.${key}`)

const SuspendedOrganizationDialog = ({ open }) => {
  const [acknowledged, setAcknowledged] = useState(false)

  const closeDialog = () => {
    setAcknowledged(true)
  }

  const onClose = (_, reason) => {
    if (reason === 'backdropClick') {
      closeDialog()
    }
  }

  if (acknowledged) return null

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          width: '400px',
        },
        '& .MuiDialogTitle-root': {
          color: '#797979',
          textAlign: 'center',
          padding: `${SPACING.XL4} ${SPACING.XL} ${SPACING.XL}`,
        },
        '& .MuiDialogActions-root': {
          display: 'flex',
          justifyContent: 'center',
          paddingBottom: SPACING.S,
        },
      }}
    >
      <DialogTitle>{translateWithPrefix('dialog_message')}</DialogTitle>
      <DialogActions>
        <Button onClick={closeDialog}>
          {translateWithPrefix('ok_button')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

SuspendedOrganizationDialog.propTypes = {
  open: PropTypes.bool,
  classes: PropTypes.object,
}

SuspendedOrganizationDialog.defaultProps = {
  open: false,
}

export default SuspendedOrganizationDialog
