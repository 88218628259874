import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Button } from 'ggx-componentlibrary/components/button/button.component'
import { CalloutModal } from '../../callout-modal/callout-modal.component'

const TransactionHistoryCallout = ({
  stepText = '',
  handleNext,
  handleSkip,
}) => {
  const [t] = useTranslation()
  const [modalPosition, setModalPosition] = useState(null)
  const [highlights, setHighlights] = useState([])

  const heading = t('tour__download_transaction_records')

  const footer = (
    <>
      <Button onClick={handleSkip} buttonType="secondary">
        {t('button__end_tour')}
      </Button>
      <Button onClick={handleNext}>{t('button_next')}</Button>
    </>
  )

  useEffect(() => {
    const transactionHistoryLink = document.querySelector(
      '[data-testid="navigation-bar-transaction-history"]'
    )
    const billingBalanceLink = document.querySelector(
      '[data-testid="navigation-bar-billing-and-balance"]'
    )

    // eslint-disable-next-line no-unused-expressions
    transactionHistoryLink?.scrollIntoView({ block: 'start', inline: 'start' })
    // eslint-disable-next-line no-unused-expressions
    billingBalanceLink?.scrollIntoView({ block: 'end', inline: 'start' })

    const yPosition = Math.ceil(
      (transactionHistoryLink || billingBalanceLink)?.getBoundingClientRect?.()
        .y
    )

    setHighlights([
      {
        width: '192px',
        height: '42px',
        x: '12px',
        y: `${yPosition - 1}px`,
      },
    ])

    setModalPosition({
      left: '420px',
      top: `${yPosition + 80}px`,
    })
  }, [])

  return (
    <CalloutModal
      stepText={stepText}
      highlights={highlights}
      modalPosition={modalPosition}
      heading={heading}
      footer={footer}
    />
  )
}

TransactionHistoryCallout.propTypes = {
  stepText: PropTypes.string,
  handleNext: PropTypes.func,
  handleSkip: PropTypes.func,
}

export { TransactionHistoryCallout }
