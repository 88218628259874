const Color = {
  CORNFLOWER_BLUE: '#f0f6f5',
  LOCHMARA_BLUE: '#0088bf',
  BAHAMA_BLUE: '#006a96',
  BISCAY_BLUE: '#1B4A6A',
  CERULEAN_BLUE: '#009fe1',
  LIGHTER_BLUE: '#D6ECF5',
  SUPERNOVA_YELLOW: '#ffc905',
  EARLY_DAWN_YELLOW: '#fffae7',
  LIGHTNING_YELLOW: '#F8C01C',
  TURBO_YELLOW: '#ffe805',
  PORCELAIN_GREY: '#eff4f4',
  PERSIAN_GREEN: '#00a493',
  MANDY_RED: '#ea5365',
  WHITE: '#ffffff',
  BLACK: '#000000',
  CONCRETE_GREY: '#f2f2f2',
  RESOLUTION_GREY: '#012983',

  ALABASTER_GREY: '#fafafa',
  ALTO_GREY: '#d5d5d5',
  SILVER: '#cacaca',
  TUNDORA_GREY: '#4a4a4a',
  DUSTY_GREY: '#9b9b9b',
  GEYSER: '#dce5e4',
}

export default Color
