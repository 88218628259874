// @flow
import { useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import Router from 'react-router/lib/Router'
import invariant from 'invariant'
import ga from 'react-ga'
import { hot } from 'react-hot-loader/root'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { ErrorBoundary } from '../../service/bugsnag'

import MuiB2bTheme from '../muiB2bTheme'

import configRoutes from '../routes'
import { getCountry } from '../views/helpers'
import Countries from '../constants/countries'
import { track } from 'ggx-service/mixpanel/mixpanel'

const theme = createTheme({
  ...MuiB2bTheme,
  typography: {
    fontFamily: 'Wix Madefor Text',
  },
})

const Root = ({ routerHistory, store, onUpdate }) => {
  invariant(
    routerHistory,
    '<Root /> needs either a routingContext or routerHistory to render.'
  )

  const country: string = useMemo(getCountry, [])
  const isChina = country === Countries.CHINA

  const logPageView = useCallback(() => {
    track('Page_view', { path: window.location.pathname })
    if (!isChina && window.ga) {
      ga.pageview(window.location.pathname)
    } else if (window._hmt) {
      _hmt.push(['_trackPageview', window.location.pathname])
    }
  }, [isChina])

  useEffect(() => {
    if (!isChina) {
      const trackerID: string = ENV.GA_TRACKER_ID
      ga.initialize(trackerID)
    }
  }, [isChina])

  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <Router history={routerHistory} onUpdate={onUpdate || logPageView}>
            {configRoutes(store)}
          </Router>
        </Provider>
      </ThemeProvider>
    </ErrorBoundary>
  )
}

Root.propTypes = {
  routerHistory: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
  onUpdate: PropTypes.func,
}

export default hot(Root)
