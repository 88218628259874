
import styled from 'styled-components'

import { SPACING } from 'ggx-componentlibrary/design/spacing/spacing.constants'
import { COLOR } from 'ggx-componentlibrary/design/color/color.constants'
import { FONT_BOLD } from 'ggx-componentlibrary/design/typography/typography.constants'
import {
  Body,
  SmallTextBold,
  SmallText,
} from 'ggx-componentlibrary/design/typography/typography.styles'
import OrderHistorySVG from '../assets/icon-order-history.svg'
import PlusIconSVG from '../assets/icon-plus.svg'
import SavedAddressIconSVG from '../assets/icon-saved-address.svg'
import LocationSuggestionSVG from '../assets/icon-location-suggestion.svg'
import BinIconSVG from '../assets/icon-bin.svg'
import { LocationTypeImage } from "./location-type-image/location-type-image.component"
import { LocationTypeTextSection } from "./location-type-text-section/location-type-text-section.component"
import ContactIconSVG from '../assets/icon-contact.svg'
import AddressBookIconSVG from '../assets/icon-address-book.svg'
import AddContactIconSVG from '../assets/icon_add-contact.svg'


const BodyStyles = `
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 16px;
`

const IconLeftStyles = `
  flex-shrink: 0;
  width: 24px;
  height: 24px;
`

const AddressContainer = styled.li`
  display: flex;
  align-items: center;
  padding: ${SPACING.S};
  ${props =>
    props.showDivider ? `border-bottom: 1px solid ${COLOR.GREY_LIGHT};` : ''}
  gap: ${SPACING.S};
`

const AddressInformation = styled.div`
  flex: 1 1 auto;
`

const AddressBody = styled(Body)`
  ${BodyStyles}
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`

const AddressCompound = styled.div`
    display: flex;
    & > *:nth-child(2) {
      flex: 0 0 auto;
      display: inline-block;
      max-width: 80px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
`

const AddressContactBody = styled(SmallText)`
  ${BodyStyles}
  color: ${COLOR.GREY_MEDIUM};
`

const SaveAddressBody = styled(Body)`
  color: ${COLOR.BLUE_MEDIUM};
  margin: 0;
`

const AddressName = styled(SmallText)`
  ${FONT_BOLD};
  color: ${COLOR.BLACK};
  margin-bottom: 0;
`

const OrderHistoryIcon = styled(OrderHistorySVG)`
  ${IconLeftStyles}
`

const SavedAddressIcon = styled(SavedAddressIconSVG)`
  ${IconLeftStyles}
`

const LocationSuggestionIcon = styled(LocationSuggestionSVG)`
  ${IconLeftStyles}
`

const PlusIcon = styled(PlusIconSVG)`
  ${IconLeftStyles}
`

const BinIcon = styled(BinIconSVG)`
  margin-left: auto;
  flex-shrink: 0;
  cursor: pointer;
`

const GenericOptionContainer = styled.li`
  padding: ${SPACING.S};
  
  &:not(:last-child) {
    ${props =>
      props.showDivider ? `border-bottom: 1px solid ${COLOR.GREY_LIGHT};` : ''}
  }
`

const GenericContent = styled.div`
  display: flex;
  flex-direction: column;
`

const GenericLabel = styled(SmallTextBold)`
  color: ${COLOR.BLACK};
  margin-bottom: ${SPACING.XXS};
`

const GenericInfo = styled(SmallText)`
  margin: 0;
  color: ${COLOR.GREY_DARK};
`

const StyledLocationTypeImage = styled(LocationTypeImage)`
  width: 32px;
  height: 32px;
`

const StyledLocationTypeTextSection = styled(LocationTypeTextSection)`
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 16px;
`

const ContactIcon = styled(ContactIconSVG)`
  ${IconLeftStyles}
`

const AddressBookIcon = styled(AddressBookIconSVG)`
  cursor: pointer;
  ${IconLeftStyles}
`

const AddContactIcon = styled(AddContactIconSVG)`
  cursor: pointer;
  ${IconLeftStyles}
`

const Divider = styled.li`
  margin: 12px 0;
  border-bottom: 1px solid ${COLOR.GREY_LIGHT};
  &:last-child {
    display: none;
  }
`

export {
  AddressContainer,
  AddressInformation,
  AddressBody,
  AddressCompound,
  AddressContactBody,
  SaveAddressBody,
  AddressName,
  GenericOptionContainer,
  GenericContent,
  GenericLabel,
  GenericInfo,
  OrderHistoryIcon,
  SavedAddressIcon,
  LocationSuggestionIcon,
  PlusIcon,
  BinIcon,
  StyledLocationTypeImage,
  StyledLocationTypeTextSection,
  ContactIcon,
  AddressBookIcon,
  Divider,
  AddContactIcon,
}