import PropTypes from 'prop-types'
import {
  Body,
  SmallText,
  SmallTextBold,
} from 'ggx-componentlibrary/design/typography/typography.styles'
import { useTranslation } from 'react-i18next'
import { getLocale } from '../../../../../../legacy/utils/locale'

const LocationTypeTextSection = ({ className, location }) => {
  const { t } = useTranslation()
  let smallTextContent
  if (location.type === 'CIRCLE_K') {
    smallTextContent = t('text__location_code__location_circle-k', {
      locationCode: location.id,
    })
  } else if (location.type === 'SEVEN_ELEVEN') {
    smallTextContent = t('text__location_code__location_7-11', {
      locationCode: location.id,
    })
  } else if (location.type === 'ALFRED_LOCKER') {
    smallTextContent = t('text__location_code__location_locker', {
      locationCode: location.id,
    })
  } else if (location.type === 'ALFRED_POINT') {
    smallTextContent = t('text__location_code__location_point', {
      locationCode: location.id,
    })
  }

  const locale = getLocale()
  const nameWithCorrectLocale =
    locale === 'zh-hk' ? location.name_zh : location.name_en
  const addressWithCorrectLocale =
    locale === 'zh-hk' ? location.address_zh : location.address_en

  return (
    <div className={className}>
      <SmallTextBold typographyStyles="c-black" noMargin>
        {nameWithCorrectLocale}
      </SmallTextBold>
      <Body noMargin>{addressWithCorrectLocale}</Body>
      <SmallText noMargin>{smallTextContent}</SmallText>
    </div>
  )
}

LocationTypeTextSection.propTypes = {
  className: PropTypes.string,
  location: PropTypes.object.isRequired,
}

export { LocationTypeTextSection }
