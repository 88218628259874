import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { getTransactions } from '../../../actions/wallet'
import translate from '../../../locales/translate'
import { Pagination } from 'ggx-componentlibrary/components/table/pagination/pagination.component'

const translateWithPrefix = (key, options) =>
  translate(`wallet.view.${key}`, options)

const TransactionActionBar = ({
  currentPage,
  totalCount,
  totalPages,
  loading,
  handlePageNavClick,
}) => (
  <Pagination
    paginationText={translateWithPrefix('totalTransactions', {
      count: totalCount,
    })}
    currentPageNumber={currentPage}
    totalPageNumber={totalPages}
    onPageNavClick={handlePageNavClick}
    loading={loading}
  />
)

TransactionActionBar.propTypes = {
  currentPage: PropTypes.number,
  totalCount: PropTypes.number,
  totalPages: PropTypes.number,
  loading: PropTypes.bool,
  handlePageNavClick: PropTypes.func,
}

const mapStateToProps = state => ({
  currentPage: state.transactions.currentPage,
  totalCount: state.transactions.totalCount,
  totalPages: state.transactions.totalPages,
  loading: state.transactions.loading,
})

const mapDispatchToProps = dispatch => ({
  handlePageNavClick: newPageNumber => {
    dispatch(getTransactions({ page: newPageNumber }))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionActionBar)
