import Route from 'react-router/lib/Route'

import { loadableCreator } from 'ggx-global/loading/loadable-creator'
import { featureFlagService } from 'ggx-service/feature-flag/feature-flag'
import { getFeatureFlag } from 'ggx-service/feature-flag/selectors'

const LoadableInventory = loadableCreator({
  dynamicImport: () =>
    import(/* webpackChunkName: 'inventory' */ './inventory.component'),
  componentName: 'Inventory',
})

const redirectToIndex = store => (_nextState, replace, callback) => {
  const checkFlag = () => {
    const state = store.getState()
    const featureFlagInventory = getFeatureFlag({
      state,
      featureFlag: 'inventory',
    })
    const shouldRedirect = featureFlagInventory === false

    if (shouldRedirect) {
      replace('/')
    }
    callback()
  }

  featureFlagService
    .triggerFeatureFlag('inventory')
    .then(checkFlag)
    .catch(checkFlag)
}

const InventoryRoutes = store => (
  <Route
    path="inventory"
    component={LoadableInventory}
    onEnter={redirectToIndex(store)}
  />
)

export { InventoryRoutes }
