import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { StyledDropdown } from './filter-branch.component.styles'
import { HeadingSmall } from '../../component-library/design/typography/typography.styles'
import { getBranches } from '../../users-and-branches/duck/selectors'

const FilterBranch = ({ onChange, filterBranch }) => {
  const [t] = useTranslation()
  const branches = useSelector(state => getBranches(state))

  const statusFilterOptions = [
    { label: t('common__show_all'), value: undefined },
    ...branches.map(({ id, name }) => ({ value: id, label: name })),
  ]

  const filterBranchOption = statusFilterOptions.find(
    ({ value }) => value === filterBranch
  )

  return (
    <div>
      <HeadingSmall>{t('common__branch')}</HeadingSmall>
      <StyledDropdown
        defaultOptions={statusFilterOptions}
        onChange={onChange}
        value={filterBranchOption || statusFilterOptions[0]}
        dataTestid="van-order-branch-filter-dropdown"
      />
    </div>
  )
}

FilterBranch.propTypes = {
  onChange: PropTypes.func,
  filterBranch: PropTypes.string,
}

export { FilterBranch }
