const COLLAPSED_WIDTH = '64px'
const EXPANDED_WIDTH = '220px'
const COLLAPSED_TRANSATION_DURATION = '0.25s'
const EXPANDED_TRANSATION_DURATION = '0.3s'

const NAV_SIDEBAR_WIDTH = '240px'

export {
  COLLAPSED_WIDTH,
  EXPANDED_WIDTH,
  COLLAPSED_TRANSATION_DURATION,
  EXPANDED_TRANSATION_DURATION,
  NAV_SIDEBAR_WIDTH,
}
