import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import {
  StyledBodyBold,
  StyledBody,
  InstructionWrapper,
  BankDetailsWrapper,
  ItemWrapper,
  ItemLabel,
  ContactDetailsWrapper,
  StyledTextLink,
} from './bank-transfer-section.component.styles'

const BankTransferSectionTW = () => {
  const [t] = useTranslation()

  return (
    <Fragment>
      <StyledBodyBold>
        {t('billing.topup.modal.bankTransfer.title')}
      </StyledBodyBold>

      <InstructionWrapper>
        <StyledBody>{t('billing.topup.modal.bankTransfer.stepOne')}</StyledBody>
        <StyledBody>
          {t('billing.topup.modal.bankTransfer.preTransferInstructions')}
        </StyledBody>
      </InstructionWrapper>

      <BankDetailsWrapper>
        <ItemWrapper>
          <ItemLabel>
            {t('billing.topup.modal.bankTransfer.bankName')}
          </ItemLabel>
          <StyledBody>
            {t('billing.topup.modal.bankTransfer.bankNameValue')}
          </StyledBody>
        </ItemWrapper>

        <ItemWrapper>
          <ItemLabel>
            {t('billing.topup.modal.bankTransfer.beneficiary')}
          </ItemLabel>
          <StyledBody>
            {t('billing.topup.modal.bankTransfer.beneficiaryValue')}
          </StyledBody>
        </ItemWrapper>

        <ItemWrapper>
          <ItemLabel>
            {t('billing.topup.modal.bankTransfer.bankCode')}
          </ItemLabel>
          <StyledBody>
            {t('billing.topup.modal.bankTransfer.bankCodeValue')}
          </StyledBody>
        </ItemWrapper>

        <ItemWrapper>
          <ItemLabel>
            {t('billing.topup.modal.bankTransfer.accountNumber')}
          </ItemLabel>
          <StyledBody>
            {t('billing.topup.modal.bankTransfer.accountNumberValue')}
          </StyledBody>
        </ItemWrapper>
      </BankDetailsWrapper>

      <InstructionWrapper>
        <StyledBody>{t('billing.topup.modal.bankTransfer.stepTwo')}</StyledBody>
        <StyledBody>{t('text__bank_transfer_step_two')}</StyledBody>
        <StyledTextLink
          target="_blank"
          href="https://docs.google.com/forms/d/e/1FAIpQLSddDQSjJNphpHxP8Bpmwm3PIYXLxW5ti0VFlmfptqyiAhrISA/viewform"
        >
          {t('text__fill_out_transfer_details')}
        </StyledTextLink>
      </InstructionWrapper>

      <InstructionWrapper>
        <StyledBody>
          {t('billing.topup.modal.bankTransfer.stepThree')}
        </StyledBody>
        <StyledBody>
          {t('billing.topup.modal.bankTransfer.stepThreeText')}
        </StyledBody>
      </InstructionWrapper>

      <ContactDetailsWrapper>
        <StyledBody>
          {t('billing.topup.modal.bankTransfer.contactUs')}
        </StyledBody>
      </ContactDetailsWrapper>

      <ContactDetailsWrapper>
        <StyledBody>
          {t('billing.topup.modal.bankTransfer.contactLine')}
        </StyledBody>
      </ContactDetailsWrapper>
    </Fragment>
  )
}

export { BankTransferSectionTW }
