import { useMemo } from 'react'
import { track, MIXPANEL_EVENT } from 'ggx-service/mixpanel/mixpanel'
import { brazeClient, BRAZE_EVENT } from 'ggx-service/braze'
import {
  getAccountId,
  getAccountName,
  getAccountEmail,
  getOrganizationName,
  getPreviousPage,
  getSessionToken,
  getCurrentUser,
} from 'ggx-src/users-and-branches/duck/selectors'
import { appStore } from 'ggx-src/store'

const setNullFallback = value =>
  typeof value === 'undefined' || value === '' ? null : value

export const fetchGeneralTrackingInfo = async () => {
  if (!getCurrentUser(appStore.getState())) {
    await new Promise(resolve => {
      const unsubscribe = appStore.store.subscribe(() => {
        if (getCurrentUser(appStore.getState())) resolve(unsubscribe())
      })
    })
  }

  const state = appStore.getState()
  return {
    user_name: getAccountName(state),
    user_email: getAccountEmail(state),
    user_account_id: getAccountId(state),
    company_name: getOrganizationName(state),
    previous_page: setNullFallback(getPreviousPage(state) || document.referrer),
    session_id: getSessionToken(state),
  }
}

export default () => {
  return useMemo(
    () => ({
      sendEventUsersAndBranches: async () => {
        const properties = await fetchGeneralTrackingInfo()
        track(MIXPANEL_EVENT.USERS_AND_BRANCHES.ENTER_PAGE, properties)
        brazeClient.setCustomEvent(
          BRAZE_EVENT.USERS_AND_BRANCHES.ENTER_PAGE,
          properties
        )
      },
      sendEventBranchFilterSelected: async (
        branch_id,
        branch_name_selection
      ) => {
        const properties = {
          ...(await fetchGeneralTrackingInfo()),
          branch_id,
          branch_name_selection,
        }
        track(
          MIXPANEL_EVENT.USERS_AND_BRANCHES.BRANCH_FILTER_SELECTED,
          properties
        )
        brazeClient.setCustomEvent(
          BRAZE_EVENT.USERS_AND_BRANCHES.BRANCH_FILTER_SELECTED,
          properties
        )
      },
      sendEventAccessLevelFilterSelected: async access_level_selection => {
        const properties = {
          ...(await fetchGeneralTrackingInfo()),
          access_level_selection,
        }
        track(
          MIXPANEL_EVENT.USERS_AND_BRANCHES.ACCESS_LEVEL_FILTER_SELECTED,
          properties
        )
        brazeClient.setCustomEvent(
          BRAZE_EVENT.USERS_AND_BRANCHES.ACCESS_LEVEL_FILTER_SELECTED,
          properties
        )
      },
      sendEventStatusFilterSelected: async Status_selection => {
        const properties = {
          ...(await fetchGeneralTrackingInfo()),
          Status_selection,
        }
        track(
          MIXPANEL_EVENT.USERS_AND_BRANCHES.STATUS_FILTER_SELECTED,
          properties
        )
        brazeClient.setCustomEvent(
          BRAZE_EVENT.USERS_AND_BRANCHES.STATUS_FILTER_SELECTED,
          properties
        )
      },
      sendEventEditUsersButtonTapped: async (
        branch_filter_selection,
        access_level_filter_selection,
        Status_selection,
        user_id_selection_count
      ) => {
        const properties = {
          ...(await fetchGeneralTrackingInfo()),
          branch_filter_selection,
          access_level_filter_selection,
          Status_selection,
          user_id_selection_count,
        }
        track(
          MIXPANEL_EVENT.USERS_AND_BRANCHES.EDIT_USERS_BUTTON_TAPPED,
          properties
        )
        brazeClient.setCustomEvent(
          BRAZE_EVENT.USERS_AND_BRANCHES.EDIT_USERS_BUTTON_TAPPED,
          properties
        )
      },
      sendEventAddNewUserButtonTapped: async () => {
        const properties = await fetchGeneralTrackingInfo()
        track(
          MIXPANEL_EVENT.USERS_AND_BRANCHES.ADD_NEW_USER_BUTTON_TAPPED,
          properties
        )
        brazeClient.setCustomEvent(
          BRAZE_EVENT.USERS_AND_BRANCHES.ADD_NEW_USER_BUTTON_TAPPED,
          properties
        )
      },
      sendEventNewUserSaved: async (
        new_user_email,
        new_user_branch,
        new_user_type
      ) => {
        const properties = {
          ...(await fetchGeneralTrackingInfo()),
          new_user_email,
          new_user_branch,
          new_user_type,
        }
        track(MIXPANEL_EVENT.USERS_AND_BRANCHES.NEW_USER_SAVED, properties)
        brazeClient.setCustomEvent(
          BRAZE_EVENT.USERS_AND_BRANCHES.NEW_USER_SAVED,
          properties
        )
      },
      sendEventEditInfoButtonTapped: async () => {
        const properties = await fetchGeneralTrackingInfo()
        track(
          MIXPANEL_EVENT.USERS_AND_BRANCHES.EDIT_INFO_BUTTON_TAPPED,
          properties
        )
        brazeClient.setCustomEvent(
          BRAZE_EVENT.USERS_AND_BRANCHES.EDIT_INFO_BUTTON_TAPPED,
          properties
        )
      },
      sendEventEditUserInfoSaveButtonTapped: async (
        edit_user_access_level_changed,
        edit_user_user_status,
        Change_password
      ) => {
        const properties = {
          ...(await fetchGeneralTrackingInfo()),
          edit_user_access_level_changed,
          edit_user_user_status,
          Change_password,
        }
        track(
          MIXPANEL_EVENT.USERS_AND_BRANCHES.EDIT_USER_INFO_SAVE_BUTTON_TAPPED,
          properties
        )
        brazeClient.setCustomEvent(
          BRAZE_EVENT.USERS_AND_BRANCHES.EDIT_USER_INFO_SAVE_BUTTON_TAPPED,
          properties
        )
      },
      sendEventUserTableSortedOnName: async () => {
        const properties = await fetchGeneralTrackingInfo()
        track(
          MIXPANEL_EVENT.USERS_AND_BRANCHES.USER_TABLE_SORTED_ON_NAME,
          properties
        )
        brazeClient.setCustomEvent(
          BRAZE_EVENT.USERS_AND_BRANCHES.USER_TABLE_SORTED_ON_NAME,
          properties
        )
      },
      sendEventUserTableSortedOnBranch: async () => {
        const properties = await fetchGeneralTrackingInfo()
        track(
          MIXPANEL_EVENT.USERS_AND_BRANCHES.USER_TABLE_SORTED_ON_BRANCH,
          properties
        )
        brazeClient.setCustomEvent(
          BRAZE_EVENT.USERS_AND_BRANCHES.USER_TABLE_SORTED_ON_BRANCH,
          properties
        )
      },
      sendEventUserTableSortedOnAccessLevel: async () => {
        const properties = await fetchGeneralTrackingInfo()
        track(
          MIXPANEL_EVENT.USERS_AND_BRANCHES.USER_TABLE_SORTED_ON_ACCESS_LEVEL,
          properties
        )
        brazeClient.setCustomEvent(
          BRAZE_EVENT.USERS_AND_BRANCHES.USER_TABLE_SORTED_ON_ACCESS_LEVEL,
          properties
        )
      },
      sendEventUserTableSortedOnStatus: async () => {
        const properties = await fetchGeneralTrackingInfo()
        track(
          MIXPANEL_EVENT.USERS_AND_BRANCHES.USER_TABLE_SORTED_ON_STATUS,
          properties
        )
        brazeClient.setCustomEvent(
          BRAZE_EVENT.USERS_AND_BRANCHES.USER_TABLE_SORTED_ON_STATUS,
          properties
        )
      },
    }),
    []
  )
}
