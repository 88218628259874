import _ from 'lodash'
import Constants from '../constants/action'
import { sortString } from '../views/helpers/orders'
import { findIncluded } from '../utils/orders/action'

const initialState = {
  orderRequests: [],
  parcels: [],
  orders: [],
  waypoints: [],
  activeOrders: [],
  pendingOrders: [],
  completedOrders: [],
  etas: [],
  filterParams: { sort: sortString('id', false) },
  sortBy: 'id',
  sortAsc: false,
  allOrderRequestIds: [],
  allParcelIds: [],
  selectedOrderRequest: null,
  selectedOrderRequestBreakdown: null,
  openedReportDisputeConfirmDialog: false, // related to slectedOrderRequest
  openedReportDisputeMessageDialog: false, // related to slectedOrderRequest
  currentPage: 1,
  prevPage: null,
  nextPage: null,
  totalPages: 0,
  totalCount: 0,
  totalPrice: 0.0,
  openOrderDetail: false,
  openPreviewImage: false,
  openSignatureImage: false,
  selectGalleryType: '',
  selectedParcelId: '',
  selectedParcelImages: [],
  parcelsSortedBy: 'id',
  parcelsSortedAsc: false,
  todayStatus: { pending: 0, active: 0, cancelled: 0, completed: 0 },
  goodsInformation: null,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case Constants.SET_ORDER_REQUESTS: {
      return {
        ...state,
        orderRequests: action.orderRequests,
        waypoints: findIncluded(action.included, 'waypoints'),
        orders: findIncluded(action.included, 'orders'),
        included: action.included,
        prevPage: action.meta.prev_page,
        nextPage: action.meta.next_page,
        currentPage: action.meta.current_page,
        totalPages: action.meta.total_pages,
        totalCount: action.meta.total_count,
        totalPrice: action.meta.total_price,
        allOrderRequestIds: action.meta.all_order_request_ids,
        allParcelIds: action.meta.all_parcel_ids,
        q: action.q,
        loading: false,
      }
    }

    case Constants.SET_ORDER_ETAS: {
      return {
        ...state,
        etas: [action.eta, ...state.etas],
      }
    }

    case Constants.SET_ORDER_STATUS_GROUPS: {
      const pendingOrders = action.orderRequests.filter(
        order => order.attributes.status === 'pending'
      )
      const activeOrders = action.orderRequests.filter(
        order => order.attributes.status === 'active'
      )
      const completedOrders = action.orderRequests.filter(
        order => order.attributes.status === 'completed'
      )

      return {
        ...state,
        activeOrders,
        pendingOrders,
        completedOrders,
      }
    }

    case Constants.SET_ORDERS_SORT: {
      return {
        ...state,
        ordersSortBy: action.column,
        ordersSortAsc: action.isNewColumn ? false : !state.ordersSortAsc,
      }
    }

    case Constants.SET_ORDERS_FILTER_PARAMS: {
      return {
        ...state,
        filterParams: action.filterParams,
      }
    }

    case Constants.SET_FILTER_PARAMS:
      return {
        ...state,
        filterParams: action.filterParams || state.filterParams,
        sortBy: action.sortBy || state.sortBy,
        sortAsc:
          typeof action.sortAsc === 'boolean' ? action.sortAsc : state.sortAsc,
      }

    case Constants.TOGGLE_OPEN_DETAIL:
      return {
        ...state,
        openOrderDetail: !state.openOrderDetail,
      }

    case Constants.TOGGLE_OPEN_SIGNATURE:
      return {
        ...state,
        openSignatureImage: !state.openSignatureImage,
      }

    // FIXME: not a toggle just a boolean select
    case Constants.TOGGLE_OPEN_REPORT_DISPUTE_CONFIRM_DIALOG:
      return {
        ...state,
        openedReportDisputeConfirmDialog: action.opened,
      }
    // FIXME: not a toggle just a boolean select
    case Constants.TOGGLE_OPEN_REPORT_DISPUTE_MESSAGE_DIALOG:
      return {
        ...state,
        openedReportDisputeMessageDialog: action.opened,
      }

    case Constants.TOGGLE_OPEN_PREVIEW:
      return {
        ...state,
        openPreviewImage: !state.openPreviewImage,
      }

    case Constants.SET_ORDER_REQUESTS_FETCHING:
      return { ...state, loading: true }

    case Constants.CLEAR_SELECTED_ORDER_REQUEST:
      return {
        ...state,
        dispute: null,
        goodsInformation: null,
        selectedOrder: null,
        orderRequestDetail: null,
        selectedParcels: null,
        timeSheet: null,
        selectedUser: null,
        selectedWaypoints: null,
        selectedOrderRequest: null,
      }

    case Constants.SELECTED_ORDER_REQUEST_BREAKDOWN_FETCHING:
      return { ...state, breakdownLoading: true }

    case Constants.SELECTED_ORDER_REQUEST_BREAKDOWN:
      return {
        ...state,
        selectedOrderRequestBreakdown: action.breakdown,
        breakdownLoading: false,
      }

    case Constants.SET_GOODS_INFORMATION_IMAGES: {
      if (state.selectedOrderRequest && state.goodsInformation) {
        return {
          ...state,
          goodsInformation: {
            attributes: {
              ...state.goodsInformation.attributes,
              images: action.images,
            },
          },
        }
      }
      return state
    }

    case Constants.UPDATE_SELECTED_ORDER_REQUEST: {
      if (
        state.selectedOrderRequest &&
        state.selectedOrderRequest.id === action.orderRequest.id
      ) {
        // Add the existing images to the new orderRequest
        const target = action.orderRequest
        let oldImages = _.get(
          state,
          'selectedOrderRequest.goods_information.images'
        )
        if (oldImages) {
          oldImages = oldImages.slice(0) // Array cloning
          _.set(target, 'goods_information.images', oldImages)
          return { ...state, selectedOrderRequest: target }
        }
      }
      return { ...state, selectedOrderRequest: action.orderRequest }
    }

    case Constants.SET_ORDERS_ERROR:
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}
