/* eslint-disable camelcase */
import { payByCreditCard } from '../../van-booking/duck/selectors'
import { getFeatureFlag } from 'ggx-service/feature-flag/selectors'
import {
  SUBSCRIPTION_STATUS,
  SUBSCRIPTION_STATE,
} from '../paid-subscription-plan.constants'

const getSubscriptionInfo = state => state?.subscriptionInfo

const getIsSubscribed = state =>
  state?.subscriptionInfo?.subscription_status === SUBSCRIPTION_STATUS.ACTIVE

const getEndDate = state => state?.subscriptionInfo?.end_date

const getStartDate = state => state?.subscriptionInfo?.start_date

const getState = state => state?.subscriptionInfo?.state

const getIsFreeTrial = state =>
  state?.subscriptionInfo?.state === SUBSCRIPTION_STATE.FREE_TRIAL

const getIsEligibleSubscription = state =>
  payByCreditCard(state) &&
  getFeatureFlag({ state, featureFlag: 'paid_subscription_plan' }) &&
  !getIsSubscribed(state)

const getDuration = state => {
  const startDate = getStartDate(state)
  const endDate = getEndDate(state)

  let trialDuration = 0
  if (startDate && endDate) {
    const trialStartDate = new Date(startDate)
    const trialEndDate = new Date(endDate)

    trialDuration = Math.round(
      (trialEndDate - trialStartDate) / (1000 * 60 * 60 * 24 * 30)
    )
  }

  return trialDuration
}

export {
  getSubscriptionInfo,
  getIsSubscribed,
  getEndDate,
  getStartDate,
  getState,
  getIsFreeTrial,
  getIsEligibleSubscription,
  getDuration,
}
