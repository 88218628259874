import PropTypes from 'prop-types'

import { StyledButton, Loader } from './button.component.styles'

const Button = props => {
  const { isLoading, loadingText, children, size } = props

  return (
    <StyledButton {...props}>
      {isLoading ? (
        <>
          <Loader size={size} /> {loadingText}
        </>
      ) : (
        children
      )}
    </StyledButton>
  )
}

Button.displayName = 'Button'

Button.propTypes = {
  isLoading: PropTypes.bool,
  loadingText: PropTypes.string,
  children: PropTypes.node,
  size: PropTypes.oneOf(['large', 'small']),
  // eslint-disable-next-line react/no-unused-prop-types
  disabled: PropTypes.bool,
  // eslint-disable-next-line react/no-unused-prop-types
  buttonType: PropTypes.oneOf([
    'primary',
    'warning',
    'secondary',
    'danger',
    'secondary-danger-100',
    'danger-100',
  ]),
}

export { Button }
