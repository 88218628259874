import { useEffect } from 'react'

const useOnOutsideRefClickListener = (ref, handler) => {
  useEffect(() => {
    const handleOnClick = event => {
      const clickedOutsideRefElement =
        ref && ref.current && !ref.current.contains(event.target)
      if (clickedOutsideRefElement) {
        handler()
      }
    }

    document.addEventListener('click', handleOnClick)
    return () => {
      document.removeEventListener('click', handleOnClick)
    }
  }, [handler, ref])
}

export { useOnOutsideRefClickListener }
