// @flow
import i18next from 'i18next'

const getLocaleAmount = amount => {
  const numberAmount = Math.abs(amount)
  return numberAmount.toLocaleString(i18next.language)
}

type Props = { amount: any }

const getCurrency = ({ amount }: Props = { amount: 0 }): string => {
  if (amount === undefined || amount === null) {
    return i18next.t('currency.format', { amount: '-' })
  }
  const localeAmount = getLocaleAmount(amount)
  const isNegative = Number(amount) < 0
  return `${isNegative ? '-' : ''}${i18next.t('currency.format', {
    amount: localeAmount,
  })}`
}

export default getCurrency
