import React from 'react'
import PropTypes from 'prop-types'

import CheckboxSelectedIcon from './assets/check-box-selected.svg'
import CheckboxDeselectedIcon from './assets/check-box-default.svg'
import CheckboxDisabledIcon from './assets/check-box-disable.svg'
import CheckboxIndeterminateIcon from './assets/check-box-indeterminate.svg'
import CheckBoxDisabledWithValue from './assets/check-box-disabled-with-value.svg'

import {
  HiddenCheckbox,
  DisplayedCheckbox,
  CheckboxContainer,
  StyledBody,
  SubContainer,
  StyledLabel,
} from './checkbox.component.styles'

class Checkbox extends React.Component {
  static propTypes = {
    dataTestid: PropTypes.string,
    className: PropTypes.string,
    labelText: PropTypes.string,
    customLabel: PropTypes.node,
    onChange: PropTypes.func,
    children: PropTypes.node,
    disabled: PropTypes.bool,
    input: PropTypes.shape({
      value: PropTypes.any,
      onChange: PropTypes.func,
    }),
    value: PropTypes.bool,
    indeterminate: PropTypes.bool,
  }

  static defaultProps = {
    input: {},
    value: false,
    indeterminate: false,
    onChange: () => {},
  }

  handleChange = event => {
    const { indeterminate } = this.props

    if (indeterminate) {
      ;(this.props.input.onChange || this.props.onChange)(false)
    } else {
      ;(this.props.input.onChange || this.props.onChange)(event.target.checked)
    }
  }

  _renderCorrectIcon = () => {
    const { disabled, indeterminate, value, input } = this.props

    if (disabled && value) {
      return <CheckBoxDisabledWithValue />
    }

    if (disabled) {
      return <CheckboxDisabledIcon />
    }

    if (indeterminate) {
      return <CheckboxIndeterminateIcon />
    }

    if (input.value || value) {
      return <CheckboxSelectedIcon />
    }

    return <CheckboxDeselectedIcon />
  }

  render() {
    const {
      className,
      dataTestid,
      labelText,
      customLabel,
      children,
      disabled,
      value,
      input,
    } = this.props

    const isChecked = Boolean(input.value || value)
    return (
      <div className={className} data-testid={dataTestid}>
        <StyledLabel data-testid="checkbox_label">
          <CheckboxContainer disabled={disabled}>
            <HiddenCheckbox
              checked={isChecked}
              onChange={this.handleChange}
              disabled={disabled}
              data-testid="checkbox"
            />
            <DisplayedCheckbox>{this._renderCorrectIcon()}</DisplayedCheckbox>
            {customLabel || <StyledBody>{labelText}</StyledBody>}
          </CheckboxContainer>
        </StyledLabel>
        {children && isChecked && <SubContainer>{children}</SubContainer>}
      </div>
    )
  }
}

export { Checkbox }
