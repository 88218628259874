import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { DataTab, CardContainer } from './voucher.component.styles'
import { Loading } from 'ggx-global/loading/loading.component'
import { Pagination } from 'ggx-componentlibrary/components/table/pagination/pagination.component'

const CardsTable = ({
  loading,
  column,
  currentPageNumber,
  totalPageNumber,
  onLoadPage,
  children,
}) => {
  const [t] = useTranslation()
  return (
    <DataTab isPending={loading}>
      <CardContainer column={column}>{children}</CardContainer>
      {totalPageNumber > 0 && (
        <Pagination
          paginationText={t('orderManagement.table.pagination', {
            currentPageNumber,
            totalPageNumber,
          })}
          currentPageNumber={currentPageNumber}
          totalPageNumber={totalPageNumber}
          onPageNavClick={onLoadPage}
          loading={loading}
        />
      )}
      <Loading pastDelay={loading} />
    </DataTab>
  )
}

CardsTable.propTypes = {
  loading: PropTypes.bool,
  column: PropTypes.number,
  currentPageNumber: PropTypes.number,
  totalPageNumber: PropTypes.number,
  onLoadPage: PropTypes.func,
  children: PropTypes.node,
}

export { CardsTable }
