import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { useSelector } from 'react-redux'

import { CardElementStyles, HiddenButton } from './payment.component.styles'
import { StyledError } from 'ggx-componentlibrary/components/text-field/text-field.component.styles'
import { getFeatureFlag } from 'ggx-service/feature-flag/selectors'
import { brazeClient, BRAZE_EVENT } from 'ggx-service/braze'
import { paymentService } from '../../payment/api/api.service'

const InjectedStripeCard = ({
  cardError,
  onReceiveToken,
  errorMessages,
  onConfirmCardSetup,
  onCardSaved,
}) => {
  const [error, setError] = useState(cardError)
  const elements = useElements()
  const stripe = useStripe()
  const featureFlagPayment3ds = useSelector(state =>
    getFeatureFlag({ state, featureFlag: 'payment_3ds' })
  )

  useEffect(() => {
    setError(cardError)
  }, [cardError])

  const onChange = info => {
    setError(info.error)
  }

  const saveCardUsingIntent = async () => {
    setError(null)

    try {
      const { client_secret } = await paymentService.getCardSetupIntent()

      const cardElement = elements.getElement(CardElement)
      const result = await stripe.confirmCardSetup(client_secret, {
        payment_method: {
          card: cardElement,
        },
      })

      if (result.setupIntent) {
        onConfirmCardSetup()
        brazeClient.setCustomEvent(BRAZE_EVENT.PAYMENT.ADD_CARDS)
        return
      } else if (result.error && result.error.type === 'validation_error') {
        setError({ code: result.error.code })
      } else {
        setError({ code: 'save_card_error' })
      }

      onCardSaved()
    } catch (caughtError) {
      setError({ code: 'save_card_error' })
      onCardSaved()
    }
  }

  const getToken = async () => {
    setError(null)

    try {
      const cardElement = elements.getElement(CardElement)
      const result = await stripe.createToken(cardElement)

      if (result.token) {
        onReceiveToken(result.token)
        brazeClient.setCustomEvent(BRAZE_EVENT.PAYMENT.ADD_CARDS)
        return
      } else if (result.error && result.error.type === 'validation_error') {
        setError({ code: result.error.code })
      } else {
        setError({ code: 'save_card_error' })
      }

      onCardSaved()
    } catch (caughtError) {
      setError({ code: 'save_card_error' })
      onCardSaved()
    }
  }

  const saveCardHandler = event => {
    event.preventDefault()
    event.stopPropagation()

    if (error) {
      onCardSaved()
      return
    }

    if (featureFlagPayment3ds) {
      saveCardUsingIntent()
    } else {
      getToken()
    }
  }

  return (
    <form className="credit-card-form" onSubmit={saveCardHandler}>
      <CardElement options={{ style: CardElementStyles }} onChange={onChange} />
      {error?.code && <StyledError>{errorMessages[error.code]}</StyledError>}
      <HiddenButton
        className="credit-card-hidden-button"
        onClick={saveCardHandler}
      />
    </form>
  )
}

InjectedStripeCard.propTypes = {
  cardError: PropTypes.shape({
    code: PropTypes.string,
  }),
  onReceiveToken: PropTypes.func,
  errorMessages: PropTypes.object,
  onConfirmCardSetup: PropTypes.func,
  onCardSaved: PropTypes.func,
}

InjectedStripeCard.defaultProps = {
  errorMessages: {},
  onCardSaved: () => {},
}

export { InjectedStripeCard }
