import PropTypes from 'prop-types'
import MuiAvatar from '@mui/material/Avatar'
import { Avatar } from './message.component.styles'

const MessageAvatar = ({ url }) =>
  !url ? (
    <Avatar src="/images/general_3/icon_avatar.png" alt="icon" />
  ) : (
    <MuiAvatar src={url} />
  )

MessageAvatar.propTypes = {
  url: PropTypes.string,
}

export default MessageAvatar
