import { Component } from 'react'
import PropTypes from 'prop-types'

import ProAvatarOverlay from '../../assets/images/pro-avatar-overlay.svg'
import { IconImg } from './icon'

export default class ProfilePhoto extends Component {
  constructor(props) {
    super(props)
    let keyPrefix
    // NOTE: currentUser has the key "organization"
    if ({}.hasOwnProperty.call(props.owner, 'organization')) {
      keyPrefix = 'user'
    } else {
      keyPrefix = 'organization'
    }
    this.state = { keyPrefix }
  }

  overridePicture = path => {
    this.setState({ overrideSrc: path })
  }

  _image(src, size = '128px', keyPrefix, navBarPic = false) {
    const { isSubscribed } = this.props
    let style = { borderRadius: '50%', width: size, height: size }

    if (!navBarPic) {
      return (
        <img
          className={`${keyPrefix}-profile-photo`}
          src={src}
          style={style}
          alt="profile"
        />
      )
    }

    if (isSubscribed) {
      style = { ...style, border: '2px solid #712958', boxSizing: 'border-box' }
    }

    return (
      <div
        style={{
          position: 'relative',
          overflow: 'visible',
          width: size,
          height: size,
        }}
      >
        <img
          className={`${keyPrefix}-profile-photo`}
          src={src}
          style={style}
          alt="profile"
        />
        {isSubscribed && (
          <ProAvatarOverlay
            style={{ position: 'absolute', bottom: '-4px', right: '-6px' }}
          />
        )}
      </div>
    )
  }

  render() {
    const { altImgPath, owner, size, navBarPic } = this.props
    const profilePhoto = owner.profile_photo
    const src = `/images/${altImgPath}.png`

    if (this.state.overrideSrc) {
      return this._image(
        this.state.overrideSrc,
        size,
        this.state.keyPrefix,
        navBarPic
      )
    }
    if (profilePhoto && profilePhoto.urls) {
      const squareSmallUrl = profilePhoto.urls.square_small
      return this._image(squareSmallUrl, size, this.state.keyPrefix, navBarPic)
    }
    if (this.state.keyPrefix === 'organization') {
      return (
        <IconImg
          id={`${this.state.keyPrefix}-profile-photo`}
          imgPath={altImgPath}
        />
      )
    }

    return this._image(src, size, this.state.keyPrefix, navBarPic)
  }
}

ProfilePhoto.propTypes = {
  owner: PropTypes.object.isRequired,
  isSubscribed: PropTypes.bool.isRequired,
  altImgPath: PropTypes.string.isRequired,
  size: PropTypes.string,
  navBarPic: PropTypes.bool,
}
