import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
  Container,
  FeatureFlagList,
  StyledTable,
  ConfigureFeatureFlagSectionWrapper,
  DebugMenuItem,
  StyledTextField,
} from './debug-menu.component.styles'
import { Button } from 'ggx-componentlibrary/components/button/button.component'
import { Accordion } from 'ggx-componentlibrary/components/accordion/accordion.component'
import { getHeaderBannerHeight } from 'ggx-global/duck/selectors'

const DebugMenu = () => {
  const [filter, setFilter] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const dispatch = useDispatch()
  const featureFlagList = useSelector(state =>
    Object.keys(state)
      .filter(key => /^featureFlag/i.test(key))
      .sort()
      .map(featureFlagKey => ({
        name: featureFlagKey,
        status: state[featureFlagKey],
      }))
  )

  const filteredFeatureFlagList = featureFlagList.filter(featureFlag =>
    featureFlag.name.toLowerCase().includes?.(filter.toLowerCase())
  )
  const headerBannerHeight = useSelector(state => getHeaderBannerHeight(state))
  const organisationId = useSelector(
    state => state?.session?.currentUser?.organization?.id
  )
  const organisationPaymentMethod = useSelector(
    state => state?.session?.currentUser?.organization?.payment_method
  )
  const organisationPricingModel = useSelector(
    state => state?.session?.currentUser?.organization?.pricing_type
  )
  const userId = useSelector(state => state?.session?.currentUser?.id)
  const firstFeatureFlagList = filteredFeatureFlagList.filter(
    (featureFlag, index) => index < filteredFeatureFlagList.length / 2
  )
  const secondFeatureFlagList = filteredFeatureFlagList.filter(
    (featureFlag, index) => index >= filteredFeatureFlagList.length / 2
  )

  const handleFilterChange = value => {
    setFilter(value)
  }

  const overrideActionName = flagName => {
    switch (flagName) {
      case 'featureFlagVnVan500Van1000':
        return 'FEATUREFLAG_VN_VAN500_VAN1000'
      case 'featureFlagGogodelivery':
        return 'FEATUREFLAG_GOGODELIVERY_GGB'
      default:
        return ''
    }
  }

  const renderFeatureFlagListAsTable = list => {
    const rows = list.map(featureFlag => {
      let actionType = featureFlag.name
        .replace(/([A-Z0-9]+)/g, '_$1')
        .replace('_', '')
        .toUpperCase()
      actionType =
        overrideActionName(featureFlag.name) === ''
          ? actionType
          : overrideActionName(featureFlag.name)

      const handleClick = newValue => {
        dispatch({ type: actionType, value: newValue })
      }

      return (
        <StyledTable.Row>
          <StyledTable.Data isFlag>
            {featureFlag.name.replace('featureFlag', '')}
          </StyledTable.Data>
          <StyledTable.Data
            button
            enabled={featureFlag.status}
            onClick={() => handleClick(true)}
          >
            On
          </StyledTable.Data>
          <StyledTable.Data
            button
            enabled={!featureFlag.status}
            onClick={() => handleClick(false)}
          >
            Off
          </StyledTable.Data>
        </StyledTable.Row>
      )
    })

    return <StyledTable>{rows}</StyledTable>
  }

  return (
    <>
      <DebugMenuItem
        typographyStyles="mr-m c-red_medium"
        onClick={() => setIsOpen(prevState => !prevState)}
      >
        ⚙
      </DebugMenuItem>
      {isOpen && (
        <Container headerBannerHeight={headerBannerHeight}>
          <Accordion header="Configure Feature flags" defaultIsOpen>
            <StyledTextField
              type="text"
              value={filter}
              showClearIcon
              onChange={handleFilterChange}
              onClearInput={() => setFilter('')}
            />
            <ConfigureFeatureFlagSectionWrapper>
              <FeatureFlagList>
                {renderFeatureFlagListAsTable(firstFeatureFlagList)}
              </FeatureFlagList>
              <FeatureFlagList>
                {renderFeatureFlagListAsTable(secondFeatureFlagList)}
              </FeatureFlagList>
            </ConfigureFeatureFlagSectionWrapper>
          </Accordion>

          <Accordion header="Organisation / User Information">
            <StyledTable>
              <StyledTable.Row>
                <StyledTable.Data>Organisation Id</StyledTable.Data>
                <StyledTable.Data>{organisationId}</StyledTable.Data>
              </StyledTable.Row>
              <StyledTable.Row>
                <StyledTable.Data>Organisation Payment method</StyledTable.Data>
                <StyledTable.Data>{organisationPaymentMethod}</StyledTable.Data>
              </StyledTable.Row>
              <StyledTable.Row>
                <StyledTable.Data>Organisation Pricing model</StyledTable.Data>
                <StyledTable.Data>{organisationPricingModel}</StyledTable.Data>
              </StyledTable.Row>
              <StyledTable.Row>
                <StyledTable.Data>User Id</StyledTable.Data>
                <StyledTable.Data>{userId}</StyledTable.Data>
              </StyledTable.Row>
            </StyledTable>
          </Accordion>

          <Accordion header="Other tools">
            <Button onClick={() => localStorage.clear()}>
              Clear local storage
            </Button>
          </Accordion>
        </Container>
      )}
    </>
  )
}

export { DebugMenu }
