import Constants from '../constants/action'

const initialState = {
  map: null,
  autocompleteService: null,
  placesService: null,
  geocoder: null,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case Constants.SET_SERVICES:
      return {
        ...state,
        autocompleteService: action.autocompleteService,
        placesService: action.placesService,
        geocoder: action.geocoder,
      }
    case Constants.SET_MAP_OBJECT:
      return { ...state, map: action.map }
    default:
      return state
  }
}
