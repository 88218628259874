import { isTaiwan } from '../legacy/utils/locale'

const taiwanSuspension = () => {
  if (new Date('2022-10-01T00:00:00+08:00') <= new Date() && isTaiwan()) {
    // TW service is going to shutdown on 01Oct2022.
    window.location.href = 'https://www.gogox.com/tw/'
  }
}

const checkServiceSuspension = () => {
  taiwanSuspension()
}

export { checkServiceSuspension }
