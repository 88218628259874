import React from 'react'
import { useSelector } from 'react-redux'
import { Trans } from 'react-i18next'

import { Wrapper } from './banner.component.styles'
import { Body } from 'ggx-componentlibrary/design/typography/typography.styles'
import { getBanner } from '../duck/selectors'
import { api } from '../../legacy/utils/api'
import { formatDateTime } from 'ggx-service/date-time/format-date-time'

const Banner = () => {
  const { key, parameter } = useSelector(state => getBanner(state))
  const isUserLoggedIn = api.loggedIn()

  if (!key) {
    return null
  }

  return (
    <Wrapper positionFixed={isUserLoggedIn}>
      {key === 'subscription_need_credit_card' && (
        <Body typographyStyles="c-white mb-none">
          <Trans
            i18nKey="common__add_credit_card_before_subscription_expires"
            components={<b />}
            values={{
              date: formatDateTime(
                parameter.subscriptionEndDate,
                'D MMM, YYYY'
              ),
            }}
          />
        </Body>
      )}
    </Wrapper>
  )
}

export { Banner }
