import _ from 'lodash'
import axios from 'axios'
import {
  getCountry,
  getAuthToken,
  setAuthToken,
  clearAuthToken,
} from '../views/helpers'
import Countries from '../constants/countries'
import { getLocale } from './locale'
import { apiLanguageCode } from '../locales/conversions'
import { unauthorizedSession } from '../actions/session'
import { appStore } from '../../store'

// to work with error from `/b2b/order_requests/download_excel`
function needIndices(params, isArray = false) {
  if (isArray && _.isObject(params) && !_.isArray(params)) {
    return true
  } else if (_.isArray(params)) {
    return params.map(param => needIndices(param, true)).indexOf(true) >= 0
  } else if (_.isObject(params)) {
    return (
      _.flatMapDeep(params, param => needIndices(param, false)).indexOf(true) >=
      0
    )
  }
  return false
}

function paramsSerializer(params) {
  if (needIndices(params)) {
    return qs.stringify(params)
  }

  return qs.stringify(params, { arrayFormat: 'brackets' })
}

function noop() {}

function ensureEndWithSlash(url) {
  if (!url) return url

  return url.endsWith('/') ? url : `${url}/`
}

class Api {
  static publicPaths = [
    'users/sign_in',
    'users/reset_password',
    'users/forgot_password_instruction',
    'b2b/users/account_confirm',
    'b2b/users/verification_email',
    'b2b/users/verify_confirm_token',
    'b2b/users/activate',
  ]

  static BASE_URL = {
    API_URL: `${ensureEndWithSlash(ENV.API_URL)}`,
    USERS: `${ensureEndWithSlash(ENV.API_URL)}users/`,
    B2B: `${ensureEndWithSlash(ENV.API_URL)}b2b/`,
    DP_C3: `${ensureEndWithSlash(ENV.DP_C3_API_URL)}`,
    NDD: `${ensureEndWithSlash(ENV.NDD_API_URL)}`,
    WALLET: `${ensureEndWithSlash(ENV.WALLET_API_URL)}`,
  }

  constructor(baseURL = localStorage.getItem('api_url') || ENV.API_URL) {
    this.url = ensureEndWithSlash(baseURL)
    const locale = getLocale()
    const language = apiLanguageCode(locale)

    this.defaultHeader = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'GoGoVan-User-Language': language,
      'GoGoVan-User-Auth-Version': '2',
    }

    this.axios = axios.create({ baseURL })

    this.axios.interceptors.response.use(
      response => response,
      error => {
        const fullURL = (this.url + error.config.url).replace(
          /(?<!:)\/\//g,
          '/'
        )
        if (
          error?.response?.status === 401 &&
          !Api.publicPaths.some(path => fullURL.endsWith(path))
        ) {
          appStore.getStore().dispatch(unauthorizedSession())
        }
        return Promise.reject(error)
      }
    )
  }

  loggedIn() {
    return !!getAuthToken()
  }

  async login(uniqueIdentifier, password, rememberMe, dispatch) {
    try {
      const data = { user: { password } }

      if (getCountry() === Countries.CHINA) {
        data.user.phone_number = uniqueIdentifier
      } else {
        data.user.email = uniqueIdentifier
      }

      const info = await this.request('post', 'users/sign_in', dispatch, data)
      setAuthToken(info)

      return info
    } catch (error) {
      // console.warn('Error in loginUser', error)
      throw error
    }
  }

  async logout() {
    try {
      const data = {}
      const dispatch = noop()
      const res = await this.request('post', 'users/sign_out', dispatch, data)
      return res
    } catch (error) {
      throw error
    } finally {
      clearAuthToken()
    }
  }

  get(path, dispatch, data) {
    try {
      if (path !== 'users/sign_in') {
        const authToken = getAuthToken()
        this.defaultHeader.Authorization = `Basic ${authToken}`
      }

      return this.axios.get(path, {
        params: data,
        headers: this.defaultHeader,
        paramsSerializer,
      })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('Error in api request', error)
      throw error
    }
  }

  uploadFile(method, path, data, fileType, dispatch) {
    try {
      const authToken = getAuthToken()
      const headers = {
        ...this.defaultHeader,
        Authorization: `Basic ${authToken}`,
        Accept: fileType,
        'Content-Type': fileType,
      }

      const config = {
        method,
        url: path,
        data,
        headers,
      }

      return this.axios.request(config)
    } catch (error) {
      throw error
    }
  }

  request(
    method,
    path,
    dispatch,
    data,
    headers = {},
    config = {},
    params = {}
  ) {
    try {
      if (!Api.publicPaths.includes(path)) {
        const authToken = getAuthToken()
        this.defaultHeader.Authorization = `Basic ${authToken}`
      }

      const requestConfig = {
        ...config,
        method,
        url: path,
        data,
        headers: { ...this.defaultHeader, ...headers },
        params,
      }

      return this.axios.request(requestConfig)
    } catch (error) {
      throw error
    }
  }

  changeAPIEndpoint(url) {
    this.url = url
    this.axios = axios.create({ baseURL: url })
  }
}

let api = new Api() // Might be mocked for tests

function injectAPI(newApi) {
  api = newApi
}

function restoreAPI() {
  api = new Api()
}

export { api, Api, injectAPI, restoreAPI, ensureEndWithSlash }
