import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Link from 'react-router/lib/Link'

import {
  HeaderWrapper,
  StyledGogoBusinessLogoLink,
  StyledGogoBusinessLogo,
  StyledGogoBusinessProLogo,
  AddCreditButtons,
  StyledIconDollar,
  StyledHamburgerIcon,
  LoginLink,
} from './header.component.styles'
import {
  SegmentedButtonsGroup,
  SegmentedButton,
} from '../../component-library/components/segmented-button/segmented-button.component'
import { ConnectedTopupModal } from '../../billing/topup-modal/topup-modal.component'
import { Banner } from '../banner/banner.component'
import { formatCurrency } from '../../service/i18n/format-currency.service'
import { payByCreditCard } from '../../van-booking/duck/selectors'
import { getTotalCredit } from '../../billing/duck/selectors'
import { getFeatureFlagGogodelivery } from '../../delivery/duck/selectors'
import { collapseSidebar } from '../../global/duck/actions'
import { api } from '../../legacy/utils/api'
import { getIsSubscribed } from '../../paid-subscription-plan/duck/selectors'

const Header = ({
  isCreditCardUser,
  featureFlagGogodelivery,
  totalCredit,
  toggleCollapseSidebar,
  showLoginButton,
  showHamburgerIcon,
  showTopupButtons,
}) => {
  const [t] = useTranslation()
  const isSubscribed = useSelector(state => getIsSubscribed(state))

  const [showTopupModal, setShowTopupModal] = useState(false)

  const isUserLoggedIn = api.loggedIn()

  const handleAddCreditClick = () => {
    setShowTopupModal(true)
  }

  const handleModalClose = () => {
    setShowTopupModal(false)
  }

  const creditCardUserWithPrepaid = isCreditCardUser || featureFlagGogodelivery

  return (
    <>
      <HeaderWrapper isUserLoggedIn={isUserLoggedIn}>
        {showHamburgerIcon && (
          <StyledHamburgerIcon onClick={toggleCollapseSidebar} />
        )}
        <StyledGogoBusinessLogoLink to="/">
          {isSubscribed ? (
            <StyledGogoBusinessProLogo />
          ) : (
            <StyledGogoBusinessLogo />
          )}
        </StyledGogoBusinessLogoLink>
        {showTopupButtons && creditCardUserWithPrepaid && (
          <AddCreditButtons>
            <SegmentedButtonsGroup>
              <SegmentedButton as={Link} to="/payment-method">
                <StyledIconDollar />
                {formatCurrency({ amount: totalCredit })}
              </SegmentedButton>
              <SegmentedButton onClick={handleAddCreditClick}>
                {t('payment.subheaders.addCredit')}
              </SegmentedButton>
            </SegmentedButtonsGroup>
          </AddCreditButtons>
        )}
        {showLoginButton && (
          <LoginLink href="/login">{t('button__log_in')}</LoginLink>
        )}
        {showTopupModal && (
          <ConnectedTopupModal handleModalClose={handleModalClose} />
        )}
      </HeaderWrapper>
      <Banner />
    </>
  )
}

Header.propTypes = {
  isCreditCardUser: PropTypes.bool,
  totalCredit: PropTypes.number,
  featureFlagGogodelivery: PropTypes.bool,
  toggleCollapseSidebar: PropTypes.func,
  showLoginButton: PropTypes.bool,
  showHamburgerIcon: PropTypes.bool,
  showTopupButtons: PropTypes.bool,
}

Header.defaultProps = {
  isCreditCardUser: false,
  totalCredit: 0,
  showHamburgerIcon: false,
  showTopupButtons: false,
}

const mapStateToProps = state => ({
  isCreditCardUser: payByCreditCard(state),
  totalCredit: getTotalCredit(state),
  featureFlagGogodelivery: getFeatureFlagGogodelivery(state),
})

const mapDispatchToProps = dispatch => ({
  toggleCollapseSidebar: value => dispatch(collapseSidebar(value)),
})

const ConnectedHeader = connect(mapStateToProps, mapDispatchToProps)(Header)

export { ConnectedHeader }
