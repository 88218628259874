// @flow
import convertActionConstantHash from './convertActionConstantHash'

const ActionConstants: Array<string> = [
  'USER_LOGGED_IN',
  'USER_LOGGED_OUT',
  'CURRENT_USER',
  'UPDATE_CURRENT_USER',
  'UPDATE_CURRENT_USER_LANGUAGE',
  'SESSIONS_LOADING',
  'SESSIONS_ERROR',
  'SESSION_UNAUTHORIZED',
  'SESSION_FORBIDDEN',
  'SESSION_RESET',
  'SESSION_SWITCH',
  'REGISTRATIONS_ERROR',

  'SET_ERROR_MESSAGE',
  'SET_SUCCESS_MESSAGE',
  'SET_HIGHLIGHT_ERROR',
  'CLEAR_HIGHLIGHT_ERROR',

  'SHOW_LOADING',
  'HIDE_LOADING',

  'CURRENT_ORDER_REQUEST_GET_BREAKDOWN',
  'CURRENT_ORDER_REQUEST_ADD_WAYPOINT',
  'CURRENT_ORDER_REQUEST_UPDATE_WAYPOINTS',
  'TOGGLE_OPEN_REPORT_DISPUTE_CONFIRM_DIALOG',
  'TOGGLE_OPEN_REPORT_DISPUTE_MESSAGE_DIALOG',
  'SET_ACTIVE_WAYPOINT',
  'CURRENT_ORDER_REQUEST_SET_PICK_UP_TIME_TYPE',
  'CURRENT_ORDER_REQUEST_SET_NOW_PICK_UP_TIME',

  'SET_ORDER_REQUESTS',
  'SET_ORDER_REQUESTS_FETCHING',
  'SELECT_ORDER_REQUEST',
  'CLEAR_SELECTED_ORDER_REQUEST',
  'SELECTED_ORDER_REQUEST_BREAKDOWN',
  'SELECTED_ORDER_REQUEST_BREAKDOWN_FETCHING',
  'SET_GOODS_INFORMATION_IMAGES',
  'UPDATE_SELECTED_ORDER_REQUEST',
  'SET_FILTER_PARAMS',
  'SET_VEHICLE_LIST',

  'SET_ORDERS_SORT',
  'SET_ORDERS_FILTER_PARAMS',
  'SET_ORDER_STATUS_GROUPS',
  'SELECTED_NEW_ORDER_REQUEST_BREAKDOWN',
  'SET_ORDERS_ERROR',
  'SET_ORDER_ETAS',

  'NOTIFICATION_CONNECTION_DOWN',
  'NOTIFICATION_CONNECTION_UP',
  'NOTIFICATION_RECEIVE_MESSAGE',
  'NOTIFICATION_SUBSCRIBE_SUCCEEDED',
  'NOTIFICATION_SUBSCRIBE_FAILED',
  'NOTIFICATION_SUBSCRIBING',
  'NOTIFICATION_READ_MESSAGES',
  'NOTIFICATION_CLEAR_MESSAGES',
  'NOTIFICATION_CLEAR_MESSAGE',
  'NOTIFICATION_READ_MESSAGE',
  'SET_NOTIFICATIONS',
  'SET_NOTIFICATIONS_FETCHING',
  'TOGGLE_NOTIFICATION_ACTION_REQUEST',

  'CLEAR_ADDRESSES',
  'SET_ADDRESSES',
  'SET_ADDRESSES_FETCHING',
  'SET_ADDRESSES_ERROR',

  'SET_MAP_OBJECT',
  'SET_SERVICES',

  'CLEAR_MESSAGES',
  'CLEAR_MESSAGE',
  'MARK_READ',

  'NOTIFICATION_CENTRE_TOGGLE',

  'SET_ORGANIZATION',
  'RESET_ORGANIZATION',
  'SET_ADMIN_ORGANIZATIONS',
  'SET_REGIONS',

  'TOGGLE_OPEN_DETAIL',
  'TOGGLE_OPEN_SIGNATURE',
  'TOGGLE_OPEN_PREVIEW',

  'IMPORT_SPLIT_ORDER',
  'IMPORT_SPLIT_WAYPOINT',

  'CURRENT_ROUTE_MODIFIED',
  'CURRENT_ROUTE_CREATED',
  'CURRENT_ROUTE_UPDATED',

  'SET_WALLET_FILTER_PARAMS',
  'SET_TRANSACTIONS',
  'SET_TRANSACTIONS_ERROR',
  'SET_TRANSACTIONS_FETCHING',
  'SET_DEPOSIT_TRANSACTIONS',
  'SET_DEPOSIT_TRANSACTIONS_FETCHING',
  'SET_DEPOSIT_TRANSACTIONS_ERROR',
  'SET_DEPOSIT_FILTER_PARAMS',

  'POLYGON_DIALOG_SEEN',
]

export default convertActionConstantHash(ActionConstants)
