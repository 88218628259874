import Constants from '../constants/action'

const initialState = {
  routes: [],
  selectedRoutes: [],
  nextPage: null,
  prevPage: null,
  currentPage: 1,
  totalPages: 1,
  loading: false,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case Constants.CLEAR_ROUTE:
      return initialState

    default:
      return state
  }
}
