import translate from '../locales/translate'

const translateWithPrefix = key => translate(`utils.time.${key}`)

export function setTime(updateTime, oldTime = new Date()) {
  const newTime = new Date(oldTime.getTime())
  newTime.setHours(updateTime.getHours())
  newTime.setMinutes(updateTime.getMinutes())
  newTime.setSeconds(updateTime.getSeconds())
  return newTime
}

export function setDate(updateDate, oldDate = new Date()) {
  const newDate = new Date(oldDate.getTime())
  newDate.setYear(updateDate.getFullYear())
  newDate.setMonth(updateDate.getMonth())
  newDate.setDate(updateDate.getDate())
  return newDate
}

export function humanizeTime(seconds) {
  const hour = seconds > 3600 ? Math.floor(seconds / 3600) : 0
  const minute = seconds > 60 ? Math.floor((seconds - hour * 3600) / 60) : 0
  const second = seconds % 60

  if (seconds > 3600) {
    return `${hour}${translateWithPrefix(
      'hours'
    )} ${minute}${translateWithPrefix('minutes')}`
  } else if (seconds > 60) {
    return `${minute}${translateWithPrefix(
      'minutes'
    )} ${second}${translateWithPrefix('seconds')}`
  }

  return `${second}s`
}

export function filterKeys(obj, keys) {
  const ret = {}
  keys.forEach(key => {
    if (typeof obj[key] !== 'undefined') {
      ret[key] = obj[key]
    }
  })
  return ret
}

// Extracted from https://github.com/reactjs/redux/issues/303#issuecomment-125184409
export function observeStore(store, select, onChange) {
  let currentState

  function handleChange() {
    const nextState = select(store.getState())
    if (nextState && nextState !== currentState) {
      currentState = nextState
      onChange(currentState)
    }
  }

  const unsubscribe = store.subscribe(handleChange)
  handleChange()
  return unsubscribe
}

export function isTablet() {
  return window.matchMedia('(max-width: 1023px)').matches
}
