import { featureFlags } from './constants'
import { getReduxFeatureFlagName } from './helpers'

const featureFlagReducers = featureFlags.reduce((reducers, featureflag) => {
  const initialState = false

  const reducerName = getReduxFeatureFlagName(featureflag)

  const reducer = {
    ...reducers,
    [reducerName]: (state = initialState, action = {}) => {
      switch (action.type) {
        case `FEATUREFLAG_${featureflag.toUpperCase()}`:
          return action.value || initialState

        default:
          return state
      }
    },
  }

  return reducer
}, {})

export { featureFlagReducers }
