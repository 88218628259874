import axios from 'axios'

const apiService = {
  requestAppVersion: async () => {
    try {
      const result = await axios.get('/app-version')
      return result.data
    } catch (error) {
      throw error
    }
  },
}

export { apiService }
