const SUBSCRIPTION_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
}

const SUBSCRIPTION_STATE = {
  ACTIVE: 'active',
  CANCEL_AT_PERIOD_END: 'cancel_at_period_end',
  FREE_TRIAL: 'free_trial',
  FREE_TRIAL_EXPIRED: 'free_trial_expired',
}

export { SUBSCRIPTION_STATUS, SUBSCRIPTION_STATE }
