import styled from "styled-components"

import { Body } from "ggx-componentlibrary/design/typography/typography.styles"

const LearnMoreText = styled(Body)`
  display: flex;
  align-items: center;
`

export { LearnMoreText }
