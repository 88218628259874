import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import { parse, format, isValid, isBefore, isAfter } from 'date-fns'

import { COLOR } from 'ggx-componentlibrary/design/color/color.constants'
import { DEFAULT_FONT_STACK_FOR_JS } from 'ggx-componentlibrary/design/typography/typography.constants'

import { DateInputWrapper } from './date-input.component.styles'

const overAllowedDateRange = parsedDate => {
  return (
    parsedDate.getFullYear() < 2014 ||
    parsedDate.getFullYear() > new Date().getFullYear() ||
    !isValid(parsedDate)
  )
}

const formatInputVal = str => {
  let match = str.match(/^(\d{1,2})[^0-9]?(\d{1,2})?[^0-9]?(\d{1,2})?$/)

  if (match) {
    let [, day, month, year] = match
    let formatted = day
    if (month) {
      formatted += `/${month}`
    }
    if (year) {
      formatted += `/${year}`
    }
    return formatted
  }
  return str
}

const DateInput = ({
  dateRange = [],
  inputText,
  onClick,
  handleDateInputChange,
  handleSelect,
}) => {
  const [editableDateRange, setEditableDateRange] = useState({
    startDate: {
      value: format(dateRange[0].startDate, 'dd/MM/yy'),
      invalid: false,
    },
    endDate: {
      value: format(dateRange[0].endDate, 'dd/MM/yy'),
      invalid: false,
    },
  })
  const [isBackspaceKey, setIsBackspaceKey] = useState(false)
  const textFieldStyles = (invalid) => ({
    '.MuiInputBase-formControl:before': {
      display: 'none',
    },
    '.MuiInputBase-formControl:after': {
      display: 'none',
    },
    '.MuiInput-input': {
      textAlign: 'center',
      fontSize: '14px',
      fontFamily: DEFAULT_FONT_STACK_FOR_JS,
      color: invalid ? COLOR.RED_MEDIUM : COLOR.BLACK,
    },
  })

  const handleInputChange = (e, input) => {
    let { value } = e.target
    const parsedDate = parse(value, 'dd/MM/yy', new Date())

    if (!isBackspaceKey) {
      value = formatInputVal(value)
    }

    setEditableDateRange({
      ...editableDateRange,
      [`${input}`]: {
        value: value,
        invalid: overAllowedDateRange(parsedDate),
      },
    })
  }

  const handleInputBlur = (e, input) => {
    const parsedStartDate = parse(
      editableDateRange.startDate.value,
      'dd/MM/yy',
      new Date()
    )
    const parsedEndDate = parse(
      editableDateRange.endDate.value,
      'dd/MM/yy',
      new Date()
    )

    if (editableDateRange[input].invalid) {
      setEditableDateRange({
        ...editableDateRange,
        [`${input}`]: {
          value: format(dateRange[0][input], 'dd/MM/yy'),
          invalid: false,
        },
      })
    } else {
      const updatedDates = {
        startDate: {
          startDate: parsedStartDate,
          endDate: isAfter(parsedStartDate, parsedEndDate)
            ? parsedStartDate
            : parsedEndDate,
        },
        endDate: {
          startDate: isBefore(parsedEndDate, parsedStartDate)
            ? parsedEndDate
            : parsedStartDate,
          endDate: parsedEndDate,
        },
      }
      const updatedDatesObj = updatedDates[input]
      handleDateInputChange(updatedDatesObj)
    }
  }

  const handleKeyDown = e => {
    const isValidDates =
      !editableDateRange.startDate.invalid && !editableDateRange.endDate.invalid

    setIsBackspaceKey(e.key === 'Backspace')

    if (e.key === 'Enter' && isValidDates) {
      const parsedStartDate = parse(
        editableDateRange.startDate.value,
        'dd/MM/yy',
        new Date()
      )
      const parsedEndDate = parse(
        editableDateRange.endDate.value,
        'dd/MM/yy',
        new Date()
      )

      handleSelect({
        startDate: parsedStartDate,
        endDate: parsedEndDate,
        shouldUpdateRange: true,
      })
    }
  }

  useEffect(() => {
    setEditableDateRange({
      startDate: {
        value: format(dateRange[0].startDate, 'dd/MM/yy'),
        invalid: false,
      },
      endDate: {
        value: format(dateRange[0].endDate, 'dd/MM/yy'),
        invalid: false,
      },
    })
  }, [dateRange[0].startDate, dateRange[0].endDate])

  return (
    <DateInputWrapper onClick={onClick}>
      <TextField
        id="date-input-start-date"
        variant="standard"
        sx={textFieldStyles(editableDateRange.startDate.invalid)}
        value={editableDateRange.startDate.value}
        onChange={e => handleInputChange(e, 'startDate')}
        onBlur={e => handleInputBlur(e, 'startDate')}
        onKeyDown={handleKeyDown}
      />
      <span>{inputText}</span>
      <TextField
        id="date-input-end-date"
        variant="standard"
        sx={textFieldStyles(editableDateRange.endDate.invalid)}
        value={editableDateRange.endDate.value}
        onChange={e => handleInputChange(e, 'endDate')}
        onBlur={e => handleInputBlur(e, 'endDate')}
        onKeyDown={handleKeyDown}
      />
    </DateInputWrapper>
  )
}

DateInput.propTypes = {
  dateRange: PropTypes.array,
  inputText: PropTypes.string,
  onClick: PropTypes.func,
  handleDateInputChange: PropTypes.func,
  handleSelect: PropTypes.func,
}

export default DateInput
