import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { SmallText } from 'ggx-componentlibrary/design/typography/typography.styles'

import { Menu, SubMenu, LanguageSwitch } from './settings.component.styles'
import {
  isMasterUser,
  isSubUser,
} from 'ggx-src/users-and-branches/duck/selectors'
import { apiLanguageCode } from 'ggx-src/legacy/locales/conversions'
import { isHongKong, isHongKongEnglish } from 'ggx-src/legacy/utils/locale'
import { getFeatureFlagDeveloper } from 'ggx-src/developer/duck/selectors'
import { apiService } from 'ggx-src/profile/api/api.service'
import { updateUserLanguage } from 'ggx-src/profile/duck/actions'
import { getFeatureFlag } from 'ggx-service/feature-flag/selectors'
import useTracking, { EVENT } from 'ggx-src/address-book/use-tracking'

const Settings = ({
  onMouseEnter,
  onOutsideClick,
  isSubMenuOpen,
  setIsSubMenuOpen,
  subMenuOffset,
}) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const tracking = useTracking()

  const masterUser = useSelector(state => isMasterUser(state))
  const subUser = useSelector(state => isSubUser(state))
  const featureFlagDeveloper = useSelector(state =>
    getFeatureFlagDeveloper(state)
  )

  const languageToSwitchTo = isHongKongEnglish()
    ? t('languageToggle.label.zhHK')
    : t('languageToggle.label.enHK')

  useEffect(() => {
    const handleClickOutsideSubMenu = e => {
      const clickedInSubMenu = e.target.closest(
        '[data-testid="navigation-sub-menu"]'
      )
      const clickedInMenuOrSubMenu = e.target.closest(
        '[data-testid="navigation-menu"]'
      )
      const clickedInFooterMenuUserItem = e.target.closest(
        '[data-testid="navigation-bar-footer-menu-user"]'
      )

      if (isSubMenuOpen && !clickedInSubMenu && !clickedInFooterMenuUserItem) {
        setIsSubMenuOpen(false)
      }

      if (isSubMenuOpen && !clickedInMenuOrSubMenu) {
        onOutsideClick()
      }
    }

    document
      .querySelector('#main_container')
      .addEventListener('click', handleClickOutsideSubMenu)
    return () =>
      document
        .querySelector('#main_container')
        .removeEventListener('click', handleClickOutsideSubMenu)
  }, [onOutsideClick, isSubMenuOpen, setIsSubMenuOpen])

  const handleSwitchLanguage = () => {
    const newLanguage = apiLanguageCode(isHongKongEnglish() ? 'zh-hk' : 'en-hk')

    apiService.updateUserLanguage(newLanguage).then(() => {
      dispatch(updateUserLanguage(newLanguage))
    })
  }

  const featureFlagEnableAddressBook = useSelector(state =>
    getFeatureFlag({ state, featureFlag: 'enable_address_book' })
  )

  return (
    <Menu onMouseEnter={onMouseEnter}>
      <SubMenu
        data-testid="navigation-sub-menu"
        $showSubMenu={isSubMenuOpen}
        $subMenuOffset={subMenuOffset}
      >
        <SubMenu.Row
          to="/profile"
          onClick={() => setIsSubMenuOpen(false)}
          data-testid="user-profile-page-link"
        >
          <SmallText typographyStyles="mb-none c-black">
            {t('myaccount_tab_profile')}
          </SmallText>
        </SubMenu.Row>

        {!subUser && (
          <SubMenu.Row
            to="/users-and-branches"
            onClick={() => setIsSubMenuOpen(false)}
            data-testid="users-and-branches-link"
          >
            <SmallText typographyStyles="mb-none c-black">
              {t('common__users_and_branches')}
            </SmallText>
          </SubMenu.Row>
        )}
        {featureFlagEnableAddressBook && (
          <SubMenu.Row
            to="/address-book"
            onClick={() => {
              setIsSubMenuOpen(false)
              tracking[EVENT.OPEN_NEW_ADDRESS_BOOK]()
            }}
          >
            <SmallText typographyStyles="mb-none c-black">
              {t('address_book')}
            </SmallText>
          </SubMenu.Row>
        )}
        {featureFlagDeveloper && masterUser && (
          <SubMenu.Row
            to="/developer/dashboard"
            onClick={() => setIsSubMenuOpen(false)}
          >
            <SmallText typographyStyles="mb-none c-black">
              {t('common__api_dashboard')}
            </SmallText>
          </SubMenu.Row>
        )}

        {isHongKong() && (
          <LanguageSwitch
            typographyStyles="c-black"
            onClick={handleSwitchLanguage}
            data-testid="language-switch"
          >
            {languageToSwitchTo}
          </LanguageSwitch>
        )}

        <SubMenu.Row to="/logout" data-testid="logout-button">
          <SmallText typographyStyles="mb-none c-grey_dark">
            {t('common__logout')}
          </SmallText>
        </SubMenu.Row>
      </SubMenu>
    </Menu>
  )
}

Settings.propTypes = {
  onMouseEnter: PropTypes.func,
  onOutsideClick: PropTypes.func,
  isSubMenuOpen: PropTypes.bool,
  setIsSubMenuOpen: PropTypes.func,
  subMenuOffset: PropTypes.number,
}

export default Settings
