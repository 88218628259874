import btoa from 'btoa'

const key = 'authToken'
const localStorage = window.localStorage
const sessionStorage = window.sessionStorage

function clearAuthToken() {
  localStorage.removeItem(key)
  sessionStorage.removeItem(key)
}

function getAuthToken() {
  return localStorage.getItem(key) || sessionStorage.getItem(key)
}

function setAuthToken(response) {
  const token = btoa(
    `${response.data.user.id}:${response.data.user.session_token}`
  )

  localStorage.setItem(key, token)
}

function decodedToken() {
  const token = localStorage.getItem(key);
  
  if (!token) return {}
  
  const parts = atob(token).split(':')
  return {
    userId: parts[0],
    sessionToken: parts[1]
  }
}

function getUserId() {
  return atob(getAuthToken()).replace(/:.+$/g, '')
}

export { clearAuthToken, getAuthToken, setAuthToken, getUserId, decodedToken }
