import React from 'react'
import PropTypes from 'prop-types'

import { StyledTextArea } from './textarea.component.styles'
import { StyledError } from './../text-field/text-field.component.styles'

const TextArea = ({
  id,
  name,
  value,
  placeholder,
  onChange,
  className,
  meta,
  input,
  dataTestid,
  ...rest
}) => {
  const {
    name: reduxFormName,
    onChange: reduxFormOnChange,
    value: reduxFormValue,
  } = input

  const wrappedOnChange =
    typeof (reduxFormOnChange || onChange) === 'function'
      ? event => {
          ;(reduxFormOnChange || onChange)(event.target.value)
        }
      : null

  const error = meta.touched && meta.error

  return (
    <div className={className} data-testid={dataTestid}>
      <StyledTextArea
        id={id}
        name={reduxFormName || name}
        value={typeof reduxFormValue === 'string' ? reduxFormValue : value}
        placeholder={placeholder}
        error={error}
        onChange={wrappedOnChange}
        {...rest}
      />
      {error && <StyledError>{meta.error}</StyledError>}
    </div>
  )
}

TextArea.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  rows: PropTypes.number,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func,
  }),
  dataTestid: PropTypes.string,
  className: PropTypes.string,
}

TextArea.defaultProps = {
  input: {},
  meta: {},
  onChange: () => {},
  rows: 4,
}

TextArea.displayName = 'TextArea'

export { TextArea }
