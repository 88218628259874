import React from 'react'
import PropTypes from 'prop-types'

import { formatCardBrand } from 'ggx-service/card-brand/card-brand'

import {
  CardDisplayWrapper,
  CardIcon,
  CardNumberWrapper,
  CardExpiryDateWrapper,
} from './credit-card-display.component.styles'

const CreditCardDisplay = ({
  brand = '',
  number = '',
  expiryDate,
  noBorderDisplayMode,
}) => {
  if (!number) return null
  const cardBrand = () => {
    const brandsRegex = /amex|diners|discover|jcb|mastercard|unionpay|unknown|visa/
    const returnCardBrand =
      formatCardBrand(brand).match(brandsRegex) || 'unknown'

    // eslint-disable-next-line global-require, import/no-dynamic-require
    return <img src={require(`./assets/${returnCardBrand}.png`)} alt={brand} />
  }

  const cardLast4 = () => {
    let displayCardNumber = number.replace(/\s/g, '')
    displayCardNumber = `**** ${displayCardNumber.substring(
      displayCardNumber.length - 4
    )}`

    return displayCardNumber
  }

  const cardExpiryDate = () => {
    const displayDateIndices = [2, 4]
    let displayExpiryDate = expiryDate ?? ' '

    displayDateIndices.forEach(index => {
      if (displayExpiryDate.substring(index, index + 1) !== ' ') {
        displayExpiryDate = `${displayExpiryDate.substring(
          0,
          index
        )} ${displayExpiryDate.substring(index)}`
      }
    })

    return displayExpiryDate
  }

  return (
    <CardDisplayWrapper noBorderDisplayMode={noBorderDisplayMode}>
      {brand && <CardIcon>{cardBrand()}</CardIcon>}
      <CardNumberWrapper>{cardLast4()}</CardNumberWrapper>
      <CardExpiryDateWrapper>{cardExpiryDate()}</CardExpiryDateWrapper>
    </CardDisplayWrapper>
  )
}

CreditCardDisplay.displayName = 'CreditCardDisplay'

CreditCardDisplay.propTypes = {
  brand: PropTypes.string,
  number: PropTypes.string,
  expiryDate: PropTypes.string,
  noBorderDisplayMode: PropTypes.bool,
}

export { CreditCardDisplay }
