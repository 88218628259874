import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { FreeTrialWelcomeModal } from '../../paid-subscription-plan/free-trial-welcome-modal/free-trial-welcome-modal.component'
import {
  getIsFreeTrial,
  getState,
} from '../../paid-subscription-plan/duck/selectors'
import { SubscriptionModal } from '../../paid-subscription-plan/subscription-modal/subscription-modal.component'
import { SUBSCRIPTION_STATE } from '../../paid-subscription-plan/paid-subscription-plan.constants'
import { getFeatureFlag } from 'ggx-service/feature-flag/selectors'
import { Onboarding } from '../../onboarding/onboarding.component'
import { getServiceIntroduction } from '../../onboarding/duck/selectors'

const ModalContainer = () => {
  const [showFreeTrialWelcomeModal, setShowFreeTrialWelcomeModal] = useState(
    true
  )
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(true)
  const HAS_SEEN_FREE_TRIAL_WELCOME_MODAL_KEY = 'hasSeenFreeTrialWelcomeModal'
  const isFreeTrial = useSelector(state => getIsFreeTrial(state))
  const subscriptionState = useSelector(state => getState(state))
  const featureFlagOnboarding = useSelector(state =>
    getFeatureFlag({ state, featureFlag: 'onboarding' })
  )
  const showServiceIntroduction = useSelector(state =>
    getServiceIntroduction(state)
  )
  const hasNotSeenFreeTrialWelcomeModal =
    showFreeTrialWelcomeModal &&
    isFreeTrial &&
    !localStorage.getItem(HAS_SEEN_FREE_TRIAL_WELCOME_MODAL_KEY)
  const freeTrialExpiredSubscriptionModal =
    showSubscriptionModal &&
    subscriptionState === SUBSCRIPTION_STATE.FREE_TRIAL_EXPIRED

  const handleFreeTrialWelcomeModalClose = () => {
    setShowFreeTrialWelcomeModal(false)
    localStorage.setItem(HAS_SEEN_FREE_TRIAL_WELCOME_MODAL_KEY, true)
  }

  return (
    <>
      {hasNotSeenFreeTrialWelcomeModal && (
        <FreeTrialWelcomeModal
          handleModalClose={handleFreeTrialWelcomeModalClose}
        />
      )}
      {freeTrialExpiredSubscriptionModal && (
        <SubscriptionModal
          onRequestClose={() => setShowSubscriptionModal(false)}
        />
      )}
      {featureFlagOnboarding && showServiceIntroduction && <Onboarding />}
    </>
  )
}

export { ModalContainer }
