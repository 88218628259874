const getFeatureFlagGogodelivery = state => state.featureFlagGogodelivery
const getFeatureFlagDeliveryInternalUser = state =>
  state?.featureFlagDeliveryInternalUser
const getFeatureFlagDeliveryOptionalFieldFloor = state =>
  state?.featureFlagDeliveryOptionalFieldFloor
const getDeliveryDropoffTime = state => state?.deliveryDropoffTime
const getDeliveryOrderDetailImageUrl = state =>
  state?.deliveryOrderDetailImageUrl
const getDeliveryBulkImportTableData = state =>
  state?.deliveryBulkImportTableData
const getDeliveryBulkImportTableSelectedVoucherIds = state =>
  state?.deliveryBulkImportTableData
    .map(row => row.user_voucher_ids)
    .flat()
    .filter(Boolean)
const getDeliveryBulkImportTotalBeforeDiscount = state =>
  state?.deliveryBulkImportTableData.reduce(
    (res, row) => res + Number(row.payment?.base_charge ?? 0),
    0
  )
const getDeliveryBulkImportDiscount = state =>
  state?.deliveryBulkImportTableData.reduce(
    (res, row) =>
      res + Number(row.payment?.discounts?.user_vouchers?.[0]?.discount ?? 0),
    0
  )
const getDeliveryBulkImportQuotationTotal = state =>
  state?.deliveryBulkImportQuotationTotal
const getDeliveryBulkImportQuoteInProgress = state =>
  state?.deliveryBulkImportQuoteInProgress
const getDeliveryBulkImportAddressMatchInProgress = state =>
  state?.deliveryBulkImportAddressMatchInProgress
const getDeliveryBulkImportUploadError = state =>
  state?.deliveryBulkImportUploadError
const getDeliveryBulkImportShowLoadingScreen = state =>
  state?.deliveryBulkImportShowLoadingScreen
const getDeliveryBulkImportExcelParsingInProgress = state =>
  state?.deliveryBulkImportExcelParsingInProgress
const getDeliveryFormData = (state, formName) => state?.form?.[formName]?.values
const getShopifyQuoteInProgress = state => state?.shopifyQuoteInProgress
const getShopifyOrdersTableData = state => state?.shopifyOrdersTable
const getShopifyOrdersQuotationTotal = state =>
  state?.shopifyOrdersQuotationTotal

export {
  getFeatureFlagGogodelivery,
  getFeatureFlagDeliveryInternalUser,
  getFeatureFlagDeliveryOptionalFieldFloor,
  getDeliveryDropoffTime,
  getDeliveryOrderDetailImageUrl,
  getDeliveryBulkImportTableData,
  getDeliveryBulkImportQuotationTotal,
  getDeliveryBulkImportQuoteInProgress,
  getDeliveryBulkImportAddressMatchInProgress,
  getDeliveryBulkImportUploadError,
  getDeliveryBulkImportShowLoadingScreen,
  getDeliveryBulkImportExcelParsingInProgress,
  getDeliveryFormData,
  getShopifyQuoteInProgress,
  getShopifyOrdersTableData,
  getShopifyOrdersQuotationTotal,
  getDeliveryBulkImportTableSelectedVoucherIds,
  getDeliveryBulkImportTotalBeforeDiscount,
  getDeliveryBulkImportDiscount,
}
