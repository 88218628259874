import React from 'react'
import PropTypes from 'prop-types'

import { TabWrapper, TabItem } from './tab.component.styles'

const Tab = ({
  className,
  items,
  value,
  dataTestid,
  onClick,
  onKeyPress,
  itemWidth,
}) => (
  <TabWrapper className={className} dataTestid={dataTestid}>
    {items.map(({ id, disabled, label }) => {
      const onClickHandler = disabled ? () => {} : onClick
      const onKeyPressHandler = disabled ? () => {} : onKeyPress
      const hasFocusState = (onClick || onKeyPress) && !disabled

      return (
        <TabItem
          id={id}
          value={value}
          key={id}
          onClick={onClickHandler}
          onKeyPress={onKeyPressHandler}
          disabled={disabled}
          tabIndex={hasFocusState ? '0' : null}
          itemWidth={itemWidth}
        >
          {label}
        </TabItem>
      )
    })}
  </TabWrapper>
)

Tab.displayName = 'Tab'

Tab.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  value: PropTypes.string,
  dataTestid: PropTypes.string,
  onClick: PropTypes.func,
  onKeyPress: PropTypes.func,
  itemWidth: PropTypes.string,
  className: PropTypes.string,
}

export { Tab }
