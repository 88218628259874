import styled from "styled-components"
import PropTypes from "prop-types"

import { COLOR } from "../../design/color/color.constants"
import {
  FONT_BOLD,
  FONT_SIZE,
} from "../../design/typography/typography.constants"
import { SPACING } from "../../design/spacing/spacing.constants"

const SegmentedButtonsWrapper = styled.div`
  display: flex;
`

const SegmentedButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  ${FONT_BOLD};
  font-size: ${FONT_SIZE.MEDIUM};
  color: ${COLOR.BLUE_MEDIUM};
  border: 1px solid ${COLOR.GREY_LIGHT};
  border-right: ${({ last }) =>
    last ? `1px solid ${COLOR.GREY_LIGHT}` : "none"};
  border-top-left-radius: ${({ first }) => (first ? "4px" : "0")};
  border-top-right-radius: ${({ last }) => (last ? "4px" : "0")};
  border-bottom-right-radius: ${({ last }) => (last ? "4px" : "0")};
  border-bottom-left-radius: ${({ first }) => (first ? "4px" : "0")};
  background-color: ${COLOR.WHITE};
  height: 40px;
  min-width: 120px;
  padding: 0 ${SPACING.M};
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background-color: #e5e7e8;
  }

  &:focus {
    outline: none;
    border: 1px solid ${COLOR.BLUE_MEDIUM};
  }

  &:active {
    outline: none;
    box-shadow: inset 0 1px 4px 0 rgba(0, 18, 26, 0.1);
  }
`

SegmentedButton.propTypes = {
  first: PropTypes.bool,
  last: PropTypes.bool,
}

SegmentedButton.defaultProps = {
  first: false,
  last: false,
}

export { SegmentedButtonsWrapper, SegmentedButton }
