import Constants from '../constants/action'
import { api } from '../utils/api'
import { downloadXLSX } from '../views/helpers'

export const setWalletFilterParams = filterParams => ({
  type: Constants.SET_WALLET_FILTER_PARAMS,
  filterParams,
})

export const getTransactions = (params = {}) => dispatch => {
  dispatch({ type: Constants.SET_TRANSACTIONS_FETCHING })
  return api
    .get('b2b/credit_transactions', dispatch, params)
    .then(response => {
      dispatch({
        type: Constants.SET_TRANSACTIONS,
        transactions: response.data,
      })
      return response.data
    })
    .catch(error => {
      dispatch({
        type: Constants.SET_TRANSACTIONS_ERROR,
        error,
      })
    })
}

export const getTransactionsReport = ids => dispatch => {
  api
    .request(
      'post',
      'b2b/credit_transactions/download_excel',
      dispatch,
      { b2b_credit_transaction_ids: ids },
      {},
      { responseType: 'arraybuffer' }
    )
    .then(response => {
      downloadXLSX(response.data)
    })
}

export const getDepositTransactions = (params = {}) => dispatch => {
  dispatch({ type: Constants.SET_DEPOSIT_TRANSACTIONS_FETCHING })
  return api
    .get('b2b/deposit_transactions', dispatch, params)
    .then(response => {
      dispatch({
        type: Constants.SET_DEPOSIT_TRANSACTIONS,
        depositTransactions: response.data,
      })
      return response.data
    })
    .catch(error => {
      dispatch({
        type: Constants.SET_DEPOSIT_TRANSACTIONS_ERROR,
        error: error.response,
      })
    })
}

export const getDepositTransactionsReport = ids => dispatch => {
  api
    .request(
      'post',
      'b2b/deposit_transactions/download_excel',
      dispatch,
      { b2b_deposit_transaction_ids: ids },
      {},
      { responseType: 'arraybuffer' }
    )
    .then(response => {
      downloadXLSX(response.data)
    })
}

export default {
  setWalletFilterParams,
}
