import styled from "styled-components"

import { COLOR } from "../../../../component-library/design/color/color.constants"
import { SPACING } from "../../../../component-library/design/spacing/spacing.constants"
import {
  Body,
  TextLink,
} from "../../../../component-library/design/typography/typography.styles"
import { FONT_SIZE } from "../../../../component-library/design/typography/typography.constants"
import { ORDER_DETAIL_WIDTH } from "../../order-detail.component.styles"
import { Tooltip } from "../../../../component-library/components/tooltip/tooltip.component"
import { ContentBox } from "../../../../component-library/components/tooltip/tooltip.component.styles"

const SubText = styled(Body)`
  color: ${COLOR.GREY_DARK};
  margin-bottom: 0;
`

const StyledBody = styled(Body)`
  margin-bottom: 0;
`

const EpodContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const EpodImage = styled.img`
  width: 60px;
  height: 60px;
  margin-right: 4px;
  margin-top: -24px;
  object-fit: contain;
  border-radius: 4px;
  border: 1px solid rgba(0, 18, 26, 0.10);
  visibility: ${props => props.$display ? 'visible' : 'hidden' };

  &:last-child {
    margin-right: 0;
  }
`

const EpodSignatureImage = styled.img`
  width: calc(${ORDER_DETAIL_WIDTH}px - 80px);
  height: 160px;
`

const StyledTooltip = styled(Tooltip)`
  ${ContentBox} {
    left: -40px;
  }
`

const Container = styled.div`
  margin-bottom: ${SPACING.M};
`

const StyledTextLink = styled(TextLink)`
  font-size: ${FONT_SIZE.MEDIUM};
`

const WaypointContent = styled.div`
  display: flex;
`

const WaypointContentLeft = styled.div`
  flex-grow: 1;
  max-width: calc(100% - 125px)
`

const WaypointContentRight = styled.div`
  flex: 0 0 125px;
`

export {
  SubText,
  StyledBody,
  EpodImage,
  EpodSignatureImage,
  StyledTooltip,
  EpodContainer,
  Container,
  StyledTextLink,
  WaypointContent,
  WaypointContentLeft,
  WaypointContentRight,
}
