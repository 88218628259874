import Constants from '../constants/action'
import { api } from '../utils/api'
import MapUtils from '../utils/map'
import { findIncludedAttributes, findIncluded } from '../utils/orders/action'

const activeEtaOrders = (orderRequests, included) => dispatch => {
  const activeOrderRequests = orderRequests.filter(
    order => order.attributes.status === 'active'
  )
  if (!activeOrderRequests) return

  const waypoints = findIncluded(included, 'waypoints')
  const orders = findIncluded(included, 'orders')

  activeOrderRequests.map(req => {
    if (!req.relationships.order.data) return null
    const orderIncluded = findIncludedAttributes(
      req.relationships.order.data.id,
      orders
    )
    const nextWaypointId = waypoints.find(
      waypoint => !waypoint.driver_arrived_at
    ).id
    const nextWaypoint = findIncludedAttributes(nextWaypointId, included)

    if (orderIncluded.driver_location) {
      const etaPromise = new Promise((resolve, reject) => {
        MapUtils.getETA(
          orderIncluded.driver_location,
          nextWaypoint,
          (etaValue, success) => {
            if (success) {
              resolve(etaValue)
            } else {
              reject('GET ETA')
            }
          }
        )
      })
      return etaPromise.then(data => {
        const eta = {
          orderRequestId: orderIncluded.order_request_id,
          eta: data,
        }
        dispatch({
          type: Constants.SET_ORDER_ETAS,
          eta,
        })
      })
    }
  })
}

export const setOrderStatusGroups = (orderRequests, included) => ({
  type: Constants.SET_ORDER_STATUS_GROUPS,
  orderRequests,
  included,
})

export const getOrderRequests = (params = {}, organization) => dispatch => {
  dispatch({ type: Constants.SET_ORDER_REQUESTS_FETCHING })
  return api
    .get('b2b/order_requests', dispatch, params)
    .then(response => {
      dispatch({
        type: Constants.SET_ORDER_REQUESTS,
        orderRequests: response.data.data,
        included: response.data.included,
        meta: response.data.meta,
        organization,
      })
      dispatch(setOrderStatusGroups(response.data.data, response.data.included))
      dispatch(activeEtaOrders(response.data.data, response.data.included))
    })
    .catch(error => {
      dispatch({
        type: Constants.SET_ORDERS_ERROR,
        error,
      })
    })
}
