// @flow

import CountriesLocales from '../../locales/countries'

let country: ?string = localStorage.getItem('country')
// if country variable in localStorage is not a valid one
if (!Object.keys(CountriesLocales).includes(country)) {
  country = ENV.GOGOVAN_COUNTRY
  localStorage.setItem('country', ENV.GOGOVAN_COUNTRY)
}

export default function getCountry() {
  return country
}
