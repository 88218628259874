import { useMemo } from 'react'
import { track as trackByMixpanel } from 'ggx-service/mixpanel/mixpanel'
import { brazeClient } from 'ggx-service/braze'
import { fetchGeneralTrackingInfo } from 'ggx-src/users-and-branches/use-tracking.js'

export const EVENT = {
  OPEN_NEW_ADDRESS_BOOK: 'Open_new_address_book',
  EDIT_USERS_BUTTON_TAPPED: 'Edit_users_button_tapped',
  EDIT_ADDRESS_SAVE_BUTTON_TAPPED: 'Edit_address_save_button_tapped',
  EDIT_ADDRESS_DELETE_BUTTON_TAPPED: 'Edit_address_delete_button_tapped',
  ADD_ADDRESS_BUTTON_TAPPED: 'Add_address_button_tapped',
  NEW_ADDRESS_SAVED_ADDRESS_BOOK_FLOW: 'New_address_saved_address_book_flow',
  MULTIPLE_ADDRESS_RECORDS_ACTION: 'Multiple_address_records_action',
  OPEN_ADDRESS_BOOK_FROM_PICKUP_WAYPOINT_BAR:
    'open_address_book_from_pick-up_waypoint_bar',
  OPEN_ADDRESS_BOOK_FROM_DROPOFF_WAYPOINT_BAR:
    'open_address_book_from_drop-off_waypoint_bar',
  ADDRESS_BOOK_RECORD_USED_FOR_WAYPOINT_INFO:
    'address_book_record_used_for_waypoint_info',
  ADD_TO_ADDRESS_BOOK_CLICKED: 'Add_to_address_book_clicked',
  NEW_ADDRESS_SAVED_PLACE_ORDER_FLOW: 'New_address_saved_place_order_flow',
}

export const trackFactory = fetchSuperProperties => async (
  event,
  properties = {}
) => {
  const generalProperties = await fetchSuperProperties()
  trackByMixpanel(event, { ...generalProperties, ...properties })
  brazeClient.setCustomEvent(event, { ...generalProperties, ...properties })
}

export const track = trackFactory(fetchGeneralTrackingInfo)

export default () => {
  return useMemo(
    () => ({
      [EVENT.OPEN_NEW_ADDRESS_BOOK]: () =>
        requestIdleCallback(() => track(EVENT.OPEN_NEW_ADDRESS_BOOK)),
      [EVENT.EDIT_USERS_BUTTON_TAPPED]: () =>
        requestIdleCallback(() => track(EVENT.EDIT_USERS_BUTTON_TAPPED)),
      [EVENT.EDIT_ADDRESS_SAVE_BUTTON_TAPPED]: (
        properties = {
          edit_address_street_address_changed: false,
          edit_address_phone_number_changed: false,
          edit_address_nickname_changed: false,
          edit_address_email_changed: false,
        }
      ) =>
        requestIdleCallback(() =>
          track(EVENT.EDIT_ADDRESS_SAVE_BUTTON_TAPPED, properties)
        ),
      [EVENT.EDIT_ADDRESS_DELETE_BUTTON_TAPPED]: () =>
        requestIdleCallback(() =>
          track(EVENT.EDIT_ADDRESS_DELETE_BUTTON_TAPPED)
        ),
      [EVENT.ADD_ADDRESS_BUTTON_TAPPED]: () =>
        requestIdleCallback(() => track(EVENT.ADD_ADDRESS_BUTTON_TAPPED)),
      [EVENT.NEW_ADDRESS_SAVED_ADDRESS_BOOK_FLOW]: (
        properties = {
          new_name_added: false,
          new_nickname_added: false,
          new_email_added: false,
        }
      ) =>
        requestIdleCallback(() =>
          track(EVENT.NEW_ADDRESS_SAVED_ADDRESS_BOOK_FLOW, properties)
        ),
      [EVENT.MULTIPLE_ADDRESS_RECORDS_ACTION]: (
        properties = {
          number_of_records_selected: 0,
        }
      ) =>
        requestIdleCallback(() =>
          track(EVENT.MULTIPLE_ADDRESS_RECORDS_ACTION, properties)
        ),
      [EVENT.OPEN_ADDRESS_BOOK_FROM_PICKUP_WAYPOINT_BAR]: () =>
        requestIdleCallback(() =>
          track(EVENT.OPEN_ADDRESS_BOOK_FROM_PICKUP_WAYPOINT_BAR)
        ),
      [EVENT.OPEN_ADDRESS_BOOK_FROM_DROPOFF_WAYPOINT_BAR]: () =>
        requestIdleCallback(() =>
          track(EVENT.OPEN_ADDRESS_BOOK_FROM_DROPOFF_WAYPOINT_BAR)
        ),
      [EVENT.ADDRESS_BOOK_RECORD_USED_FOR_WAYPOINT_INFO]: (
        properties = {
          type_of_waypoint: '',
        }
      ) =>
        requestIdleCallback(() =>
          track(EVENT.ADDRESS_BOOK_RECORD_USED_FOR_WAYPOINT_INFO, properties)
        ),
      [EVENT.ADD_TO_ADDRESS_BOOK_CLICKED]: (
        properties = {
          type_of_waypoint: '',
        }
      ) =>
        requestIdleCallback(() =>
          track(EVENT.ADD_TO_ADDRESS_BOOK_CLICKED, properties)
        ),
      [EVENT.NEW_ADDRESS_SAVED_PLACE_ORDER_FLOW]: (
        properties = {
          type_of_waypoint: '',
        }
      ) =>
        requestIdleCallback(() =>
          track(EVENT.NEW_ADDRESS_SAVED_PLACE_ORDER_FLOW, properties)
        ),
    }),
    []
  )
}
