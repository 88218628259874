const SET_SAVED_CARD_INFO = 'SET_SAVED_CARD_INFO'
const SET_PAYMENT_INFO = 'SET_PAYMENT_INFO'
const SET_CARD_INFO = 'SET_CARD_INFO'
const SET_CARDS_INFO = 'SET_CARDS_INFO'
const REMOVE_CARD = 'REMOVE_CARD'

export {
  SET_SAVED_CARD_INFO,
  SET_PAYMENT_INFO,
  SET_CARD_INFO,
  SET_CARDS_INFO,
  REMOVE_CARD,
}
