const EMAIL_FORMAT = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,15}$/i

const HK_PHONE_FORMAT = /^((\+)?852)?[2-9][0-9]{7}$/
const HK_RESERVED_PHONE = /^((999)|(00))/
const SG_PHONE_FORMAT = /^((\+)?65)?[3|6|8|9][0-9]{7}$/
const TW_PHONE_FORMAT = /^((\+)?886)?0[0-9]{8,10}$/
const VN_PHONE_FORMAT = /^(0|84|\+84)(32|33|34|35|36|37|38|39|52|56|58|59|70|76|77|78|79|81|82|83|84|85|86|88|89|90|91|92|93|94|96|97|98|99)[0-9]{7}$/

export {
  EMAIL_FORMAT,
  HK_PHONE_FORMAT,
  HK_RESERVED_PHONE,
  SG_PHONE_FORMAT,
  TW_PHONE_FORMAT,
  VN_PHONE_FORMAT,
}
