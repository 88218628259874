import { useEffect } from 'react'
import { getLocale } from '../../legacy/utils/locale'

const useDelightedSurvey = (scriptContent, surveyLocales = null) => {
  useEffect(() => {
    const script = document.createElement('script')

    script.type = 'text/javascript'

    if (surveyLocales === null || surveyLocales.includes(getLocale())) {
      script.innerHTML = scriptContent
    }

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])
}

const formatLocale = () => {
  let locale

  switch (getLocale()) {
    case 'zh-hk':
      locale = 'zh-HK'
      break
    case 'zh-tw':
      locale = 'zh-TW'
      break
    case 'vi-vn':
      locale = 'vi'
      break
    case 'en-hk':
    case 'en-sg':
    default:
      locale = 'en'
  }

  return locale
}

export { useDelightedSurvey, formatLocale }
