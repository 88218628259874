const formatCardBrand = (brand, capitalize = false) => {
  const cardBrandMapping = {
    visa: ['Visa', 'visa'],
    Visa: ['Visa', 'visa'],
    mastercard: ['MasterCard', 'mastercard'],
    Mastercard: ['MasterCard', 'mastercard'],
    MasterCard: ['MasterCard', 'mastercard'],
    amex: ['American Express', 'amex'],
    Amex: ['American Express', 'amex'],
    'American Express': ['American Express', 'amex'],
    unionpay: ['UnionPay', 'unionpay'],
    Unionpay: ['UnionPay', 'unionpay'],
    UnionPay: ['UnionPay', 'unionpay'],
  }

  if (!cardBrandMapping[brand] && typeof brand === 'string') {
    return brand
  }

  if (!cardBrandMapping[brand]) {
    return 'Credit card'
  }

  if (capitalize) return cardBrandMapping[brand][0]

  return cardBrandMapping[brand][1]
}

export { formatCardBrand }
