import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Label } from '../navigation.component.styles'
import HomeSVG from '../assets/home.svg'

import { HomeRow } from './home.component.styles'

const HomeSection = ({
  activePath,
  isNavigationCollapsed,
  isNavigationHovered,
}) => {
  const [t] = useTranslation()

  return (
    <HomeRow
      to="/home"
      $isActive={activePath === '/home'}
      $isNavigationCollapsed={isNavigationCollapsed}
      $isNavigationHovered={isNavigationHovered}
    >
      <HomeSVG />
      <Label>{t('common__home')}</Label>
    </HomeRow>
  )
}

HomeSection.propTypes = {
  activePath: PropTypes.string,
  isNavigationCollapsed: PropTypes.bool,
  isNavigationHovered: PropTypes.bool,
}

export default HomeSection
