import styled from "styled-components"

import { COLOR } from "ggx-componentlibrary/design/color/color.constants"
import { SPACING } from "ggx-componentlibrary/design/spacing/spacing.constants"
import {
  TextLink,
  TextLinkStyles,
  SmallText,
  SmallTextBold,
  Body,
  HeadingL,
} from "ggx-componentlibrary/design/typography/typography.styles"
import DeliveryCardSVG from "../assets/delivery-service-card.svg"
import TransportCardSVG from "../assets/transport-service-card.svg"
import ExternalLinkSVG from "../../assets/external_link.svg"

const CARD_HEIGHT = "428px"
const CARD_WIDTH = "278px"

const DeliveryIcon = styled(DeliveryCardSVG)``
const TransportIcon = styled(TransportCardSVG)``
const ExternalLinkIcon = styled(ExternalLinkSVG)`
  margin-left: ${SPACING.XXS};
  width: 16px;
  height: 16px;
  position: relative;
  top: -1px;
`

const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${SPACING.S} ${SPACING.M};
  align-items: center;
  width: ${CARD_WIDTH};
  height: ${CARD_HEIGHT};
  border-radius: 8px;
  border: 1px solid
    ${(props) =>
      props.$available ? `${COLOR.BLUE_MEDIUM}` : `${COLOR.GREY_LIGHT}`};
  > *:not(ul) {
    ${(props) => !props.$available && "opacity: 0.38;"}
  }
  background-color: ${COLOR.WHITE};
`

Card.Heading = styled(HeadingL)`
  text-align: center;
  margin-top: ${SPACING.S};
`

Card.Message = styled(Body)`
  text-align: center;
`

Card.Available = styled(SmallTextBold)`
  ${(prop) => prop.$available && `background-color: ${COLOR.BLUE_MEDIUM};`}
  ${(prop) =>
    !prop.$available &&
    `
    &:before {
      content: " ";
      white-space: pre;
    }
  `}
  color: ${COLOR.WHITE};
  padding: ${SPACING.XS};
  text-align: center;
  width: calc(100% + ${SPACING.S} * 2 + 2px);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`

const Details = styled.ul`
  background-color: ${COLOR.GREY_LIGHTEST};
  color: ${COLOR.GREY_DARK};
  padding: ${SPACING.S} ${SPACING.S};
  list-style-position: outside;
  border-radius: 8px;
  margin-top: 0;
  width: 100%;
`

const PricingTableLink = styled(TextLink)`
  display: flex;
  margin-top: auto;
`

PricingTableLink.Text = styled(SmallText)`
  ${TextLinkStyles};
  margin-bottom: 0;
`

export {
  Card,
  Details,
  ExternalLinkIcon,
  DeliveryIcon,
  TransportIcon,
  PricingTableLink,
}
