import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { BodyBold } from 'ggx-componentlibrary/design/typography/typography.styles'

import { getFeatureFlag } from 'ggx-service/feature-flag/selectors'

import { brazeClient, BRAZE_EVENT } from 'ggx-service/braze'

import {
  Label,
  SectionTitle,
  PlaceOrderRow,
  BulkOrderRow,
  OrderManagementRow,
  PlaceOrderIcon,
  BulkOrderIcon,
  OrderManagementIcon,
} from '../navigation.component.styles'

import EcommerceIntegrationSVG from '../assets/ecommerce-integration.svg'

import { EcommerceIntegrationRow } from './delivery.component.styles'

const DeliverySection = ({
  activePath,
  isNavigationCollapsed,
  isNavigationHovered,
  hasNDDService,
  showDeliveryBulkImport,
}) => {
  const [t] = useTranslation()
  const featureFlagDeliveryIntegration = useSelector(state =>
    getFeatureFlag({ state, featureFlag: 'delivery_integration' })
  )
  const featureFlagShopifyIntegration = useSelector(state =>
    getFeatureFlag({ state, featureFlag: 'shopify_integration' })
  )

  const handleDeliveryClick = () => {
    brazeClient.setCustomEvent(BRAZE_EVENT.DELIVERY.NEW_ORDER)
  }

  return (
    <>
      <SectionTitle
        $isNavigationCollapsed={isNavigationCollapsed}
        $isNavigationHovered={isNavigationHovered}
      >
        <BodyBold>
          {hasNDDService
            ? t('common__same_day_delivery')
            : t('common__courier_delivery')}
        </BodyBold>
      </SectionTitle>

      <PlaceOrderRow
        to="/delivery/order"
        $isActive={activePath === '/delivery/order'}
        $isNavigationCollapsed={isNavigationCollapsed}
        $isNavigationHovered={isNavigationHovered}
        onClick={handleDeliveryClick}
      >
        <PlaceOrderIcon $isActive={activePath === '/delivery/order'} />

        <Label>{t('common__place_order_title')}</Label>
      </PlaceOrderRow>
      {showDeliveryBulkImport && (
        <BulkOrderRow
          to="/delivery/bulk-import"
          $isActive={activePath === '/delivery/bulk-import'}
          $isNavigationCollapsed={isNavigationCollapsed}
          $isNavigationHovered={isNavigationHovered}
        >
          <BulkOrderIcon $isActive={activePath === '/delivery/bulk-import'} />
          <Label>{t('common__bulk_order')}</Label>
        </BulkOrderRow>
      )}
      <OrderManagementRow
        to="/delivery/order-management"
        $isActive={activePath === '/delivery/order-management'}
        $isNavigationCollapsed={isNavigationCollapsed}
        $isNavigationHovered={isNavigationHovered}
        data-testid="navigation-bar-delivery-order-management"
      >
        <OrderManagementIcon
          $isActive={activePath === '/delivery/order-management'}
        />
        <Label>{t('common__order_management')}</Label>
      </OrderManagementRow>
      {featureFlagDeliveryIntegration && (
        <EcommerceIntegrationRow
          to="/delivery/integration"
          $isActive={activePath === '/delivery/integration'}
          $isNavigationCollapsed={isNavigationCollapsed}
          $isNavigationHovered={isNavigationHovered}
        >
          <EcommerceIntegrationSVG />
          <Label>{t('sideMenu__ecommerce_integration')}</Label>
        </EcommerceIntegrationRow>
      )}
      {featureFlagShopifyIntegration && (
        <EcommerceIntegrationRow
          to="/delivery/shopify-integration"
          $isActive={activePath === '/delivery/shopify-integration'}
          $isNavigationCollapsed={isNavigationCollapsed}
          $isNavigationHovered={isNavigationHovered}
        >
          <EcommerceIntegrationSVG />
          <Label>{t('sideMenu__shopify_integration')}</Label>
        </EcommerceIntegrationRow>
      )}
    </>
  )
}

DeliverySection.propTypes = {
  activePath: PropTypes.string,
  isNavigationCollapsed: PropTypes.bool,
  isNavigationHovered: PropTypes.bool,
  hasNDDService: PropTypes.bool,
  showDeliveryBulkImport: PropTypes.bool,
}

export default DeliverySection
