import PropTypes from 'prop-types'

import translate from '../../../legacy/locales/translate'
import {
  MessageWrapper,
  Message,
  ActionTextButton,
  CloseIcon,
} from './merge-message.component.styles'

const MergeMessage = ({ onSeeAllClick, onDismissAllClick, messageCount }) => {
  const mergedCount = messageCount - 2
  return (
    <MessageWrapper>
      <Message>
        {translate('popup.merge.notifStatement', { count: mergedCount })}
      </Message>
      <ActionTextButton onClick={onSeeAllClick}>
        {translate('popup.merge.seeAll')}
      </ActionTextButton>
      <ActionTextButton onClick={onDismissAllClick}>
        {translate('popup.merge.dismissAll')}
        <CloseIcon> X</CloseIcon>
      </ActionTextButton>
    </MessageWrapper>
  )
}

MergeMessage.propTypes = {
  messageCount: PropTypes.number.isRequired,
  onDismissAllClick: PropTypes.func.isRequired,
  onSeeAllClick: PropTypes.func.isRequired,
}

export { MergeMessage }
