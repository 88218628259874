import _ from 'lodash'
import Constants from '../constants/action'
import Vehicles from '../constants/vehicles'
import { createWaypoint } from '../utils/orders/action'
import { getCountry } from '../views/helpers'

const initialState = {
  err: null,
  breakdown: null,
  activeOrderRequest: false,
  orderRequest: {
    vehicleCount: 1 /** TODO: extra that needs to be removed before sending out the request */,
    shareOrSplit:
      'share' /** TODO: extra that needs to be removed before sending out the request */,
    vehicle: Vehicles[getCountry()][0],
    waypoints_attributes: [createWaypoint(), createWaypoint()],
    order_request_detail_attributes: {
      passenger_count: 0,
      b2b_requirements: {},
    },
    goods_information_attributes: {
      images: [],
    },
    order_request_extra_attributes: {},
    pickup_time: new Date(Date.now() + 360000 * 4), // pickup_date / pickup_time
  },
  loading: false,
  splittedOrderRequests: [],
  splittedBreakdowns: [],
  optimizedOrderRequest: null,
  optimizedBreakdown: null,
  addresses: {
    selectedAddresses: [],
  },
  useCurrentPickupTime: true,
}

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case Constants.CURRENT_ORDER_REQUEST_SET_PICK_UP_TIME_TYPE:
      return { ...state, useCurrentPickupTime: action.useOwnPickupTime }

    case Constants.CURRENT_ORDER_REQUEST_SET_NOW_PICK_UP_TIME:
      return {
        ...state,
        orderRequest: {
          ...state.orderRequest,
          pickup_time: new Date(Date.now() + 360000 * 4), // pickup_date / pickup_time
        },
      }

    case Constants.SET_ACTIVE_WAYPOINT:
      return { ...state, activeWaypointID: action.activeWaypointID }

    case Constants.CURRENT_ORDER_REQUEST_ADD_WAYPOINT: {
      const regionId = _.last(state.orderRequest.waypoints_attributes).region_id

      return {
        ...state,
        activeWaypointID: state.orderRequest.waypoints_attributes.length,
        orderRequest: {
          ...state.orderRequest,
          waypoints_attributes: [
            ...state.orderRequest.waypoints_attributes,
            Object.assign(createWaypoint(action.waypoint), {
              region_id: regionId,
            }),
          ],
        },
      }
    }

    case Constants.CURRENT_ORDER_REQUEST_UPDATE_WAYPOINTS:
      return {
        ...state,
        orderRequest: {
          ...state.orderRequest,
          waypoints_attributes: [
            ...state.orderRequest.waypoints_attributes.slice(0, action.index),
            createWaypoint(action.waypoint),
            ...state.orderRequest.waypoints_attributes.slice(action.index + 1),
          ],
        },
      }

    case Constants.IMPORT_SPLIT_ORDER: {
      return {
        ...state,
        splittedOrderRequests: [
          ...(state.splittedOrderRequests || []),
          action.importedOrder,
        ],
      }
    }

    case Constants.IMPORT_SPLIT_WAYPOINT: {
      const actualRouteNumber = action.routeNumber - 1
      return {
        ...state,
        splittedOrderRequests: state.splittedOrderRequests.map(
          (splittedOrderRequest, i) => {
            if (i === actualRouteNumber) {
              return {
                ...(splittedOrderRequest || []),
                waypoints_attributes: [
                  ...(state.splittedOrderRequests[actualRouteNumber]
                    .waypoints_attributes || []),
                  action.importedWaypoint,
                ],
              }
            }
            return splittedOrderRequest
          }
        ),
      }
    }

    default:
      return state
  }
}

export default reducer
