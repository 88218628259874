const FEATUREFLAG_CREDIT_CARD_VANBOOKING_ACCESS =
  'FEATUREFLAG_CREDIT_CARD_VANBOOKING_ACCESS'
const FEATUREFLAG_HOURLY_RENTAL = 'FEATUREFLAG_HOURLY_RENTAL'
const FEATUREFLAG_CONFIGURABLE_REQUIREMENT =
  'FEATUREFLAG_CONFIGURABLE_REQUIREMENT'
const DISPLAY_CUSTOM_SERVICES = 'DISPLAY_CUSTOM_SERVICES'
const DISPLAY_ORDER_QUOTATION = 'DISPLAY_ORDER_QUOTATION'
const FEATUREFLAG_FAVORITE_DRIVERS = 'FEATUREFLAG_FAVORITE_DRIVERS'
const FEATUREFLAG_BULK_IMPORT = 'FEATUREFLAG_BULK_IMPORT'
const BULK_IMPORT_SET_TABLE_DATA = 'BULK_IMPORT_SET_TABLE_DATA'
const BULK_IMPORT_SET_QUOTATION = 'BULK_IMPORT_SET_QUOTATION'
const BULK_IMPORT_EDIT_TABLE_DATA = 'BULK_IMPORT_EDIT_TABLE_DATA'
const BULK_IMPORT_DELETE_TABLE_DATA = 'BULK_IMPORT_DELETE_TABLE_DATA'
const BULK_IMPORT_DELETE_TABLE_ROW = 'BULK_IMPORT_DELETE_TABLE_ROW'
const BULK_IMPORT_SET_QUOTE_IN_PROGRESS = 'BULK_IMPORT_SET_QUOTE_IN_PROGRESS'
const FEATUREFLAG_FIXED_MOVING_FEE = 'FEATUREFLAG_FIXED_MOVING_FEE'

export {
  FEATUREFLAG_CREDIT_CARD_VANBOOKING_ACCESS,
  FEATUREFLAG_HOURLY_RENTAL,
  FEATUREFLAG_CONFIGURABLE_REQUIREMENT,
  DISPLAY_CUSTOM_SERVICES,
  DISPLAY_ORDER_QUOTATION,
  FEATUREFLAG_FAVORITE_DRIVERS,
  FEATUREFLAG_BULK_IMPORT,
  BULK_IMPORT_SET_TABLE_DATA,
  BULK_IMPORT_SET_QUOTATION,
  BULK_IMPORT_DELETE_TABLE_DATA,
  BULK_IMPORT_EDIT_TABLE_DATA,
  BULK_IMPORT_SET_QUOTE_IN_PROGRESS,
  BULK_IMPORT_DELETE_TABLE_ROW,
  FEATUREFLAG_FIXED_MOVING_FEE,
}
