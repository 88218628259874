import React from 'react'
import PropTypes from 'prop-types'

import {
  SegmentedButtonsWrapper,
  SegmentedButton,
} from './segmented-button.component.styles'

const SegmentedButtonsGroup = ({ className, children, dataTestid }) => {
  const lastChildIndex = React.Children.count(children) - 1
  const firstChildProp = { first: true }
  const lastChildProp = { last: true }

  return (
    <SegmentedButtonsWrapper className={className} dataTestid={dataTestid}>
      {children.map((child, i) => {
        let childProp = { key: i }
        if (i === 0) {
          childProp = Object.assign(childProp, firstChildProp)
        } else if (i === lastChildIndex) {
          childProp = Object.assign(childProp, lastChildProp)
        }

        return React.cloneElement(child, childProp)
      })}
    </SegmentedButtonsWrapper>
  )
}

SegmentedButtonsGroup.displayName = 'SegmentedButtonGroup'
SegmentedButton.displayName = 'SegmentedButton'

SegmentedButtonsGroup.propTypes = {
  dataTestid: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.element.isRequired),
}

export { SegmentedButtonsGroup, SegmentedButton }
