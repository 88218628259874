import styled from "styled-components"
import {
  FONT_SIZE,
  LINE_HEIGHT,
  FONT_WEIGHT,
} from "ggx-componentlibrary/design/typography/typography.constants"
import { SPACING } from "ggx-componentlibrary/design/spacing/spacing.constants"
import { Body } from 'ggx-componentlibrary/design/typography/typography.styles'

const appearance = {
  variables: {
    fontFamily:
      'Wix Madefor Text, Lucida Grande, Helvetica Neue, Helvetica, Arial, Verdana, sans-serif',
    fontSizeBase: FONT_SIZE.MEDIUM,
    fontLineHeight: LINE_HEIGHT.MEDIUM,
    fontWeightNormal: FONT_WEIGHT.REGULAR,
    spacingGridRow: SPACING.M,
  },
  rules: {
    '.Label': {
      fontWeight: FONT_WEIGHT.BOLD,
      marginBottom: SPACING.XS,
    },
    '.Input': {
      padding: '12px 16px',
    },
  },
}

const CardContainer = styled.div`
  width: 160px;
  height: 100px;
  margin: ${SPACING.M} auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 16px auto 40px;
  > svg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    opacity: ${props => props.$expired ? 0.5 : 1}; ;
  }
`

const FieldWrapper = styled.div`
  margin: 0 0 ${SPACING.M};
`

const AlignCenterBody = styled(Body)`
  text-align: center
`

export {
  appearance,
  CardContainer,
  FieldWrapper,
  AlignCenterBody,
}