import Constants from '../constants/action'
import { api } from '../utils/api'
import MapUtils from '../utils/map'
import { sortString } from '../views/helpers'

function getNextWaypointETA(req) {
  return new Promise(resolve => {
    if (req.status !== 'active') {
      resolve(req)
      return
    }

    const driverLocation = req.order.driver_location
    if (!driverLocation) {
      // eslint-disable-next-line no-console
      console.error('The driver does not have recent location.')
      resolve(req)
      return
    }

    const nextWaypoint = req.waypoints.find(w => !w.driver_arrived_at)
    if (!nextWaypoint) {
      resolve(req)
      return
    }

    MapUtils.getETA(driverLocation, nextWaypoint, (etaValue, success) => {
      if (success) {
        nextWaypoint.eta = etaValue
      }
      resolve(req)
    })
  })
}

function assignParcelsToWaypoints(orderRequest) {
  if (!Array.isArray(orderRequest.parcels)) {
    return
  }

  orderRequest.parcels.forEach(p => {
    const assignedWaypoint = orderRequest.waypoints.find(
      w => Number(w.id) === Number(p.assigned_waypoint_id)
    )

    if (assignedWaypoint) {
      if (!Array.isArray(assignedWaypoint.parcels)) {
        assignedWaypoint.parcels = []
      }

      assignedWaypoint.parcels.push(p)
    }
  })

  delete orderRequest.parcels
}

export function updateSelectedOrderRequest(id, resolve, reject, dispatch) {
  return api.get(`b2b/order_requests/${id}`, dispatch).then(
    res => {
      const orderRequest = res.data

      assignParcelsToWaypoints(orderRequest)

      getNextWaypointETA(orderRequest).then(
        reqWithETA => {
          resolve({ orderRequest: reqWithETA })
        },
        () => {
          resolve({ orderRequest })
        }
      )
    },
    err => reject(err)
  )
}

// Report Dispute Button related
export const openDispute = ({ orderRequestId, dispatch }) => {
  api
    .request('post', 'b2b/order_request_disputes', dispatch, {
      order_request_id: orderRequestId,
    })
    .then(() => {
      // done nothing ?
    })
    .catch(() => {
      // catch it but just ignore any errors
    })
}

export const openReportDisputeConfirmDialog = ({ opened }) => ({
  type: Constants.TOGGLE_OPEN_REPORT_DISPUTE_CONFIRM_DIALOG,
  opened,
})

export const openReportDisputeMessageDialog = ({ opened }) => ({
  type: Constants.TOGGLE_OPEN_REPORT_DISPUTE_MESSAGE_DIALOG,
  opened,
})

const Actions = {
  updateSelectedOrderRequest: id => dispatch => {
    new Promise((resolve, reject) => {
      updateSelectedOrderRequest(id, resolve, reject, dispatch)
    }).then(
      ({ orderRequest }) => {
        dispatch({
          type: Constants.UPDATE_SELECTED_ORDER_REQUEST,
          orderRequest,
        })
      },
      () => {}
    )
  },

  setFilterParams: (filterParams, sortBy, sortAsc) => dispatch => {
    dispatch({
      type: Constants.SET_FILTER_PARAMS,
      filterParams: {
        ...filterParams,
        sort:
          sortBy && typeof sortAsc === 'boolean'
            ? sortString(sortBy, sortAsc)
            : filterParams.sort,
      },
      sortBy,
      sortAsc,
    })
  },

  cancelSelectedOrderRequest: orderRequestId => dispatch => {
    api
      .request('post', `order_requests/${orderRequestId}/cancel`, dispatch)
      .then(() => {
        dispatch(Actions.updateSelectedOrderRequest(orderRequestId))
      })
      .catch(() => {
        // catch it but just ignore any errors
      })
  },

  selectOrderRequest: (orderRequestId, included) => dispatch => {
    api.get(`b2b/order_requests/${orderRequestId}`, dispatch).then(response => {
      dispatch({
        type: Constants.UPDATE_SELECTED_ORDER_REQUEST,
        orderRequest: response.data,
      })
    })

    dispatch({ type: Constants.SELECTED_ORDER_REQUEST_BREAKDOWN_FETCHING })
    api
      .get(`b2b/order_requests/${orderRequestId}/breakdown`, dispatch)
      .then(response => {
        dispatch({
          type: Constants.SELECTED_ORDER_REQUEST_BREAKDOWN,
          breakdown: response.data,
        })
      })
  },

  clearSelectedOrderRequest: () => dispatch => {
    dispatch({
      type: Constants.CLEAR_SELECTED_ORDER_REQUEST,
    })
  },

  getGoodsInfoImages: goodsInfoId => dispatch =>
    api
      .get(`b2b/goods_information/${goodsInfoId}/images`, dispatch)
      .then(response => {
        dispatch({
          type: Constants.SET_GOODS_INFORMATION_IMAGES,
          images: response.data,
        })
      }),

  toggleOpenDetail: () => dispatch => {
    dispatch({
      type: Constants.TOGGLE_OPEN_DETAIL,
    })
  },

  toggleOpenSignature: () => dispatch => {
    dispatch({
      type: Constants.TOGGLE_OPEN_SIGNATURE,
    })
  },

  toggleOpenPreview: () => dispatch => {
    dispatch({
      type: Constants.TOGGLE_OPEN_PREVIEW,
    })
  },
}

export default Actions
