import styled from "styled-components";

import Color from '../../../legacy/views/helpers/color'

const NotificationContent = styled.div`
  flex: 1 1 auto;
  overflow: auto;
  display: flex;
  flex-direction: column;
`

const Notifications = styled.ul`
  padding-left: 0;
  margin: 0;
  font-size: 14px;
  line-height: 17px;
  color: $tundora-grey;
  background-color: ${Color.ALABASTER_GREY};
  overflow-y: scroll;
  overflow-x: hidden;
  flex: 1 1 auto;
  list-style-type: none;
`

const LoadMore = styled.div`
  background-color: ${Color.PORCELAIN_GREY};
  text-align: center;
  line-height: 63px;
  height: 63px;
`

export {
  NotificationContent,
  Notifications,
  LoadMore,
}