import * as types from './types'
import { couponService } from 'ggx-global/api/api.service'
import { paymentService } from 'ggx-src/payment/api/api.service'

function collapseSidebar() {
  return {
    type: types.COLLAPSE_SIDEBAR,
  }
}

function hideToastContainer(value) {
  return {
    type: types.HIDE_TOAST_CONTAINER,
    value,
  }
}

function showBanner({ key, parameter }) {
  return {
    type: types.SHOW_BANNER,
    key,
    parameter,
  }
}

function hideBanner() {
  return {
    type: types.HIDE_BANNER,
  }
}

function fetchCoupon({
  orderPrice,
  voucherCode,
  serviceType,
  vehicleType,
  regionIds,
} = {}) {
  return async dispatch => {
    const payload = await couponService.getCoupons({
      orderPrice,
      voucherCode,
      serviceType,
      vehicleType,
      regionIds,
    })
    dispatch({
      type: types.SET_COUPON,
      payload,
    })
  }
}

const showAddCreditCardModal = () => {
  return async dispatch => {
    const cardItent = await paymentService.getCardSetupIntent()

    dispatch({
      type: types.SHOW_ADD_CREDIT_CARD_MODAL,
      payload: {
        visible: true,
        cardItent: cardItent,
      },
    })
  }
}

const hideAddCreditCardModal = () => {
  return {
    type: types.HIDE_ADD_CREDIT_CARD_MODAL,
  }
}

export {
  collapseSidebar,
  hideToastContainer,
  showBanner,
  hideBanner,
  fetchCoupon,
  showAddCreditCardModal,
  hideAddCreditCardModal,
}
