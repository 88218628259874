import { Api } from '../../legacy/utils/api'

const _orderApi = new Api(Api.BASE_URL.API_URL)

const orderService = {
  getOrder: async idOrderRequest => {
    const method = 'get'
    const endpoint = `/b2b/order_requests/${idOrderRequest}`

    try {
      const result = await _orderApi.request(method, endpoint)
      return result.data
    } catch (error) {
      throw error
    }
  },
  getConfigurableRequirement: async vehicle => {
    const method = 'get'
    const endpoint = '/b2b/additional_requirements'
    const params = { vehicle }

    try {
      const result = await _orderApi.request(
        method,
        endpoint,
        null,
        null,
        null,
        null,
        params
      )
      return result.data
    } catch (error) {
      throw error
    }
  },
  downloadPDF: async (params = {}) => {
    const method = 'post'
    const endpoint = '/b2b/order_requests/pdf'
    const config = { responseType: 'blob' }

    try {
      const result = await _orderApi.request(
        method,
        endpoint,
        null,
        params,
        null,
        config
      )

      return {
        data: result.data,
        headers: result.headers,
      }
    } catch (error) {
      throw error
    }
  },
  downloadXLSX: async (params = {}) => {
    const method = 'post'
    const endpoint = '/b2b/order_requests/xlsx'
    const config = { responseType: 'blob' }

    try {
      const result = await _orderApi.request(
        method,
        endpoint,
        null,
        params,
        null,
        config
      )

      return {
        data: result.data,
        headers: result.headers,
      }
    } catch (error) {
      throw error
    }
  },
  downloadOrderPDF: async (id, lang = 'en') => {
    const method = 'post'
    const endpoint = `/b2b/order_requests/${id}/print`
    const config = { responseType: 'blob' }

    try {
      const result = await _orderApi.request(
        method,
        endpoint,
        null,
        { lang },
        null,
        config
      )

      return {
        data: result.data,
      }
    } catch (error) {
      throw error
    }
  },
  getOrderRequests_old: async (params = {}) => {
    const method = 'get'
    const endpoint = '/b2b/order_requests'

    try {
      const result = await _orderApi.request(
        method,
        endpoint,
        null,
        null,
        null,
        null,
        params
      )
      return result.data
    } catch (error) {
      throw error
    }
  },
  getOrderRequests: async (params = {}) => {
    const method = 'get'
    const endpoint = '/b2b/order_requests/index'

    try {
      const result = await _orderApi.request(
        method,
        endpoint,
        null,
        null,
        null,
        null,
        params
      )
      return result.data
    } catch (error) {
      throw error
    }
  },
  cancelOrder: async uuid => {
    const method = 'post'
    const endpoint = `/order_requests/${uuid}/cancel`

    try {
      const results = await _orderApi.request(method, endpoint)
      return results
    } catch (error) {
      throw error
    }
  },
  rateDriver: async ({
    idOrderRequest,
    feedbackTypeCds,
    driverRating,
    clientFeedback,
  }) => {
    const method = 'post'
    const endpoint = `/order_requests/${idOrderRequest}/rate`

    const data = {
      id: idOrderRequest,
      order: {
        driver_rating: driverRating,
        feedback_type_cds: feedbackTypeCds,
        client_feedback: clientFeedback,
      },
    }

    try {
      const results = await _orderApi.request(method, endpoint, null, data)
      return results
    } catch (error) {
      throw error
    }
  },
}

export { _orderApi, orderService }
