// @flow

import Constants from '../constants/action'
import type { Message, NotificationAction } from '../types'

type State = {
  openCentre: boolean,
  messages: ?Array<Message>,
}

const initialState = {
  openCentre: false,
  messages: [],
}

function reducer(
  state: State = initialState,
  action: NotificationAction
): State {
  switch (action.type) {
    case Constants.NOTIFICATION_CENTRE_TOGGLE:
      return Object.assign({}, state, {
        openCentre: !state.openCentre,
      })

    default:
      return state
  }
}

export default reducer
