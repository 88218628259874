import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import {
  BodyBold,
  SmallText,
} from 'ggx-componentlibrary/design/typography/typography.styles'
import { Tooltip } from 'ggx-componentlibrary/components/tooltip/tooltip.component'

import {
  StyledRadioButton,
  CreditCardOptionContainer,
  CreditCardContainer,
  StyledEditLink,
  StyledLink,
} from './payment-method-modal.component.styles'

import { getPaymentInfo } from 'ggx-src/payment/duck/actions'
import { getStripeCards } from 'ggx-src/payment/duck/selectors'
import { formatCardBrand } from 'ggx-service/card-brand/card-brand'
import { getCurrentUserLevel } from 'ggx-src/users-and-branches/duck/selectors'

import CardMasterIcon from '../assets/card_master_color.svg'
import CardVisaIcon from '../assets/card_visa_color.svg'
import CardUnionpayIcon from '../assets/card_unionpay_color.svg'
import CardAeIcon from '../assets/card_ae_color.svg'

const CreditCardSection = ({
  valuePrefix,
  selectedPaymentMethod,
  handlePaymentSelection,
  onClickAdd,
  onClickEdit,
}) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()

  const stripeCards = useSelector(state => getStripeCards(state) ?? [])
  useEffect(() => {
    dispatch(getPaymentInfo())
  }, [])

  const userLevel = useSelector(state => getCurrentUserLevel(state))
  const isMaster = userLevel === 'b2b_master_user'

  return (
    <>
      <CreditCardContainer>
        <BodyBold typographyStyles="mb-s">{t('payment_card')}</BodyBold>
        {stripeCards.map(card => (
          <CreditCardOptionContainer key={card.id} id={card.id}>
            <label>
              <StyledRadioButton
                onChange={handlePaymentSelection}
                option={{
                  value: `${valuePrefix ?? ''}${card.id}`,
                  disabled: card.is_expired,
                }}
                checked={
                  selectedPaymentMethod === `${valuePrefix ?? ''}${card.id}` ||
                  ((valuePrefix ?? '').startsWith(selectedPaymentMethod) &&
                    card.is_default)
                }
                dataTestid="pay-by-credit-card"
              />
              <div>
                <div>
                  {(() => {
                    switch (card.brand.toLowerCase()) {
                      case 'amex':
                        return <CardAeIcon />
                      case 'mastercard':
                        return <CardMasterIcon />
                      case 'unionpay':
                        return <CardUnionpayIcon />
                      case 'visa':
                        return <CardVisaIcon />
                    }
                  })()}
                  <BodyBold
                    typographyStyles={`${
                      card.is_expired ? 'c-grey_dark' : ''
                    } ml-xs mr-xs mb-none`}
                  >
                    {card.nickname ||
                      t('default_card_name', {
                        card_type: formatCardBrand(card.brand, true),
                      })}
                  </BodyBold>
                </div>
                <SmallText
                  typographyStyles={`${
                    card.is_expired ? 'c-grey_dark' : ''
                  } mb-none`}
                >
                  ⋅⋅⋅⋅ {card.last4}
                  {card.is_default && (
                    <>
                      <SmallText
                        as="span"
                        typographyStyles="ml-xxs mr-xxs mb-none"
                      >
                        ·
                      </SmallText>
                      <SmallText
                        as="span"
                        typographyStyles="c-grey_dark ml-none mr-none mb-none"
                      >
                        {t('primary_card')}
                      </SmallText>
                    </>
                  )}
                  {card.is_expired && (
                    <>
                      <SmallText
                        as="span"
                        typographyStyles="ml-xxs mr-xxs mb-none"
                      >
                        ·
                      </SmallText>
                      <SmallText
                        as="span"
                        typographyStyles="c-red_medium ml-none mr-none mb-none"
                      >
                        {t('expired')}
                      </SmallText>
                    </>
                  )}
                </SmallText>
              </div>
            </label>
            {isMaster && (
              <StyledEditLink onClick={() => onClickEdit(card)}>
                {t('edit')}
              </StyledEditLink>
            )}
          </CreditCardOptionContainer>
        ))}
        {stripeCards.length < 4 && (
          <Tooltip
            noMargin
            isHeadingHoverable={!isMaster}
            showTooltipIcon={false}
            heading={
              <StyledLink isMaster={isMaster} onClick={isMaster && onClickAdd}>
                {t('add_card')}
              </StyledLink>
            }
            content={
              <SmallText>{t('helpertext__dont_have_permission')}</SmallText>
            }
          />
        )}
        {stripeCards.length >= 4 && (
          <SmallText>{t('helpertext__added_max_amount_of_cards')}</SmallText>
        )}
      </CreditCardContainer>
    </>
  )
}

CreditCardSection.propTypes = {
  valuePrefix: PropTypes.string,
  selectedPaymentMethod: PropTypes.string,
  handlePaymentSelection: PropTypes.func,
  onClickAdd: PropTypes.func,
  onClickEdit: PropTypes.func,
}

export { CreditCardSection }
