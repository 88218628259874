import { tooltipClasses } from '@mui/material/Tooltip'

import { SPACING } from 'ggx-componentlibrary/design/spacing/spacing.constants'

import { Color } from '../../src/legacy/views/helpers'

export default {
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          flex: '1 1 auto',
          marginRight: SPACING.S,
        },
        inputRoot: {
          paddingTop: 0,
          paddingBottom: 0,
        },
        listbox: {
          '& .MuiAutocomplete-option': {
            padding: `12px ${SPACING.S}`,
            '&.Mui-focused[aria-selected="true"]': {
              backgroundColor: 'rgba(25,118,210,0.08)',
            },
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          width: 370,
          padding: SPACING.M,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        outlinedPrimary: {
          color: Color.LOCHMARA_BLUE,
          borderColor: Color.LOCHMARA_BLUE,
          '&:hover': {
            color: Color.CERULEAN_BLUE,
            borderColor: Color.LOCHMARA_BLUE,
            backgroundColor: Color.PORCELAIN_GREY,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: `0 0 0 ${SPACING.XS}`,
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          padding: SPACING.S,
          backgroundColor: Color.WHITE,
          border: '1px solid rgba(0,0,0,0.1)',
          boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.08)',
          color: Color.BLACK,
          lineHieght: SPACING.S,
          fontFamily:
            'Wix Madefor Text, Lucida Grande, Helvetica Neue, Helvetica, Arial, Verdana, sans-serif',
        },
        tooltipPlacementTop: {
          [`&.${tooltipClasses.tooltip}.${tooltipClasses.tooltipPlacementTop}`]: {
            marginBottom: SPACING.XS,
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          display: 'flex',
          flexDirection: 'column',
          padding: `${SPACING.XS} 0`,
          border: '1px solid rgba(0,0,0,0.1)',
          boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.1)',
          fontFamily:
            'Wix Madefor Text, Lucida Grande, Helvetica Neue, Helvetica, Arial, Verdana, sans-serif',
        },
      },
    },
  },
}
