import React from 'react'
import PropTypes from 'prop-types'

import {
  PaginationWrapper,
  PaginationText,
  PaginationButtonsWrapper,
  PaginationButtonLeft,
  PaginationButtonRight,
} from './pagination.component.styles'

const Pagination = ({
  paginationText,
  currentPageNumber,
  totalPageNumber,
  onPageNavClick,
  loading,
  className,
}) => {
  const onPageNavClickIsFunc = typeof onPageNavClick === 'function'

  const onClickPrev = event => {
    const newPageNumber = currentPageNumber <= 2 ? 1 : currentPageNumber - 1

    if (onPageNavClickIsFunc) {
      event.target.blur()
      onPageNavClick(newPageNumber)
    }
  }

  const onClickNext = event => {
    const newPageNumber =
      currentPageNumber >= totalPageNumber
        ? totalPageNumber
        : currentPageNumber + 1

    if (onPageNavClickIsFunc) {
      event.target.blur()
      onPageNavClick(newPageNumber)
    }
  }

  return (
    <PaginationWrapper className={className}>
      {paginationText && <PaginationText>{paginationText}</PaginationText>}
      <PaginationButtonsWrapper>
        <PaginationButtonLeft
          onClick={onClickPrev}
          disabled={loading || currentPageNumber <= 1}
          data-testid="pagination_prev"
        />
        <PaginationButtonRight
          onClick={onClickNext}
          disabled={loading || currentPageNumber >= totalPageNumber}
          data-testid="pagination_next"
        />
      </PaginationButtonsWrapper>
    </PaginationWrapper>
  )
}

Pagination.propTypes = {
  paginationText: PropTypes.string,
  currentPageNumber: PropTypes.number,
  totalPageNumber: PropTypes.number,
  onPageNavClick: PropTypes.func,
  loading: PropTypes.bool,
  className: PropTypes.string,
}

export { Pagination }
