import React from 'react'
import withRouter from 'react-router/lib/withRouter'

import { Button } from 'ggx-componentlibrary/components/button/button.component'

import { ConnectedHeader } from '../global/header/header.component'
import { ApplicationContainer } from '../global/global.styles'
import translate from '../legacy/locales/translate'
import {
  FlexContainer,
  FlexContentLeft,
  FlexContentRight,
  StyledHeadingXL,
  StyledHeadingL,
  StyledHeading,
} from './404.component.styles'

const translateObject = 'errors.page_not_found'
const translateWithPrefix = key => translate(`${translateObject}.${key}`)

const PageNotFoundView = ({ router }) => (
  <ApplicationContainer>
    <ConnectedHeader />
    <FlexContainer>
      <FlexContentLeft>
        <div>
          <StyledHeadingXL>{translateWithPrefix('oops')}</StyledHeadingXL>
          <StyledHeadingL>{translateWithPrefix('status')}</StyledHeadingL>
          <StyledHeading className="slogan">
            {translateWithPrefix('message')}
          </StyledHeading>
          <Button buttonType="warning" onClick={() => router.push('/')}>
            {translateWithPrefix('home')}
          </Button>
        </div>
      </FlexContentLeft>
      <FlexContentRight>
        <img src="/images/b2b_illustration_ggx.png" alt="gogox" />
      </FlexContentRight>
    </FlexContainer>
  </ApplicationContainer>
)

export default withRouter(PageNotFoundView)
