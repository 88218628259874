import * as types from './types'

function setProfilePhotoDataUrl(dataUrl) {
  return {
    type: types.SET_PROFILE_IMAGE_DATA_URL,
    value: dataUrl,
  }
}

function setCompanyProfilePhotoDataUrl(dataUrl) {
  return {
    type: types.SET_COMPANY_PROFILE_IMAGE_DATA_URL,
    value: dataUrl,
  }
}

function updateUserLanguage(value) {
  return {
    type: types.UPDATE_CURRENT_USER_LANGUAGE,
    data: { meta: { language: value } },
  }
}

function setProfilePhotoDataURLWithRevokeOldURL(dataUrl) {
  return (dispatch, getState) => {
    const { profilePhotoDataUrl: oldURL } = getState()
    dispatch(setProfilePhotoDataUrl(dataUrl))
    URL.revokeObjectURL(oldURL)
  }
}

function setCompanyProfilePhotoDataURLWithRevokeOldURL(dataUrl) {
  return (dispatch, getState) => {
    const { companyProfilePhotoDataUrl: oldURL } = getState()
    dispatch(setCompanyProfilePhotoDataUrl(dataUrl))
    URL.revokeObjectURL(oldURL)
  }
}

export {
  setProfilePhotoDataUrl,
  setCompanyProfilePhotoDataUrl,
  updateUserLanguage,
  setProfilePhotoDataURLWithRevokeOldURL,
  setCompanyProfilePhotoDataURLWithRevokeOldURL,
}
