import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { socket } from 'ggx-global/faye/faye.setup'
import { PackageListPanel } from './package-list-panel.component'
import { QRCodeModal } from './qr-code-modal.compoent'

const PayNowSection = ({ onSuccess, onFailure }) => {
  const [step, setStep] = useState(1)
  const [selectedPackage, setSelectedPackage] = useState()
  const organisationId = useSelector(
    state => state?.organization?.organization?.id
  )

  const handleFailureAndBackToStep1 = () => {
    onFailure()
    setStep(1)
  }

  useEffect(() => {
    if (step === 2 && organisationId) {
      const subscription = socket.subscribe(
        `/notification/subscription/${organisationId}`,
        ({ event_type, message }) => {
          if (event_type === 'payment_transaction') {
            switch (message) {
              case 'paynow_topup_success':
                return onSuccess()
              case 'paynow_topup_failed':
                return handleFailureAndBackToStep1()
              default:
                break
            }
          }
        }
      )
      const timerId = setTimeout(() => setStep(1), 5 * 60 * 1000)
      return () => {
        subscription.unsubscribe()
        clearTimeout(timerId)
      }
    }
  }, [step, organisationId])

  const handleNext = useCallback(value => {
    setSelectedPackage(value)
    setStep(2)
  })

  return (
    <div>
      <div hidden={step !== 1}>
        <PackageListPanel onNext={handleNext} />
      </div>
      {(step === 2 || step === 3) && (
        <QRCodeModal
          selectedPackage={selectedPackage}
          step={step}
          onCancel={() => setStep(1)}
        />
      )}
    </div>
  )
}

PayNowSection.propTypes = {
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
}

export { PayNowSection }
