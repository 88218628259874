import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { BodyBold } from 'ggx-componentlibrary/design/typography/typography.styles'

import {
  Label,
  SectionTitle,
  PlaceOrderRow,
  OrderManagementRow,
  PlaceOrderIcon,
  OrderManagementIcon,
} from '../navigation.component.styles'

const NDDSection = ({
  activePath,
  isNavigationCollapsed,
  isNavigationHovered,
}) => {
  const [t] = useTranslation()

  return (
    <>
      <SectionTitle
        $isNavigationCollapsed={isNavigationCollapsed}
        $isNavigationHovered={isNavigationHovered}
      >
        <BodyBold>{t('common__next_day_delivery')}</BodyBold>
      </SectionTitle>

      <PlaceOrderRow
        to="/next-day-delivery/place-order"
        $isActive={activePath === '/next-day-delivery/place-order'}
        $isNavigationCollapsed={isNavigationCollapsed}
        $isNavigationHovered={isNavigationHovered}
      >
        <PlaceOrderIcon
          $isActive={activePath === '/next-day-delivery/place-order'}
        />
        <Label>{t('common__place_order_title')}</Label>
      </PlaceOrderRow>

      <OrderManagementRow
        to="/next-day-delivery/order-management"
        $isNavigationCollapsed={isNavigationCollapsed}
        $isNavigationHovered={isNavigationHovered}
        $isActive={activePath?.startsWith(
          '/next-day-delivery/order-management'
        )}
      >
        <OrderManagementIcon
          $isActive={activePath?.startsWith(
            '/next-day-delivery/order-management'
          )}
        />
        <Label>{t('common__order_management')}</Label>
      </OrderManagementRow>
    </>
  )
}

NDDSection.propTypes = {
  activePath: PropTypes.string,
  isNavigationCollapsed: PropTypes.bool,
  isNavigationHovered: PropTypes.bool,
}

export default NDDSection
