import React, { useEffect, useState, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useQuery, useMutation, useQueryClient } from 'react-query'

import { brazeClient, BRAZE_EVENT } from 'ggx-service/braze'
import { VanBooking } from '../van-booking.component'
import { setDocumentTitle } from '../../service/document-title/set-document-title'
import { Table } from '../../component-library/components/table/table.component'
import { useFavoriteDriversColumns } from './favorite-drivers.columns'
import { LoadingSpinner } from '../../legacy/views/helpers/loading/spinner'
import NoticeActions from '../../legacy/actions/notice'
import { Modal } from '../../component-library/components/modal/modal.component'
import { Button } from '../../component-library/components/button/button.component'
import { Body } from '../../component-library/design/typography/typography.styles'
import { favoriteDriversApiService } from '../api/favorite-drivers-api.service'

const FAVORITE_DRIVERS_KEY = 'FAVORITE_DRIVERS'

const FavoriteDrivers = ({ displayErrorMessage }) => {
  const queryClient = useQueryClient()
  const [t] = useTranslation()
  const [openRemoveDriverModal, setOpenRemoveDriverModal] = useState(false)
  const idFavoriteDriverToRemove = useRef()

  const onError = useCallback(() => {
    displayErrorMessage(t('errors.generic'))
  }, [displayErrorMessage, t])

  const fetchFavoriteDrivers = useCallback(async () => {
    const { data } = await favoriteDriversApiService.getFavoriteDrivers()
    return data.map((driver, index) => ({ ...driver, index: index + 1 }))
  }, [])

  const {
    isLoading: isLoadingGetDrivers,
    data = [],
  } = useQuery(FAVORITE_DRIVERS_KEY, fetchFavoriteDrivers, { onError })
  const { mutate: removeFavoriteDriverMutation } = useMutation(
    driverId => favoriteDriversApiService.removeFavoriteDriver(driverId),
    {
      onMutate: driverId => {
        brazeClient.setCustomEvent(
          BRAZE_EVENT.TRANSPORT.FAVORITE_DRIVER_REMOVED
        )
        const previousFavoriteDrivers = queryClient.getQueryData(
          FAVORITE_DRIVERS_KEY
        )
        queryClient.setQueryData(FAVORITE_DRIVERS_KEY, old =>
          old.filter(driver => driver.id !== driverId)
        )
        return previousFavoriteDrivers
      },
      onError: (error, favoriteDriverRemoved, previousFavoriteDrivers) => {
        queryClient.setQueryData(FAVORITE_DRIVERS_KEY, previousFavoriteDrivers)
        displayErrorMessage(t('errors.generic'))
      },
      onSettled: () => queryClient.invalidateQueries(FAVORITE_DRIVERS_KEY),
    }
  )

  useEffect(() => {
    setDocumentTitle(t('common__favourite_drivers'))
  }, [t])

  const handleRemoveFavoriteDriver = () => {
    removeFavoriteDriverMutation(idFavoriteDriverToRemove.current)
    setOpenRemoveDriverModal(false)
  }

  const handleModalOpen = idDriver => {
    setOpenRemoveDriverModal(true)
    idFavoriteDriverToRemove.current = idDriver
  }

  const handleModalClose = () => {
    setOpenRemoveDriverModal(false)
  }

  const footerButton = (
    <Button onClick={handleRemoveFavoriteDriver}>
      {t('myDrivers.favlist.removeFromFav.popup.button.confirm')}
    </Button>
  )

  return (
    <div>
      <VanBooking
        heading={t('common__favourite_drivers')}
        description={t('myDrivers.subtitle')}
      >
        <Table
          uniqueIdentifier="id"
          columns={useFavoriteDriversColumns(handleModalOpen)}
          data={data}
          noDataMessage={t('myDrivers.favlist.empty')}
          loading={isLoadingGetDrivers}
          customLoadingIndicator={<LoadingSpinner />}
        />
        {openRemoveDriverModal && (
          <Modal
            header={t('myDrivers.favlist.removeFromFav.popup.title')}
            footer={footerButton}
            onRequestClose={handleModalClose}
          >
            <Body>{t('myDrivers.favlist.removeFromFav.popup.content')}</Body>
          </Modal>
        )}
      </VanBooking>
    </div>
  )
}

FavoriteDrivers.propTypes = {
  displayErrorMessage: PropTypes.func,
}

const mapDispatchToProps = dispatch => ({
  displayErrorMessage: message =>
    dispatch(NoticeActions.setErrorMessage(message)),
})
const ConnectedFavoriteDrivers = connect(
  undefined,
  mapDispatchToProps
)(FavoriteDrivers)

export { ConnectedFavoriteDrivers, FAVORITE_DRIVERS_KEY }
