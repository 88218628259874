import Route from 'react-router/lib/Route'
import IndexRedirect from 'react-router/lib/IndexRedirect'
import IndexRoute from 'react-router/lib/IndexRoute'

import { loadableCreator } from 'ggx-global/loading/loadable-creator'
import { getFeatureFlag } from 'ggx-service/feature-flag/selectors'
import { featureFlagService } from 'ggx-service/feature-flag/feature-flag'
import { brazeClient, BRAZE_EVENT } from 'ggx-service/braze'

import { payByCreditCard } from '../van-booking/duck/selectors'

const LoadablePlaceOrder = loadableCreator({
  dynamicImport: () =>
    import(
      /* webpackChunkName: 'next-day-delivery-place-order' */ './place-order/place-order.component'
    ),
  componentName: 'PlaceOrder',
})

const LoadableEditOrder = loadableCreator({
  dynamicImport: () =>
    import(
      /* webpackChunkName: 'next-day-delivery-edit-order' */ './place-order/edit-order.component'
    ),
  componentName: 'EditOrder',
})

const LoadableOrderManagement = loadableCreator({
  dynamicImport: () =>
    import(
      /* webpackChunkName: 'next-day-delivery-order-management' */ './order-management/order-management.component'
    ),
  componentName: 'OrderManagement',
})

const redirectToIndex = store => (_nextState, replace, callback) => {
  const checkFlag = () => {
    const state = store.getState()
    const featureFlagNextDayDelivery = getFeatureFlag({
      state,
      featureFlag: 'next_day_delivery',
    })
    const isCreditCardUser = payByCreditCard(state)
    const shouldRedirect = !isCreditCardUser || !featureFlagNextDayDelivery

    if (shouldRedirect) {
      replace('/')
    }
    callback()
  }

  featureFlagService
    .triggerFeatureFlag('next_day_delivery')
    .then(checkFlag)
    .catch(checkFlag)
}

const logEnterThePage = () => {
  brazeClient.setCustomEvent(BRAZE_EVENT.NEXT_DAY_DELIVERY.ORDER_MANAGEMENT)
}

const NextDayDeliveryRoutes = store => (
  <Route path="/next-day-delivery" onEnter={redirectToIndex(store)}>
    <IndexRedirect to="/next-day-delivery/place-order" />
    <Route path="place-order" component={LoadablePlaceOrder} />
    <Route path="edit-order">
      <IndexRedirect to="/next-day-delivery/place-order" />
      <Route path=":uuid" component={LoadableEditOrder} />
    </Route>
    <Route path="order-management" onEnter={logEnterThePage}>
      <IndexRoute component={LoadableOrderManagement} />
      <Route path=":uuid">
        <IndexRoute component={LoadableOrderManagement} />
        <Route path=":pkgId" component={LoadableOrderManagement} />
      </Route>
    </Route>
  </Route>
)

export { NextDayDeliveryRoutes }
