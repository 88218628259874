const getSavedCard = state =>
  Boolean(
    state?.payment?.savedCardInfo?.brand && state?.payment?.savedCardInfo?.last4
  )
const getSavedCardInfo = state => state?.payment?.savedCardInfo
const getHasSavedCardInfoBeenRetrieved = state =>
  state?.payment?.savedCardInfo?.hasBeenRetrieved
const getAvailablePaymentMethods = state =>
  state?.payment?.availablePaymentMethods
const getStripeCards = state => state?.payment?.stripeCards
const getPrimaryStripeCard = state =>
  state?.payment?.stripeCards?.filter(item => item.is_default)[0]

export {
  getSavedCard,
  getSavedCardInfo,
  getHasSavedCardInfoBeenRetrieved,
  getAvailablePaymentMethods,
  getStripeCards,
  getPrimaryStripeCard,
}
