function downloadXLSX(data) {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  const reader = new FileReader()
  const blob = new Blob([data], { type: fileType })
  reader.readAsDataURL(blob)

  reader.onloadend = function onloadend() {
    window.open(reader.result)
  }
}

export { downloadXLSX }
