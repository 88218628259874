// @flow

const Countries: { [key: string]: string } = {
  HONG_KONG: 'HK',
  SINGAPORE: 'SG',
  TAIWAN: 'TW',
  CHINA: 'CN',
  VIETNAM: 'VN',
}

export default Countries
