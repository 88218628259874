import { connect } from 'react-redux'

import TabbedView from '../../views/wallet/tabbed_view'
import {
  getTransactions,
  getDepositTransactions,
  setWalletFilterParams,
  getTransactionsReport,
  getDepositTransactionsReport,
} from '../../actions/wallet'
import { getOrderRequests } from '../../actions/orders'

const mapStateToProps = state => ({
  organization: state.organization.organization,
  transactions: state.transactions.transactions,
  depositTransactions: state.deposits.depositTransactions,
  filterParams: state.transactions.filterParams,
  currentPage: state.transactions.currentPage,
  prevPage: state.transactions.prevPage,
  nextPage: state.transactions.nextPage,
  totalPages: state.transactions.totalPages,
  totalCount: state.transactions.totalCount,
  loading: state.transactions.loading,
  nextDepositPage: state.deposits.nextPage,
  prevDepositPage: state.deposits.prevPage,
  currentDepositPage: state.deposits.currentPage,
  allTransactionIds: state.transactions.allIds,
  allDepositIds: state.deposits.allIds,
  included: state.orderRequests.included,
  orderRequests: state.orderRequests.orderRequests,
})

const mapDispatchToProps = dispatch => ({
  getTransactions: params => {
    dispatch(getTransactions(params))
  },
  getDepositTransactions: params => {
    dispatch(getDepositTransactions(params))
  },
  setWalletFilter: params => {
    dispatch(setWalletFilterParams(params))
  },
  downloadTransactionStatement: ids => {
    dispatch(getTransactionsReport(ids))
  },
  downloadDepositStatement: ids => {
    dispatch(getDepositTransactionsReport(ids))
  },
  getOrderRequests: (params, org) => {
    dispatch(getOrderRequests(params, org))
  },
})

const ConnectedWalletTabbedViewContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TabbedView)

export default props => <ConnectedWalletTabbedViewContainer {...props} />
