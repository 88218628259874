import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import {
  Container,
  UpgradeIcon,
  ExternalLinkIcon,
  UpgradeLink,
} from './subscription-callout.component.styles'
import { Body } from 'ggx-componentlibrary/design/typography/typography.styles'

const SubscriptionCallout = ({ message, className }) => {
  const [t] = useTranslation()

  return (
    <Container className={className}>
      <UpgradeIcon />
      <Body noMargin>{message}</Body>
      <UpgradeLink typographyStyles="s-medium" href="/pro" target="_blank">
        {t('common__upgrade_to_pro')}
        <ExternalLinkIcon />
      </UpgradeLink>
    </Container>
  )
}

SubscriptionCallout.propTypes = {
  message: PropTypes.string,
  className: PropTypes.string,
}

export { SubscriptionCallout }
