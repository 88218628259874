
import styled from "styled-components"

import { SmallTextBold } from "ggx-componentlibrary/design/typography/typography.styles"
import { COLOR } from "ggx-componentlibrary/design/color/color.constants"
import { SPACING } from "ggx-componentlibrary/design/spacing/spacing.constants"

import { EXPANDED_TRANSATION_DURATION } from "../navigation.constants"
import { Row, STYLES_FOR_TOGGLE_OPACITY, Label } from "../navigation.component.styles"

import BellSVG from "./assets/bell.svg"
import ChevronSVG from "../assets/chevron-right.svg"

const BellIcon = styled(BellSVG)`
  flex-shrink: 0;
`

const ChevronIcon = styled(ChevronSVG)`
  margin: 0 ${SPACING.XS} 0 auto;
  flex-shrink: 0;
`

const NotificationWrapper = styled.div`
  overflow: hidden;
  flex-shrink: 0;
`

const NotificationRow = styled(Row)`
  cursor: pointer;

  ${Label} {
    flex-grow: 1;
    max-width: calc(100% - 75px);
    color: ${COLOR.GREY_DARK};
    ${STYLES_FOR_TOGGLE_OPACITY}
  }

  ${BellIcon} {
    path {
      stroke: ${COLOR.GREY_DARK};
    }
  }

  ${ChevronIcon} {
    position: absolute;
    right: 10px;
  }
  
  ${(props) => !props.$isActive && `
    :hover {
      ${Label} {
        color: ${COLOR.BLUE_MEDIUM};
      }

      ${BellIcon} {
        path {
          stroke: ${COLOR.BLUE_MEDIUM};
        }
      }

      ${ChevronIcon} {
        path {
          stroke: ${COLOR.BLUE_MEDIUM};
          stroke-opacity: 1;
        }
      }
    }
  `}
`

const NotificationIconContainer = styled.div`
  position: relative;
  top: 1px;
`

const InlineNotificationCount = styled(SmallTextBold)`
  margin: 0 10px 0 0;
  padding: 0 8px;
  background-color: ${COLOR.RED_MEDIUM};
  color: ${COLOR.WHITE};
  border-radius: 9px;
  text-align: center;
`

const CollapsedNotificationCount = styled(InlineNotificationCount)`
  position: absolute;
  right: -18px;
  top: -3px;
  opacity: ${(props) => props.$isNavigationCollapsed && !props.$isNavigationHovered ? 1 : 0};
  transition: opacity ${EXPANDED_TRANSATION_DURATION};
`

export {
  BellIcon,
  ChevronIcon,
  NotificationWrapper,
  NotificationRow,
  NotificationIconContainer,
  InlineNotificationCount,
  CollapsedNotificationCount
}