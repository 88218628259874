import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Button } from 'ggx-componentlibrary/components/button/button.component'
import { CalloutModal } from '../../callout-modal/callout-modal.component'

const OrderManagementCallout = ({ stepText = '', handleNext, handleSkip }) => {
  const [t] = useTranslation()
  const [highlights, setHighlights] = useState([])
  const [modalPosition, setModalPosition] = useState(null)

  const heading = t('tour__download_order_records')
  const message = t('tour__get_complete_reports_for_expense_management')
  const [prevStep, maxStep] = stepText.split(' / ')

  const footer =
    prevStep === maxStep ? (
      <Button onClick={handleSkip}>{t('button__got_it')}</Button>
    ) : (
      <>
        <Button onClick={handleSkip} buttonType="secondary">
          {t('button__end_tour')}
        </Button>
        <Button onClick={handleNext}>{t('button_next')}</Button>
      </>
    )

  useEffect(() => {
    const vanOrderManagementLink = document.querySelector(
      '[data-testid="navigation-bar-van-order-management"]'
    )
    const deliveryOrderManagementLink = document.querySelector(
      '[data-testid="navigation-bar-delivery-order-management"]'
    )

    const vanOrderManagementLinkYPosition = Math.ceil(
      vanOrderManagementLink?.getBoundingClientRect?.().y
    )
    const deliveryOrderManagementLinkYPosition = Math.ceil(
      deliveryOrderManagementLink?.getBoundingClientRect?.().y
    )

    setModalPosition({
      left: '420px',
      top: `${vanOrderManagementLinkYPosition + 90}px`,
    })

    setHighlights(
      [
        vanOrderManagementLink && {
          width: '192px',
          height: '42px',
          x: '12px',
          y: `${vanOrderManagementLinkYPosition - 3}px`,
        },
        deliveryOrderManagementLink && {
          width: '192px',
          height: '42px',
          x: '12px',
          y: `${deliveryOrderManagementLinkYPosition - 3}px`,
        },
      ].filter(Boolean)
    )
  }, [])

  return (
    <CalloutModal
      stepText={stepText}
      highlights={highlights}
      modalPosition={modalPosition}
      heading={heading}
      message={message}
      footer={footer}
    />
  )
}

OrderManagementCallout.propTypes = {
  stepText: PropTypes.string,
  handleNext: PropTypes.func,
  handleSkip: PropTypes.func,
}

export { OrderManagementCallout }
