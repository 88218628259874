import styled from "styled-components"

import { SPACING } from "ggx-componentlibrary/design/spacing/spacing.constants"
import { Dropdown } from "ggx-componentlibrary/components/dropdown/dropdown.component"

import { Color } from "../../helpers"

const FilterContainer = styled.div`
  background-color: ${Color.PORCELAIN_GREY};
  padding: 1.5em;
  display: flex;
  width: 100%;
`

const Filter = styled.div`
  flex: 0 0 10%;
  margin-right: ${SPACING.S};
`

const FilterLabel = styled.div`
  padding-bottom: 12px;
`

const StyledDropdown = styled(Dropdown)`
  background: white;
  border-radius: 4px;
`

export { FilterContainer, Filter, FilterLabel, StyledDropdown }
