import styled from "styled-components"

import {
  FONT_REGULAR,
  FONT_WEIGHT,
  FONT_SIZE,
} from "../../design/typography/typography.constants"
import { COLOR } from "../../design/color/color.constants"
import { SPACING } from "../../design/spacing/spacing.constants"
import { SmallText, Heading } from "../../design/typography/typography.styles"

const RadioButton = styled.span`
  box-sizing: border-box;
  width: 100%;

  ${FONT_REGULAR};
  font-size: ${FONT_SIZE.LARGE};
  text-align: center;
  color: ${(props) => (props.disabled ? COLOR.GREY_DARK : COLOR.BLACK)};
  background-color: ${(props) =>
    props.disabled ? COLOR.GREY_LIGHTER : COLOR.WHITE};
  padding: ${SPACING.XS} ${SPACING.S};
  line-height: 22px;
  border: 1px solid ${COLOR.GREY_LIGHT};
  border-radius: 4px;
  display: inline-block;
  user-select: ${(props) => (props.disabled ? "none" : "auto")};
`

const RadioButtonWrapper = styled.div`
  &:focus,
  &:hover {
    outline: none;
    ${RadioButton} {
      cursor: ${(props) => (props.disabled ? null : "pointer")};
      border-color: ${(props) => (props.disabled ? null : COLOR.BLUE_MEDIUM)};
    }
  }

  &[aria-checked="true"] ${RadioButton} {
    color: ${(props) => (props.disabled ? COLOR.GREY_DARK : COLOR.BLUE_MEDIUM)};
    border-color: ${(props) =>
      props.disabled ? COLOR.GREY_LIGHT : COLOR.BLUE_MEDIUM};
    font-weight: ${FONT_WEIGHT.BOLD};
  }
`

const StyledSmallText = styled(SmallText)`
  margin: ${SPACING.XS} 0 0;
`

const StyledHeading = styled(Heading)`
  margin-bottom: ${SPACING.S};
`

export { RadioButton, RadioButtonWrapper, StyledSmallText, StyledHeading }
