import styled from "styled-components"

import { COLOR } from "./../../../component-library/design/color/color.constants"
import { SPACING } from "./../../../component-library/design/spacing/spacing.constants"
import { FONT_SIZE } from "./../../../component-library/design/typography/typography.constants"

import {
  RadioButtonGroup,
  RadioButton,
} from "../../../component-library/components/radio-button/radio-button.component"
import { RadioButtonWrapper } from "../../../component-library/components/radio-button/radio-button.component.styles"
import { Button } from "../../../component-library/components/button/button.component"
import { TextArea } from "../../../component-library/components/textarea/textarea.component"
import { BodyBold } from "../../../component-library/design/typography/typography.styles"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${COLOR.GREY_LIGHTER};
  border-radius: 8px;
  padding: ${SPACING.S};
  margin: ${SPACING.XS} 0px;
`

const StyledBodyBold = styled(BodyBold)`
  text-align: center;
  margin-top: ${SPACING.M};
`

const StyledTextArea = styled(TextArea)`
  width: 100%;
  margin-bottom: ${SPACING.S};
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

const StyledButton = styled(Button)`
  margin-left: ${SPACING.XS};
`

export {
  Wrapper,
  StyledBodyBold,
  StyledTextArea,
  ButtonsWrapper,
  StyledButton,
}
