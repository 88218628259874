import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { SmallText } from 'ggx-componentlibrary/design/typography/typography.styles'
import {
  Card,
  Details,
  PricingTableLink,
  ExternalLinkIcon,
} from './service.card.component.styles'

const ServiceCard = ({
  className,
  availableText,
  content,
  cardImage,
  pricingTableLink,
}) => {
  const [t] = useTranslation()
  return (
    <Card className={className} $available={!!availableText}>
      <Card.Available $available={!!availableText}>
        {availableText}
      </Card.Available>
      {cardImage}
      <Card.Heading>{content?.heading}</Card.Heading>
      <Card.Message typographyStyles="mb-s">{content?.message}</Card.Message>
      {content?.details?.length > 0 && (
        <Details>
          {content.details.map(text => (
            <SmallText typographyStyles="ml-s mb-none">
              <li>{text}</li>
            </SmallText>
          ))}
        </Details>
      )}
      {availableText && (
        <PricingTableLink href={pricingTableLink} target="_blank">
          <PricingTableLink.Text>
            {t('text_link__view_pricing_table')}
          </PricingTableLink.Text>
          <ExternalLinkIcon />
        </PricingTableLink>
      )}
    </Card>
  )
}

ServiceCard.propTypes = {
  className: PropTypes.string,
  cardImage: PropTypes.node,
  availableText: PropTypes.string,
  pricingTableLink: PropTypes.string,
  content: PropTypes.shape({
    heading: PropTypes.string,
    message: PropTypes.string,
    details: PropTypes.arrayOf(PropTypes.string),
  }),
}

export { ServiceCard }
