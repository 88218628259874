import styled from "styled-components"
import { SPACING } from "ggx-componentlibrary/design/spacing/spacing.constants"
import { COLOR } from "ggx-componentlibrary/design/color/color.constants"
import { Page } from 'ggx-global/page/page.component'
import { LoadingContainer } from 'ggx-global/loading/loading.component.styles'
import {
  BodyBold,
  Body,
  HeadingL,
  TextLinkStyles,
} from 'ggx-componentlibrary/design/typography/typography.styles'
import { PaginationWrapper, PaginationText } from 'ggx-componentlibrary/components/table/pagination/pagination.component.styles'
import { Modal } from 'ggx-componentlibrary/components/modal/modal.component'
import VanTruckCourierPNGPath from './assets/van_truck_courier.png'
import VanTruckPNGPath from './assets/van_truck.png'
import CourierPNGPath from './assets/courier.png'
import MotorcyclePath from './assets/motorcycle.png'
import SedanPath from './assets/sedan.png'
import TricyclePath from './assets/tricycle.png'
import TruckPath from './assets/truck.png'
import VanPath from './assets/van.png'

const DataTab = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  & > ${LoadingContainer} {
    background: white;
    position: absolute;
    top: 0;
    left: 0;
    opacity: ${props => props.isPending ? '0.8' : '0'};
  }
  & ${PaginationText} {
    margin-top: 0;
    margin-bottom: 0;
  }
  & ${PaginationWrapper} {
    margin-top: 0;
  }
`

const CardContainer = styled.div`
  margin: 0;
  display: grid;
  grid-template-columns: repeat(${props=> props.column ?? 3}, 1fr);
  grid-template-rows: repeat(3, max-content);
  column-gap: ${SPACING.L};
  row-gap: calc(${SPACING.M} - 4px);
  grid-auto-rows: max-content;
  flex: 1 0 auto;
`

const StyledPage = styled(Page)`
  padding-right: ${SPACING.XL};
  padding-bottom: 0;
  & > ${DataTab} {
    min-height: calc(100vh - 180.5px);
  }
  & > ${DataTab} ${CardContainer} {
    margin: ${SPACING.M} 0 ${SPACING.XXS};
    row-gap: ${SPACING.L};
    min-height: 541px;
  }

  & > ${DataTab} ${PaginationWrapper} {
    margin-top: ${SPACING.M};
  }
`

const Loading = styled.div`
  display: flex;
  justify-content: space-between;
`

const DescriptionBody = styled(Body)`
  color: ${COLOR.GREY_DARK};
`

const Card = styled.div`
  flex: 0 1 auto;
  max-width: 370px;
  border: 1px solid ${COLOR.GREY_LIGHT};
  &[data-selected] {
    border: 1px solid ${COLOR.BLUE_MEDIUM};  
  }
  border-radius: 4px;
  & > *:nth-child(1), & > *:nth-child(2) {
    padding: ${SPACING.S};
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  & > *:nth-child(1) > *:nth-child(2) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  & > *:nth-child(2) {
    border-top: 1px dashed ${COLOR.GREY_LIGHT};
  }
  background: ${props => props.greyStyle ? COLOR.GREY_LIGHTEST : COLOR.WHITE};
  position: relative;
  &::before {
    content: ' ';
    display: ${props => props.total > 1 ? 'block' : 'none'};
    z-index: -1;
    position: absolute;
    top: 100%;
    margin: -2px 2px 0;
    width: calc(100% - 4px);
    height: 6px;
    background: ${props => props.greyStyle ? COLOR.GREY_LIGHTEST : COLOR.WHITE};
    border: 1px solid ${COLOR.GREY_LIGHT};
    border-top: 0;
    border-radius: 4px;
  }
  &::after {
    content: ' ';
    display: ${props => props.total > 2 ? 'block' : 'none'};
    z-index: -2;
    position: absolute;
    top: 100%;
    margin: 1px 5px 0;
    width: calc(100% - 10px);
    height: 6px;
    background: ${props => props.greyStyle ? COLOR.GREY_LIGHTEST : COLOR.WHITE};
    border: 1px solid ${COLOR.GREY_LIGHT};
    border-top: 0;
    border-radius: 4px;
  }
  & ${DescriptionBody}:last-child {
    margin: 0;
  }
`

const CardTitle = styled(HeadingL)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 280px;
  &[data-selected] {
    color: ${COLOR.BLUE_MEDIUM};
  }
`

const ActiveEmptyDescription = styled.div`
  grid-area: 2 / 1 / end / end;
  margin-top: -8px;
  padding: 120px 0;
`

const HistoryEmptyDescription = styled.div`
  grid-area: 1 / 1 / end / end;
  margin-top: -8px;
  padding: 120px 0;
`

const StyledBodyBold = styled(BodyBold)`
  text-align: center;
  margin-bottom: 10px;
`

const StyledBody = styled(Body)`
  text-align: center;
  margin: 0;
`

const ServiceTypesBody = styled(Body)`
  display: flex;
  color: ${COLOR.GREY_DARK};
  & > svg {
    margin-right: ${SPACING.XXS};
  }
  & > span {
    margin-right: ${SPACING.XS};
  }
`

const VehiclePicture = styled.img`
  height: 45px;
  width: 45px;
  border-radius: 50px;
  overflow: hidden;
  margin-bottom: ${SPACING.XXS};
  ${props => props.greyStyle ? 'filter: grayscale(1) opacity(0.5);' : ''}
`

const DetailsButton = styled(Body)`
  ${TextLinkStyles}
  margin: 0;
`

const ListContainer = styled.ul`
  margin: 0;
  padding-left: ${SPACING.M};
`

const VehiclePictureInDetail = styled.img`
  height: 64px;
  width: 64px;
  border-radius: 50px;
  overflow: hidden;
  float: right;
`

const DetailHR = styled.hr`
  border: 1px solid ${COLOR.GREY_LIGHT};
  border-top: 0px;
  margin: ${SPACING.M} 0;
`

const RedemptionFormContainer = styled.div`
  flex: 0 1 auto;
  max-width: 370px;
  max-height: 156px;
  border: 1px solid ${COLOR.GREY_LIGHT};
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 46px 24px 22px;
  & > div {
    width: 100%;
    height: 100%;
  }
  & > div > div:nth-child(2) {
    display: flex;
    align-items: flex-start;
  }
  & > div > div:nth-child(2) > *:first-child {
    flex-grow: 1;
    margin-right: ${SPACING.S};
  }
`

const FieldHeader = styled(BodyBold)`
  display: block;
  margin-bottom: ${SPACING.XS};
`

const StyledModal = styled(Modal)`
  & ${Card}:not([data-disabled]) {
    cursor: pointer;
  }
`

const SERVICE_TYPES = {
  TRANSPORT: 'transport',
  DELIVERY: 'delivery',
}

const getVoucherPicture = (serviceTypes, vehicleTypesRule) => {
  if (serviceTypes.length === 2) return VanTruckCourierPNGPath
  else if (serviceTypes.length === 1) {
    if (serviceTypes[0] === SERVICE_TYPES.TRANSPORT) {
      let vehicleSet = vehicleTypesRule.reduce((set, item) => {
        switch (item) {
          case 'van': set.add(VanPath); break;
          case 'van500': set.add(VanPath); break;
          case 'van1000': set.add(VanPath); break;
          case 'motorcycle': set.add(MotorcyclePath); break;
          case 'tricycle': set.add(TricyclePath); break;
          case 'sedan': set.add(SedanPath); break;
          case 'mudou': set.add(TruckPath); break;
          case 'mudou9': set.add(TruckPath); break;
          case 'lorry10': set.add(TruckPath); break;
          case 'lorry14': set.add(TruckPath); break;
          case 'lorry24': set.add(TruckPath); break;
          case 'lorry26': set.add(TruckPath); break;
          case 'truck500': set.add(TruckPath); break;
          case 'truck1000': set.add(TruckPath); break;
          case 'truck1500': set.add(TruckPath); break;
          case 'truck2000': set.add(TruckPath); break;
        }
        return set
      }, new Set())
      return vehicleSet.size === 1 ? [...vehicleSet.values()][0] : VanTruckPNGPath;
    } else if (serviceTypes[0] === SERVICE_TYPES.DELIVERY) {
      return CourierPNGPath;
    }
  }
}

export {
  StyledPage,
  DataTab,
  CardContainer,
  Loading,
  Card,
  CardTitle,
  ActiveEmptyDescription,
  HistoryEmptyDescription,
  StyledBodyBold,
  StyledBody,
  ServiceTypesBody,
  DescriptionBody,
  VehiclePicture,
  DetailsButton,
  ListContainer,
  VehiclePictureInDetail,
  DetailHR,
  RedemptionFormContainer,
  FieldHeader,
  StyledModal,
  getVoucherPicture
}
