import styled from "styled-components"

const Container = styled.div`
  position: fixed;
  z-index: 9999;
  ${(props) => props.top && `top: ${props.top};`}
  ${(props) => props.right && `right: ${props.right};`}
  ${(props) => props.bottom && `bottom: ${props.bottom};`}
  ${(props) => props.left && `left: ${props.left};`}
`

export { Container }
