import Snackbar from '@mui/material/Snackbar'
import SnackbarContent from '@mui/material/SnackbarContent'
import PropTypes from 'prop-types'

import Color from './color'

function createSnackbar({ type, open, message, onRequestClose }) {
  const messageBgColor = {
    success: Color.PERSIAN_GREEN,
    error: Color.MANDY_RED,
  }
  const sx = {
    backgroundColor: messageBgColor[type],
  }

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={onRequestClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <SnackbarContent message={message} sx={sx} />
    </Snackbar>
  )
}

function ErrorSnackbar({ open, message, onRequestClose, classes }) {
  return createSnackbar({
    type: 'error',
    open,
    message,
    onRequestClose,
  })
}

function SuccessSnackbar({ open, message, onRequestClose, classes }) {
  return createSnackbar({
    type: 'success',
    open,
    message,
    onRequestClose,
  })
}

createSnackbar.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  onRequestClose: PropTypes.func,
  classes: PropTypes.object,
}

export const StyledSuccessSnackbar = SuccessSnackbar
export const StyledErrorSnackbar = ErrorSnackbar
