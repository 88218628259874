const initialState = []

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE': {
      const lastItem = state[state.length - 1]
      if (
        lastItem?.pathname !== action.payload.pathname ||
        lastItem?.search !== action.payload.search
      ) {
        return state.concat(action.payload)
      }
    }
    default:
      return state
  }
}
