import styled from "styled-components"

import { Body, SmallText } from "ggx-componentlibrary/design/typography/typography.styles"
import { FONT_REGULAR } from "ggx-componentlibrary/design/typography/typography.constants"
import { SPACING } from "ggx-componentlibrary/design/spacing/spacing.constants"
import { COLOR } from "ggx-componentlibrary/design/color/color.constants"
import { Tooltip } from "ggx-componentlibrary/components/tooltip/tooltip.component"
import TooltipIcon from 'ggx-componentlibrary/components/tooltip/assets/info.svg'

const ContentBoxWidth = "338px"

const ItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${SPACING.XS};
`

const AdditionalServiceItem = styled(SmallText)`
  margin: 0;
`

const StyledTooltip = styled(Tooltip)`
  flex-grow: 1;

  div:first-child {
    margin-bottom: 0;
  }
  svg {
    margin-left: 4px;
  }
`

const StyledTooltipText = styled(SmallText)`
  margin-bottom: 0;
  color: ${COLOR.BLACK};
`

const AdditionalServiceItemWithIcon = styled(SmallText)`
  margin: 0;
  display: flex;
`

const StyledTooltipLink = styled.a`
  display: inline-flex;
  margin-left: 4px;
`

const StyledTooltipIcon = styled(TooltipIcon)`
  display: inline-block;
  height: 16px;
  width: 16px;
  cursor: pointer;
  transition: filter 300ms eas-in;
  &:hover {
    filter: brightness(1.2);
  }
`

const Menu = styled.div`
  position: absolute;
  flex-direction: column;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  background-color: ${COLOR.WHITE};
  border-radius: ${SPACING.XXS};
  transition: opacity 0.2s ease-in-out;
  padding: ${SPACING.XS} 0;
  color: ${COLOR.BLACK};
  right: 0;
`
Menu.Row = styled(Body)`
  text-decoration: none;
  padding: 16px 0;
  color: ${COLOR.RED_MEDIUM};
  width: 147px;
  text-align: center;
  :hover {
    background-color: ${COLOR.GREY_LIGHTER};
  }
`

const StyledLink = styled(SmallText)`
  display: inline-block;
  position: relative;
  color: ${COLOR.BLUE_MEDIUM};
  text-decoration: none;
  cursor: pointer;
  ${FONT_REGULAR}
  
  & ${Menu} {
    display: none;
  }
  &:hover ${Menu} {
    display: flex;
  }
`

export {
  ItemWrapper,
  AdditionalServiceItem,
  StyledTooltip,
  StyledTooltipText,
  ContentBoxWidth,
  AdditionalServiceItemWithIcon,
  StyledTooltipLink,
  StyledTooltipIcon,
  StyledLink,
  Menu,
}
