import React from 'react'
import * as Reactabular from 'reactabular-table'
import * as resolve from 'table-resolver'
import PropTypes from 'prop-types'

import { Pagination } from './pagination/pagination.component'
import {
  StyledTableWrapper,
  StyledTable,
  StyledTableAlwaysRerenderedRow,
  StyledTableHeaderCell,
  StyledTableBodyCell,
  StyledEmptyDataMessage,
  StyledLoadingWrapper,
  StyledDeleteRowIcon,
} from './table.component.styles'
import { SelectionHeader } from './selection-header.component'
import { SelectionCell } from './selection-cell.component'

class Table extends React.Component {
  static displayName = 'Table'

  static propTypes = {
    uniqueIdentifier: PropTypes.string.isRequired,
    className: PropTypes.string,
    dataTestid: PropTypes.string,
    onRowActivation: PropTypes.func,
    data: PropTypes.array,
    noDataMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    customLoadingIndicator: PropTypes.node,
    loading: PropTypes.bool,
    activatedRow: PropTypes.any,
    paginationText: PropTypes.string,
    currentPageNumber: PropTypes.number,
    totalPageNumber: PropTypes.number,
    onPageNavClick: PropTypes.func,
    autoLayout: PropTypes.bool,
    supportEdit: PropTypes.bool,
    supportSelection: PropTypes.bool,
    supportRowDeletion: PropTypes.bool,
    supportMultiRowHeader: PropTypes.bool,
    onSelect: PropTypes.func,
    onCellChange: PropTypes.func,
    onRowDelete: PropTypes.func,
    invalidRowList: PropTypes.array,
    previousSelectedRows: PropTypes.object,
    limit: PropTypes.number,
    onExceedLimit: PropTypes.func,
    deleteButtonLeft: PropTypes.bool,
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        headerLabel: PropTypes.string.isRequired,

        /* Used to provide a custom element to be rendered in the header */
        headerComponent: PropTypes.func,

        /* Used to format the string displayed in the cell, ie value => `+${value}` */
        cellFormatter: PropTypes.func,

        /* Used to provide a custom element to be rendered in the cell */
        cellComponent: PropTypes.func,
      })
    ).isRequired,
    disabledRowIndexes: PropTypes.arrayOf(PropTypes.number),
  }

  state = {
    activatedRow: null,
    selectedRows: {},
    previousSelectedRows: {},
  }

  componentDidUpdate(prevProps) {
    const {
      activatedRow,
      data,
      supportSelection,
      uniqueIdentifier,
      onSelect,
      previousSelectedRows,
    } = this.props
    const activatedRowUpdated =
      typeof activatedRow !== 'undefined' &&
      activatedRow !== this.state.activatedRow &&
      activatedRow !== prevProps.activatedRow
    const previousSelectedRowUpdated =
      typeof previousSelectedRows === 'object' &&
      Object.keys(previousSelectedRows).length > 0 &&
      previousSelectedRows !== this.state.previousSelectedRows

    if (activatedRowUpdated) {
      this.setState({ activatedRow })
    }

    if (previousSelectedRowUpdated) {
      this.setState({
        selectedRows: { ...prevProps.selectedRows, ...previousSelectedRows },
        previousSelectedRows,
      })
    }

    if (supportSelection && data && data !== prevProps.data) {
      const { selectedRows } = this.state
      const filterUnavailableData = (prevState, selectedRowIdentifier) =>
        data.some(
          row =>
            row[uniqueIdentifier].toString() ===
            selectedRowIdentifier.toString()
        )
          ? { ...prevState, [selectedRowIdentifier]: true }
          : prevState
      const filteredSelectedRows = Object.keys(selectedRows).reduce(
        filterUnavailableData,
        {}
      )

      this.setState({ selectedRows: filteredSelectedRows })

      if (typeof onSelect === 'function') {
        onSelect(this._getSelectedData(filteredSelectedRows))
      }
    }
  }

  handleHeaderSelection = value => {
    const {
      uniqueIdentifier,
      data,
      onSelect,
      onExceedLimit,
      limit,
    } = this.props
    const { previousSelectedRows } = this.state

    const createAllSelectedState = (prevState, nextRow) => ({
      ...prevState,
      [nextRow[uniqueIdentifier]]: true,
    })
    const newSelectedRows =
      value === true
        ? (data && data.reduce(createAllSelectedState, {})) || {}
        : {}

    if (typeof onSelect === 'function') {
      if (limit) {
        if (
          Object.keys(previousSelectedRows).length +
            Object.keys(newSelectedRows).length >
          limit
        ) {
          onExceedLimit()
        } else {
          onSelect(this._getSelectedData(newSelectedRows))
          this.setState({ selectedRows: newSelectedRows })
        }
      } else {
        onSelect(this._getSelectedData(newSelectedRows))
        this.setState({ selectedRows: newSelectedRows })
      }
    } else {
      this.setState({ selectedRows: newSelectedRows })
    }
  }

  handleRowSelection = identifier => value => {
    const { onSelect, onExceedLimit, limit } = this.props
    const { selectedRows, previousSelectedRows } = this.state

    const newSelectedRows =
      value === true
        ? { ...selectedRows, [identifier]: true }
        : Object.keys(selectedRows).reduce(
            (prevState, nextRowIdentifier) =>
              nextRowIdentifier.toString() === identifier.toString()
                ? prevState
                : { ...prevState, [nextRowIdentifier]: true },
            {}
          )

    if (typeof onSelect === 'function') {
      if (limit) {
        if (
          Object.keys(previousSelectedRows).length +
            Object.keys(newSelectedRows).length >
          limit
        ) {
          onExceedLimit()
        } else {
          onSelect(this._getSelectedData(newSelectedRows))
          this.setState({ selectedRows: newSelectedRows })
        }
      } else {
        onSelect(this._getSelectedData(newSelectedRows))
        this.setState({ selectedRows: newSelectedRows })
      }
    } else {
      this.setState({ selectedRows: newSelectedRows })
    }
  }

  _createRowProps = row => {
    const {
      uniqueIdentifier,
      onRowActivation,
      disabledRowIndexes = [],
    } = this.props
    const identifier = row[uniqueIdentifier]
    const supportActivation = typeof onRowActivation === 'function'

    return {
      supportActivation,
      isDisabled: disabledRowIndexes.includes(identifier),
      isSelected: this.state.selectedRows[identifier] === true,
      isActivated: this.state.activatedRow === identifier,
      onClick: supportActivation
        ? () => {
            this.setState({ activatedRow: identifier })
            onRowActivation(row)
          }
        : undefined,
      'data-testid': `table-row-${identifier}`,
    }
  }

  _createDeleteRowColumn() {
    return {
      cell: {
        transforms: [
          (_, { rowData }) => {
            const { onRowDelete, uniqueIdentifier } = this.props
            const handleClick = () => {
              onRowDelete(rowData[uniqueIdentifier])
            }

            return {
              children: <StyledDeleteRowIcon onClick={handleClick} />,
            }
          },
        ],
      },
      props: { isDeleteRowColumn: true },
    }
  }

  _createSelectionColumn({ onFirstRowOfMultiRowHeader }) {
    return {
      header: {
        transforms: [
          () => {
            const { data, uniqueIdentifier } = this.props
            const { selectedRows } = this.state
            const allSelected =
              data &&
              data.every(row => selectedRows[row[uniqueIdentifier]] === true)
            const indeterminate =
              !allSelected &&
              data.some(row => selectedRows[row[uniqueIdentifier]] === true)

            return {
              ...(!onFirstRowOfMultiRowHeader && {
                children: (
                  <SelectionHeader
                    onSelect={this.handleHeaderSelection}
                    indeterminate={indeterminate}
                    allSelected={allSelected}
                  />
                ),
              }),
            }
          },
        ],
      },
      cell: {
        transforms: [
          (_, { rowData }) => {
            const { uniqueIdentifier } = this.props
            const identifier = rowData[uniqueIdentifier]
            const selected = this.state.selectedRows[identifier] === true

            return {
              children: (
                <SelectionCell
                  onSelect={this.handleRowSelection(identifier)}
                  selected={selected}
                />
              ),
            }
          },
        ],
      },
      props: { isSelectionColumn: true },
    }
  }

  _remapColumnDefinitions(columns) {
    const {
      supportSelection,
      supportMultiRowHeader,
      supportRowDeletion,
      uniqueIdentifier,
      onCellChange,
      supportEdit,
      deleteButtonLeft,
    } = this.props

    const columnData = supportMultiRowHeader
      ? resolve.columnChildren({ columns })
      : columns

    const convertValueToString = value =>
      typeof value !== 'undefined' && value !== null && value.toString()

    const remappedHeaderFormat = HeaderComponent => label => (
      <HeaderComponent label={label} />
    )

    const remappedCellTransform = CellComponent => (value, { rowData }) => ({
      children: <CellComponent value={value} rowData={rowData} />,
    })

    const remappedEditableCellTransform = (CellComponent, dataTestid) => (
      value,
      { rowData }
    ) => {
      return {
        children: (
          <CellComponent
            value={value}
            rowData={rowData}
            onCellChange={onCellChange}
            uniqueIdentifier={uniqueIdentifier}
            dataTestid={dataTestid}
          />
        ),
      }
    }

    const remappedCellFormat = formatFn => (value, { rowData }) =>
      formatFn(value, rowData)

    const defaultCellComponent = () => value => ({
      children: <div>{value}</div>,
    })

    const getTransform = column =>
      (column.cellComponent && [
        remappedCellTransform(column.cellComponent, column.key),
      ]) ||
      (column.editableCellComponent &&
        supportEdit && [
          remappedEditableCellTransform(
            column.editableCellComponent,
            column.key
          ),
        ]) ||
      (column.editableCellComponent &&
        !supportEdit && [defaultCellComponent()]) ||
      []

    const onFirstRowOfMultiRowHeader = columnData.some(
      column => column?.props?.colSpan
    )

    const remappedColumns = [
      ...(supportSelection
        ? [this._createSelectionColumn({ onFirstRowOfMultiRowHeader })]
        : []),
      ...columnData.map(column => ({
        property: column.key,
        header: {
          label: column.headerLabel,
          formatters: column.headerComponent
            ? [remappedHeaderFormat(column.headerComponent)]
            : [],
          props: {
            columnWidth: column.width || 'auto',
            ...(column.props?.colSpan ? { colSpan: column.props.colSpan } : {}),
          },
        },
        cell: {
          props: {
            overflow: column.overflow,
          },
          formatters: column.cellFormatter
            ? [remappedCellFormat(column.cellFormatter)]
            : [convertValueToString],
          transforms: getTransform(column),
        },
      })),
    ]

    if (supportRowDeletion && deleteButtonLeft) {
      return [...[this._createDeleteRowColumn()], ...remappedColumns]
    } else if (supportRowDeletion && !deleteButtonLeft) {
      return [...remappedColumns, ...[this._createDeleteRowColumn()]]
    }
    return remappedColumns
  }

  _getSelectedData(selectedRows = this.state.selectedRows) {
    const { data = [], uniqueIdentifier } = this.props

    return Object.keys(selectedRows).map(selectedIdentifier =>
      data.find(
        row =>
          row[uniqueIdentifier].toString() === selectedIdentifier.toString()
      )
    )
  }

  _getNestedHeaders(columns) {
    const headerRows = resolve.headerRows({ columns })
    return headerRows.map(row => this._remapColumnDefinitions(row))
  }

  _getMultiRowHeaderGridLineIndex(columns) {
    const { supportSelection } = this.props
    const columnsEndingIndex = columns
      .map(column => column.children.length)
      .slice(0, -1)
    const indexToAddGridline = supportSelection ? [1] : []
    const startingIndex = supportSelection ? 1 : 0

    columnsEndingIndex.reduce((prev, current) => {
      indexToAddGridline.push(prev + current)
      return prev + current
    }, startingIndex)

    return indexToAddGridline
  }

  getRenderers = () => {
    const {
      supportEdit,
      autoLayout,
      invalidRowList = [],
      uniqueIdentifier,
    } = this.props

    const applyCustomBodyCellStyles = (props, CellComponent) => {
      const rowData = props?.children?.props?.rowData || {}
      const row = rowData[uniqueIdentifier]
      const invalidRow = invalidRowList.includes(row)

      return <CellComponent invalidRow={invalidRow} {...props} />
    }

    return {
      table: props => (
        <StyledTable
          supportEdit={supportEdit}
          autoLayout={autoLayout}
          {...props}
        />
      ),
      header: {
        cell: props => <StyledTableHeaderCell {...props} />,
      },
      body: {
        row: StyledTableAlwaysRerenderedRow,
        cell: props => applyCustomBodyCellStyles(props, StyledTableBodyCell),
      },
    }
  }

  render() {
    const {
      data,
      columns,
      uniqueIdentifier,
      className,
      dataTestid,
      noDataMessage,
      paginationText,
      loading,
      currentPageNumber = 1,
      totalPageNumber = 1,
      onPageNavClick,
      customLoadingIndicator: CustomLoadingIndicator,
      supportMultiRowHeader,
      supportRowDeletion,
    } = this.props

    const multiRowHeader = supportMultiRowHeader
      ? this._getNestedHeaders(columns)
      : null
    const multiRowHeaderGridLines = supportMultiRowHeader
      ? this._getMultiRowHeaderGridLineIndex(columns)
      : []
    const remappedColumns = this._remapColumnDefinitions(columns)
    const loadingElement = CustomLoadingIndicator || (
      <StyledEmptyDataMessage>Loading...</StyledEmptyDataMessage>
    )
    const noDataElement = (
      <StyledEmptyDataMessage>{noDataMessage}</StyledEmptyDataMessage>
    )

    if (!data || data.length === 0) {
      return (
        <StyledTableWrapper className={className} data-testid={dataTestid}>
          <StyledLoadingWrapper showOverlay={loading}>
            {loading ? loadingElement : noDataElement}
          </StyledLoadingWrapper>
        </StyledTableWrapper>
      )
    }

    return (
      <StyledTableWrapper
        className={className}
        data-testid={dataTestid || 'table-wrapper'}
        multiRowHeaderGridLines={multiRowHeaderGridLines}
        supportRowDeletion={supportRowDeletion}
      >
        <Reactabular.Provider
          columns={remappedColumns}
          renderers={this.getRenderers()}
        >
          <Reactabular.Header headerRows={multiRowHeader} />
          <Reactabular.Body
            rows={data}
            rowKey={uniqueIdentifier}
            onRow={this._createRowProps}
          />
        </Reactabular.Provider>
        {loading ? (
          <StyledLoadingWrapper showOverlay>
            {loadingElement}
          </StyledLoadingWrapper>
        ) : null}
        {paginationText && (
          <Pagination
            paginationText={paginationText}
            currentPageNumber={currentPageNumber}
            totalPageNumber={totalPageNumber}
            onPageNavClick={onPageNavClick}
            loading={loading}
          />
        )}
      </StyledTableWrapper>
    )
  }
}

export { Table }
