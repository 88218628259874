const FEATUREFLAG_GOGODELIVERY_GGB = 'FEATUREFLAG_GOGODELIVERY_GGB'
const FEATUREFLAG_DELIVERY_HOLD_AND_CHARGE =
  'FEATUREFLAG_DELIVERY_HOLD_AND_CHARGE'
const FEATUREFLAG_DELIVERY_INTERNAL_USER = 'FEATUREFLAG_DELIVERY_INTERNAL_USER'
const FEATUREFLAG_DELIVERY_OPTIONAL_FIELD_FLOOR =
  'FEATUREFLAG_DELIVERY_OPTIONAL_FIELD_FLOOR'
const FEATUREFLAG_DELIVERY_ADDRESS_MATCHING =
  'FEATUREFLAG_DELIVERY_ADDRESS_MATCHING'
const DELIVERY_SET_DROPOFF_TIME = 'DELIVERY_SET_DROPOFF_TIME'
const DELIVERY_SET_ORDER_DETAIL_IMAGE_DATA_URL =
  'DELIVERY_SET_ORDER_DETAIL_IMAGE_DATA_URL'
const DELIVERY_SET_BULK_IMPORT_TABLE_DATA =
  'DELIVERY_SET_BULK_IMPORT_TABLE_DATA'
const DELIVERY_EDIT_BULK_IMPORT_TABLE_DATA =
  'DELIVERY_EDIT_BULK_IMPORT_TABLE_DATA'
const DELIVERY_BULK_EDIT_BULK_IMPORT_TABLE_DATA =
  'DELIVERY_BULK_EDIT_BULK_IMPORT_TABLE_DATA'
const DELIVERY_DELETE_BULK_IMPORT_TABLE_DATA =
  'DELIVERY_DELETE_BULK_IMPORT_TABLE_DATA'
const DELIVERY_DELETE_BULK_IMPORT_TABLE_DATA_ROW =
  'DELIVERY_DELETE_BULK_IMPORT_TABLE_DATA_ROW'
const DELIVERY_SET_BULK_IMPORT_QUOTATION_TOTAL =
  'DELIVERY_SET_BULK_IMPORT_QUOTATION_TOTAL'
const FEATUREFLAG_DELIVERY_PACKAGE_SIZE_LWH =
  'FEATUREFLAG_DELIVERY_PACKAGE_SIZE_LWH'
const DELIVERY_BULK_IMPORT_SET_QUOTE_IN_PROGRESS =
  'DELIVERY_BULK_IMPORT_SET_QUOTE_IN_PROGRESS'
const DELIVERY_BULK_IMPORT_ADDRESS_MATCH_IN_PROGRESS =
  'DELIVERY_BULK_IMPORT_ADDRESS_MATCH_IN_PROGRESS'
const DELIVERY_SET_BULK_IMPORT_UPLOAD_ERROR =
  'DELIVERY_SET_BULK_IMPORT_UPLOAD_ERROR'
const DELIVERY_BULK_IMPORT_SHOW_LOADING_SCREEN =
  'DELIVERY_BULK_IMPORT_SHOW_LOADING_SCREEN'
const DELIVERY_BULK_IMPORT_SET_EXCEL_PARSING_IN_PROGRESS =
  'DELIVERY_BULK_IMPORT_SET_EXCEL_PARSING_IN_PROGRESS'

const SHOPIFY_SET_QUOTE_IN_PROGRESS = 'SHOPIFY_SET_QUOTE_IN_PROGRESS'
const SHOPIFY_SET_ORDERS_TABLE_DATA = 'SHOPIFY_SET_ORDERS_TABLE_DATA'
const SHOPIFY_EDIT_ORDERS_TABLE_DATA = 'SHOPIFY_EDIT_ORDERS_TABLE_DATA'
const SHOPIFY_EDIT_COLUMNS_ORDERS_TABLE_DATA =
  'SHOPIFY_EDIT_COLUMNS_ORDERS_TABLE_DATA'
const SHOPIFY_DELETE_ORDERS_TABLE_DATA = 'SHOPIFY_DELETE_ORDERS_TABLE_DATA'
const SHOPIFY_DELETE_ORDERS_TABLE_DATA_ROW =
  'SHOPIFY_DELETE_ORDERS_TABLE_DATA_ROW'
const SHOPIFY_SET_ORDERS_TABLE_QUOTATION_TOTAL =
  'SHOPIFY_SET_ORDERS_TABLE_QUOTATION_TOTAL'

export {
  FEATUREFLAG_GOGODELIVERY_GGB,
  FEATUREFLAG_DELIVERY_HOLD_AND_CHARGE,
  FEATUREFLAG_DELIVERY_INTERNAL_USER,
  FEATUREFLAG_DELIVERY_OPTIONAL_FIELD_FLOOR,
  FEATUREFLAG_DELIVERY_ADDRESS_MATCHING,
  DELIVERY_SET_DROPOFF_TIME,
  DELIVERY_SET_ORDER_DETAIL_IMAGE_DATA_URL,
  DELIVERY_SET_BULK_IMPORT_TABLE_DATA,
  DELIVERY_EDIT_BULK_IMPORT_TABLE_DATA,
  DELIVERY_BULK_EDIT_BULK_IMPORT_TABLE_DATA,
  DELIVERY_DELETE_BULK_IMPORT_TABLE_DATA_ROW,
  DELIVERY_SET_BULK_IMPORT_QUOTATION_TOTAL,
  DELIVERY_DELETE_BULK_IMPORT_TABLE_DATA,
  FEATUREFLAG_DELIVERY_PACKAGE_SIZE_LWH,
  DELIVERY_BULK_IMPORT_SET_QUOTE_IN_PROGRESS,
  DELIVERY_BULK_IMPORT_ADDRESS_MATCH_IN_PROGRESS,
  DELIVERY_SET_BULK_IMPORT_UPLOAD_ERROR,
  DELIVERY_BULK_IMPORT_SHOW_LOADING_SCREEN,
  DELIVERY_BULK_IMPORT_SET_EXCEL_PARSING_IN_PROGRESS,
  SHOPIFY_SET_ORDERS_TABLE_DATA,
  SHOPIFY_EDIT_ORDERS_TABLE_DATA,
  SHOPIFY_EDIT_COLUMNS_ORDERS_TABLE_DATA,
  SHOPIFY_DELETE_ORDERS_TABLE_DATA,
  SHOPIFY_DELETE_ORDERS_TABLE_DATA_ROW,
  SHOPIFY_SET_QUOTE_IN_PROGRESS,
  SHOPIFY_SET_ORDERS_TABLE_QUOTATION_TOTAL,
}
