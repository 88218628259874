import styled from 'styled-components'

import Color from '../../../legacy/views/helpers/color'
import {
  DEFAULT_FONT_STACK,
} from "ggx-componentlibrary/design/typography/typography.constants"
import {
  FONT_SIZE,
} from "ggx-componentlibrary/design/typography/typography.constants"

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${Color.ALABASTER_GREY};
  border-bottom: 1px solid ${Color.SILVER};
  padding-left: 28px;
  padding-right: 16px;
  cursor: pointer;
`

const TitleText = styled.p`
  ${DEFAULT_FONT_STACK}
  font-size: ${FONT_SIZE.LARGE};
  line-height: 37px;
  color: ${Color.TUNDORA_GREY};
`

export {
  Container,
  TitleText
}