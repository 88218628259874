import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'

import { Button } from 'ggx-componentlibrary/components/button/button.component'
import { TextField } from 'ggx-componentlibrary/components/text-field/text-field.component'
import {
  RedemptionFormContainer,
  FieldHeader,
} from './voucher.component.styles'
import { displayAlert } from 'ggx-componentlibrary/components/alerts/alerts.component'
import { couponService } from 'ggx-global/api/api.service'

const SERVICE_TYPES = {
  TRANSPORT: 'transport',
  DELIVERY: 'delivery',
}

const useFieldStateMeta = (name, { dirtyFields, touchedFields, errors }) => {
  const dirty = dirtyFields[name]
  const touched = touchedFields[name]
  const error = errors[name]
  return useMemo(() => ({ dirty, touched, error: error?.message }), [
    dirty,
    touched,
    error,
  ])
}

const RedemptionForm = ({
  onSuccess,
  orderPrice,
  serviceType,
  vehicleType,
}) => {
  const [t] = useTranslation()
  const {
    register,
    handleSubmit,
    setError,
    formState,
    resetField,
    setFocus,
  } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
  })
  const couponMeta = useFieldStateMeta('coupon', formState)
  const onSubmit = useCallback(
    async ({ coupon }) => {
      try {
        const data = await couponService.redeem(coupon, {
          orderPrice,
          serviceType,
          vehicleType,
        })
        const detail = Object.values(data.vouchers)[0]
        await onSuccess(coupon, data)
        displayAlert({
          message: t(
            detail.can_apply_to_order
              ? 'feedback__coupon_claimed'
              : 'feedback__coupon_order_not_eligible'
          ),
          autoClose: true,
        })
        setFocus('coupon')
        resetField('coupon')
      } catch (e) {
        if (e.response.data.i18n_key === 'first_order_rule_not_match') {
          setError('coupon', {
            type: 'validError',
            message: t('error__coupon_new_users_only'),
          })
        } else
          setError('coupon', {
            type: 'validError',
            message: t('error__invalid_coupon_code'),
          })
      }
    },
    [onSuccess]
  )

  return (
    <RedemptionFormContainer
      as="form"
      onSubmit={event => {
        event.stopPropagation()
        event.preventDefault()
        if (!formState.isSubmitting) {
          handleSubmit(onSubmit)(event)
        }
      }}
    >
      <div>
        <FieldHeader as="label" htmlFor="coupon">
          {t('text__add_a_coupon')}
        </FieldHeader>
        <div>
          {useMemo(
            () => (
              <TextField
                id="coupon"
                name="coupon"
                meta={couponMeta}
                inputProps={register('coupon', {
                  required: t('error__invalid_coupon_code'),
                })}
              />
            ),
            [couponMeta, register, t]
          )}
          <Button type="submit" isLoading={formState.isSubmitting}>
            {t('add_coupon')}
          </Button>
        </div>
      </div>
    </RedemptionFormContainer>
  )
}

RedemptionForm.propTypes = {
  onSuccess: PropTypes.func,
  orderPrice: PropTypes.number,
  serviceType: PropTypes.oneOf([
    SERVICE_TYPES.TRANSPORT,
    SERVICE_TYPES.DELIVERY,
  ]),
  vehicleType: PropTypes.string,
}

export { RedemptionForm }
