import Route from 'react-router/lib/Route'
import { loadableCreator } from '../delivery/loading/loadable-creator'
import { featureFlagService } from './../service/feature-flag/feature-flag'

const LoadableHome = loadableCreator({
  dynamicImport: () =>
    import(/* webpackChunkName: 'home' */ './home.component'),
  componentName: 'Home',
})

const redirectToPlaceOrder = (store, featureFlagName) => (
  _nextState,
  replace,
  callback
) => {
  const checkFlag = () => {
    const state = store.getState()
    const shouldRedirect = state[featureFlagName] === false
    if (shouldRedirect) {
      replace('/van/order')
    }
    callback()
  }

  featureFlagService
    .triggerFeatureFlag('homepage_access')
    .then(checkFlag)
    .catch(checkFlag)
}

const HomeRoutes = store => (
  <Route
    component={LoadableHome}
    path="home"
    onEnter={redirectToPlaceOrder(store, 'featureFlagHomepageAccess')}
  />
)

export { HomeRoutes }
