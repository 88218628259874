import React from "react"
import styled from "styled-components"
import { COLOR } from "../../design/color/color.constants"
import { SPACING } from "../../design/spacing/spacing.constants"
import { SmallText, Body } from "../../design/typography/typography.styles"
import {
  FONT_SIZE,
  FONT_REGULAR,
} from "../../design/typography/typography.constants"
import ClearIcon from "./assets/clear-icon.svg"

const InputOnFocusStyles = `border-color: ${COLOR.BLUE_MEDIUM};`
const HEIGHT = "40px"
const HEIGHT_WITH_HELPER_TEXT = "64px"

const InputStyles = (props) => `
  &[type=number]::-webkit-inner-spin-button,
  &[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type=number] {
    -moz-appearance: textfield;
  }

  box-sizing: border-box;
  flex-grow: 1;
  ${FONT_REGULAR};
  height: ${HEIGHT};
  padding: 0 ${SPACING.S};
  color: ${props.disabled ? COLOR.GREY_DARK : COLOR.BLACK};
  border: 1px solid;
  border-radius: 4px;
  outline: none;
  background-color: ${props.disabled ? COLOR.GREY_LIGHTEST : COLOR.WHITE};
  border-color: ${COLOR.GREY_LIGHT};
  ${props.isFocused ? InputOnFocusStyles : ""}

  // Overrides GGB global styling
  ${props.error ? `&&& { border-color: ${COLOR.RED_MEDIUM}; }` : ""}

  && {
    font-size: ${FONT_SIZE.MEDIUM};
  }

  &:focus {
    ${InputOnFocusStyles}
  }

  &::placeholder {
    color: ${COLOR.GREY_DARK};
  }

  ${props.$showClearIcon ? `padding-right: ${SPACING.XL}` : ""}
  ${
    props.$showSuffix && !props.$showClearIcon
      ? "border-radius: 4px 0 0 4px;"
      : ""
  }
  ${
    props?.options?.some((option) => option.singleValueMessage !== undefined)
      ? `height: ${HEIGHT_WITH_HELPER_TEXT};`
      : ""
  }
`
const StyledSmallText = styled(SmallText)`
  margin: ${SPACING.XS} 0 0;
`

const StyledInput = styled(
  React.forwardRef(({ error, ...args }, ref) => <input ref={ref} {...args} />)
)(InputStyles)

const StyledError = styled(SmallText)`
  margin: ${SPACING.XS} 0 0;
  color: ${COLOR.RED_MEDIUM};
`

const InputContainer = styled.div`
  position: relative;
  display: flex;
`

const StyledClearIcon = styled(ClearIcon)`
  cursor: pointer;
  position: absolute;
  right: 0;
  height: 100%;
  padding-right: 10px;
  box-sizing: initial;
`

const Suffix = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  border: 1px solid;
  border-color: ${COLOR.GREY_LIGHT};
  border-radius: 0 4px 4px 0;
  padding: 0 12px;
  border-left: none;
`

const SuffixContent = styled(Body)`
  color: ${COLOR.GREY_DARK};
  margin: 0;
  width: max-content;
`

export {
  InputStyles,
  StyledSmallText,
  StyledInput,
  StyledError,
  InputContainer,
  StyledClearIcon,
  Suffix,
  SuffixContent,
}
