import { getCountry } from '../views/helpers'

function geocode(geocoder, address) {
  return new Promise((resolve, reject) => {
    geocoder.geocode({ address }, (places, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        if (
          places.length === 1 &&
          places[0].address_components.length === 1 &&
          places[0].address_components[0].types[0] === 'country'
        ) {
          reject(new Error("Couldn't find a location"))
          return
        }

        const geometry = places[0].geometry
        if (geometry && geometry.location) {
          const loc = geometry.location
          resolve({ places, lat: loc.lat(), lng: loc.lng() })
          return
        }
        reject(new Error("Couldn't find a location"))
      } else {
        reject(new Error(status))
      }
    })
  })
}

function reverseGeocode(geocoder, place) {
  return new Promise((resolve, reject) => {
    geocoder.geocode({ location: place }, (places, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        resolve(places)
      } else {
        reject(new Error(status))
      }
    })
  })
}

const formatInput = input => {
  const currentCountry = getCountry()
  switch (currentCountry) {
    case 'SG':
      return `${input}, Singapore`
    case 'TW':
      return `台灣${input}`
    default:
      return input
  }
}

const autocomplete = (geocoder, autocompleteService, input) =>
  new Promise((resolve, reject) => {
    const componentRestrictions = {}
    if (getCountry()) {
      componentRestrictions.country = getCountry().toLowerCase()
    }

    autocompleteService.getPlacePredictions(
      {
        input: formatInput(input),
        componentRestrictions,
      },
      (predictions, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          resolve(
            predictions.filter(
              prediction => prediction.types.indexOf('country') === -1
            )
          )
        } else {
          geocode(geocoder, input)
            .then(({ places }) => {
              const localizedPredictions = places.filter(p => {
                const component = p.address_components.find(
                  c => c.types[0] === 'country'
                )

                return (
                  p.address_components.length > 1 &&
                  component.short_name === getCountry()
                )
              })

              if (localizedPredictions.length) {
                return resolve(localizedPredictions)
              }
              return reject(new Error('No localized predictions found.'))
            })
            .catch(() => reject(new Error(status)))
        }
      }
    )
  })

const place = (placesService, { place_id }) =>
  new Promise((resolve, reject) => {
    // autocompleteService.getQueryPredictions(
    placesService.getDetails({ placeId: place_id }, (_place, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        resolve(_place)
      } else {
        reject(new Error('Something happened...'))
      }
    })
  })

function getETA(start, end, callback, returnText = false) {
  const googleGlobal = window.google || window.__legacy__google

  if (!googleGlobal) return

  // NOTE: because the driver location returned from the server
  // has keys latitude and longitude...
  const origin = new googleGlobal.maps.LatLng(start.latitude, start.longitude)
  const destination = new googleGlobal.maps.LatLng(end.lat, end.lon)
  const distanceMatrixService = new googleGlobal.maps.DistanceMatrixService()

  distanceMatrixService.getDistanceMatrix(
    {
      origins: [origin],
      destinations: [destination],
      travelMode: googleGlobal.maps.TravelMode.DRIVING,
      avoidHighways: true,
      avoidTolls: true,
    },
    (response, status) => {
      if (status === googleGlobal.maps.DistanceMatrixStatus.OK) {
        const data = response.rows[0].elements[0]

        if (data.status === googleGlobal.maps.DistanceMatrixStatus.OK) {
          const returnValue = returnText
            ? data.duration.text
            : data.duration.value
          return callback(returnValue, true)
        }
      }

      return callback(null, false)
    }
  )
}

export default { geocode, reverseGeocode, autocomplete, place, getETA }
