import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { endOfYear } from 'date-fns'
import vi from 'date-fns/locale/vi'
import enUS from 'date-fns/locale/en-US'
import zhCN from 'date-fns/locale/zh-CN'
import zhTW from 'date-fns/locale/zh-TW'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

import {
  StyledDateRange,
  CalendarWrapper,
  Footer,
} from '../calendar.component.styles'
import { StyledButton } from '../../button/button.component.styles'
import { COLOR } from '../../../design/color/color.constants'

import { useOnOutsideRefClickListener } from '../hooks/use-on-outside-ref-click-listener'
import DateInput from './date-input.component'

const CalendarWithEditableInput = ({
  onSubmit,
  selectedDates,
  footerCancelButtonText,
  footerApplyButtonText,
  locale,
  inputText,
}) => {
  const localeMapper = {
    'en-hk': enUS,
    'zh-hk': zhTW,
    'en-sg': enUS,
    'zh-tw': zhTW,
    'zh-cn': zhCN,
    'vi-vn': vi,
  }

  const validStartDate =
    new Date(selectedDates.startDate).toString() !== 'Invalid Date'
  const validEndDate =
    new Date(selectedDates.startDate).toString() !== 'Invalid Date'
  const {
    startDate: defaultStartDate,
    endDate: defaultEndDate,
  } = useMemo(() => {
    const startDate = new Date(
      moment()
        .clone()
        .subtract(15, 'days')
    )
    const endDate = new Date(
      moment()
        .clone()
        .add(15, 'days')
    )

    return { startDate, endDate }
  }, [])

  const [showCalendar, setShowCalendar] = useState(false)
  const [dateRange, setDateRange] = useState([
    {
      startDate: validStartDate
        ? new Date(selectedDates.startDate)
        : defaultStartDate,
      endDate: validEndDate ? new Date(selectedDates.endDate) : defaultEndDate,
      key: 'selection',
    },
  ])

  useEffect(() => {
    if (selectedDates.startDate === '' || selectedDates.endDate === '') {
      setDateRange([
        {
          startDate: defaultStartDate,
          endDate: defaultEndDate,
          key: 'selection',
        },
      ])
    }
    if (!!selectedDates.startDate && !!selectedDates.endDate) {
      setDateRange([
        {
          startDate: selectedDates.startDate,
          endDate: selectedDates.endDate,
          key: 'selection',
        },
      ])
    }
  }, [defaultEndDate, defaultStartDate, selectedDates])

  const handleSelect = (updateDates = {}) => {
    onSubmit({
      startDate: updateDates.startDate || dateRange[0].startDate,
      endDate: updateDates.endDate || dateRange[0].endDate,
    })
    setShowCalendar(false)

    if (updateDates.shouldUpdateRange) {
      setDateRange([
        {
          startDate: updateDates.startDate,
          endDate: updateDates.endDate,
          key: 'selection',
        },
      ])
    }
  }

  const handleDateInputChange = newDates => {
    setDateRange([
      {
        ...dateRange[0],
        ...newDates,
      },
    ])
  }

  // Dimsiss the calendar view when clicked outside the calendar component
  const calendarContainerRef = useRef()
  const onOutsideCalendarClicked = useCallback(() => {
    setShowCalendar(false)
  }, [])
  useOnOutsideRefClickListener(calendarContainerRef, onOutsideCalendarClicked)

  const onCalendarWrapperClicked = useCallback(() => {
    if (!showCalendar) setShowCalendar(true)
  }, [showCalendar])

  return (
    <div ref={calendarContainerRef}>
      <DateInput
        dateRange={dateRange}
        onClick={onCalendarWrapperClicked}
        handleDateInputChange={handleDateInputChange}
        handleSelect={handleSelect}
        inputText={inputText}
      />
      {showCalendar && (
        <CalendarWrapper data-testid="calendar-date-range">
          <StyledDateRange
            ranges={dateRange}
            months={2}
            direction="horizontal"
            showDateDisplay={false}
            onChange={date => setDateRange([date.selection])}
            fixedHeight
            color={COLOR.BLUE_MEDIUM}
            rangeColors={[COLOR.BLUE_MEDIUM]}
            locale={localeMapper[locale]}
            editableDateInputs={true}
            dateDisplayFormat="d/MM/yy"
            maxDate={endOfYear(new Date())}
            minDate={new Date('2013/07/01')}
          />
          <Footer>
            <StyledButton
              buttonType="secondary"
              onClick={() => setShowCalendar(false)}
            >
              {footerCancelButtonText}
            </StyledButton>
            <StyledButton
              onClick={handleSelect}
              data-testid="calendar-apply-button"
            >
              {footerApplyButtonText}
            </StyledButton>
          </Footer>
        </CalendarWrapper>
      )}
    </div>
  )
}

CalendarWithEditableInput.defaultProps = {
  selectedDates: {
    startDate: '',
    endDate: '',
  },
  footerCancelButtonText: 'Cancel',
  footerApplyButtonText: 'Apply',
  locale: 'en-hk',
  inputText: 'to',
}

CalendarWithEditableInput.propTypes = {
  onSubmit: PropTypes.func,
  selectedDates: PropTypes.shape({
    startDate: PropTypes.date,
    endDate: PropTypes.date,
  }),
  footerCancelButtonText: PropTypes.string,
  footerApplyButtonText: PropTypes.string,
  locale: PropTypes.string,
  inputText: PropTypes.string
}

export { CalendarWithEditableInput }
