import moment from 'moment-timezone'
import { isB2BUser } from '../../legacy/views/helpers/auth/auth'
import { registerSuper, peopleSet, identify, lastLoginKey } from './mixpanel'

// user is the response from login call to api
function initializeMixpanel(user) {
  try {
    const dateFormatString = 'YYYY-MM-DDTHH:mm:ss'
    const localLoginKey = lastLoginKey(user.id)
    const lastLogin = localStorage.getItem(localLoginKey) || ''
    const lastLoginUTC = lastLogin
      ? moment(parseInt(lastLogin))
          .utc()
          .format(dateFormatString)
      : null
    const userType = isB2BUser(user) ? 'business' : 'personal'
    const country = localStorage.getItem('country') || ENV.GOGOVAN_COUNTRY

    const superProperties = {
      user_account_id: user.id,
      last_login: lastLoginUTC,
      user_type: userType,
      country,
      user_logged_in_method: 'logged',
    }

    const peopleProperties = {
      $last_login: moment()
        .utc()
        .format(dateFormatString),
      Company: user.organization.name,
      user_account_id: user.id,
      screen_width: screen.width,
      screen_height: screen.height,
      device_pixel_ratio: window.devicePixelRatio,
    }

    registerSuper(superProperties)
    peopleSet(peopleProperties)
    identify(user.id)
  } catch (error) {
    console.log(error)
  }
}

export { initializeMixpanel }
