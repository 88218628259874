import * as braze from '@braze/web-sdk'

// https://docs.google.com/spreadsheets/d/1o1a9CnNqGqN6B8lstBjxVCnv92rANluunW6A_ZtzS38/edit#gid=0
const BRAZE_EVENT = {
  AUTH: {
    LOGIN: 'login',
  },
  DELIVERY: {
    NEW_ORDER: 'web_GGD_New Order_Same Day',
    ORDER_CONFIRMED_OLD: 'Order Confirmed - Delivery Service',
    ORDER_CONFIRMED_NEW: 'web_GGD_Place_Order_Order_Confirmed_Same Day',
    BULK_IMPORT_ORDERS: 'web_GGD_Bulk_Order_Same Day',
    BULK_IMPORT_ORDERS_FILE_UPLOAD: 'web_GGD_Bulk _Import_Status_Same Day',
    BULK_ORDERS_CONFIRMED: 'web_GGD_Place_Bulk_Order_Same Day',
    ORDER_MANAGEMENT: 'web_GGD_Order_Management_Same Day',
    DOWNLOAD_REPORT: 'GGXB_Order_Management_Download_Report',
  },
  NEXT_DAY_DELIVERY: {
    NEW_ORDER: 'web_GGD_New Order_Next Day',
    ORDER_CONFIRMED: 'web_GGD_Place_Order_Order_Confirmed_Next Day',
    ORDER_MANAGEMENT: 'web_GGD_Order_Management_Next Day',
  },
  TRANSPORT: {
    NEW_ORDER: 'web_start order info',
    NEW_ORDER_DETAILS_CONFIRMED: 'web_order details confirmed',
    NEW_ORDER_INFO_CONFIRMED: 'web_order info confirmed',
    ORDER_CONFIRMED_OLD: 'web_order confirmed',
    ORDER_CONFIRMED_NEW: 'Order Confirmed - Van Service',
    FAVORITE_DRIVER_ADDED: 'web_favorite driver added',
    FAVORITE_DRIVER_REMOVED: 'web_favorite driver removed',
    ORDER_MANAGEMENT: 'web_order management',
    BULK_IMPORT_ORDERS: 'BULK_IMPORT_ORDERS',
    ORDER_BY_IMPORT_ADDRESS: 'ORDER_BY_IMPORT_ADDRESS',
    DOWNLOAD_REPORT: 'GGXB_Order_Management_Download_Report',
  },
  PAYMENT: {
    ADD_CARDS: 'web_add cards',
    REMOVE_CARDS: 'web_remove cards',
    WALLET_TOPUP: 'web_wallet topup',
    WALLET_TOPUP_PAID: 'web_wallet topup paid',
    PAID_BY_CREDIT_CARDS: 'web_order paid by credit cards',
    PAID_BY_WALLET: 'web_order paid by wallet credits',
  },
  TRANSACTION_HISTORY: {
    DOWNLOAD_REPORT: 'GGXB_Transaction_History_Download_Report',
  },
  USERS_AND_BRANCHES: {
    ENTER_PAGE: 'USERS_AND_BRANCHES',
    BRANCH_FILTER_SELECTED: 'Branch_filter_selected',
    ACCESS_LEVEL_FILTER_SELECTED: 'Access_level_filter_selected',
    STATUS_FILTER_SELECTED: 'Status_filter_selected',
    EDIT_USERS_BUTTON_TAPPED: 'Edit_users_button_tapped',
    ADD_NEW_USER_BUTTON_TAPPED: 'Add_new_user_button_tapped',
    NEW_USER_SAVED: 'New_user_saved',
    EDIT_INFO_BUTTON_TAPPED: 'Edit_info_button_tapped',
    EDIT_USER_INFO_SAVE_BUTTON_TAPPED: 'Edit_User_info_save_button_tapped',
    USER_TABLE_SORTED_ON_NAME: 'User_table_sorted_on_name',
    USER_TABLE_SORTED_ON_BRANCH: 'User_table_sorted_on_branch',
    USER_TABLE_SORTED_ON_ACCESS_LEVEL: 'User_table_sorted_on_access_level',
    USER_TABLE_SORTED_ON_STATUS: 'User_table_sorted_on_status',
  },
}

const brazeClient = {
  initialize: () => {
    braze.initialize(process.env.BRAZE_KEY, {
      baseUrl: 'sdk.iad-03.braze.com',
      enableLogging: process.env.CLIENT_ENV === 'development',
      // Allows Braze dashboard users to run Javascript.
      allowUserSuppliedJavascript: true,
    })
    // automaticallyShowInAppMessages should be called before calling openSession
    braze.automaticallyShowInAppMessages()
    braze.openSession()
    braze.requestPushPermission()
    window.braze = braze
  },
  setUser: ({
    id,
    email,
    phoneNumber,
    name,
    country,
    language,
    paymentMethod,
    organizationName,
    accountLevel,
  }) => {
    braze.changeUser(`${country}#${id}`)
    braze.getUser().setFirstName(name)
    braze.getUser().setEmail(email)
    braze.getUser().setPhoneNumber(phoneNumber)
    braze.getUser().setLanguage(language)

    braze.getUser().setCustomUserAttribute('Country', country)
    braze.getUser().setCustomUserAttribute('account_level', accountLevel)
    braze.getUser().setCustomUserAttribute('Company', organizationName)
    braze
      .getUser()
      .setCustomUserAttribute('UMT.organization_name', organizationName)
    braze
      .getUser()
      .setCustomUserAttribute(
        'has_credit_card',
        paymentMethod === 'credit_card'
      )
    braze
      .getUser()
      .setCustomUserAttribute(
        'has_monthly_settlement',
        paymentMethod === 'monthly_settlement'
      )
    braze
      .getUser()
      .setCustomUserAttribute(
        'has_monthly_settlement',
        paymentMethod === 'monthly_settlement'
      )

    braze.getUser().setCustomUserAttribute('screen_width', screen.width)
    braze.getUser().setCustomUserAttribute('screen_height', screen.height)
    braze
      .getUser()
      .setCustomUserAttribute('device_pixel_ratio', window.devicePixelRatio)
  },
  setCustomEvent: (name, property) => {
    braze.logCustomEvent(name, property)
  },
}

export { brazeClient, BRAZE_EVENT }
