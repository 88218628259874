import { push, replace } from 'react-router-redux'

import Constants from '../constants/action'
import { api } from '../utils/api'
import { setAuthToken, setTempProfilePhoto } from '../views/helpers'
import NoticeActions from './notice'
import translate from '../locales/translate'

function _updateUserPassword(user, data, dispatch) {
  return api
    .request('put', 'b2b/user_passwords', dispatch, data)
    .then(response => {
      setAuthToken(response)
      dispatch(push('/users/profile'))
    })
    .catch(error => {
      throw error
    })
}

export const validResetPasswordToken = (token, email, type) => dispatch => {
  const data = { token }
  api.request('put', 'users/reset_password', dispatch, data).then(
    () => {
      dispatch(
        push({
          pathname: '/set-password',
          query: {
            uniqueIdentifier: email,
            token,
            type,
          },
        })
      )
    },
    error => {
      const usedResetPasswordToken = 401
      const expiredResetPasswordToken = 410

      const state = {
        view: 'reset-password-link-expired',
        email,
      }

      switch (error.response.status) {
        case usedResetPasswordToken: {
          dispatch(
            replace({
              pathname: '/recovery',
              state,
            })
          )
          dispatch(
            NoticeActions.setErrorMessage(
              translate('validations.errors.usedResetPasswordToken')
            )
          ) // this code works only after login, but the scenario in which the user enters this code is the forget password.
          break
        }
        case expiredResetPasswordToken: {
          dispatch(
            replace({
              pathname: '/recovery',
              state,
            })
          )
          dispatch(
            NoticeActions.setErrorMessage(
              translate('validations.errors.expiredResetPasswordToken')
            )
          ) // this code works only after login, but the scenario in which the user enters this code is the forget password.
          break
        }

        default: {
          dispatch(push('/'))
          console.log('VALID RESET PASSWORD TOKEN ERROR', error)
          break
        }
      }
    }
  )
}

const Actions = {
  updateUserLanguage: language => {
    const data = { meta: { language } }
    return dispatch => {
      if (api.loggedIn()) {
        // Store the user language on the user
        api.request('put', 'b2b/users', dispatch, data).then(() => {
          dispatch({
            type: Constants.UPDATE_CURRENT_USER_LANGUAGE,
            data: { meta: { language } },
          })
        })
      } else {
        // Notify the reducer about the language change
        dispatch({
          type: Constants.UPDATE_CURRENT_USER_LANGUAGE,
          data: { meta: { language } },
        })
      }
    }
  },

  // TODO: show error message if fail
  updateUserPassword: (user, data) => dispatch => {
    _updateUserPassword(user, data, dispatch)
  },

  // NOTE: uploads profile image and adds the server image id to the current user
  // sets the temporary photo to the uploaded src img
  uploadProfilePhoto: (user, file, src) => dispatch => {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('type', 0)

    api
      .uploadFile('post', 'images', formData, file.type, dispatch)
      .then(response => {
        const data = { user: { profile_photo_image_id: response.data.id } }
        api.request('put', 'b2b/users', dispatch, data).then(() => {
          setTempProfilePhoto('user', src)
        })
      })
  },
}

export default Actions
