import styled from "styled-components"

import {
  FONT_REGULAR,
  FONT_SIZE,
} from "../../../design/typography/typography.constants"
import { SPACING } from "../../../design/spacing/spacing.constants"
import { COLOR } from "../../../design/color/color.constants"

const PaginationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  margin-top: ${SPACING.M};
`

const PaginationText = styled.p`
  ${FONT_REGULAR}
  font-size: ${FONT_SIZE.MEDIUM};
`

const PaginationButtonsWrapper = styled.div`
  ${PaginationText} ~ & {
    margin-left: ${SPACING.M};
  }
`

const PaginationButtonStyles = `
  position: relative;
  width: 40px;
  height: 40px;
  background-color: ${COLOR.WHITE};
  border: 1px solid ${COLOR.GREY_LIGHT};
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    width: 5px;
    height: 5px;
    top: 50%;
    left: 50%;
    border-top: 2px solid ${COLOR.BLACK};
    border-right: 2px solid ${COLOR.BLACK};
  }

  &:hover {
    background-color: ${COLOR.GREY_LIGHTER};
  }

  &:focus {
    outline: 0;
    background-color: ${COLOR.GREY_LIGHTER};
    box-shadow: inset 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  }

  &:disabled {
    background-color: ${COLOR.GREY_LIGHTER};
    cursor: auto;

    &::after {
      border-top-color: ${COLOR.GREY_MEDIUM};
      border-right-color: ${COLOR.GREY_MEDIUM};
    }
  }
`

const PaginationButtonLeft = styled.button`
  ${PaginationButtonStyles}
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;

  &::after {
    transform: translate(-50%, -50%) rotate(-135deg);
  }
`

const PaginationButtonRight = styled.button`
  ${PaginationButtonStyles}
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  margin-left: -1px;

  &::after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
`

export {
  PaginationWrapper,
  PaginationText,
  PaginationButtonsWrapper,
  PaginationButtonLeft,
  PaginationButtonRight,
}
