import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { useTranslation } from 'react-i18next'

import { getAuthToken } from './auth/tokens'

const SessionSwitchDialog = ({ open }) => {
  const [t] = useTranslation()
  const hasToken = getAuthToken()
  const title = hasToken
    ? t('dialog__title__other_tab_switched_account')
    : t('dialog__title__other_tab_logged_out')
  const body = hasToken
    ? t('dialog__description__other_tab_switched_account')
    : t('dialog__description__other_tab_logged_out')

  return (
    <Dialog
      open={open}
      disableEscapeKeyDown
      sx={{
        '& .MuiPaper-root': {
          width: '400px',
          height: '200px',
          textAlign: 'center',
        },
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{body}</DialogContentText>
      </DialogContent>
    </Dialog>
  )
}

SessionSwitchDialog.propTypes = {
  open: PropTypes.bool,
}

export default SessionSwitchDialog
