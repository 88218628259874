import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { SmallText } from 'ggx-componentlibrary/design/typography/typography.styles'

import {
  OrganizationIcon,
  BellIcon,
  ChevronIcon,
  Menu,
  SubMenu,
  OrgSwitchContainer,
  RowSeparator,
  LanguageSwitch,
  MenuRowPrimary,
  MenuRowSecondary,
  NotificationCount,
  NotificationIconContainer,
} from './footer-menu.component.styles'
import { getOrganizationName } from 'ggx-src/profile/duck/selectors'
import {
  isMasterUser,
  isAdminUser,
  isSubUser,
  getBranches,
  getBranchId,
} from 'ggx-src/users-and-branches/duck/selectors'
import { apiLanguageCode } from 'ggx-src/legacy/locales/conversions'
import { isHongKong, isHongKongEnglish } from 'ggx-src/legacy/utils/locale'
import {
  getNotificatonsUnreadCount,
  getIsNotificationCentreOpen,
} from 'ggx-global/duck/selectors'
import {
  clearMessages,
  getNotifications,
} from 'ggx-src/legacy/actions/notification'
import { toggleNotificationCentre } from 'ggx-src/legacy/actions/header'
import { getFeatureFlagDeveloper } from 'ggx-src/developer/duck/selectors'
import { getFeatureFlag } from 'ggx-service/feature-flag/selectors'
import { OrganizationSwitch } from 'ggx-global/organization-switch/organization-switch.component'
import { apiService } from 'ggx-src/profile/api/api.service'
import { updateUserLanguage } from 'ggx-src/profile/duck/actions'

const FooterMenu = ({
  onMouseEnter,
  onOutsideClick,
  isSubMenuOpen,
  setIsSubMenuOpen,
  subMenuOffset,
  isOrganizationSwitchOpen,
  setIsOrganizationSwitchOpen,
}) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()

  const adminUser = useSelector(state => isAdminUser(state))
  const masterUser = useSelector(state => isMasterUser(state))
  const subUser = useSelector(state => isSubUser(state))
  const branches = useSelector(state => getBranches(state))
  const branchId = useSelector(state => getBranchId(state))
  const currentBranch = branches?.find(branch => branch.id === branchId)
  const branchName = currentBranch?.name

  const unreadCount = useSelector(state => getNotificatonsUnreadCount(state))
  const isNotificationCentreOpen = useSelector(state =>
    getIsNotificationCentreOpen(state)
  )

  const organizationName = useSelector(state => getOrganizationName(state))

  const featureFlagDeveloper = useSelector(state =>
    getFeatureFlagDeveloper(state)
  )
  const featureFlagGGBWebTransportNid = useSelector(state =>
    getFeatureFlag({ state, featureFlag: 'ggb_web_transport_nid' })
  )

  const languageToSwitchTo = isHongKongEnglish()
    ? t('languageToggle.label.zhHK')
    : t('languageToggle.label.enHK')

  useEffect(() => {
    const handleClickOutsideSubMenu = e => {
      const clickedInSubMenu = e.target.closest(
        '[data-testid="navigation-sub-menu"]'
      )
      const clickedInMenuOrSubMenu = e.target.closest(
        '[data-testid="navigation-menu"]'
      )
      const clickedInFooterMenuUserItem = e.target.closest(
        '[data-testid="navigation-bar-footer-menu-user"]'
      )

      if (isSubMenuOpen && !clickedInSubMenu && !clickedInFooterMenuUserItem) {
        setIsSubMenuOpen(false)
      }

      if (isSubMenuOpen && !clickedInMenuOrSubMenu) {
        onOutsideClick()
      }
    }

    document
      .querySelector('#main_container')
      .addEventListener('click', handleClickOutsideSubMenu)
    return () =>
      document
        .querySelector('#main_container')
        .removeEventListener('click', handleClickOutsideSubMenu)
  }, [
    onOutsideClick,
    isSubMenuOpen,
    setIsSubMenuOpen,
    isOrganizationSwitchOpen,
    setIsOrganizationSwitchOpen,
  ])

  const handleShowNotificationCentre = () => {
    dispatch(clearMessages())
    dispatch(getNotifications(1, 8, { featureFlagGGBWebTransportNid }))
    dispatch(toggleNotificationCentre())
  }

  const handleShowOrganizationSwitch = () => {
    setIsOrganizationSwitchOpen(!isOrganizationSwitchOpen)
  }

  const handleSwitchLanguage = () => {
    const newLanguage = apiLanguageCode(isHongKongEnglish() ? 'zh-hk' : 'en-hk')

    apiService.updateUserLanguage(newLanguage).then(() => {
      dispatch(updateUserLanguage(newLanguage))
    })
  }

  return (
    <Menu onMouseEnter={onMouseEnter}>
      <SubMenu
        data-testid="navigation-sub-menu"
        $showSubMenu={isSubMenuOpen}
        $subMenuOffset={subMenuOffset}
      >
        <SubMenu.Row
          to="/profile"
          onClick={() => setIsSubMenuOpen(false)}
          data-testid="user-profile-page-link"
        >
          <SmallText typographyStyles="mb-none c-black">
            {t('myaccount_tab_profile')}
          </SmallText>
        </SubMenu.Row>

        {!subUser && (
          <SubMenu.Row
            to="/users-and-branches"
            onClick={() => setIsSubMenuOpen(false)}
            data-testid="users-and-branches-link"
          >
            <SmallText typographyStyles="mb-none c-black">
              {t('common__users_and_branches')}
            </SmallText>
          </SubMenu.Row>
        )}

        {featureFlagDeveloper && masterUser && (
          <SubMenu.Row
            to="/developer/dashboard"
            onClick={() => setIsSubMenuOpen(false)}
          >
            <SmallText typographyStyles="mb-none c-black">
              {t('common__api_dashboard')}
            </SmallText>
          </SubMenu.Row>
        )}

        {isHongKong() && (
          <LanguageSwitch
            typographyStyles="c-black"
            onClick={handleSwitchLanguage}
            data-testid="language-switch"
          >
            {languageToSwitchTo}
          </LanguageSwitch>
        )}

        <RowSeparator />

        <SubMenu.Row to="/logout" data-testid="logout-button">
          <SmallText typographyStyles="mb-none c-grey_dark">
            {t('common__logout')}
          </SmallText>
        </SubMenu.Row>
      </SubMenu>

      {adminUser && (
        <OrgSwitchContainer $showOrganizationSwitch={isOrganizationSwitchOpen}>
          <OrganizationSwitch />
        </OrgSwitchContainer>
      )}

      {adminUser && (
        <Menu.Row
          onClick={handleShowOrganizationSwitch}
          $isActive={isOrganizationSwitchOpen}
        >
          <OrganizationIcon />
          <div>
            <MenuRowPrimary>{organizationName}</MenuRowPrimary>
            <MenuRowSecondary>{branchName}</MenuRowSecondary>
          </div>
          <ChevronIcon />
        </Menu.Row>
      )}

      <Menu.Row
        onClick={handleShowNotificationCentre}
        $isActive={isNotificationCentreOpen}
        data-testid="navigation-bar-notification-switch"
      >
        <NotificationIconContainer>
          {unreadCount > 0 && (
            <NotificationCount typographyStyles="mb-none">
              {unreadCount}
            </NotificationCount>
          )}
          <BellIcon />
        </NotificationIconContainer>
        <MenuRowPrimary>{t('common__notifications')}</MenuRowPrimary>
        <ChevronIcon />
      </Menu.Row>
    </Menu>
  )
}

FooterMenu.propTypes = {
  onMouseEnter: PropTypes.func,
  onOutsideClick: PropTypes.func,
  isSubMenuOpen: PropTypes.bool,
  setIsSubMenuOpen: PropTypes.func,
  subMenuOffset: PropTypes.number,
  isOrganizationSwitchOpen: PropTypes.bool,
  setIsOrganizationSwitchOpen: PropTypes.func,
}

export { FooterMenu }
