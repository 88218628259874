import styled from "styled-components"

import { SPACING } from "../../../component-library/design/spacing/spacing.constants"
import {
  Body,
  BodyBold,
  TextLink,
} from "../../../component-library/design/typography/typography.styles"
import { FONT_SIZE } from "../../../component-library/design/typography/typography.constants"

const StyledBodyBold = styled(BodyBold)`
  margin: 0 0 ${SPACING.S};
`

const StyledBody = styled(Body)`
  margin-bottom: 0;
`

const InstructionWrapper = styled.div`
  margin-bottom: ${SPACING.S};
`

const BankDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${SPACING.M};
`

const ItemWrapper = styled.div`
  display: flex;
`

const ItemLabel = styled(Body)`
  width: 137px;
  margin-bottom: 0;
  flex-shrink: 0;
`

const ContactDetailsWrapper = styled.div`
  margin-bottom: ${SPACING.M};
`

const StyledTextLink = styled(TextLink)`
  font-size: ${FONT_SIZE.MEDIUM};
`

export {
  StyledBodyBold,
  StyledBody,
  InstructionWrapper,
  BankDetailsWrapper,
  ItemWrapper,
  ItemLabel,
  ContactDetailsWrapper,
  StyledTextLink,
}
