import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'

import { displayAlert } from 'ggx-componentlibrary/components/alerts/alerts.component'
import { Modal } from 'ggx-componentlibrary/components/modal/modal.component'
import { Button } from 'ggx-componentlibrary/components/button/button.component'
import { TextField } from 'ggx-componentlibrary/components/text-field/text-field.component'
import { BodyBold } from 'ggx-componentlibrary/design/typography/typography.styles'

import { requiredField, requiredEmailFormat } from 'ggx-service/validation'

const SendOrderReceiptModal = ({ uuid, handleCancelSendOrderReceipt, sendOrderReceipt }) => {
  const [t] = useTranslation()
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')

  const handleEmailChange = useCallback(value => {
    setEmail(value)
  }, [])

  const handleSendOrderReceipt = async () => {
    const isEmailMissing = requiredField(t('error__email_missing'))
    const isWrongFormatValidator = requiredEmailFormat(t('error__email_format'))
    const errors = [isEmailMissing, isWrongFormatValidator]
      .map(validator => validator(email))
      .filter(Boolean)

    if (isEmpty(errors)) {
      setError('')
      try {
        await sendOrderReceipt(uuid, email)
        displayAlert({ message: t('feedback__email_sent') })
      } catch (error) {
        displayAlert({
          message: t('errors.generic'),
          type: 'error',
        })
      }
      handleCancelSendOrderReceipt()
    } else {
      setError(errors[0])
    }
  }

  return (
    <Modal
      height="initial"
      header={t('share_order_info_via_email')}
      footer={
        <>
          <Button buttonType="secondary" onClick={handleCancelSendOrderReceipt}>
            {t('common__cancel')}
          </Button>
          <Button onClick={handleSendOrderReceipt}>{t('btn__send')}</Button>
        </>
      }
      onRequestClose={handleCancelSendOrderReceipt}
      customStyles={{
        overlay: { zIndex: 1301 },
      }}
      shouldCloseOnEsc
    >
      <BodyBold>{t('enter_an_email')}</BodyBold>
      <TextField
        id="SendOrderReceiptModalEmailInput"
        value={email}
        onChange={handleEmailChange}
        meta={{
          touched: true,
          error: error,
        }}
      />
    </Modal>
  )
}

SendOrderReceiptModal.propTypes = {
  uuid: PropTypes.string,
  handleCancelSendOrderReceipt: PropTypes.func,
  sendOrderReceipt: PropTypes.func,
}

export default SendOrderReceiptModal
