// @flow

const Vehicles: { [key: string]: Array<string> } = {
  HK: ['van', 'mudou', 'mudou9'],
  SG: ['motorcycle', 'sedan', 'van', 'lorry10', 'lorry14', 'lorry24'],
  TW: ['motorcycle', 'van', 'mtruck'],
  VN: [
    'tricycle',
    'van500',
    'van1000',
    'truck500',
    'truck1000',
    'truck1500',
    'truck2000',
    'motorcycle',
  ],
}

export default Vehicles
