import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import session from './session'
import header from './header'
import currentOrderRequest from './current_order_request'
import notification from './notification'
import addresses from './addresses'
import maps from './maps'
import orderRequests from './order_requests'
import organization from './organization'
import notice from './notice'
import routes from './routes'
import currentRoute from './current_route'
import transactions from './transactions'
import deposits from './deposits'
import history from './history'

const appReducer = {
  routing: routerReducer,
  session,
  header,
  currentOrderRequest,
  orderRequests,
  notification,
  addresses,
  maps,
  organization,
  notice,
  routes,
  currentRoute,
  transactions,
  deposits,
  history,
}

export default appReducer
