import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Popover from '@mui/material/Popover'
import Tooltip from '@mui/material/Tooltip'

import { Body } from 'ggx-componentlibrary/design/typography/typography.styles'

import {
  OpenShareMenuText,
  ShareMenuItem,
  StyledBody,
} from './order-sharing.component.styles'
import ShareSVG from '../assets/share.svg'

const OrderSharingMenu = ({
  whatsappURL,
  handleCopyLink,
  handleOpenSendOrderReceiptModal,
  status,
}) => {
  const [t] = useTranslation()
  const [shareMenuAnchor, setShareMenuAnchor] = useState(null)
  const open = Boolean(shareMenuAnchor)
  const id = open ? 'order-sharing-menu' : undefined
  const IsOrdercompleted = status === 'completed'

  const handleOpen = useCallback(e => {
    setShareMenuAnchor(e.currentTarget)
  }, [])
  const handleClose = useCallback(() => {
    setShareMenuAnchor(null)
  }, [])
  const handleEmailButtonClick = useCallback(() => {
    if (!IsOrdercompleted) return
    handleOpenSendOrderReceiptModal()
    setShareMenuAnchor(null)
  }, [])

  return (
    <>
      <OpenShareMenuText onClick={handleOpen}>
        {t('share')}
        <ShareSVG />
      </OpenShareMenuText>
      <Popover
        id={id}
        open={open}
        anchorEl={shareMenuAnchor}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ marginTop: '10px' }}
      >
        <ShareMenuItem onClick={handleCopyLink}>
          <Body typographyStyles="mb-NONE">{t('button__copy_link')}</Body>
        </ShareMenuItem>
        <ShareMenuItem>
          <a href={whatsappURL} target="_blank" rel="noopener noreferrer">
            <Body typographyStyles="mb-NONE">{t('whatsapp')}</Body>
          </a>
        </ShareMenuItem>
        {!IsOrdercompleted ? (
          <Tooltip
            title={t('send_email_after_complete')}
            placement="bottom-start"
          >
            <StyledBody typographyStyles="mb-NONE" disabled={!IsOrdercompleted}>
              {t('common__email')}
            </StyledBody>
          </Tooltip>
        ) : (
          <ShareMenuItem onClick={handleEmailButtonClick}>
            <Body typographyStyles="mb-NONE">{t('common__email')}</Body>
          </ShareMenuItem>
        )}
      </Popover>
    </>
  )
}

OrderSharingMenu.propTypes = {
  whatsappURL: PropTypes.string,
  status: PropTypes.string,
  handleCopyLink: PropTypes.func,
  handleOpenSendOrderReceiptModal: PropTypes.func,
}

export default OrderSharingMenu
