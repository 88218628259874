const payByMonthlySettlement = state =>
  Boolean(
    state?.session?.currentUser?.organization?.payment_method ===
      'monthly_settlement'
  )

const payByCreditCard = state =>
  Boolean(
    state?.session?.currentUser?.organization?.payment_method === 'credit_card'
  )

const getCustomServices = state => state.vanBooking.customServices

const getB2BRegions = state =>
  state.organization &&
  state.organization.regions &&
  state.organization.regions.map(({ id, name }) => ({ id, name }))

const isB2BRegionPricingModel = state =>
  Boolean(
    state.organization &&
      state.organization.organization &&
      state.organization.organization.pricing_type ===
        'region_names_plus_waypoints'
  )

const getOrderQuotation = state => state.vanBooking.orderQuotation

const hasEpod = state =>
  state.session &&
  state.session.currentUser &&
  state.session.currentUser.organization &&
  state.session.currentUser.organization.epod

const getFeatureFlagHourlyRental = state => state.featureFlagHourlyRental

const getFeatureFlagConfigurableRequirement = state =>
  state.featureFlagConfigurableRequirement

const getFeatureFlagFavoriteDrivers = state => state.featureFlagFavoriteDrivers

const getFeatureFlagBulkImport = state => state.featureFlagBulkImport

const getVanBookingBulkImportData = state => state.vanBookingBulkImportTable

const getVanBookingBulkImportQuotation = state =>
  state.vanBookingBulkImportQuotation

const getVanBookingBulkImportQuoteInProgress = state =>
  state?.vanBookingBulkImportQuoteInProgress

const getFeatureFlagFixedMovingFee = state => state?.featureFlagFixedMovingFee

export {
  payByMonthlySettlement,
  payByCreditCard,
  getCustomServices,
  getB2BRegions,
  isB2BRegionPricingModel,
  getOrderQuotation,
  hasEpod,
  getFeatureFlagHourlyRental,
  getFeatureFlagConfigurableRequirement,
  getFeatureFlagFavoriteDrivers,
  getFeatureFlagBulkImport,
  getVanBookingBulkImportData,
  getVanBookingBulkImportQuotation,
  getVanBookingBulkImportQuoteInProgress,
  getFeatureFlagFixedMovingFee,
}
