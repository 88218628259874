import styled from "styled-components"

import { SPACING } from "../../../component-library/design/spacing/spacing.constants"
import { Body } from "../../../component-library/design/typography/typography.styles"

const CancelMessage = styled(Body)`
  margin-bottom: ${SPACING.S};
`

export { CancelMessage }
