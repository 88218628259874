import PropTypes from 'prop-types'
import Dialog from '@mui/material/Dialog'
import { SPACING } from '../../../../component-library/design/spacing/spacing.constants'
import translate from '../../../locales/translate'

import { LoadingSpinner } from './spinner'

const translateWithPrefix = key => translate(`helpers.loading.${key}`)

const LoadingDialogBase = ({ open }) => (
  <Dialog
    disableEscapeKeyDown
    open={open}
    sx={{
      '& .MuiPaper-root': {
        width: '232px',
        paddingTop: SPACING.M,
        paddingBottom: SPACING.XS,
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
      },
    }}
  >
    <LoadingSpinner />
    <p>{translateWithPrefix('loading')}</p>
  </Dialog>
)

const LoadingDialog = LoadingDialogBase

LoadingDialog.propTypes = {
  open: PropTypes.bool,
  classes: PropTypes.object,
}

export { LoadingDialog }
