import { Api } from '../../legacy/utils/api'

const _profileApi = new Api(Api.BASE_URL.API_URL)

const apiService = {
  uploadProfilePhoto: async file => {
    try {
      const method = 'post'
      const endpoint = '/images'
      const data = new FormData()
      data.append('file', file)
      data.append('type', 0)

      const headers = {
        Accept: file.type,
        'Content-Type': file.type,
      }

      const response = await _profileApi.request(
        method,
        endpoint,
        null,
        data,
        headers
      )
      return response.data.id
    } catch (error) {
      throw error
    }
  },

  linkProfilePhotoToUser: async profilePhotoId => {
    try {
      const method = 'put'
      const endpoint = 'b2b/users'
      const data = {
        profile_photo_image_id: profilePhotoId,
      }

      return await _profileApi.request(method, endpoint, null, data)
    } catch (error) {
      throw error
    }
  },

  uploadCompanyPhoto: async (file, organizationId) => {
    try {
      const method = 'post'
      const endpoint = `/b2b/organizations/${organizationId}/upload_image`
      const data = new FormData()
      data.append('file', file)
      data.append('type', 0)
      data.append('id', organizationId)

      const headers = {
        Accept: file.type,
        'Content-Type': file.type,
      }

      const response = await _profileApi.request(
        method,
        endpoint,
        null,
        data,
        headers
      )
      return response.data.id
    } catch (error) {
      throw error
    }
  },

  linkCompanyPhotoToUser: async (companyPhotoId, organizationId) => {
    try {
      const method = 'put'
      const endpoint = `b2b/organizations/${organizationId}`
      const data = {
        organization: {
          profile_photo_image_id: companyPhotoId,
        },
      }

      return await _profileApi.request(method, endpoint, null, data)
    } catch (error) {
      throw error
    }
  },

  updateCompanyContactData: async (organizationId, payload) => {
    try {
      const method = 'put'
      const endpoint = `b2b/organizations/${organizationId}`
      const data = {
        organization: payload,
      }

      return await _profileApi.request(method, endpoint, null, data)
    } catch (error) {
      throw error
    }
  },

  updateUserDataAndPassword: async data => {
    try {
      const method = 'put'
      const endpoint = '/b2b/users'
      return await _profileApi.request(method, endpoint, null, data)
    } catch (error) {
      throw error
    }
  },

  updateUserLanguage: async language => {
    try {
      const method = 'put'
      const endpoint = '/b2b/users'
      const data = {
        meta: { language },
      }

      return await _profileApi.request(method, endpoint, null, data)
    } catch (error) {
      throw error
    }
  },

  removeProfilePhoto: async () => {
    try {
      const method = 'put'
      const endpoint = 'b2b/users'
      const data = {
        profile_photo_image_id: null,
      }

      return await _profileApi.request(method, endpoint, null, data)
    } catch (error) {
      throw error
    }
  },

  removeCompanyPhoto: async organizationId => {
    try {
      const method = 'put'
      const endpoint = `b2b/organizations/${organizationId}`
      const data = {
        organization: {
          profile_photo_image_id: null,
        },
      }

      return await _profileApi.request(method, endpoint, null, data)
    } catch (error) {
      throw error
    }
  },
}

export { apiService, _profileApi }
