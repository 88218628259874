import Route from 'react-router/lib/Route'

import { loadableCreator } from 'ggx-global/loading/loadable-creator'
import Actions from 'ggx-src/legacy/actions/session'

const LoadableProfile = loadableCreator({
  dynamicImport: () =>
    import(/* webpackChunkName: 'users' */ './profile.component'),
  componentName: 'Profile',
})

const ProfileRoutes = store => (
  <Route
    path="profile"
    component={LoadableProfile}
    onEnter={(_nextState, replace, callback) => {
      store.dispatch(Actions.currentUser()).finally(callback)
    }}
  />
)

export { ProfileRoutes }
