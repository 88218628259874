import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { useTranslation } from 'react-i18next'

import { Button } from 'ggx-componentlibrary/components/button/button.component'
import { COLOR } from 'ggx-componentlibrary/design/color/color.constants'
import { SPACING } from 'ggx-componentlibrary/design/spacing/spacing.constants.js'
import { Heading } from 'ggx-componentlibrary/design/typography/typography.styles'
import IconExternalLink from 'ggx-src/assets/external_link.svg'
import { TNCLink, ContentText } from './TNCConfirmDialog.styles'


const TNCConfirmDialog = ({ open, actions }) => {
  const [t] = useTranslation()

  return (
    <Dialog
      open={open}
      disableEscapeKeyDown
      sx={{
        '& .MuiPaper-root': {
          width: '600px',
          '> .MuiDialogTitle-root': {
            borderBottom: `1px solid ${COLOR.GREY_LIGHT}`,
          },
          '> .MuiDialogContent-root': {
            padding: SPACING.M,
          },
          '> .MuiDialogActions-root': {
            borderTop: `1px solid ${COLOR.GREY_LIGHT}`,
            padding: `${SPACING.S} ${SPACING.M}`,
          },
          '> .MuiDialogActions-root button': {
            marginLeft: `${SPACING.S}`
          }
        },
      }}
    >
      <DialogTitle>
        <Heading>{t('vietnam_new_tnc__title')}</Heading>
      </DialogTitle>
      <DialogContent>
        <ContentText>{t('vietnam_new_tnc__description_1')}</ContentText>
        <ContentText>{t('vietnam_new_tnc__description_2')}</ContentText>
        <ContentText>{t('vietnam_new_tnc__description_3')}</ContentText>
        <TNCLink href={t('url__vietnam_terms_of_service')} target="_blank">
          {t('vietnam_new_tnc__view_now')}
          <IconExternalLink />
        </TNCLink>
      </DialogContent>
      <DialogActions>
        <Button onClick={actions.logout} buttonType="secondary">
          {t('common__logout')}
        </Button>
        <Button onClick={actions.updateUserConsentTimestamp}>
          {t('vietnam_new_tnc__agree')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

TNCConfirmDialog.propTypes = {
  open: PropTypes.bool,
  actions: PropTypes.shape({
    logout: PropTypes.func,
    updateUserConsentTimestamp: PropTypes.func,
  }),
}

export default TNCConfirmDialog
