import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import {
  PickupPinIcon,
  DropoffPinIcon,
} from '../../../component-library/components/waypoint/waypoint.styles'
import { Body } from '../../../component-library/design/typography/typography.styles'
import {
  WaypointContainer,
  WaypointInformation,
  MorePointsIcon,
} from './route-list.component.styles'

const RouteList = ({ rowData }) => {
  const [t] = useTranslation()

  const firstWaypoint = rowData.waypoints[0] || {}
  const lastWaypoint = rowData.waypoints[rowData.waypoints.length - 1] || {}
  const additionalWaypointCount = rowData.waypoints.length - 2
  const additionalWaypointText = `+ ${additionalWaypointCount} ${t(
    'common__waypoint'
  )}`

  return (
    <>
      <WaypointContainer>
        <PickupPinIcon />
        <WaypointInformation>
          <Body noMargin>
            {firstWaypoint.detail_address} {firstWaypoint.address}
          </Body>
        </WaypointInformation>
      </WaypointContainer>
      {additionalWaypointCount > 0 && (
        <WaypointContainer>
          <MorePointsIcon />
          {additionalWaypointText}
        </WaypointContainer>
      )}
      <WaypointContainer>
        <DropoffPinIcon />
        <WaypointInformation>
          <Body noMargin>
            {lastWaypoint.detail_address} {lastWaypoint.address}
          </Body>
        </WaypointInformation>
      </WaypointContainer>
    </>
  )
}

RouteList.propTypes = {
  rowData: PropTypes.objectOf({
    waypoints: PropTypes.arrayOf(PropTypes.object),
  }),
}

export { RouteList }
