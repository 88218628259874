import styled from "styled-components"

import { SPACING } from "../../../component-library/design/spacing/spacing.constants"
import {
  Body,
  BodyBold,
} from "../../../component-library/design/typography/typography.styles"

const DROPDOWN_MAXHEIGHT = 245
const DROPDOWN_WIDTH = "273px"
const CREDIT_CARD_GROUP_WIDTH = "397px"

const DropdownContainer = styled.div`
  width: ${DROPDOWN_WIDTH};
`

const StyledBody = styled(BodyBold)`
  margin: ${SPACING.M} 0 ${SPACING.S};
`

const PaymentOptionHeading = styled(Body)`
  margin-bottom: ${SPACING.S};
`

export {
  DropdownContainer,
  StyledBody,
  PaymentOptionHeading,
  DROPDOWN_MAXHEIGHT,
  CREDIT_CARD_GROUP_WIDTH,
}
