import styled from "styled-components"

import { Modal } from "ggx-componentlibrary/components/modal/modal.component"
import { Content } from "ggx-componentlibrary/components/modal/modal.component.styles"
import { COLOR } from "ggx-componentlibrary/design/color/color.constants"
import DeliveryOnboardingSVG from "../assets/delivery-onboarding-logo.svg"
import TransportOnboardingSVG from "../assets/transport-onboarding-logo.svg"
import { SPACING } from "ggx-componentlibrary/design/spacing/spacing.constants"

const DeliveryIcon = styled(DeliveryOnboardingSVG)``
const TransportIcon = styled(TransportOnboardingSVG)``

const StyledModal = styled(Modal)`
  ${Content} {
    padding: 0;
    margin-top: 0;
  }
`

const Container = styled.div`
  display: flex;
  height: 100%;
`

const GreetingCard = styled.div`
  width: 42%;
  padding: ${SPACING.L};

  display: flex;
  flex-direction: column;
  justify-content: center;
`

const ServicesCard = styled.div`
  width: 58%;
  background-color: ${COLOR.GREY_LIGHTEST};
  display: flex;
  justify-content: space-around;
  align-items: center;
`

const Service = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const TourGreetingCard = styled.div`
  width: 424px;
  padding: ${SPACING.L};

  display: flex;
  flex-direction: column;
  justify-content: center;
`

const TourImageCard = styled.div`
  width: 318px;
  background-color: ${COLOR.GREY_LIGHTEST};
  display: flex;
  justify-content: space-around;
  align-items: end;
`

const StyledImg = styled.img`
  width: 200px;
`

export {
  StyledModal,
  Container,
  GreetingCard,
  ServicesCard,
  DeliveryIcon,
  TransportIcon,
  Service,
  TourGreetingCard,
  TourImageCard,
  StyledImg,
}
