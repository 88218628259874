import styled from "styled-components"
import Link from "react-router/lib/Link"

import { COLOR } from "ggx-componentlibrary/design/color/color.constants"
import { SPACING } from "../../component-library/design/spacing/spacing.constants"
import {
  COLLAPSED_WIDTH,
  EXPANDED_WIDTH,
  COLLAPSED_TRANSATION_DURATION,
  EXPANDED_TRANSATION_DURATION,
} from "./navigation.constants"
import {
  FONT_REGULAR,
  FONT_SIZE,
  LINE_HEIGHT,
  FONT_BOLD,
} from "ggx-componentlibrary/design/typography/typography.constants"
import {
  BodyBold,
  SmallText,
  SmallTextBold,
} from "ggx-componentlibrary/design/typography/typography.styles"
import { ContentBox } from "ggx-componentlibrary/components/tooltip/tooltip.component.styles"
import Logo from "./assets/gogobusiness-logo.svg"
import CollapseSVG from "./assets/collapse_icon.svg"
import ExpandSVG from "./assets/expand_icon.svg"
import TopupSVG from "./assets/top_up.svg"
import HomeSVG from "./assets/home.svg"
import HomeActiveSVG from "./assets/home-active.svg"
import PlaceOrderSVG from "./assets/place-order.svg"
import PlaceOrderActiveSVG from "./assets/place-order-active.svg"
import OrderManagementSVG from "./assets/order-management.svg"
import OrderManagementActiveSVG from "./assets/order-management-active.svg"
import FavoriteDriverSVG from "./assets/favorite-driver.svg"
import FavoriteDriverActiveSVG from "./assets/favorite-driver-active.svg"
import BulkOrderSVG from "./assets/bulk-order.svg"
import BulkOrderActiveSVG from "./assets/bulk-order-active.svg"
import PaymentMethodSVG from "./assets/payment_method.svg"
import PaymentMethodActiveSVG from "./assets/payment_method-active.svg"
import TransactionHistorySVG from "./assets/transaction_history.svg"
import TransactionHistoryActiveSVG from "./assets/transaction_history-active.svg"
import EcommerceIntegrationSVG from "./assets/ecommerce-integration.svg"
import EcommerceIntegrationActiveSVG from "./assets/ecommerce-integration-active.svg"
import CouponSVG from "./assets/coupon.svg"
import CouponActiveSVG from "./assets/coupon-active.svg"

const ROW_HEIGHT = "40px"

const STYLES_FOR_SIDEBAR_ICON = (isActive) => `
  display: ${isActive ? "none" : "block"};
  flex-shrink: 0;
`

const STYLES_FOR_SIDEBAR_ACTIVE_ICON = (isActive) => `
  display: ${isActive ? "block" : "none"};
  flex-shrink: 0;
`

const CollapseIcon = styled(CollapseSVG)``
const ExpandIcon = styled(ExpandSVG)``
const TopupIcon = styled(TopupSVG)``
const HomeIcon = styled(HomeSVG)`
  ${(props) => STYLES_FOR_SIDEBAR_ICON(props.$isActive)}
`
const HomeActiveIcon = styled(HomeActiveSVG)`
  ${(props) => STYLES_FOR_SIDEBAR_ACTIVE_ICON(props.$isActive)}
`
const PlaceOrderIcon = styled(PlaceOrderSVG)`
  ${(props) => STYLES_FOR_SIDEBAR_ICON(props.$isActive)}
`
const PlaceOrderActiveIcon = styled(PlaceOrderActiveSVG)`
  ${(props) => STYLES_FOR_SIDEBAR_ACTIVE_ICON(props.$isActive)}
`
const OrderManagementIcon = styled(OrderManagementSVG)`
  ${(props) => STYLES_FOR_SIDEBAR_ICON(props.$isActive)}
`
const OrderManagementActiveIcon = styled(OrderManagementActiveSVG)`
  ${(props) => STYLES_FOR_SIDEBAR_ACTIVE_ICON(props.$isActive)}
`
const FavoriteDriverIcon = styled(FavoriteDriverSVG)`
  ${(props) => STYLES_FOR_SIDEBAR_ICON(props.$isActive)}
`
const FavoriteDriverActiveIcon = styled(FavoriteDriverActiveSVG)`
  ${(props) => STYLES_FOR_SIDEBAR_ACTIVE_ICON(props.$isActive)}
`
const BulkOrderIcon = styled(BulkOrderSVG)`
  ${(props) => STYLES_FOR_SIDEBAR_ICON(props.$isActive)}
`
const BulkOrderActiveIcon = styled(BulkOrderActiveSVG)`
  ${(props) => STYLES_FOR_SIDEBAR_ACTIVE_ICON(props.$isActive)}
`
const PaymentMethodIcon = styled(PaymentMethodSVG)`
  ${(props) => STYLES_FOR_SIDEBAR_ICON(props.$isActive)}
`
const PaymentMethodActiveIcon = styled(PaymentMethodActiveSVG)`
  ${(props) => STYLES_FOR_SIDEBAR_ACTIVE_ICON(props.$isActive)}
`
const TransactionHistoryIcon = styled(TransactionHistorySVG)`
  ${(props) => STYLES_FOR_SIDEBAR_ICON(props.$isActive)}
`
const TransactionHistoryActiveIcon = styled(TransactionHistoryActiveSVG)`
  ${(props) => STYLES_FOR_SIDEBAR_ACTIVE_ICON(props.$isActive)}
`
const EcommerceIntegrationIcon = styled(EcommerceIntegrationSVG)`
  ${(props) => STYLES_FOR_SIDEBAR_ICON(props.$isActive)}
`
const EcommerceIntegrationActiveIcon = styled(EcommerceIntegrationActiveSVG)`
  ${(props) => STYLES_FOR_SIDEBAR_ACTIVE_ICON(props.$isActive)}
`
const CouponIcon = styled(CouponSVG)`
  ${(props) => STYLES_FOR_SIDEBAR_ICON(props.$isActive)}
`
const CouponActiveIcon = styled(CouponActiveSVG)`
  ${(props) => STYLES_FOR_SIDEBAR_ACTIVE_ICON(props.$isActive)}
`

const NavigationContainer = styled.nav`
  position: absolute;
  z-index: 5;
  width: ${(props) => {
    if (props.$isNavigationHovered) return EXPANDED_WIDTH
    return props.$isNavigationCollapsed ? COLLAPSED_WIDTH : EXPANDED_WIDTH
  }};
  height: 100vh;

  display: flex;
  flex-direction: column;

  will-change: width;
  transition-property: width;
  transition-duration: ${(props) => {
    if (props.$isNavigationHovered) return EXPANDED_TRANSATION_DURATION
    return props.$isNavigationCollapsed
      ? COLLAPSED_TRANSATION_DURATION
      : EXPANDED_TRANSATION_DURATION
  }};
  transition-timing-function: ${(props) => {
    if (props.$isNavigationHovered) return "ease-out"
    return props.$isNavigationCollapsed ? "ease-in" : "ease-out"
  }};

  background: ${COLOR.GREY_LIGHTEST};
  border-right: 1px solid ${COLOR.GREY_LIGHT};
`

const NavigationInnerContainer = styled.div`
  position: relative;
  width: 100%;
  flex-grow: 1;
  padding-bottom: 130px;

  overflow-x: hidden;
  overflow-y: auto;
`

const CollapseButtonHint = styled(SmallText)`
  color: ${COLOR.WHITE};
  background-color: ${COLOR.BLACK};
  position: absolute;
  top: 0;
  left: 32px;
  margin: 0;
  padding: ${SPACING.XXS} ${SPACING.XS};
  pointer-events: none;
  opacity: 0;
  width: max-content;
  border-radius: ${SPACING.XXS};
  transition: opacity 200ms ease-in-out;
`

const CollapseButtonContainer = styled.div`
  position: absolute;
  width: ${SPACING.XL2};
  height: ${SPACING.XL2};
  top: 7px;
  right: -24px;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  z-index: 1;
`

const CollapseButton = styled.div`
  width: ${SPACING.M};
  height: ${SPACING.M};

  background: ${COLOR.GREY_LIGHTEST};
  border-radius: 4px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  z-index: 1;
  position: relative;
  :hover {
    ${CollapseButtonHint} {
      opacity: 1;
    }
  }
`

const LogoContainer = styled(Link)`
  flex-shrink: 0;
  overflow-x: hidden;
`

const GogobusinessLogo = styled(Logo)`
  margin: ${SPACING.M};
  opacity: ${(props) => {
    if (props.$isNavigationHovered) return 1
    return props.$isNavigationCollapsed ? 0 : 1
  }};

  transition: opacity
    ${(props) => {
      if (props.$isNavigationHovered) return EXPANDED_TRANSATION_DURATION
      return props.$isNavigationCollapsed
        ? COLLAPSED_TRANSATION_DURATION
        : EXPANDED_TRANSATION_DURATION
    }};
`

const Label = styled.span`
  ${FONT_REGULAR}
  font-size: ${FONT_SIZE.SMALL};
  line-height: ${LINE_HEIGHT.SMALL};
  color: ${COLOR.GREY_DARKER};
  margin-left: ${SPACING.S};
  text-decoration: none;
`

const Row = styled(Link)`
  position: relative;
  display: flex;
  justify-content: start;
  align-items: center;
  height: ${ROW_HEIGHT};
  padding: 0 ${SPACING.S};
  width: ${EXPANDED_WIDTH};
  text-decoration: none;
  ${(props) => props.$isActive && `background-color: ${COLOR.GREY_LIGHTER};`}

  ${Label} {
    ${(props) => props.$isActive && FONT_BOLD}
  }

  :hover {
    background-color: ${COLOR.GREY_LIGHTER};

    ${Label} {
      color: ${COLOR.BLUE_MEDIUM};
    }
    ${HomeIcon} {
      display: none;
    }
    ${HomeActiveIcon} {
      display: block;
    }
    ${PlaceOrderIcon} {
      display: none;
    }
    ${PlaceOrderActiveIcon} {
      display: block;
    }
    ${OrderManagementIcon} {
      display: none;
    }
    ${OrderManagementActiveIcon} {
      display: block;
    }
    ${FavoriteDriverIcon} {
      display: none;
    }
    ${FavoriteDriverActiveIcon} {
      display: block;
    }
    ${BulkOrderIcon} {
      display: none;
    }
    ${BulkOrderActiveIcon} {
      display: block;
    }
    ${PaymentMethodIcon} {
      display: none;
    }
    ${PaymentMethodActiveIcon} {
      display: block;
    }
    ${TransactionHistoryIcon} {
      display: none;
    }
    ${TransactionHistoryActiveIcon} {
      display: block;
    }
    ${EcommerceIntegrationIcon} {
      display: none;
    }
    ${EcommerceIntegrationActiveIcon} {
      display: block;
    }
    ${CouponIcon} {
      display: none;
    }
    ${CouponActiveIcon} {
      display: block;
    }
  }
`

const RowSection = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 0 ${SPACING.S};
  height: ${ROW_HEIGHT};
  width: ${EXPANDED_WIDTH};
  opacity: ${(props) => {
    if (props.$isNavigationHovered) return 1
    return props.$isNavigationCollapsed ? 0 : 1
  }};

  transition: opacity
    ${(props) => {
      if (props.$isNavigationHovered) return EXPANDED_TRANSATION_DURATION
      return props.$isNavigationCollapsed
        ? COLLAPSED_TRANSATION_DURATION
        : EXPANDED_TRANSATION_DURATION
    }};
`

const RowSectionSeparatorWrapper = styled.div`
  position: relative;
`

const RowSectionSeparator = styled.div`
  opacity: ${(props) => {
    if (!props.$isNavigationCollapsed) return 0
    return props.$isNavigationHovered ? 0 : 1
  }};
  transition: opacity
    ${(props) => {
      if (!props.$isNavigationCollapsed) return COLLAPSED_TRANSATION_DURATION
      return props.$isNavigationHovered
        ? COLLAPSED_TRANSATION_DURATION
        : EXPANDED_TRANSATION_DURATION
    }};

  &::after {
    content: "";
    position: absolute;
    width: 24px;
    height: 16px;
    top: -16px;
    left: 16px;
    border-top: 1px solid ${COLOR.GREY_MEDIUM};
  }
`

const RowTopupButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: ${SPACING.XS} 0px ${SPACING.XL} ${SPACING.S};
  margin-top: ${SPACING.S};
`

const TopupButtonAmount = styled(BodyBold)`
  color: ${COLOR.GREY_DARKER};
  margin-bottom: 0;
  transition: color 0.1s;
`

const TopupButtonLabel = styled(SmallText)`
  color: ${COLOR.GREY_DARKER};
  margin-bottom: 0;
  transition: color 0.1s;
`

const TopupButton = styled.a`
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px ${SPACING.S};
  background-color: ${COLOR.WHITE};
  border: 1px solid ${COLOR.GREY_LIGHT};
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;
  width: 160px;
  transition: background-color 0.1s,
    opacity
      ${(props) => {
        if (props.$isNavigationHovered) return EXPANDED_TRANSATION_DURATION
        return props.$isNavigationCollapsed
          ? COLLAPSED_TRANSATION_DURATION
          : EXPANDED_TRANSATION_DURATION
      }};
  opacity: ${(props) => {
    if (props.$isNavigationHovered) return 1
    return props.$isNavigationCollapsed ? 0 : 1
  }};

  &:hover {
    background: ${COLOR.GREY_LIGHTER};

    ${TopupButtonAmount} {
      color: ${COLOR.BLACK};
    }

    ${TopupButtonLabel} {
      color: ${COLOR.BLUE_MEDIUM};
    }
  }
`

const CouponBadge = styled.span`
  margin-left: auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 ${SPACING.XXS};
  background-color: ${COLOR.GREY_DARK};
  border-radius: 2px;
  ${FONT_BOLD};
  & > * {
    color: ${COLOR.WHITE};
    transform: scale(0.83333);
  }
`
const AdaptiveMenuBlock = styled.div`
  position: relative;
  & > *:first-child {
    opacity: ${(props) => {
      if (props.$isNavigationHovered) return 1
      return props.$isNavigationCollapsed ? 0 : 1
    }};
  
    transition: opacity
      ${(props) => {
        if (props.$isNavigationHovered) return EXPANDED_TRANSATION_DURATION
        return props.$isNavigationCollapsed
          ? COLLAPSED_TRANSATION_DURATION
          : EXPANDED_TRANSATION_DURATION
      }};
  }
  & > *:nth-child(2) {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
    opacity: ${(props) => {
      if (props.$isNavigationHovered) return 0
      return !props.$isNavigationCollapsed ? 0 : 1
    }};
    transition: opacity
      ${(props) => {
        if (!props.$isNavigationHovered) return EXPANDED_TRANSATION_DURATION
        return !props.$isNavigationCollapsed
          ? COLLAPSED_TRANSATION_DURATION
          : EXPANDED_TRANSATION_DURATION
      }};
  }
`

const UserInfoContainer = styled.div`
  white-space: nowrap;
  margin: ${SPACING.S};
  background: ${COLOR.WHITE};
  border-radius: 6px;
  & ${ContentBox} {
    right: -12px;
    bottom: 100%;
  }
  & > *:nth-child(2) {
    border-top: 1px solid ${COLOR.GREY_LIGHT};
  }
`
const UserInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${SPACING.XS} 12px;
  border-radius: 6px;
  cursor: pointer;
  &:hover {
    ${SmallTextBold} {
      color: ${COLOR.BLUE_MEDIUM};
    }
    path {
      stroke: ${COLOR.BLUE_MEDIUM};
    }
  }
  
  div {
    width: calc(100% - 24px);
  }
  div > ${SmallTextBold}, ${SmallText} {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`
const BalanceInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${SPACING.XS} 12px;
  cursor: pointer;
  width: 100%;
  & path:nth-child(odd) {
    fill: ${COLOR.BLUE_MEDIUM};
    fill-opacity: 1;
  }
  & path:nth-child(even) {
    stroke: ${COLOR.BLUE_MEDIUM};
    stroke-opacity: 1;
  }
  &:hover > *:first-child {
    color: ${COLOR.BLUE_MEDIUM};
  }
`
const CollapseUserInfoContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  padding: ${SPACING.XS} ${SPACING.S};
`

const ProfilePhoto = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;
  background-image: ${(props) => `url(${props.$profilePhotoSrc})`};
  background-size: cover;
  background-position: center center;
  margin: 0 0 28px;
  flex-shrink: 0;
`

export {
  NavigationContainer,
  NavigationInnerContainer,
  CollapseButtonContainer,
  CollapseButton,
  LogoContainer,
  GogobusinessLogo,
  Row,
  RowSection,
  RowSectionSeparatorWrapper,
  RowSectionSeparator,
  RowTopupButton,
  Label,
  TopupButton,
  TopupButtonAmount,
  TopupButtonLabel,
  CollapseIcon,
  ExpandIcon,
  TopupIcon,
  HomeIcon,
  HomeActiveIcon,
  PlaceOrderIcon,
  PlaceOrderActiveIcon,
  OrderManagementIcon,
  OrderManagementActiveIcon,
  FavoriteDriverIcon,
  FavoriteDriverActiveIcon,
  BulkOrderIcon,
  BulkOrderActiveIcon,
  PaymentMethodIcon,
  PaymentMethodActiveIcon,
  TransactionHistoryIcon,
  TransactionHistoryActiveIcon,
  EcommerceIntegrationIcon,
  EcommerceIntegrationActiveIcon,
  CouponIcon,
  CouponActiveIcon,
  CollapseButtonHint,
  CouponBadge,
  AdaptiveMenuBlock,
  UserInfoContainer,
  UserInfo,
  BalanceInfo,
  CollapseUserInfoContainer,
  ProfilePhoto,
}
