import styled from "styled-components"

import { SPACING } from "../component-library/design/spacing/spacing.constants"
import { Body } from "../component-library/design/typography/typography.styles"

const VanBookingContainer = styled.section`
  padding: ${SPACING.M} ${SPACING.L};
`

const HeadingSection = styled.div`
  padding-bottom: ${SPACING.M};
`

const StyledDescription = styled(Body)`
  margin-bottom: 0;
`

export { VanBookingContainer, HeadingSection, StyledDescription }
