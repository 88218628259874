import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Drawer from '@mui/material/Drawer'

import DrawerNotificationTitle from '../drawer-title/drawer-title.component'
import { toggleNotificationCentre } from '../../../legacy/actions/header'
import { LoadingSpinner } from '../../../legacy/views/helpers'
import MessagesCentreContainer from '../../../legacy/containers/messages_centre'
import { NotificationsContainer } from './list.component.styles'

const NotificationList = ({
  messages,
  onCentreClick,
  openCentre,
  isLoading,
}) => {
  const renderMessagesCentre =
    isLoading && messages.length === 0 ? (
      <LoadingSpinner style={{ display: 'block', margin: '10px auto' }} />
    ) : (
      <MessagesCentreContainer messages={messages} />
    )

  return (
    <Drawer
      open={openCentre}
      anchor="right"
      variant="persistent"
      data-testid="notification-center"
      sx={{
        '& .MuiPaper-root': {
          padding: 0,
          width: 352,
          boxShadow:
            'rgb(0 0 0 / 16%) 0px 3px 10px, rgb(0 0 0 / 23%) 0px 3px 10px',
          zIndex: 1201, // Drawer component default z-index is 1200
        },
      }}
    >
      <NotificationsContainer>
        <DrawerNotificationTitle onClick={onCentreClick} />
        {renderMessagesCentre}
      </NotificationsContainer>
    </Drawer>
  )
}

NotificationList.propTypes = {
  onCentreClick: PropTypes.func.isRequired,
  openCentre: PropTypes.bool.isRequired,
  messages: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({
  isLoading: state.notification.isLoading,
})

const mapDispatchToProps = dispatch => ({
  onCentreClick: () => {
    dispatch(toggleNotificationCentre())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(NotificationList)
