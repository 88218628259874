import { useState } from 'react'
import { useSelector } from 'react-redux'

import { TopupCallout } from './topup-callout/topup-callout.component'
import { OrderManagementCallout } from './order-management-callout/order-management-callout.component'
import { TransactionHistoryCallout } from './transaction-history-callout/transaction-history-callout.component'
import { UserManagementCallout } from './user-management-callout/user-management-callout.component'
import { isHongKong } from '../../legacy/utils/locale'
import { payByMonthlySettlement } from '../../van-booking/duck/selectors'
import { isSubUser as isSubUserSelector } from '../../users-and-branches/duck/selectors'

const CALLOUT = {
  TOPUP: 'TOPUP',
  ORDER_MANAGEMENT: 'ORDER_MANAGEMENT',
  TRANSACTION_HISTORY: 'TRANSACTION_HISTORY',
  USER_MANAGEMENT: 'USER_MANAGEMENT',
}

const FeatureTour = () => {
  const isMonthlySettlement = useSelector(state =>
    payByMonthlySettlement(state)
  )
  const isSubUser = useSelector(state => isSubUserSelector(state))
  const isMontlySettlementAndNoneHK = !isHongKong() && isMonthlySettlement
  const [callout, setCallout] = useState(
    (isMontlySettlementAndNoneHK && CALLOUT.ORDER_MANAGEMENT) || CALLOUT.TOPUP
  )
  const [currentStep, setCurrentStep] = useState(1)
  const deductStepForSubUser = isSubUser ? 2 : 0
  const maxStep = (isMontlySettlementAndNoneHK ? 3 : 4) - deductStepForSubUser

  const handleSkip = () => {
    setCallout(null)
  }

  const handleNextOrderManagement = () => {
    if (isSubUser) {
      setCallout(null)
    } else {
      setCurrentStep(prev => prev + 1)
      setCallout(CALLOUT.TRANSACTION_HISTORY)
    }
  }

  return (
    <>
      {callout === CALLOUT.TOPUP && (
        <TopupCallout
          handleNext={() => {
            setCurrentStep(prev => prev + 1)
            setCallout(CALLOUT.ORDER_MANAGEMENT)
          }}
          handleSkip={handleSkip}
          stepText={`${currentStep} / ${maxStep}`}
        />
      )}
      {callout === CALLOUT.ORDER_MANAGEMENT && (
        <OrderManagementCallout
          handleNext={handleNextOrderManagement}
          handleSkip={handleSkip}
          stepText={`${currentStep} / ${maxStep}`}
        />
      )}
      {callout === CALLOUT.TRANSACTION_HISTORY && (
        <TransactionHistoryCallout
          handleNext={() => {
            setCurrentStep(prev => prev + 1)
            setCallout(CALLOUT.USER_MANAGEMENT)
          }}
          handleSkip={handleSkip}
          stepText={`${currentStep} / ${maxStep}`}
        />
      )}
      {callout === CALLOUT.USER_MANAGEMENT && (
        <UserManagementCallout
          handleSkip={handleSkip}
          stepText={`${currentStep} / ${maxStep}`}
        />
      )}
    </>
  )
}

export { FeatureTour }
