import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { OrganizationSwitch } from 'ggx-global/organization-switch/organization-switch.component'
import {
  getBranches,
  getBranchId,
} from 'ggx-src/users-and-branches/duck/selectors'

import { Label } from '../navigation.component.styles'

import {
  ChevronIcon,
  OrganizationRow,
  OrgSwitchContainer,
  TextWrapper,
} from './admin.component.styles'
import OrganizationSVG from './assets/organization.svg'

const AdminSection = ({
  organizationName,
  isOrganizationSwitchOpen,
  setIsOrganizationSwitchOpen,
  isNavigationCollapsed,
  isNavigationHovered,
}) => {
  const branches = useSelector(state => getBranches(state))
  const branchId = useSelector(state => getBranchId(state))
  const currentBranch = branches?.find(branch => branch.id === branchId)
  const branchName = currentBranch?.name

  const handleShowOrganizationSwitch = () => {
    setIsOrganizationSwitchOpen(!isOrganizationSwitchOpen)
  }

  return (
    <>
      <OrgSwitchContainer $showOrganizationSwitch={isOrganizationSwitchOpen}>
        <OrganizationSwitch />
      </OrgSwitchContainer>
      <OrganizationRow
        onClick={handleShowOrganizationSwitch}
        $isActive={isOrganizationSwitchOpen}
        $isNavigationCollapsed={isNavigationCollapsed}
        $isNavigationHovered={isNavigationHovered}
      >
        <OrganizationSVG />
        <TextWrapper>
          <Label>{organizationName}</Label>
          <Label>{branchName}</Label>
        </TextWrapper>
        <ChevronIcon />
      </OrganizationRow>
    </>
  )
}

AdminSection.propTypes = {
  organizationName: PropTypes.string,
  isOrganizationSwitchOpen: PropTypes.bool,
  setIsOrganizationSwitchOpen: PropTypes.func,
  isNavigationCollapsed: PropTypes.bool,
  isNavigationHovered: PropTypes.bool,
}

export default AdminSection
