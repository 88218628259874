import * as React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { appStore } from '../store'

const { RELEASE_STAGE } = process.env
const { APP_VERSION } = process.env
const { BUGSNAG_KEY } = process.env

Bugsnag.start({
  apiKey: BUGSNAG_KEY,
  appVersion: APP_VERSION,
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ['stag', 'prod'],
  releaseStage: RELEASE_STAGE,
  metadata: {
    region: ENV.GOGOVAN_COUNTRY,
  },
  onError: event => {
    const state = appStore.getStore() ? appStore.getState() : null
    const currentUser = state && state.session && state.session.currentUser
    const userId = currentUser ? currentUser.id : 'anonymous'
    const organizationId =
      userId === 'anonymous'
        ? null
        : currentUser.organization && currentUser.organization.id

    event.addMetadata('user', {
      userId,
      organizationId,
    })
  },
})

export const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(
  React
)
