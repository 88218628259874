import * as types from './types'

const initialState = false
const creditBalanceReducerInitialState = {
  credit: 0,
  freeCredit: 0,
  freeCreditExpiryDate: '',
  unsettledBalance: 0,
}

function creditBalanceReducer(
  state = creditBalanceReducerInitialState,
  action = {}
) {
  switch (action.type) {
    case types.SET_CREDIT_BALANCE:
      return {
        ...state,
        credit: action.credit,
        freeCredit: action.freeCredit,
        freeCreditExpiryDate: action.freeCreditExpiryDate,
        unsettledBalance: action.unsettledBalance,
      }
    default:
      return state
  }
}

function prepaidTopupBankTransferOnlyReducer(
  state = initialState,
  action = {}
) {
  switch (action.type) {
    case types.FEATUREFLAG_PREPAID_TOPUP_BANK_TRANSFER_ONLY:
      return typeof action.value === 'boolean' ? action.value : initialState

    default:
      return state
  }
}

const billingReducers = {
  creditBalance: creditBalanceReducer,
  featureFlagPrepaidTopupBankTransferOnly: prepaidTopupBankTransferOnlyReducer,
}

export { billingReducers }
