import { ADDRESS_TYPES } from '../place-order/order-form/pickup-form/address-type-form/address-type-form.constants'
import * as types from './types'

const initialValues = {
  sender_address: '',
  sender_floor: '',
  sender_name: '',
  sender_phone_number: '',
  service_provider: 'alfred',
  address_type: ADDRESS_TYPES.COMMERCIAL,
  packages_attributes: [],
  price_breakdown: [],
  meta_data: {},
}

function NDDReducer(state = initialValues, action = {}) {
  switch (action.type) {
    case types.SET_REUSE_FORM:
      return {
        sender_address: action.payload.sender_address,
        sender_floor: action.payload.sender_floor,
        sender_name: action.payload.sender_name,
        sender_phone_number: action.payload.sender_phone_number,
        address_type: action.payload.address_type,
        service_provider: 'alfred',
        packages_attributes: action.payload.packages_attributes,
        price_breakdown: action.payload.price_breakdown,
        meta_data: action.payload.meta_data,
      }
    case types.RESET_REUSE_FORM:
      return {
        sender_address: '',
        sender_floor: '',
        sender_name: '',
        sender_phone_number: '',
        service_provider: 'alfred',
        address_type: ADDRESS_TYPES.COMMERCIAL,
        packages: [],
        packages_attributes: [],
        price_breakdown: [],
        meta_data: {},
      }

    default:
      return state
  }
}

const NDDFormDataReducer = {
  NDDFormData: NDDReducer,
}

export { NDDFormDataReducer }
