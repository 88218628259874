import React from 'react'
import PropTypes from 'prop-types'

import {
  WaypointWrapper,
  WaypointTitleWrapper,
  WaypointTitle,
  WaypointCaption,
  WaypointIconRight,
  WaypointAnchorWrapper,
  WaypointContentWrapper,
  PickupPinIcon,
  DropoffPinIcon,
  CustomIconContainer,
} from './waypoint.styles'

const Waypoint = React.forwardRef((props, ref) => {
  const {
    caption,
    anchor,
    isStopover,
    isDestination,
    children,
    dataTestid,
    className,
    isCollapsed,
    iconRight,
    titleLabel,
    customIcon,
    trailSolid,
    trailColor,
  } = props

  const title = titleLabel ? (
    <>{titleLabel}</>
  ) : (
    <WaypointCaption>{caption}</WaypointCaption>
  )

  const anchorContainer = (
    <WaypointAnchorWrapper
      isDestination={isDestination}
      trailSolid={trailSolid}
      trailColor={trailColor}
    >
      {anchor}
    </WaypointAnchorWrapper>
  )

  const content = (
    <WaypointContentWrapper
      isDestination={isDestination}
      trailSolid={trailSolid}
      trailColor={trailColor}
    >
      {children}
    </WaypointContentWrapper>
  )

  const renderIcon = () => {
    if (customIcon) {
      return <CustomIconContainer>{customIcon}</CustomIconContainer>
    }

    return isStopover || isDestination ? <DropoffPinIcon /> : <PickupPinIcon />
  }

  return (
    <WaypointWrapper
      ref={ref}
      className={className}
      data-testid={dataTestid}
      draggingOver={props.draggingOver}
      isDragging={props.isDragging}
    >
      <WaypointTitleWrapper
        hasCaption={!titleLabel}
        isStopover={isStopover}
        isDestination={isDestination}
        trailSolid={trailSolid}
        trailColor={trailColor}
      >
        {renderIcon()}
        <WaypointTitle hasIcon={!!iconRight}>{title}</WaypointTitle>
        {iconRight && <WaypointIconRight>{iconRight}</WaypointIconRight>}
      </WaypointTitleWrapper>
      {anchor && anchorContainer}
      {!isCollapsed && content}
    </WaypointWrapper>
  )
})

Waypoint.displayName = 'Waypoint'

Waypoint.propTypes = {
  caption: PropTypes.string,
  anchor: PropTypes.node,
  isStopover: PropTypes.bool,
  isDestination: PropTypes.bool,
  children: PropTypes.node,
  dataTestid: PropTypes.string,
  className: PropTypes.string,
  isCollapsed: PropTypes.bool,
  iconRight: PropTypes.node,
  isDragging: PropTypes.bool,
  draggingOver: PropTypes.string,
  titleLabel: PropTypes.node,
  customIcon: PropTypes.node,
  trailSolid: PropTypes.bool,
  trailColor: PropTypes.string,
}

Waypoint.defaultProps = {
  isStopover: false,
  isDestination: false,
  isCollapsed: false,
}

export { Waypoint }
