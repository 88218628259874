import { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { displayAlert } from 'ggx-componentlibrary/components/alerts/alerts.component'
import { Modal } from 'ggx-componentlibrary/components/modal/modal.component'
import { Button } from 'ggx-componentlibrary/components/button/button.component'
import { ClassicRadioButton } from 'ggx-componentlibrary/components/classic-radio-button/classic-radio-button.component'
import { RadioButtonGroupContainer } from 'ggx-componentlibrary/components/classic-radio-button/classic-radio-button.component.styles'
import { Body } from 'ggx-componentlibrary/design/typography/typography.styles'
import {
  StyledTextField,
  CustomBonusRadioButton,
  CustomAmountLabel,
} from './bonus-modal.component.styles'
import { formatCurrency } from '../../../service/i18n/format-currency.service'
import { apiService } from '../../api/api.service'
import { BONUS_VALUES, BONUS_RANGE } from './bonus-modal.constants'
import { getCountry } from 'ggx-service/locale/country.service'

const BonusModal = ({
  onModalClose,
  initialValue,
  orderId,
  onBonusAdded,
  vehicle,
}) => {
  const bonusOptions = [0, ...BONUS_VALUES[getCountry()][vehicle]]
  const { bonusMinimum, bonusMaximum } = BONUS_RANGE[getCountry()]

  const getInitialBonusValue = bonusValue => {
    if (bonusOptions.includes(bonusValue)) {
      return bonusValue
    }

    return 'custom'
  }

  const getIntialCustomBonusValue = bonusValue => {
    if (bonusOptions.includes(bonusValue)) {
      return ''
    }

    return bonusValue
  }

  const [customBonusError, setCustomBonusError] = useState('')
  const [bonus, setBonus] = useState(() => getInitialBonusValue(initialValue))
  const [customBonus, setCustomBonus] = useState(() =>
    getIntialCustomBonusValue(initialValue)
  )
  const [isLoading, setIsLoading] = useState(false)

  const [t] = useTranslation()

  const handleAddBonus = async () => {
    setIsLoading(true)
    try {
      let bonusValue = bonus

      if (bonus === 'custom') {
        bonusValue = customBonus
      }

      await apiService.addBonus(orderId, bonusValue)
      displayAlert({ message: t('alert__ggv__bonus_added') })
    } catch (error) {
      displayAlert({ message: t('errors.generic'), type: 'error' })
    } finally {
      onBonusAdded()
      onModalClose()
      setIsLoading(false)
    }
  }

  const preventEnteringDecimalOrHyphen = event => {
    if (['e', 'E', '-', '.'].includes(event.key)) {
      event.preventDefault()
    }
  }

  const handleCustomBonusChange = value => {
    setCustomBonus(value)
    const valueAsNumber = parseInt(value, 10)
    if (valueAsNumber > bonusMaximum) {
      setCustomBonusError(
        t('text__please_tip_maximum', {
          amount: formatCurrency({ amount: bonusMaximum }),
        })
      )
    } else if (valueAsNumber < bonusMinimum) {
      setCustomBonusError(
        t('text__please_tip_minimum', {
          amount: formatCurrency({ amount: bonusMinimum }),
        })
      )
    } else {
      setCustomBonusError('')
    }
  }

  const meta = customBonusError
    ? { touched: true, error: customBonusError }
    : {}

  const customAmountTextField = (
    <>
      <CustomAmountLabel>{t('common__custom_amount')}</CustomAmountLabel>
      <StyledTextField
        value={customBonus}
        label="Custom bonus amount"
        placeholder={t('common__currency')}
        type="number"
        meta={meta}
        onChange={handleCustomBonusChange}
        onFocus={() => setBonus('custom')}
        onKeyPress={preventEnteringDecimalOrHyphen}
      />
    </>
  )

  const disabled =
    (bonus === 'custom' && !customBonus) || Boolean(customBonusError)

  const footer = (
    <>
      <Button onClick={onModalClose} buttonType="secondary">
        {t('common__cancel')}
      </Button>
      <Button
        disabled={disabled}
        onClick={handleAddBonus}
        isLoading={isLoading}
        loadingText={t('button__save')}
      >
        {t('button__save')}
      </Button>
    </>
  )

  const defaultBonusAmountRadioButtons = bonusOptions.map(value => {
    const label =
      value === 0
        ? t('common__ggv__no_bonus')
        : formatCurrency({ amount: value })

    return (
      <ClassicRadioButton
        dataTestid={`radio-button-${value}-bonus`}
        option={{ label, value }}
        onChange={() => setBonus(value)}
        checked={bonus === value}
        key={value}
      />
    )
  })

  return (
    <Modal
      header={t('common__driver_bonus')}
      footer={footer}
      onRequestClose={onModalClose}
    >
      <RadioButtonGroupContainer>
        {defaultBonusAmountRadioButtons}
        <CustomBonusRadioButton
          dataTestid="radio-button-custom-bonus"
          option={{ label: customAmountTextField, value: 'custom' }}
          onChange={() => setBonus('custom')}
          checked={bonus === 'custom'}
        />
        {!customBonusError && (
          <Body typographyStyles="mt-xs ml-l mb-none c-grey_dark">
            {t('helper__ggv__add_bonus_from', {
              amount: formatCurrency({ amount: bonusMinimum }),
            })}
          </Body>
        )}
      </RadioButtonGroupContainer>
    </Modal>
  )
}

BonusModal.propTypes = {
  onModalClose: PropTypes.func,
  initialValue: PropTypes.string,
  orderId: PropTypes.string,
  onBonusAdded: PropTypes.func,
  vehicle: PropTypes.string,
}

BonusModal.defaultProps = {
  onModalClose: () => {},
  initialValue: 0,
}

export { BonusModal }
