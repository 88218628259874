import styled from "styled-components"
import { COLOR } from "../../design/color/color.constants"
import {
  FONT_REGULAR,
  FONT_BOLD,
  FONT_SIZE,
} from "../../design/typography/typography.constants"
import { SPACING } from "../../design/spacing/spacing.constants"

const TabWrapper = styled.div`
  display: flex;
  border-bottom: 1px solid ${COLOR.GREY_LIGHT};
`
const TabItem = styled.span`
  flex-grow: ${(props) => (props.itemWidth ? 0 : 1)};
  flex-basis: ${(props) => (props.itemWidth ? props.itemWidth : 0)};
  text-align: center;
  ${(props) => (props.value === props.id ? FONT_BOLD : FONT_REGULAR)};
  font-size: ${FONT_SIZE.MEDIUM};
  padding: ${SPACING.S} 0;
  color: ${(props) =>
    props.value === props.id ? COLOR.BLUE_MEDIUM : COLOR.BLACK};
  border-bottom: ${(props) =>
    props.value === props.id ? `4px solid ${COLOR.BLUE_MEDIUM}` : "none"};
  background-color: ${(props) =>
    props.disabled ? COLOR.GREY_LIGHTEST : COLOR.WHITE};

  &:hover {
    cursor: ${(props) =>
      (props.onClick || props.onKeyPress) &&
      props.value !== props.id &&
      !props.disabled
        ? "pointer"
        : "auto"};
  }

  &:focus {
    outline: none;
    border: ${(props) =>
      props.disabled || props.value === props.id
        ? null
        : `1px solid ${COLOR.BLUE_MEDIUM}`};
  }

  &:active {
    outline: ${(props) => (props.onClick || props.onKeyPress ? "none" : null)};
    border: ${(props) => (props.onClick || props.onKeyPress ? "none" : null)};
    border-bottom: ${(props) =>
      props.value === props.id && `4px solid ${COLOR.BLUE_MEDIUM}`};
  }
`

export { TabWrapper, TabItem }
