import React, { useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'

import {
  Heading,
  Body,
  BodyBold,
  TextLink,
} from 'ggx-componentlibrary/design/typography/typography.styles'
import {
  CardContainer,
  FabIconLarge,
  FabIconSmall,
  CardRow,
  ContactButton,
  CloseIcon,
  PhoneIcon,
  Schedule,
  StyledExternalLink,
} from './floating-action-button.component.styles'
import { getLocale } from '../../legacy/utils/locale'

const FloatingActionButton = () => {
  const [t] = useTranslation()
  const [showCard, setShowCard] = useState(false)

  const helpUrl = `https://help.gogox.com/hc/${getLocale()}`

  return (
    <>
      <CardContainer $isOpen={showCard}>
        <CardRow>
          <FabIconSmall>?</FabIconSmall>
          <Schedule>
            <Heading typographyStyles="mb-none">
              {t('fab__contact_support__title')}
            </Heading>
            <Body typographyStyles="mb-none">
              {t('fab__contact_support__subtitle')}
            </Body>
          </Schedule>
          <CloseIcon onClick={() => setShowCard(false)} />
        </CardRow>
        <Body typographyStyles="mb-m">
          <Trans
            i18nKey="fab__contact_support__content"
            components={<TextLink href={helpUrl} target="_blank" />}
          />
          <TextLink href={helpUrl} target="_blank">
            <StyledExternalLink />
          </TextLink>
        </Body>
        <ContactButton>
          <PhoneIcon />
          <BodyBold typographyStyles="mb-none">
            {t('fab__contact_support__phone')}
          </BodyBold>
        </ContactButton>
      </CardContainer>

      <FabIconLarge onClick={() => setShowCard(true)} $isOpen={!showCard}>
        ?
      </FabIconLarge>
    </>
  )
}

export { FloatingActionButton }
