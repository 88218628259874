import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

import { Modal } from 'ggx-componentlibrary/components/modal/modal.component'
import { Button } from 'ggx-componentlibrary/components/button/button.component'

import { getLocale } from 'ggx-src/legacy/utils/locale'

import { AddCreditCardForm } from './add-credit-card-form.component'
import { appearance } from './credit-card.component.styles'

const stripePromise = loadStripe(process.env.STRIPE_KEY)

const AddCreditCardModal = ({ onModalClose, cardItent }) => {
  const [t] = useTranslation()
  const locale = getLocale()
  const [isProcessing, setIsProcessing] = useState(false)
  const [isReadyForSubmit, setIsReadyForSubmit] = useState(false)

  return (
    <Modal
      height="initial"
      maxHeight="610px"
      header={t('creditCardGroup.button.addCard')}
      footer={
        <>
          <Button
            buttonType="secondary"
            size="small"
            onClick={onModalClose}
            disabled={isProcessing}
          >
            {t('common__cancel')}
          </Button>
          <Button
            buttonType="primary"
            size="small"
            disabled={!isReadyForSubmit || isProcessing}
            isLoading={isProcessing}
            onClick={() => {
              document.getElementById('payment-form-submit').click()
            }}
          >
            {t('creditCardGroup.button.addCard')}
          </Button>
        </>
      }
      onRequestClose={onModalClose}
    >
      <Elements
        locale={locale}
        stripe={stripePromise}
        options={{ clientSecret: cardItent.client_secret, appearance }}
      >
        <AddCreditCardForm
          onFormStateChange={e => {
            e.complete !== isReadyForSubmit && setIsReadyForSubmit(e.complete)
          }}
          cardIntentId={cardItent.intent_id}
          setIsProcessing={setIsProcessing}
        />
      </Elements>
    </Modal>
  )
}

AddCreditCardModal.propTypes = {
  onModalClose: PropTypes.func,
  cardItent: PropTypes.object,
}

export { AddCreditCardModal }
