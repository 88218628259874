const host = ENV.ASSET_URL

// for Phoenix to add max-age to images automatically
const vsn = process.env.CLIENT_ENV === 'production' ? '?vsn=d' : ''

function assetUrl(url) {
  return url.indexOf('http') === 0 ? `${url}${vsn}` : `${host}${url}${vsn}`
}

function asset1x2xUrl(img1xUrl, img2xUrl) {
  return `${host}${img1xUrl}${vsn} 1x, ${host}${img2xUrl}${vsn} 2x`
}

export { assetUrl, asset1x2xUrl }
