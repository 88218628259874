import * as types from './types'

function subscriptionInfoReducer(state = {}, action = {}) {
  switch (action.type) {
    case types.SET_SUBSCRIPTION_INFO:
      return action.value
    default:
      return state
  }
}

const subscriptionReducer = {
  subscriptionInfo: subscriptionInfoReducer,
}

export { subscriptionReducer }
