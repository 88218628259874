import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

import { COLOR } from 'ggx-componentlibrary/design/color/color.constants'
import { SPACING } from 'ggx-componentlibrary/design/spacing/spacing.constants'

import {
  CLIENT_FEEDBACK_TYPE,
  CLIENT_BAD_FEEDBACK_OPTIONS,
  CLIENT_GOOD_FEEDBACK_OPTIONS,
} from '../driver-rating.component'
import { StyledBodyBold } from '../driver-rating.component.styles'

const DriverRatingOptions = ({
  ratingSelected,
  selectedOptions,
  handleChange,
}) => {
  const [t] = useTranslation()
  const feedbackOptions =
    ratingSelected > 4
      ? CLIENT_GOOD_FEEDBACK_OPTIONS
      : CLIENT_BAD_FEEDBACK_OPTIONS

  return (
    <FormControl
      variant="standard"
      sx={{
        marginBottom: SPACING.S,
      }}
    >
      <StyledBodyBold>
        {ratingSelected === 5
          ? t('text__let_driver_know_what_liked')
          : t('text__what_could_be_improved')}
      </StyledBodyBold>
      <FormGroup
        row
        sx={{
          justifyContent: 'center',
        }}
      >
        {Object.entries(feedbackOptions).map(([key, value]) => {
          const keyToNum = Number(key)
          if (
            ratingSelected > 4 &&
            keyToNum === CLIENT_FEEDBACK_TYPE.incorrect_driver_info
          )
            return null
          const isActive = selectedOptions.includes(keyToNum)
          return (
            <FormControlLabel
              key={keyToNum}
              sx={{
                border: `1px solid ${
                  isActive ? COLOR.BLUE_MEDIUM : COLOR.GREY_LIGHT
                }`,
                borderRadius: '4px',
                padding: `${SPACING.XS} ${SPACING.S}`,
                margin: '0 4px 8px',
                '&:hover': {
                  borderColor: COLOR.BLUE_MEDIUM,
                },
                '& .MuiFormControlLabel-label': {
                  fontFamily:
                    'Wix Madefor Text, Lucida Grande, Helvetica Neue, Helvetica, Arial, Verdana, sans-serif',
                  fontSize: '14px',
                  color: isActive ? COLOR.BLUE_MEDIUM : COLOR.BLACK,
                  fontWeight: isActive ? 700 : 400,
                },
              }}
              control={
                <Checkbox
                  sx={{
                    display: 'none',
                  }}
                  checked={ratingSelected[keyToNum]}
                  onChange={handleChange}
                  name={keyToNum}
                />
              }
              label={t(value)}
            />
          )
        })}
      </FormGroup>
    </FormControl>
  )
}

DriverRatingOptions.propTypes = {
  ratingSelected: PropTypes.number,
  selectedOptions: PropTypes.arrayOf(PropTypes.number),
  handleChange: PropTypes.func,
}

export default DriverRatingOptions
