import { push } from 'react-router-redux'

import Constants from '../constants/action'

const Actions = {
  setHighlightError: errorObject => dispatch => {
    dispatch({
      type: Constants.SET_HIGHLIGHT_ERROR,
      errorObject,
    })
  },

  clearHighlightError: () => dispatch => {
    dispatch({ type: Constants.CLEAR_HIGHLIGHT_ERROR })
  },

  setErrorMessage: errorMessage => dispatch => {
    dispatch({
      type: Constants.SET_ERROR_MESSAGE,
      errorMessage,
    })
  },

  setSuccessMessage: successMessage => dispatch => {
    dispatch({
      type: Constants.SET_SUCCESS_MESSAGE,
      successMessage,
    })
  },

  clearMessages: () => dispatch => {
    dispatch({ type: Constants.CLEAR_MESSAGES })
  },

  showLoading: () => dispatch => {
    dispatch({ type: Constants.SHOW_LOADING })
  },

  hideLoading: () => dispatch => {
    dispatch({ type: Constants.HIDE_LOADING })
  },

  acceptPolygon: () => dispatch => {
    dispatch({ type: Constants.POLYGON_DIALOG_SEEN })
    dispatch(push('/orders/new/confirm'))
  },

  rejectPolygon: () => dispatch => {
    dispatch({ type: Constants.POLYGON_DIALOG_SEEN })
  },
}

export default Actions
