import { Api } from '../../legacy/utils/api'

const _priceQuotationApi = new Api(Api.BASE_URL.B2B)

const priceQuotationService = {
  getQuote: async values => {
    const method = 'post'
    const endpoint = '/order_requests/price_breakdown'
    const data = { order: values }

    try {
      const result = await _priceQuotationApi.request(
        method,
        endpoint,
        null,
        data,
        null,
        null,
        null
      )
      return result.data
    } catch (error) {
      throw error
    }
  },
  getBreakdown: async id => {
    const method = 'get'
    const endpoint = `/order_requests/${id}/breakdown`

    try {
      const result = await _priceQuotationApi.request(
        method,
        endpoint,
        null,
        null,
        null,
        null,
        null
      )
      return result.data
    } catch (error) {
      throw error
    }
  },
}

export { _priceQuotationApi, priceQuotationService }
