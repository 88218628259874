async function convertBlobToBase64(blob) {
  const reader = new FileReader()
  reader.readAsDataURL(blob)
  await new Promise(res => {
    reader.onloadend = res
  })
  return reader.result
}

async function convertBlobToBinaryString(blob) {
  const reader = new FileReader()
  reader.readAsBinaryString(blob)
  await new Promise(res => {
    reader.onloadend = res
  })
  return reader.result
}

async function convertBlobToArrayBuffer(blob) {
  const reader = new FileReader()
  reader.readAsBinaryString(blob)
  await new Promise(res => {
    reader.onloadend = res
  })
  return reader.result
}

function convertBinaryStringToURL(binaryString, type) {
  const blob = new Blob([Uint8Array.from(binaryString, s => s.charCodeAt(0))], {
    type,
  })
  return URL.createObjectURL(blob)
}

async function compressImage(file, quality = 1) {
  const img = document.createElement('img')
  try {
    img.src = URL.createObjectURL(file)
    img.style.display = 'none'
    await new Promise(res => img.addEventListener('load', res))
    document.body.appendChild(img)
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    canvas.width = img.width
    canvas.height = img.height
    context.drawImage(img, 0, 0, img.width, img.height)
    return await new Promise(res =>
      canvas.toBlob(blob => res(blob), 'image/jpeg', quality)
    )
  } finally {
    document.body.removeChild(img)
    URL.revokeObjectURL(img.src)
  }
}

async function serializeFile(file) {
  const blob = await compressImage(file)
  const data = await convertBlobToBinaryString(blob)
  return { type: blob.type, data }
}

function loadSerializedFileToURL(key) {
  const tempImageData = localStorage.getItem(`${key}_data`)
  const tempImageType = localStorage.getItem(`${key}_type`)
  if (tempImageData && tempImageType) {
    return convertBinaryStringToURL(tempImageData, tempImageType)
  }
}

function saveSerializedFile(key, { type, data }) {
  localStorage.setItem(`${key}_data`, data)
  localStorage.setItem(`${key}_type`, type)
}

function removeSerializedFile(key) {
  localStorage.removeItem(`${key}_data`)
  localStorage.removeItem(`${key}_type`)
}

export {
  convertBlobToBase64,
  convertBlobToBinaryString,
  convertBlobToArrayBuffer,
  convertBinaryStringToURL,
  compressImage,
  serializeFile,
  loadSerializedFileToURL,
  saveSerializedFile,
  removeSerializedFile,
}
