import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Dropdown } from 'ggx-componentlibrary/components/dropdown/dropdown.component'
import {
  DropdownContainer,
  DROPDOWN_MAXHEIGHT,
} from './credit-card-section.component.styles'
import { formatCurrency } from '../../../service/i18n/format-currency.service'

const PrepaidPackageForm = ({ input, meta, packages }) => {
  const [t] = useTranslation()

  const options = packages.map(
    ({ id, attributes: { credits, free_credits: freeCredits } }) => ({
      value: id,
      label: `${formatCurrency({ amount: credits })} (+${formatCurrency({
        amount: freeCredits,
      })} ${t('billing.topup.modal.package.label')})`,
    })
  )

  const selected = input.value || (options.length > 0 ? options[0] : null)

  const handleSelection = option => {
    input.onChange(option.value)
  }

  return (
    <DropdownContainer>
      <Dropdown
        dataTestid="topup-modal-dropdown"
        defaultOptions={options}
        onChange={handleSelection}
        value={selected}
        maxHeight={DROPDOWN_MAXHEIGHT}
        meta={{ error: meta.error, touched: meta.touched }}
      />
    </DropdownContainer>
  )
}

PrepaidPackageForm.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func,
  }),
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
  packages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      attributes: PropTypes.shape({
        title: PropTypes.string,
        first_time_free_credits: PropTypes.string,
      }),
    })
  ),
}

export { PrepaidPackageForm }
