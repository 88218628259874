// @flow

import { getLocale } from '../utils/locale'
import * as debug from '../utils/debug'
import Locales from './index'

const error = 'Translation error: invalid key'
const fallbackLocale = 'en-hk'

export default function translate(
  key: string,
  options?: Object,
  useFallback: boolean
) {
  const debugTranslations =
    ENV.ENABLE_DEBUG && JSON.parse(debug.getDebugTranslations())

  const currentLocale = getLocale()
  const locale = useFallback ? fallbackLocale : currentLocale
  const components = key.split('.')
  let current = Locales[locale]

  if (debugTranslations) {
    const serializedOptions = options ? ` ${JSON.stringify(options)}` : ''
    return key + serializedOptions
  }

  while (components.length) {
    current = current[components.shift()]

    if (typeof current === 'function') {
      current = current(options)
    }

    if (!current && typeof current !== 'string') {
      if (ENV.ENABLE_DEBUG) {
        // eslint-disable-next-line no-console
        console.error(`${error}, ${key}`)
      }

      return useFallback ? undefined : translate(key, options, true)
    }
  }
  return current
}

export const translateUserLevel = (level: string) => {
  let levelKey
  switch (level) {
    case 'b2b_admin_user':
      levelKey = 'admin'
      break
    case 'b2b_master_user':
      levelKey = 'master'
      break
    case 'b2b_branch_user':
      levelKey = 'branchUser'
      break
    default:
      levelKey = 'subUser'
  }
  return translate(`users.profileEdit.${levelKey}`)
}
