import PropTypes from 'prop-types'

import {
  HeadingL,
  Body,
} from 'ggx-componentlibrary/design/typography/typography.styles'
import {
  StyledModal,
  Chevron,
  PositionedWrapper,
} from './callout-modal.component.styles'

const CalloutModal = ({
  heading,
  highlights,
  footer,
  modalPosition,
  stepText,
  message,
  chevronTopOffset,
}) => {
  return (
    <PositionedWrapper>
      <StyledModal
        footer={footer}
        highlights={highlights}
        modalPosition={modalPosition}
        showCloseButton={false}
        width="392px"
      >
        <Chevron $chevronTopOffset={chevronTopOffset} />
        {stepText && <Body typographyStyles="mb-none">{stepText}</Body>}
        {heading && <HeadingL typographyStyles="mb-s">{heading}</HeadingL>}
        {message && <Body typographyStyles="mb-none">{message}</Body>}
      </StyledModal>
    </PositionedWrapper>
  )
}

CalloutModal.propTypes = {
  heading: PropTypes.string,
  stepText: PropTypes.string,
  message: PropTypes.string,
  footer: PropTypes.node,
  highlights: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.string,
      y: PropTypes.string,
      width: PropTypes.string,
      height: PropTypes.string,
    })
  ),
  modalPosition: PropTypes.shape({
    left: PropTypes.string,
    top: PropTypes.string,
  }),
  chevronTopOffset: PropTypes.string,
}

export { CalloutModal }
