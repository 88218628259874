import {
  isHongKong,
  isSingapore,
  isTaiwan,
  isVietnam,
} from '../../legacy/utils/locale'

function getMomentTimeZone() {
  if (isHongKong()) {
    return 'Asia/Hong_Kong'
  } else if (isSingapore()) {
    return 'Asia/Singapore'
  } else if (isTaiwan()) {
    return 'Asia/Taipei'
  } else if (isVietnam()) {
    return 'Asia/Ho_Chi_Minh'
  }

  return 'Asia/Hong_Kong'
}

export { getMomentTimeZone }
