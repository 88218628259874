import PropTypes from 'prop-types'

const styles = {
  container: {
    padding: '1.42857142857em',
    marginBottom: '0em',
  },
}

export default function PageHeader({ title, titleStyle, additionalNodes }) {
  const nodes = Array.isArray(additionalNodes)
    ? additionalNodes.map((node, i) => <span key={i}>{node}</span>)
    : additionalNodes

  return (
    <section style={styles.container}>
      <h1 style={titleStyle}>{title}</h1>
      {nodes}
    </section>
  )
}

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  titleStyle: PropTypes.object,
  additionalNodes: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}
