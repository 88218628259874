import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'

import { Container } from './component-position-by-route.component.styles'

const ComponentPositionByRoute = withRouter(
  ({ positionList, defaultPosition, location, children }) => {
    const [top, setTop] = useState(null)
    const [right, setRight] = useState(null)
    const [bottom, setBottom] = useState(null)
    const [left, setLeft] = useState(null)

    useEffect(() => {
      setTop(defaultPosition.top)
      setRight(defaultPosition.right)
      setBottom(defaultPosition.bottom)
      setLeft(defaultPosition.left)

      // eslint-disable-next-line no-restricted-syntax
      for (const positionItem of positionList) {
        const path = positionItem.route
        const isRegex = path instanceof RegExp
        if (
          (isRegex && path.test(location.pathname)) ||
          path === location.pathname
        ) {
          setTop(positionItem.position.top)
          setRight(positionItem.position.right)
          setBottom(positionItem.position.bottom)
          setLeft(positionItem.position.left)
          break
        }
      }
    }, [location.pathname, defaultPosition, positionList])

    return (
      <Container top={top} right={right} bottom={bottom} left={left}>
        {children}
      </Container>
    )
  }
)

ComponentPositionByRoute.defaultProps = {
  defaultPosition: {},
  positionList: [],
}

const positionShape = PropTypes.shape({
  top: PropTypes.string,
  bottom: PropTypes.string,
  right: PropTypes.string,
  left: PropTypes.string,
})

ComponentPositionByRoute.propTypes = {
  positionList: PropTypes.arrayOf(
    PropTypes.shape({
      route: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      position: positionShape,
    })
  ),
  defaultPosition: positionShape,
  location: PropTypes.shape({ pathname: PropTypes.string }),
  children: PropTypes.node,
}

export { ComponentPositionByRoute }
