import { useEffect } from 'react'
import PropTypes from 'prop-types'

import { setDocumentTitle } from 'ggx-service/document-title/set-document-title'
import {
  SmallText,
  HeadingXL,
  Body,
} from 'ggx-componentlibrary/design/typography/typography.styles'

import {
  PageContainer,
  HeadingSection,
  LeftColumn,
  RightColumn,
} from './page.component.styles'

const Page = ({
  className,
  noDocumentTitle,
  documentTitle,
  isHeading,
  section,
  heading,
  description,
  children,
  rightComponent,
  headingSectionTypographyStyles,
}) => {
  useEffect(() => {
    if (noDocumentTitle) return

    setDocumentTitle(documentTitle || heading)
  }, [noDocumentTitle, documentTitle, heading])

  return (
    <PageContainer
      className={className}
      isHeading={isHeading}
      data-cy={section}
    >
      <HeadingSection
        isHeading={isHeading}
        typographyStyles={headingSectionTypographyStyles}
      >
        <LeftColumn>
          {section && (
            <SmallText typographyStyles="mb-none">{section}</SmallText>
          )}
          <HeadingXL>{heading}</HeadingXL>
          {description && <Body typographyStyles="mb-none">{description}</Body>}
        </LeftColumn>
        {rightComponent && <RightColumn>{rightComponent}</RightColumn>}
      </HeadingSection>
      {isHeading ? null : children}
    </PageContainer>
  )
}

Page.propTypes = {
  className: PropTypes.string,
  noDocumentTitle: PropTypes.bool,
  documentTitle: PropTypes.string,
  isHeading: PropTypes.bool,
  section: PropTypes.string,
  heading: PropTypes.string.isRequired,
  description: PropTypes.string,
  children: PropTypes.node,
  rightComponent: PropTypes.node,
  headingSectionTypographyStyles: PropTypes.string,
}

Page.defaultProps = {
  noDocumentTitle: false,
  isHeading: false,
}

export { Page }
