import { useState, useEffect } from 'react'
import { useStore } from 'react-redux'
import { getFeatureFlag } from 'ggx-service/feature-flag/selectors'
import { featureFlagService } from 'ggx-service/feature-flag/feature-flag'

export default function useFeatureFlag(flagName) {
  const store = useStore()
  return useFeatureFlagFromExternalStore(flagName, store)
}

export function useFeatureFlagFromExternalStore(flagName, store) {
  const [featureFlag, setFeatureFlag] = useState(() =>
    getFeatureFlag({
      state: store.getState(),
      featureFlag: flagName,
    })
  )
  useEffect(() => {
    return store.subscribe(() => {
      const flag = getFeatureFlag({
        state: store.getState(),
        featureFlag: flagName,
      })
      if (flag !== featureFlag) setFeatureFlag(flag)
    })
  }, [])
  return featureFlag
}

export function useFeatureFlagValue(flagName) {
  const [featureFlagValue, setFeatureFlagValue] = useState(
    featureFlagService.getFeatureFlagValue(flagName)
  )
  useEffect(() => {
    featureFlagService.triggerFeatureFlag(flagName).then(() => {
      setFeatureFlagValue(featureFlagService.getFeatureFlagValue(flagName))
    })
  }, [])
  return featureFlagValue
}
