import * as types from './types'

const initialState = false
const dropoffTimeInitialState = {
  instant: '',
  speed: '',
  same_day_day: '',
  same_day_night: '',
}
const imageDataUrlInitialState = null
const bulkImportTableInitialState = []
const bulkImportQuotationTotalInitialState = 0
const bulkImportUploadErrorInitialState = null

function gogodeliveryReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.FEATUREFLAG_GOGODELIVERY_GGB:
      return typeof action.value === 'boolean' ? action.value : initialState

    default:
      return state
  }
}

function deliveryDropoffTimeReducer(
  state = dropoffTimeInitialState,
  action = {}
) {
  switch (action.type) {
    case types.DELIVERY_SET_DROPOFF_TIME:
      return {
        ...state,
        instant: action.instant,
        speed: action.speed,
        same_day_day: action.same_day_day,
        same_day_night: action.same_day_night,
      }

    default:
      return state
  }
}

function deliveryOrderDetailImageUrlReducer(
  state = imageDataUrlInitialState,
  action = {}
) {
  switch (action.type) {
    case types.DELIVERY_SET_ORDER_DETAIL_IMAGE_DATA_URL:
      return action.value

    default:
      return state
  }
}

function deliveryBulkImportTableReducer(
  state = bulkImportTableInitialState,
  action = {}
) {
  switch (action.type) {
    case types.DELIVERY_SET_BULK_IMPORT_TABLE_DATA:
      return action.value.map(rowData => ({ ...rowData }))

    case types.DELIVERY_EDIT_BULK_IMPORT_TABLE_DATA: {
      const { value, rowId, column } = action
      const copiedRow = { ...state.find(rowData => rowData.row === rowId) }
      copiedRow[column] = value

      return state.map(rowData =>
        rowData.row === rowId ? copiedRow : { ...rowData }
      )
    }

    case types.DELIVERY_BULK_EDIT_BULK_IMPORT_TABLE_DATA: {
      const { value, column, selectedRows } = action
      const editAllRows =
        (selectedRows && selectedRows.length === 0) || !selectedRows

      return state.map(row => {
        const copiedRow = { ...row }
        if (
          (selectedRows &&
            selectedRows.length > 0 &&
            selectedRows.includes(row.row)) ||
          editAllRows
        ) {
          copiedRow[column] = value
          delete copiedRow[`${column}_error`]
          delete copiedRow[`${column}_warning`]
        }

        return copiedRow
      })
    }

    case types.DELIVERY_DELETE_BULK_IMPORT_TABLE_DATA: {
      const { rowId, column } = action
      const copiedRow = { ...state.find(rowData => rowData.row === rowId) }
      delete copiedRow[column]

      return state.map(rowData =>
        rowData.row === rowId ? copiedRow : { ...rowData }
      )
    }

    case types.DELIVERY_DELETE_BULK_IMPORT_TABLE_DATA_ROW:
      return state.filter(rowData => rowData.row !== action.value)

    default:
      return state
  }
}

function deliveryBulkImportUploadErrorReducer(
  state = bulkImportUploadErrorInitialState,
  action = {}
) {
  switch (action.type) {
    case types.DELIVERY_SET_BULK_IMPORT_UPLOAD_ERROR:
      return action.value

    default:
      return state
  }
}

function deliveryBulkImportShowLoadingScreenReducer(
  state = false,
  action = {}
) {
  switch (action.type) {
    case types.DELIVERY_BULK_IMPORT_SHOW_LOADING_SCREEN:
      return typeof action.value === 'boolean' ? action.value : false

    default:
      return state
  }
}

function deliveryBulkImportQuotationTotalReducer(
  state = bulkImportQuotationTotalInitialState,
  action = {}
) {
  switch (action.type) {
    case types.DELIVERY_SET_BULK_IMPORT_QUOTATION_TOTAL:
      return action.value

    default:
      return state
  }
}

function deliveryBulkImportQuoteInProgressReducer(state = false, action = {}) {
  switch (action.type) {
    case types.DELIVERY_BULK_IMPORT_SET_QUOTE_IN_PROGRESS:
      return typeof action.value === 'boolean' ? action.value : initialState

    default:
      return state
  }
}

function deliveryBulkImportExcelParsingInProgressReducer(
  state = false,
  action = {}
) {
  switch (action.type) {
    case types.DELIVERY_BULK_IMPORT_SET_EXCEL_PARSING_IN_PROGRESS:
      return typeof action.value === 'boolean' ? action.value : initialState

    default:
      return state
  }
}

function deliveryBulkImportAddressMatchInProgressReducer(
  state = false,
  action = {}
) {
  switch (action.type) {
    case types.DELIVERY_BULK_IMPORT_ADDRESS_MATCH_IN_PROGRESS:
      return typeof action.value === 'boolean' ? action.value : initialState

    default:
      return state
  }
}

function deliveryInternalUserReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.FEATUREFLAG_DELIVERY_INTERNAL_USER:
      return typeof action.value === 'boolean' ? action.value : initialState

    default:
      return state
  }
}

function deliveryOptionalFieldFloorReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.FEATUREFLAG_DELIVERY_OPTIONAL_FIELD_FLOOR:
      return typeof action.value === 'boolean' ? action.value : initialState

    default:
      return state
  }
}

function shopifyOrdersQuotationTotalReducer(state = 0, action = {}) {
  switch (action.type) {
    case types.SHOPIFY_SET_ORDERS_TABLE_QUOTATION_TOTAL:
      return action.value

    default:
      return state
  }
}

function shopifyOrdersTableReducer(state = [], action = {}) {
  switch (action.type) {
    case types.SHOPIFY_SET_ORDERS_TABLE_DATA:
      return action.value.map(rowData => ({ ...rowData }))

    case types.SHOPIFY_EDIT_ORDERS_TABLE_DATA: {
      const { value, rowId, column } = action
      const copiedRow = {
        ...state.find(rowData => rowData.merchant_order_number === rowId),
      }
      copiedRow[column] = value

      return state.map(rowData =>
        rowData.merchant_order_number === rowId ? copiedRow : { ...rowData }
      )
    }

    case types.SHOPIFY_EDIT_COLUMNS_ORDERS_TABLE_DATA: {
      const { value, column, selectedRows } = action
      const editAllRows =
        (selectedRows && selectedRows.length === 0) || !selectedRows

      return state.map(row => {
        const copiedRow = { ...row }
        if (
          (selectedRows &&
            selectedRows.length > 0 &&
            selectedRows.includes(row.row)) ||
          editAllRows
        ) {
          copiedRow[column] = value
          delete copiedRow[`${column}_error`]
          delete copiedRow[`${column}_warning`]
        }

        return copiedRow
      })
    }

    case types.SHOPIFY_DELETE_ORDERS_TABLE_DATA: {
      const { rowId, column } = action
      const copiedRow = {
        ...state.find(rowData => rowData.merchant_order_number === rowId),
      }
      delete copiedRow[column]

      return state.map(rowData =>
        rowData.merchant_order_number === rowId ? copiedRow : { ...rowData }
      )
    }

    case types.SHOPIFY_DELETE_ORDERS_TABLE_DATA_ROW:
      return state.filter(
        rowData => rowData.merchant_order_number !== action.value
      )

    default:
      return state
  }
}

function shopifyQuoteInProgressReducer(state = false, action = {}) {
  switch (action.type) {
    case types.SHOPIFY_SET_QUOTE_IN_PROGRESS:
      return typeof action.value === 'boolean' ? action.value : initialState

    default:
      return state
  }
}

const deliveryReducers = {
  featureFlagGogodelivery: gogodeliveryReducer,
  featureFlagDeliveryInternalUser: deliveryInternalUserReducer,
  featureFlagDeliveryOptionalFieldFloor: deliveryOptionalFieldFloorReducer,
  deliveryDropoffTime: deliveryDropoffTimeReducer,
  deliveryOrderDetailImageUrl: deliveryOrderDetailImageUrlReducer,
  deliveryBulkImportTableData: deliveryBulkImportTableReducer,
  deliveryBulkImportQuotationTotal: deliveryBulkImportQuotationTotalReducer,
  deliveryBulkImportQuoteInProgress: deliveryBulkImportQuoteInProgressReducer,
  deliveryBulkImportUploadError: deliveryBulkImportUploadErrorReducer,
  deliveryBulkImportAddressMatchInProgress: deliveryBulkImportAddressMatchInProgressReducer,
  deliveryBulkImportShowLoadingScreen: deliveryBulkImportShowLoadingScreenReducer,
  deliveryBulkImportExcelParsingInProgress: deliveryBulkImportExcelParsingInProgressReducer,
  shopifyOrdersTable: shopifyOrdersTableReducer,
  shopifyQuoteInProgress: shopifyQuoteInProgressReducer,
  shopifyOrdersQuotationTotal: shopifyOrdersQuotationTotalReducer,
}

export { deliveryReducers }
