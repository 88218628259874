import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { PaymentElement } from '@stripe/react-stripe-js'
import { useStripe, useElements } from '@stripe/react-stripe-js'
import { useDispatch } from 'react-redux'

import { BodyBold } from 'ggx-componentlibrary/design/typography/typography.styles'
import { TextField } from 'ggx-componentlibrary/components/text-field/text-field.component'
import { displayAlert } from 'ggx-componentlibrary/components/alerts/alerts.component'

import { handleAddCreditCardSuccess } from 'ggx-src/payment/duck/actions'
import { paymentService } from 'ggx-src/payment/api/api.service'
import { getCountry } from 'ggx-service/locale/country.service'

import { FieldWrapper } from './credit-card.component.styles'

const AddCreditCardForm = ({
  onFormStateChange,
  cardIntentId,
  setIsProcessing,
}) => {
  const [t] = useTranslation()
  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useDispatch()
  const timerRef = useRef(null)
  const errorMessages = {
    incomplete_number: t('error.missing.creditCardNumber'),
    incomplete_expiry: t('error.missing.creditCardDate'),
    incomplete_cvc: t('error.missing.creditCardCVC'),
    incomplete_zip: t('error.missing.creditCardZip'),
    invalid_expiry_month_past: t('error.missing.creditCardDate'),
    invalid_expiry_year_past: t('error.missing.creditCardDate'),
    invalid_expiry_year: t('error.missing.creditCardDate'),
    invalid_number: t('error__please_enter_valid_credit_card_number'),
    get_card_error: t('error.creditCard.retrieve'),
    save_card_error: t('error.creditCard.save'),
    remove_card_error: t('error.creditCard.remove'),
    error__failed_to_add_card: t('error__failed_to_add_card'),
  }

  const saveCardHandler = async event => {
    const nickname = new FormData(event.target).get('nickname')
    event.preventDefault()
    event.stopPropagation()

    try {
      setIsProcessing(true)

      await paymentService.updateCardSetupIntent(cardIntentId, {
        metadata: {
          nickname,
        },
      })

      const result = await stripe.confirmSetup({
        elements,
        confirmParams: {
          payment_method_data: {
            billing_details: {
              address: { country: getCountry(), postal_code: null },
            },
          },
        },
        redirect: 'if_required',
      })

      if (result?.setupIntent?.status === 'succeeded') {
        // timerRef.current = setTimeout(() => {
        //   dispatch(handleAddCreditCardSuccess())
        // }, 2000)
      } else {
        const message =
          result.error && result.error.type === 'validation_error'
            ? errorMessages[result.error.code]
            : t('error__failed_to_add_card')
        displayAlert({ message, type: 'error' })
      }
    } catch (caughtError) {
      setIsProcessing(false)
      displayAlert({ message: t('error__failed_to_add_card'), type: 'error' })
    }
  }

  const paymentElementOptions = {
    layout: 'tabs',
    fields: {
      billingDetails: {
        address: {
          country: 'never',
          postalCode: 'never',
        },
      },
    },
    terms: {
      bancontact: 'never',
      card: 'never',
      ideal: 'never',
      sepaDebit: 'never',
      sofort: 'never',
      auBecsDebit: 'never',
      usBankAccount: 'never',
    },
  }

  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current)
    }
  }, [])

  return (
    <form id="payment-form" onSubmit={saveCardHandler}>
      <PaymentElement
        id="payment-element"
        options={paymentElementOptions}
        onChange={event => onFormStateChange(event)}
      />
      <FieldWrapper>
        <BodyBold>{t('card_nickname_optional')}</BodyBold>
        <TextField name="nickname" placeholder="e.g. Department card" />
      </FieldWrapper>
      <button
        id="payment-form-submit"
        disabled={!stripe || !elements}
        style={{ display: 'none' }}
        type="submit"
      />
    </form>
  )
}

export { AddCreditCardForm }
