import { toast } from 'react-toastify'

import { CloseButton } from './alerts.component.styles'

const defaultOptions = {
  position: 'top-center',
  hideProgressBar: true,
  closeOnClick: false,
  draggable: false,
}

const displayAlert = ({
  message,
  autoClose = true,
  type = 'default',
  hideCloseButton = false,
  toastId,
}) => {
  const options = {
    ...defaultOptions,
    autoClose: autoClose === true ? 3000 : autoClose,
    closeButton: hideCloseButton || autoClose ? false : <CloseButton />,
    toastId,
    type,
  }

  return toast(message, options)
}

const dismissAlert = ({ toastId }) => toast.dismiss(toastId)

const updateAlert = ({
  message,
  autoClose = true,
  type = 'default',
  hideCloseButton = false,
  toastId,
}) => {
  const options = {
    ...defaultOptions,
    autoClose: autoClose === true ? 3000 : autoClose,
    closeButton: hideCloseButton || autoClose ? false : <CloseButton />,
  }

  toast.update(toastId, {
    ...options,
    render: message,
    type,
  })
}

const isAlertActive = ({ toastId }) => toast.isActive(toastId)

export { displayAlert, dismissAlert, isAlertActive, updateAlert }
