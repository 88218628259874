import { composeWithDevTools } from 'redux-devtools-extension'
import Constants from '../../legacy/constants/action'

// https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/Troubleshooting.md#excessive-use-of-memory-and-cpu
const actionSanitizer = action => {
  const blockList = [Constants.SET_MAP_OBJECT, Constants.SET_SERVICES]
  if (blockList.includes(action.type)) {
    return { type: action.type }
  }
  return action
}

const stateSanitizer = state => ({
  ...state,
  maps: '',
  profilePhotoDataUrl: '',
})

const composeReduxDevTools = composeWithDevTools({
  actionSanitizer,
  stateSanitizer,
})

export { composeReduxDevTools }
