import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  Card,
  CardTitle,
  ServiceTypesBody,
  DescriptionBody,
  VehiclePicture,
  DetailsButton,
  getVoucherPicture,
} from './voucher.component.styles'
import {
  SmallTextBold,
  Body,
} from 'ggx-componentlibrary/design/typography/typography.styles'
import CoveredLorrySVG from './assets/covered_lorry.svg'
import PackageSVG from './assets/package.svg'
import { formatDateTime } from 'ggx-service/date-time/format-date-time'
import { getCountry } from 'ggx-service/locale/country.service'

const SERVICE_TYPES = {
  TRANSPORT: 'transport',
  DELIVERY: 'delivery',
}

const VoucherCard = ({
  title,
  serviceTypes,
  vehicleTypesRule,
  minOrderSpent,
  expiredAt,
  remaining,
  total,
  type,
  onClick,
  onClickDetail,
}) => {
  const [t] = useTranslation()
  return (
    <Card
      greyStyle={type === 'history' || type === 'disabled'}
      total={total}
      {...(type === 'disabled' && { 'data-disabled': '' })}
      {...(type === 'selected' && { 'data-selected': '' })}
      onClick={event => {
        if (type !== 'disabled') onClick(event)
      }}
    >
      <div>
        <div>
          <CardTitle {...(type === 'selected' && { 'data-selected': '' })}>
            {title}
          </CardTitle>
          {getCountry() === 'HK' && (
            <ServiceTypesBody>
              {serviceTypes.length === 2 && (
                <>
                  <CoveredLorrySVG />
                  <span>{t('common__transport')}</span>
                  <PackageSVG />
                  <span>{t('common.delivery')}</span>
                </>
              )}
              {serviceTypes.length === 1 &&
                serviceTypes[0] === SERVICE_TYPES.TRANSPORT && (
                  <>
                    <CoveredLorrySVG />
                    <span>{t('text__coupon_transport_only')}</span>
                  </>
                )}
              {serviceTypes.length === 1 &&
                serviceTypes[0] === SERVICE_TYPES.DELIVERY && (
                  <>
                    <PackageSVG />
                    <span>{t('text__coupon_delivery_only')}</span>
                  </>
                )}
            </ServiceTypesBody>
          )}
          <DescriptionBody>
            {minOrderSpent === 0
              ? t('data__any_order_amount')
              : t('data__min_currency_number_spend', { amount: minOrderSpent })}
          </DescriptionBody>
          {type === 'history' && (
            <DescriptionBody>
              {remaining === 0 && t('data__n_used', { used: total })}
              {total === remaining && t('data__n_expired', { expired: total })}
              {0 < remaining &&
                remaining < total &&
                t('data__n_used_n_expired', {
                  used: total - remaining,
                  expired: remaining,
                })}
            </DescriptionBody>
          )}
        </div>
        <div>
          <VehiclePicture
            greyStyle={type === 'history' || type === 'disabled'}
            src={getVoucherPicture(serviceTypes, vehicleTypesRule)}
          />
          {type !== 'history' && total !== 1 && (
            <SmallTextBold noMargin typographyStyles="c-grey_darker">
              {t('data__x_of_x', { remaining, total })}
            </SmallTextBold>
          )}
        </div>
      </div>
      <div>
        <Body noMargin typographyStyles="c-grey_dark">
          {type !== 'history' &&
            t('data__valid_until_date', {
              date: formatDateTime(
                expiredAt,
                t('common__timestamp__dd_mmm_yyyy'),
                false
              ),
            })}
          {type === 'history' && remaining === 0 && t('text__coupon_used')}
          {type === 'history' &&
            remaining > 0 &&
            t('data__expired_date', {
              date: formatDateTime(
                expiredAt,
                t('common__timestamp__dd_mmm_yyyy'),
                false
              ),
            })}
        </Body>
        <DetailsButton
          onClick={event => {
            event.stopPropagation()
            onClickDetail(event)
          }}
        >
          {t('text__details')}
        </DetailsButton>
      </div>
    </Card>
  )
}

VoucherCard.propTypes = {
  title: PropTypes.string,
  serviceTypes: PropTypes.arrayOf(PropTypes.string),
  vehicleTypesRule: PropTypes.arrayOf(PropTypes.string),
  minOrderSpent: PropTypes.number,
  expiredAt: PropTypes.number,
  remaining: PropTypes.number,
  total: PropTypes.number,
  type: PropTypes.oneOf(['history', 'disabled', 'selected']),
  onClick: PropTypes.func,
  onClickDetail: PropTypes.func,
}

export { VoucherCard }
