import styled from "styled-components"

import { ClassicRadioButton } from "ggx-componentlibrary/components/classic-radio-button/classic-radio-button.component"
import { SPACING } from "ggx-componentlibrary/design/spacing/spacing.constants"
import {
  FONT_SIZE,
  LINE_HEIGHT,
} from "ggx-componentlibrary/design/typography/typography.constants"
import {
  TextLink,
} from 'ggx-componentlibrary/design/typography/typography.styles'
import { COLOR } from 'ggx-componentlibrary/design/color/color.constants'
import {
  SmallText,
} from 'ggx-componentlibrary/design/typography/typography.styles'

const StyledRadioButton = styled(ClassicRadioButton)`
  margin-bottom: ${SPACING.XXS};
`

const OptionContainer = styled.div`
  margin-bottom: ${SPACING.S};
`

const CreditCardContainer = styled.div`
  margin-bottom: ${SPACING.M};
`

const CreditCardOptionContainer = styled.div`
  display: flex;
  margin-bottom: ${SPACING.S};
  > label {
    display: flex;
    align-items: baseline;
  }
  > label > ${StyledRadioButton} {
    margin-bottom: 0;
  }
  > label > ${StyledRadioButton} ~ div > div:first-child{
    display: flex;
    align-items: center;
    margin-bottom: 4px;
  }
  > label > ${StyledRadioButton} ~ div{
    padding-bottom: 16px;
    position: relative;
  }
  > label > ${StyledRadioButton} ~ div > ${SmallText}{
    position: absolute;
    white-space: nowrap;
  }
`
const StyledLink = styled(TextLink)`
  font-size: ${FONT_SIZE.MEDIUM};
  line-height: ${LINE_HEIGHT.MEDIUM};
  white-space: pre-line;
  ${props => !props.isMaster && `
    color: ${COLOR.GREY_DARK};
    cursor: not-allowed;
  `}
`
const StyledEditLink = styled(TextLink)`
font-size: ${FONT_SIZE.MEDIUM};
line-height: ${SPACING.M};
white-space: pre-line;
height: ${SPACING.M};
align-self: flex-start;
`

const CardLimitIndicator = styled.p`
  font-size: ${FONT_SIZE.LARGE};
  color: ${COLOR.GREY_DARK}
`

export {
  StyledRadioButton,
  OptionContainer,
  CreditCardContainer,
  CreditCardOptionContainer,
  StyledLink,
  StyledEditLink,
  CardLimitIndicator
}
