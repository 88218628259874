import Constants from '../constants/action'

const initialState = {
  addresses: [],
  selectedAddresses: [],
  nextPage: null,
  prevPage: null,
  currentPage: 1,
  totalPages: 1,
  loading: true,
  q: null,
  addressesSortBy: 'created_at',
  addressesSortAsc: false,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case Constants.CLEAR_ADDRESSES:
      return initialState

    case Constants.SET_ADDRESSES: {
      return {
        ...state,
        addresses: action.addresses.data,
        prevPage: action.addresses.meta.prev_page,
        nextPage: action.addresses.meta.next_page,
        currentPage: action.addresses.meta.current_page,
        totalPages: action.addresses.meta.total_pages,
        totalCount: action.addresses.meta.total_count,
        included: action.addresses.included,
        q: action.q,
        loading: false,
      }
    }

    case Constants.SET_ADDRESSES_FETCHING: {
      return {
        ...state,
        loading: true,
        currentPage: state.currentPage,
        totalPages: state.totalPages,
        totalCount: state.totalCount,
      }
    }

    case Constants.SET_ADDRESSES_ERROR:
      return { ...state, loading: false }

    default:
      return state
  }
}
