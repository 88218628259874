import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getLocale } from '../../utils/locale'
import { apiLanguageCode } from '../../locales/conversions'
import translate from '../../locales/translate'
import UserActions from '../../actions/users'

const LanguageToggle = props => {
  const { style, translationKey, translationPrefix, changeLanguage } = props
  const linkText = translate(`${translationPrefix}.${translationKey}`)

  return (
    <a href="#" onClick={changeLanguage} style={style}>
      {linkText}
    </a>
  )
}

LanguageToggle.propTypes = {
  style: PropTypes.object,
  translationKey: PropTypes.string.isRequired,
  translationPrefix: PropTypes.string.isRequired,
  changeLanguage: PropTypes.func,
}

const mapDispatchToProps = dispatch => ({
  changeLanguage: () => {
    const newLanguage = getLocale() === 'zh-hk' ? 'en-hk' : 'zh-hk'
    const language = apiLanguageCode(newLanguage)
    dispatch(UserActions.updateUserLanguage(language))
  },
})

export default connect(null, mapDispatchToProps)(LanguageToggle)
