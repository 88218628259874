import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Instructions, TopupButton } from './external-topup.component.styles'

const ExternalTopup = ({ currentUser }) => {
  const [t] = useTranslation()
  const {
    email: userEmail,
    meta: { phone_number: userPhone } = {},
    organization,
  } = currentUser
  const { id: organizationId, name: organizationName } = organization

  const userEmailEncoded = encodeURIComponent(userEmail)
  const organizationNameEncoded = encodeURIComponent(organizationName)

  let externalUrl = `${process.env.EXTERNAL_TOPUP_URL}/?org_id=${organizationId}&org_name=${organizationNameEncoded}&user_email=${userEmailEncoded}`

  if (userPhone) {
    const userPhoneEncoded = encodeURIComponent(userPhone)
    externalUrl = `${externalUrl}&phone_number=${userPhoneEncoded}`
  }

  return (
    <>
      <Instructions>{t('topup.with.credit.card.instruction')}</Instructions>
      <TopupButton forwardedAs="a" target="_blank" href={externalUrl}>
        {t('topup.with.credit.card.button')}
      </TopupButton>
    </>
  )
}

ExternalTopup.propTypes = {
  currentUser: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  currentUser: state.session.currentUser,
})

const ConnectedExternalTopup = connect(mapStateToProps)(ExternalTopup)

export { ConnectedExternalTopup }
