import styled from 'styled-components'
import PropTypes from 'prop-types'

import { COLOR } from '../../design/color/color.constants'
import { FONT_BOLD } from '../../design/typography/typography.constants'

const Badge = styled.span`
  display: inline-block;
  padding: 2px;
  background-color: ${props => COLOR[props.backgroundColor.toUpperCase()]};
  border-radius: 2px;
  text-transform: uppercase;
  ${FONT_BOLD};
  font-size: 8px;
  color: ${props => COLOR[props.foregroundColor.toUpperCase()]};
`

Badge.displayName = 'Badge'

Badge.propTypes = {
  children: PropTypes.string.isRequired,
  foregroundColor: PropTypes.string,
  backgroundColor: PropTypes.string,
}

Badge.defaultProps = {
  foregroundColor: 'BLACK',
  backgroundColor: 'YELLOW_MEDIUM',
}

export { Badge }
