import styled from "styled-components"

import { COLOR } from "../../../../component-library/design/color/color.constants"
import { SPACING } from "../../../../component-library/design/spacing/spacing.constants"
import {
  FONT_SIZE,
  FONT_BOLD,
} from "../../../../component-library/design/typography/typography.constants"
import checkPin from "./assets/check.svg"
import crossPin from "./assets/cross.svg"

const iconStyles = `
  background-color: ${COLOR.WHITE};
  margin-right: ${SPACING.XS};
  flex-grow: 0;
  flex-shrink: 0;
  width: ${SPACING.M};
`

const CheckPinIcon = styled(checkPin)`
  ${iconStyles};
`

const CrossPinIcon = styled(crossPin)`
  ${iconStyles};
`

const StatusIndicatorTrailStyles = `
  content: '';
  position: absolute;
  top: 22px;
  left: 11px;
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: ${COLOR.GREY_LIGHT};
  z-index: 1;
  bottom: -2px;
`

const StatusIndicatorTitle = styled.span`
  ${FONT_BOLD};
  font-size: ${FONT_SIZE.MEDIUM};
`

const StatusIndicatorContentWrapper = styled.div`
  &:before {
    ${StatusIndicatorTrailStyles};
  }
`

const StatusIndicatorWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: top;
  padding-bottom: 18px;

  &:last-child {
    ${StatusIndicatorContentWrapper} {
      &:before {
        border-left-style: none;
      }
    }
  }
`

export {
  StatusIndicatorWrapper,
  CheckPinIcon,
  CrossPinIcon,
  StatusIndicatorContentWrapper,
  StatusIndicatorTitle,
}
