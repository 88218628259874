import styled from "styled-components"

import { Body, BodyBold } from "../../design/typography/typography.styles"
import { SPACING } from "../../design/spacing/spacing.constants"
import { COLOR } from "../../design/color/color.constants"

const HiddenRadioButton = styled.input.attrs(() => ({ type: "radio" }))`
  display: none;
`

const DisplayedRadioButton = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: ${SPACING.S};
  opacity: ${({ disabled }) => disabled && 0.38};
`

const RadioButtonContainer = styled.div`
  display: inline-flex;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
  user-select: none;
`

const StyledBody = styled(Body)`
  margin: 0;
  color: ${(props) => props.disabled && COLOR.GREY_MEDIUM};
`

const StyledBodyBold = styled(BodyBold)`
  margin: 0;
  color: ${(props) => props.disabled && COLOR.GREY_MEDIUM};
`

const RadioButtonGroupContainer = styled.div`
  [data-testid|="radio-button"] {
    margin-bottom: ${SPACING.XS};

    :last-of-type {
      margin-bottom: 0;
    }
  }
`

export {
  HiddenRadioButton,
  DisplayedRadioButton,
  RadioButtonContainer,
  StyledBody,
  StyledBodyBold,
  RadioButtonGroupContainer,
}
