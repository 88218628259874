import styled from "styled-components"

import { COLOR } from "ggx-componentlibrary/design/color/color.constants"
import { SPACING } from "ggx-componentlibrary/design/spacing/spacing.constants"
import { TextLink } from "ggx-componentlibrary/design/typography/typography.styles"
import UpgradeIconSVG from "../assets/pro-upgrade-icon.svg"
import ExternalLinkIconSVG from "../../assets/external_link.svg"

const Container = styled.div`
  background-color: ${COLOR.GREY_LIGHTER};
  padding: ${SPACING.S} ${SPACING.S};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${COLOR.GREY_LIGHT};
  border-radius: 4px;
`

const UpgradeIcon = styled(UpgradeIconSVG)`
  margin-right: ${SPACING.XS};
`

const UpgradeLink = styled(TextLink)`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const ExternalLinkIcon = styled(ExternalLinkIconSVG)`
  margin-left: 4px;
`

export { Container, UpgradeIcon, ExternalLinkIcon, UpgradeLink }
