import styled from "styled-components"

import { Button } from "../../../component-library/components/button/button.component"
import { Body } from "../../../component-library/design/typography/typography.styles"
import { SPACING } from "../../../component-library/design/spacing/spacing.constants"

const Instructions = styled(Body)`
  margin-bottom: ${SPACING.S};
`

const TopupButton = styled(Button)`
  display: inline-block;
`

export { Instructions, TopupButton }
