import styled from "styled-components"

import { COLOR } from "ggx-componentlibrary/design/color/color.constants"
import { SPACING } from "ggx-componentlibrary/design/spacing/spacing.constants"
import { FONT_SIZE } from "ggx-componentlibrary/design/typography/typography.constants"
import { Button } from "ggx-componentlibrary/components/button/button.component"
import {
  RadioButtonGroup,
  RadioButton,
} from "ggx-componentlibrary/components/radio-button/radio-button.component"
import { BodyBold } from "ggx-componentlibrary/design/typography/typography.styles"
import { TextField } from "ggx-componentlibrary/components/text-field/text-field.component"

const Container = styled.section`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: ${(props) => props.headerBannerHeight || "70px"};
  right: ${SPACING.XS};
  background-color: ${COLOR.WHITE};
  width: 500px;
  max-height: 90vh;
  padding: ${SPACING.S} 0;
  margin-bottom: ${SPACING.S};
  overflow: scroll;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
`

const ConfigureFeatureFlagSectionWrapper = styled.div`
  display: flex;
`

const FeatureFlagList = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-right: ${SPACING.S};
`

const StyledRadioButtonGroup = styled(RadioButtonGroup)`
  [role="radiogroup"] {
    display: flex;
    justify-content: flex-start;
  }

  [role="radio"] {
    margin-right: ${SPACING.S};
    width: 60px;
  }
`

const StyledRadioButton = styled(RadioButton)`
  padding: 0;
  margin-right: ${SPACING.XS};
`

const StyledButton = styled(Button)`
  margin-bottom: ${SPACING.S};
`

const DebugMenuItem = styled.a`
  position: fixed;
  right: ${SPACING.S};
  top: 0;
  color: ${COLOR.RED_MEDIUM};
  font-size: ${FONT_SIZE.XXXLARGE};
  cursor: pointer;
  z-index: 100;
`

const StyledTable = styled.table`
  width: 100%;
`

StyledTable.Header = styled.th`
  font-size: ${FONT_SIZE.SMALL};
  font-weight: 700;
  padding: 2px 2px;
`

StyledTable.Row = styled.tr`
  border-bottom: 1px solid ${COLOR.GREY_LIGHT};
`

StyledTable.Data = styled.td`
  font-size: ${FONT_SIZE.SMALL};
  padding: 2px 2px;
  border-right: 1px solid ${COLOR.GREY_LIGHT};
  ${(props) => props.enabled && `background-color: ${COLOR.BLUE_MEDIUM};`}
  ${(props) => props.button && !props.enabled && "opacity: 0.2;"}
  ${(props) => props.button && "cursor: pointer;"}
`

const StyledTextField = styled(TextField)`
  margin-bottom: ${SPACING.S};
`

export {
  Container,
  StyledRadioButtonGroup,
  StyledRadioButton,
  FeatureFlagList,
  StyledButton,
  StyledTable,
  ConfigureFeatureFlagSectionWrapper,
  DebugMenuItem,
  StyledTextField,
}
