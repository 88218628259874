import { Api } from '../../legacy/utils/api'

const _selfRegistrationApi = new Api(Api.BASE_URL.B2B)
const _selfRegistrationGeneralApi = new Api(Api.BASE_URL.API_URL)

const selfRegistrationService = {
  activateOrganisationAccount: async values => {
    const method = 'post'
    const endpoint = '/users/signup/activate'

    try {
      const result = await _selfRegistrationApi.request(
        method,
        endpoint,
        null,
        values
      )
      return result.data
    } catch (error) {
      throw error
    }
  },
  setPassword: async values => {
    const method = 'put'
    const endpoint = '/users/reset_password'

    try {
      const result = await _selfRegistrationGeneralApi.request(
        method,
        endpoint,
        null,
        values
      )
      return result.data
    } catch (error) {
      throw error
    }
  },
  signup: async values => {
    const method = 'post'
    const endpoint = '/users/signup'

    try {
      const result = await _selfRegistrationApi.request(
        method,
        endpoint,
        null,
        values
      )
      return result.data
    } catch (error) {
      const { response: { data = {} } = {} } = error
      const { errors = [] } = data
      throw errors
    }
  },
}

export {
  _selfRegistrationApi,
  _selfRegistrationGeneralApi,
  selfRegistrationService,
}
