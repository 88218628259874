import PropTypes from 'prop-types'

const MIXPANEL_EVENT = {
  AUTH: {
    LOGIN_LANDING: 'login landing', // When the user loads the page '/login'
  },
  ONBOARDING: {
    LANDING: 'GGB_Onboarding_Questions_Landing', // When the user sees the question popup for the first time
    STARTED: 'GGB_Onboarding_Questions_Started', // When the user clicks 'Yes, start tutorial"
    SKIPPED: 'GGB_Onboarding_Questions_Skipped', // When the user clicks "Skip tutorial" after starting the quiz. This does not trigger if they skip it on the landing page, we won't track that because there will be an implied dropoff in the funnel.
    COMPLETED: 'GGB_Onboarding_Questions_Completed', // When the user clicks "Got it" after completing all questions and receiving a result
  },
  TRANSPORT: {
    PLACE_ORDER: 'GGB_Place_Order', // When the client SUCCESSFULLY places the orderby clicking the 'place order' CTA
    DOWNLOAD_REPORT: 'GGXB_Order_Management_Download_Report', // When the client clicks the "Download Report" on the order management page
    STEPS: {
      ORDER_INFO_CONFIRMED: 'GGB_Order Info Confirmed',
      ORDER_DETAILS_CONFIRMED: 'GGB_Order Details Confirmed',
      ORDER_CONFIRMED: 'GGB_Order Confirmed',
    },
  },
  DELIVERY: {
    LANDING: 'GGD_New Order', // When the user loads the page "/delivery/order"
    PLACE_ORDER: 'GGD_Place Order', // Not tracking now.
    ORDER_CONFIRMED: 'GGD_Place_Order_Order_Confirmed', // When the client SUCCESSFULLY places the orderby clicking the 'place order' CTA
    PAGE_LINK_CLICKED: 'GGD_Place_Order_Link_Clicked', // When the client clicks any link/hyperlink embedded in the page
    FORM: {
      ADDRESS_INPUT: 'GGD_Place_Order_Address_Input', // When Pick-up form focused
      PACKAGE_INFORMATION: 'GGD_Place_Order_Package_Information', // When Package details form focused
      SCHEDULE_ORDER: 'GGD_Place_Order_Schedule_Order', // When Schedule time form focused
      PACKAGE_PHOTO_UPLOAD: 'GGD_Place_Order_Package_Photo_Upload', // When a user successfully uploads a photo OR attempts to and an error is returned
      DROPOFF_TIME_SELECTED: 'GGD_Place_Order_Dropoff_Time_Selected', // When the client clicks any of the dropoff time options on the page
    },
    DETAILS: {
      PRINT_LABEL: 'GGD_Print_Single_Label', // Click on the 'Print shipping label' button on '/delivery/order-management' > order details
      ADD_BONUS: 'GGD_Order_Details_Add_Bonus', // When the client SUCCESSFULLY clicks 'Save' after selecting a bonus for the courier
      PAGE_LINK_CLICKED: 'GGD_Order_Details_Link_Clicked', // When the client clicks any link/hyperlink embedded in the page
    },
    ORDER_MANAGEMENT: {
      LANDING: 'GGD_Order_Management', // When the user loads the page "/delivery/order-management"
      PRINT_SELECTED_LABEL: 'GGD_Print_Selected_Label', // Click on the 'Print shipping label' button on '/delivery/order-management'
      DOWNLOAD_REPORT: 'GGD_Order_Management_Download_Report',
    },
    BULK_ORDER: {
      PLACE_ORDER: 'GGD_Place_Bulk_Order', // Click on the 'Place order' button on '/delivery/bulk-import'
      PRINT_LABEL: 'GGD_Print_Bulk_Label', // Click on the 'Print shipping label' button on '/delivery/bulk-import/complete'
      PAGE_LOADED: 'GGD_Bulk_Order', // When the user loads the page "/delivery/bulk-import"
      DOWNLOAD_TEMPLATE: 'GGD_Download_Bulk_Import_Template', // Click on the 'Download bulk import template (.xlsx)' button on '/delivery/bulk-import'
      BULK_IMPORT_STATUS: 'GGD_Bulk_Import_Status', // Track the success of bulk-imported orders and identify reasons for any failures.
      // Click on the 'Edit all' button on '/delivery/bulk-import' table header
      BULK_EDIT_PICKUP_NAME: 'GGD_Bulk_Edit_Pickup_Name',
      BULK_EDIT_PICKUP_NUMBER: 'GGD_Bulk_Edit_Pickup_Number',
      BULK_EDIT_PICKUP_ADDRESS: 'GGD_Bulk_Edit_Pickup_Address',
      BULK_EDIT_PICKUP_FLOOR: 'GGD_Bulk_Edit_Pickup_Floor', // Not tracking now.
      BULK_EDIT_DROPOFF_NAME: 'GGD_Bulk_Edit_Dropoff_Name',
      BULK_EDIT_DROPOFF_NUMBER: 'GGD_Bulk_Edit_Dropoff_Number',
      BULK_EDIT_DROPOFF_ADDRESS: 'GGD_Bulk_Edit_Dropoff_Address',
      BULK_EDIT_DROPOFF_FLOOR: 'GGD_Bulk_Edit_Dropoff_Floor', // Not tracking now.
      BULK_EDIT_PACKAGE_CONTENT: 'GGD_Bulk_Edit_Content',
      BULK_EDIT_PACKAGE_SIZE: 'GGD_Bulk_Edit_Size',
      BULK_EDIT_PACKAGE_WEIGHT: 'GGD_Bulk_Edit_Weight',
      BULK_EDIT_REMARK: 'GGD_Bulk_Edit_Remark',
      BULK_EDIT_PICKUP_TIME: 'GGD_Bulk_Edit_Pickup_Time',
      BULK_EDIT_DROPOFF_TIME: 'GGD_Bulk_Edit_Dropoff_Time',
    },
  },
  INVENTORY: {
    // Users are currently unable to access the Inventory page unless they know the exact route to the page.
    LANDING: 'open inventory', // Not tracking now. Click the 'inventory' tab in the sidebar
    CLICK_CTA_BUTTON: 'import products', // Click the 'import products' CTA on the inventory page
  },
  ECOMMERCE_INTEGRATION: {
    LANDING: 'GGD_eCommerce_Integration_Landing', // When the client successfully lands on the `eCommerce Integration` page for GGD
    GET_STARTED: 'GGD_eCommerce_Integration_Get_Started', // When the client clicks on `Get Started` on the eCommerce Integration page for GGD
    CLICK_CTA_BUTTON: 'GGD_eCommerce_Integration_Popup_CTA', // When the client clicks on "Sign me up!" in the eCommerce Integration popup OR when the client closes the eCommerce Integration popup in some way
  },
  TRANSACTION_HISTORY: {
    DOWNLOAD_REPORT: 'GGXB_Transaction_History_Download_Report', // Click on the 'Download report' button on '/billing-history'
  },
  USERS_AND_BRANCHES: {
    ENTER_PAGE: 'USERS_AND_BRANCHES',
    BRANCH_FILTER_SELECTED: 'Branch_filter_selected',
    ACCESS_LEVEL_FILTER_SELECTED: 'Access_level_filter_selected',
    STATUS_FILTER_SELECTED: 'Status_filter_selected',
    EDIT_USERS_BUTTON_TAPPED: 'Edit_users_button_tapped',
    ADD_NEW_USER_BUTTON_TAPPED: 'Add_new_user_button_tapped',
    NEW_USER_SAVED: 'New_user_saved',
    EDIT_INFO_BUTTON_TAPPED: 'Edit_info_button_tapped',
    EDIT_USER_INFO_SAVE_BUTTON_TAPPED: 'Edit_User_info_save_button_tapped',
    USER_TABLE_SORTED_ON_NAME: 'User_table_sorted_on_name',
    USER_TABLE_SORTED_ON_BRANCH: 'User_table_sorted_on_branch',
    USER_TABLE_SORTED_ON_ACCESS_LEVEL: 'User_table_sorted_on_access_level',
    USER_TABLE_SORTED_ON_STATUS: 'User_table_sorted_on_status',
  },
}

// helpers
const lastLoginKey = userId => `user_${userId}_lastLogin`

const identify = userId => {
  if (window.mixpanel) mixpanel.identify(userId)
}

const registerSuper = properties => {
  if (window.mixpanel) mixpanel.register(properties)
}

const peopleSet = properties => {
  if (window.mixpanel) mixpanel.people.set(properties)
}

const track = (event, properties) => {
  if (window.mixpanel) mixpanel.track(event, properties)
}

const trackLinks = (query, eventName, properties) => {
  if (window.mixpanel) mixpanel.track_links(query, eventName, properties)
}

const trackForms = (query, eventName, properties) => {
  if (window.mixpanel) mixpanel.track_forms(query, eventName, properties)
}

identify.propTypes = {
  userId: PropTypes.number.isRequired,
}

registerSuper.propTypes = {
  properties: PropTypes.object.isRequired,
}

peopleSet.propTypes = {
  properties: PropTypes.object.isRequired,
}

track.propTypes = {
  event: PropTypes.string.isRequired,
  properties: PropTypes.object,
}

trackLinks.propTypes = {
  query: PropTypes.string.isRequired,
  eventName: PropTypes.string.isRequired,
  properties: PropTypes.object,
}

trackForms.propTypes = {
  query: PropTypes.string.isRequired,
  eventName: PropTypes.string.isRequired,
  properties: PropTypes.object,
}

export {
  identify,
  lastLoginKey,
  peopleSet,
  registerSuper,
  track,
  trackForms,
  trackLinks,
  MIXPANEL_EVENT,
}
