import React from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
  clearMessages,
  getNotifications,
} from 'ggx-src/legacy/actions/notification'
import { toggleNotificationCentre } from 'ggx-src/legacy/actions/header'

import {
  getNotificatonsUnreadCount,
  getIsNotificationCentreOpen,
} from 'ggx-global/duck/selectors'
import { getFeatureFlag } from 'ggx-service/feature-flag/selectors'

import { Label } from '../navigation.component.styles'
import {
  BellIcon,
  ChevronIcon,
  NotificationWrapper,
  NotificationRow,
  NotificationIconContainer,
  InlineNotificationCount,
  CollapsedNotificationCount,
} from './notification.component.styles'

const Notification = ({
  isNavigationCollapsed,
  isNavigationHovered,
  onMouseEnter,
}) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const isNotificationCentreOpen = useSelector(state =>
    getIsNotificationCentreOpen(state)
  )
  const featureFlagGGBWebTransportNid = useSelector(state =>
    getFeatureFlag({ state, featureFlag: 'ggb_web_transport_nid' })
  )
  const unreadCount = useSelector(state => getNotificatonsUnreadCount(state))

  const handleShowNotificationCentre = () => {
    dispatch(clearMessages())
    dispatch(getNotifications(1, 8, { featureFlagGGBWebTransportNid }))
    dispatch(toggleNotificationCentre())
  }

  return (
    <NotificationWrapper onMouseEnter={onMouseEnter}>
      <NotificationRow
        onClick={handleShowNotificationCentre}
        $isActive={isNotificationCentreOpen}
        $isNavigationCollapsed={isNavigationCollapsed}
        $isNavigationHovered={isNavigationHovered}
        data-testid="navigation-bar-notification-switch"
      >
        <NotificationIconContainer>
          {unreadCount > 0 && (
            <CollapsedNotificationCount
              $isNavigationCollapsed={isNavigationCollapsed}
              $isNavigationHovered={isNavigationHovered}
              typographyStyles="mb-none"
            >
              {unreadCount || 0}
            </CollapsedNotificationCount>
          )}
          <BellIcon />
        </NotificationIconContainer>
        <Label>{t('common__notifications')}</Label>
        {(!isNavigationCollapsed || isNavigationHovered) && unreadCount > 0 && (
          <InlineNotificationCount typographyStyles="mb-none">
            {unreadCount || 0}
          </InlineNotificationCount>
        )}
        <ChevronIcon />
      </NotificationRow>
    </NotificationWrapper>
  )
}

Notification.propTypes = {
  isNavigationCollapsed: PropTypes.bool,
  isNavigationHovered: PropTypes.bool,
}

export default Notification
