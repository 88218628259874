import moment from 'moment-timezone'
import i18next from 'i18next'

import { getMomentTimeZone } from '../locale/timezone'

const currentTimezone = getMomentTimeZone()

const formatDateTime = (ISODateTime, format = '', showMinutes = true) => {
  const timezoneAdjustedTime = moment.tz(ISODateTime, currentTimezone)
  const timeFormat = showMinutes
    ? `, ${i18next.t('common__timestamp__h_mm_12hr')}`
    : ''

  if (format) return timezoneAdjustedTime.format(format)

  moment.calendarFormat = (myMoment, now) => {
    const diff = myMoment.diff(now, 'days', true)

    if (diff < -1) return 'sameElse'
    if (diff < 0) return 'lastDay'
    if (diff < 1) return 'sameDay'
    if (diff < 2) return 'nextDay'
    return 'sameElse'
  }

  return moment(timezoneAdjustedTime).calendar(null, {
    lastDay: `${i18next.t('common__timestamp__yesterday_d_mmm')}${timeFormat}`,
    sameDay: `${i18next.t('common__timestamp__today_d_mmm')}${timeFormat}`,
    nextDay: `${i18next.t('common__timestamp__tomorrow_d_mmm')}${timeFormat}`,
    sameElse(now) {
      if (this.year() === now.year()) {
        return `${i18next.t('common__timestamp__weekday_d_mmm')}${timeFormat}`
      }
      return `${i18next.t(
        'common__timestamp__weekday_d_mmm_yyyy'
      )}${timeFormat}`
    },
  })
}

export { formatDateTime }
