import PropTypes from 'prop-types'
import { IconImg } from '../icon'

const Style = {
  image: {
    width: '50px',
    height: '50px',
  },
}

const LoadingSpinner = props => (
  <IconImg
    className="rotating"
    // Merge default style and inline style into a new object
    style={Object.assign({}, Style.image, props.style)}
    imgPath="general/icon_loading_small"
  />
)

LoadingSpinner.propTypes = {
  style: PropTypes.object,
}

export { LoadingSpinner }
