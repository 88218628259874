import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  AuthErrorContainer,
  StyledHeadingXL,
  StyledBody,
  StyledTextLink,
} from './auth-error.component.styles'
import StopIcon from './assets/stop.svg'

const AuthErrorView = () => {
  const [t] = useTranslation()

  return (
    <AuthErrorContainer>
      <StopIcon />
      <StyledHeadingXL>{t('no_permission__title')}</StyledHeadingXL>
      <StyledBody>{t('no_permission__description')}</StyledBody>
      <StyledTextLink to="/home">{t('back_to_home')}</StyledTextLink>
    </AuthErrorContainer>
  )
}

export default AuthErrorView
