// @flow

const Auth: { [key: string]: string } = {
  B2B_ADMIN_USER: 'b2b_admin_user',
  B2B_MASTER_USER: 'b2b_master_user',
  B2B_BRANCH_USER: 'b2b_branch_user',
  B2B_SUB_USER: 'b2b_sub_user',
  QUERY_TYPE_ACTIVATE: 'activate',
  QUERY_TYPE_RESET_PASSWORD: 'reset_password',
}

export default Auth
