const COLLAPSED_WIDTH = '56px'
const EXPANDED_WIDTH = '192px'
const COLLAPSED_TRANSATION_DURATION = '0.25s'
const EXPANDED_TRANSATION_DURATION = '0.3s'

const NAV_SIDEBAR_WIDTH = '240px'
const ACTIVE_NAV_ITEM_CLASSNAME = 'active-nav-item'

export {
  COLLAPSED_WIDTH,
  EXPANDED_WIDTH,
  COLLAPSED_TRANSATION_DURATION,
  EXPANDED_TRANSATION_DURATION,
  NAV_SIDEBAR_WIDTH,
  ACTIVE_NAV_ITEM_CLASSNAME,
}
