import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import {
  StyledModal,
  FadeInWrapper,
  StyledRadioButtonGroup,
  StyledSmallPackageIcon,
  StyledLargePackgeIcon,
  buttonStyle,
  Container,
  QuestionsContainer,
  ServiceCardsContainer,
  StyledServiceCard,
  FooterContainer,
  QuestionCount,
} from './service-selection-modal.styles'
import {
  HeadingXL,
  Heading,
  Body,
  SmallText,
} from 'ggx-componentlibrary/design/typography/typography.styles'
import { RadioButton } from 'ggx-componentlibrary/components/radio-button/radio-button.component'
import { Button } from 'ggx-componentlibrary/components/button/button.component'
import { apiService } from '../api/api.service'
import { ServiceCard } from '../service-card/service.card.component'
import {
  TransportIcon,
  DeliveryIcon,
} from '../service-card/service.card.component.styles'
import { onboardingTrackingService } from '../tracking.service'

const ANSWERS = {
  DROPOFF_SINGLE: '1-dropOff',
  DROPOFF_MULTI: 'multiple-dropOff',
  PACKAGE_SMALL: 'smallPackage',
  PACKAGE_LARGE: 'largePackage',
  DOOR_TO_DOOR_YES: 'yes',
  DOOR_TO_DOOR_NO: 'no',
}

const USER_SELECTIONS = {
  MULTI_SMALL_D2D_YES: `${ANSWERS.DROPOFF_MULTI}${ANSWERS.PACKAGE_SMALL}${ANSWERS.DOOR_TO_DOOR_YES}`,
  MULTI_SMALL_D2D_NO: `${ANSWERS.DROPOFF_MULTI}${ANSWERS.PACKAGE_SMALL}${ANSWERS.DOOR_TO_DOOR_NO}`,
  SINGLE_SMALL_D2D_YES: `${ANSWERS.DROPOFF_SINGLE}${ANSWERS.PACKAGE_SMALL}${ANSWERS.DOOR_TO_DOOR_YES}`,
  SINGLE_LARGE_D2D_YES: `${ANSWERS.DROPOFF_SINGLE}${ANSWERS.PACKAGE_LARGE}${ANSWERS.DOOR_TO_DOOR_YES}`,
  MULTI_LARGE_D2D_YES: `${ANSWERS.DROPOFF_MULTI}${ANSWERS.PACKAGE_LARGE}${ANSWERS.DOOR_TO_DOOR_YES}`,
  MULTI_LARGE_D2D_NO: `${ANSWERS.DROPOFF_MULTI}${ANSWERS.PACKAGE_LARGE}${ANSWERS.DOOR_TO_DOOR_NO}`,
  SINGLE_SMALL_D2D_NO: `${ANSWERS.DROPOFF_SINGLE}${ANSWERS.PACKAGE_SMALL}${ANSWERS.DOOR_TO_DOOR_NO}`,
  SINGLE_LARGE_D2D_NO: `${ANSWERS.DROPOFF_SINGLE}${ANSWERS.PACKAGE_LARGE}${ANSWERS.DOOR_TO_DOOR_NO}`,
}

const ServiceSelectionModal = ({
  handleModalClose,
  handleFeatureTourStart,
}) => {
  const [t] = useTranslation()
  const [option, setOption] = useState({
    dropOff: null,
    itemSize: null,
    doorTodoor: null,
  })

  const selectedOption = Object.values(option).filter(Boolean).length
  const total = Object.keys(option).length
  const currentState = selectedOption + 1 > total ? total : selectedOption + 1

  const getTransportMode = () => {
    const { dropOff, itemSize, doorTodoor } = option
    const chosenOptions = `${dropOff}${itemSize}${doorTodoor}`

    switch (chosenOptions) {
      case USER_SELECTIONS.MULTI_SMALL_D2D_YES:
      case USER_SELECTIONS.MULTI_SMALL_D2D_NO:
      case USER_SELECTIONS.SINGLE_SMALL_D2D_YES:
      case USER_SELECTIONS.SINGLE_SMALL_D2D_NO: {
        return ['Delivery', 'Transport']
      }
      default:
        return ['Transport']
    }
  }

  const handleOnboardingSkip = () => {
    handleModalClose()
    const trackingProperties = {
      questionMultipleLocations: option.dropOff || '',
      questionPackageSize: option.itemSize || '',
      questionDoorToDoor: option.doorTodoor || '',
    }

    onboardingTrackingService.onboardingQuestionsSkipped(trackingProperties)
  }

  const handleOnboardingComplete = () => {
    handleFeatureTourStart()
    const trackingProperties = {
      questionMultipleLocations: option.dropOff,
      questionPackageSize: option.itemSize,
      questionDoorToDoor: option.doorTodoor,
      finalResult: getTransportMode(),
    }

    onboardingTrackingService.onboardingQuestionsCompleted(trackingProperties)
  }

  const dropOffOption = [
    { label: t('onboarding__answers__first__option_one'), value: '1-dropOff' },
    {
      label: t('onboarding__answers__first__option_two'),
      value: 'multiple-dropOff',
    },
  ]

  const itemSizeOption = [
    {
      label: t('onboarding__answers__second__option_one'),
      value: 'smallPackage',
      icon: <StyledSmallPackageIcon />,
    },
    {
      label: t('onboarding__answers__second__option_two'),
      value: 'largePackage',
      icon: <StyledLargePackgeIcon />,
    },
  ]

  const serviceTypeOption = [
    { label: t('onboarding__answers__third__option_one'), value: 'yes' },
    { label: t('onboarding__answers__third__option_two'), value: 'no' },
  ]

  const footer = (
    <>
      <SmallText typographyStyles="mb-none">
        {t('question_counter', { current: currentState, total })}
      </SmallText>
      <Button buttonType="secondary" onClick={handleOnboardingSkip}>
        {t('button__onboarding__skip_tutorial')}
      </Button>
    </>
  )

  const footerAfterQuestionsAnswered = (
    <FooterContainer>
      <QuestionCount>
        {t('question_counter', { current: currentState, total })}
      </QuestionCount>
      <SmallText typographyStyles="mr-m mb-none">
        {t('text__onboarding__tip')}
      </SmallText>
      <Button onClick={handleOnboardingComplete}>
        {t('button__nice_got_it')}
      </Button>
    </FooterContainer>
  )

  const getServiceCards = () => {
    const { dropOff, itemSize, doorTodoor } = option
    const chosenOptions = `${dropOff}${itemSize}${doorTodoor}`

    let availableTextTransport
    let availableTextDelivery
    let detailsTransport = []
    let detailsDelivery = []

    switch (chosenOptions) {
      case USER_SELECTIONS.MULTI_SMALL_D2D_YES: {
        availableTextTransport = t('text__label__onboarding__available')
        availableTextDelivery = t('text__label__onboarding__available_cheapest')
        detailsTransport = [
          t('text__card__details__transport_multiple_drop-offs'),
          t('text__card__details__transport_door-to-door'),
        ]
        detailsDelivery = [
          t('text__card__details__delivery_multiple_drop-offs'),
        ]
        break
      }
      case USER_SELECTIONS.MULTI_SMALL_D2D_NO: {
        availableTextTransport = t('text__label__onboarding__available')
        availableTextDelivery = t('text__label__onboarding__available_cheapest')
        detailsTransport = [
          t('text__card__details__transport_multiple_drop-offs'),
        ]
        detailsDelivery = [
          t('text__card__details__delivery_multiple_drop-offs'),
        ]
        break
      }
      case USER_SELECTIONS.SINGLE_SMALL_D2D_YES: {
        availableTextTransport = t('text__label__onboarding__available')
        availableTextDelivery = t('text__label__onboarding__available_cheapest')
        break
      }
      case USER_SELECTIONS.SINGLE_LARGE_D2D_YES: {
        availableTextTransport = t('text__label__onboarding__available')
        detailsTransport = [t('text__card__details__transport_door-to-door')]
        detailsDelivery = [t('text__card__details__delivery_package_capacity')]
        break
      }
      case USER_SELECTIONS.MULTI_LARGE_D2D_YES: {
        availableTextTransport = t('text__label__onboarding__available')
        detailsTransport = [
          t('text__card__details__transport_multiple_drop-offs'),
          t('text__card__details__transport_door-to-door'),
        ]
        detailsDelivery = [t('text__card__details__delivery_package_capacity')]
        break
      }
      case USER_SELECTIONS.MULTI_LARGE_D2D_NO: {
        availableTextTransport = t('text__label__onboarding__available')
        detailsTransport = [
          t('text__card__details__transport_multiple_drop-offs'),
        ]
        detailsDelivery = [t('text__card__details__delivery_package_capacity')]
        break
      }
      case USER_SELECTIONS.SINGLE_SMALL_D2D_NO: {
        availableTextTransport = t('text__label__onboarding__available')
        availableTextDelivery = t('text__label__onboarding__available_cheapest')
        break
      }
      case USER_SELECTIONS.SINGLE_LARGE_D2D_NO: {
        availableTextTransport = t('text__label__onboarding__available')
        detailsDelivery = [t('text__card__details__delivery_package_capacity')]
        break
      }
      default:
        break
    }

    return (
      <>
        <StyledServiceCard
          cardImage={<TransportIcon />}
          availableText={availableTextTransport}
          content={{
            heading: t('common__transport_service'),
            message: t('description__card__transport'),
            details: detailsTransport,
          }}
          pricingTableLink="https://www.gogox.com/pricing/"
        />
        <ServiceCard
          cardImage={<DeliveryIcon />}
          availableText={availableTextDelivery}
          content={{
            heading: t('common__courier_delivery'),
            message: t('description__card__delivery'),
            details: detailsDelivery,
          }}
          pricingTableLink="https://s3-ap-northeast-1.amazonaws.com/wp-gogovan.com/wp-content/uploads/sites/2/2021/09/01111613/GGD-Price-Table_ENG_All.pdf"
        />
      </>
    )
  }

  const allQuestionsAnswered = !Object.values(option).some(
    value => value == null
  )
  const modalWidth = allQuestionsAnswered ? '1080px' : '444px'
  const modalHeight = '596px'
  const modalFooter = allQuestionsAnswered
    ? footerAfterQuestionsAnswered
    : footer

  return (
    <StyledModal
      width={modalWidth}
      height={modalHeight}
      showCloseButton={false}
      footer={modalFooter}
    >
      <Container>
        <QuestionsContainer>
          <HeadingXL typographyStyles="mb-m">
            {t('onboarding__questions__title')}
          </HeadingXL>
          <FadeInWrapper>
            <Heading typographyStyles="mb-s">
              {t('onboarding__questions__first')}
            </Heading>
            <StyledRadioButtonGroup
              titleId="dropOffOption"
              onSelectionChange={value =>
                setOption({ ...option, dropOff: value })
              }
              value={option.dropOff}
            >
              {dropOffOption.map((button, index) => (
                <RadioButton key={index} value={button.value}>
                  {button.label}
                </RadioButton>
              ))}
            </StyledRadioButtonGroup>
          </FadeInWrapper>

          {option.dropOff && (
            <FadeInWrapper>
              <Heading typographyStyles="mb-s">
                {t('onboarding__questions__second')}
              </Heading>
              <StyledRadioButtonGroup
                titleId="itemSizeOption"
                onSelectionChange={value =>
                  setOption({ ...option, itemSize: value })
                }
                value={option.itemSize}
                buttonStyle={buttonStyle}
              >
                {itemSizeOption.map((button, index) => (
                  <RadioButton key={index} value={button.value}>
                    {button.icon}
                    <Body>{button.label}</Body>
                  </RadioButton>
                ))}
              </StyledRadioButtonGroup>
            </FadeInWrapper>
          )}

          {option.itemSize && (
            <FadeInWrapper>
              <Heading typographyStyles="mb-s">
                {t('onboarding__questions__third')}
              </Heading>
              <StyledRadioButtonGroup
                titleId="serviceTypeOption"
                onSelectionChange={value =>
                  setOption({ ...option, doorTodoor: value })
                }
                value={option.doorTodoor}
              >
                {serviceTypeOption.map((button, index) => (
                  <RadioButton key={index} value={button.value}>
                    {button.label}
                  </RadioButton>
                ))}
              </StyledRadioButtonGroup>
            </FadeInWrapper>
          )}
        </QuestionsContainer>
        {allQuestionsAnswered && (
          <ServiceCardsContainer>{getServiceCards()}</ServiceCardsContainer>
        )}
      </Container>
    </StyledModal>
  )
}

ServiceSelectionModal.propTypes = {
  handleModalClose: PropTypes.func,
  handleFeatureTourStart: PropTypes.func,
}

export { ServiceSelectionModal }
