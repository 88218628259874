import styled from "styled-components"

import { Tooltip } from "ggx-componentlibrary/components/tooltip/tooltip.component"
import { SPACING } from "ggx-componentlibrary/design/spacing/spacing.constants"

import { IconImg } from "../../helpers"

const BalanceContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const DepositSection = styled(Tooltip)`
  margin-top: ${SPACING.M};
`

const DownloadIcon = styled(IconImg)`
  margin-right: ${SPACING.XS};
`

const DownloadReportContainer = styled.div`
  display: flex;
  align-self: end;
  margin-bottom: ${SPACING.S};
`

export {
  BalanceContainer,
  DepositSection,
  DownloadIcon,
  DownloadReportContainer,
}
