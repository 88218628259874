import { track, MIXPANEL_EVENT } from 'ggx-service/mixpanel/mixpanel'

const onboardingTrackingService = {
  onboardingQuestionsLanding() {
    const eventName = MIXPANEL_EVENT.ONBOARDING.LANDING
    track(eventName)
  },
  onboardingQuestionsStarted() {
    const eventName = MIXPANEL_EVENT.ONBOARDING.STARTED
    track(eventName)
  },
  onboardingQuestionsSkipped({
    questionMultipleLocations,
    questionPackageSize,
    questionDoorToDoor,
  }) {
    const eventName = MIXPANEL_EVENT.ONBOARDING.SKIPPED
    const properties = {
      question_multiple_locations: questionMultipleLocations,
      question_package_size: questionPackageSize,
      question_door_to_door: questionDoorToDoor,
    }
    track(eventName, properties)
  },
  onboardingQuestionsCompleted({
    questionMultipleLocations,
    questionPackageSize,
    questionDoorToDoor,
    finalResult,
  }) {
    const eventName = MIXPANEL_EVENT.ONBOARDING.COMPLETED
    const properties = {
      question_multiple_locations: questionMultipleLocations,
      question_package_size: questionPackageSize,
      question_door_to_door: questionDoorToDoor,
      final_result: finalResult,
    }
    track(eventName, properties)
  },
}

export { onboardingTrackingService }
