import React from 'react'
import PropTypes from 'prop-types'

import {
  TooltipWrapper,
  TooltipHeading,
  StyledTooltipIcon,
  ContentBox,
} from './tooltip.component.styles'

class Tooltip extends React.Component {
  static displayName = 'Tooltip'

  static propTypes = {
    heading: PropTypes.node.isRequired,
    content: PropTypes.node.isRequired,
    contentWidth: PropTypes.string,
    dataTestid: PropTypes.string,
    className: PropTypes.string,
    noMargin: PropTypes.bool,
    marginBottom: PropTypes.string,
    showTooltipIcon: PropTypes.bool,
    isHeadingHoverable: PropTypes.bool,
    disabled: PropTypes.bool,
  }

  state = {
    showContentBox: false,
    offsetContent: 0,
  }

  componentDidUpdate() {
    if (this.state.showContentBox && this.contentRef.current) {
      this.observer.observe(this.contentRef.current)
    }
  }

  hideContentBox = () => {
    this.setState({
      showContentBox: false,
    })
  }

  showContentBox = () => {
    this.setState({
      showContentBox: true,
    })
  }

  handleIntersectionEvent = ([entry]) => {
    if (entry.intersectionRatio <= 1 && entry.boundingClientRect.height > 0) {
      this.setState({ offsetContent: entry.boundingClientRect.height })
    } else {
      this.setState({ offsetContent: 0 })
    }
  }

  observer = new window.IntersectionObserver(this.handleIntersectionEvent)
  contentRef = React.createRef()

  render() {
    const {
      heading,
      content,
      contentWidth,
      dataTestid,
      className,
      noMargin,
      marginBottom,
      showTooltipIcon = true,
      isHeadingHoverable = false,
    } = this.props

    return (
      <TooltipWrapper data-testid={dataTestid} className={className}>
        <TooltipHeading
          noMargin={noMargin}
          marginBottom={marginBottom}
          onMouseEnter={isHeadingHoverable ? this.showContentBox : undefined}
          onMouseLeave={isHeadingHoverable ? this.hideContentBox : undefined}
        >
          {heading}
          {showTooltipIcon && (
            <StyledTooltipIcon
              onMouseEnter={
                isHeadingHoverable ? undefined : this.showContentBox
              }
              onMouseLeave={
                isHeadingHoverable ? undefined : this.hideContentBox
              }
            />
          )}
        </TooltipHeading>
        {!this.props.disabled && this.state.showContentBox && (
          <ContentBox
            offsetContent={this.state.offsetContent}
            ref={this.contentRef}
            contentWidth={contentWidth}
          >
            {content}
          </ContentBox>
        )}
      </TooltipWrapper>
    )
  }
}

export { Tooltip, TooltipHeading }
