import { storeNewLanguageAndReload } from '../../legacy/utils/language'
import { apiLanguageCode } from '../../legacy/locales/conversions'
import CountriesLocales from '../../legacy/locales/countries'
import { setAuthToken } from '../../legacy/views/helpers/auth/tokens'

const handleLanguageParameter = (nextState, replace, callback) => {
  const { query = {}, pathname } = nextState.location
  const {
    lang: locale,
    sessiontoken: sessionTokenUrlParameter,
    userid: userIdUrlParameter,
    ...rest
  } = query

  // create authToken when session token and user id are provided in URL parameters
  if (sessionTokenUrlParameter && userIdUrlParameter) {
    setAuthToken({
      data: {
        user: {
          id: userIdUrlParameter,
          session_token: sessionTokenUrlParameter,
        },
      },
    })
  }

  const country = localStorage.getItem('country') || ENV.GOGOVAN_COUNTRY
  const currentLocale = localStorage.getItem('locale')
  const isValidLanguage =
    CountriesLocales[country].includes(locale) && currentLocale !== locale

  if (isValidLanguage) {
    return storeNewLanguageAndReload(apiLanguageCode(locale))
  }

  if (typeof locale === 'string') {
    replace({ pathname, query: rest })
  }

  callback()
}

export { handleLanguageParameter }
