import styled from "styled-components"

import { COLOR } from "../../design/color/color.constants"
import {
  FONT_SIZE,
  FONT_REGULAR,
} from "../../design/typography/typography.constants"

const StripeElementStyles = `
  box-sizing: border-box;
  height: 40px;
  padding: 12px 16px;
  border: 1px solid ${COLOR.GREY_LIGHT};
  border-radius: 4px;
`

const CardDisplayWrapper = styled.div`
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  ${StripeElementStyles}
  ${FONT_REGULAR}
  font-size: ${FONT_SIZE.SMALL};
  color: ${COLOR.GREY_DARK};
  letter-spacing: 1px;
  ${(props) =>
    props.noBorderDisplayMode
      ? "height: auto; padding: 0; border: none;"
      : null};
`

const CardIcon = styled.div`
  width: 20px;
  height: 14px;
  margin-right: 6px;

  img {
    display: block;
    position: relative;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
  }
`

const CardNumberWrapper = styled.div`
  flex: 1;
  text-align: left;
`

const CardExpiryDateWrapper = styled.div`
  text-align: right;
`

export {
  CardDisplayWrapper,
  CardIcon,
  CardNumberWrapper,
  CardExpiryDateWrapper,
}
