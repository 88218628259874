import { compose, createStore, applyMiddleware, combineReducers } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { routerMiddleware } from 'react-router-redux'
import { createLogger } from 'redux-logger'
import { reducer as formReducer } from 'redux-form'

import reducers from './legacy/reducers'
import Constants from './legacy/constants/action'
import { deliveryReducers } from './delivery/duck/reducers'
import { vanBookingReducers } from './van-booking/duck/reducers'
import { billingReducers } from './billing/duck/reducers'
import { selfRegistrationReducers } from './self-registration/duck/reducers'
import { homepageAccessReducer } from './home/duck/reducers'
import { featureFlagGlobalReducers } from './global/duck/reducers'
import { developerReducers } from './developer/duck/reducers'
import { orderManagementReducers } from './order-management/duck/reducers'
import { featureFlagReducers } from './service/feature-flag/reducers'
import { paymentReducers } from './payment/duck/reducers'
import { subscriptionReducer } from './paid-subscription-plan/duck/reducers'
import { profileReducers } from './profile/duck/reducers'
import { NDDFormDataReducer } from './next-day-delivery/duck/reducers'

const rootReducer = (state, action) => {
  if (action.type === Constants.USER_LOGGED_OUT) {
    // https://gogotech.atlassian.net/browse/BET-966
    // persist feature flag on logged out
    const { featureFlagReloadPage } = state
    state = { featureFlagReloadPage }
  }
  return combineReducers({
    ...reducers,
    ...deliveryReducers,
    ...vanBookingReducers,
    ...selfRegistrationReducers,
    ...billingReducers,
    ...featureFlagGlobalReducers,
    ...developerReducers,
    ...orderManagementReducers,
    ...featureFlagReducers,
    payment: paymentReducers,
    ...subscriptionReducer,
    ...profileReducers,
    ...NDDFormDataReducer,
    form: formReducer,
    featureFlagHomepageAccess: homepageAccessReducer,
  })(state, action)
}

const middlewares = [thunkMiddleware]

if (ENV.ENABLE_REDUX_LOGGER) {
  const loggerMiddleware = createLogger({
    level: 'info',
    collapsed: true,
  })
  middlewares.push(loggerMiddleware)
}

// HACK
export const appStore = {
  store: null,
  getStore: () => appStore.store,
  setStore: store => {
    appStore.store = store
  },
  getState: () => appStore.getStore().getState(),
}

export default function configureStore(browserHistory) {
  const reduxRouterMiddleware = routerMiddleware(browserHistory)
  const createStoreWithMiddleware = ENV.ENABLE_DEBUG
    ? // eslint-disable-next-line global-require
      require('./service/redux-devtools/setup').composeReduxDevTools(
        applyMiddleware(reduxRouterMiddleware, ...middlewares)
      )(createStore)
    : compose(applyMiddleware(reduxRouterMiddleware, ...middlewares))(
        createStore
      )

  const store = createStoreWithMiddleware(rootReducer)
  appStore.setStore(store)
  return store
}
