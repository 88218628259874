import { Api } from '../../legacy/utils/api'

const _api = new Api(Api.BASE_URL.B2B)

const apiService = {
  getTopupPackages: async () => {
    const method = 'get'
    const endpoint = '/prepaid_wallets/topup_packages'

    try {
      const result = await _api.request(
        method,
        endpoint,
        null,
        null,
        null,
        null,
        null
      )
      return result.data
    } catch (error) {
      throw error
    }
  },

  getPrepaidBalance: async () => {
    const method = 'get'
    const endpoint = '/prepaid_wallets'

    try {
      const result = await _api.request(
        method,
        endpoint,
        null,
        null,
        null,
        null,
        null
      )
      return result.data
    } catch (error) {
      throw error
    }
  },

  addCredit: async (topupPackageId, paymentIntentId = null, stripeCardId) => {
    const method = 'post'
    const endpoint = '/prepaid_wallets/add_credit'

    try {
      const data = {
        topup_package_id: topupPackageId,
        stripe_payment_intent_id: paymentIntentId,
        stripe_card_id: stripeCardId,
      }
      const result = await _api.request(method, endpoint, null, data)
      return result.data
    } catch (error) {
      throw error
    }
  },

  getTransactionsHistory: async (params = {}) => {
    const method = 'get'
    const endpoint = '/transaction_history'

    try {
      const result = await _api.request(
        method,
        endpoint,
        null,
        null,
        null,
        null,
        params
      )
      return result.data
    } catch (error) {
      throw error
    }
  },

  downloadTransactionHistoryReport: async (params = {}) => {
    const method = 'post'
    const endpoint = '/transaction_history/download_excel'
    const config = { responseType: 'blob' }

    try {
      const result = await _api.request(
        method,
        endpoint,
        null,
        null,
        null,
        config,
        params
      )
      return result.data
    } catch (error) {
      throw error
    }
  },

  getPayNowQRCodeURL: async packageId => {
    const method = 'post'
    const endpoint = '/prepaid_wallets/top_up_by_paynow'

    try {
      const result = await _api.request(method, endpoint, null, {
        topup_package_id: packageId,
      })
      return result.data?.qrcode_url
    } catch (error) {
      throw error
    }
  },
}

export { _api, apiService }
