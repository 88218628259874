import styled from "styled-components"

import {
  COLLAPSED_WIDTH,
  EXPANDED_WIDTH,
  COLLAPSED_TRANSATION_DURATION,
  EXPANDED_TRANSATION_DURATION,
} from "ggx-global/navigation/navigation.constants"
import {
  COLLAPSED_WIDTH as COLLAPSED_WIDTH_V2,
  EXPANDED_WIDTH as EXPANDED_WIDTH_V2,
  COLLAPSED_TRANSATION_DURATION as COLLAPSED_TRANSATION_DURATION_V2,
  EXPANDED_TRANSATION_DURATION as EXPANDED_TRANSATION_DURATION_V2,
} from "ggx-global/navigation/v2/navigation.constants"

const MainContainer = styled.div`
  display: flex;
  position: relative;
  width: 100vw;
  height: 100vh;
`

const ContentWrapper = styled.div`
  ${(props) => {
    const collapsedWidth = props.$featureFlagSidebarEnhancement ? COLLAPSED_WIDTH_V2 : COLLAPSED_WIDTH;
    const expandedWidth = props.$featureFlagSidebarEnhancement ? EXPANDED_WIDTH_V2 : EXPANDED_WIDTH;
    const collapsedTransationDuration = props.$featureFlagSidebarEnhancement ? COLLAPSED_TRANSATION_DURATION_V2 : COLLAPSED_TRANSATION_DURATION;
    const expandedTransationDuration = props.$featureFlagSidebarEnhancement ? EXPANDED_TRANSATION_DURATION_V2 : EXPANDED_TRANSATION_DURATION;

    return `
      position: absolute;
      right: 0px;
    
      width: calc(100vw - ${
        props.$isNavigationCollapsed ? collapsedWidth : expandedWidth
      });
      height: 100vh;
      overflow: auto;
      will-change: width;
      transition-property: width;
      transition-duration: ${
        props.$isNavigationCollapsed
          ? collapsedTransationDuration
          : expandedTransationDuration
      };
      transition-timing-function: ${
        props.$isNavigationCollapsed ? "ease-in" : "ease-out"
      };
    `
  }}
`

export { MainContainer, ContentWrapper }
