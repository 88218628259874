import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import {
  readMessage,
  getNotifications,
  notificationCancelOrderRequest,
} from '../actions/notification'
import Actions from '../actions/order_requests'
import { MessagesCentre } from '../../global/notifications'

const mapStateToProps = state => ({
  currentPage: state.notification.currentPage,
  isLoading: state.notification.isLoading,
  isLoadingAction: state.notification.isLoadingAction,
  orderRequests: state.orderRequests.orderRequests,
  included: state.orderRequests.included,
})

const mapDispatchToProps = dispatch => ({
  onLoadMoreClick: (page, prePage, option = {}) => {
    dispatch(getNotifications(page, prePage, option))
  },
  onOrderIdClick: (orderRequestEventId, id, included, payload) => {
    dispatch(readMessage(orderRequestEventId || payload.deliveryOrderUuid))
    if (id) {
      dispatch(Actions.selectOrderRequest(id.toString(), included))
      dispatch(Actions.toggleOpenDetail())
      dispatch(push(`/orders/${id}`))
    } else if (payload.deliveryOrderUuid && payload.deliveryOrderTid) {
      dispatch(
        push({
          pathname: '/delivery/order-management',
          state: {
            uuid: payload.deliveryOrderUuid,
            tid: payload.deliveryOrderTid,
          },
        })
      )
    }
  },
  // TODO: if selectedOrderRequest add dispatch updateSelectedOrderRequest?
  // its likely the dialog status will not update to be cancelled
  onCancelOrderClick: orderRequestId => {
    dispatch(notificationCancelOrderRequest(orderRequestId))
  },
})

const MessagesCentreContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MessagesCentre)

export default MessagesCentreContainer
