import styled from "styled-components"

import { Table } from "../../component-library/components/table/table.component"
import { StyledLoadingWrapper } from "../../component-library/components/table/table.component.styles"
import { Body, SmallText } from "../../component-library/design/typography/typography.styles"
import { COLOR } from "../../component-library/design/color/color.constants"
import { FOOTER_HEIGHT } from "../order-management.component.styles"
import ActiveStatusSVG from "./assets/status-active.svg"
import PendingStatusSVG from "./assets/status-pending.svg"
import CompletedStatusSVG from "./assets/status-completed.svg"
import CancelledStatusSVG from "./assets/status-cancelled.svg"

const FlexContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 8px;
`

const SubText = styled(Body)`
  color: ${COLOR.GREY_DARK};
  word-break: break-word;
`

const FlexContainer = styled.div`
  display: flex;
`

const StatusIconStyles = `
  margin-right: 8px;
  min-width: 16px;
`

const ActiveStatusIcon = styled(ActiveStatusSVG)`
  ${StatusIconStyles}
`

const CancelledStatusIcon = styled(CancelledStatusSVG)`
  ${StatusIconStyles}
`

const CompletedStatusIcon = styled(CompletedStatusSVG)`
  ${StatusIconStyles}
`

const PendingStatusIcon = styled(PendingStatusSVG)`
  ${StatusIconStyles}
`

const StyledTable = styled(Table)`
  width: 100%;
  ${props => props.$isEmpty ? '' : `
    height: calc(100vh - 293px);
    overflow-x: scroll;
  `}

  > table {
    height: auto;
  }
  ${StyledLoadingWrapper} {
    > * {
      top: calc(230px - ${FOOTER_HEIGHT});
    }
  }
`
const CustomizedHeader = styled(SmallText)`
  margin: 8px;
`

const CustomizedCell = styled(Body)`
  margin: 0 8px;
`

const CustomizedTextCell = styled(Body)`
  display: -webkit-box;
  margin: 0 8px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
`

export {
  FlexContainerColumn,
  FlexContainer,
  SubText,
  ActiveStatusIcon,
  CancelledStatusIcon,
  PendingStatusIcon,
  CompletedStatusIcon,
  StyledTable,
  CustomizedHeader,
  CustomizedCell,
  CustomizedTextCell,
}
