import styled from 'styled-components'

const DateInputWrapper = styled.div`
  display: flex;
  align-items: baseline;
  width: 205px;
  height: 40px;
  padding: 5px 8px 5px 0;
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 4px;

  span {
    font-size: 14px;
  }
`

export { DateInputWrapper }