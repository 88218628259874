import React from 'react'
import PropTypes from 'prop-types'

import {
  StatusIndicatorWrapper,
  CheckPinIcon,
  CrossPinIcon,
  StatusIndicatorContentWrapper,
  StatusIndicatorTitle,
} from './status-indicator.component.styles'

const StatusIndicator = props => {
  const { status, children, isCross, dataCy } = props

  return (
    <StatusIndicatorWrapper>
      {isCross ? <CrossPinIcon /> : <CheckPinIcon />}
      <StatusIndicatorContentWrapper>
        <StatusIndicatorTitle data-testid="status-text">
          {status}
        </StatusIndicatorTitle>
        <div>{children}</div>
      </StatusIndicatorContentWrapper>
    </StatusIndicatorWrapper>
  )
}

StatusIndicator.displayName = 'Status Indicator'

StatusIndicator.propTypes = {
  status: PropTypes.string,
  children: PropTypes.node,
  isCross: PropTypes.bool,
}

export { StatusIndicator }
