import React from 'react'
import Loadable from 'react-loadable'

import { Loading } from './loading.component'

const loadableCreator = (options = {}) =>
  Loadable({
    loader: options.dynamicImport,
    loading: Loading,
    render: (loadedModule, props) => {
      const LoadedComponent = loadedModule[options.componentName]
      return (
        <React.StrictMode>
          <LoadedComponent {...props} />
        </React.StrictMode>
      )
    },
  })

export { loadableCreator }
