import { Api } from '../../legacy/utils/api'

const BASE_URL = ENV.API_URL
const _subscriptionApi = new Api(`${BASE_URL}/b2b/subscriptions`)

const subscriptionService = {
  register: async ({ tier = null }) => {
    const method = 'post'
    const endpoint = '/register'
    const params = { tier }

    try {
      const result = await _subscriptionApi.request(
        method,
        endpoint,
        null,
        null,
        null,
        null,
        params
      )
      return result.data
    } catch (error) {
      throw error
    }
  },
  cancel: async ({ customerFeedback = null }) => {
    const method = 'post'
    const endpoint = '/cancel'
    const params = { customer_feedback: customerFeedback }

    try {
      const result = await _subscriptionApi.request(
        method,
        endpoint,
        null,
        null,
        null,
        null,
        params
      )
      return result.data
    } catch (error) {
      throw error
    }
  },
  getStatus: async () => {
    const method = 'get'
    const endpoint = '/show'

    try {
      const result = await _subscriptionApi.request(method, endpoint)
      return result.data
    } catch (error) {
      throw error
    }
  },
  confirmFreeTrialExpired: async () => {
    const method = 'post'
    const endpoint = '/confirm_free_trial_expired'

    try {
      const result = await _subscriptionApi.request(method, endpoint)
      return result.data
    } catch (error) {
      throw error
    }
  },
}

export { _subscriptionApi, subscriptionService }
