/**
 * @example
 * BLUE_DARK: '#006e9b' BLUE_MEDIUM: '#0088bf' BLUE_LIGHT: '#bfe1ef' BLUE_LIGHTER: '#d6ecf5'
 * GREY_DARKER: '#404e54' GREY_DARK: 'rgba(0,18,26,0.57)' GREY_MEDIUM: '#bfc4c6'
 * GREY_LIGHT: 'rgba(0,0,0,0.1)' GREY_LIGHTER: '#f2f3f3' GREY_LIGHTEST: '#f7f8f8'
 * BLACK: '#00121a' WHITE: '#ffffff'
 * TEAL_MEDIUM: '#00aeb1' TEAL_LIGHT: '#bfebeb'
 * RED_MEDIUM: '#ea5265' RED_MEDIUMLIGHT: '#f4a8b2' RED_LIGHT: '#fad4d8'
 * YELLOW_MEDIUM: '#ffdd00' YELLOW_LIGHT: '#ffe594' YELLOW_LIGHTER: '#fff6bf'
 * ORANGE_DARK: '#f0a000' ORANGE_MEDIUM: '#ffb800'
 * PURPLE_DARK: '#712958' PURPLE_MEDIUM: '#f571f6'
 */
const COLOR = {
  BLUE_DARK: '#006e9b',
  BLUE_MEDIUM: '#0088bf',
  BLUE_LIGHT: '#bfe1ef',
  BLUE_LIGHTER: '#d6ecf5',

  GREY_DARKER: '#404e54',
  GREY_DARK: 'rgba(0,18,26,0.57)',
  GREY_MEDIUM: '#bfc4c6',
  GREY_LIGHT: 'rgba(0,0,0,0.1)',
  GREY_LIGHTER: '#f2f3f3',
  GREY_LIGHTEST: '#f7f8f8',

  BLACK: '#00121a',
  WHITE: '#ffffff',

  TEAL_MEDIUM: '#00aeb1',
  TEAL_LIGHT: '#bfebeb',

  RED_MEDIUM: '#ea5265',
  RED_MEDIUMLIGHT: '#f4a8b2',
  RED_LIGHT: '#fad4d8',

  YELLOW_MEDIUM: '#ffdd00',
  YELLOW_LIGHT: '#ffe594',
  YELLOW_LIGHTER: '#fff6bf',

  ORANGE_DARK: '#f0a000',
  ORANGE_MEDIUM: '#ffb800',

  PURPLE_DARK: '#712958',
  PURPLE_MEDIUM: '#f571f6',

  DANGER_100: 'rgba(214, 31, 73, 1)',
  DANGER_200: 'rgba(196, 28, 67, 1)',
}

export { COLOR }
