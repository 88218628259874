import React from 'react'
import browserHistory from 'react-router/lib/browserHistory'
import { syncHistoryWithStore } from 'react-router-redux'
import 'moment/locale/en-gb'
import 'moment/locale/zh-hk'
import 'moment/locale/vi'
import TagManager from 'react-gtm-module'
import { brazeClient } from './service/braze'
import { QueryClient, QueryClientProvider } from 'react-query'

import configureStore from './store'
import Root from './legacy/containers/root'
import { getLocale } from './legacy/utils/locale'
import './service/bugsnag'
import './service/i18n/config'
import { checkServiceSuspension } from 'ggx-service/service-suspension'

import i18n from 'i18next'
import { translate } from './service/i18n/translate.service'

const getMomentLocaleString = locale => {
  if (locale.match(/en/)) {
    return 'en-gb'
  } else if (locale.match(/vi/)) {
    return 'vi'
  }
  return 'zh-hk'
}

const tagManagerArgs = {
  gtmId: ENV.GOOGLE_TAG_MANAGER_ID,
}

TagManager.initialize(tagManagerArgs)

const queryClient = new QueryClient()
const store = configureStore(browserHistory)
const history = syncHistoryWithStore(browserHistory, store)
const target = document.getElementById('main_container')
const node = (
  <QueryClientProvider client={queryClient}>
    <Root routerHistory={history} store={store} />
  </QueryClientProvider>
)
const locale = getLocale()
const momentLocale = getMomentLocaleString(locale)
moment.locale(momentLocale)

checkServiceSuspension()

brazeClient.initialize()

ReactDOM.render(node, target)

if (window.Cypress) {
  window.store = store
  window.t = (key, data = {}) => {
    let result = i18n.t(key, data)
    if (result === key) result = translate(key, data)
    return result
  }
}
