export default {
  accounts: {
    edit: {
      editUser: 'Chỉnh sửa người dùng',
      pageHeader: 'Quản lý tài khoản > Chỉnh sửa người dùng',
      updateUserFail: 'Cập nhật người dùng thất bại',
      updateUserSuccess: 'Người dùng được cập nhật',
    },
    form: {
      accessLevel: 'Cấp độ truy cập',
      branch: 'Số tài khoản con',
      branchUser: 'Tài khoản con',
      changePassword: 'Thay đổi mật khẩu',
      confirmPassword: 'Xác nhận mật khẩu',
      currentPassword: 'Mật khẩu hiện tại',
      detailAddress: 'Tầng / Phòng',
      email: 'Email',
      master: 'Tài khoản chính',
      name: 'Tên',
      newPassword: 'Mật khẩu mới',
      pageHeader: 'Quản lý tài khoản > Hình thức người dùng',
      phone: 'Số điện thoại',
      profileForm: 'Chi tiết hồ sơ',
      profileImage: 'Hình ảnh hồ sơ',
      streetAddress: 'Địa chỉ',
      subUser: 'Người dùng',
    },
    new: {
      createUser: 'Tạo tài khoản người dùng mới',
      pageHeader: 'Quản lý tài khoản > Tạo người dùng mới',
    },
    view: {
      accessLevel: 'Cấp độ truy cập',
      addNewUser: 'Thêm người dùng mới',
      admin: 'Siêu người dùng GGV (Quản trị người dùng)',
      branch: 'Con',
      branchUser: 'Tài khoản con',
      deactivate: 'Kích hoạt người dùng',
      deactivated: 'Người dùng được kích hoạt',
      deactivateFail: 'Kích hoạt người dùng thất bại',
      detailAddress: 'Tầng / Phòng',
      editProfile: 'Thay đổi chi tiết hồ sơ',
      email: 'Email',
      false: 'Thất bại',
      master: 'Người dùng chính',
      name: 'Tên',
      pageHeader: 'Quản lý tài khoản',
      phone: 'Số điện thoại',
      queryPlaceholder: 'Tên, Chi nhánh, Email,.v...v',
      reactivate: 'Kích hoạt lại',
      reactivated: 'Đã kích hoạt lại',
      reactivateFail: 'Kích hoạt lại thất bại',
      search: 'Tìm kiếm',
      showAll: 'Hiển thị tất cả',
      streetAddress: 'Địa chỉ',
      subUser: 'Người dùng',
      true: '',
      users: '',
    },
  },
  addresses: {
    delete: {
      confirmationPrompt: '',
      routeConfirmationPrompt: '',
      routeSuccessMessage: 'Tuyến đường đã bị xóa',
      successMessage: 'Địa chỉ đã bị xóa',
    },
    edit: {
      editAddress: 'Thay đổi liên hệ',
      editRoute: 'Thay đổi tuyến đường',
      pageHeader: 'Danh bạ địa chỉ > Thay đổi liên hệ',
      pageHeaderRoute: 'Danh bạ địa chỉ > Thay đổi tuyến đường',
      update: 'Lưu',
    },
    index: {
      addNewContact: 'Thêm người liên hệ mới',
      addNewRoute: 'Thêm tuyến đường mới',
      addresses: 'Các địa chỉ',
      branch: 'Chi nhánh',
      company: 'Tên công ty',
      create: 'Tạo',
      createRoute: 'Tạo tuyến đường',
      creator: 'Người dùng',
      csvSuccess: 'Tải lên tập tin CSV',
      delete: 'Xóa',
      detailAddress: 'Tầng / Phòng',
      duplicateAddresses: 'Loại bỏ địa chỉ trùng lặp',
      edit: 'Chỉnh sửa',
      editRoute: 'Chiỉnh sửa tuyến đường',
      email: 'Email',
      filterType: 'Kiểu lọc',
      importContacts: 'Cập nhật liên hệ từ CSV',
      name: 'Người liên hệ',
      next: 'Tiếp tục',
      nouniqueaddress: '',
      pageHeader: 'Danh bạ',
      phone: 'Số điện thoại',
      placeNewOrder: 'Đặt hàng',
      previous: '',
      queryPlaceholder: 'Tên người liên hệ, Số điện thoại, Email,..v..v',
      receiver: 'Người liên hệ',
      remarks: 'Các nhận xét',
      remark: 'Các lưu ý',
      region: 'Lưu ý',
      route: 'Địa chỉ liên quan',
      routeName: 'Tên đường',
      routeNameUnique: '',
      routes: 'Tuyến đường',
      search: 'Tìm kiếm',
      sender: 'Người gửi',
      showingPage: options =>
        `Hiển thị trang ${options.currentPage} của ${options.totalPages}`,
      streetAddress: 'Địa chỉ',
      successSnackbar: 'Tuyến đường đã được tạo',
      totalAddresses: options => `Tổng hợp các địa chỉ: ${options.count}`,
      totalRoutes: options => `Tổng hợp các tuyến đường: ${options.count}`,
      type: 'Kiểu',
      update: 'Cập nhật',
    },
    new: {
      createAddress: 'Tạo liên hệ',
      pageHeader: 'Danh bạ địa chỉ > Tạo liên hệ mới',
      save: 'Lưu & Tạo địa điểm khác',
      successMessage: 'Liên hệ đã được tạo',
    },
    routes: {
      edit: {
        success: 'Tuyến tường đã được chỉnh sửa',
      },
    },
  },
  constants: {
    addressBoxFields: {
      addressType: '',
      addressTypeReceiver: '',
      addressTypeSender: '',
      collectOnDelivery: '',
      company: 'Tên công ty',
      desiredDeliveryTime: '',
      desiredDeliveryTimePlaceholder: '',
      destination: '',
      destinationCSV: 'Chọn 1 điểm giao hàng, hoặc chọn trên bản đồ',
      detailAddress: 'Tầng / Phòng',
      email: 'Email',
      instructions: 'Lưu ý',
      parcelIds: '',
      parcelIdsPlaceholder: '',
      phone: 'Số điện thoại',
      pickupPoint: '',
      receiver: 'Biên nhận',
      region: '',
      sender: '',
      waypoint: '',
    },
    currency: {
      prefix: '',
    },
    goodsTypes: {
      constructionMaterials: '',
      documents: '',
      food: '',
      fragile: '',
      groceries: '',
      nil: '',
      none: '',
      null: '',
      others: '',
    },
    goodTypes: {
      none: '',
    },
    industries: {
      accounting: '',
      agriculture: '',
      arts: '',
      construction: '',
      culture: '',
      education: '',
      electricity: '',
      environment: '',
      financial: '',
      healthcare: '',
      hotels: '',
      information: '',
      international: '',
      leasing: '',
      legal: '',
      manufacturing: '',
      mining: '',
      others: '',
      professional: '',
      publicAdministration: '',
      realEstate: '',
      residentServices: '',
      transport: '',
      wholesale: '',
    },
    orderRequestStatuses: {
      active: 'Hoạt động',
      cancelled: 'Đã hủy',
      completed: 'Đã hoàn thành',
      pending: 'Đã hoàn thành',
    },
    parcelStatuses: {
      assigned: 'Đã giao',
      delivered: 'Thành công',
      failedToDeliver: 'Giao hàng thất bại',
      parcelPickedUp: 'Đang trên đường',
      pending: 'Đang chờ',
      reassigned: 'Giao hàng lại',
    },
    vehicles: {
      damas: '',
      damasDescription: '',
      labo: '',
      laboDescription: '',
      lbread: '',
      lbreadDescription: '',
      lorry10: '',
      lorry10Description: '',
      lorry14: '',
      lorry14Description: '',
      lorry24: '',
      lorry24Description: '',
      ltruck: '',
      ltruckDescription: '',
      mbread: '',
      mbreadDescription: '',
      motorcycle: 'Xe máy',
      motorcycleDescription: '',
      mtruck: 'Xe tải 3,5 tấn',
      mtruckDescription: '',
      mudou: '',
      mudou9: '',
      mudou9Description: '',
      mudouDescription: '',
      sbread: '',
      sbreadDescription: '',
      sedan: '',
      sedanDescription: '',
      sltruck: '',
      sltruckDescription: '',
      struck: 'Xe tải nhỏ',
      struckDescription: '',
      ton1: '',
      ton1_2_and_ton1_4: '',
      ton1_2_and_ton1_4Description: '',
      ton11: '',
      ton11Description: '',
      ton14: '',
      ton14Description: '',
      ton15: '',
      ton15Description: '',
      ton18: '',
      ton18Description: '',
      ton1Description: '',
      ton2_5: '',
      ton2_5Description: '',
      ton22: '',
      ton22Description: '',
      ton25: '',
      ton25Description: '',
      ton3_5: '',
      ton3_5Description: '',
      ton4_5: '',
      ton4_5Description: '',
      ton5: '',
      ton5Description: '',
      ton8: '',
      ton8Description: '',
      ton9_5: '',
      ton9_5Description: '',
      tricycle: 'Xe ba gác',
      truck500: 'Xe tải 500kg',
      truck1000: 'Xe tải 1000kg',
      truck1500: 'Xe tải 1500kg',
      truck2000: 'Xe tải 2000kg',
      van: '',
      vanDescription: '',
    },
  },
  errors: {
    auth: {
      restricted: '',
    },
    generic: '',
    page_not_found: {
      home: '',
      message: '',
      oops: '',
      status: '',
    },
  },
  header: {
    addressBook: 'Danh bạ',
    dropdown: {
      accountMgmt: 'Quản lý tài khoản',
      contactUs: 'Liên hệ chúng tôi',
      corporateProfile: 'Hồ sơ công ty',
      help: 'Trợ giúp',
      logout: 'Đăng xuất',
      switchChineseEnglish: '',
      tnc: 'Điều khoản và Điều kiện',
    },
    orderMgmt: 'Quản lý đơn hàng',
    placeOrder: 'Đặt hàng',
    tnc: {
      agree: '',
      next: 'Tiếp tục',
      previous: 'Quay lại',
      proceed: 'Xác nhận',
      title: 'Điều khoản & Điều kiện',
    },
    wallet: 'Hóa đơn & Số dư',
  },
  helpers: {
    addressBox: {
      autocompletePopover: {
        locationSuggestion: 'Đề xuất vị trí',
        savedAddress: '',
      },
      index: {
        addALocation: 'Thêm 1 điểm dừng',
        address: 'Địa chỉ',
        addressBoxTitle: 'Thông tin lấy hàng',
        addressCSV: 'Địa chỉ (*)',
        cantFindAddress:
          'Không thể tìm 1 hoặc nhiều hơn các điểm đến. Vui lòng kiểm tra lại điểm đến',
        collectOnDelivery: 'Thu thập đơn hàng',
        company: 'Tên công ty',
        contactName: 'Người liên hệ',
        contactPhone: 'Số điện thoại liên hệ',
        desiredDeliveryTime: 'Thời gian giao hàng',
        detailAddress: 'Tầng / Phòng',
        downloadTemplate: 'Tải về tập tin mẫu CSV',
        email: 'eMAIL',
        goodsAndServiceSetting: 'Cài đặt Dịch vụ & Hàng hóa',
        addDestination: 'Thêm 1 điểm dừng',
        importRoutes: 'Cập nhật tuyến đường',
        notApplicable: 'N/A',
        parcelIds: 'Mã kiện hàng',
        region: 'Khu vực',
        remark: 'Lưu ý',
        remarkValue: options =>
          `Thu thập đơn hàng: ${options.collectOnDelivery}, Lưu ý: ${options.remark}`,
        tooManyLines: 'Tập tin csv chỉ được cập nhật tối đa 100 dòng.',
        viewAll: 'Xem tất cả',
        route: 'Tuyến đường',
      },
      waypoint_details: {
        okButton: 'OK',
        cancelButton: 'Hủy bỏ',
      },
    },
    button: {
      cancel: 'Hủy bỏ',
      chooseFromComputer: 'Chọn từ máy tính',
      saveAndExit: 'Lưu và Kết thúc',
      search: 'Tìm kiếm',
    },
    datepicker: {
      cancel: 'Hủy bỏ',
      date: 'Ngày',
      ok: 'OK',
    },
    dialog: {
      cancel: 'Hủy bỏ',
      confirm: 'Xác nhận',
      ok: 'OK',
      submit: 'Gửi đi',
    },
    loading: {
      loading: 'Đang tải...',
    },
    mapView: {
      driver: 'Tài xế',
      eta: 'Thời gian dự kiến',
      license: 'Biển số xe',
      notApplicable: 'Không có',
      phone: 'Số điện thoại',
      remark: 'Lưu ý',
      travelTime: 'Thời gian du lịch',
    },
    pagination: {
      totalSeparator: 'của',
    },
    text: {
      addressNotFound:
        'Xin lỗi, chúng tôi không thể tìm thấy bất kỳ địa chỉ nào hợp lệ. Bạn có thể kiểm tra lại chính tả và thử lại',
      required: 'Trường không được để trống',
    },
    timepicker: {
      cancel: 'Hủy bỏ',
      ok: 'OK',
    },
  },
  images: {
    logo: 'general_3/logo',
  },
  notification: {
    actionButton: {
      cancel: 'Hủy đơn hàng',
      cancelled: 'Đã hủy!',
    },
  },
  notificationCentre: {
    drawerTitle: {
      notification: 'Thông báo',
    },
    message: {
      todayAt: options => `Hôm nay @${options.time}`,
    },
    messagesCentre: {
      loadMore: 'Tải thêm thông báo',
      prefs: 'Thông báo ưu tiên',
    },
    preferencesCentre: {
      byEmail: 'Nhận thông báo bằng email',
      prefs: 'Ưu tiên',
    },
  },
  orders: {
    dialog: {
      badAttitude: 'Thái độ tệ',
      branch: 'Chi nhánh:',
      cancel: 'Hủy đơn hàng',
      cbm: 'CBM',
      collectOnDelivery: options => `Thu thập đơn hàng: ${options.fee}`,
      collectOnDeliveryHeader: 'Thu thập đơn hàng',
      confirmOrderComplete:
        'Đơn hàng hoàn thành. Vui lòng đánh giá chất lượng tài xế',
      contactNumber: 'Số điện thoại người nhận',
      desiredDeliveryTime: options =>
        `Thời gian giao hàng mong muốn: ${options.time}`,
      desiredDeliveryTimeHeader: 'Thời gian giao hàng mong muốn',
      destination: 'Điểm giao hàng',
      disputeClosed: 'Giải quyết tranh chấp',
      disputeClosedMessage: options =>
        `Điều chỉnh giá đơn hàng #${options.orderRequestId} đã được xử lý`,
      disputeReported: 'Báo cáo là trường hợp tranh chấp',
      disputeSubmittedMessage: options =>
        `Đơn hàng #${options.orderRequestId} đã được báo cáo có tranh chấp`,
      download: 'Tải xuống mẫu CSV',
      driver: 'Tài xế:',
      duration: 'Khoảng thời gian:',
      email: 'Email',
      feeIssue: 'Vấn đề giá cả',
      goodsInfo: 'Thông tin kiện hàng',
      help: 'Trợ giúp',
      images: 'Hình ảnh',
      import: '',
      importSingleRouteButton: '',
      importMultiRouteButton: '',
      item: '',
      licensePlate: '',
      longLoadingTime: '',
      noGoodsInfo: '',
      noimages: '',
      nosignature: '',
      notApplicable: '',
      notOnTime: 'Tài xế không có mặt đúng giờ',
      orderCompletionConfirmed: 'Đơn hàng xác nhận đã hoàn thành',
      orderNumber: options => `Mã số đơn hàng: ${options.id}`,
      other: 'Khác',
      parcelNumber: 'Mã kiện hàng',
      phoneNumber: 'Số điện thoại',
      pickupTime: 'Thời gian lấy hàng',
      pickupTimeCSV: 'Thời gian lấy hàng (*)',
      previewImage: 'Xem trước hình ảnh',
      previewSignature: 'Xem trước chữ ký',
      priceBreakdown: 'Chi tiết giá',
      quantity: 'Số lượng',
      rateAndConfirmCompletion: 'Tỷ lệ',
      reachingOut: '',
      reachingOutNone: '',
      reachingOutOne: '',
      reachingOutMany: '',
      recipient: 'Biên nhận',
      remarks: 'Lưu ý',
      remarksFile: 'Lưu ý',
      reportDispute: 'Tranh chấp về giá',
      reportDisputeConfirmMessage:
        'Bạn có chắc rằng bạn muốn báo cáo tranh chấp về giá? Sau khi báo cáo, chúng tôi sẽ liên lạc với bạn sớm.',
      reuse: 'Sử dụng lại tuyến đường',
      routeChanged: 'Tuyến đường đã được thay đổi',
      routes: 'Tuyến đường',
      share: 'Chia sẻ',
      sizeOfGoods: 'Kích thước hàng hóa',
      timeSheetRemarks: 'Lưu ý tài xế',
      timeSheets: 'Tài liệu tham khảo',
      totalFee: 'Giá tổng cộng',
      vehicle: 'Loại phương tiện',
      vehicleType: 'Loại phương tiện',
      vehicleTypeCSV: 'Loại phương tiện',
      weight: 'Khối lượng',
      whatWasTheProblem: 'Đã có vấn đề gì?',
      singleRoute: 'Nhập tuyến đường riêng lẻ',
      multiRoute: 'Nhập nhiều tuyến đường',
    },
    filter: {
      amended: 'Đã cập nhật giá',
      branch: 'Chi nhánh',
      disputeStatus: 'Trạng thái tranh chấp',
      open: 'Báo cáo tranh chấp',
      ordersFrom: 'Từ',
      parcelStatus: 'Trạng thái kiện hàng',
      pickupDate: 'Thời gian lấy',
      queryPlaceholder: 'Tên/ Số điện thoại của khách hàng hoặc tài xế',
      search: 'Tìm kiếm',
      showAll: 'Hiển thị tất cả',
      status: 'Trạng thái đơn hàng',
      to: 'Đến',
      vehicle: 'Phương tiện',
    },
    new: {
      goodsRequirements: {
        goodsDetails: 'Chi tiết hàng hóa',
      },
      basicInfo: {
        addInstructions: 'Thêm lưu ý',
        disabled: '',
        edit: 'Chỉnh sửa',
        email: 'Email',
        goodsAndServiceSetting: 'Cài đặt hàng hóa và dịch vụ',
        extraRequirements: 'Thêm yêu cầu',
        hourlyRental: 'Cho thuê theo giờ',
        hours: 'Giờ',
        instructions: 'Lưu ý',
        noRental: 'Không cho thuê theo giờ',
        phone: 'Số điện thoại',
        remarks: 'Lưu ý',
        pickupDate: 'Ngày lấy hàng',
        pickupPoint: 'Điểm lấy hàng',
        pickupTime: 'Thời gian lấy hàng',
        sender: 'Người gửi',
        now: 'Bây giờ',
        later: 'Chọn ngày và giờ',
        tunnel: '',
        tunnel_any: '',
        tunnel_hung: '',
        tunnel_western: '',
        tunnel_eastern: '',
        tunnel_lion_rock: '',
        tunnel_tates_cairn: '',
        tunnel_eagles_nest: '',
        tunnel_tai_lam: '',
        tunnel_aberdeen: '',
        tunnel_tko: '',
        tunnel_tm_bridge: '',
        tunnel_discovery: '',
        tunnel_shing_mun: '',
        passenger: 'Số thứ tự của hành khách',
        selection: {
          today: 'Hôm nay',
          tomorrow: 'Ngày mai',
          later: 'Trễ hơn',
        },
        placeholder: {
          selectTime: 'Chọn thời gian',
        },
        subtitle: {
          total: 'Tổng cộng',
        },
        chargeInfo: '',
      },
      index: {
        newOrder: '',
      },
      basicinfo: {
        selection: {
          asap: 'Ngay bây giờ',
        },
      },
      total: {
        insuranceTitle: '',
        insuranceDescription: '',
      },
    },
    view: {
      address: '',
      detail: 'Chi tiết',
      disputeStatuses: {
        amended: '',
        closed: '',
        open: '',
      },
      driverInfo: '',
      eta: '',
      exportThisReport: 'Xuất báo cáo',
      extraDropoffPoints: '',
      filterBranch: '',
      filterCurrent: '',
      filterDispute: '',
      filterNoOrders: '',
      filterNoOrdersSearch: '',
      filterOrderStatus: 'Trạng thái đơn hàng',
      filterParcel: 'Trạng thái kiện hàng',
      filterSearch: 'Tìm kiếm',
      filterVehicle: 'Phương tiện',
      mapDetail: 'Bản đồ | Chi tiết',
      mapView: 'Xem bản đồ',
      next: 'Tiếp tục',
      nosignatureorimages: 'Không có hình ảnh hay chữ ký',
      orderNumber: 'Mã đơn hàng',
      pageHeader: 'Quản lý đơn hàng',
      parcelId: 'Mã kiện hàng',
      parcelNumber: 'Mã kiện hàng',
      parcelView: 'Bởi kiện hàng',
      pickupTime: '',
      placedBy: 'PLACED BY',
      plusReceivers: '',
      previous: '',
      priceColumn: '',
      receiverInfo: '',
      receivers: '',
      route: '',
      sender: '',
      showingPage: '',
      status: '',
      statusReason: '',
      tableView: 'Chi tiết đơn hàng',
      timestamp: '',
      todayStatus: options =>
        `Chào ${options.org}! Bạn có: ${options.active} đơn hàng đang giao, ${options.pending} đơn hàng đang chờ xác nhận, ${options.completed} đơn hàng hoàn thành và ${options.cancelled} đơn hàng bị hủy hôm nay`,
      totalAmount: 'Tổng cộng',
      totalOrders: options => `Tổng đơn hàng: ${options.count}`,
      totalParcels: options => `Tổng kiện hàng: ${options.count}`,
      updateInfo: 'Thông tin đã tải lên',
      vehicle: 'Phương tiện',
    },
    waypointStatus: {
      arrivedAt: options => `Đến @ ${options.time}`,
      deliveredAt: options => `Giao hàng @ ${options.time}`,
      eta: options => `Thời gian dự kiến ${options.eta}`,
      findingDriver: 'Đang tìm tài xế',
      pending: 'Đang xử lý',
    },
  },
  organization: {
    switch: {
      apply: 'Áp dụng',
      branchLabel: 'Chi nhánh',
      label: 'Chuyển đổi tổ chức',
      organizationLabel: 'Tổ chức',
    },
  },
  organizations: {
    addressEdit: {
      destination: 'Điểm giao hàng',
      detailAddress: 'Tầng / Phòng',
      editMyOrgAddress: 'Chiỉnh sửa địa chỉ công ty của tôi',
      pageHeader: 'Hồ sơ công ty > Chỉnh sửa hồ sơ > Thay đổi địa chỉ',
    },
    edit: {
      add: 'Thêm',
      addABranch: 'Thêm 1 chi nhành',
      branchAddress: 'Địa chỉ chi nhánh',
      branches: 'Các chi nhánh',
      branchName: 'Tên chi nhánh',
      cannotRemoveApiBranchErr:
        'Bạn không thể xóa hoặc chỉnh sửa chi nhánh có liên kết API',
      cannotRemoveBranchErr:
        'Xin lỗi, chi nhánh này không thể gỡ bỏ vì nó đang có người dùng tồn tại',
      companyWebsite: 'Trang web công ty',
      contactPerson: 'Người liên hệ',
      contactPersonPhone: 'Số điện thoại người liên hệ',
      corporateName: 'Tên công ty',
      corporateProfileImage: 'Hình ảnh hồ sơ công ty',
      detailAddress: 'Tầng / Phòng',
      editAddress: 'Thay đổi địa chỉ',
      editMyOrg: 'Chỉnh sửa công ty của tôi',
      email: 'Email chung',
      industry: 'Ngành hàng',
      mapAddress: 'Địa chỉ',
      pageHeader: 'Hồ sơ công ty > Chỉnh sửa hồ sơ',
      remark: 'Lưu ý chung (Tham khảo tài xế)',
    },
    show: {
      branches: 'Các chi nhánh',
      companyWebsite: 'Trang web công ty',
      contactPerson: 'Người liên hệ',
      contactPersonPhone: 'Số điện thoại người liên hệ',
      detailAddress: 'Chi tiết địa chỉ',
      editProfile: 'Chỉnh sửa hồ sơ',
      email: 'Địa chỉ email chung',
      industry: 'Ngành hàng',
      mapAddress: 'Địa chỉ',
      myOrg: 'Công ty của tôi',
      pageHeader: 'Hồ sơ công ty',
      remark: '',
      salesRepresentative: '',
      unassigned: '',
    },
  },
  popup: {
    merge: {
      dismissAll: 'BỎ QUA TẤT CẢ',
      notifStatement: options => `Có ${options.count} thông báo mới khác`,
      seeAll: 'HIỂN THỊ TẤT CẢ',
    },
    message: {
      dismiss: '',
      todayAt: '',
    },
    newFeauture: {
      title: '',
      body: '',
    },
    info: {
      insuranceDetails: {
        title: '',
      },
    },
    actionButton: {
      claimInsurance: '',
      close: '',
      getStarted: '',
    },
    confirm: {
      removeImage: {
        title: '',
        content: '',
      },
      actionButton: {
        removeImage: '',
        cancelOrder: '',
        removeCard: '',
        addCard: '',
      },
      cancelOrder: {
        title: '',
        content: '',
      },
      addCard: {
        content: '',
      },
    },
  },
  session: {
    dialog: {
      multiSessionBody: '',
      multiSessionTitle: '',
      havePermit: '',
      noPermit: '',
      polygonDialogHeader: '',
      no_service: '',
      restricted_area: '',
      restricted_area_both: '',
      gotIt: '',
    },
  },
  user_suspended: {
    dialog: {
      dialog_message: '',
      ok_button: 'OK',
    },
  },
  users: {
    login: {
      signIn: 'Đăng nhập',
    },
    profile: {
      accessLevel: 'Cấp độ truy cập',
      accMgmt: 'Quản lý tài khoản',
      address: 'Địa chỉ',
      branch: 'Chi nhánh',
      detailAddress: 'Tầng / Phòng',
      editCorporateProfile: 'Chỉnh sửa hồ sơ công ty',
      editMyProfile: 'Chỉnh sửa hồ sơ của tôi',
      email: 'Email',
      myAcc: 'Tài khoản của tôi',
      notApplicable: 'Không có',
      pageHeader: 'Tài khoản của tôi',
      phoneNumber: 'Số liên hệ',
    },
    profileEdit: {
      accessLevel: 'Cấp độ tài khoản',
      admin: 'Quản trị viên',
      branch: 'Số chi nhánh',
      branchUser: 'Tài khoản con',
      changePassword: 'Mật khẩu thay đổi',
      confirmPassword: 'Mật khẩu xác nhận',
      currentPassword: 'Mật khẩu hiện tại',
      detailAddress: 'Tầng/ Phòng',
      editMyAcc: 'Chỉnh sửa tài khoản của tôi',
      email: 'Email',
      master: 'Tài khoản chính',
      name: 'Tên',
      newPassword: 'Mật khẩu mới',
      pageHeader: 'Quản lý tài khoản > Chỉnh sửa hồ sơ',
      passwordMismatch: 'Mật khẩu mới và mật khẩu xác nhận phải trùng khớp',
      phone: 'Số điện thoại',
      streetAddress: 'Địa chỉ phố',
      subUser: 'Người dùng',
    },
    changePassword: {
      updateFail:
        'Chi tiết người dùng được cập nhật. Cập nhật mật khẩu thất bại.',
    },
  },
  utils: {
    time: {
      hours: 'giờ',
      minutes: 'phút',
      seconds: 'giây',
    },
  },
  validations: {
    errors: {
      baseErrorAddress: 'Địa chỉ không hợp lệ',
      baseErrorEmail: 'Email không hợp lệ',
      baseErrorNumber: 'Không phải một số?',
      baseErrorPhone: 'Số điện thoại không hợp lệ',
      baseErrorUnknown: 'Lỗi: Dữ liệu trùng lặp',
      completeErrorMessage: options =>
        `${options.baseError} tại dòng ${options.faultyLines}`,
      emptyAddress: 'Vui lòng chọn điểm giao hàng',
      expiredResetPasswordToken: 'Liên kết cài đặt lạt mật khẩu đã hết hạn',
      failedToComputeLatLon: options =>
        `Không thể tính toán vĩ độ / kinh độ của ${options.field}.`,
      invalidDesiredDeliveryTime: 'Vui lòng nhập định dạng thời gian hợp lệ',
      invalidEmailFormat: 'Định dạng email không hợp lệ',
      invalidFields: 'Các trường không hợp lệ',
      invalidNumberFormat: 'Định dạng số không hợp lệ',
      invalidOrder: 'Đơn hàng không hợp lệ. Vui lòng kiểm tra dữ liệu của bạn',
      invalidPassword: 'Mật khẩu không hợp lệ',
      invalidPhone: 'Một hoặc nhiều hơn số điện thoại được nhập không hợp lệ',
      invalidPhoneNumber: 'Số điện thoại không hợp lệ',
      invalidUsernameOrPassword: 'Tên người sùng hoặc mật khẩu không hợp lệ',
      missingField: 'Trường bị thiếu',
      passwordLengthTooShort:
        'Mật khẩu của bạn cần phải có ít nhất 8 ký tự chữ và số',
      passwordMismatch: 'Mật khẩu không khớp',
      unresolveableAddress: 'Vui lòng chọn một địa chỉ từ kết quả tìm kiếm',
      usedResetPasswordToken: 'Liên kết cài đặt lại mật khẩu đã được sử dụng',
      invalidPasswordMin8: 'Vui lòng nhập mật khẩu với tối thiểu 8 ký tự',
    },
    fields: {
      address: 'Địa chỉ',
      contactName: 'Tên người liên hệ',
    },
    phoneRegexp: '',
  },
  wallet: {
    filter: {
      month: 'Tháng',
      showAll: 'Hiển thị tất cả',
      year: 'Năm',
    },
    view: {
      amount: 'Giá tổng cộng',
      balance: 'Số dư',
      companyName: 'Tên công ty',
      date: 'Ngày',
      deposit: 'Tiền đặt cọc',
      depositInfo: '',
      depositView: 'Tiền đặt cọc',
      description: 'Mô tả',
      downloadReport: 'Tải xuống báo cáo',
      next: 'Kế tiếp',
      orderRequestId: 'Số đơn hàng',
      pageHeader: 'Hóa đơn & Số dư',
      previous: 'Trước đó',
      showingPage: options =>
        `Hiển thị trang ${options.currentPage} của ${options.TotalPages}`,
      time: 'Thời gian',
      totalDeposits: options => `Tổng tiền đặt cọc: ${options.count}`,
      totalOrders: options => `Tổng đơn hàng: ${options.count}`,
      totalTransactions: options => `Tổng giao dịch: ${options.count}`,
      transaction: 'Giao dịch',
      transactionTypes: {
        deposit: 'Tiền đặt cọc',
        orderCompletion: 'Đơn hàng hoàn thành',
        orderDispute: 'Đơn hàng có tranh chấp',
        settlement: 'Thanh toán - Cảm ơn',
      },
    },
    internallink: {
      addCard: '',
      removeCard: '',
    },
  },
  sidemenu: {
    van: {
      title: '',
      item: {
        placeOrder: 'Đặt hàng',
        orderManagement: 'Quản lý đơn hàng',
        addressBook: 'Danh bạ',
        billing: 'Hóa đơn & Số dư',
      },
    },
    d2d: {
      title: 'Dịch vụ chuyển phát nhanh',
      item: {
        placeOrder: 'Đặt hàng',
        orderManagement: 'Quản lý đơn hàng',
        wallet: 'Ví',
      },
    },
  },
  onboarding: {
    header: {
      selectService: 'Bạn cần dịch vụ gì?',
    },
    serviceType: {
      title: {
        van: '',
        d2d: 'Dịch vụ chuyển phát nhanh',
      },
      body: {
        van: '',
        d2d: '',
      },
      description: {
        van: '',
        d2d: '',
      },
    },
    van: {
      title: '',
      feature1: '',
      feature2: '',
      feature3: '',
      activate: {
        title: '',
        status: '',
      },
      description: '',
      email: '',
    },
    delivery: {
      title: '',
      description: '',
      link: 'Nhấn vào đây',
    },
  },
  sectionHeader: {
    serviceType: {
      van: {
        name: '',
        placeOrder: 'Đặt hàng',
        orderManagement: 'Quản lý đơn hàng',
        addressBook: 'Danh bạ',
        billing: 'Hóa đơn & Số dư',
      },
      d2d: {
        name: 'Dịch vụ chuyển phát nhanh',
      },
    },
  },
  error: {
    invalid: {
      address: '',
      contact: {
        number: '',
      },
    },
  },
  orderManagement: {
    actionButton: {
      placeOrder: '',
    },
    tab: {
      orders: {
        all: '',
        active: '',
        past: '',
      },
    },
  },
  orderDetails: {
    courier: '',
    loading: '',
    title: {
      status: '',
      shippingInfo: {
        packageSize: '',
        packageWeight: '',
      },
    },
    status: {
      orderConfirm: '',
      accepted: '',
      pickup: '',
      complete: '',
    },
    shippingInfo: {
      button: {
        courier: '',
      },
    },
  },
  systemMessage: {
    order: {
      cancelled: '',
      cancelledReturn: '',
    },
  },
  badges: {
    new: '',
  },
  newOrder: {
    tab: {
      orderInfo: {
        name: '1. Thông tin',
        subtitle: {
          route: 'Tuyến đường',
          pickupTime: 'Thời gian lấy hàng',
          hourlyRental: 'Cho thuê theo giờ',
          vehicleType: 'Loại phương tiện',
          noOfVehicle: 'Số thứ tự của phương tiện',
        },
        route: {
          textfield: {
            pickUpPoint: 'Điểm lấy hàng / Giao hàng',
            floorRoom: 'Tầng / Phòng',
            name: 'Tên ',
            phone: 'Điện thoại',
          },
          link: {
            addWaypoint: 'Thêm điểm dừng',
            clearWaypoint: 'Xóa',
            removeWaypoint: 'Gỡ bỏ',
          },
          phoneError: 'Vui lòng nhập số điện thoại hợp lệ',
          addressError: 'Vui lòng nhập địa chỉ hợp lệ',
        },
        description: {
          noOfVehicle: 'Tất cả các phương tiện đi theo cùng một lộ trìn',
        },
        selection: {
          hourlyRental: 'Không cho thuê theo giờ',
          vehicleType: {
            van: '',
            Truck55: '',
            Truck9: '',
            hoverInfo: {
              van: {
                title: '',
                content: '',
              },
              truck55: {
                title: '',
                content: '',
              },
              truck9: {
                title: '',
                content: '',
              },
            },
          },
        },
        btnNext: 'Tiếp tục',
        hourlyRental: {
          hours: '',
        },
      },
      additional: {
        name: '2. Bổ sung',
        title: {
          additionalService: '',
        },
        selection: {
          passengers: {
            van: {
              title: '',
            },
            truck55n9: {
              title: '',
              description: '',
            },
            options: {
              passenger1: '',
              passengers2: '',
              passengers3: '',
              passengers4: '',
              passengers5: '',
            },
          },
          rentCart: {
            title: '',
            options: {
              cart1: '',
              cart2: '',
              cart3: '',
            },
          },
          rentForklift: {
            title: '',
            options: {
              forklift1: '',
              forklift2: '',
              forklift3: '',
            },
          },
          goodOver6Ft: {
            title: '',
            options: {
              goodOver6Ft: '',
              goodOver6x2Ft: '',
            },
          },
          pet: {
            title: '',
          },
          newVehicle: {
            title: '',
          },
          engDriver: {
            title: '',
          },
          moveGoods: {
            title: '',
            hover: '',
          },
          disposeWaste: {
            title: '',
            hover: '',
          },
          noteToDriver: {
            title: 'Ghi chú cho tài xế',
            description:
              'Mô tả về hàng hoá của bạn và các yêu cầu đặc biệt. (Tuỳ chọn)',
            characterLimit: 'Số ký tự giới hạn: 1000',
            textfield: {
              placeholder: 'Ghi chú',
            },
            exceedCharacterError: 'Bạn đã vượt quá giới hạn tối đa 1000 ký tự',
          },
        },
        btnBack: 'Quay lại',
        btnGetQuote: 'Báo giá',
      },
      summary: {
        name: '3. Tổng cộng',
        title: {
          price: 'Giá',
          estimatedTotal: {
            label: 'Tổng phí ước tính',
            price: {
              quote: '',
            },
          },
          routeFee: 'Chi phí lộ trình',
          additionalService: 'Dịch vụ cộng thêm',
          orderSummary: '',
          pickUp: '',
          route: '',
          vehicle: '',
          noteToDriver: 'Ghi chú cho tài xế',
        },
        breakdown: {
          passengers: 'Dịch vụ cộng thêm',
          rentCart: '',
          rentForklift: '',
          goodOver6Ft: '',
          goodOver6x2Ft: '',
          pet: '',
          newVehicle: '',
          engDriver: '',
          disposeWaste: '',
          waitingTime: '',
          moveGoods: '',
        },
        feeRemark: '',
        link: {
          learnMore: 'Tìm hiểu thêm về giá',
        },
        btnEdit: '\bĐiều chỉnh đơn hàng',
        btnPlaceOrder: 'Đặt đơn hàng',
        priceUpdatePrompt: {
          title: 'Đơn hàng của bạn đã được cập nhật',
          content:
            'Thời gian được chọn đã qua và phí của bạn đã được cập nhật.',
        },
      },
    },
  },
  signup: {
    createAccount: {
      title: '',
      description: '',
      textfield: {
        name: '',
        Email: '',
        phone: '',
        error: {
          empty: {
            name: '',
            email: '',
            phone: '',
            pw: '',
            reenterPw: '',
          },
          format: {
            email: '',
            phone: '',
            pw: '',
          },
          exist: {
            phone: '',
          },
          match: {
            pw: '',
          },
        },
      },
      actionButton: {
        createAccount: '',
      },
      smalltext: {
        termsOfService: '',
        and: '',
        privacyPolicy: '',
        enterpriseUser: '',
        phone: '',
        email: '',
      },
      checkMail: {
        title: '',
        description1: '',
        description2: '',
        smalltext: '',
      },
    },
    activateAccount: {
      setpw: {
        title: '',
        description: '',
        textfield: {
          password: '',
          reenterPassword: '',
        },
        button: {
          next: '',
        },
      },
      companyInfo: {
        title: '',
        description: '',
        textfield: {
          title: {
            companyName: '',
            industry: '',
          },
          placeholder: {
            companyName: '',
          },
        },
        selection: {
          placeholder: {
            selectIndustry: '',
          },
        },
        selectIndustry: {
          selection: {
            constructions: '',
            interior: '',
            florists: '',
            food: '',
            printing: '',
            commercial: '',
            education: '',
            pet: '',
            textile: '',
            event: '',
            ecommerce: '',
            health: '',
            electronics: '',
            retails: '',
            trading: '',
            logistics: '',
            automobile: '',
            entertainment: '',
          },
        },
        selectCompanySize: {
          selection: {
            title: '',
            from1to10: '',
            from11to30: '',
            from30up: '',
          },
          placeHolder: '',
        },
        companyWeb: {
          title: '',
          textfield: {
            placeHolder: '',
          },
        },
        neededService: {
          textfield: {
            title: '',
          },
          selection: {
            placeHolder: '',
            vantruck: '',
            vantruckMover: '',
            courier: '',
            all: '',
            others: '',
          },
        },
        button: {
          getStarted: '',
        },
      },
      error: {
        empty: {
          textfield: '',
          selection: '',
        },
      },
    },
  },
  ggb: {
    login: {
      error: {
        expired: {
          message:
            'Liên kết kích hoạt đã hết hạn. Đăng nhập nếu bạn đã kích hoạt, hoặc đăng ký một tài khoản mới\n',
          signup: '',
        },
      },
      noLogin: 'Chưa có tài khoản? ',
      signup: 'Đăng ký GoGoX Business miễn phí',
    },
  },
}
