import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useQueryClient } from 'react-query'

import { displayAlert } from '../../../component-library/components/alerts/alerts.component'
import { SmallText } from '../../../component-library/design/typography/typography.styles'
import { brazeClient, BRAZE_EVENT } from 'ggx-service/braze'
import {
  Container,
  DriverInformation,
  ProfilePicture,
  DriverDetails,
  DriverPhotoPlaceHolder,
  EmptyHeart,
  FilledHeart,
} from './driver-info.component.styles'
import { favoriteDriversApiService } from '../../../van-booking/api/favorite-drivers-api.service'
import { FAVORITE_DRIVERS_KEY } from '../../../van-booking/favorite-drivers/favorite-drivers.component'

const DriverInfo = ({ order }) => {
  const [t] = useTranslation()
  const queryClient = useQueryClient()
  const [isFavoriteDriver, setIsFavoriteDriver] = useState(false)
  const linkedOrder = order.order || {}
  const driverLicense = (
    <SmallText>
      {t('text__license_value', {
        value: linkedOrder.driver_license_plate || '',
      })}
    </SmallText>
  )
  const driverPhoto = linkedOrder.driver_profile_photo ? (
    <ProfilePicture src={linkedOrder.driver_profile_photo} />
  ) : (
    <DriverPhotoPlaceHolder />
  )
  const driverDetails = (
    <DriverDetails>
      {linkedOrder.driver_nickname}
      {linkedOrder.driver_phone_number &&
        ` (${linkedOrder.driver_phone_number})`}
    </DriverDetails>
  )

  // the effect is rerun to handle case where driver releases order and new driver picks up the order.
  useEffect(() => {
    const fetchAndCheckIsFavoriteDriver = async () => {
      if (!linkedOrder.driver_id) return

      try {
        const { data } = await favoriteDriversApiService.getFavoriteDrivers()
        const driverIsFavorited = data.some(
          driver => driver.id === linkedOrder.driver_id
        )
        setIsFavoriteDriver(driverIsFavorited)
      } catch (error) {
        console.log(error)
      }
    }

    fetchAndCheckIsFavoriteDriver()
  }, [linkedOrder.driver_id])

  const handleAddFavoriteDriver = async () => {
    try {
      queryClient.removeQueries(FAVORITE_DRIVERS_KEY)
      await favoriteDriversApiService.addFavoriteDriver(linkedOrder.driver_id)
      brazeClient.setCustomEvent(BRAZE_EVENT.TRANSPORT.FAVORITE_DRIVER_ADDED)
      displayAlert({
        message: t('notification__name_was_added_to_favourites'),
        hideCloseButton: true,
      })
      setIsFavoriteDriver(true)
    } catch (error) {
      console.log(error)
    }
  }

  const handleRemoveFavoriteDriver = async () => {
    try {
      queryClient.removeQueries(FAVORITE_DRIVERS_KEY)
      await favoriteDriversApiService.removeFavoriteDriver(
        linkedOrder.driver_id
      )
      brazeClient.setCustomEvent(BRAZE_EVENT.TRANSPORT.FAVORITE_DRIVER_REMOVED)
      displayAlert({
        message: t('notification__name_was_removed_from_favourites'),
        hideCloseButton: true,
      })
      setIsFavoriteDriver(false)
    } catch (error) {
      console.log(error)
    }
  }

  if (!order.order) return null

  return (
    <Container>
      {driverPhoto}
      <DriverInformation>
        {driverDetails}
        {driverLicense}
      </DriverInformation>
      {isFavoriteDriver ? (
        <FilledHeart onClick={handleRemoveFavoriteDriver} />
      ) : (
        <EmptyHeart onClick={handleAddFavoriteDriver} />
      )}
    </Container>
  )
}

DriverInfo.propTypes = {
  order: PropTypes.object,
}

DriverInfo.defaultProps = {
  order: {},
}

export { DriverInfo }
