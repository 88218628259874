import { Component } from 'react'
import PropTypes from 'prop-types'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHeader from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import {
  dateColumn,
  timeColumn,
  descriptionColumn,
  amountColumn,
} from '../row-column/row_column.component'

import { LoadingSpinner } from '../../helpers'
import styles from '../styles'
import translate from '../../../locales/translate'

const translateWithPrefix = (key, options) =>
  translate(`wallet.view.${key}`, options)

const headerColumns = [
  { titleKey: 'date' },
  { titleKey: 'time' },
  { titleKey: 'description', wide: true },
  { titleKey: 'amount' },
]

const buildTransactionColumns = ({ row }) => {
  const { attributes } = row
  const { amount, created_at: createdAt, remark } = attributes

  return [
    { column: dateColumn(createdAt) },
    { column: timeColumn(createdAt) },
    { column: descriptionColumn(remark), wide: true },
    { column: amountColumn(parseInt(amount, 10)) },
  ]
}

class WalletDepositTab extends Component {
  bodyRows() {
    const { depositTransactions } = this.props
    const buildColumns = depositTransactions.map((row, i) => {
      const transactionColumns = buildTransactionColumns({
        row,
      })
      const rowColumns = transactionColumns.map((transaction, p) => (
        <TableCell key={p} style={styles.rowCell}>
          {transaction.column}
        </TableCell>
      ))
      return (
        <TableRow key={i} selectable={false}>
          {rowColumns}
        </TableRow>
      )
    })
    return buildColumns
  }

  headerRow() {
    const columns = headerColumns.map((header, i) => (
      <TableCell key={i} style={styles.headerCell}>
        {translateWithPrefix(header.titleKey).toUpperCase()}
      </TableCell>
    ))
    return columns
  }

  render() {
    const { loading } = this.props
    return (
      <>
        <Table selectable={false}>
          <TableHeader displaySelectAll={false}>
            <TableRow selectable={false}>{this.headerRow()}</TableRow>
          </TableHeader>
          {!loading && <TableBody>{this.bodyRows()}</TableBody>}
        </Table>
        {loading && <LoadingSpinner style={styles.loadingSpinner} />}
      </>
    )
  }
}

WalletDepositTab.propTypes = {
  depositTransactions: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
}

export default WalletDepositTab
