import Constants from '../constants/action'

const initialState = {
  successMessage: '',
  errorMessage: '',
  showLoading: false,
  routeError: null,
}

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case Constants.SET_SUCCESS_MESSAGE:
      return { ...state, successMessage: action.successMessage }

    case Constants.SET_ERROR_MESSAGE:
      return { ...state, errorMessage: action.errorMessage }

    case Constants.SET_HIGHLIGHT_ERROR: {
      const result = state.routeError || {}
      const errorKey = `${action.errorObject.key}_${action.errorObject.index}`
      if (typeof action.errorObject.field !== 'undefined') {
        // this is 2 level error (e.g wapoiny N error > waypoint detail have each input )
        result[errorKey] = {
          ...result[errorKey],
          [`${action.errorObject.field}`]: action.errorObject,
        }
      } else {
        // this is one level (e.g wapoiny N error)
        result[errorKey] = action.errorObject
      }
      return { ...state, routeError: result }
    }

    case Constants.CLEAR_HIGHLIGHT_ERROR:
      return { ...state, routeError: null }

    case Constants.CLEAR_MESSAGES:
      return initialState

    case Constants.SHOW_LOADING:
      return { ...state, showLoading: true }

    case Constants.HIDE_LOADING:
      return { ...state, showLoading: false }

    case Constants.POLYGON_DIALOG_SEEN:
      return {
        ...state,
        restrictedPolygon: false,
      }

    default:
      return state
  }
}

export default reducer
