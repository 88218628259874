import { Color } from '../helpers'

const styles = {
  rowColumn: {
    border: `1px solid ${Color.ALTO_GREY}`,
  },
  negativeAmount: {
    color: Color.MANDY_RED,
  },
  loadingSpinner: {
    display: 'block',
    margin: '0px auto',
  },
  rowCell: {
    border: `1px solid ${Color.ALTO_GREY}`,
  },
  headerCell: {
    borderBottom: 'none',
  },
}

export default styles
