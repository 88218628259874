import styled from "styled-components"

import { SPACING } from "ggx-componentlibrary/design/spacing/spacing.constants"
import { FONT_BOLD } from "ggx-componentlibrary/design/typography/typography.constants"
import { COLOR } from "ggx-componentlibrary/design/color/color.constants"

import { EXPANDED_TRANSATION_DURATION } from "../../navigation.constants"
import { Row } from '../../navigation.component.styles'

const StyledCouponRow = styled(Row)`
  svg {
    margin-top: 4px;
    path {
      stroke: ${COLOR.GREY_DARK};
    }
  }

  ${(props) => !props.$isActive && `
    :hover {
      svg {
        path {
          stroke: ${COLOR.BLUE_MEDIUM};
        }
      }
    }
  `}
`

const CouponBadge = styled.span`
  position: absolute;
  right: 18px;
  padding: 0 ${SPACING.XS};
  background-color: ${COLOR.GREY_DARK};
  border-radius: 9px;
  ${FONT_BOLD};
  & > * {
    color: ${COLOR.WHITE};
    transform: scale(0.83333);
  }
`

const CouponIconWrapper =styled.span`
  position: relative;

  ${CouponBadge} {
    top: 0px;
    right: -9px;
    opacity: ${(props) => props.$isNavigationCollapsed && !props.$isNavigationHovered ? 1 : 0};
    transition: opacity ${EXPANDED_TRANSATION_DURATION};
  }
`

export { StyledCouponRow, CouponBadge, CouponIconWrapper }