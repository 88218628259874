import { FONT_SIZE } from './typography.constants'
import { COLOR } from '../color/color.constants'
import { SPACING } from '../spacing/spacing.constants'

const MARGIN_TYPES = {
  t: 'top',
  r: 'right',
  b: 'bottom',
  l: 'left',
}

function getMarginStyling(style) {
  const marginSizes = Object.keys(SPACING).map(margin => margin.toLowerCase())
  const marginSizeRegexString = marginSizes.join('|')
  const marginStyleRegexString = `^m(t|r|b|l)-(${marginSizeRegexString})$`
  const marginStyleRegex = new RegExp(marginStyleRegexString)
  const isValidMarginStyle = marginStyleRegex.test(style)

  if (!isValidMarginStyle) return ''

  const [originalString, type, size] = style.match(marginStyleRegex)
  return `margin-${MARGIN_TYPES[type]}: ${
    SPACING[size.toUpperCase()]
  } !important;`
}

function getColorStyling(style) {
  const colorConstants = Object.keys(COLOR).map(color => color.toLowerCase())
  const colorConstantsRegexString = colorConstants.join('|')
  const colorStyleRegexString = `^c-(${colorConstantsRegexString})$`
  const colorStyleRegex = new RegExp(colorStyleRegexString)
  const isColorStyle = colorStyleRegex.test(style)

  if (!isColorStyle) return ''

  const [originalString, color] = style.match(colorStyleRegex)
  return `color: ${COLOR[color.toUpperCase()]} !important;`
}

function getFontSizeStyling(style) {
  const fontSizeConstants = Object.keys(FONT_SIZE).map(fontSize =>
    fontSize.toLowerCase()
  )
  const fontSizeConstantsRegexString = fontSizeConstants.join('|')
  const fontSizeRegexString = `^s-(${fontSizeConstantsRegexString})$`
  const fontSizeRegex = new RegExp(fontSizeRegexString)
  const isFontSizeStyle = fontSizeRegex.test(style)

  if (!isFontSizeStyle) return ''

  const [originalString, fontSize] = style.match(fontSizeRegex)
  return `font-size: ${FONT_SIZE[fontSize.toUpperCase()]} !important;`
}

function getTypographyStyling(styleString = '') {
  const lowerCaseStyles = styleString.toLowerCase()
  const stylesList = lowerCaseStyles.split(/\s+/)
  let styles = ''

  stylesList.forEach(style => {
    styles += getMarginStyling(style)
    styles += getColorStyling(style)
    styles += getFontSizeStyling(style)
  })

  return styles
}

export { getTypographyStyling }
