export default {
  accounts: {
    edit: {
      editUser: 'Edit User',
      pageHeader: 'ACCOUNT MANANGEMENT > EDIT USER',
      updateUserFail: 'USER UPDATE FAILED',
      updateUserSuccess: 'USER UPDATED',
    },
    form: {
      accessLevel: 'ACCESS LEVEL',
      branch: 'BRANCH NUMBER',
      branchUser: 'BRANCH USER',
      changePassword: 'CHANGE PASSWORD',
      confirmPassword: 'CONFIRM PASSWORD',
      currentPassword: 'CURRENT PASSWORD',
      detailAddress: 'FLOOR / ROOM',
      email: 'EMAIL',
      master: 'MASTER USER',
      name: 'NAME',
      newPassword: 'NEW PASSWORD',
      pageHeader: 'ACCOUNT MANAGEMENT > USER FORM',
      phone: 'PHONE NUMBER',
      profileForm: 'PROFILE DETAILS',
      profileImage: 'PROFILE IMAGE',
      streetAddress: 'STREET ADDRESS',
      subUser: 'USER',
    },
    new: {
      createUser: 'CREATE NEW USER ACCOUNT',
      pageHeader: 'ACCOUNT MANAGEMENT > CREATE NEW USER',
    },
    view: {
      accessLevel: 'ACCESS LEVEL',
      addNewUser: 'ADD NEW USER',
      admin: 'GGV Super User (ADMIN USER)',
      branch: 'BRANCH',
      branchUser: 'BRANCH USER',
      deactivate: 'DEACTIVATE USER',
      deactivated: 'USER DEACTIVATED',
      deactivateFail: 'USERS FAIL TO DEACTIVATE',
      detailAddress: 'FLOOR / ROOM',
      editProfile: 'EDIT PROFILE DETAILS',
      email: 'EMAIL',
      false: 'FALSE',
      master: 'MASTER USER',
      name: 'NAME',
      pageHeader: 'ACCOUNT MANAGEMENT',
      phone: 'PHONE NUMBER',
      queryPlaceholder: 'NAME, BRANCH, EMAIL, ETC',
      reactivate: 'REACTIVATE',
      reactivated: 'REACTIVATED',
      reactivateFail: 'FAILED TO REACTIVATE',
      search: 'SEARCH:',
      showAll: 'SHOW ALL',
      streetAddress: 'ADDRESS',
      subUser: 'USER',
      true: 'TRUE',
      users: 'USERS',
    },
  },
  addresses: {
    delete: {
      confirmationPrompt:
        'Are you sure you want to delete the selected addresses?',
      routeConfirmationPrompt:
        'Are you sure you want to delete the selected routes?',
      routeSuccessMessage: 'ROUTES DELETED',
      successMessage: 'ADDRESSES DELETED',
    },
    edit: {
      editAddress: 'EDIT CONTACT',
      editRoute: 'EDIT ROUTE',
      pageHeader: 'ADDRESS BOOK > EDIT CONTACT',
      pageHeaderRoute: 'ADDRESS BOOK > EDIT ROUTE',
      update: 'SAVE',
    },
    index: {
      addNewContact: 'ADD NEW CONTACT PERSON',
      addNewRoute: 'ADD NEW ROUTE',
      addresses: 'ADDRESSES',
      branch: 'BRANCH',
      company: 'COMPANY NAME',
      create: 'CREATE',
      createRoute: 'CREATE ROUTE',
      creator: 'USER',
      csvSuccess: 'UPLOAD CSV FILE',
      delete: 'DELETE',
      detailAddress: 'FLOOR / ROOM',
      duplicateAddresses: 'REMOVE DUPLICATE ADDRESSES',
      edit: 'EDIT',
      editRoute: 'EDIT ROUTE',
      email: 'EMAIL',
      filterType: 'FILTER TYPE:',
      importContacts: 'IMPORT CONTACTS FROM CSV',
      name: 'Contact Person',
      next: 'NEXT',
      nouniqueaddress: 'NO UNIQUE ADDRESSES TO IMPORT',
      pageHeader: 'ADDRESS BOOK',
      phone: 'PHONE NUMBER',
      placeNewOrder: 'PLACE AN ORDER',
      previous: 'PREVIOUS',
      queryPlaceholder: 'CONTACT NAME, PHONE, EMAIL, ETC.',
      receiver: 'CONTACT PERSON',
      remarks: 'REMARKS',
      remark: 'REMARK',
      region: 'Region',
      route: 'Related Addresses',
      routeName: 'ROUTE NAME',
      routeNameUnique: 'ROUTE NAME MUST BE UNIQUE',
      routes: 'ROUTES',
      search: 'SEARCH:',
      sender: 'SENDER',
      showingPage: options =>
        `SHOWING PAGE ${options.currentPage} OF ${options.totalPages}`,
      streetAddress: 'ADDRESS',
      successSnackbar: 'ROUTE CREATED',
      totalAddresses: options => `TOTAL ADDRESSES: ${options.count}`,
      totalRoutes: options => `TOTAL ROUTES: ${options.count}`,
      type: 'TYPE',
      update: 'UPDATE',
    },
    new: {
      createAddress: 'CREATE CONTACT',
      pageHeader: 'ADDRESS BOOK > CREATE NEW CONTACT',
      save: 'SAVE & CREATE ANOTHER',
      successMessage: 'CONTACT CREATED',
    },
    routes: {
      edit: {
        success: 'ROUTE EDITED',
      },
    },
  },
  constants: {
    addressBoxFields: {
      addressType: 'ADDRESS TYPE',
      addressTypeReceiver: 'RECIPIENT',
      addressTypeSender: 'SENDER',
      collectOnDelivery: 'COLLECT ON DELIVERY',
      company: 'Company name',
      desiredDeliveryTime: 'Desired delivery time',
      desiredDeliveryTimePlaceholder: 'Delivery time (e.g. 15:30)',
      destination: 'Drop-off point',
      destinationCSV: 'Select a drop-off point, or click on the map',
      detailAddress: 'Floor / room',
      email: 'EMAIL',
      instructions: 'Remarks',
      parcelIds: 'PARCEL IDs',
      parcelIdsPlaceholder: 'Parcel IDs separated by semicolons',
      phone: 'Phone number',
      pickupPoint: 'PICK-UP POINT',
      receiver: 'Recipient',
      region: 'REGION*',
      sender: 'SENDER*',
      waypoint: 'WAY POINT',
    },
    currency: {
      prefix: 'HK$',
    },
    goodsTypes: {
      constructionMaterials: 'Construction Materials',
      documents: 'Documents',
      food: 'Food',
      fragile: 'Fragile',
      groceries: 'Groceries',
      nil: 'Unselected',
      none: 'Unselected',
      null: 'Unselected',
      others: 'Others',
    },
    goodTypes: {
      none: 'Unselected',
    },
    industries: {
      accounting: 'Accounting and Administration',
      agriculture:
        'Agriculture, Forestry, Animal Husbandry, Fishing Occupations',
      arts: 'Arts and Design',
      construction: 'Construction Industry',
      culture: 'Culture, Sports, Media and Entertainment',
      education: 'Education',
      electricity:
        'Electricity, Heat, Fuel and Water Production and Supply Industry',
      environment: 'Environment and Public Facilities Management',
      financial: 'Financial and Insurance Industry',
      healthcare: 'Healthcare and Social Work',
      hotels: 'Hotels, Tourism and Catering Services',
      information: 'Information and Communications Services',
      international: 'International Organizations',
      leasing: 'Leasing and Business Services',
      legal: 'Legal Services',
      manufacturing: 'Manufacturing',
      mining: 'Mining and Quarrying Occupations',
      others: 'Others',
      professional: 'Professional, Scientific and Technical Services',
      publicAdministration:
        'Public administration, Social Security and Social Organizations',
      realEstate: 'Real Estate',
      residentServices: 'Resident Services, Repairs and Other Services',
      transport: 'Transport, Storage, Postal and Courier Services',
      wholesale: 'Wholesale and Retail Trade',
    },
    orderRequestStatuses: {
      active: 'Active',
      cancelled: 'Cancelled',
      completed: 'Completed',
      pending: 'Wait for driver',
    },
    parcelStatuses: {
      assigned: 'ASSIGNED',
      delivered: 'SUCCESSFUL',
      failedToDeliver: 'FAILED DELIVERY',
      parcelPickedUp: 'ON ITS WAY',
      pending: 'PENDING',
      reassigned: 'REASSIGNED',
    },
    vehicles: {
      damas: '',
      damasDescription: '',
      labo: '',
      laboDescription: '',
      lbread: '',
      lbreadDescription: '',
      lorry10: '',
      lorry10Description: '',
      lorry14: '',
      lorry14Description: '',
      lorry24: '',
      lorry24Description: '',
      ltruck: '',
      ltruckDescription: '',
      mbread: '',
      mbreadDescription: '',
      motorcycle: 'Motorcycle',
      motorcycleDescription: '',
      mtruck: '3.5ton truck',
      mtruckDescription: '',
      mudou: '5.5 ton truck',
      mudou9: '9 ton truck',
      mudou9Description:
        'Carries max. 5 passengers; max. load 3-3.5 tons; available storage space dimensions: 19’-21’ length, 6’ height, 6’ width.',
      mudouDescription:
        'Carries max. 5 passengers; max. load 1-1.5 tons; available storage space dimensions: 17’-19’ length, 6’ height, 6’ width.',
      sbread: '',
      sbreadDescription: '',
      sedan: '豪客',
      sedanDescription: '',
      sltruck: '',
      sltruckDescription: '',
      struck: 'Small truck',
      struckDescription: '',
      ton1: '',
      ton1_2_and_ton1_4: '',
      ton1_2_and_ton1_4Description: '',
      ton11: '',
      ton11Description: '',
      ton14: '',
      ton14Description: '',
      ton15: '',
      ton15Description: '',
      ton18: '',
      ton18Description: '',
      ton1Description: '',
      ton2_5: '',
      ton2_5Description: '',
      ton22: '',
      ton22Description: '',
      ton25: '',
      ton25Description: '',
      ton3_5: '',
      ton3_5Description: '',
      ton4_5: '',
      ton4_5Description: '',
      ton5: '',
      ton5Description: '',
      ton8: '',
      ton8Description: '',
      ton9_5: '',
      ton9_5Description: '',
      tricycle: 'Tricycle',
      truck500: 'Truck500',
      truck1000: 'Truck1000',
      truck1500: 'Truck1500',
      truck2000: 'Truck2000',
      van: 'Van',
      vanDescription:
        'Maximun 5 passengers Maximum load 700 kg Available storage space 6′ L, 4′ H, and 4′ W',
    },
  },
  errors: {
    auth: {
      restricted: 'RESTRICTED ACCESS',
    },
    generic: 'Something went wrong! Please try again.',
    page_not_found: {
      home: 'HOME',
      message: 'Sorry, an error occured, requested page not found!',
      oops: 'Oops!',
      status: '404 Not Found',
    },
  },
  header: {
    addressBook: 'ADDRESS BOOK',
    dropdown: {
      accountMgmt: 'ACCOUNT MANAGEMENT',
      contactUs: 'CONTACT US',
      corporateProfile: 'COMPANY PROFILE',
      help: 'HELP',
      logout: 'LOGOUT',
      switchChineseEnglish: '繁體中文',
      tnc: 'TERMS AND CONDITIONS',
    },
    orderMgmt: 'ORDER MANAGEMENT',
    placeOrder: 'PLACE AN ORDER',
    tnc: {
      agree: 'I agree and accept the terms and conditions mentioned above',
      next: 'NEXT',
      previous: 'PREVIOUS',
      proceed: 'PROCEED',
      title: 'Terms ＆ Conditions',
    },
    wallet: 'BILLING & BALANCE',
  },
  helpers: {
    addressBox: {
      autocompletePopover: {
        locationSuggestion: 'Location suggestion',
        savedAddress: 'Saved address',
      },
      index: {
        addALocation: 'Add a waypoint',
        address: 'ADDRESS',
        addressBoxTitle: 'Pick-up information',
        addressCSV: 'ADDRESS (*)',
        cantFindAddress:
          'Could not find one or more of the destinations please check the destinations.',
        collectOnDelivery: 'COLLECT ON DELIVERY',
        company: 'COMPANY NAME',
        contactName: 'CONTACT PERSON',
        contactPhone: 'CONTACT NUMBER',
        desiredDeliveryTime: 'DELIVERY TIME',
        detailAddress: 'FLOOR / ROOM',
        downloadTemplate: 'DOWNLOAD CSV TEMPLATE',
        email: 'EMAIL',
        goodsAndServiceSetting: 'Goods & service setting',
        addDestination: 'ADD A WAYPOINT',
        importRoutes: 'IMPORT ROUTE(S)',
        notApplicable: 'N/A',
        parcelIds: 'PARCEL IDs',
        region: 'REGION',
        remark: 'REMARKS',
        remarkValue: options =>
          `Collect on delivery: ${options.collectOnDelivery}, Remark: ${options.remark}`,
        tooManyLines: 'The csv file import is limited to 100 rows per file.',
        viewAll: 'VIEW ALL',
        route: 'ROUTE',
      },
      waypoint_details: {
        okButton: 'OK',
        cancelButton: 'CANCEL',
      },
    },
    button: {
      cancel: 'CANCEL',
      chooseFromComputer: 'CHOOSE FROM COMPUTER',
      saveAndExit: 'SAVE & EXIT',
      search: 'SEARCH',
    },
    datepicker: {
      cancel: 'Cancel',
      date: 'Date',
      ok: 'OK',
    },
    dialog: {
      cancel: 'CANCEL',
      confirm: 'CONFIRM',
      ok: 'OK',
      submit: 'SUBMIT',
    },
    loading: {
      loading: 'Loading...',
    },
    mapView: {
      driver: 'DRIVER',
      eta: 'ETA',
      license: 'LICENSE PLATE NUMBER',
      notApplicable: 'N/A',
      phone: 'PHONE NUMBER',
      remark: 'Remark',
      travelTime: 'TRAVEL TIME',
    },
    pagination: {
      totalSeparator: 'of',
    },
    text: {
      addressNotFound:
        "Sorry, we couldn't find any matching address. You may check your spelling and try again.",
      required: "Field can't be empty.",
    },
    timepicker: {
      cancel: 'Cancel',
      ok: 'OK',
    },
  },
  images: {
    logo: 'general_3/logo',
  },
  notification: {
    actionButton: {
      cancel: 'CANCEL ORDER',
      cancelled: 'CANCELLED',
    },
  },
  notificationCentre: {
    drawerTitle: {
      notification: 'NOTIFICATIONS',
    },
    message: {
      todayAt: options => `TODAY @ ${options.time}`,
    },
    messagesCentre: {
      loadMore: 'LOAD MORE NOTIFICATIONS',
      prefs: 'NOTIFICATION PREFERENCES',
    },
    preferencesCentre: {
      byEmail: 'Receive Notification by email',
      prefs: 'PREFERENCES',
    },
  },
  orders: {
    dialog: {
      badAttitude: 'Bad attitude',
      branch: 'BRANCH:',
      cancel: 'CANCEL ORDER',
      cbm: 'CBM',
      collectOnDelivery: options => `Collect On Delivery: ${options.fee}`,
      collectOnDeliveryHeader: 'Collect On Delivery',
      confirmOrderComplete:
        'Order completed. Please rate our driver performance.',
      contactNumber: 'Recipient phone number',
      desiredDeliveryTime: options => `Desired Delivery Time: ${options.time}`,
      desiredDeliveryTimeHeader: 'Desired Delivery Time',
      destination: 'Drop-off point',
      disputeClosed: 'Dispute Settled',
      disputeClosedMessage: options =>
        `Order #${options.orderRequestId} price amendment has been handled`,
      disputeReported: 'Report as Disputed Case',
      disputeSubmittedMessage: options =>
        `Order #${options.orderRequestId} dispute reported`,
      download: 'DOWNLOAD CSV TEMPLATE',
      driver: 'DRIVER:',
      duration: 'DURATION:',
      email: 'EMAIL',
      feeIssue: 'Pricing issue',
      goodsInfo: 'Goods Info',
      help: 'HELP',
      images: 'IMAGES',
      import: 'Import Routes',
      importSingleRouteButton: 'UPLOAD CSV FILE',
      importMultiRouteButton: 'UPLOAD CSV FILE',
      item: 'TYPES OF GOODS',
      licensePlate: 'LICENSE PLATE NUMBER:',
      longLoadingTime: 'Exceed Loading Time',
      noGoodsInfo: 'No goods information',
      noimages: 'No Images',
      nosignature: 'No Signature',
      notApplicable: 'N/A',
      notOnTime: 'Driver was not on time',
      orderCompletionConfirmed: 'Order completion confirmed',
      orderNumber: options => `ORDER NUMBER: ${options.id}`,
      other: 'Other',
      parcelNumber: 'Parcel ID',
      phoneNumber: 'PHONE NUMBER:',
      pickupTime: 'PICK-UP TIME:',
      pickupTimeCSV: 'PICK-UP TIME (*)',
      previewImage: 'Preview Image',
      previewSignature: 'Preview Signature',
      priceBreakdown: 'PRICE BREAKDOWN:',
      quantity: 'QUANTITY',
      rateAndConfirmCompletion: 'RATE',
      reachingOut:
        "(options.count === 0 || !options.count) ? 'Reaching out to drivers' : (options.count === 1) ? 'Reaching out to 1 driver' : 'Reaching out to ' + options.count + ' drivers'",
      reachingOutNone: 'Reaching out to drivers',
      reachingOutOne: 'Reaching out to 1 driver',
      reachingOutMany: options => `Reaching out to ${options.count} drivers`,
      recipient: 'Recipient',
      remarks: 'REMARKS',
      remarksFile: 'Remarks',
      reportDispute: 'Price Dispute',
      reportDisputeConfirmMessage:
        'Are you sure that you want to report price dispute? Once reported, we will contact you soon.',
      reuse: 'REUSE ROUTE',
      routeChanged: 'Route was changed',
      routes: 'Routes',
      share: 'SHARE',
      sizeOfGoods: 'SIZE OF GOODS',
      timeSheetRemarks: 'Driver Remarks',
      timeSheets: 'Reference documents',
      totalFee: 'TOTAL PRICE:',
      vehicle: 'VEHICLE TYPE',
      vehicleType: 'VEHICLE TYPE',
      vehicleTypeCSV: 'VEHICLE TYPE',
      weight: 'WEIGHT',
      whatWasTheProblem: 'What was the problem?',
      singleRoute: 'Import Single Route',
      multiRoute: 'Import Multiple Routes',
    },
    filter: {
      amended: 'Updated Price',
      branch: 'BRANCH',
      disputeStatus: 'DISPUTE STATUS',
      open: 'Reported Dispute',
      ordersFrom: 'FROM',
      parcelStatus: 'PARCEL STATUS',
      pickupDate: 'Pick-up Date',
      queryPlaceholder: "Driver or Client 's Name / Mobile",
      search: 'SEARCH:',
      showAll: 'Show all',
      status: 'ORDER STATUS',
      to: 'TO',
      vehicle: 'VEHICLE',
    },
    new: {
      goodsRequirements: {
        goodsDetails: 'GOODS DETAILS',
      },
      basicInfo: {
        addInstructions: 'ADD REMARKS',
        disabled: 'disabled',
        edit: 'EDIT',
        email: 'EMAIL',
        goodsAndServiceSetting: 'GOODS & SERVICE SETTING',
        extraRequirements: 'ADDITIONAL REQUIREMENTS',
        hourlyRental: 'Hourly rental',
        hours: 'Hours',
        instructions: 'REMARKS',
        noRental: 'No hourly rental',
        phone: 'PHONE NUMBER',
        remarks: 'REMARKS',
        pickupDate: 'PICK-UP DATE',
        pickupPoint: 'PICK-UP POINT',
        pickupTime: 'Pick-up time',
        sender: 'SENDER:',
        now: 'Now',
        later: 'Choose date and time',
        tunnel: 'PREFERRED TUNNEL',
        tunnel_any: 'Any tunnel',
        tunnel_hung: 'Cross-Harbour Tunnel',
        tunnel_western: 'Western Habour Tunnel',
        tunnel_eastern: 'Eastern Harbour Crossing',
        tunnel_lion_rock: 'Lion Rock Tunnel',
        tunnel_tates_cairn: 'Tates Cairn Tunnel',
        tunnel_eagles_nest: 'Eagles Nest Tunnel',
        tunnel_tai_lam: 'Tai Lam Tunnel',
        tunnel_aberdeen: 'Aberdeen Tunnel',
        tunnel_tko: 'Tseung Kwan O Tunnel',
        tunnel_tm_bridge: 'Tsing Ma Bridge',
        tunnel_discovery: 'Discovery Bay Tunnel',
        tunnel_shing_mun: 'Shing Mun Tunnel',
        passenger: 'NO. OF PASSENGERS',
        selection: {
          today: 'Today',
          tomorrow: 'Tomorrow',
          later: 'Later',
        },
        placeholder: {
          selectTime: 'Select time',
        },
        subtitle: {
          total: 'Total',
        },
        chargeInfo: 'You won’t be charged until order is complete',
      },
      index: {
        newOrder: 'New Order',
      },
      basicinfo: {
        selection: {
          asap: 'ASAP',
        },
      },
      total: {
        insuranceTitle: 'Free insurance coverage up to HK$5,000',
        insuranceDescription:
          'In order to be eligible for this insurance coverage, please take a photo of your package prior to delivery.',
      },
    },
    view: {
      address: 'ADDRESS',
      detail: 'Details',
      disputeStatuses: {
        amended: 'Price Updated',
        closed: 'Reported Dispute - Settled',
        open: 'Reported Dispute - Processing',
      },
      driverInfo: 'DRIVER INFO',
      eta: 'ETA',
      exportThisReport: 'EXPORT THIS REPORT',
      extraDropoffPoints: options => ` +${options.count} Drop-off point(s)`,
      filterBranch: 'Branch',
      filterCurrent: 'Current filters',
      filterDispute: 'Dispute status',
      filterNoOrders: options =>
        `No orders found from ${options.startDate} to ${options.endDate}`,
      filterNoOrdersSearch: options =>
        `No orders found for the search term: ${options.searchTerm}`,
      filterOrderStatus: 'Order status',
      filterParcel: 'Parcel status',
      filterSearch: 'Search',
      filterVehicle: 'Vehicle',
      mapDetail: 'Map | Detail',
      mapView: 'Map View',
      next: 'NEXT',
      nosignatureorimages: 'NO IMAGES OR SIGNATURE',
      orderNumber: 'Order ID',
      pageHeader: 'Order Management',
      parcelId: 'PARCEL ID',
      parcelNumber: 'Parcel ID',
      parcelView: 'By Parcel',
      pickupTime: 'PICK-UP TIME',
      placedBy: 'PLACED BY',
      plusReceivers: options => ` +${options.count} recipient(s)`,
      previous: 'PREVIOUS',
      priceColumn: 'PRICE',
      receiverInfo: 'RECIPIENT INFO',
      receivers: options => `${options.count} recipients`,
      route: 'ROUTE',
      sender: 'SENDER INFO',
      showingPage: options =>
        `SHOWING PAGE ${options.currentPage} OF ${options.totalPages}`,
      status: 'STATUS',
      statusReason: 'STATUS REASON',
      tableView: 'Table View',
      timestamp: 'TIMESTAMP',
      todayStatus: options =>
        `Hi ${options.org}! You have: ${options.active} active, ${options.pending} pending, ${options.completed} completed and ${options.cancelled} cancelled orders today`,
      totalAmount: 'TOTAL AMOUNT:',
      totalOrders: options => `TOTAL ORDERS: ${options.count}`,
      totalParcels: options => `TOTAL PARCELS: ${options.count}`,
      updateInfo: 'UPLOADED INFO',
      vehicle: 'VEHICLE',
    },
    waypointStatus: {
      arrivedAt: options => `ARRIVED @ ${options.time}`,
      deliveredAt: options => `DELIVERED @ ${options.time}`,
      eta: options => `ETA ${options.eta}`,
      findingDriver: 'FINDING DRIVERS',
      pending: 'PENDING',
    },
  },
  organization: {
    switch: {
      apply: 'APPLY',
      branchLabel: 'Branch',
      label: 'Switch Organization',
      organizationLabel: 'Organization',
    },
  },
  organizations: {
    addressEdit: {
      destination: 'DROP-OFF POINT*',
      detailAddress: 'FLOOR / ROOM',
      editMyOrgAddress: 'Edit My Company Address',
      pageHeader: 'COMPANY PROFILE > EDIT PROFILE > CHANGE ADDRESS',
    },
    edit: {
      add: 'Add',
      addABranch: 'ADD A BRANCH',
      branchAddress: 'Branch Address',
      branches: 'BRANCHES',
      branchName: 'Branch Name',
      cannotRemoveApiBranchErr:
        'You cannot delete or edit this Branch which linked with an API key',
      cannotRemoveBranchErr:
        'Sorry, this branch cannot be removed because it has existing users.',
      companyWebsite: 'COMPANY WEBSITE',
      contactPerson: 'CONTACT PERSON',
      contactPersonPhone: 'CONTACT PERSON PHONE NUMBER',
      corporateName: 'COMPANY NAME',
      corporateProfileImage: 'COMPANY PROFILE IMAGE',
      detailAddress: 'FLOOR / ROOM',
      editAddress: 'EDIT ADDRESS',
      editMyOrg: 'Edit My Company',
      email: 'GENERAL EMAIL',
      industry: 'INDUSTRY',
      mapAddress: 'ADDRESS',
      pageHeader: 'COMPANY PROFILE > EDIT PROFILE',
      remark: 'GENERAL REMARKS (driver reference)',
    },
    show: {
      branches: 'BRANCHES',
      companyWebsite: 'COMPANY WEBSITE',
      contactPerson: 'CONTACT PERSON',
      contactPersonPhone: 'CONTACT PERSON PHONE NUMBER',
      detailAddress: 'DETAIL ADDRESS',
      editProfile: 'EDIT PROFILE',
      email: 'GENERAL EMAIL ADDRESS',
      industry: 'INDUSTRY',
      mapAddress: 'ADDRESS',
      myOrg: 'MY COMPANY',
      pageHeader: 'COMPANY PROFILE',
      remark:
        "General Remarks (General remarks will be shown on every order for driver's reference.)",
      salesRepresentative: '',
      unassigned: '',
    },
  },
  popup: {
    merge: {
      dismissAll: 'DISMISS ALL',
      notifStatement: options =>
        `There are ${options.count} more new notifications`,
      seeAll: 'SHOW ALL',
    },
    message: {
      dismiss: 'DISMISS',
      todayAt: options => `TODAY @ ${options.time}`,
    },
    newFeauture: {
      title: 'Introducing courier delivery!',
      body:
        'Have your packages delivered by our couriers in 4 hours or the same day.',
    },
    info: {
      insuranceDetails: {
        title: 'Insurance Details',
      },
    },
    actionButton: {
      claimInsurance: 'Write claim request email',
      close: 'Close',
      getStarted: 'Get started',
    },
    confirm: {
      removeImage: {
        title: 'Remove image',
        content: 'Are you sure you want to remove this image?',
      },
      actionButton: {
        removeImage: 'Confirm remove image',
        cancelOrder: 'Confirm cancel order',
        removeCard: 'Confirm & remove card',
        addCard: 'Add card',
      },
      cancelOrder: {
        title: 'Cancel order',
        content:
          'Are you sure you want to cancel this order? You will not be charged.',
      },
      addCard: {
        content: 'Card will be save and pay for courier delivery service.',
      },
    },
  },
  session: {
    dialog: {
      multiSessionBody: 'You are being logged out',
      multiSessionTitle: 'Multiple Sessions Detected',
      havePermit: 'I HOLD A VALID PERMIT',
      noPermit: 'I DO NOT HOLD A VALID PERMIT',
      polygonDialogHeader: 'Restricted Area',
      no_service: options =>
        `The following address is out of service area. ${options.address} Should you need further asistance, please contact us at 35908975.`,
      restricted_area: options =>
        `The following address is restricted to closed road permit holders only. ${options.address} If you don't have a valid permit, the fare will be quoted by driver. Please contact us at 35908975 for further assistance.`,
      restricted_area_both: options =>
        `The following address is restricted to closed road permit holders only. ${options.address} The fare will be quoted by driver. Please contact us at 35908975 for further assistance.`,
      gotIt: 'GOT IT',
    },
  },
  user_suspended: {
    dialog: {
      dialog_message:
        'We regret to inform you that your account has been frozen. If you would like to continue using GOGO Business, please contact us at 3590 8975',
      ok_button: 'OK',
    },
  },
  users: {
    login: {
      signIn: 'Sign in',
    },
    profile: {
      accessLevel: 'ACCESS LEVEL',
      accMgmt: 'ACCOUNT MANAGEMENT',
      address: 'ADDRESS',
      branch: 'BRANCH',
      detailAddress: 'FLOOR  / ROOM',
      editCorporateProfile: 'EDIT COMPANY PROFILE',
      editMyProfile: 'EDIT MY PROFILE',
      email: 'EMAIL',
      myAcc: 'MY ACCOUNT',
      notApplicable: 'N/A',
      pageHeader: 'MY ACCOUNT',
      phoneNumber: 'CONTACT NUMBER',
    },
    profileEdit: {
      accessLevel: 'ACCESS LEVEL',
      admin: 'Admin',
      branch: 'BRANCH NUMBER',
      branchUser: 'BRANCH USER',
      changePassword: 'CHANGE PASSWORD',
      confirmPassword: 'CONFIRM PASSWORD',
      currentPassword: 'CURRENT PASSWORD',
      detailAddress: 'FLOOR / ROOM',
      editMyAcc: 'Edit My Account',
      email: 'EMAIL',
      master: 'MASTER USER',
      name: 'NAME',
      newPassword: 'NEW PASSWORD',
      pageHeader: 'ACCOUNT MANAGEMENT > EDIT PROFILE',
      passwordMismatch:
        'YOUR NEW PASSWORD AND PASSWORD CONFIRMATION MUST MATCH',
      phone: 'PHONE NUMBER',
      streetAddress: 'STREET ADDRESS',
      subUser: 'USER',
    },
    changePassword: {
      updateFail: 'User details updated. Password update failed.',
    },
  },
  utils: {
    time: {
      hours: 'hour',
      minutes: 'min',
      seconds: 's',
    },
  },
  validations: {
    errors: {
      baseErrorAddress: 'Invalid address',
      baseErrorEmail: 'Invalid email',
      baseErrorNumber: 'Not a number',
      baseErrorPhone: 'Invalid phone number',
      baseErrorUnknown: 'Error: Data Identical',
      completeErrorMessage: options =>
        `${options.baseError} at line(s) ${options.faultyLines}`,
      emptyAddress: 'Please select a drop-off point.',
      expiredResetPasswordToken: 'The password reset link has expired.',
      failedToComputeLatLon: options =>
        `Failed to compute the latitude/longitude of ${options.field}.`,
      invalidDesiredDeliveryTime: 'Please enter a valid time format.',
      invalidEmailFormat: 'Invalid email format.',
      invalidFields: 'Invalid fields.',
      invalidNumberFormat: 'Invalid number format.',
      invalidOrder: 'Invalid order. Please check your data.',
      invalidPassword: 'Password is invalid.',
      invalidPhone: 'One or more of the entered phone numbers are invalid',
      invalidPhoneNumber: 'Invalid phone number.',
      invalidUsernameOrPassword: 'Username or password is invalid.',
      missingField: 'Missing field',
      passwordLengthTooShort:
        'Your password needs to have at least 8 letter or digits',
      passwordMismatch: 'Password mismatch.',
      unresolveableAddress: 'Please select an address from the search result.',
      usedResetPasswordToken: 'The password reset link has been used',
      invalidPasswordMin8: 'Please enter a password with minimum 8 characters.',
    },
    fields: {
      address: 'Address',
      contactName: 'Contact person name',
    },
    phoneRegexp: '/^[0-9]{8}$/',
  },
  wallet: {
    filter: {
      month: 'MONTH',
      showAll: 'Show All',
      year: 'YEAR',
    },
    view: {
      amount: 'TOTAL PRICE',
      balance: 'BALANCE',
      companyName: 'COMPANY NAME',
      date: 'DATE',
      deposit: 'DEPOSIT',
      depositInfo:
        'Deposits works like a credit limit that a client can use in GoGoX Business. If you are expecting to have monthly usage around $3000, we recommend you to prepare $3000 as a deposit for setting up GoGoX Business account.',
      depositView: 'Deposits',
      description: 'DESCRIPTION',
      downloadReport: 'Download Report',
      next: 'NEXT',
      orderRequestId: 'ORDER NUMBER',
      pageHeader: 'BILLING & BALANCE',
      previous: 'PREVIOUS',
      showingPage: options =>
        `SHOWING PAGE ${options.currentPage} OF ${options.totalPages}`,
      time: 'TIME',
      totalDeposits: options => `TOTAL DEPOSITS: ${options.count}`,
      totalOrders: options => `TOTAL ORDERS: ${options.count}`,
      totalTransactions: options => `TOTAL TRANSACTIONS: ${options.count}`,
      transaction: 'TRANSACTION',
      transactionTypes: {
        deposit: 'Deposit',
        orderCompletion: 'Completed Order',
        orderDispute: 'Disputed Order',
        settlement: 'Payment - Thank You',
      },
    },
    internallink: {
      addCard: 'Add credit card',
      removeCard: 'Remove credit card',
    },
  },
  sidemenu: {
    van: {
      title: 'Van service',
      item: {
        placeOrder: 'Place an order',
        orderManagement: 'Order management',
        addressBook: 'Address book',
        billing: 'Billing & balance',
      },
    },
    d2d: {
      title: 'Courier delivery',
      item: {
        placeOrder: 'Place an order',
        orderManagement: 'Order management',
        wallet: 'Wallet',
      },
    },
  },
  onboarding: {
    header: {
      selectService: 'What service do you need?',
    },
    serviceType: {
      title: {
        van: 'Van service',
        d2d: 'Courier delivery',
      },
      body: {
        van: 'Van service booking on demand',
        d2d: 'Courier delivery with  4 hours or same day delivery',
      },
      description: {
        van: '(e.g. tables or matress)',
        d2d: '(e.g. parcel or fruit baskets)',
      },
    },
    van: {
      title: 'Van service for GOGO Business',
      feature1: 'Monthly payment settlement to \nease business transactions.',
      feature2: 'Dedicated account manager to \nsupport your business need.',
      feature3:
        'Manage and review all your orders \nand billings in one place.',
      activate: {
        title: 'Activate monthly settlement (Van service only)',
        status: 'Deposit not confirmed yet',
      },
      description:
        'Please follow the instructions in email to provide Business Registration copy and Deposit Receipt, so that van service for GOGO Business would be enabled with monthly settlement in your account. If you’ve any questions, please reach us at',
      email: 'business@gogovan.hk',
    },
    delivery: {
      title: 'Courier delivery for GOGO Business',
      description:
        'If you want to place an order for small package (less than 60cm x 60cm x 60cm) delivery from door to door, please',
      link: 'click here',
    },
  },
  sectionHeader: {
    serviceType: {
      van: {
        name: 'Van service',
        placeOrder: 'Place an order',
        orderManagement: 'Order management',
        addressBook: 'Address book',
        billing: 'Billing & balance',
      },
      d2d: {
        name: 'Courier delivery',
      },
    },
  },
  error: {
    invalid: {
      address: 'Please enter a valid address',
      contact: {
        number: 'Please enter a valid contact number',
      },
    },
  },
  orderManagement: {
    actionButton: {
      placeOrder: 'Place an order',
    },
    tab: {
      orders: {
        all: 'All',
        active: 'Active',
        past: 'Past',
      },
    },
  },
  orderDetails: {
    courier: 'Courier',
    loading: 'Please wait while we search for couriers...',
    title: {
      status: 'Status',
      shippingInfo: {
        packageSize: 'Package size',
        packageWeight: 'Package weight',
      },
    },
    status: {
      orderConfirm: 'Order confirmed',
      accepted: 'Accepted by courier',
      pickup: 'Package picked up',
      complete: 'Order delivered',
    },
    shippingInfo: {
      button: {
        courier: 'Courier',
      },
    },
  },
  systemMessage: {
    order: {
      cancelled: 'Order cancelled successfully',
      cancelledReturn:
        'Order cancelled successfully. Your courier will contact you to arrange the return of the package.',
    },
  },
  badges: {
    new: 'NEW',
  },
  newOrder: {
    tab: {
      orderInfo: {
        name: '1. Order info',
        subtitle: {
          route: 'Route',
          pickupTime: 'Pick-up time',
          hourlyRental: 'Hourly rental',
          vehicleType: 'Vehicle type',
          noOfVehicle: 'No. of vehicle',
        },
        route: {
          textfield: {
            pickUpPoint: 'Pick-up/drop-off point',
            floorRoom: 'Floor/room',
            name: 'Name',
            phone: 'Phone',
          },
          link: {
            addWaypoint: 'Add waypoint',
            clearWaypoint: 'Clear',
            removeWaypoint: 'Remove',
          },
          phoneError: 'Please enter a valid phone number',
          addressError: 'Please enter a valid address',
        },
        description: {
          noOfVehicle: 'All vehicles follow the same route',
        },
        selection: {
          hourlyRental: 'No hourly rental',
          vehicleType: {
            van: 'Van',
            Truck55: '5.5 ton truck',
            Truck9: '9 ton truck',
            hoverInfo: {
              van: {
                title: 'Van',
                content:
                  'Maximum 5 passengers, maximum load 700kg. Available storage space 6’L, 4’ H, and 4’W',
              },
              truck55: {
                title: '5.5 ton truck',
                content:
                  'Carries max. 5 passengers; load 1-1.5 tons; available storage space dimensions: 17’-19’ length, 6’ height, 6’ width.',
              },
              truck9: {
                title: '9 ton truck',
                content:
                  'Carries max. 5 passengers; load 3-3.5 tons; available storage space dimensions: 19’-21’ length, 6’ height, 6’ width.',
              },
            },
          },
        },
        btnNext: 'Next',
        hourlyRental: {
          hours: 'hours',
        },
      },
      additional: {
        name: '2. Additional',
        title: {
          additionalService: 'Additional service',
        },
        selection: {
          passengers: {
            van: {
              title: 'Passengers ($0)',
            },
            truck55n9: {
              title: 'Passengers ($20)',
              description:
                'A double cab truck with more seats is needed to hold 3 or more passengers at a single fee of $20.',
            },
            options: {
              passenger1: '1\b passenger',
              passengers2: '2\b passengers',
              passengers3: '3\b passengers',
              passengers4: '4\b passengers',
              passengers5: '5\b passengers',
            },
          },
          rentCart: {
            title: 'Rent cart ($10 per 1)',
            options: {
              cart1: '1 cart',
              cart2: '2 carts',
              cart3: '3 carts',
            },
          },
          rentForklift: {
            title: 'Rent forklift ($20 per 1)',
            options: {
              forklift1: '1 forklift',
              forklift2: '2 forklifts',
              forklift3: '3 forklifts',
            },
          },
          goodOver6Ft: {
            title: 'Goods longer than 6 feet ($15-30)',
            options: {
              goodOver6Ft: 'Goods size over 6 feet ($15)',
              goodOver6x2Ft: 'Goods size over 6x2 feet ($30)',
            },
          },
          pet: {
            title: 'Transport pets ($10)',
          },
          newVehicle: {
            title: 'New vehicle ($5)',
          },
          engDriver: {
            title: 'English-speaking driver ($5)',
          },
          moveGoods: {
            title: 'Move goods door-to-door (Quote)',
            hover:
              'Driver will give you a quote based on your note about your goods.',
          },
          disposeWaste: {
            title: 'Transport or dispose waste (Quote)',
            hover:
              'Driver will give you a quote based on the your note about your waste.',
          },
          noteToDriver: {
            title: 'Notes to driver',
            description: 'Describe your goods and special request. (Optional)',
            characterLimit: 'Character limit: 1000',
            textfield: {
              placeholder: 'Note',
            },
            exceedCharacterError:
              'You have exceeded the character limit of 1000.',
          },
        },
        btnBack: 'Back',
        btnGetQuote: 'Get quote',
      },
      summary: {
        name: '3. Summary',
        title: {
          price: 'Price',
          estimatedTotal: {
            label: 'Estimated total',
            price: {
              quote: 'Quote',
            },
          },
          routeFee: 'Route fee',
          additionalService: 'Additional service',
          orderSummary: 'Order summary',
          pickUp: 'Pick-up',
          route: 'Route',
          vehicle: 'Vehicle',
          noteToDriver: 'Note to driver',
        },
        breakdown: {
          passengers: 'Passengers (%)',
          rentCart: 'Rent cart (%)',
          rentForklift: 'Rent forklift (%)',
          goodOver6Ft: 'Goods size over 6 feet',
          goodOver6x2Ft: 'Goods size over 6x2 feet',
          pet: 'Transport pets',
          newVehicle: 'New vehicle',
          engDriver: 'English-speaking driver',
          disposeWaste: 'Transport or dispose waste',
          waitingTime: 'Waiting time (% min)',
          moveGoods: 'Move goods door-to-door',
        },
        feeRemark:
          'The above price does not include tunnel fees, bridge fees, parking fees and all other misc. fees. Please confirm with driver the total price on boarding.',
        link: {
          learnMore: 'Learn more about pricing',
        },
        btnEdit: 'Edit order',
        btnPlaceOrder: 'Place order',
        priceUpdatePrompt: {
          title: 'Your order has been updated',
          content:
            'The selected time has passed and your fee has been updated.',
        },
      },
    },
  },
  signup: {
    createAccount: {
      title: 'Create an account',
      description:
        'Create your free account at GOGO Business to enjoy cashless payment, easy order management, and dedicated account manager.',
      textfield: {
        name: 'Name',
        Email: 'Email',
        phone: 'Phone number',
        error: {
          empty: {
            name: 'Please enter a name',
            email: 'Please enter an email address',
            phone: 'Please enter a phone number',
            pw: 'Please enter a password',
            reenterPw: 'Please re-enter password',
          },
          format: {
            email: 'Please enter a valid email address',
            phone: 'This email is already taken',
            pw: 'Password must be at least 8 characters',
          },
          exist: {
            phone: 'This phone number is already being used',
          },
          match: {
            pw: "Password didn't match. Please try again",
          },
        },
      },
      actionButton: {
        createAccount: 'Create account',
      },
      smalltext: {
        termsOfService: 'Terms of service',
        and: 'and',
        privacyPolicy: 'Privacy policy',
        enterpriseUser:
          'For enterprise with high volume usage, please contact us at',
        phone: '(+852) 3590 8975 or',
        email: 'business@gogovan.hk ',
      },
      checkMail: {
        title: 'Check your email',
        description1: options =>
          `We’ve sent you a verification email to ${options.email}.\n`,
        description2:
          'If you don’t see it in your inbox, please check your spam folder.',
        smalltext: {
          email: 'business@gogovan.hk ',
        },
      },
    },
    activateAccount: {
      setpw: {
        title: 'Activate account',
        description:
          'Welcome to GOGO Business, let’s set this up! \nFirst please create a password.',
        textfield: {
          password: 'Password',
          reenterPassword: 'Re-enter password',
        },
        button: {
          next: 'Next',
        },
      },
      companyInfo: {
        title: 'Tell us a little about your business',
        description: 'It help us to personalize your onboarding experience',
        textfield: {
          title: {
            companyName: 'Company name',
            industry: 'Industry',
          },
          placeholder: {
            companyName: 'Company name',
          },
        },
        selection: {
          placeholder: {
            selectIndustry: 'Select industry',
          },
        },
        selectIndustry: {
          selection: {
            constructions: 'Constructions & Hardware Materials',
            interior: 'Interior Designs and Production & Funiture',
            florists: 'Florists',
            food: 'Food & Beverage',
            printing: 'Printing & Publication',
            commercial: 'Commercial & Banking & Financial service',
            education: 'Education, Officials / NGO',
            pet: 'Pet Shop',
            textile: 'Textile & Clothing',
            event:
              'Event coordinate; Advertising & Production House & Exhibition',
            ecommerce: 'E-commerce & E-business',
            health: 'Health & Health Care',
            electronics: 'Electronics',
            retails: 'Retails, Consumables & Sport Goods',
            trading: 'Trading Companies',
            logistics: 'Logistics',
            automobile: 'Automobile / Parts',
            entertainment: 'Entertainment',
          },
        },
        selectCompanySize: {
          selection: {
            title: 'Company size',
            from1to10: '1-10',
            from11to30: '11-30',
            from30up: '30+',
          },
          placeHolder: 'Select company size',
        },
        companyWeb: {
          title: 'Company website (Optional)',
          textfield: {
            placeHolder: 'Company website',
          },
        },
        neededService: {
          textfield: {
            title: 'Which service do you need?',
          },
          selection: {
            placeHolder: 'Select...',
            vantruck: 'Van & Truck',
            vantruckMover: 'Van & Truck + Mover',
            courier: 'Courier',
            all: 'All',
            others: 'Others',
          },
        },
        button: {
          getStarted: 'Get started',
        },
      },
      error: {
        empty: {
          textfield: 'Please enter a company name',
          selection: 'Please select an option',
        },
      },
    },
  },
  ggb: {
    login: {
      error: {
        expired: {
          message:
            "The activation link has been expired. Login if you've already activated, or ",
          signup: 'Sign up a new account.',
        },
      },
      noLogin: "Don't have a login? ",
      signup: 'Sign up GOGO Business for free',
    },
  },
}
