import styled from "styled-components"
import Link from "react-router/lib/Link"

import IconDollar from "./assets/dollar.svg"
import { COLOR } from "../../component-library/design/color/color.constants"
import { SPACING } from "../../component-library/design/spacing/spacing.constants"
import { FONT_SIZE } from "../../component-library/design/typography/typography.constants"
import { HEADER_HEIGHT } from "../../global/header/header.constants"
import { TextLink } from "../../component-library/design/typography/typography.styles"
import HamburgerIcon from "./assets/hamburger.svg"
import GogoBusinessLogo from "./assets/gogobusiness-logo.svg"
import GogoBusinessProLogo from "./assets/gogobusinesspro-logo.svg"

const HeaderWrapper = styled.header`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 10;
  width: 100%;
  padding: 0;
  height: ${HEADER_HEIGHT};
  border-bottom: solid 1px ${COLOR.GREY_LIGHT};
  background-color: ${COLOR.WHITE};
  padding-right: ${SPACING.M};
`

const LogoLinkStyles = `
  display: block;
  height: 20px;
  margin-right: auto;
  margin-left: ${SPACING.M};
`

const StyledGogoBusinessLogoLink = styled(Link)`
  ${LogoLinkStyles}
`

const StyledGogoBusinessLogo = styled(GogoBusinessLogo)`
  height: 100%;
`

const StyledGogoBusinessProLogo = styled(GogoBusinessProLogo)`
  height: 100%;
`

const AddCreditButtons = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${SPACING.XL};
`

const StyledIconDollar = styled(IconDollar)`
  margin-right: ${SPACING.XS};
`

const StyledHamburgerIcon = styled(HamburgerIcon)`
  margin-left: ${SPACING.M};
  cursor: pointer;
`

const LoginLink = styled(TextLink)`
  font-size: ${FONT_SIZE.LARGE};
`

export {
  HeaderWrapper,
  StyledGogoBusinessLogoLink,
  StyledGogoBusinessLogo,
  StyledGogoBusinessProLogo,
  AddCreditButtons,
  StyledIconDollar,
  StyledHamburgerIcon,
  LoginLink,
}
