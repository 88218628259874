/**
 * @typedef typography_constants
 * @example
 *
 * Spacing Constants
 * XXS: '4px'  XS:   '8px' S:   '16px' M:   '24px' L:   '32px'
 * XL: '40px'  XL2: '48px' XL3: '56px' XL4: '64px' XL5: '128px'
 */
const SPACING = {
  NONE: '0',
  XXS: '4px',
  XS: '8px',
  S: '16px',
  M: '24px',
  L: '32px',
  XL: '40px',
  XL2: '48px',
  XL3: '56px',
  XL4: '64px',
  XL5: '128px',
}

export { SPACING }
