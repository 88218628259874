import Route from 'react-router/lib/Route'
import { loadableCreator } from 'ggx-global/loading/loadable-creator'
import Actions from 'ggx-src/legacy/actions/session'
import { featureFlagService } from 'ggx-service/feature-flag/feature-flag'

const LoadableAddressBook = loadableCreator({
  dynamicImport: () =>
    import(/* webpackChunkName: 'users' */ './address-book.component'),
  componentName: 'AddressBook',
})

const prepaidRoutesHandler = store => (_nextState, replace, callback) => {
  const { dispatch } = store
  const checkFlag = () => {
    const state = store.getState()
    const { featureFlagEnableAddressBook } = state

    if (!featureFlagEnableAddressBook) {
      replace('/404')
    }
    callback()
  }

  Promise.all([
    dispatch(Actions.currentUser()),
    featureFlagService.triggerFeatureFlag('enable_address_book'),
  ])
    .then(checkFlag)
    .catch(checkFlag)
}

const AddressBookRoutes = store => (
  <Route
    path="address-book"
    component={LoadableAddressBook}
    onEnter={prepaidRoutesHandler(store)}
  />
)

export { AddressBookRoutes }
