import Route from 'react-router/lib/Route'
import IndexRoute from 'react-router/lib/IndexRoute'

import { loadableCreator } from '../global/loading/loadable-creator'
import { getFeatureFlag } from '../service/feature-flag/selectors'
import { featureFlagService } from '../service/feature-flag/feature-flag'
import { setSubscriptionInfo } from './duck/actions'
import { getIsSubscribed, getIsFreeTrial } from './duck/selectors'
import { api } from '../legacy/utils/api'
import Actions from '../legacy/actions/session'

const LoadablePaidSubscriptionPlan = loadableCreator({
  dynamicImport: () =>
    import(
      /* webpackChunkName: 'paid-subscription-plan' */ './paid-subscription-plan.component'
    ),
  componentName: 'PaidSubscriptionPlan',
})

const LoadableSubscriptionManagement = loadableCreator({
  dynamicImport: () =>
    import(
      /* webpackChunkName: 'subscription-management' */ './subscription-management/subscription-management.component'
    ),
  componentName: 'SubscriptionManagement',
})

const redirectToRoot = store => async (_nextState, replace, callback) => {
  const checkFlag = () => {
    const state = store.getState()
    const shouldRedirect =
      getFeatureFlag({
        state,
        featureFlag: 'paid_subscription_landing_page',
      }) === false

    if (shouldRedirect) {
      replace('/')
    }

    callback()
  }

  const isUserLoggedIn = api.loggedIn()
  if (isUserLoggedIn) {
    const { dispatch } = store
    dispatch(Actions.currentUser())
    await dispatch(setSubscriptionInfo())
  } else {
    featureFlagService?.setIdentifier()
  }

  featureFlagService
    .triggerFeatureFlag('paid_subscription_landing_page')
    .then(checkFlag)
    .catch(checkFlag)
}

const redirectForPro = store => (_nextState, replace, callback) => {
  const checkSubscribed = () => {
    const state = store.getState()
    const shouldRedirect = getIsSubscribed(state) && !getIsFreeTrial(state)

    if (shouldRedirect) {
      replace('/pro/management')
    }

    callback()
  }

  checkSubscribed()
}

const redirectForManagement = store => (_nextState, replace, callback) => {
  const checkLoggedInAndSubscribed = () => {
    const state = store.getState()

    if (!api.loggedIn()) {
      const pathname = _nextState.location.pathname.substring(1)
      replace(`/login?redirectTo=${pathname}`)
    } else if (!getIsSubscribed(state) || getIsFreeTrial(state)) {
      replace('/pro')
    }

    callback()
  }

  checkLoggedInAndSubscribed()
}

const PaidSubscriptionPlanRoutes = store => (
  <Route path="pro" onEnter={redirectToRoot(store)}>
    <IndexRoute
      component={LoadablePaidSubscriptionPlan}
      onEnter={redirectForPro(store)}
    />
    <Route
      path="management"
      component={LoadableSubscriptionManagement}
      onEnter={redirectForManagement(store)}
    />
  </Route>
)

export { PaidSubscriptionPlanRoutes }
