import { useSubscriptionNotification } from '../../paid-subscription-plan/faye/use-subscription-notification'
import { useLegacyNotification } from '../../legacy/faye/use-legacy-notification'

const FayeSubscription = () => {
  useLegacyNotification()
  useSubscriptionNotification()

  return null
}

export { FayeSubscription }
