import { useTranslation } from 'react-i18next'

import {
  DriverProfilePicture,
  DriverProfile,
} from './favorite-drivers.component.styles'
import {
  Body,
  TextLink,
} from '../../component-library/design/typography/typography.styles'
import { VEHICLE_TRANSLATIONS } from '../../service/vehicle/vehicle'

const useFavoriteDriversColumns = handleModalOpen => {
  const [t] = useTranslation()

  const favoriteDriversTableColumns = [
    {
      key: 'index',
      width: '60px',
    },
    {
      key: 'name',
      headerLabel: t('myDrivers.favList.name'),
      width: '200px',
      cellComponent: ({ rowData, value }) => (
        <DriverProfile>
          {rowData?.profile_image_url && (
            <DriverProfilePicture src={rowData.profile_image_url} />
          )}
          <span>{value}</span>
        </DriverProfile>
      ),
    },
    {
      key: 'vehicle_type',
      headerLabel: t('myDrivers.favList.vehicleType'),
      cellComponent: ({ rowData }) => (
        <Body>{t(VEHICLE_TRANSLATIONS[rowData.vehicle_type])}</Body>
      ),
    },
    {
      key: 'remove',
      cellComponent: ({ rowData }) => (
        <TextLink onClick={() => handleModalOpen(rowData.id)}>
          {t('myDrivers.favList.button.remove')}
        </TextLink>
      ),
      width: '200px',
    },
  ]

  return favoriteDriversTableColumns
}

export { useFavoriteDriversColumns }
