import styled from "styled-components"

import { Dropdown } from "ggx-componentlibrary/components/dropdown/dropdown.component"
import { SPACING } from "ggx-componentlibrary/design/spacing/spacing.constants"

const StyledDropdown = styled(Dropdown)`
  width: 168px;
  margin-right: ${SPACING.S};
`

export { StyledDropdown }
