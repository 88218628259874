import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import noop from 'lodash/noop'

import { apiService } from 'ggx-src/billing/api/api.service'
import NoticeActions from 'ggx-src/legacy/actions/notice'
import { formatCurrency } from 'ggx-service/i18n/format-currency.service'
import { SPACING } from 'ggx-componentlibrary/design/spacing/spacing.constants'
import { COLOR } from 'ggx-componentlibrary/design/color/color.constants'
import {
  SmallText,
  TextLink,
} from 'ggx-componentlibrary/design/typography/typography.styles'
import { Modal } from 'ggx-componentlibrary/components/modal/modal.component'
import { Button } from 'ggx-componentlibrary/components/button/button.component'
import { LoadingSpinner } from 'ggx-src/legacy/views/helpers/loading/spinner'
import {
  Row,
  QRCodeContainer,
  QRCode,
  PayNowLogo,
  StepList,
  Step,
  StyledExternalLinkIcon,
} from './pay-now-section.component.styles'

const QR_CODE_STEP = {
  LOADING: 'LOADING',
  READY: 'READY',
  VERIFYING: 'VERIFYING',
}

const QRCodeModal = ({ selectedPackage, step, onCancel }) => {
  const [t] = useTranslation()
  const [qrCodeUrl, setQRCodeUrl] = useState(null)
  const [qrCodeStep, setQRCodeStep] = useState(QR_CODE_STEP.LOADING)
  const dispatch = useDispatch()

  useEffect(() => {
    let alive = true
    ;(async () => {
      try {
        const url = await apiService.getPayNowQRCodeURL(selectedPackage.id)
        if (alive) setQRCodeUrl(url)
      } catch (error) {
        dispatch(NoticeActions.setErrorMessage(t('errors.generic')))
        onCancel && onCancel()
      }
    })()
    return () => (alive = false)
  }, [])

  useEffect(() => {
    if (step > 2) setQRCodeStep(QR_CODE_STEP.VERIFYING)
  }, [step])

  return (
    <Modal
      height="379px"
      header={t('top_up_with_paynow__sum__header', {
        amount: formatCurrency(selectedPackage),
      })}
      footer={
        <Button
          buttonType="secondary"
          size="small"
          onClick={onCancel}
          disabled={qrCodeStep === QR_CODE_STEP.VERIFYING}
        >
          {t('common__cancel')}
        </Button>
      }
      onRequestClose={qrCodeStep !== QR_CODE_STEP.VERIFYING ? onCancel : noop}
      shouldCloseOnEsc={qrCodeStep !== QR_CODE_STEP.VERIFYING}
      showCloseButton={qrCodeStep !== QR_CODE_STEP.VERIFYING}
    >
      <Row>
        <QRCodeContainer>
          <QRCode>
            {qrCodeStep === QR_CODE_STEP.LOADING && (
              <>
                <LoadingSpinner
                  style={{
                    width: SPACING.L,
                    height: SPACING.L,
                  }}
                />
                <SmallText style={{ marginBottom: 0, marginTop: SPACING.S }}>
                  {t('button__please_wait')}
                </SmallText>
              </>
            )}
            <img
              style={{
                display: qrCodeStep !== QR_CODE_STEP.READY && 'none',
              }}
              src={qrCodeUrl}
              onLoad={() => setQRCodeStep(QR_CODE_STEP.READY)}
            />
            {qrCodeStep === QR_CODE_STEP.VERIFYING && (
              <>
                <LoadingSpinner
                  style={{
                    width: SPACING.L,
                    height: SPACING.L,
                  }}
                />
                <SmallText style={{ marginBottom: 0, marginTop: SPACING.S }}>
                  {t('common__verifying_payment')}
                </SmallText>
              </>
            )}
          </QRCode>
          <PayNowLogo />
        </QRCodeContainer>
        <StepList>
          <div
            style={{ opacity: qrCodeStep === QR_CODE_STEP.VERIFYING && 0.4 }}
          >
            <Step>
              {t('paynow_top_up_instruction_step_1__sum__desc')}
              <span style={{ display: 'inline-block', marginLeft: SPACING.S }}>
                <SmallText noMargin as="span">
                  {t('paynow_top_up_instruction_step_1__we_support__sum__desc')}
                </SmallText>
                <SmallText
                  href="https://abs.org.sg/consumer-banking/pay-now#:~:text=PAYNOW%20IS%20OFFERED%20BY%20THESE%20PARTICIPANTS"
                  target="_blank"
                  as={TextLink}
                  style={{ color: COLOR.BLUE_MEDIUM }}
                >
                  {t('paynow_top_up_instruction_step_1__other_apps__sum__desc')}
                  <StyledExternalLinkIcon />
                </SmallText>
              </span>
            </Step>
            <Step>{t('paynow_top_up_instruction_step_2__sum__desc')}</Step>
            <Step>{t('paynow_top_up_instruction_step_3__sum__desc')}</Step>
            <Step>{t('paynow_top_up_instruction_step_4__sum__desc')}</Step>
          </div>
          <Step>{t('paynow_top_up_instruction_step_5__sum__desc')}</Step>
        </StepList>
      </Row>
    </Modal>
  )
}

QRCodeModal.propTypes = {
  selectedPackage: PropTypes.shape({
    id: PropTypes.string,
    amount: PropTypes.string,
  }),
  step: PropTypes.number,
  onCancel: PropTypes.func,
}

export { QRCodeModal }
