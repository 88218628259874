const keySuffix = 'temp_profile_photo'
const store = window.localStorage

function key(owner) {
  return `${owner}_${keySuffix}`
}

function setTempProfilePhoto(owner, src) {
  store.setItem(key(owner), src)
}

function getTempProfilePhoto(owner) {
  return store.getItem(key(owner))
}

function removeTempProfilePhoto(owner) {
  store.removeItem(key(owner))
}

export { setTempProfilePhoto, getTempProfilePhoto, removeTempProfilePhoto }
