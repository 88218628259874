import styled from "styled-components"

import DriverPhotoPlaceHolderSVG from "../assets/driver-photo-placeholder.svg"
import EmptyHeartSVG from "../assets/empty-heart.svg"
import FilledHeartSVG from "../assets/filled-heart.svg"
import { SPACING } from "../../../component-library/design/spacing/spacing.constants"
import { COLOR } from "../../../component-library/design/color/color.constants"
import { BodyBold } from "../../../component-library/design/typography/typography.styles"

const PHOTO_STYLES = `
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: ${SPACING.S};
`

const ProfilePicture = styled.img`
  ${PHOTO_STYLES}
`

const DriverPhotoPlaceHolder = styled(DriverPhotoPlaceHolderSVG)`
  ${PHOTO_STYLES}
`

const Container = styled.div`
  display: flex;
  padding: ${SPACING.M} 0px;
  margin-bottom: ${SPACING.M};
  border-bottom: 1px solid ${COLOR.GREY_LIGHT};
`

const DriverDetails = styled(BodyBold)`
  margin: 0;
`

const DriverInformation = styled.div`
  position: relative;
  top: 4px;
  flex-grow: 1;
`

const EmptyHeart = styled(EmptyHeartSVG)`
  align-self: center;
  cursor: pointer;
`

const FilledHeart = styled(FilledHeartSVG)`
  align-self: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
`

export {
  Container,
  DriverInformation,
  ProfilePicture,
  DriverDetails,
  DriverPhotoPlaceHolder,
  EmptyHeart,
  FilledHeart,
}
