import PropTypes from 'prop-types'

import { LoadingSpinner } from '../../legacy/views/helpers/loading/spinner'
import { LoadingContainer } from './loading.component.styles'

const Loading = ({ pastDelay, error, timedOut }) => {
  if (pastDelay || error || timedOut) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
      </LoadingContainer>
    )
  }

  return null
}

Loading.propTypes = {
  pastDelay: PropTypes.bool,
  error: PropTypes.bool,
  timedOut: PropTypes.bool,
}

export { Loading }
