import styled from "styled-components"

import { COLOR } from "ggx-componentlibrary/design/color/color.constants"
import { SPACING } from "ggx-componentlibrary/design/spacing/spacing.constants"

const StyledContentWrapper = styled.div`
  width: 100%;
  min-height: ${SPACING.L};
  padding: ${SPACING.S};
`

const StyledWrapper = styled.a`
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: ${SPACING.S};
  background-color: ${(props) =>
    props.showContent ? `${COLOR.GREY_LIGHTEST}` : "transparent"};
  > svg {
    overflow: visible;
  }
`

export { StyledContentWrapper, StyledWrapper }
