import styled from "styled-components"

import { SPACING } from "../../../component-library/design/spacing/spacing.constants"
import { COLOR } from "../../../component-library/design/color/color.constants"
import { Body } from "../../../component-library/design/typography/typography.styles"
import {
  iconStyles,
  PickupPinIcon,
  DropoffPinIcon,
} from "../../../component-library/components/waypoint/waypoint.styles"
import morePoints from '../assets/more-points.svg'

const MorePointsIcon = styled(morePoints)`
  ${iconStyles}
`

const WaypointContainer = styled.div`
  display: flex;
  margin: 0 8px 0;
  margin-bottom: 10px;
  ${PickupPinIcon} {
    background-color: transparent;
  }
  ${DropoffPinIcon} {
    background-color: transparent;
  }
  ${MorePointsIcon} {
    background-color: transparent;
  }
  &:last-child {
    margin-bottom: 0;
  }
`

const WaypointInformation = styled.div`
  display: flex;
  flex-direction: column;
`


export { WaypointContainer, WaypointInformation, MorePointsIcon }
