import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { getLocale } from '../../legacy/utils/locale'
import * as debug from '../../legacy/utils/debug'

import enHK from './translations/en-HK.json'
import zhHK from './translations/zh-HK.json'
import enSG from './translations/en-SG.json'
import zhTW from './translations/zh-TW.json'
import viVN from './translations/vi-VN.json'

const debugTranslations =
  ENV.ENABLE_DEBUG && JSON.parse(debug.getDebugTranslations())

const resources = {
  'en-HK': {
    translation: enHK,
  },
  'zh-HK': {
    translation: zhHK,
  },
  'en-SG': {
    translation: enSG,
  },
  'zh-TW': {
    translation: zhTW,
  },
  'vi-VN': {
    translation: viVN,
  },
}

i18n.use(initReactI18next).init({
  resources,
  // Using a non-existent language option 'debug' allows keys to be shown instead
  lng: debugTranslations ? 'debug' : getLocale(),
  interpolation: {
    escapeValue: false,
  },
  debug: ENV.ENABLE_DEBUG,
})
