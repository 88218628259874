import styled from "styled-components"

import {
    HeadingXL,
    HeadingL,
    Heading,
  } from 'ggx-componentlibrary/design/typography/typography.styles'
import { SPACING } from "ggx-componentlibrary/design/spacing/spacing.constants"

import Color from '../legacy/views/helpers/color'

const FlexContainer = styled.div`
  display: flex;
  height: 100vh;
`

const FlexContentLeft = styled.div`
  display: flex;
  flex: 1 0 50%;
  background: ${Color.LOCHMARA_BLUE};
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const FlexContentRight = styled.div`
  display: flex;
  flex: 1 0 50%;
  background: ${Color.LOCHMARA_BLUE};
  justify-content: center;
  align-items: center;
`

const StyledHeadingXL = styled(HeadingXL)`
  color: ${Color.SUPERNOVA_YELLOW};
  font-weight: normal;
`

const StyledHeadingL = styled(HeadingL)`
  color: ${Color.WHITE};
  font-weight: normal;
`

const StyledHeading = styled(Heading)`
  color: ${Color.WHITE};
  font-weight: normal;
  margin-bottom: ${SPACING.L}
`

export { FlexContainer, FlexContentLeft, FlexContentRight, StyledHeadingXL, StyledHeadingL, StyledHeading }