const isMasterUser = state =>
  state?.session?.currentUser?.level === 'b2b_master_user'

const isBranchUser = state =>
  state?.session?.currentUser?.level === 'b2b_branch_user'

const isSubUser = state => state?.session?.currentUser?.level === 'b2b_sub_user'

const isAdminUser = state =>
  state?.session?.currentUser?.level === 'b2b_admin_user'

const getOrganizationId = state => state?.organization?.organization?.id

const getOrganizationName = state => state?.organization?.organization?.name

const getBranches = state => state.organization && state.organization.branches

const getBranchId = state => state.session?.currentUser?.branch_id

const getCurrentUserLevel = state => state?.session?.currentUser?.level || ''

const getAccountName = state => state?.session?.currentUser?.name

const getAccountEmail = state => state?.session?.currentUser?.email

const getAccountId = state => state?.session?.currentUser?.id

const getSessionToken = state => state?.session?.currentUser?.session_token

const getPreviousPage = state =>
  state?.history[state?.history.length - 2]?.pathname

const getCurrentUser = state => state?.session?.currentUser

export {
  isMasterUser,
  isBranchUser,
  isSubUser,
  isAdminUser,
  getOrganizationId,
  getBranches,
  getBranchId,
  getCurrentUserLevel,
  getOrganizationName,
  getAccountName,
  getAccountEmail,
  getAccountId,
  getSessionToken,
  getPreviousPage,
  getCurrentUser,
}
