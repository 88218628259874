import styled from "styled-components"

import { Body } from "../../../component-library/design/typography/typography.styles"
import { FONT_SIZE } from "../../../component-library/design/typography/typography.constants"
import { COLOR } from "../../../component-library/design/color/color.constants"

const orderStatusBg = {
  arrivedAt: COLOR.TEAL_MEDIUM,
  cannotFindDriver: COLOR.YELLOW_MEDIUM,
  driverPoolFallback: COLOR.YELLOW_MEDIUM,
  cancelled: COLOR.RED_MEDIUM,
  created: COLOR.TEAL_MEDIUM,
  completed: COLOR.BLUE_DARK,
  expired: COLOR.RED_MEDIUM,
  failedToDeliver: COLOR.RED_MEDIUM,
  pickedUp: COLOR.TEAL_MEDIUM,
  released: COLOR.RED_MEDIUM,
}

const BasicBtn = styled.button`
  outline: none;
  border: 0;
  background: transparent;
  font-size: ${FONT_SIZE.SMALL};
`

const NotificationLargeText = styled(Body)`
  font-size: ${FONT_SIZE.LARGE};
`

const Avatar = styled.img`
  height: 2.5rem;
  width: 2.5rem;
  paddingtop: 1.357em;
`

const StatusWrapper = styled.div`
  display: flex;
  flex: 0 0 2rem;
  justify-content: center;
  padding-top: 2rem;
`

const StatusIndicator = styled.div`
  border-radius: 50%;
  width: 0.571em;
  height: 0.571em;
  background-color: ${({ status }) => orderStatusBg[status]};
`

const MessageWrapper = styled.li`
  display: flex;
  border-bottom: 0.036em solid rgba(0, 0, 0, 0.4);
`

const MessageContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 2rem;
`

const MessageBody = styled.p`
  margin: 0;
  flex: 1 0 50%;
`

const MessageContentAbove = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 33%;
  padding-top: 1.5rem;
  align-items: center;
`

const MessageContentBelow = styled.div`
  display: flex;
  flex: 1 1 33%;
  padding-bottom: 0.5rem;
  justify-content: space-between;
`

const MessageContentBelowLeft = styled.div`
  display: flex;
`

const CancelBtnContainer = styled.div`
  flex: 1 0 100%;
  text-align: start;

  & > button {
    margin: 0;
  }
`
const CancelOrderBtn = styled.button`
  border-radius: 0.2rem;
  padding: 0.2rem;
  border: 1px solid ${COLOR.BLUE_MEDIUM};
  color: ${COLOR.BLUE_MEDIUM};
  background: ${COLOR.WHITE};
  font-size: ${FONT_SIZE.SMALL};

  &:hover {
    background: ${COLOR.GREY_LIGHTER};
  }
`

const StyledDate = styled.p`
  font-size: ${FONT_SIZE.SMALL};
  padding-right: 0.25rem;
`
const StyledRequestId = styled(BasicBtn)`
  color: ${COLOR.BLUE_MEDIUM};

  &:hover {
    color: ${COLOR.BLUE_DARK};
  }
`

const DismissBtn = styled(BasicBtn)`
  color: ${COLOR.GREY_DARKER};

  &:hover {
    color: ${COLOR.BLUE_DARK};
  }

  & > span {
    padding-left: 0.5em;
    color: ${COLOR.RED_MEDIUM};
  }
`

export {
  NotificationLargeText,
  Avatar,
  StatusWrapper,
  StatusIndicator,
  MessageContentWrapper,
  MessageWrapper,
  MessageBody,
  MessageContentAbove,
  MessageContentBelow,
  MessageContentBelowLeft,
  CancelBtnContainer,
  CancelOrderBtn,
  StyledDate,
  StyledRequestId,
  DismissBtn,
}
