import styled from "styled-components"

import { SPACING } from "../../component-library/design/spacing/spacing.constants"
import { TextField } from "../../component-library/components/text-field/text-field.component"
import { Button } from "../../component-library/components/button/button.component"

const OrderSearchContainer = styled.div`
  display: flex;
`

const StyledTextField = styled(TextField)`
  width: 280px;
  margin-right: ${SPACING.XS};

  & input {
    padding-right: ${SPACING.S};
  }
`

const StyledButton = styled(Button)`
  line-height: 5px;
`

export { OrderSearchContainer, StyledTextField, StyledButton }
