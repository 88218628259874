import _ from 'lodash'
import { push } from 'react-router-redux'
import i18next from 'i18next'

import Constants from '../constants/action'
import { api } from '../utils/api'
import { clearAuthToken } from '../views/helpers'
import { getLocale } from '../utils/locale'
import { apiLanguageCode } from '../locales/conversions'
import { lastLoginKey, track } from '../../service/mixpanel/mixpanel'
import translate from '../locales/translate'
import OrganizationActions from './organizations'
import UserActions from './users'
import { featureFlagService } from '../../service/feature-flag/feature-flag'
import { initializeMixpanel } from '../../service/mixpanel/setup'
import { isB2BUser } from '../views/helpers/auth/auth'
import { brazeClient, BRAZE_EVENT } from 'ggx-service/braze'
import { getCountry } from '../../service/locale/country.service'
import {
  loadSerializedFileToURL,
  removeSerializedFile,
} from '../../profile/utils'
import {
  setProfilePhotoDataURLWithRevokeOldURL,
  setCompanyProfilePhotoDataURLWithRevokeOldURL,
} from '../../profile/duck/actions'

function _initMixpanel(user) {
  const localLoginKey = lastLoginKey(user.id)
  initializeMixpanel(user)
  localStorage.setItem(localLoginKey, Date.now())
}

export function setCurrentUser(dispatch, user) {
  dispatch({
    type: Constants.CURRENT_USER,
    currentUser: user,
  })

  dispatch(OrganizationActions.getOrganization(user))
}

export function unauthorizedSession() {
  return dispatch => {
    dispatch({ type: Constants.SESSION_UNAUTHORIZED })
    setTimeout(() => {
      dispatch({ type: Constants.USER_LOGGED_OUT })
      dispatch(push('/login'))
    }, 4000)
  }
}

export function sessionSwitch() {
  return dispatch => {
    dispatch({ type: Constants.SESSION_SWITCH, payload: true })
    setTimeout(() => {
      dispatch({ type: Constants.SESSION_SWITCH, payload: false })
      window.location.reload()
    }, 4000)
  }
}

export const resetSession = () => ({
  type: Constants.SESSION_RESET,
})

const processAfterLogout = (userId, dispatch, getState) => {
  try {
    const { profilePhotoDataUrl, companyProfilePhotoDataUrl } = getState()
    if (profilePhotoDataUrl) URL.revokeObjectURL(profilePhotoDataUrl)
    if (companyProfilePhotoDataUrl)
      URL.revokeObjectURL(companyProfilePhotoDataUrl)

    dispatch({ type: Constants.USER_LOGGED_OUT })

    featureFlagService?.setIdentifier()

    // mixpanel
    const lastLogin = localStorage.getItem(lastLoginKey(userId))
    const loginSeconds = (Date.now() - lastLogin) / 1000
    track('Logout', { LoginLength: loginSeconds })
    window.location = '/login'
  } catch (e) {
    console.log(error)
  }
}

const Actions = {
  login: (uniqueIdentifier, password, rememberMe) => dispatch => {
    dispatch({ type: Constants.SESSIONS_LOADING })

    return api
      .login(uniqueIdentifier, password, rememberMe, dispatch)
      .then(res => {
        const user = res.data.user

        if (!isB2BUser(user)) {
          clearAuthToken()
          const b2cUrl = i18next.t('b2clink.login') || 'https://gogox.com'
          window.location.href = b2cUrl
          return
        }

        // set feature flag with an organization on login
        featureFlagService?.setIdentifier(user)

        setCurrentUser(dispatch, user)
        const redirectionRoute = window.location.href.split('redirectTo=')[1]
        dispatch(
          push(redirectionRoute ? { pathname: `/${redirectionRoute}` } : '/')
        )

        // mixpanel
        const localLoginKey = lastLoginKey(user.id)
        const lastLogin = localStorage.getItem(localLoginKey) || ''

        _initMixpanel(user)

        track('Login', {
          RememberMe: rememberMe,
          LastLogin: lastLogin,
        })

        localStorage.setItem(localLoginKey, Date.now())

        // Setup user language if not already defined
        const userLanguage = _.get(user, 'meta.language')
        if (!userLanguage) {
          const locale = getLocale()
          const language = apiLanguageCode(locale)
          dispatch(UserActions.updateUserLanguage(language))
        }

        brazeClient.setUser({
          id: user.id,
          name: user.name,
          email: user.email,
          phoneNumber: user.meta.phone_number,
          country: getCountry(),
          language: getLocale(),
          accountLevel: user.level,
          organizationName: user.organization.name,
          paymentMethod: user.organization.payment_method,
        })

        brazeClient.setCustomEvent(BRAZE_EVENT.AUTH.LOGIN)
      })
      .catch(error => {
        const status = _.get(error, 'response.status')
        if (status === 403) {
          dispatch({
            type: Constants.SESSION_FORBIDDEN,
          })
        } else {
          dispatch({
            type: Constants.SESSIONS_ERROR,
            error: translate('validations.errors.invalidUsernameOrPassword'),
          })
        }
      })
  },

  logout: userId => async (dispatch, getState) => {
    try {
      await api.logout()
      processAfterLogout(userId, dispatch, getState)
    } catch (error) {
      error => console.log(error)
    }
  },

  logoutByPasswordChanged: userId => (dispatch, getState) =>
    Actions.processAfterLogout(userId, dispatch, getState),

  callUserProfile: _.memoize(
    dispatch => api.request('get', '/users/profile', dispatch),
    () => Math.floor(Date.now() / 1000)
  ),
  currentUser: () => dispatch => {
    return Actions.callUserProfile(dispatch)
      .then(res => {
        const user = res.data.user
        if (user.suspended) {
          throw new Error('User is suspended')
        }
        setCurrentUser(dispatch, user)

        // set feature flag with an organization when reload page when already logged in
        featureFlagService?.setIdentifier(user)

        _initMixpanel(user)

        try {
          // check and show the backup profile image
          if (user.profile_photo?.status === 'pending') {
            const profileImageURL = loadSerializedFileToURL(
              'profile_temp_image'
            )
            dispatch(setProfilePhotoDataURLWithRevokeOldURL(profileImageURL))
          } else {
            removeSerializedFile('profile_temp_image')
          }

          // check and show the backup company image
          if (user.organization?.profile_photo?.status === 'pending') {
            const companyImageURL = loadSerializedFileToURL(
              'company_temp_image'
            )
            dispatch(
              setCompanyProfilePhotoDataURLWithRevokeOldURL(companyImageURL)
            )
          } else {
            removeSerializedFile('company_temp_image')
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error)
        }
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log(error)
        setTimeout(() => {
          clearAuthToken()
          dispatch(push('/login'))
        }, 4000)
      })
  },
}

export default Actions
