import styled from "styled-components"

const NotificationsContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

export {
  NotificationsContainer
}