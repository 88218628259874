import React, { Fragment } from 'react'
import { useTranslation, Trans } from 'react-i18next'

import {
  StyledBodyBold,
  StyledBody,
  InstructionWrapper,
  BankDetailsWrapper,
  ItemWrapper,
  ItemLabel,
  ContactDetailsWrapper,
} from './bank-transfer-section.component.styles'
import {
  TextLink,
  SmallText,
} from '../../../component-library/design/typography/typography.styles'

const BankTransferSectionVN = () => {
  const [t] = useTranslation()

  const email = t('email.bankTransfer')

  return (
    <Fragment>
      <StyledBodyBold>
        {t('billing.topup.modal.bankTransfer.title')}
      </StyledBodyBold>

      <InstructionWrapper>
        <StyledBody>{t('billing.topup.modal.bankTransfer.stepOne')}</StyledBody>
        <StyledBody>
          {t('billing.topup.modal.bankTransfer.preTransferInstructions')}
        </StyledBody>
      </InstructionWrapper>

      <BankDetailsWrapper>
        <ItemWrapper>
          <ItemLabel>
            {t('billing.topup.modal.bankTransfer.bankName')}
          </ItemLabel>
          <StyledBody>
            {t('billing.topup.modal.bankTransfer.bankNameValue')}
          </StyledBody>
        </ItemWrapper>

        <ItemWrapper>
          <ItemLabel>
            {t('billing.topup.modal.bankTransfer.beneficiary')}
          </ItemLabel>
          <StyledBody>
            {t('billing.topup.modal.bankTransfer.beneficiaryValue')}
          </StyledBody>
        </ItemWrapper>

        <ItemWrapper>
          <ItemLabel>
            {t('billing.topup.modal.bankTransfer.accountNumber')}
          </ItemLabel>
          <StyledBody>
            {t('billing.topup.modal.bankTransfer.accountNumberValue')}
          </StyledBody>
        </ItemWrapper>
      </BankDetailsWrapper>

      <InstructionWrapper>
        <StyledBody>{t('billing.topup.modal.bankTransfer.stepTwo')}</StyledBody>
        <StyledBody>
          <Trans i18nKey="billing.topup.modal.bankTransfer.postTransferInstructions">
            x<TextLink href={`mailto:${email}`}>{{ email }}</TextLink>
          </Trans>
        </StyledBody>
      </InstructionWrapper>

      <ContactDetailsWrapper>
        <StyledBody>
          {t('billing.topup.modal.bankTransfer.contactUs')}
        </StyledBody>
      </ContactDetailsWrapper>

      <SmallText>{t('billing.topup.modal.bankTransfer.disclaimer')}</SmallText>
    </Fragment>
  )
}

export { BankTransferSectionVN }
