import styled from "styled-components"

import { COLOR } from "ggx-componentlibrary/design/color/color.constants"
import { SPACING } from "ggx-componentlibrary/design/spacing/spacing.constants"
import { HEADER_HEIGHT } from "../header/header.constants"
import { BANNER_HEIGHT } from "./banner.constants"

const positionFixedStyles = `
  position: fixed;
  top: ${HEADER_HEIGHT};
  height: ${BANNER_HEIGHT};
`

const positionRelativeStyles = `
  position: relative;
  top: 0;
  height: auto;
`

const Wrapper = styled.div`
  background-color: ${COLOR.RED_MEDIUM};
  padding: ${SPACING.S};
  left: 0;
  right: 0;
  z-index: 9;
  ${(props) =>
    props.positionFixed ? positionFixedStyles : positionRelativeStyles};
`

export { Wrapper, BANNER_HEIGHT, positionFixedStyles, positionRelativeStyles }
