import styled from "styled-components"

import { Body, TextLink } from 'ggx-componentlibrary/design/typography/typography.styles'
import { SPACING } from 'ggx-componentlibrary/design/spacing/spacing.constants.js'

const TNCLink = styled(TextLink)`
    display: inline-flex;
    align-items: center;

    svg {
        margin-left: 6px;
        width: 16px;
        height: 16px;
    }
`

const ContentText = styled(Body)`
    margin-bottom: ${SPACING.S};
`

export {
    TNCLink,
    ContentText,
}