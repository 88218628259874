import { isEmpty, differenceBy } from 'lodash'
import i18next from 'i18next'

import { hideAddCreditCardModal } from 'ggx-global/duck/actions'
import { brazeClient, BRAZE_EVENT } from 'ggx-service/braze'
import { displayAlert } from 'ggx-componentlibrary/components/alerts/alerts.component'

import * as types from './types'
import { paymentService } from '../api/api.service'

function setSavedCardInfo({ id = null, brand = null, last4 = null }) {
  return {
    type: types.SET_SAVED_CARD_INFO,
    value: { id, brand, last4, hasBeenRetrieved: true },
  }
}

function setPaymentInfo(paymentInfo) {
  return {
    type: types.SET_PAYMENT_INFO,
    value: paymentInfo,
  }
}

function setCardInfo(card) {
  return {
    type: types.SET_CARD_INFO,
    value: card,
  }
}

function setCardsInfo(cards) {
  return {
    type: types.SET_CARDS_INFO,
    value: cards,
  }
}

function getSavedCard() {
  return async dispatch => {
    try {
      const result = await paymentService.getCard()
      dispatch(
        setSavedCardInfo({
          id: result.id,
          brand: result.brand,
          last4: result.last_four_digits,
        })
      )
    } catch (error) {
      dispatch(setSavedCardInfo({ id: null, brand: null, last4: null }))
    }
  }
}

function getPaymentInfo({
  checkPrimaryCardValidity = false,
  checkDuplicatedCards = false,
} = {}) {
  return async (dispatch, getState) => {
    try {
      const checkExpired = cardInfo => {
        const date = new Date()
        const year = date.getFullYear()
        const month = date.getMonth() + 1

        return (
          year > cardInfo.exp_year ||
          (year === cardInfo.exp_year && month > cardInfo.exp_month)
        )
      }

      const result = await paymentService.getPaymentInfo()
      let expiredPrimaryCard = {}
      let backupPrimaryCard = {}
      const stripe_cards_with_expiry = result.stripe_cards.map(card => {
        card.is_expired = checkExpired(card)
        card.nickname =
          card.nickname ||
          i18next.t('default_card_name', { card_type: card.brand })

        if (card.is_expired && card.is_default) {
          expiredPrimaryCard = card
        }

        if (!card.is_expired && isEmpty(backupPrimaryCard)) {
          backupPrimaryCard = card
        }

        return card
      })

      if (checkDuplicatedCards) {
        const {
          payment: { stripeCards },
        } = getState()
        const newCard = differenceBy(
          stripe_cards_with_expiry,
          stripeCards,
          'id'
        )[0]

        if (!isEmpty(newCard)) {
          // Align checking logic with CA https://github.com/gogovan/gogovan-client-v2-ios/blob/trunk/GoGoVanClient/RIBs/Payment/AddCreditCard/AddCreditCardInteractor.swift#L143-L152
          const hasDuplicatedCard = stripeCards.some(
            card =>
              card.last4 === newCard.last4 &&
              card.exp_year === newCard.exp_year &&
              card.exp_month === newCard.exp_month
          )

          if (hasDuplicatedCard) {
            displayAlert({
              message: i18next.t('feedback__duplicate_card'),
              type: 'error',
            })
          }
        }
      }

      dispatch(
        setPaymentInfo({
          availablePaymentMethods: result.available_payment_methods,
          stripeCards: stripe_cards_with_expiry,
        })
      )

      if (checkPrimaryCardValidity) {
        if (!isEmpty(expiredPrimaryCard) && !isEmpty(backupPrimaryCard)) {
          dispatch(
            updateCreditCardInfo({
              cardInfo: {
                stripe_card_id: backupPrimaryCard.id,
                is_primary: true,
              },
              errorCB: () => {
                console.warn('Primary card is expired but switch card failed')
              },
              successCB: () => {
                displayAlert({
                  message: i18next.t('feedback__primary_card_expired_updated'),
                })
              },
            })
          )
        }
      }

      return result
    } catch (error) {
      console.error(error)
    }
  }
}

function updateCreditCardInfo({ cardInfo, errorCB, successCB }) {
  return async dispatch => {
    try {
      const updatedCard = await paymentService.updateCard({
        nickname: cardInfo.nickname,
        stripe_card_id: cardInfo.stripe_card_id,
        is_primary: cardInfo.is_primary,
      })

      dispatch(setCardInfo(updatedCard))
      successCB && successCB()
    } catch (error) {
      errorCB && errorCB()
    }
  }
}

function handleAddCreditCardSuccess() {
  return async (dispatch, getState) => {
    const {
      modal: { creditCard: creditCardIsVisiable },
    } = getState()

    if (!creditCardIsVisiable) return

    try {
      brazeClient.setCustomEvent(BRAZE_EVENT.PAYMENT.ADD_CARDS)
      await dispatch(getPaymentInfo({ checkDuplicatedCards: true }))
      dispatch(hideAddCreditCardModal())
      displayAlert({ message: i18next.t('feedback__added_card') })
    } catch (error) {
      console.error(error)
    }
  }
}

export {
  getSavedCard,
  setSavedCardInfo,
  getPaymentInfo,
  updateCreditCardInfo,
  handleAddCreditCardSuccess,
}
