import React from 'react'
import PropTypes from 'prop-types'

import {
  StyledInput,
  StyledSmallText,
  StyledError,
  InputContainer,
  StyledClearIcon,
  Suffix,
  SuffixContent,
} from './text-field.component.styles'

const TextField = React.forwardRef(
  (
    {
      id,
      name,
      defaultValue,
      value,
      label,
      placeholder,
      helperText,
      type,
      disabled,
      onChange,
      className,
      meta = {},
      input = {},
      showClearIcon,
      onClearInput,
      showSuffix,
      showErrorWhenDirty,
      onFormikChange = null,
      inputProps,
      ...rest
    },
    ref
  ) => {
    const {
      name: reduxFormName,
      onBlur: reduxFormOnBlur,
      onChange: reduxFormOnChange,
      value: reduxFormValue,
    } = input

    const wrappedOnChange =
      typeof (reduxFormOnChange || onChange) === 'function'
        ? event => {
            ;(reduxFormOnChange || onChange)(event.target.value)
          }
        : null

    const error =
      ((showErrorWhenDirty && meta.dirty) || meta.touched) && meta.error

    const clearInput =
      typeof (reduxFormOnChange || onChange) === 'function'
        ? () => {
            ;(reduxFormOnChange || onChange)('')
          }
        : null

    const hasValue = !!(typeof reduxFormValue === 'string'
      ? reduxFormValue
      : value)

    const handleClearIconClick = () => {
      if (typeof onClearInput === 'function') {
        onClearInput()
      }
      clearInput()
    }

    return (
      <div className={className}>
        <InputContainer>
          {showClearIcon && hasValue && (
            <StyledClearIcon onClick={handleClearIconClick} />
          )}
          <StyledInput
            id={id}
            name={reduxFormName || name}
            defaultValue={defaultValue}
            value={typeof reduxFormValue === 'string' ? reduxFormValue : value}
            aria-label={
              helperText ? `${placeholder} ${helperText}` : placeholder
            }
            placeholder={placeholder}
            type={type}
            error={error}
            disabled={disabled}
            onChange={wrappedOnChange || onFormikChange}
            onBlur={reduxFormOnBlur || null}
            $showClearIcon={showClearIcon}
            $showSuffix={!!showSuffix}
            data-error-field={!!meta.error}
            data-testid={`${id || 'text_field'}_input`}
            ref={ref}
            {...inputProps}
            {...rest}
          />
          {showSuffix && !showClearIcon && (
            <Suffix>
              <SuffixContent>{showSuffix}</SuffixContent>
            </Suffix>
          )}
        </InputContainer>
        {!error && helperText && (
          <StyledSmallText aria-hidden>{helperText}</StyledSmallText>
        )}
        {error && <StyledError>{meta.error}</StyledError>}
      </div>
    )
  }
)

TextField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  meta: PropTypes.object,
  input: PropTypes.object,
  showClearIcon: PropTypes.bool,
  showSuffix: PropTypes.string,
  className: PropTypes.string,
  onClearInput: PropTypes.func,
  showErrorWhenDirty: PropTypes.bool,
  onFormikChange: PropTypes.func,
  inputProps: PropTypes.object,
}

TextField.displayName = 'TextField'

export { TextField, StyledSmallText }
