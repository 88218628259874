import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation, Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Modal } from 'ggx-componentlibrary/components/modal/modal.component'
import { Button } from 'ggx-componentlibrary/components/button/button.component'
import { LearnMoreText } from './free-trial-welcome-modal.component.styles'
import {
  Body,
  TextLink,
} from 'ggx-componentlibrary/design/typography/typography.styles'
import { getStartDate, getEndDate } from '../duck/selectors'
import { formatDateTime } from 'ggx-service/date-time/format-date-time'
import IconExternalLink from '../../assets/external_link.svg'

const FreeTrialWelcomeModal = ({ handleModalClose }) => {
  const [t] = useTranslation()
  const subscriptionStartDate = useSelector(state => getStartDate(state))
  const subscriptionEndDate = useSelector(state => getEndDate(state))
  const subscriptionPeriod =
    new Date(subscriptionEndDate).getMonth() -
    new Date(subscriptionStartDate).getMonth()

  return (
    <Modal
      header={t('modal__title__free_trial_announcement')}
      footer={<Button onClick={handleModalClose}>{t('button__got_it')}</Button>}
      onRequestClose={handleModalClose}
    >
      <Body>
        <Trans
          i18nKey="modal__description__free_trial_announcement"
          values={{
            value: subscriptionPeriod,
            startdate: formatDateTime(subscriptionStartDate, 'D MMM, YYYY'),
            enddate: formatDateTime(subscriptionEndDate, 'D MMM, YYYY'),
          }}
          components={[<b />]}
        />
      </Body>
      <LearnMoreText noMargin>
        <Trans
          i18nKey="modal__description__free_trial_announcement__learn_more"
          components={[<TextLink target="_blank" href="/pro" />]}
        />
        <TextLink target="_blank" href="/pro">
          <IconExternalLink />
        </TextLink>
      </LearnMoreText>
    </Modal>
  )
}

FreeTrialWelcomeModal.propTypes = {
  handleModalClose: PropTypes.func,
}

FreeTrialWelcomeModal.defaultProps = {
  handleModalClose: () => {},
}

export { FreeTrialWelcomeModal }
