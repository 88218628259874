import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Button } from '../../../component-library/components/button/button.component'
import { Modal } from '../../../component-library/components/modal/modal.component'
import { CancelMessage } from './order-cancel-modal.component.styles'

const OrderCancelModal = ({ handleCancelOrder, handleModalClose }) => {
  const [t] = useTranslation()
  const footer = (
    <>
      <Button onClick={handleModalClose} buttonType="secondary">
        {t('button__keep_searching')}
      </Button>
      <Button
        onClick={handleCancelOrder}
        buttonType="danger"
        data-cy="confirm-canel-btn"
      >
        {t('button__cancel_order')}
      </Button>
    </>
  )

  return (
    <Modal
      header={`${t('button__cancel_order')}?`}
      footer={footer}
      onRequestClose={handleModalClose}
      customStyles={{
        overlay: { zIndex: 1201 },
      }}
    >
      <CancelMessage>{t('modal__description__cancel_order')}</CancelMessage>
    </Modal>
  )
}

OrderCancelModal.propTypes = {
  handleCancelOrder: PropTypes.func,
  handleModalClose: PropTypes.func,
}

export { OrderCancelModal }
