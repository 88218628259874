import styled from "styled-components"
import { DateRange } from "react-date-range"

import { InputStyles } from "../../components/text-field/text-field.component.styles"
import { StyledButton } from "../../components/button/button.component.styles"
import { SPACING } from "../../design/spacing/spacing.constants"
import { COLOR } from "../../design/color/color.constants"
import { DEFAULT_FONT_STACK } from "../../design/typography/typography.constants"
import DropdownArrow from "./assets/dropdown_arrow.svg"

const InputWrapper = styled.div`
  position: relative;
  width: 193px;
  > * {
    cursor: pointer;
  }
`
const CalendarWrapper = styled.div`
  position: absolute;
  z-index: 1;
  border: solid 1px ${COLOR.GREY_LIGHT};
  ${DEFAULT_FONT_STACK};
`
const StyledInput = styled.input`
  ${InputStyles};
  width: 100%;
  caret-color: transparent;
  ::selection {
    background-color: transparent;
  }
`

const StyledDateRange = styled(DateRange)`
  width: 587px;
`
const Footer = styled.div`
  border-top: solid 1px ${COLOR.GREY_LIGHT};
  width: 587px;
  background-color: white;
  text-align: right;
  padding: ${SPACING.S} ${SPACING.M} ${SPACING.S} 0;
  ${StyledButton} {
    margin-right: ${SPACING.S};
    :last-of-type {
      margin-right: 0;
    }
  }
`

const StyledDropdownArrow = styled(DropdownArrow)`
  position: absolute;
  right: 0;
  height: 100%;
  margin-right: 10px;
`

export {
  StyledInput,
  StyledDateRange,
  CalendarWrapper,
  Footer,
  StyledDropdownArrow,
  InputWrapper,
}
