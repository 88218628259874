import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation, Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Body } from 'ggx-componentlibrary/design/typography/typography.styles'
import { Button } from 'ggx-componentlibrary/components/button/button.component'
import { Modal } from 'ggx-componentlibrary/components/modal/modal.component'
import { formatDateTime } from 'ggx-service/date-time/format-date-time'
import {
  getIsSubscribed,
  getEndDate,
  getState,
} from '../../../paid-subscription-plan/duck/selectors'
import { SUBSCRIPTION_STATE } from '../../../paid-subscription-plan/paid-subscription-plan.constants'

const RemoveCardModal = ({ onRequestClose, onConfirm, isLoading }) => {
  const [t] = useTranslation()
  const isSubscribed = useSelector(state => getIsSubscribed(state))
  const subscriptionEndDate = useSelector(state => getEndDate(state))
  const subscriptionState = useSelector(state => getState(state))

  const showAddCreditCardMessage =
    isSubscribed &&
    subscriptionState !== SUBSCRIPTION_STATE.CANCEL_AT_PERIOD_END

  return (
    <Modal
      onRequestClose={onRequestClose}
      header={
        showAddCreditCardMessage
          ? t('title__modal__remove_credit_card_linked_to_subscription')
          : t('title__modal__remove_credit_card')
      }
      footer={
        <>
          <Button buttonType="secondary" onClick={onRequestClose}>
            {t('common__cancel')}
          </Button>
          <Button buttonType="danger" onClick={onConfirm} isLoading={isLoading}>
            {t('button__remove_card')}
          </Button>
        </>
      }
      data-cy="RemoveCardModal"
    >
      <Body>
        {showAddCreditCardMessage ? (
          <Trans
            i18nKey="common__add_credit_card_before_subscription_expires"
            components={<strong />}
            values={{
              date: formatDateTime(subscriptionEndDate, 'D MMM, YYYY'),
            }}
          />
        ) : (
          t('popup.confirm.removeCard.content')
        )}
      </Body>
    </Modal>
  )
}

RemoveCardModal.propTypes = {
  onRequestClose: PropTypes.func,
  onConfirm: PropTypes.func,
  isLoading: PropTypes.bool,
}

export { RemoveCardModal }
