/* eslint-disable camelcase */
import * as types from './types'
import { subscriptionService } from '../api/api.service'

const setSubscriptionInfo = () => async dispatch => {
  try {
    const {
      state,
      subscription_status,
      end_date,
      start_date,
    } = await subscriptionService.getStatus()

    dispatch({
      type: types.SET_SUBSCRIPTION_INFO,
      value: { state, subscription_status, start_date, end_date },
    })
  } catch (error) {
    dispatch({ type: types.SET_SUBSCRIPTION_INFO, value: {} })
  }
}

export { setSubscriptionInfo }
