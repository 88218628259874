import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { BodyBold } from 'ggx-componentlibrary/design/typography/typography.styles'
import { Modal } from 'ggx-componentlibrary/components/modal/modal.component'
import { Button } from 'ggx-componentlibrary/components/button/button.component'
import { TextField } from 'ggx-componentlibrary/components/text-field/text-field.component'
import { Checkbox } from 'ggx-componentlibrary/components/checkbox/checkbox.component.js'
import { displayAlert } from 'ggx-componentlibrary/components/alerts/alerts.component'

import AmericanExpressSVG from 'ggx-src/billing/payment-method/payment-methods-section/assets/american_express.svg'
import MasterCardSVG from 'ggx-src/billing/payment-method/payment-methods-section/assets/mastercard.svg'
import UnionPaySVG from 'ggx-src/billing/payment-method/payment-methods-section/assets/unionpay.svg'
import VisaSVG from 'ggx-src/billing/payment-method/payment-methods-section/assets/visa.svg'
import UnknownSVG from 'ggx-src/billing/payment-method/payment-methods-section/assets/unknown.svg'

import { paymentService } from 'ggx-src/payment/api/api.service'
import {
  updateCreditCardInfo,
  getPaymentInfo,
} from 'ggx-src/payment/duck/actions'
import { getStripeCards } from 'ggx-src/payment/duck/selectors'

import { brazeClient, BRAZE_EVENT } from 'ggx-service/braze'

import {
  CardContainer,
  FieldWrapper,
  AlignCenterBody,
} from './credit-card.component.styles'

const EditCreditCardModal = ({
  id,
  brand,
  last4,
  nickname,
  is_default,
  is_expired,
  onModalClose,
}) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const [editableNickname, setEditableNickname] = useState(nickname)
  const [isPrimary, setIsPrimary] = useState(is_default)
  const enableSubmitButton =
    isPrimary !== is_default || editableNickname !== nickname
  const [isEdting, setIsEdting] = useState(true)
  const [isRemoving, setIsRemoving] = useState(false)
  const stripeCards = useSelector(state => getStripeCards(state) ?? [])

  const onRemoveCard = async () => {
    if (isRemoving) return
    setIsRemoving(true)
    try {
      const isOnlyOneCard = stripeCards.length === 1
      await paymentService.removeCard(id)
      await dispatch(getPaymentInfo())
      displayAlert({
        message:
          is_default && !isOnlyOneCard
            ? t('feedback__primary_card_removed')
            : t('feedback__removed_card'),
      })
      brazeClient.setCustomEvent(BRAZE_EVENT.PAYMENT.REMOVE_CARDS)
      onModalClose()
    } catch (error) {
      setIsRemoving(false)
      displayAlert({ message: t('error__cannot_remove_card'), type: 'error' })
    }
  }

  const onUpdateCard = () => {
    dispatch(
      updateCreditCardInfo({
        cardInfo: {
          nickname: editableNickname,
          stripe_card_id: id,
          is_primary: isPrimary,
        },
        errorCB: () => {
          displayAlert({ message: t('error.creditCard.save') })
        },
        successCB: () => {
          displayAlert({ message: t('feedback__card_details_saved') })
          onModalClose()
        },
      })
    )
  }

  return (
    <>
      <Modal
        height="initial"
        maxHeight="610px"
        header={
          isEdting ? t('edit_card') : t('title__modal__remove_credit_card')
        }
        footer={
          isEdting ? (
            <>
              <Button
                buttonType="danger"
                size="small"
                onClick={() =>
                  is_expired ? onRemoveCard() : setIsEdting(false)
                }
              >
                {t('button__remove_card')}
              </Button>
              {!is_expired && (
                <Button
                  buttonType="primary"
                  size="small"
                  onClick={onUpdateCard}
                  disabled={!enableSubmitButton}
                >
                  {t('myaccount_button_update&save')}
                </Button>
              )}
            </>
          ) : (
            <>
              <Button
                buttonType="secondary"
                size="small"
                onClick={() => setIsEdting(true)}
              >
                {t('common__cancel')}
              </Button>
              <Button buttonType="danger" size="small" onClick={onRemoveCard}>
                {t('button__remove_card')}
              </Button>
            </>
          )
        }
        onRequestClose={onModalClose}
      >
        <CardContainer $expired={is_expired}>
          {(() => {
            switch ((brand || '').toLowerCase()) {
              case 'amex':
                return <AmericanExpressSVG />
              case 'mastercard':
                return <MasterCardSVG />
              case 'unionpay':
                return <UnionPaySVG />
              case 'visa':
                return <VisaSVG />
              default:
                return <UnknownSVG />
            }
          })()}
          <BodyBold typographyStyles="c-black mb-none">⋅⋅⋅⋅ {last4}</BodyBold>
        </CardContainer>
        {isEdting ? (
          <>
            <FieldWrapper>
              <BodyBold>{t('card_nickname_optional')}</BodyBold>
              <TextField
                name="nickname"
                value={editableNickname}
                onChange={value => setEditableNickname(value)}
                disabled={is_expired}
              />
            </FieldWrapper>
            <Checkbox
              labelText="Make primary card"
              value={isPrimary}
              disabled={is_default || is_expired}
              onChange={value => {
                setIsPrimary(value)
              }}
            />
          </>
        ) : (
          <AlignCenterBody typographyStyles="mb-none">
            {t('popup.confirm.removeCard.content')}
          </AlignCenterBody>
        )}
      </Modal>
    </>
  )
}

EditCreditCardModal.propTypes = {
  id: PropTypes.number,
  brand: PropTypes.oneOf(['Visa', 'Mastercard', 'Amex', 'Unionpay']),
  last4: PropTypes.string,
  nickname: PropTypes.string,
  is_default: PropTypes.bool,
  is_expired: PropTypes.bool,
  onModalClose: PropTypes.func,
}

export { EditCreditCardModal }
