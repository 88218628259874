import React from 'react'
import PropTypes from 'prop-types'

import RadioSelectedIcon from './assets/selected.svg'
import RadioDeselectedIcon from './assets/selection-radio-default.svg'
import RadioDisabledIcon from './assets/selection-radio-disable.svg'

import {
  HiddenRadioButton,
  DisplayedRadioButton,
  RadioButtonContainer,
  StyledBody,
  StyledBodyBold,
  RadioButtonGroupContainer,
} from './classic-radio-button.component.styles'

const ClassicRadioButton = ({
  className,
  dataTestid,
  option,
  checked,
  onChange,
  labelBold,
}) => {
  const renderCorrectIcon = () => {
    if (checked && option.disabled) {
      return <RadioSelectedIcon />
    }
    if (option.disabled) {
      return <RadioDisabledIcon />
    }
    if (checked) {
      return <RadioSelectedIcon />
    }
    return <RadioDeselectedIcon />
  }

  return (
    <div className={className} data-testid={dataTestid}>
      <label>
        <RadioButtonContainer disabled={option.disabled}>
          <HiddenRadioButton
            value={option.value}
            checked={checked}
            onChange={onChange}
            disabled={option.disabled}
          />
          <DisplayedRadioButton disabled={option.disabled}>
            {renderCorrectIcon()}
          </DisplayedRadioButton>
          {labelBold ? (
            <StyledBodyBold disabled={option.disabled}>
              {option.label}
            </StyledBodyBold>
          ) : (
            <StyledBody disabled={option.disabled}>{option.label}</StyledBody>
          )}
        </RadioButtonContainer>
      </label>
    </div>
  )
}

ClassicRadioButton.propTypes = {
  dataTestid: PropTypes.string,
  className: PropTypes.string,
  option: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.any,
    disabled: PropTypes.bool,
  }),
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  labelBold: PropTypes.bool,
}

ClassicRadioButton.defaultProps = {
  option: {
    disabled: false,
  },
}

class ClassicRadioButtonGroup extends React.Component {
  static propTypes = {
    dataTestid: PropTypes.string,
    className: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired,
        disabled: PropTypes.bool,
      }).isRequired
    ),
    onChange: PropTypes.func,
    input: PropTypes.shape({
      value: PropTypes.any,
      onChange: PropTypes.func,
    }),
    value: PropTypes.any,
    labelBold: PropTypes.bool,
    valueType: PropTypes.string,
  }

  static defaultProps = {
    input: {},
    onChange: () => {},
    value: '',
    labelBold: false,
    valueType: '',
  }

  state = {
    selected:
      this.props.input.value || this.props.value || this.props.options[0].value,
  }

  handleSelect = event => {
    let value = event.target.value
    if (this.props.valueType === 'boolean') {
      value = event.target.value === 'true'
    }

    ;(this.props.input.onChange || this.props.onChange)(value)
    this.setState({ selected: value })
  }

  _renderOptions = () =>
    this.props.options.map((option, index) => (
      <ClassicRadioButton
        dataTestid={`radio-button-${index}`}
        key={`radio-button-${index}`}
        option={option}
        onChange={this.handleSelect}
        checked={this.state.selected === option.value}
        labelBold={this.props.labelBold}
      />
    ))

  render() {
    const { className, dataTestid } = this.props

    return (
      <RadioButtonGroupContainer className={className} data-testid={dataTestid}>
        {this._renderOptions()}
      </RadioButtonGroupContainer>
    )
  }
}

export { ClassicRadioButton, ClassicRadioButtonGroup }
