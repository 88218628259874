import styled, { keyframes } from "styled-components"

import TooltipIcon from "./assets/info.svg"
import { SPACING } from "../../design/spacing/spacing.constants"
import { COLOR } from "../../design/color/color.constants"

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const TooltipWrapper = styled.div`
  position: relative;
`

const TooltipHeading = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) =>
    props.noMargin ? "0" : props.marginBottom ?? SPACING.XS};
`

const StyledTooltipIcon = styled(TooltipIcon)`
  margin-left: ${SPACING.XS};
  height: 16px;
  width: 16px;
`

const ContentBox = styled.div`
  position: absolute;
  background-color: ${COLOR.WHITE};
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  width: ${(props) => props.contentWidth};
  padding: ${SPACING.S};
  display: inline-block;
  z-index: 1;
  top: ${(props) =>
    props.offsetContent > 0
      ? `calc(-${props.offsetContent}px - ${SPACING.XS})`
      : "none"};
  animation: ${fadeIn} 0.1s linear;
`

export { TooltipWrapper, TooltipHeading, StyledTooltipIcon, ContentBox }
