const apiLanguageCode = locale => {
  switch (locale) {
    case 'zh-cn':
      return 'zh_CN1'
    case 'en-hk':
      return 'en_US'
    case 'zh-hk':
      return 'zh_TW'
    case 'en-sg':
      return 'en_SG'
    case 'zh-tw':
      return 'zh_TW1'
    case 'vi-vn':
      return 'vi_VN'
    default:
      return 'en_US'
  }
}

const languageCodeToLocale = code => {
  switch (code) {
    case 'zh_CN1':
      return 'zh-cn'
    case 'en_US':
      return 'en-hk'
    case 'zh_TW':
      return 'zh-hk'
    case 'en_SG':
      return 'en-sg'
    case 'zh_TW1':
      return 'zh-tw'
    default:
      return 'en-hk'
  }
}

const datePickerLocale = locale => {
  switch (locale) {
    case 'zh-cn':
      return 'zh-Hans-CN'
    case 'en-hk':
      return 'en-HK'
    case 'zh-hk':
      return 'zh-Hant-TW'
    case 'en-sg':
      return 'en-SG'
    case 'zh-tw':
      return 'zh-Hant-TW'
    default:
      return 'en-HK'
  }
}

const mapLanguageCode = locale => {
  switch (locale) {
    case 'zh-cn':
      return 'zh-CN'
    case 'en-hk':
      return 'en'
    case 'en-sg':
      return 'en'
    case 'zh-tw':
      return 'zh-TW'
    case 'zh-hk':
      return 'zh-TW'
    default:
      return 'en'
  }
}

export {
  apiLanguageCode,
  datePickerLocale,
  mapLanguageCode,
  languageCodeToLocale,
}
