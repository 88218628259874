import styled from "styled-components"

import { COLOR } from 'ggx-componentlibrary/design/color/color.constants'
import { SPACING } from "ggx-componentlibrary/design/spacing/spacing.constants"
import { Loader } from "ggx-componentlibrary/components/loader/loader.component"
import { SmallTextBold } from "ggx-componentlibrary/design/typography/typography.styles"
import { Button } from "ggx-componentlibrary/components/button/button.component"

import OrderDetailCross from "./assets/order-detail-cross.svg"

const ORDER_DETAIL_WIDTH = 370

const StyledOrderDetailCross = styled(OrderDetailCross)`
  cursor: pointer;
  position: absolute;
  top: calc(2px + ${SPACING.M});
  right: ${SPACING.M};
  border: none;
  padding: 0;
  background: none;
  -webkit-appearance: none;
`

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const StaticTrackerMap = styled.img`
  position: relative;
  margin-bottom: ${SPACING.M};
`

const MapContainer = styled.div`
  display: flex;
`

const LoadingMessageWrapper = styled.div`
  display: flex;
  position: absolute;
  width: 161px;
  height: ${SPACING.L};
  align-items: center;
  z-index: 1;
  background-color: ${COLOR.WHITE};
  transform: translateX(79px) translateY(70px);
  border-radius: ${SPACING.XS};
  padding: ${SPACING.XS} 0px;
  box-shadow: 0px ${SPACING.XXS} ${SPACING.XXS} rgba(0, 0, 0, 0.1);

  ${Loader} {
    width: 18px;
    height: 18px;
    margin-left: ${SPACING.S};
  }
  ${SmallTextBold} {
    padding-left: ${SPACING.XS};
    color: ${COLOR.BLACK};
    margin-bottom: 0px;
  }
`

const BonusButton = styled(Button)`
  margin-bottom: ${SPACING.M};
`

export {
  ORDER_DETAIL_WIDTH,
  StyledOrderDetailCross,
  LoaderWrapper,
  StaticTrackerMap,
  MapContainer,
  LoadingMessageWrapper,
  BonusButton,
}
