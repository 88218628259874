import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { useColumns } from './order-table.columns'
import { StyledTable } from './order-table.component.styles'
import { LoadingSpinner } from '../../legacy/views/helpers/loading/spinner'
import { isSubUser } from 'ggx-src/users-and-branches/duck/selectors.js'

const OrderTable = ({
  orders,
  loading,
  onRowActivation,
  noDataMessage,
  activatedRow,
  sortByTimeDescending,
  toggleSortByTimeDescending,
}) => {
  const subUser = useSelector(state => isSubUser(state))
  const columns = useColumns({
    sortByTimeDescending,
    toggleSortByTimeDescending,
    isSubUser: subUser,
  })

  return (
    <StyledTable
      $isEmpty={orders.length === 0}
      uniqueIdentifier="id"
      columns={columns}
      data={orders}
      noDataMessage={noDataMessage}
      customLoadingIndicator={<LoadingSpinner />}
      loading={loading}
      onRowActivation={onRowActivation}
      activatedRow={activatedRow}
    />
  )
}

OrderTable.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  onRowActivation: PropTypes.func,
  noDataMessage: PropTypes.node,
  activatedRow: PropTypes.string,
  sortByTimeDescending: PropTypes.bool,
  toggleSortByTimeDescending: PropTypes.func,
}

export { OrderTable }
