import Constants from '../constants/action'
import { eventsWithoutNotification } from '../constants/notification'

const initialState = {
  socket: null,
  subscription: null,
  subscribed: null,
  error: null,
  id: 0,
  messages: [],
  readMessages: [],
  unreadCount: 0,
  notifications: [],
  isLoading: true,
  isLoadingAction: false,
}

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case Constants.NOTIFICATION_NOT_CONNECTED:
      return { ...initialState }

    case Constants.NOTIFICATION_CONNECTION_DOWN:
      return {
        ...state,
        socket: null,
        subscription: null,
        subscribed: null,
        error: action.error,
      }

    case Constants.NOTIFICATION_SUBSCRIBING:
      return {
        ...state,
        socket: action.socket,
        subscription: action.subscription,
        error: null,
      }

    case Constants.NOTIFICATION_SUBSCRIBE_SUCCEEDED:
      return { ...state, subscribed: true, error: null }

    case Constants.NOTIFICATION_SUBSCRIBE_FAILED:
      return {
        ...state,
        subscription: null,
        subscribed: null,
        error: action.error,
      }

    case Constants.NOTIFICATION_RECEIVE_MESSAGE: {
      const messages = [action.message, ...state.messages]
      const unreadCount = eventsWithoutNotification.includes(
        action.message.eventType
      )
        ? state.unreadCount
        : state.unreadCount + 1
      return {
        ...state,
        messages,
        eventType: action.message.eventType,
        eventId: action.message.orderRequestEventId,
        driverLocation: action.message.driver_location,
        unreadCount,
      }
    }
    // mark as read for all new messages
    case Constants.NOTIFICATION_READ_MESSAGES: {
      const readMessages = [state.messages, state.readMessages]
      readMessages.slice(0, state.maxReadMessages)
      return { ...state, readMessages, messages: [] }
    }

    case Constants.NOTIFICATION_CLEAR_MESSAGES:
      return { ...state, messages: [], notifications: [], unreadCount: 0 }

    case Constants.NOTIFICATION_CLEAR_MESSAGE:
      return {
        ...state,
        messages: state.messages.filter(
          message =>
            message.orderRequestEventId !== action.id &&
            message.deliveryOrderUuid !== action.id
        ),
      }

    case Constants.NOTIFICATION_READ_MESSAGE: {
      const messages = state.messages.map(message =>
        message.orderRequestEventId === action.id ||
        message.deliveryOrderUuid === action.id
          ? Object.assign({}, message, { read: true })
          : message
      )

      const unreadMessages = messages.filter(message => !message.read).length

      return {
        ...state,
        unreadCount: unreadMessages,
        messages,
      }
    }

    case Constants.SET_NOTIFICATIONS_FETCHING:
      return { ...state, isLoading: true }

    case Constants.SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: state.notifications.concat(action.notifications),
        isLoading: false,
        currentPage: action.page,
      }

    case Constants.TOGGLE_NOTIFICATION_ACTION_REQUEST:
      return {
        ...state,
        isLoadingAction: !state.isLoadingAction,
      }

    default:
      return state
  }
}

export default reducer
