import Constants from '../constants/action'

const initialState = {
  currentUser: null,
  error: null,
  loading: null,
  unauthorizedSession: null,
  sessionSwitch: false,
  userForbidden: false,
}

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case Constants.CURRENT_USER:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...action.currentUser,
        },
        loading: null,
        error: null,
      }

    case Constants.UPDATE_CURRENT_USER:
      return { ...state, currentUser: { ...state.currentUser, ...action.data } }

    case Constants.UPDATE_CURRENT_USER_LANGUAGE:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          meta: {
            ...(state?.currentUser?.meta || {}),
            language: action.data.meta.language,
            b2b: {
              ...(state?.currentUser?.meta?.b2b || {}),
            },
          },
        },
      }

    case Constants.SESSIONS_LOADING:
      return { ...state, loading: true, error: null }

    case Constants.SESSIONS_ERROR:
      return { ...state, loading: null, error: action.error }

    case Constants.USER_LOGGED_OUT:
      return initialState

    case Constants.SESSION_UNAUTHORIZED:
      return { ...state, unauthorizedSession: true }

    case Constants.SESSION_SWITCH:
      return { ...state, sessionSwitch: action.payload }
    case Constants.SESSION_FORBIDDEN:
      return { ...state, userForbidden: true }

    case Constants.SESSION_RESET:
      return initialState

    default:
      return state
  }
}

export default reducer
