import * as types from './types'

function profilePhotoDataUrlReducer(state = null, action = {}) {
  switch (action.type) {
    case types.SET_PROFILE_IMAGE_DATA_URL:
      return action.value
    default:
      return state
  }
}

function companyProfilePhotoDataUrlReducer(state = null, action = {}) {
  switch (action.type) {
    case types.SET_COMPANY_PROFILE_IMAGE_DATA_URL:
      return action.value
    default:
      return state
  }
}

const profileReducers = {
  profilePhotoDataUrl: profilePhotoDataUrlReducer,
  companyProfilePhotoDataUrl: companyProfilePhotoDataUrlReducer,
}

export { profileReducers }
