import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Button } from 'ggx-componentlibrary/components/button/button.component'
import { CalloutModal } from '../../callout-modal/callout-modal.component'
import { payByMonthlySettlement } from '../../../van-booking/duck/selectors'

const HIGHLIGHT = {
  width: '196px',
  height: '56px',
  x: '4px',
  y: '140px',
}

const MODAL_POSITION = {
  left: '420px',
  top: '244px',
}

const TopupCallout = ({ handleNext, handleSkip, stepText }) => {
  const [t] = useTranslation()
  const isMonthlySettlement = useSelector(state =>
    payByMonthlySettlement(state)
  )
  const heading =
    (isMonthlySettlement &&
      t('tour__top_up_your_wallet_for_delivery_orders')) ||
    t('tour__top_up_your_wallet')
  const message =
    (isMonthlySettlement &&
      t(
        'tour__get_up_to_000_free_credits_for_the_first_top_up_with_credit_card_or_bank_transfer'
      )) ||
    t(
      'tour__get_up_to_000_free_credits_for_the_first_top_up_and_000_thereafter'
    )

  const footer = (
    <>
      <Button buttonType="secondary" onClick={handleSkip}>
        {t('button__end_tour')}
      </Button>
      <Button onClick={handleNext}>{t('button_next')}</Button>
    </>
  )

  return (
    <CalloutModal
      stepText={stepText}
      highlights={[HIGHLIGHT]}
      modalPosition={MODAL_POSITION}
      heading={heading}
      message={message}
      footer={footer}
    />
  )
}

TopupCallout.propTypes = {
  handleNext: PropTypes.func,
  handleSkip: PropTypes.func,
  stepText: PropTypes.string,
}

export { TopupCallout, HIGHLIGHT, MODAL_POSITION }
