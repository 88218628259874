import styled from "styled-components"
import { SPACING } from "ggx-componentlibrary/design/spacing/spacing.constants"
import { FONT_BOLD } from "ggx-componentlibrary/design/typography/typography.constants"
import { Button } from "ggx-componentlibrary/components/button/button.component"

const Container = styled.div`
  width: 300px;
  padding: ${SPACING.S};
`
const Label = styled.div`
  font-size: 14px;
  ${FONT_BOLD}
`

const FieldLabel = styled.div`
  margin: ${SPACING.S} 0 ${SPACING.XS};
`

const ApplyButton = styled(Button)`
  width: 100%;
  margin-top: ${SPACING.S};
`

export { Container, Label, FieldLabel, ApplyButton }
