import styled, { keyframes } from "styled-components"
import PropTypes from "prop-types"

import { COLOR } from "../../design/color/color.constants"
import {
  FONT_SIZE,
  LINE_HEIGHT,
  FONT_BOLD,
} from "../../design/typography/typography.constants"
import { SPACING } from "../../design/spacing/spacing.constants"
import LoaderImage from "./assets/loader.svg"

const StyledButton = styled.button`
  ${(props) =>
    props.isLoading &&
    `display: flex;
    justify-content: center;`}

  ${FONT_BOLD};
  font-size: ${(props) =>
    props.size === "large" ? FONT_SIZE.LARGE : FONT_SIZE.MEDIUM};
  line-height: ${(props) =>
    props.size === "large" ? LINE_HEIGHT.LARGE : LINE_HEIGHT.MEDIUM};

  color: ${({ buttonType, disabled }) => {
    if (disabled) {
      return COLOR.GREY_DARK
    } else if (buttonType === "primary" || buttonType === "danger" || buttonType === "danger-100") {
      return COLOR.WHITE
    } else if (buttonType === "secondary-danger-100") {
      return COLOR.DANGER_100
    }
    return COLOR.BLACK
  }};

  background-color: ${({ buttonType, disabled }) => {
    const colorMap = {
      disabled: COLOR.GREY_LIGHTER,
      primary: COLOR.BLUE_MEDIUM,
      danger: COLOR.RED_MEDIUM,
      warning: COLOR.ORANGE_MEDIUM,
      "danger-100": COLOR.DANGER_100
    }

    if (disabled) return colorMap.disabled

    return colorMap[buttonType] || COLOR.WHITE
  }};

  ${({ size }) =>
    size === "small" ? `padding: 12px ${SPACING.S};` : `padding: ${SPACING.S};`}

  border-width: 1px;
  border-style: solid;

  border-color: ${({ buttonType, disabled }) => {
    if (disabled) {
      return COLOR.GREY_LIGHT
    } else if (buttonType === "primary") {
      return COLOR.BLUE_MEDIUM
    } else if (buttonType === "danger") {
      return COLOR.RED_MEDIUM
    } else if (buttonType === "secondary-danger-100") {
      return COLOR.DANGER_100
    } else if (buttonType === "danger-100") {
      return COLOR.DANGER_100
    }
    return COLOR.GREY_LIGHT
  }};

  border-radius: 4px;
  outline: none;
  text-decoration: none;
  ${(props) => !props.disabled && "cursor: pointer;"}
  ${(props) => props.disabled && "pointer-events: none;"}
  ${(props) => props.width && `width: ${props.width};`}

  &:hover {
    background-color: ${({ buttonType, disabled }) => {
      if (disabled) {
        return COLOR.GREY_LIGHTER
      } else if (buttonType === "primary") {
        return "#007aac"
      } else if (buttonType === "danger") {
        return "#DD4558"
      } else if (buttonType === "warning") {
        return COLOR.ORANGE_DARK
      } else if (buttonType === "danger-100") {
        return COLOR.DANGER_200
      }
      return "#ebeded"
    }};
  }

  &:active {
    box-shadow: inset 0 1px 4px 0
      ${(props) =>
        props.buttonType === "primary"
          ? "rgba(0, 0, 0, 0.2)"
          : "rgba(0, 18, 26, 0.1)"};
  }
`

StyledButton.propTypes = {
  buttonType: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  width: PropTypes.string,
}

StyledButton.defaultProps = {
  buttonType: "primary",
  size: "small",
}

const rotating = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

const Loader = styled(LoaderImage)`
  animation: ${rotating} 1.4s linear infinite;
  height: ${(props) =>
    props.size === "large" ? LINE_HEIGHT.LARGE : LINE_HEIGHT.MEDIUM};
  width: ${(props) =>
    props.size === "large" ? LINE_HEIGHT.LARGE : LINE_HEIGHT.MEDIUM};
  margin-right: ${(props) =>
    props.size === "large" ? SPACING.XS : SPACING.XXS};
`

Loader.propTypes = {
  size: PropTypes.string,
}

Loader.defaultProps = {
  size: "small",
}

export { StyledButton, Loader }
