import PropTypes from 'prop-types'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'

import translate from '../../locales/translate'

const translateWithPrefix = key => translate(`session.dialog.${key}`)

const SessionDialog = ({ open }) => (
  <Dialog
    open={open}
    disableEscapeKeyDown
    sx={{
      '& .MuiPaper-root': {
        width: '400px',
        height: '200px',
        textAlign: 'center',
      },
    }}
  >
    <DialogTitle>{translateWithPrefix('multiSessionTitle')}</DialogTitle>
    {translateWithPrefix('multiSessionBody')}
  </Dialog>
)

SessionDialog.propTypes = {
  open: PropTypes.bool,
}

export default SessionDialog
