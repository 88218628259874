import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Button } from 'ggx-componentlibrary/components/button/button.component'
import { CalloutModal } from '../../callout-modal/callout-modal.component'

function getFooterMenuUserLink() {
  return document.querySelector(
    '[data-testid="navigation-bar-footer-menu-user"]'
  ).children[0]
}

const UserManagementCallout = ({ handleSkip, stepText }) => {
  const [highlights, setHighlights] = useState([])
  const [modalPosition, setModalPosition] = useState(null)
  const [t] = useTranslation()

  const footer = (
    <Button
      onClick={evt => {
        handleSkip(evt)
        getFooterMenuUserLink().click()
      }}
    >
      {t('button__got_it')}
    </Button>
  )
  const heading = t('tour__get_your_team_together')
  const message = t('tour__invite_your_colleagues_and_assign_different_roles')

  useEffect(() => {
    ;(async () => {
      const footerMenuUserLink = getFooterMenuUserLink()
      footerMenuUserLink.scrollIntoView({ block: 'end' })
      await new Promise(res => setTimeout(res, 0))
      footerMenuUserLink.click()

      const footerMenuUserLinkYPosition =
        Math.ceil(footerMenuUserLink?.getBoundingClientRect?.().y) - 5
      const footerMenuUserLinkHeight =
        Math.ceil(footerMenuUserLink?.getBoundingClientRect?.().height) + 10

      const subMenu = document.querySelector(
        '[data-testid="navigation-sub-menu"]'
      )
      const subMenuWidth = Math.ceil(subMenu?.getBoundingClientRect().width)
      const subMenuHeight = Math.ceil(subMenu?.getBoundingClientRect().height)
      const subMenuY = Math.ceil(subMenu?.getBoundingClientRect().y) - 6
      const subMenuX = Math.ceil(subMenu?.getBoundingClientRect().x) - 6

      setModalPosition({
        left: `${subMenuWidth + 410}px`,
        top: `${subMenuY + 95}px`,
      })

      setHighlights([
        {
          width: `${160 - Math.max(166 - subMenuX, 0)}px`,
          height: `${footerMenuUserLinkHeight}px`,
          x: '6px',
          y: `${footerMenuUserLinkYPosition}px`,
        },
        {
          width: `${subMenuWidth + 15}px`,
          height: `${subMenuHeight + 12}px`,
          x: `${subMenuX}px`,
          y: `${subMenuY}px`,
        },
      ])
    })()
  }, [])

  return (
    <CalloutModal
      stepText={stepText}
      highlights={highlights}
      modalPosition={modalPosition}
      heading={heading}
      message={message}
      footer={footer}
      chevronTopOffset="5%"
    />
  )
}

UserManagementCallout.propTypes = {
  handleSkip: PropTypes.func,
  stepText: PropTypes.string,
}

export { UserManagementCallout }
