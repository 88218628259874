import PropTypes from 'prop-types'
import Link from 'react-router/lib/Link'
import { asset1x2xUrl } from './asset_url'

function makeSrcSet(imgPath) {
  return asset1x2xUrl(`/images/${imgPath}.png`, `/images/${imgPath}@2x.png`)
}

function IconImg({ imgPath, ...props }) {
  return <img {...props} srcSet={makeSrcSet(imgPath)} alt="icon" />
}

function IconLink({ imgPath, url, className }) {
  return (
    <Link to={url}>
      <IconImg className={className} imgPath={imgPath} />
    </Link>
  )
}
IconImg.propTypes = {
  imgPath: PropTypes.string.isRequired,
}

IconLink.propTypes = {
  imgPath: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export { IconImg, IconLink, makeSrcSet }
