import styled from "styled-components"

import { Button } from "../../component-library/components/button/button.component"
import { Body } from "../../component-library/design/typography/typography.styles"
import { SPACING } from "../../component-library/design/spacing/spacing.constants"

const ADD_CARD_BUTTON_WIDTH = "105px"
const ADD_CARD_BUTTON_MARGIN_LEFT = SPACING.XS

const StyledAddCardButton = styled(Button)`
  display: inline-block;
  flex-shrink: 0;
  margin-left: ${SPACING.XS};
  padding: 12px ${SPACING.S};
  width: ${ADD_CARD_BUTTON_WIDTH};
  height: 42px;
`

const PaymentWrapper = styled.div`
  display: flex;
  align-items: flex-start;

  width: ${(props) => {
    if (props.formWidth && props.savedCard) {
      return `calc(${props.formWidth} - ${ADD_CARD_BUTTON_WIDTH} - ${ADD_CARD_BUTTON_MARGIN_LEFT})`
    }
    if (props.formWidth) {
      return props.formWidth
    }
    return "100%"
  }};
`

const StyledHint = styled(Body)`
  margin: ${SPACING.XS} 0 0;
`

export { StyledAddCardButton, PaymentWrapper, StyledHint }
