import { useMemo } from 'react'
import { track } from 'ggx-src/address-book/use-tracking'

export const EVENT = {
  SEARCH_USED: 'search_used',
}

export default () => {
  return useMemo(
    () => ({
      [EVENT.SEARCH_USED]: (properties = { keyword_used: '' }) =>
        requestIdleCallback(() => track(EVENT.SEARCH_USED, properties)),
    }),
    []
  )
}
