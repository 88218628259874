import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { loadStripe } from '@stripe/stripe-js'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Elements } from '@stripe/react-stripe-js'

import { InjectedStripeCard } from './injected-stripe-card.component'
import {
  StyledPaymentWrapper,
  CardIcon,
  CardDisplayWrapper,
  CardNumberWrapper,
  CardExpiryDateWrapper,
  StyledTextLink,
} from './payment.component.styles'
import { StyledError } from 'ggx-componentlibrary/components/text-field/text-field.component.styles'
import { paymentService } from '../../payment/api/api.service'
import { walletApiService } from '../../van-booking/api/wallet-api.service'
import { setSavedCardInfo } from '../../payment/duck/actions'
import { getSavedCard, getSavedCardInfo } from '../../payment/duck/selectors'
import { displayAlert } from 'ggx-componentlibrary/components/alerts/alerts.component'
import { RemoveCardModal } from './remove-card-modal/remove-card-modal.component'
import { featureFlagService } from 'ggx-service/feature-flag/feature-flag'
import { formatCardBrand } from 'ggx-service/card-brand/card-brand'
import { brazeClient, BRAZE_EVENT } from 'ggx-service/braze'

const stripePromise = loadStripe(process.env.STRIPE_KEY)

const Payment = ({
  className,
  expiryDate,
  dataTestid,
  noBorderDisplayMode,
  metaError,
  onCardSaved,
}) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const savedCard = useSelector(state => getSavedCard(state))
  const cardInfo = useSelector(state => getSavedCardInfo(state))
  const [cardError, setCardError] = useState(null)
  const [showRemoveCardModal, setShowRemoveCardModal] = useState(false)
  const [removeCreditCardInProgress, setRemoveCreditCardInProgress] = useState(
    false
  )

  const errorMessages = {
    incomplete_number: t('error.missing.creditCardNumber'),
    incomplete_expiry: t('error.missing.creditCardDate'),
    incomplete_cvc: t('error.missing.creditCardCVC'),
    incomplete_zip: t('error.missing.creditCardZip'),
    invalid_expiry_month_past: t('error.missing.creditCardDate'),
    invalid_expiry_year_past: t('error.missing.creditCardDate'),
    invalid_expiry_year: t('error.missing.creditCardDate'),
    invalid_number: t('error__please_enter_valid_credit_card_number'),
    get_card_error: t('error.creditCard.retrieve'),
    save_card_error: t('error.creditCard.save'),
    remove_card_error: t('error.creditCard.remove'),
  }

  const getCardInfo = async () => {
    setCardError(null)

    try {
      const result = await paymentService.getCard()
      dispatch(
        setSavedCardInfo({
          id: result.id,
          brand: result.brand,
          last4: result.last_four_digits,
        })
      )
    } catch (error) {
      if (error?.response?.data?.i18n_key === 'default_credit_card_not_found') {
        dispatch(setSavedCardInfo({ id: null, brand: null, last4: null }))
      } else {
        dispatch(setSavedCardInfo({ id: null, brand: null, last4: null }))
        setCardError({ code: 'get_card_error' })
      }
    }
  }

  useEffect(() => {
    getCardInfo()
    featureFlagService.triggerFeatureFlag('payment_3ds')
  }, [])

  useEffect(() => {
    if (savedCard && cardInfo?.last4 === null) getCardInfo()
    if (!savedCard && cardInfo?.last4) getCardInfo()
  }, [savedCard])

  useEffect(() => {
    if (metaError) setCardError(metaError)
  }, [metaError])

  const cardBrand = () => {
    const brandsRegex = /amex|diners|discover|jcb|mastercard|unionpay|unknown|visa/
    const returnCardBrand =
      formatCardBrand(cardInfo.brand).match(brandsRegex) || 'unknown'

    // eslint-disable-next-line global-require, import/no-dynamic-require
    return (
      <img
        src={require(`./assets/${returnCardBrand}.png`)}
        alt={cardInfo.brand}
      />
    )
  }

  const removeCard = async () => {
    setCardError(null)
    setRemoveCreditCardInProgress(true)
    try {
      await paymentService.removeCard(cardInfo?.id)
      dispatch(setSavedCardInfo({ id: null, brand: null, last4: null }))
      displayAlert({ message: t('systemMessage.wallet.removeCard') })
      brazeClient.setCustomEvent(BRAZE_EVENT.PAYMENT.REMOVE_CARDS)
    } catch (error) {
      setCardError({ code: 'remove_card_error' })
    } finally {
      setShowRemoveCardModal(false)
      setRemoveCreditCardInProgress(false)
    }
  }

  const renderDisplayComponent = () => {
    const displayDateIndices = [2, 4]
    let displayExpiryDate = expiryDate ?? ' '
    let displayCardNumber = cardInfo.last4.replace(/\s/g, '')
    displayCardNumber = `**** ${displayCardNumber.substring(
      displayCardNumber.length - 4
    )}`

    displayDateIndices.forEach(index => {
      if (displayExpiryDate.substring(index, index + 1) !== ' ') {
        displayExpiryDate = `${displayExpiryDate.substring(
          0,
          index
        )} ${displayExpiryDate.substring(index)}`
      }
    })

    return (
      <div>
        <CardDisplayWrapper noBorderDisplayMode={noBorderDisplayMode}>
          {cardInfo.brand && <CardIcon>{cardBrand()}</CardIcon>}
          <CardNumberWrapper>{displayCardNumber}</CardNumberWrapper>
          <CardExpiryDateWrapper>{displayExpiryDate}</CardExpiryDateWrapper>
        </CardDisplayWrapper>
        {cardError && (
          <StyledError>{errorMessages[cardError.code]}</StyledError>
        )}
        <StyledTextLink onClick={() => setShowRemoveCardModal(true)}>
          {t('creditCardGroup.button.removeCard')}
        </StyledTextLink>
        {showRemoveCardModal && (
          <RemoveCardModal
            onConfirm={removeCard}
            onRequestClose={() => setShowRemoveCardModal(false)}
            isLoading={removeCreditCardInProgress}
          />
        )}
      </div>
    )
  }

  const onConfirmCardSetup = async () => {
    const delay = ms => new Promise(resolve => setTimeout(resolve, ms))

    // eslint-disable-next-line consistent-return
    const getCardWithRetry = async () => {
      for (let attempts = 0; attempts < 3; attempts += 1) {
        try {
          // eslint-disable-next-line no-await-in-loop
          return await paymentService.getCard()
        } catch (error) {
          const isLastAttempt = attempts + 1 === 3
          if (isLastAttempt) throw error
          // eslint-disable-next-line no-await-in-loop
          await delay(1000)
        }
      }
    }

    try {
      const result = await getCardWithRetry()
      dispatch(
        setSavedCardInfo({
          id: result.id,
          brand: result.brand,
          last4: result.last_four_digits,
        })
      )
      displayAlert({ message: t('systemMessage.wallet.addCard') })
      setCardError(null)
      onCardSaved()
    } catch {
      setCardError({ code: 'get_card_error' })
      onCardSaved()
    }
  }

  const onReceiveToken = async token => {
    try {
      const result = await walletApiService.saveCard(token)
      dispatch(
        setSavedCardInfo({
          id: result.id,
          brand: result.brand,
          last4: result.last_four_digits,
        })
      )

      setCardError(null)
      onCardSaved()
      displayAlert({ message: t('systemMessage.wallet.addCard') })
    } catch (error) {
      setCardError({ code: 'save_card_error' })
      onCardSaved()
    }
  }

  const renderInputComponent = () => (
    <Elements locale="en" stripe={stripePromise}>
      <InjectedStripeCard
        errorMessages={errorMessages}
        cardError={cardError}
        onConfirmCardSetup={onConfirmCardSetup}
        onReceiveToken={onReceiveToken}
        onCardSaved={onCardSaved}
      />
    </Elements>
  )

  return (
    <StyledPaymentWrapper className={className} data-testid={dataTestid}>
      {cardInfo?.last4 ? renderDisplayComponent() : renderInputComponent()}
    </StyledPaymentWrapper>
  )
}

Payment.displayName = 'Payment'

Payment.propTypes = {
  className: PropTypes.string,
  expiryDate: PropTypes.string,
  dataTestid: PropTypes.string,
  noBorderDisplayMode: PropTypes.bool,
  metaError: PropTypes.shape({
    code: PropTypes.string,
  }),
  onCardSaved: PropTypes.func,
}

Payment.defaultProps = {
  onCardSaved: () => {},
}

export { Payment }
