import indexOf from 'lodash/indexOf'

import CountriesLocales from '../locales/countries'
import Locales from '../locales/index'

let locale = localStorage.getItem('locale')

if (!locale || !Object.keys(Locales).includes(locale)) {
  // NOTE: if country is invalid, set to default
  const country = localStorage.getItem('country') || ENV.GOGOVAN_COUNTRY
  locale = CountriesLocales[country][0]
  localStorage.setItem('locale', locale)
}

function getLocale() {
  return locale
}

function isHongKong() {
  return locale === 'zh-hk' || locale === 'en-hk'
}

function isHongKongEnglish() {
  return locale === 'en-hk'
}

function isSingapore() {
  return locale === 'en-sg'
}

function isTaiwan() {
  return locale === 'zh-tw'
}

function isChina() {
  return locale === 'zh-cn'
}

function isCurrentCountryLocale(lcl) {
  const country = ENV.GOGOVAN_COUNTRY
  return indexOf(CountriesLocales[country], lcl) !== -1
}

function isVietnam() {
  return locale === 'vi-vn'
}

export {
  getLocale,
  isHongKong,
  isHongKongEnglish,
  isSingapore,
  isTaiwan,
  isChina,
  isCurrentCountryLocale,
  isVietnam,
}
