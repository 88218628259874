import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import {
  SmallText,
  HeadingXL,
} from 'ggx-componentlibrary/design/typography/typography.styles'

import {
  VanBookingContainer,
  HeadingSection,
  StyledDescription,
} from './van-booking.component.styles'

const VanBooking = props => {
  const [t] = useTranslation()

  return (
    <VanBookingContainer className={props.className}>
      <HeadingSection>
        <SmallText typographyStyles="mb-none">
          {t('sectionHeader.serviceType.van.name')}
        </SmallText>
        {props.heading ? <HeadingXL>{props.heading}</HeadingXL> : null}
        {props.description ? (
          <StyledDescription>{props.description}</StyledDescription>
        ) : null}
      </HeadingSection>
      {props.children}
    </VanBookingContainer>
  )
}

VanBooking.propTypes = {
  heading: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
}

export { VanBooking }
