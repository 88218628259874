import styled from "styled-components"
import { Link } from "react-router"

import { Modal } from "../../component-library/components/modal/modal.component"
import {
  Content,
  MODAL_BREAKPOINT,
  StyledCrossIcon,
} from "../../component-library/components/modal/modal.component.styles"
import { SPACING } from "../../component-library/design/spacing/spacing.constants"
import { COLOR } from "../../component-library/design/color/color.constants"
import {
  LINE_HEIGHT,
  FONT_WEIGHT,
} from "../../component-library/design/typography/typography.constants"
import {
  HeadingXL,
  Body,
  BodyBold,
  TextLink,
} from "../../component-library/design/typography/typography.styles"
import { CreditCardGroup } from "../../billing/components/credit-card-group.component"
import { Button } from "../../component-library/components/button/button.component"

const MODAL_HEADER_HEIGHT = "132px"

const StyledModal = styled(Modal)`
  ${StyledCrossIcon} {
    z-index: 1;
    > g {
      fill: ${COLOR.WHITE};
    }
  }
  ${Content} {
    padding: ${MODAL_HEADER_HEIGHT} ${SPACING.L} ${SPACING.L};
    margin-top: ${SPACING.XL};
    overflow-y: hidden;

    @media (min-width: 620px) {
      padding: ${MODAL_HEADER_HEIGHT} ${SPACING.XL2} ${SPACING.XL2};
      margin-top: ${SPACING.XL2};
    }
  }
`

const Title = styled(HeadingXL)`
  margin-bottom: ${SPACING.M};
`

const BillingDescription = styled(Body)`
  color: ${COLOR.GREY_DARK};
  margin-bottom: ${SPACING.S};
`

const PaymentMethodTitle = styled(BodyBold)`
  margin-bottom: ${SPACING.XS};
  line-height: ${LINE_HEIGHT.LARGE};
`

const StyledCreditCardGroup = styled(CreditCardGroup)`
  margin-bottom: ${SPACING.XL};
`

const StartSubscriptionButton = styled(Button)`
  width: 100%;
  margin-bottom: ${(props) =>
    props.isFreeTrialExpired ? `${SPACING.XS}` : `${SPACING.M}`};
`

const MaybeLaterButton = styled(Button)`
  width: 100%;
  margin-bottom: ${SPACING.M};
`

const TermsAndCondition = styled(Body)`
  color: ${COLOR.GREY_DARK};
  white-space: pre-line;
`

const SuccessText = styled(Body)`
  margin-bottom: ${SPACING.XL};
`

const StyledLink = styled(Link)`
  display: none;

  @media (min-width: ${MODAL_BREAKPOINT}) {
    display: block;
    text-align: center;
  }
`

const ModalHeader = styled.div`
  width: 100%;
  height: ${MODAL_HEADER_HEIGHT};
  background-color: ${COLOR.PURPLE_DARK};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
`

const StyledTextLink = styled(TextLink)`
  font-weight: ${FONT_WEIGHT.BOLD};
  display: flex;
  width: 210px;
`

export {
  StyledModal,
  Title,
  BillingDescription,
  PaymentMethodTitle,
  StyledCreditCardGroup,
  StartSubscriptionButton,
  TermsAndCondition,
  SuccessText,
  StyledLink,
  ModalHeader,
  StyledTextLink,
  MaybeLaterButton,
}
