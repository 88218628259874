import React from "react"
import styled from "styled-components"
import { COLOR } from "../../design/color/color.constants"
import { SPACING } from "../../design/spacing/spacing.constants"
import { Body, SmallText } from "../../design/typography/typography.styles"
import { InputStyles } from "../text-field/text-field.component.styles"
import DropdownArrow from "./assets/dropdown_arrow.svg"

const StyledMenu = styled(({ className, children, innerProps }) => (
  <div className={className} data-testid="dropdown-menu" {...innerProps}>
    {children}
  </div>
))`
  position: absolute;
  display: ${(props) =>
    props.selectProps.options.length === 0 ? "none" : "block"};
  width: 100%;
  background: white;
  border: 1px solid ${COLOR.GREY_LIGHT};
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  z-index: 1;
  ${({ selectProps }) =>
    typeof selectProps.maxHeight === "number"
      ? `
      max-height: ${selectProps.maxHeight}px;
      overflow-y: scroll;
      `
      : ""}
  ${(props) => {
    const hasHelperText = props?.options?.some(
      (option) => option.dropdownValueMessage !== undefined
    )

    return hasHelperText ? `padding: ${SPACING.S} 0;` : ""
  }}
`

const StyledOption = styled(
  ({ className, children, innerProps, isFocused, data }) => {
    const optionRef = React.useRef(null)
    if (isFocused && optionRef.current) {
      optionRef.current.scrollIntoView({ block: "nearest" })
    }

    return (
      <div
        className={className}
        data-testid="dropdown-option"
        {...innerProps}
        ref={optionRef}
      >
        <Body typographyStyles="mb-none">{children}</Body>
        {data?.dropdownValueMessage && (
          <SmallText typographyStyles="mb-none">
            {data.dropdownValueMessage}
          </SmallText>
        )}
      </div>
    )
  }
)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  background-color: ${(props) =>
    props.isFocused ? COLOR.GREY_LIGHTER : "transparent"};
  padding: ${SPACING.XS} ${SPACING.S};
  margin: 0;
  outline: none;
  cursor: pointer;
  font-weight: ${(props) => (props.isSelected ? "bold" : "normal")};
  &:hover {
    background-color: ${COLOR.GREY_LIGHTER};
  }
  ${(props) => {
    const hasHelperText = props?.options?.some(
      (option) => option.dropdownValueMessage !== undefined
    )

    return hasHelperText
      ? `
          height: 48px;
          padding: 0 ${SPACING.S};
        `
      : ""
  }}
`

const StyledControl = styled(({ className, innerProps, children }) => (
  <div
    className={className}
    onMouseDown={innerProps.onMouseDown}
    onTouchEnd={innerProps.onTouchEnd}
    data-testid="dropdown-wrapper"
  >
    {children}
  </div>
))((props) => {
  const { error, isDisabled } = props.selectProps
  const inputStyles = InputStyles({
    error,
    ...props,
  })

  return `
      ${inputStyles}
      display: flex;
      background-color: ${isDisabled ? COLOR.GREY_LIGHTEST : "transparent"};
      cursor: pointer;
    `
})

const StyledValueContainer = styled(({ className, children }) => (
  <div className={className}>{children}</div>
))`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  overflow: hidden;
`

const StyledSingleValue = styled(({ className, children, data }) => (
  <div className={className}>
    {children}
    {data?.singleValueMessage && (
      <SmallText typographyStyles="mb-none">
        {data.singleValueMessage}
      </SmallText>
    )}
  </div>
))`
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const StyledPlaceholder = styled(({ className, selectProps }) => (
  <div className={className}>{selectProps.placeholder}</div>
))`
  position: absolute;
  width: 100%;
  top: 50%;
  left: 2px;
  color: ${COLOR.GREY_DARK};
  transform: translateY(-50%);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const StyledError = styled(SmallText)`
  margin: ${SPACING.XS} 0 0;
  color: ${COLOR.RED_MEDIUM};
`

const StyledDropdownIndicator = styled(({ className, innerProps }) => (
  <DropdownArrow className={className} {...innerProps} />
))`
  use {
    fill: ${(props) => (props.isDisabled ? COLOR.GREY_MEDIUM : COLOR.BLACK)};
  }
`

const NoOptionsMessage = () => null

const ClearIndicator = () => null

const IndicatorSeparator = () => null

export {
  StyledMenu,
  StyledOption,
  StyledControl,
  StyledValueContainer,
  StyledSingleValue,
  StyledPlaceholder,
  StyledDropdownIndicator,
  NoOptionsMessage,
  ClearIndicator,
  IndicatorSeparator,
  StyledError,
}
