import { HEADER_HEIGHT } from '../header/header.constants'
import { BANNER_HEIGHT } from '../banner/banner.constants'
import { eventsWithoutNotification } from '../../legacy/constants/notification'

const getCollapseSidebar = state => state.collapseSidebar
const getHideToastContainer = state => state?.hideToastContainer
const getBanner = state => state?.banner
const getHeaderBannerHeight = state =>
  `calc(${HEADER_HEIGHT} ${state?.banner?.key ? `+ ${BANNER_HEIGHT}` : ''})`

const getNotificationMessages = state => {
  const messages = state?.notification?.messages || []
  return messages.filter(
    message => !eventsWithoutNotification.includes(message.eventType)
  )
}

const getOrderRequests = state => state?.orderRequests?.orderRequests
const getIsLoadingAction = state => state?.notification?.isLoadingAction
const getOrderRequestsIncluded = state => state?.orderRequests?.included
const getNotificatonsUnreadCount = state => state?.notification?.unreadCount
const getIsNotificationCentreOpen = state => state?.header?.openCentre
const getAddCreditCardModalInfo = state => state?.modal?.creditCard

const getActiveCouponNum = state =>
  state?.coupons?.active_voucher_ids?.length ?? 0
const getActiveCoupons = state =>
  state?.coupons?.active_voucher_ids?.map(id => ({
    ...state?.coupons?.vouchers[id],
    id,
  })) ?? []
const getHistoryCoupons = state =>
  state?.coupons?.history_voucher_ids?.map(id => ({
    ...state?.coupons?.vouchers[id],
    id,
  })) ?? []
const getAvailableCouponNum = state =>
  Object.entries(state?.coupons?.vouchers).filter(
    ([key, item]) =>
      item.can_apply_to_order &&
      !item.is_voucher_expired &&
      state?.coupons?.active_voucher_ids.includes(Number(key))
  ).length

export {
  getCollapseSidebar,
  getHideToastContainer,
  getBanner,
  getHeaderBannerHeight,
  getNotificationMessages,
  getOrderRequests,
  getIsLoadingAction,
  getOrderRequestsIncluded,
  getNotificatonsUnreadCount,
  getIsNotificationCentreOpen,
  getAddCreditCardModalInfo,
  getActiveCouponNum,
  getActiveCoupons,
  getHistoryCoupons,
  getAvailableCouponNum,
}
