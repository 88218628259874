import React, { useCallback, useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { Dropdown } from 'ggx-componentlibrary/components/dropdown/dropdown.component'
import { Button } from 'ggx-componentlibrary/components/button/button.component'
import { apiService } from 'ggx-src/billing/api/api.service'
import NoticeActions from 'ggx-src/legacy/actions/notice'
import {
  DropdownContainer,
  DROPDOWN_MAXHEIGHT,
  StyledBody,
  Footer,
} from './pay-now-section.component.styles'

import { formatCurrency } from 'ggx-service/i18n/format-currency.service'

const PackageListPanel = ({ onNext }) => {
  const [t] = useTranslation()
  const [packageList, setPackageList] = useState([])
  const [selected, setSelected] = useState(null)
  const dispatch = useDispatch()

  useEffect(() => {
    let alive = true
    ;(async () => {
      try {
        const result = await apiService.getTopupPackages()
        if (alive) {
          const options = result.data.map(
            ({ id, attributes: { credits, free_credits: freeCredits } }) => ({
              id,
              value: id,
              label: `${formatCurrency({
                amount: credits,
              })} (+${formatCurrency({ amount: freeCredits })} ${t(
                'billing.topup.modal.package.label'
              )})`,
              amount: credits,
            })
          )
          setPackageList(options)
          if (options.length) setSelected(options[0])
        }
      } catch (error) {
        dispatch(NoticeActions.setErrorMessage(t('error.fetchtopup')))
      }
    })()
    return () => (alive = false)
  }, [])

  const containerRef = useRef()
  const contentRef = useRef()

  useEffect(() => {
    const width = containerRef.current.clientWidth
    const height = containerRef.current.clientHeight
    contentRef.current.style.position = 'absolute'
    contentRef.current.style.width = `${width}px`
    containerRef.current.style.height = `${height}px`
  }, [])

  return (
    <div ref={containerRef}>
      <div ref={contentRef}>
        <StyledBody>{t('billing.topup.modal.creditcard.title')}</StyledBody>
        <DropdownContainer>
          <Dropdown
            dataTestid="topup-modal-dropdown"
            defaultOptions={packageList}
            onChange={useCallback(option => setSelected(option))}
            value={selected}
            maxHeight={DROPDOWN_MAXHEIGHT}
          />
        </DropdownContainer>
        <Footer>
          <Button
            buttonType="primary"
            size="small"
            onClick={useCallback(() => onNext(selected))}
            disabled={!selected}
          >
            {t('billing.topup.modal.button.paynow')}
          </Button>
        </Footer>
      </div>
    </div>
  )
}

PackageListPanel.propTypes = {
  onNext: PropTypes.func,
}

export { PackageListPanel }
