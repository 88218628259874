import PropTypes from 'prop-types'
import CircleKImgSrc from '../../assets/icon_circlek.png'
import SevenElevenImgSrc from '../../assets/icon_7eleven.png'
import AlfredLockerImgSrc from '../../assets/icon_alfred24_locker.png'
import AlfredPointImgSrc from '../../assets/icon_alfred24_point.png'

const CircleKImg = props => (
  <img src={CircleKImgSrc} alt="Circle K" {...props} />
)
const SevenElevenImg = props => (
  <img src={SevenElevenImgSrc} alt="Seven Eleven" {...props} />
)
const AlfredLockerImg = props => (
  <img src={AlfredLockerImgSrc} alt="Alfred Locker" {...props} />
)
const AlfredPointImg = props => (
  <img src={AlfredPointImgSrc} alt="Alfred Point" {...props} />
)

const LocationTypeImage = ({ className, locationType }) => {
  let LocationImg
  if (locationType === 'CIRCLE_K') {
    LocationImg = CircleKImg
  } else if (locationType === 'SEVEN_ELEVEN') {
    LocationImg = SevenElevenImg
  } else if (locationType === 'ALFRED_LOCKER') {
    LocationImg = AlfredLockerImg
  } else if (locationType === 'ALFRED_POINT') {
    LocationImg = AlfredPointImg
  }
  if (locationType) {
    return <LocationImg className={className} />
  }
  return <div className={className} />
}

LocationTypeImage.propTypes = {
  className: PropTypes.string,
  locationType: PropTypes.string,
}

export {
  LocationTypeImage,
  CircleKImg,
  SevenElevenImg,
  AlfredLockerImg,
  AlfredPointImg,
}
