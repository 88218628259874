import styled from "styled-components"

import { COLOR } from "ggx-componentlibrary/design/color/color.constants"
import { SPACING } from "ggx-componentlibrary/design/spacing/spacing.constants"

import { Row } from '../navigation.component.styles'
import ChevronSVG from "../assets/chevron-right.svg"

const OrganizationRow = styled(Row)`
  height: auto;
  min-height: 40px;
  cursor: pointer;

  svg:first-of-type {
    path {
      fill: ${COLOR.GREY_DARK};
      stroke: ${COLOR.GREY_DARK};
    }
  }

  svg:last-of-type {
    margin-right: 0;
  }

  ${(props) => !props.$isActive && `
    :hover {
      svg:first-of-type {
        path {
          fill: ${COLOR.BLUE_MEDIUM};
          stroke: ${COLOR.BLUE_MEDIUM};
        }
      }

      svg:last-of-type {
        path {
          stroke: ${COLOR.BLUE_MEDIUM};
        }
      }
    }
  `}
`

const OrgSwitchContainer = styled.div`
  position: fixed;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  left: 219px;
  top: 0px;
  background-color: ${COLOR.WHITE};
  border-radius: ${SPACING.XXS};

  opacity: ${(props) => (props.$showOrganizationSwitch ? 1 : 0)};
  pointer-events: ${(props) =>
    props.$showOrganizationSwitch ? "auto" : "none"};
  transition: opacity 0.2s ease-in-out;
  padding: ${SPACING.XS} 0;
`

const ChevronIcon = styled(ChevronSVG)`
  margin: 0 ${SPACING.XS} 0 auto;
  flex-shrink: 0;
`

const TextWrapper = styled.div`
  margin-left: 12px;
  color: ${COLOR.GREY_DARKER};

  span {
    margin-left: 0;
  }
`

export {
  ChevronIcon,
  OrganizationRow,
  OrgSwitchContainer,
  TextWrapper,
}