import Constants from '../constants/action'

const initialState = {
  adminOrganizations: [],
  organization: null,
  branches: null,
  branchesToCreate: [],
  branchesToDestroy: [],
  regions: null,
  error: null,
}

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case Constants.SET_ORGANIZATION:
      // TODO: delete organization's branches so we don't have two copies
      return {
        ...state,
        organization: action.organization,
        branches:
          action.organization.branches || state.organization.branches || [],
      }

    case Constants.SET_ADMIN_ORGANIZATIONS:
      return {
        ...state,
        adminOrganizations: action.adminOrganizations,
      }

    case Constants.RESET_ORGANIZATION:
      return { ...state, branchesToCreate: [], branchesToDestroy: [] }

    case Constants.SET_REGIONS:
      return { ...state, regions: action.regions }

    case Constants.SET_ERROR_MESSAGE:
      return {
        ...state,
        error: {
          data: action.data,
          errorMessage: action.message,
          status: action.status,
          statusText: action.statusText,
        },
      }

    default:
      return state
  }
}

export default reducer
