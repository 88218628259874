import styled from "styled-components"
import { ToastContainer, Slide } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import { SPACING } from "../../design/spacing/spacing.constants"
import { COLOR } from "../../design/color/color.constants"
import {
  FONT_REGULAR,
  FONT_SIZE,
  LINE_HEIGHT,
} from "../../design/typography/typography.constants"
import { HEADER_HEIGHT } from "ggx-global/header/header.constants"
import CrossIcon from "./assets/alert-cross.svg"

const StyledCrossIcon = styled(CrossIcon)`
  cursor: pointer;
  path {
    fill: ${COLOR.WHITE};
  }
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
`

const CloseButton = ({ closeToast }) => (
  <IconContainer>
    <StyledCrossIcon onClick={closeToast} />
  </IconContainer>
)

const WrappedToastContainer = ({ className, ...rest }) => (
  <div className={className}>
    <ToastContainer transition={Slide} {...rest} />
  </div>
)

const toastWidth = "380px"
const customBreakpoint = `calc( ${toastWidth} + ${SPACING.XS} * 2)`

const StyledToastContainer = styled(WrappedToastContainer).attrs((props) => {
  // custom props
})`
  .Toastify__toast-container {
    ${(props) => (props.hideToastContainer ? "z-index: 10" : "z-index: 9999")};
    top: calc(${HEADER_HEIGHT} + ${SPACING.S});
    left: ${SPACING.XS};
    width: calc(100% - ${SPACING.XS} * 2);
    max-width: ${toastWidth};

    @media (min-width: ${customBreakpoint}) {
      width: 100%;
      margin-left: calc(-${toastWidth} / 2);
      left: 50%;
    }

  }
  .Toastify__toast {
    background-color: white;
    position: relative;
    min-height: 48px;
    border-radius: 4px;
    border: none;
    color: ${COLOR.WHITE};
    padding-left: ${SPACING.S};
    padding-right: ${SPACING.S};
    cursor: default;
    display: flex;
    align-items: center;

    ${FONT_REGULAR};
    font-size: ${FONT_SIZE.MEDIUM};
    line-height: ${LINE_HEIGHT.SMALL};
    & * {
      z-index: 1;
    }
  }

  .Toastify__toast::before {
    content: ' ';
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
  }

  .Toastify__toast--default::before {
    background-color: ${COLOR.GREY_DARK};
  }

  .Toastify__toast--error::before {
    background-color: ${COLOR.RED_MEDIUM};
  }
`

export { StyledToastContainer, CloseButton }
