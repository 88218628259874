export default {
  accounts: {
    edit: {
      editUser: '編輯使用者',
      pageHeader: '帳戶管理 > 編輯使用者',
      updateUserFail: '用戶未能更新',
      updateUserSuccess: '用戶已更新',
    },
    form: {
      accessLevel: '存取權限',
      branch: '分店編號',
      branchUser: '分店使用者',
      changePassword: '更改密碼',
      confirmPassword: '請確認密碼',
      currentPassword: '目前密碼',
      detailAddress: '樓／室',
      email: '電郵地址',
      master: '管理員',
      name: '姓名',
      newPassword: '新密碼',
      pageHeader: '帳戶管理 > 使用者表格',
      phone: '電話號碼',
      profileForm: '個人資料詳細訊息',
      profileImage: '個人資料圖片',
      streetAddress: '地址',
      subUser: '本地使用者',
    },
    new: {
      createUser: '創建使用者帳戶',
      pageHeader: '帳戶管理>創建使用者帳戶',
    },
    view: {
      accessLevel: '存取權限',
      addNewUser: '添加新使用者',
      admin: 'GGV超級管理員 (admin user)',
      branch: '分店',
      branchUser: '分店管埋員',
      deactivate: '撤銷使用者權限',
      deactivated: '使用者已被撤銷',
      deactivateFail: '使用者無法被撤銷',
      detailAddress: '樓／室',
      editProfile: '編輯個人資料詳細訊息',
      email: '電郵地址',
      false: '否',
      master: '超級管理員',
      name: '姓名',
      pageHeader: '帳戶管理',
      phone: '電話號碼',
      queryPlaceholder: '姓名、分店、電郵地址等',
      reactivate: '重新授權',
      reactivated: '已重新授權',
      reactivateFail: '無法重新授權',
      search: '搜尋:',
      showAll: '顯示全部',
      streetAddress: '地址',
      subUser: '本地使用者',
      true: '是',
      users: '使用者',
    },
  },
  addresses: {
    delete: {
      confirmationPrompt: '是否刪除已選擇地址',
      routeConfirmationPrompt: '是否刪除已選擇路線',
      routeSuccessMessage: '路線已被刪除',
      successMessage: '地址已被删除',
    },
    edit: {
      editAddress: '編輯聯絡人',
      editRoute: '編輯路線',
      pageHeader: '常用送貨資料＞編輯聯絡人',
      pageHeaderRoute: '常用送貨資料＞編輯路線',
      update: '儲存',
    },
    index: {
      addNewContact: '新增聯絡人',
      addNewRoute: '新增路線',
      addresses: '地址',
      branch: '所屬分店',
      company: '公司名稱',
      create: '建立',
      createRoute: '建立路線',
      creator: '使用者',
      csvSuccess: '上傳CSV檔案',
      delete: '刪除',
      detailAddress: '樓／室',
      duplicateAddresses: '刪除重複地址',
      edit: '編輯',
      editRoute: '編輯路線',
      email: '電郵地址',
      filterType: '過濾類型:',
      importContacts: '使用CSV檔案匯入聯絡人',
      name: '收件者',
      next: '下一頁',
      nouniqueaddress: '請輸入新的地址',
      pageHeader: '常用送貨資料',
      phone: '電話號碼',
      placeNewOrder: '新增訂單',
      previous: '上一頁',
      queryPlaceholder: '聯絡人姓名、電話號碼、電子郵件等',
      receiver: '聯絡人',
      remarks: '備註',
      remark: '備註',
      region: '地區',
      route: '相關地址',
      routeName: '路線名稱',
      routeNameUnique: '路線名稱不能重複',
      routes: '路線',
      search: '搜尋：',
      sender: '送件者',
      showingPage: options =>
        `第${options.currentPage}頁／共${options.totalPages}頁`,
      streetAddress: '地址',
      successSnackbar: '成功建立路線',
      totalAddresses: options => `地址數目: ${options.count}`,
      totalRoutes: options => `路線數目: ${options.count}`,
      type: '類型',
      update: '',
    },
    new: {
      createAddress: '建立聯絡人',
      pageHeader: '常用送貨資料 > 新增聯絡人',
      save: '儲存 & 繼續新增聯絡人',
      successMessage: '聯絡人已建立',
    },
    routes: {
      edit: {
        success: '成功更改路線資料',
      },
    },
  },
  constants: {
    addressBoxFields: {
      addressType: '地址類型',
      addressTypeReceiver: '收件者',
      addressTypeSender: '送件者',
      collectOnDelivery: '代收貨款',
      company: '公司名稱',
      desiredDeliveryTime: '期望送達時間',
      desiredDeliveryTimePlaceholder: '送達時間（例如：15:30）',
      destination: '落貨點',
      destinationCSV: '選擇落貨點或點擊地圖',
      detailAddress: '樓／室',
      email: '電郵地址',
      instructions: '備註',
      parcelIds: '',
      parcelIdsPlaceholder: '',
      phone: '電話號碼',
      pickupPoint: '上貨點',
      receiver: '收件者',
      region: '地區*',
      sender: '送件者*',
      waypoint: '中途點',
    },
    currency: {
      prefix: 'HK$',
    },
    goodsTypes: {
      constructionMaterials: '建築材料',
      documents: '文件',
      food: '食用品',
      fragile: '易碎品',
      groceries: '生活用品',
      nil: '未選取',
      none: '未選取',
      null: '未選取',
      others: '其他',
    },
    goodTypes: {
      none: '未選取',
    },
    industries: {
      accounting: '會計及行政',
      agriculture: '農、林、漁、牧業',
      arts: '藝術及設計',
      construction: '建造業',
      culture: '文化、體育、娛樂業',
      education: '教育業',
      electricity: '電力、火力、燃料及水力供應業',
      environment: '環境保護及管理',
      financial: '金融及保險業',
      healthcare: '醫療服務、社會工作業',
      hotels: '酒店、旅遊及飲食業',
      information: '資訊及通訊業',
      international: '國際組織',
      leasing: '租賃及商用服務業',
      legal: '法律行業',
      manufacturing: '製造業',
      mining: '採礦及採石業',
      others: '其他',
      professional: '專業、科學及技術業',
      publicAdministration: '公共行政、社會安全及其他社會組織',
      realEstate: '地產業',
      residentServices: '居民服務相關',
      transport: '運輸、倉庫、郵政及速遞服務業',
      wholesale: '批發及零售業',
    },
    orderRequestStatuses: {
      active: '進行中',
      cancelled: '已取消',
      completed: '已完成',
      pending: '等待司機中',
    },
    parcelStatuses: {
      assigned: '',
      delivered: '',
      failedToDeliver: '',
      parcelPickedUp: '',
      pending: '',
      reassigned: '',
    },
    vehicles: {
      damas: '',
      damasDescription: '',
      labo: '',
      laboDescription: '',
      lbread: '',
      lbreadDescription: '',
      lorry10: '',
      lorry10Description: '',
      lorry14: '',
      lorry14Description: '',
      lorry24: '',
      lorry24Description: '',
      ltruck: '',
      ltruckDescription: '',
      mbread: '',
      mbreadDescription: '',
      motorcycle: '電單車',
      motorcycleDescription: '',
      mtruck: '3.5噸貨車',
      mtruckDescription: '',
      mudou: '5.5噸貨車',
      mudou9: '9噸貨車',
      mudou9Description:
        '最多可載5人 (不包括司機)；載重約3-3.5噸；貨櫃尺寸約為長19-21呎、高6呎、闊6呎。',
      mudouDescription:
        '最多可載5人 (不包括司機)；載重約1-1.5噸；貨櫃尺寸約為長17-19呎、高6呎、闊6呎。',
      sbread: '',
      sbreadDescription: '',
      sedan: '',
      sedanDescription: '',
      sltruck: '',
      sltruckDescription: '',
      struck: '小貨車',
      struckDescription: '',
      ton1: '',
      ton1_2_and_ton1_4: '',
      ton1_2_and_ton1_4Description: '',
      ton11: '',
      ton11Description: '',
      ton14: '',
      ton14Description: '',
      ton15: '',
      ton15Description: '',
      ton18: '',
      ton18Description: '',
      ton1Description: '',
      ton2_5: '',
      ton2_5Description: '',
      ton22: '',
      ton22Description: '',
      ton25: '',
      ton25Description: '',
      ton3_5: '',
      ton3_5Description: '',
      ton4_5: '',
      ton4_5Description: '',
      ton5: '',
      ton5Description: '',
      ton8: '',
      ton8Description: '',
      ton9_5: '',
      ton9_5Description: '',
      tricycle: 'Tricycle',
      truck500: 'Truck500',
      truck1000: 'Truck1000',
      truck1500: 'Truck1500',
      truck2000: 'Truck2000',
      van: 'Van 仔',
      vanDescription:
        '最多可載5位乘客 載重約700公斤 內籠呎寸約長6呎，闊4呎，高4呎',
    },
  },
  errors: {
    auth: {
      restricted: '沒有權限訪問此頁',
    },
    generic: '系統出錯！請重試。',
    page_not_found: {
      home: 'HOME',
      message: 'Sorry, an error has occured, requested page not found!',
      oops: 'Oops!',
      status: '404 Not Found',
    },
  },
  header: {
    addressBook: '常用送貨資料',
    dropdown: {
      accountMgmt: '帳戶管理',
      contactUs: '聯絡我們',
      corporateProfile: '公司資訊',
      help: '幫助',
      logout: '登出',
      switchChineseEnglish: 'English',
      tnc: '條款及細則',
    },
    orderMgmt: '訂單管理',
    placeOrder: '新增訂單',
    tnc: {
      agree: '我明白及同意以上條款及細則',
      next: '下一頁',
      previous: '上一頁',
      proceed: '提交',
      title: '條款及細則',
    },
    wallet: '帳單管理',
  },
  helpers: {
    addressBox: {
      autocompletePopover: {
        locationSuggestion: '建議地址',
        savedAddress: '已儲存送貨資料',
      },
      index: {
        addALocation: '新增中途點',
        address: '地址',
        addressBoxTitle: '收送點資料',
        addressCSV: '地址(*)',
        cantFindAddress: '無法偵測地址，請檢查',
        collectOnDelivery: '代收貨款',
        company: '公司名稱',
        contactName: '聯絡人',
        contactPhone: '電話號碼',
        desiredDeliveryTime: '送達時間',
        detailAddress: '樓／室',
        downloadTemplate: '下載CSV檔案範例',
        email: '電郵地址',
        goodsAndServiceSetting: '貨物及服務設定',
        addDestination: '新增中途點',
        importRoutes: '匯入路線',
        notApplicable: 'N/A',
        parcelIds: '',
        region: '地區',
        remark: '備註',
        remarkValue: options =>
          `代收貨款: ${options.collectOnDelivery}, 備註: ${options.remark}`,
        tooManyLines: 'CSV檔案載入行數上限為每個檔案100列。',
        viewAll: '查看全部',
        route: '路線',
      },
      waypoint_details: {
        okButton: '確認',
        cancelButton: '取消',
      },
    },
    button: {
      cancel: '取消',
      chooseFromComputer: '從電腦中選取',
      saveAndExit: '儲存及離開',
      search: '搜尋',
    },
    datepicker: {
      cancel: '取消',
      date: '日期',
      ok: '確認',
    },
    dialog: {
      cancel: '取消',
      confirm: '確認',
      ok: '確認',
      submit: '提交',
    },
    loading: {
      loading: '載入中...',
    },
    mapView: {
      driver: '司機',
      eta: '預計到達時間',
      license: '車牌號碼',
      notApplicable: 'N/A',
      phone: '電話號碼',
      remark: '註解',
      travelTime: '運輸時間',
    },
    pagination: {
      totalSeparator: 'of',
    },
    text: {
      addressNotFound:
        '對不起，我們未能找到相關地址。 請確認地址名稱正確並重試。',
      required: '請填寫任何資料',
    },
    timepicker: {
      cancel: '取消',
      ok: '確認',
    },
  },
  images: {
    logo: 'general_3/logo',
  },
  notification: {
    actionButton: {
      cancel: '取消訂單',
      cancelled: '已取消',
    },
  },
  notificationCentre: {
    drawerTitle: {
      notification: '通知',
    },
    message: {
      todayAt: options => `今天@${options.time}`,
    },
    messagesCentre: {
      loadMore: '載入更多通知',
      prefs: '通知偏好設定',
    },
    preferencesCentre: {
      byEmail: '發送通知到我的電郵地址',
      prefs: '偏好設定',
    },
  },
  orders: {
    dialog: {
      badAttitude: '態度差',
      branch: '分店：',
      cancel: '取消訂單',
      cbm: '立方米',
      collectOnDelivery: options => `代收貨款: ${options.fee}`,
      collectOnDeliveryHeader: '代收貨款',
      confirmOrderComplete: '司機已完成訂單，請您幫忙評分',
      contactNumber: '收件者電話號碼',
      desiredDeliveryTime: options => `期望送達時間: ${options.time}`,
      desiredDeliveryTimeHeader: '期望送達時間',
      destination: '落貨點',
      disputeClosed: '價錢問題已處理',
      disputeClosedMessage: options =>
        `訂單 #${options.orderRequestId} 價錢問題已處理`,
      disputeReported: '價錢問題已回報',
      disputeSubmittedMessage: options =>
        `訂單 #${options.orderRequestId} 價錢問題已回報`,
      download: '下載 匯入路線範例(多張訂單)',
      driver: '司機:',
      duration: '花費時間:',
      email: '電郵地址',
      feeIssue: '價錢問題',
      goodsInfo: '貨物資料',
      help: '幫助',
      images: '圖片',
      import: '匯入路線',
      importSingleRouteButton: '上傳CSV檔案',
      importMultiRouteButton: '上傳CSV檔案',
      item: '貨物類型',
      licensePlate: '車牌號碼:',
      longLoadingTime: '載貨時間太長',
      noGoodsInfo: '無貨物資料',
      noimages: '',
      nosignature: '',
      notApplicable: 'N/A',
      notOnTime: '司機遲到',
      orderCompletionConfirmed: '訂單已確認完成',
      orderNumber: options => `訂單編號：${options.id}`,
      other: '其他',
      parcelNumber: '',
      phoneNumber: '電話號碼：',
      pickupTime: '上貨時間：',
      pickupTimeCSV: '上貨時(*)',
      previewImage: '',
      previewSignature: '',
      priceBreakdown: '收費項目︰',
      quantity: '數量',
      rateAndConfirmCompletion: '評分',
      reachingOut:
        "(options.count === 0 || !options.count) ? '正在指派司機' : (options.count === 1) ? '已聯繫1名司機' : '已聯繫 ' + options.count + ' 司機'",
      reachingOutNone: '正在指派司機',
      reachingOutOne: '已聯繫1名司機',
      reachingOutMany: options => `已聯繫 ${options.count} 司機`,
      recipient: '收件者',
      remarks: '備註',
      remarksFile: '備註',
      reportDispute: '回報價錢問題',
      reportDisputeConfirmMessage:
        '您確定要回報價錢問題嗎？ 如確定回報後，我們會有專人聯絡您。',
      reuse: '重用此路線',
      routeChanged: '路線更改',
      routes: '相關路線',
      share: '分享',
      sizeOfGoods: '貨物尺寸',
      timeSheetRemarks: '司機備註',
      timeSheets: '參考文件',
      totalFee: '總收費：',
      vehicle: '車輛類型',
      vehicleType: '車輛類型',
      vehicleTypeCSV: '車輛類型',
      weight: '重量',
      whatWasTheProblem: '遇到問題嗎？',
      singleRoute: '匯入單一路線',
      multiRoute: '匯入多條路線',
    },
    filter: {
      amended: '已更新價錢問題',
      branch: '分店',
      disputeStatus: '價錢更新狀態',
      open: '已回報價錢問題',
      ordersFrom: '訂單從',
      parcelStatus: '',
      pickupDate: '上貨日期',
      queryPlaceholder: '司機或客戶姓名/電話號碼等',
      search: '搜尋：',
      showAll: '顯示全部',
      status: '狀態：',
      to: '到',
      vehicle: '車輛類型：',
    },
    new: {
      goodsRequirements: {
        goodsDetails: '貨物詳情',
      },
      basicInfo: {
        addInstructions: '增加備註',
        disabled: '不允許',
        edit: '編輯',
        email: '電郵地址',
        goodsAndServiceSetting: '貨物及服務設定',
        extraRequirements: '附加服務',
        hourlyRental: '包鐘',
        hours: '小時',
        instructions: '備註',
        noRental: '無包鐘服務',
        phone: '電話號碼',
        remarks: '備註',
        pickupDate: '上貨日期',
        pickupPoint: '上貨點',
        pickupTime: '上貨時間',
        sender: '送件者：',
        now: '即時',
        later: '選擇日期及時間',
        tunnel: '首選隧道',
        tunnel_any: '任何隧道',
        tunnel_hung: '海底隧道',
        tunnel_western: '西區海底隧道',
        tunnel_eastern: '東區海底隧道',
        tunnel_lion_rock: '獅子山隧道',
        tunnel_tates_cairn: '大老山隧道',
        tunnel_eagles_nest: '尖山隧道',
        tunnel_tai_lam: '大欖隧道',
        tunnel_aberdeen: '香港仔隧道',
        tunnel_tko: '將軍澳隧道',
        tunnel_tm_bridge: '青馬大穚',
        tunnel_discovery: '愉景灣隧道',
        tunnel_shing_mun: '城門隧道',
        passenger: '乘客人數',
        selection: {
          today: '今天',
          tomorrow: '明天',
          later: '今日前',
        },
        placeholder: {
          selectTime: '選擇時間',
        },
        subtitle: {
          total: '總數',
        },
        chargeInfo: '在柯打完成後才會向你收費',
      },
      index: {
        newOrder: '新訂單',
      },
      basicinfo: {
        selection: {
          asap: '盡快',
        },
      },
      total: {
        insuranceTitle: '免費保險（賠償額最高港元5,000）',
        insuranceDescription:
          '為了有資格享有此保險，請在發貨前拍下你的貨物相片。',
      },
    },
    view: {
      address: '',
      detail: '詳情',
      disputeStatuses: {
        amended: '價錢已更新',
        closed: '價錢問題－已處理',
        open: '價錢問題－處理中',
      },
      driverInfo: '司機資料',
      eta: '預計到達時間',
      exportThisReport: '匯出報告',
      extraDropoffPoints: options => `${options.count}落貨點`,
      filterBranch: '分店',
      filterCurrent: '目前篩選器設置',
      filterDispute: '價錢更新狀態',
      filterNoOrders: options =>
        `未能在以下日子找到訂單 ${options.startDate} 到 ${options.endDate}`,
      filterNoOrdersSearch: options =>
        `未能於下列條件中找到訂單 ${options.searchTerm}`,
      filterOrderStatus: '訂單狀態',
      filterParcel: '',
      filterSearch: '搜尋',
      filterVehicle: '車種',
      mapDetail: '地圖|詳細',
      mapView: '地圖模式',
      next: '下一頁',
      nosignatureorimages: '',
      orderNumber: '柯打',
      pageHeader: '訂單管理',
      parcelId: '',
      parcelNumber: '',
      parcelView: '',
      pickupTime: '上貨時間',
      placedBy: 'PLACED BY',
      plusReceivers: options => `增加 ${options.count}個收件人`,
      previous: '上一頁',
      priceColumn: '收費',
      receiverInfo: '收件者資料',
      receivers: options => `${options.count}收件人`,
      route: '路線',
      sender: '送件者資料',
      showingPage: options =>
        `第${options.currentPage}頁／共${options.totalPages}頁`,
      status: '狀態',
      statusReason: '',
      tableView: '表格模式',
      timestamp: '',
      todayStatus: options =>
        `今日訂單：${options.active} 進行中 ; ${options.pending} 等待司機接單; ${options.completed} 已完成; ${options.cancelled} 已取消`,
      totalAmount: '總額：',
      totalOrders: options => `總訂單數目：${options.count}`,
      totalParcels: '',
      updateInfo: '',
      vehicle: '車輛種類',
    },
    waypointStatus: {
      arrivedAt: options => `已到達 ${options.time}`,
      deliveredAt: options => `已送達 ${options.time}`,
      eta: options => `預計到達時間 ${options.eta}`,
      findingDriver: '尋找司機',
      pending: '等待司機中',
    },
  },
  organization: {
    switch: {
      apply: '確認',
      branchLabel: '分店',
      label: '轉換公司賬戶',
      organizationLabel: '公司',
    },
  },
  organizations: {
    addressEdit: {
      destination: '落貨點',
      detailAddress: '樓/室',
      editMyOrgAddress: '編輯我的公司地址',
      pageHeader: '公司資料>編輯資料>更改地址',
    },
    edit: {
      add: '新增',
      addABranch: '新增分店',
      branchAddress: '分店地址',
      branches: '分店',
      branchName: '分店名稱',
      cannotRemoveApiBranchErr: '無法刪除或修改API分部',
      cannotRemoveBranchErr: '抱歉，這個分店不能被移除，因為有用戶正使用它',
      companyWebsite: '公司網站',
      contactPerson: '聯絡人',
      contactPersonPhone: '聯絡人電話號碼',
      corporateName: '公司名稱',
      corporateProfileImage: '公司資料圖片',
      detailAddress: '樓/室',
      editAddress: '編輯地址',
      editMyOrg: '編輯我的公司',
      email: '常用電郵地址',
      industry: '行業',
      mapAddress: '地址',
      pageHeader: '公司資料>編輯資料',
      remark: '常用備註 (訊息自動在司機端上顯示)',
    },
    show: {
      branches: '分店',
      companyWebsite: '公司網站',
      contactPerson: '聯絡人',
      contactPersonPhone: '聯絡人電話號碼',
      detailAddress: '樓/室',
      editProfile: '修改帳戶',
      email: '公司電郵地址',
      industry: '行業',
      mapAddress: '地址',
      myOrg: '我的公司',
      pageHeader: '公司資料',
      remark: '常用備註 (常用備註將會長期顯示在每張訂單上，以供司機參考。)',
      salesRepresentative: '',
      unassigned: '',
    },
  },
  popup: {
    merge: {
      dismissAll: '全部關閉',
      notifStatement: options => `您有 ${options.count} 個新通知`,
      seeAll: '顯示全部',
    },
    message: {
      dismiss: '關閉',
      todayAt: options => `今天@${options.time}`,
    },
    newFeauture: {
      title: '最新上門送件服務！',
      body: '我們的快遞員會於4小時或即日為你送件上門',
    },
    info: {
      insuranceDetails: {
        title: '保險詳情',
      },
    },
    actionButton: {
      claimInsurance: '填寫索償保險電郵',
      close: '關閉',
      getStarted: '立即體驗',
    },
    confirm: {
      removeImage: {
        title: '刪除相片',
        content: '你確認要刪除相片？',
      },
      actionButton: {
        removeImage: '確認刪除相片',
        cancelOrder: '確認取消柯打',
        removeCard: '刪除',
        addCard: '新增信用卡',
      },
      cancelOrder: {
        title: '取消柯打',
        content: '你是否確認取消這柯打？你不會被收費。',
      },
      addCard: {
        content: '信用卡會存於帳戶並支付上門送件服務柯打',
      },
    },
  },
  session: {
    dialog: {
      multiSessionBody:
        '其他使用者正在登入你的帳戶，因此賬戶已被登出，請檢查。',
      multiSessionTitle: '帳號已被登出',
      havePermit: '我持有有效許可證',
      noPermit: '我沒有有效許可證',
      polygonDialogHeader: '禁區提示',
      no_service: options =>
        `下列地點不在服務範圍內。 ${options.address} 如需協助，請致電 35903399 查詢。`,
      restricted_area: options =>
        `下列地點僅限持有有效許可證人士進出${options.address} 如您沒有有效許可證，需與司機另議車費。請致電 35908975，我們會為你提供協助。`,
      restricted_area_both: options =>
        `下列地點僅限持有有效許可證人士進出 ${options.address} 您需與司機另議車費。請致電 35908975，我們會為你提供協助。`,
      gotIt: '知道了',
    },
  },
  user_suspended: {
    dialog: {
      dialog_message:
        '您的帳戶已被凍結。 如想繼續使用GOGO 企業的服務，請致電企業專線3590 8975跟進。',
      ok_button: '確定',
    },
  },
  users: {
    login: {
      signIn: '登入',
    },
    profile: {
      accessLevel: '存取權限',
      accMgmt: '帳戶管理',
      address: '地址',
      branch: '分店',
      detailAddress: '樓/室',
      editCorporateProfile: '編輯公司資料',
      editMyProfile: '編輯個人資料',
      email: '電郵地址',
      myAcc: '我的帳戶',
      notApplicable: 'N/A',
      pageHeader: '我的帳戶',
      phoneNumber: '電話號碼',
    },
    profileEdit: {
      accessLevel: '存取權限',
      admin: 'GGV超級管理員',
      branch: '分店編號',
      branchUser: '分店管理員',
      changePassword: '更改密碼',
      confirmPassword: '確認密碼',
      currentPassword: '目前密碼',
      detailAddress: '樓/室',
      editMyAcc: '編輯我的賬戶',
      email: '電郵地址',
      master: '超級管理員',
      name: '姓名',
      newPassword: '新密碼',
      pageHeader: '賬戶管理>編輯賬戶',
      passwordMismatch: '新密碼和確認密碼不一致',
      phone: '電話號碼',
      streetAddress: '地址',
      subUser: '分店使用者',
    },
    changePassword: {
      updateFail: '用戶資料已更新。密碼更新失敗。',
    },
  },
  utils: {
    time: {
      hours: '小時',
      minutes: '分鐘',
      seconds: '秒',
    },
  },
  validations: {
    errors: {
      baseErrorAddress: '無效的地址',
      baseErrorEmail: '錯誤的電郵地址',
      baseErrorNumber: '非號碼',
      baseErrorPhone: '錯誤的電話號碼',
      baseErrorUnknown: '錯誤: 數據重覆',
      completeErrorMessage: options =>
        `第${options.faultyLines}行有${options.baseError}`,
      emptyAddress: '請選擇落貨點。',
      expiredResetPasswordToken: '你的重設密碼連結已過期',
      failedToComputeLatLon: options => `無法計算${options.field}的經緯度`,
      invalidDesiredDeliveryTime: '請輸入一個有效的時間。',
      invalidEmailFormat: '錯誤的電郵地址格式',
      invalidFields: '輸入錯誤',
      invalidNumberFormat: '錯誤的號碼格式',
      invalidOrder: '無效的訂單，請檢查您所輸入的資料',
      invalidPassword: '密碼錯誤',
      invalidPhone: '一個或以上的電話號碼無效',
      invalidPhoneNumber: '錯誤的電話號碼',
      invalidUsernameOrPassword: '使用者名稱或密碼錯誤',
      missingField: '輸入錯誤',
      passwordLengthTooShort: '您的密碼不足8位',
      passwordMismatch: '密碼不一致',
      unresolveableAddress: '請從搜尋結果中選取一個地址。',
      usedResetPasswordToken: '重設密碼連結已被使用，請使用／要求新的連結',
      invalidPasswordMin8: '',
    },
    fields: {
      address: '地址',
      contactName: '聯絡人姓名',
    },
    phoneRegexp: 'help',
  },
  wallet: {
    filter: {
      month: '月份',
      showAll: '全部顯示',
      year: '年份',
    },
    view: {
      amount: '總收費',
      balance: '結餘／結欠',
      companyName: '公司名稱',
      date: '日期',
      deposit: '按金紀錄',
      depositInfo:
        '按金是一個信用額度，讓你可以在預設額度內使用GOGO企業叫車。如果你預計每月使用GOGOVAN的費用大約為$3000，我們會建議你存入$3000作為按金。',
      depositView: '按金',
      description: '描述',
      downloadReport: '導出報表',
      next: '下一頁',
      orderRequestId: '訂單編號',
      pageHeader: '帳單管理',
      previous: '上一頁',
      showingPage: options =>
        `第${options.currentPage}頁／共${options.totalPages}頁`,
      time: '時間',
      totalDeposits: options => `總按金：${options.count}`,
      totalOrders: options => `總訂單數目：${options.count}`,
      totalTransactions: options => `總交易次數：${options.count}`,
      transaction: '交易紀錄',
      transactionTypes: {
        deposit: '按金',
        orderCompletion: '已完成訂單',
        orderDispute: '已修正訂單',
        settlement: '已繳付－多謝',
      },
    },
    internallink: {
      addCard: '新增信用卡',
      removeCard: '刪除信用卡',
    },
  },
  sidemenu: {
    van: {
      title: '叫車服務',
      item: {
        placeOrder: '新增訂單',
        orderManagement: '訂單管理',
        addressBook: '常用送貨資料',
        billing: '帳單管理',
      },
    },
    d2d: {
      title: '搵速遞員',
      item: {
        placeOrder: '新增訂單',
        orderManagement: '訂單管理',
        wallet: '錢包',
      },
    },
  },
  onboarding: {
    header: {
      selectService: '請選擇服務',
    },
    serviceType: {
      title: {
        van: '叫車服務',
        d2d: '搵速遞員',
      },
      body: {
        van: '租用貨Van或貨車',
        d2d: '4小時或即日上門送件',
      },
      description: {
        van: '(e.g. 桌子或床墊)',
        d2d: '(e.g. 包裹或水果籃)',
      },
    },
    van: {
      title: 'GOGO企業 叫車服務',
      feature1:
        '叫車無需再用現金，您可以使用月結服務，一筆過支付所有叫車開支。',
      feature2: '專屬客戶服務經理支援，協助您解決業務上遇到的疑難。',
      feature3: '簡易管理所有訂單的進度及狀態，以及所有叫車費用一目了然。',
      activate: {
        title: '啟動月結服務 (只限叫車服務）',
        status: '按金尚待確認',
      },
      description:
        '請根據電郵上的指示去提供商業登記證副本及月結按金($1000)存款收據，以啟動月結服務 (只限叫車服務)。如有任何疑問，歡迎電郵至',
      email: 'business@gogovan.hk',
    },
    delivery: {
      title: 'GOGO企業 速遞服務',
      description:
        '若您想使用送件上門服務 (貨物大小不大於60cm x 60cm x 60cm)，請使用GOGO速遞，並',
      link: '按此',
    },
  },
  sectionHeader: {
    serviceType: {
      van: {
        name: '叫車服務',
        placeOrder: '新增訂單',
        orderManagement: '訂單管理',
        addressBook: '常用送貨資料',
        billing: '帳單管理',
      },
      d2d: {
        name: '搵速遞員',
      },
    },
  },
  error: {
    invalid: {
      address: '請提供一個有效的地址。',
      contact: {
        number: '請輸入一個有效的聯絡人號碼。',
      },
    },
  },
  orderManagement: {
    actionButton: {
      placeOrder: '建立新柯打',
    },
    tab: {
      orders: {
        all: '全部柯打',
        active: '進行中柯打',
        past: '過去柯打',
      },
    },
  },
  orderDetails: {
    courier: '速遞員',
    loading: '請耐心等待，我們正在為你搜尋速遞員...',
    title: {
      status: '狀態',
      shippingInfo: {
        packageSize: '包裝尺寸',
        packageWeight: '貨件重量',
      },
    },
    status: {
      orderConfirm: '柯打已確認',
      accepted: '已有速遞員接柯打',
      pickup: '已取貨',
      complete: '已完成柯打',
    },
    shippingInfo: {
      button: {
        courier: '速遞員',
      },
    },
  },
  systemMessage: {
    order: {
      cancelled: '已成功取消柯打',
      cancelledReturn: '已成功取消柯打。速遞員會將聯絡你安排退回貨物。',
    },
  },
  badges: {
    new: 'NEW',
  },
  newOrder: {
    tab: {
      orderInfo: {
        name: '訂單詳情',
        subtitle: {
          route: '路程',
          pickupTime: '上貨時間',
          hourlyRental: '包鐘',
          vehicleType: '車輛類型',
          noOfVehicle: '車輛數量',
        },
        route: {
          textfield: {
            pickUpPoint: '選擇上貨／落貨點',
            floorRoom: '樓／室',
            name: '名稱',
            phone: '電話號碼',
          },
          link: {
            addWaypoint: '新增中途點',
            clearWaypoint: '清除',
            removeWaypoint: '移除',
          },
          phoneError: '請輸入一個有效的電話號碼。',
          addressError: '請提供一個有效的地址。',
        },
        description: {
          noOfVehicle: '所有車輛將行駛相同的路線',
        },
        selection: {
          hourlyRental: '無包鐘服務',
          vehicleType: {
            van: 'Van仔',
            Truck55: '5.5 噸貨車',
            Truck9: '9 噸貨車',
            hoverInfo: {
              van: {
                title: 'Van仔',
                content:
                  '最多可載5位乘客。載重約700公斤。內籠呎寸約長6呎，闊4呎，高4呎。',
              },
              truck55: {
                title: '5.5 噸貨車',
                content:
                  '最多可載5位乘客。載重約1-1.5公噸。內籠呎寸約長17-19呎，闊6呎，高6呎。',
              },
              truck9: {
                title: '9 噸貨車',
                content:
                  '最多可載5位乘客。載重約3-3.5公噸。內籠呎寸約長19-21呎，闊6呎，高6呎。',
              },
            },
          },
        },
        btnNext: '下一步',
        hourlyRental: {
          hours: '小時',
        },
      },
      additional: {
        name: '附加服務設定',
        title: {
          additionalService: '附加服務',
        },
        selection: {
          passengers: {
            van: {
              title: '跟車乘客 ($0)',
            },
            truck55n9: {
              title: '跟車乘客 ($20)',
              description:
                '如需要安排屋車乘載3位或以上乘客，均收取單一收費$20。',
            },
            options: {
              passenger1: '1\b名乘客',
              passengers2: '2名乘客',
              passengers3: '3名乘客',
              passengers4: '4名乘客',
              passengers5: '5名乘客',
            },
          },
          rentCart: {
            title: '租用手推車或板車 (每架HK$10)',
            options: {
              cart1: '1架',
              cart2: '2架',
              cart3: '3架',
            },
          },
          rentForklift: {
            title: '租用唧車 (每架HK$20)',
            options: {
              forklift1: '1架',
              forklift2: '2架',
              forklift3: '3架',
            },
          },
          goodOver6Ft: {
            title: '貨物呎寸超過6呎 ($15-30)',
            options: {
              goodOver6Ft: '貨物呎寸超過6呎 ($15)',
              goodOver6x2Ft: '貨物呎寸超過6x2呎 ($30)',
            },
          },
          pet: {
            title: '運送寵物 ($10)',
          },
          newVehicle: {
            title: '新車 ($5)',
          },
          engDriver: {
            title: '英語司機 ($5)',
          },
          moveGoods: {
            title: '上門搬運 (報價)',
            hover: '司機會按貨量及詳細地址報價，請在備註說明詳情。',
          },
          disposeWaste: {
            title: '運送或棄罝廢物 (報價)',
            hover: '司機會按廢料貨量報價，請在備註說明廢料詳情。',
          },
          noteToDriver: {
            title: '備註',
            description: '請在備註說明貨物及額外服務詳情。(選填)',
            characterLimit: '字數限制：1000',
            textfield: {
              placeholder: '備註',
            },
            exceedCharacterError: '你已超出1000字數的上限。',
          },
        },
        btnBack: '返回',
        btnGetQuote: '即時報價',
      },
      summary: {
        name: '路線總覽',
        title: {
          price: '費用詳情',
          estimatedTotal: {
            label: '估計收費總數',
            price: {
              quote: '報價 ',
            },
          },
          routeFee: '路程收費',
          additionalService: '附加服務',
          orderSummary: '柯打總覽',
          pickUp: '上貨點',
          route: '路程',
          vehicle: '車輛類型',
          noteToDriver: '備註',
        },
        breakdown: {
          passengers: '跟車乘客 (%)',
          rentCart: '租用手推車或板車 (%)',
          rentForklift: '租用唧車 (%)',
          goodOver6Ft: '貨物呎寸超過6呎',
          goodOver6x2Ft: '貨物呎寸超過6x2呎',
          pet: '運送寵物',
          newVehicle: '新車',
          engDriver: '英語司機',
          disposeWaste: '運送或棄罝廢物',
          waitingTime: '等候時間',
          moveGoods: '上門搬運',
        },
        feeRemark:
          '以上費用未包含橋費，隧道費，停車場費及其他額外費用。請於裝載貨物前與司機確認費用總額。',
        link: {
          learnMore: '請點擊此處瞭解更多詳情',
        },
        btnEdit: '編緝柯打',
        btnPlaceOrder: '確認下單',
        priceUpdatePrompt: {
          title: '訂單已更新',
          content: '您所選擇的時間已過，費用已更新。',
        },
      },
    },
  },
  signup: {
    createAccount: {
      title: '建立帳號',
      description:
        '立即建立GOGO企業帳號以享用月結服務，簡易賬單管理，以及獨立企業專員為您服務。',
      textfield: {
        name: '姓名',
        Email: '電郵地址',
        phone: '電話號碼',
        error: {
          empty: {
            name: '請輸入您的姓名',
            email: '請輸入您的電郵地址',
            phone: '請輸入您的電話號碼',
            pw: '請輸入密碼',
            reenterPw: '請重新輸入密碼',
          },
          format: {
            email: '請輸入正確的電郵地址',
            phone: '該電郵地址已登記',
            pw: '密碼必須至少8個字符',
          },
          exist: {
            phone: '該電話號碼已登記',
          },
          match: {
            pw: '密碼不相符。請再試一次',
          },
        },
      },
      actionButton: {
        createAccount: '登記',
      },
      smalltext: {
        termsOfService: '使用條款',
        and: '和',
        privacyPolicy: '隱私政策',
        enterpriseUser: '大量訂單的企業客戶，請致電',
        phone: '(+852) 3590 8975 或電郵至',
        email: 'business@gogovan.hk ',
      },
      checkMail: {
        title: '請檢查你的電子郵箱',
        description1: options => `系統會傳送驗證電郵到${options.email}。`,
        description2:
          '如電郵地址正確但沒有收到驗證電郵，請檢查你的垃圾郵件箱。',
        smalltext: {
          email: 'business@gogovan.hk ',
        },
      },
    },
    activateAccount: {
      setpw: {
        title: '啟活帳戶',
        description: '歡迎使用GOGO企業！請先創建一個安全的密碼。',
        textfield: {
          password: '密碼',
          reenterPassword: '重新輸入密碼',
        },
        button: {
          next: '下一步',
        },
      },
      companyInfo: {
        title: '請告訴我們一些關於您的業務',
        description: '可幫助我們個性化設定你的賬號',
        textfield: {
          title: {
            companyName: '公司名稱',
            industry: '行業',
          },
          placeholder: {
            companyName: '公司名稱',
          },
        },
        selection: {
          placeholder: {
            selectIndustry: '選擇行業',
          },
        },
        selectIndustry: {
          selection: {
            constructions: '五金，裝修及建築工程',
            interior: '傢俱及家居佈置及設計',
            florists: '花店',
            food: '食品',
            printing: '印刷及出版',
            commercial: '商業及金融服務',
            education: '學校及機構或公營機關',
            pet: '寵物店',
            textile: '紡織 成衣',
            event: '活動統籌 廣告製作及展覽',
            ecommerce: '網購或互聯網',
            health: '醫藥 保建 衛生',
            electronics: '電子及家庭電器',
            retails: '零售 消費品 運動用品',
            trading: '貿易公司',
            logistics: '物流',
            automobile: '汽車 配件',
            entertainment: '消閒及娛樂',
          },
        },
        selectCompanySize: {
          selection: {
            title: '公司規模',
            from1to10: '1-10',
            from11to30: '11-30',
            from30up: '30+',
          },
          placeHolder: '選擇公司規模',
        },
        companyWeb: {
          title: '公司網址（可選填）',
          textfield: {
            placeHolder: '公司網網址',
          },
        },
        neededService: {
          textfield: {
            title: '需要以下哪種服務',
          },
          selection: {
            placeHolder: '選擇...',
            vantruck: '貨車',
            vantruckMover: '貨車+搬運',
            courier: '速遞',
            all: '全部',
            others: '其他',
          },
        },
        button: {
          getStarted: '立即開始',
        },
      },
      error: {
        empty: {
          textfield: '請輸入公司名稱',
          selection: '請選擇項目',
        },
      },
    },
  },
  ggb: {
    login: {
      error: {
        expired: {
          message: '鏈接已過期。如果您已經完成啟活，請登錄帳戶或',
          signup: '註冊新帳戶',
        },
      },
      noLogin: '沒有帳戶？',
      signup: '立即建立GOGO Business帳戶',
    },
  },
}
