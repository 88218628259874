import { Api } from '../../legacy/utils/api'

const _favoriteDriversApi = new Api(Api.BASE_URL.B2B)

const favoriteDriversApiService = {
  addFavoriteDriver: async idDriver => {
    const method = 'post'
    const endpoint = '/driver_interactions/favourite_drivers'
    const data = {
      driver_id: idDriver,
    }

    try {
      const result = await _favoriteDriversApi.request(
        method,
        endpoint,
        null,
        data
      )
      return result
    } catch (error) {
      throw error
    }
  },
  removeFavoriteDriver: async idDriver => {
    const method = 'delete'
    const endpoint = '/driver_interactions/favourite_drivers'
    const data = {
      driver_id: idDriver,
    }

    try {
      const result = await _favoriteDriversApi.request(
        method,
        endpoint,
        null,
        data
      )
      return result
    } catch (error) {
      throw error
    }
  },
  getFavoriteDrivers: async () => {
    const method = 'get'
    const endpoint = '/driver_interactions/favourite_drivers'

    try {
      const result = await _favoriteDriversApi.request(method, endpoint)
      return result.data
    } catch (error) {
      throw error
    }
  },
}

export { _favoriteDriversApi, favoriteDriversApiService }
