import Route from 'react-router/lib/Route'
import IndexRedirect from 'react-router/lib/IndexRedirect'

import { featureFlagService } from './../service/feature-flag/feature-flag'
import { loadableCreator } from '../delivery/loading/loadable-creator'
import { isMasterUser } from '../users-and-branches/duck/selectors'

const LoadableDeveloperDashboard = loadableCreator({
  dynamicImport: () =>
    import(
      /* webpackChunkName: 'developer-dashboard' */ './developer-dashboard.component'
    ),
  componentName: 'DeveloperDashboard',
})

const redirectToIndex = (store, featureFlagName) => (
  _nextState,
  replace,
  callback
) => {
  const checkFlag = () => {
    const state = store.getState()
    const shouldRedirect =
      state[featureFlagName] === false || !isMasterUser(state)

    if (shouldRedirect) {
      replace('/')
    }
    callback()
  }

  featureFlagService
    .triggerFeatureFlag('developer')
    .then(checkFlag)
    .catch(checkFlag)
}

const DeveloperRoutes = store => (
  <Route path="/developer">
    <IndexRedirect to="/developer/dashboard" />
    <Route
      path="dashboard"
      component={LoadableDeveloperDashboard}
      onEnter={redirectToIndex(store, 'featureFlagDeveloper')}
    />
  </Route>
)

export { DeveloperRoutes }
