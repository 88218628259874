import styled from "styled-components"
import Link from "react-router/lib/Link"

import {
  SmallText,
  SmallTextBold,
} from "ggx-componentlibrary/design/typography/typography.styles"
import { FONT_BOLD } from "ggx-componentlibrary/design/typography/typography.constants"
import { COLOR } from "ggx-componentlibrary/design/color/color.constants"
import { SPACING } from "ggx-componentlibrary/design/spacing/spacing.constants"
import { EXPANDED_WIDTH } from "../navigation.constants"

import OrganizationSVG from "./assets/organization.svg"
import BellSVG from "./assets/bell.svg"
import ChevronSVG from "./assets/chevron-right.svg"

const OrganizationIcon = styled(OrganizationSVG)`
  margin: 0 ${SPACING.S};
  flex-shrink: 0;
`

const BellIcon = styled(BellSVG)`
  margin: 0 ${SPACING.S};
  flex-shrink: 0;
`

const ChevronIcon = styled(ChevronSVG)`
  margin: 0 ${SPACING.XS} 0 auto;
  flex-shrink: 0;
`
const ChevronIconNoMargin = styled(ChevronSVG)`
  flex-shrink: 0;
`

const MenuRowPrimary = styled(SmallText)`
  margin-bottom: 0;
  color: ${COLOR.GREY_DARKER};
`

const MenuRowSecondary = styled(SmallText)`
  margin-bottom: 0;
`

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid lightgrey;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
`

Menu.Row = styled.div`
  width: ${EXPANDED_WIDTH};
  padding: ${SPACING.S} 0;
  display: flex;
  align-items: center;
  cursor: pointer;

  :hover {
    background-color: ${COLOR.GREY_LIGHTER};
  }

  ${(props) => {
    if (props.$isActive) {
      return `
        background-color: ${COLOR.GREY_LIGHTER};

        ${MenuRowPrimary} {
          ${FONT_BOLD}
          color: ${COLOR.BLACK};
        }
      `
    }
    return `
      :hover {
        ${MenuRowPrimary} {
          color: ${COLOR.BLUE_MEDIUM};
        }
      }
    `
  }}
`

const NotificationIconContainer = styled.div`
  position: relative;
  top: 1px;
`

const NotificationCount = styled(SmallTextBold)`
  width: 16px;
  height: 16px;
  background-color: ${COLOR.RED_MEDIUM};
  color: ${COLOR.WHITE};
  position: absolute;
  right: 12px;
  top: -4px;
  border-radius: 4px;
  text-align: center;
`

const SubMenu = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  left: 169px;
  width: 176px;
  top: calc(80px - ${props => Math.min(props.$subMenuOffset ?? 0, 80)}px);
  background-color: ${COLOR.WHITE};
  border-radius: ${SPACING.XXS};

  opacity: ${(props) => (props.$showSubMenu ? 1 : 0)};
  pointer-events: ${(props) => (props.$showSubMenu ? "auto" : "none")};
  transition: opacity 0.2s ease-in-out;
  padding: ${SPACING.XS} 0;
  z-index: 5;
`

SubMenu.Row = styled(Link)`
  text-decoration: none;
  padding: 12px ${SPACING.M};

  :hover {
    background-color: ${COLOR.GREY_LIGHTER};

    ${SmallText} {
      color: ${COLOR.BLUE_MEDIUM} !important;
    }
  }
`

const OrgSwitchContainer = styled.div`
  position: fixed;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  left: 219px;
  top: 0px;
  background-color: ${COLOR.WHITE};
  border-radius: ${SPACING.XXS};

  opacity: ${(props) => (props.$showOrganizationSwitch ? 1 : 0)};
  pointer-events: ${(props) =>
    props.$showOrganizationSwitch ? "auto" : "none"};
  transition: opacity 0.2s ease-in-out;
  padding: ${SPACING.XS} 0;
`

const OrganizationName = styled(SmallText)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
`

const AccountName = styled(SmallText)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`

const LanguageSwitch = styled(SmallText)`
  margin-bottom: 0;
  padding: 12px ${SPACING.M};
  cursor: pointer;
  :hover {
    background-color: ${COLOR.GREY_LIGHTER};
    color: ${COLOR.BLUE_MEDIUM} !important;
  }
`

const RowSeparator = styled.hr`
  width: calc(100% - 48px);
  margin: ${SPACING.XS} auto;
  border: 1px solid ${COLOR.GREY_LIGHT};
`

export {
  OrganizationIcon,
  BellIcon,
  ChevronIcon,
  ChevronIconNoMargin,
  Menu,
  SubMenu,
  OrgSwitchContainer,
  RowSeparator,
  LanguageSwitch,
  OrganizationName,
  AccountName,
  MenuRowPrimary,
  MenuRowSecondary,
  NotificationCount,
  NotificationIconContainer,
}
