import PropTypes from 'prop-types'

import translate from '../../../legacy/locales/translate'
import CrossSVG from '../../../legacy/assets/images/notification_center/cross.svg'

import { Container, TitleText } from './drawer-title.component.styles'

const DrawerNotificationTitle = ({ onClick = () => {} }) => (
  <Container onClick={onClick}>
    <TitleText>
      {translate('notificationCentre.drawerTitle.notification')}
    </TitleText>
    <CrossSVG />
  </Container>
)

DrawerNotificationTitle.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default DrawerNotificationTitle
