/* eslint-disable camelcase */
/* eslint-disable newline-per-chained-call */
import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment-timezone'
import { useQuery } from 'react-query'

import { getFeatureFlag } from 'ggx-service/feature-flag/selectors'
import { track, MIXPANEL_EVENT } from 'ggx-service/mixpanel/mixpanel'
import { brazeClient, BRAZE_EVENT } from 'ggx-service/braze'

import {
  FilterContainer,
  FilterAndSearchContainer,
  Footer,
  StyledPagination,
  TotalResults,
  TotalPrice,
  NoDataMessageContainer,
  StyledTooltip,
  DownloadReportDropdown,
  StyledPage,
} from './order-management.component.styles'
import {
  getFilterStatus,
  getFilterDate,
  getFilterBranch,
  getOrderSearchValue,
} from './duck/selectors'
import { FilterStatus } from './filter-status/filter-status.component'
import { FilterBranch } from './filter-branch/filter-branch.component'
import { FilterDate } from './filter-date/filter-date.component'
import { OrderSearch } from './order-search/order-search.component'
import { OrderTable } from './order-table/order-table.component'
import { orderService } from '../van-booking/api/order-api.service'
import {
  setFilterStatus,
  setFilterBranch,
  setFilterDate,
  setOrderSearchValue,
  clearFilters,
} from './duck/actions'
import {
  TextLink,
  Body,
  SmallText,
} from '../component-library/design/typography/typography.styles'
import { formatCurrency } from '../service/i18n/format-currency.service'
import { RouteredOrderDetail } from './order-detail/order-detail.component'
import MapUtils from '../legacy/utils/map'
import { useDelightedSurvey } from '../service/delighted/delighted'
import { parseGetOrdersResponse } from './helpers'
import useTracking, { EVENT } from 'ggx-src/order-management//use-tracking'

const ORDER_MANAGEMENT_KEY = 'ORDER_MANAGEMENT'

const OrderManagement = props => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const tracking = useTracking()

  const featureFlagOrderRequestsApiRevamp = useSelector(state =>
    getFeatureFlag({ state, featureFlag: 'order_requests_api_revamp' })
  )

  const defaultStartDate = moment()
    .clone()
    .subtract(15, 'days')
    .startOf('day')
    .format()
  const defaultEndDate = moment()
    .clone()
    .add(15, 'days')
    .startOf('day')
    .format()

  const filterStatus = useSelector(state => getFilterStatus(state))
  const filterBranch = useSelector(state => getFilterBranch(state))
  const filterDate = useSelector(state => getFilterDate(state))
  const orderSearchValue = useSelector(state => getOrderSearchValue(state))
  const [searchValue, setSearchValue] = useState(orderSearchValue)

  const [etas, setEtas] = useState({})

  const [currentPageNumber, setCurrentPageNumber] = useState(1)
  const [totalPageNumber, setTotalPageNumber] = useState(1)
  const [totalPrice, setTotalPrice] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [sortByTimeDescending, setSortByTimeDescending] = useState(true)

  const [selectedOrderId, setSelectedOrderId] = useState(undefined)

  const notifications = useSelector(state => state.notification?.messages)
  const notificationsPreviousCount = useRef(notifications.count)

  const featureFlagGGBWebTransportNid = useSelector(state =>
    getFeatureFlag({ state, featureFlag: 'ggb_web_transport_nid' })
  )

  const fetchParams = {
    start_date: filterDate?.startDate ?? defaultStartDate,
    end_date: filterDate?.endDate ?? defaultEndDate,
    status: filterStatus,
    branch: filterBranch,
    q: orderSearchValue,
    page: currentPageNumber,
    sort: sortByTimeDescending
      ? '-order_requests.pickup_time'
      : 'order_requests.pickup_time',
  }

  const onGetOrderRequestSuccess = queryResponse => {
    setTotalPageNumber(queryResponse.meta.total_pages)
    setTotalPrice(queryResponse.meta.total_price)
    setTotalCount(queryResponse.meta.total_count)
    queryResponse.orders = queryResponse.orders.map(({ id, nid, ...rest }) => ({
      ...rest,
      id: featureFlagGGBWebTransportNid ? nid || id : id,
    }))
  }

  const queryFunction = async params => {
    if (featureFlagOrderRequestsApiRevamp) {
      const result = await orderService.getOrderRequests(params)

      return { orders: result.data, meta: result.meta }
    }

    const result = await orderService.getOrderRequests_old(params)

    const data = parseGetOrdersResponse(result)
    const meta = result.meta

    return { orders: data, meta }
  }

  const queryKey = [ORDER_MANAGEMENT_KEY, ...Object.values(fetchParams)]

  const {
    data: queryResponse = { orders: [], meta: {} },
    isLoading,
    refetch,
  } = useQuery(queryKey, () => queryFunction(fetchParams), {
    onError: () => {},
    onSuccess: onGetOrderRequestSuccess,
    refetchInterval: 120000,
  })

  const fetchEtas = useCallback(() => {
    if (featureFlagOrderRequestsApiRevamp) {
      queryResponse.orders.forEach(order => {
        if (order?.order?.driver_location) {
          const setEtaCallback = (eta, success) => {
            if (success) {
              setEtas(prevState => ({ ...prevState, [order.id]: eta }))
            }
          }

          const nextWaypoint =
            order?.waypoints.find(waypoint => !waypoint.driver_arrived_at) ||
            order?.waypoints?.[order?.waypoints.length - 1]
          MapUtils.getETA(
            order?.order?.driver_location,
            nextWaypoint,
            setEtaCallback,
            true
          )
        }
      })
    } else {
      queryResponse.orders.forEach(order => {
        if (order?.linkedOrder?.driver_location) {
          const setEtaCallback = (eta, success) => {
            if (success) {
              setEtas(prevState => ({ ...prevState, [order.id]: eta }))
            }
          }

          // when order transition from Active -> Completed,
          // all waypoints will have driver_arrived_at
          const nextWaypoint =
            order?.waypoints.find(waypoint => !waypoint.driver_arrived_at) ||
            order?.waypoints?.[order?.waypoints.length - 1]
          MapUtils.getETA(
            order?.linkedOrder?.driver_location,
            nextWaypoint,
            setEtaCallback,
            true
          )
        }
      })
    }
  }, [queryResponse])

  const onPageNavClick = newPageNumber => setCurrentPageNumber(newPageNumber)

  const handleOpenOrderDetail = ({ id }) => {
    setSelectedOrderId(id)
    props.router.push(`/orders/${id}`)
  }

  const handleCloseOrderDetail = () => {
    setSelectedOrderId('')
    props.router.push('/orders')
  }

  // notifications can be dismissed we only refetch orders when a new one has arrived
  useEffect(() => {
    const lastNotificationOrderId = notifications?.slice(-1)[0]?.orderRequestId
    const lastNotificationIsInFilteredOrders = featureFlagOrderRequestsApiRevamp
      ? queryResponse.orders
          .map(order => order.id)
          .includes(lastNotificationOrderId)
      : queryResponse.orders
          .map(order => order.id)
          .includes(`${lastNotificationOrderId}`)

    if (
      notifications.length > notificationsPreviousCount.current &&
      lastNotificationIsInFilteredOrders
    ) {
      refetch()
    }

    notificationsPreviousCount.current = notifications.length
  }, [notifications, queryResponse.orders, refetch])

  useEffect(() => {
    const { id } = props.params
    if (id) {
      handleOpenOrderDetail({ id })
    }
  }, [])

  useEffect(() => {
    fetchEtas()
  }, [queryResponse.orders, fetchEtas])

  useEffect(() => {
    brazeClient.setCustomEvent(BRAZE_EVENT.TRANSPORT.ORDER_MANAGEMENT)
  }, [])

  const scriptContent = `
    !function(e,t,r,n){if(!e[n]){for(var i=e[n]=[],a=["survey","reset","config","init","set","get","event","identify","track","page","screen","group","alias"],s=0;s<a.length;s++){var c=a[s];i[c]=i[c]||function(e){return function(){var t=Array.prototype.slice.call(arguments);i.push([e,t])}}(c)}i.SNIPPET_VERSION="1.0.1";var o=t.createElement("script");o.type="text/javascript",o.async=!0,o.src="https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/"+r+"/"+n+".js";var v=t.getElementsByTagName("script")[0];v.parentNode.insertBefore(o,v)}}(window,document,"ivAXCvvgFi35L0Xf","delighted");
    delighted.survey({properties: {profile: 'Client', accountType: 'Business'}});`

  const surveyLocales = ['zh-hk']

  useDelightedSurvey(scriptContent, surveyLocales)

  const handleClearInput = () => {
    setCurrentPageNumber(1)
    setSearchValue()
    dispatch(setOrderSearchValue())
  }

  const handleDownloadReport = async ({ value }) => {
    let blob
    let fileName
    let organizationName
    let downloadDate
    let reportName
    const params = {
      start_date: filterDate?.startDate ?? defaultStartDate,
      end_date: filterDate?.endDate ?? defaultEndDate,
      status: filterStatus,
      branch: filterBranch,
      q: orderSearchValue,
      sort: sortByTimeDescending
        ? '-order_requests.pickup_time'
        : 'order_requests.pickup_time',
    }

    if (value === 'xlsx') {
      const { data, headers } = await orderService.downloadXLSX(params)
      blob = new Blob([data], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
      })
      fileName = decodeURIComponent(
        headers['content-disposition'].split('filename=')[1].replace(/"/g, '')
      )
      organizationName = fileName?.split(' -')[0]
      downloadDate = fileName?.split(' -')[1]?.split('at ')[1]
      reportName = `${t('CSV-title__gogobusiness_report', {
        companyName: organizationName,
        date: downloadDate,
      })}.xlsx`
    } else if (value === 'pdf') {
      const { data, headers } = await orderService.downloadPDF(params)
      blob = new Blob([data], { type: 'application/pdf' })
      fileName = decodeURIComponent(
        headers['content-disposition'].split('filename=')[1].replace(/"/g, '')
      )
      organizationName = fileName?.split(' -')[0]
      downloadDate = fileName?.split(' -')[1]?.split('at ')[1]
      reportName = `${t('CSV-title__gogobusiness_report', {
        companyName: organizationName,
        date: downloadDate,
      })}.pdf`
    }
    const trackingParam = { ...params, file_type: value }
    track(MIXPANEL_EVENT.TRANSPORT.DOWNLOAD_REPORT, trackingParam)
    brazeClient.setCustomEvent(
      BRAZE_EVENT.TRANSPORT.DOWNLOAD_REPORT,
      trackingParam
    )

    const fileURL = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = fileURL
    link.download = reportName
    link.click()
    window.URL.revokeObjectURL(fileURL)
  }

  const downloadReportButton = (
    <DownloadReportDropdown
      dataTestid="download-report-dropdown"
      placeholder={t('button__download_this_report')}
      defaultOptions={[
        { value: 'xlsx', label: t('list__xlsx') },
        { value: 'pdf', label: t('list__pdf') },
      ]}
      onChange={handleDownloadReport}
      isButtonDropdown
    />
  )

  const showClearFilterDataMessage = (
    <NoDataMessageContainer>
      <Body>{t('order_management__empty__no_orders_found')}</Body>
      <TextLink onClick={() => dispatch(clearFilters())}>
        {t('order_management__empty__clear_filters')}
      </TextLink>
    </NoDataMessageContainer>
  )

  const orderContactInfo = {}
  const ordersWithEtas = queryResponse.orders.map(order => {
    if (order.id === selectedOrderId) {
      orderContactInfo.name = order.nickname
      orderContactInfo.phoneNumber = order.phone_number
    }
    const copy = {
      ...order,
      eta: etas[order.id],
    }
    return copy
  })

  const noSearchDataMessage = (
    <Body>{t('common__you_dont_have_any_order_history')}</Body>
  )
  const hasFilterSelected = !(
    filterStatus === undefined &&
    filterBranch === undefined &&
    filterDate === undefined
  )
  const showClearFilterOptions =
    queryResponse?.orders?.length === 0 && hasFilterSelected && !isLoading

  return (
    <StyledPage
      documentTitle={t('common__order_management')}
      section={t('sidemenu.van.title')}
      heading={t('common__order_management')}
      rightComponent={downloadReportButton}
      headingSectionTypographyStyles="mb-xs"
    >
      <FilterAndSearchContainer>
        <FilterContainer>
          <FilterStatus
            onChange={({ value }) => {
              dispatch(setFilterStatus(value))
              setCurrentPageNumber(1)
            }}
            filterStatus={filterStatus}
          />
          <FilterDate
            onChange={value => {
              dispatch(setFilterDate(value))
              setCurrentPageNumber(1)
            }}
            filterDate={filterDate}
          />
          <FilterBranch
            onChange={({ value }) => {
              dispatch(setFilterBranch(value))
              setCurrentPageNumber(1)
            }}
            filterBranch={filterBranch}
          />
        </FilterContainer>
        <OrderSearch
          value={searchValue}
          handleChange={value => setSearchValue(value)}
          handleClick={() => {
            dispatch(setOrderSearchValue(searchValue))
            setCurrentPageNumber(1)
            tracking[EVENT.SEARCH_USED]({ keyword_used: searchValue })
          }}
          onClearInput={handleClearInput}
        />
      </FilterAndSearchContainer>
      <OrderTable
        orders={ordersWithEtas}
        loading={isLoading}
        onRowActivation={handleOpenOrderDetail}
        activatedRow={selectedOrderId}
        noDataMessage={
          showClearFilterOptions
            ? showClearFilterDataMessage
            : noSearchDataMessage
        }
        sortByTimeDescending={sortByTimeDescending}
        toggleSortByTimeDescending={() =>
          setSortByTimeDescending(prev => !prev)
        }
      />
      {totalPageNumber > 0 && (
        <Footer>
          <TotalResults>
            {t('common__results', { number: totalCount })}
          </TotalResults>
          <StyledPagination
            paginationText={t('orderManagement.table.pagination', {
              currentPageNumber,
              totalPageNumber,
            })}
            currentPageNumber={currentPageNumber}
            totalPageNumber={totalPageNumber}
            onPageNavClick={onPageNavClick}
            loading={isLoading}
          />
          <StyledTooltip
            noMargin
            content={t('tooltip__cancelled_orders_excluded')}
            contentWidth="150px"
          />
          <SmallText typographyStyles="m-none mr-xs mb-xxs">
            {t('common__total')}
          </SmallText>
          <TotalPrice>{formatCurrency({ amount: totalPrice })}</TotalPrice>
        </Footer>
      )}
      <RouteredOrderDetail
        onRequestClose={handleCloseOrderDetail}
        orderContactInfo={orderContactInfo}
      />
    </StyledPage>
  )
}

OrderManagement.defaultProps = {
  params: {},
}

export { OrderManagement, ORDER_MANAGEMENT_KEY }
